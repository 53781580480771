import {
    appendModalControllerIfRequired,
    makePopoverHandler,
} from '../../global/helpers.js';
import { Labels } from '../../global/labels.js';
import { isVisible, PopoverAlignment } from '../../global/ui-helpers.js';
import { UIElement } from '../ui-element.js';
import { browser } from '../../global/browser.js';
import { TabIndex } from '../../global/keyboard.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-annotation.css';

/**
 * Interface for classes that represent a service for UIAnnotation.
 * @interface IAnnotationService
 */

/**
 * Starts a chat session.
 * @function
 * @name IAnnotationService#dismiss
 * @param {string} key
 * @returns {Promise<void>}
 */

/**
 * Starts a chat session.
 * @function
 * @name IAnnotationService#isRead
 * @param {string} key
 * @returns {Promise<boolean>}
 */

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAnnotation
 * @element ui-annotation
 * @property {string} [for] {@attr for} ID or CSS selector to annotated element
 * @property {string} [contentId] {@attr content-id} Unique id to determine if a user already
 * read this annotation or not
 * @property {string} [labelClose] {@attr label-close} Label for close button.
 * @property {PopoverAlignment} [alignment] {@attr alignment} Alignment to
 * described element.
 * @property {number} [vOffset] {@attr v-offset} Vertical offset to described control
 * @property {number} [hOffset] {@attr h-offset} Horizontal offset to described control
 * @property {boolean} [locked=false] {@attr locked} Checks if annotation can be interacted
 * with or not.
 * @property {HTMLButtonElement} close Shortcut to close button.
 * @property {IAnnotationService} service Service for annotations handling. Every consumer should
 * define its own service in a project scope
 * @classdesc Represents a class for <code>ui-annotation</code> element.
 * @slot
 * @example
 * <a href="#" id="aboutUsLink">About us</>
 * <ui-annotation for="aboutUsLink" content-id="about">Read about us here.</ui-annotation>
 */
class UIAnnotation extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                for: String,
                locked: Boolean,
                contentId: String,
                labelClose: String,
                alignment: String,
                vOffset: Number,
                hOffset: Number,
            },
            children: {
                close: '.ui-annotation__close',
            },
        };
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-annotation', {
            open: 'Open annotation',
            close: 'Close annotation',
        });
    }

    /**
     * @type {number}
     */
    static get MIN_DELAY() {
        return browser.prefersReducedMotion() ? 0 : 200;
    }

    /**
     * @type {number}
     */
    static get MAX_DELAY() {
        return browser.prefersReducedMotion() ? 0 : 800;
    }

    /**
     * @type {number}
     */
    static get CLOSE_DELAY() {
        return browser.prefersReducedMotion() ? 0 : 60;
    }

    /**
     * Locks an annotation that it cannot be opened.
     */
    lock() {
        this.locked = true;
    }

    /**
     * Unlocks an annotation that it can be opened.
     */
    unlock() {
        this.locked = false;
    }

    /**
     * @returns {IAnnotationService}
     */
    static resolveService() {
        return (
            this._service || {
                isRead: () => Promise.resolve(false),
                dismiss: () => Promise.resolve({}),
            }
        );
    }

    /**
     * @param {IAnnotationService} fn
     */
    static defineService(fn) {
        this._service = fn;
    }

    /**
     * @returns {IAnnotationService}
     */
    get service() {
        return this.constructor.resolveService();
    }

    /**
     * @returns {HTMLElement}
     */
    get popoverElement() {
        if (this._detachedPopover) {
            return this._detachedPopover;
        }
        return this.querySelector('.ui-annotation__popover');
    }

    /**
     * @returns {HTMLElement|SharedComponents.UIAnnotation}
     */
    get annotatedElement() {
        let element;
        const idOrSelector = this.getAttribute('for');
        if (idOrSelector) {
            element =
                document.getElementById(idOrSelector) ||
                document.querySelector(idOrSelector);
        }
        return element || this.parentElement || this;
    }

    /**
     * Checks is annotated element is visible.
     * @returns {boolean}
     */
    isAnnotatedElementVisible() {
        const el = this.annotatedElement;
        if (!el) {
            return false;
        }

        const rect = el.getBoundingClientRect();

        if (!rect.width || !rect.height) {
            return false;
        }

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
                (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <=
                (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    /**
     * Gets popover height.
     * @returns {number}
     */
    getPopoverHeight() {
        return this.popoverElement.clientHeight;
    }

    /**
     * Gets popover width.
     * @returns {number}
     */
    getPopoverWidth() {
        return this.popoverElement.clientWidth;
    }

    /**
     * @fires event:modal-open
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }
        const useHorizontalAxis = [
            PopoverAlignment.Left,
            PopoverAlignment.Right,
        ].includes(this.alignment);
        const defaultVerticalOffset = useHorizontalAxis ? 0 : 10;
        const defaultHorizontalOffset = useHorizontalAxis ? 10 : 0;
        const popoverHandler = makePopoverHandler(this.annotatedElement, {
            popoverHeight: this.getPopoverHeight.bind(this),
            verticalOffset: this.hasAttribute('v-offset')
                ? this.vOffset
                : defaultVerticalOffset,
            popoverWidth: this.getPopoverWidth.bind(this),
            horizontalOffset: this.hasAttribute('h-offset')
                ? this.hOffset
                : defaultHorizontalOffset,
            supportMiddleAlignment: false,
            horizontalAxis: useHorizontalAxis,
            horizontalAxisSide: useHorizontalAxis ? this.alignment : undefined,
            verticalAxisSide: !useHorizontalAxis ? this.alignment : undefined,
        });
        this._detachedPopover = this.popoverElement;
        this.dispatchCustomEvent('modal-open', {
            type: 'popover',
            content: this.popoverElement,
            params: {
                classList: {
                    '-annotation': true,
                },
                position: () => {
                    return popoverHandler();
                },
                target: this,
                onOpen: () => {
                    const modal = this.popoverElement.closest('ui-modal');
                    modal && modal.setAttribute('aria-hidden', 'true');
                },
                onClose: () => {
                    this.closeDetachedPopover();
                },
            },
        });
    }

    /**
     * It closes detached popover and marks the annotation as read
     */
    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        const modal = this._detachedPopover.closest('ui-modal');
        if (modal) {
            this._detachedPopover = null;
            modal.close();
        }
    }

    /**
     * Closes popover element.
     */
    async closePopover() {
        this.closeDetachedPopover();
        if (this.intersectionObserver) {
            this.intersectionObserver.disconnect();
        }
        await this.service.dismiss(this.contentId);
        this.remove();
    }

    /**
     * @private
     */
    observeIntersection() {
        if (!this.intersectionObserver) {
            if (!this.annotatedElement) {
                return;
            }
            this.intersectionObserver = new IntersectionObserver(
                (entries) => {
                    const entry = entries[0];
                    const elIsVisible = isVisible(this.annotatedElement);
                    if (entry.isIntersecting && elIsVisible) {
                        this.showAnnotation(
                            UIAnnotation.MIN_DELAY,
                            UIAnnotation.MAX_DELAY
                        );
                    } else if (!elIsVisible) {
                        this.hideAnnotation();
                    }
                },
                { root: null }
            );
            this.intersectionObserver.observe(this.annotatedElement);
        }
    }

    /**
     * @returns {boolean}
     */
    checkIntersection() {
        if (!this.annotatedElement) {
            return false;
        }
        if (this.isAnnotatedElementVisible()) {
            this.showAnnotation(0, UIAnnotation.MAX_DELAY);
            return true;
        } else {
            this.hideAnnotation();
        }
        return false;
    }

    /**
     * Shows annotation.
     * @param {number} minDelay
     * @param {number} maxDelay
     * @returns {boolean}
     */
    showAnnotation(minDelay, maxDelay) {
        if (this.active || this.locked) {
            return false;
        }
        this.active = true;
        window.requestAnimationFrame(() => {
            if (isVisible(this.annotatedElement)) {
                const ms = minDelay + Math.ceil(Math.random() * maxDelay);
                this.popoverElement.style.setProperty(
                    '--ui-annotation-show',
                    `${ms}ms`
                );
                this.openDetachedPopover();
            }
        });
        return true;
    }

    /**
     * Hides annotation.
     */
    hideAnnotation() {
        this.active = false;
        this.popoverElement.style.setProperty(
            '--ui-annotation-show',
            `${UIAnnotation.CLOSE_DELAY}ms`
        );
        this.closeDetachedPopover();
    }

    /**
     * Damps/pulls annotation to the back.
     * @param {number|string} zIndex
     */
    damp(zIndex) {
        /** @type {UIModal} */
        const modal = this.popoverElement.closest('ui-modal');
        if (modal) {
            modal.dataset.oldZIndex = modal.style.zIndex;
            modal.style.zIndex = `${--zIndex}`;
            modal.classList.add('-damped');
        }
    }

    /**
     * Reveals/pushes annotation to the front.
     */
    reveal() {
        /** @type {UIModal} */
        const modal = this.popoverElement.closest('ui-modal');
        if (modal) {
            modal.style.zIndex = modal.dataset.oldZIndex;
            delete modal.dataset.oldZIndex;
            modal.classList.remove('-damped');
        }
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.closeDetachedPopover();
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('aria-hidden', 'true');
        this.insertElements([
            {
                tagName: 'div',
                classList: {
                    'ui-annotation__popover': true,
                },
                children: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: 'ui-annotation__content',
                        },
                        children: [...this.childNodes],
                    },
                    {
                        tagName: 'button',
                        attributes: {
                            type: 'button',
                            title: this.labelClose || UIAnnotation.labels.close,
                            tabindex: TabIndex.Inactive,
                        },
                        classList: {
                            'ui-annotation__close': true,
                            '-iconed': true,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'cross',
                                    color: UIIcon.colors.DEFAULT,
                                },
                            },
                        ],
                    },
                    {
                        tagName: 'div',
                        classList: {
                            'ui-annotation__pointer': true,
                        },
                    },
                ],
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        appendModalControllerIfRequired();
        this.close.addEventListener('click', this.closePopover.bind(this));
        this.service.isRead(this.contentId).then((read) => {
            if (!read) {
                requestAnimationFrame(() => this.checkIntersection());
                this.observeIntersection();
            }
        });
    }
}

UIAnnotation.defineElement('ui-annotation', styles);
export { UIAnnotation };

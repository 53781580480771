import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import {
    closestByCond,
    dispatchCustomEvent,
    isParentOf,
    isVisible,
    updateElement,
    PRIMARY_INTERACTIVE_ELEMENTS,
    SECONDARY_INTERACTIVE_ELEMENTS,
    insertElements,
} from '../../global/ui-helpers.js';
import { TabIndex } from '../../global/keyboard.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-modal.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @implements {IActivatable}
 * @alias UIModal
 * @element ui-modal
 * @classdesc Represents a class for <code>ui-modal</code> element
 * @fires event:modal-open-enter
 * @fires event:modal-close
 * @fires event:modal-close-enter
 * @fires event:modal-action
 * @fires event:modal-updatelist
 * @property {("page"|"alert"|"confirmation"|"popover"|"bubble"|"element")} type
 * {@attr type} The type of modal window.
 *  {@desc page: common page modal}
 *  {@desc alert: alert modal}
 *  {@desc confirmation: modal with yes/no actions}
 *  {@desc popover: represents popover element like tooltip or annotation}
 *  {@desc bubble: represents modal as bubble like floating element}
 *  {@desc element: uses element as modal}
 * @property {boolean} [detached] {@attr detached} Modal will be added to
 * the end of the body and after close will be returned.
 * @property {("fluid" | "logout")} [layout] {@attr layout} Layout of the modal dialog window.
 *  {@desc fluid: fluid modal layout, stretched to the size of content}
 *  {@desc logout: Used only for logout windows.}
 * @property {boolean} [compact] {@attr compact} Indicate should the cross button be hidden
 * @property {boolean} [shifted] {@attr shifted} Indicate should the modal be shifted
 * (Is used for multiple windows).
 * @property {boolean} [dynamic] {@attr dynamic}
 * @property {boolean} [closable] {@attr closable} Defines if modal window could be closed using
 * by clicking on overlay.
 * @property {boolean} [animation] {@attr animation} true - to force animation to be played
 * @property {number} openTimeout {@attr open-timeout} timeout for animation to play
 * @property {string} [labelAccept="Yes"] {@attr label-accept} text label for the accept button
 * (Is used for confirmation windows).
 * @property {string} [labelDecline="No"] {@attr label-decline} text label for the decline button
 * (Is used for confirmation windows).
 * @property {string} [labelDismiss="Ok"] {@attr label-dismiss} text label for the dismiss button
 * (Is used for alerts).
 * @property {number} order {@attr order} {@readonly} Z-order of the modal, this property
 * is set automatically.
 * @property {HTMLDivElement} backdrop {@readonly}
 * @property {HTMLButtonElement} closeAction {@readonly}
 * @property {UIButtonbar} buttonBar {@readonly}
 * @property {HTMLDivElement} modalWindow {@readonly}
 * @property {HTMLDivElement} modalBody {@readonly}
 * @slot
 * @example
 * <div>
 *   <ui-modal-controller></ui-modal-controller>
 *   <ui-modal id="super-modal">
 *     <h2>I am modal title</h2>
 *     <p>How are you?</p>
 *   </ui-modal>
 *   <button class="button" onclick="SharedComponents.eventBus.dispatchCustomEvent(
 *     'modal-open', { selector: '#super-modal'});">Toggle</button>
 * </div>
 */
class UIModal extends UIElement {
    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-modal', {
            accept: 'Yes',
            decline: 'No',
            dismiss: 'OK',
            close: 'Close',
        });
    }

    /**
     * Provides list of observed attributes to be watched
     */
    static get observedAttributes() {
        return ['order'];
    }

    /**
     * @type {number}
     */
    static get OPEN_TIMEOUT() {
        return 300;
    }

    /**
     * @type {number}
     */
    static get DEFAULT_ZINDEX() {
        return 899;
    }

    /**
     * @type {UIModalTypes}
     */
    static get types() {
        return {
            PAGE: 'page',
            CONFIRMATION: 'confirmation',
            ALERT: 'alert',
            POPOVER: 'popover',
            BUBBLE: 'bubble',
            ELEMENT: 'element',
        };
    }

    /**
     * Provides getter and setter for "type" property and link it to the "type" attribute
     */
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                type: String,
                align: String,
                detached: Boolean,
                order: { type: Number, default: 1 },
                compact: Boolean,
                closable: { type: Boolean, default: true },
                layout: String,
                labelAccept: String,
                labelDecline: String,
                labelDismiss: String,
                animation: Boolean,
                openTimeout: { type: Number, default: UIModal.OPEN_TIMEOUT },
            },
            children: {
                backdrop: '.ui-modal__backdrop',
                closeAction: '.ui-modal__close-action',
                buttonBar: '.ui-modal__buttons',
                modalWindow: '.ui-modal__window',
                modalBody: '.ui-modal__body',
            },
        };
    }
    get shifted() {
        return this.classList.contains('-shifted');
    }
    set shifted(value) {
        this.updateClassList({ '-shifted': value });
    }

    get locked() {
        return this.classList.contains('-locked');
    }
    set locked(value) {
        this.updateClassList({ '-locked': value });
    }

    /**
     * Check if the current modal is active.
     * @returns {boolean}
     */
    isActive() {
        return this.classList.contains('-active');
    }

    /**
     * Check if current modal is animated. All modals windows are animatable
     * expect those who has type 'popover' (tooltips, dropdown, etc.).
     * @returns {boolean}
     */
    isAnimated() {
        if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            return false;
        }
        return this.type !== UIModal.types.POPOVER || this.animation;
    }

    /**
     * Opens modal window.
     * Use this function for opening modals with the order that equals
     * to the modals order location inside DOM or just for the only one modal on the page.
     * Use 'ui-modal-controller' with 'modal-open' event to follow the right order for several modals.
     * Try to avoid mixing 'modal-open' functionality of 'ui-modal-controller' with this.
     * @fires event:modal-close-enter Fires then modal is just starting to open.
     * @returns {UIModal}
     */
    open() {
        this.dispatchCustomEvent('modal-open-enter');
        if (!this.isConnected && this.hasAttribute('dynamic')) {
            insertElements(document.body, [this]);
        }
        if (!this.isAnimated()) {
            this.updateClassList({
                '-active': true,
                '-presented': true,
            });
            this.onOpen();
        } else {
            this.updateClassList({ '-active': true });
            this.modalWindow['offsetWidth'].toString();
            window.requestAnimationFrame(() => {
                this.updateClassList({ '-presented': true });
                setTimeout(() => this.onOpen(), this.openTimeout);
            });
        }
        return this;
    }

    /**
     * Closes modal window.
     * @fires event:modal-close-enter
     * @returns {UIModal}
     */
    close() {
        this.dispatchCustomEvent('modal-close-enter');
        if (!this.isAnimated()) {
            this.updateClassList({
                '-presented': false,
                '-active': false,
            });
            this.onClose();
        } else {
            this['offsetWidth'].toString();
            window.requestAnimationFrame(() => {
                this.updateClassList({ '-presented': false });
                setTimeout(() => this.onClose(), this.openTimeout);
            });
        }
        return this;
    }

    /**
     * Close action callback.
     * @private
     */
    handleCloseAction() {
        if (!this.closable) {
            return;
        }
        this.dispatchCustomEvent('modal-close', { dismiss: true });
    }

    /**
     * Accept action callback.
     * @private
     */
    handleAcceptAction() {
        this.dispatchCustomEvent('modal-action', { type: 'accept' });
    }

    /**
     * Decline action callback.
     * @private
     */
    handleDeclineAction() {
        this.dispatchCustomEvent('modal-action', { type: 'decline' });
    }

    /**
     * Decline action callback.
     * @private
     */
    onOpen() {
        this.dispatchCustomEvent('modal-updatelist', { action: 'add' });
    }

    /**
     * Decline action callback.
     * @private
     */
    onClose() {
        this.updateClassList({ '-active': false });
        this.dispatchCustomEvent('modal-updatelist', { action: 'remove' });
        if (this.hasAttribute('dynamic')) {
            this.parentElement.removeChild(this);
        } else {
            this.setAttributes({ style: null, order: '1' });
        }
    }

    /**
     * @returns {Array<HTMLElement>}
     */
    getInteractiveElements() {
        const targets = []
            .concat(PRIMARY_INTERACTIVE_ELEMENTS)
            .concat(SECONDARY_INTERACTIVE_ELEMENTS)
            .join(',');

        const elements = [].filter.call(
            this.modalBody.querySelectorAll(targets),
            (node) => {
                return isVisible(node);
            }
        );

        if (this.closeAction) {
            elements.push(this.closeAction);
        }

        return elements;
    }

    /**
     * Prevents the body scrolling for iOS devices.
     */
    disableBodyScroll() {
        const modalBody = this.querySelector('.ui-modal__body');

        const isElementHorizontallyScrolled = (element, shiftX) => {
            if (
                element.clientWidth >= element.scrollWidth ||
                (element.scrollLeft === 0 && shiftX > 0)
            ) {
                return false;
            }
            const scrolledX =
                element.scrollWidth - element.scrollLeft <= element.clientWidth;
            return !(scrolledX && shiftX < 0);
        };

        const isElementVerticallyScrolled = (element, shiftY) => {
            if (
                element.clientHeight >= element.scrollHeight ||
                (element.scrollTop === 0 && shiftY > 0)
            ) {
                return false;
            }
            const scrolledY =
                element.scrollHeight - element.scrollTop <=
                element.clientHeight;
            return !(scrolledY && shiftY < 0);
        };

        const cancelEvent = (event) => {
            if (!event.cancelable) {
                return;
            }
            try {
                event.preventDefault();
            } catch (e) {
                // no luck, scroll in progress etc...
            }
        };

        this.addEventListener(
            'touchstart',
            (event) => {
                if (event.targetTouches.length !== 1) {
                    return;
                }
                this._clientY = event.targetTouches[0].clientY;
                this._clientX = event.targetTouches[0].clientX;
            },
            {
                passive: true,
                capture: false,
            }
        );

        this.addEventListener(
            'touchmove',
            (event) => {
                if (event.targetTouches.length !== 1) {
                    return;
                }
                if (!isParentOf(modalBody, event.target)) {
                    cancelEvent(event);
                    return;
                }
                const shiftY = event.targetTouches[0].clientY - this._clientY;
                const shiftX = event.targetTouches[0].clientX - this._clientX;
                if (Math.abs(shiftY) >= Math.abs(shiftX)) {
                    const yScrollableTarget = closestByCond(
                        event.target,
                        (node) => {
                            return (
                                node === modalBody ||
                                node.clientHeight < node.scrollHeight
                            );
                        }
                    );
                    if (
                        !isElementVerticallyScrolled(yScrollableTarget, shiftY)
                    ) {
                        cancelEvent(event);
                    }
                } else {
                    const xScrollableTarget = closestByCond(
                        event.target,
                        (node) => {
                            return (
                                node === modalBody ||
                                node.clientWidth < node.scrollWidth
                            );
                        }
                    );
                    if (
                        !isElementHorizontallyScrolled(
                            xScrollableTarget,
                            shiftX
                        )
                    ) {
                        cancelEvent(event);
                    }
                }
            },
            {
                passive: false,
                capture: false,
            }
        );
    }

    /**
     * Changes current view to active (open) state.
     * @returns {UIModal}
     */
    activate() {
        return this.open();
    }

    /**
     * Checks if modal is annotation.
     * @returns {boolean}
     */
    isAnnotation() {
        return this.classList.contains('-annotation');
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'order':
                if (this.order > 1) {
                    this.style.zIndex = String(
                        UIModal.DEFAULT_ZINDEX + this.order
                    );
                }
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttributes({
            role: 'dialog',
            'aria-modal': 'true',
            tabindex: TabIndex.Inactive,
        }).insertElements([
            {
                tagName: 'div',
                attributes: { class: 'ui-modal__backdrop' },
            },
            {
                tagName: 'div',
                classList: {
                    'ui-modal__window': true,
                    '-alert': this.type === UIModal.types.ALERT,
                    '-confirmation': this.type === UIModal.types.CONFIRMATION,
                },
                children: [
                    !this.compact &&
                        ![UIModal.types.POPOVER, UIModal.types.BUBBLE].includes(
                            this.type
                        ) && {
                            tagName: 'button',
                            attributes: {
                                class: 'ui-modal__close-action -iconed',
                                type: 'button',
                                'aria-label': UIModal.labels.close,
                            },
                            children: [
                                {
                                    tagName: 'ui-icon',
                                    attributes: {
                                        glyph: 'cross',
                                        color: UIIcon.colors.DEFAULT,
                                    },
                                },
                            ],
                        },
                    {
                        tagName: 'div',
                        attributes: {
                            class: 'ui-modal__body',
                        },
                        children: this.childNodes,
                    },
                ],
            },
        ]);

        if (this.type === UIModal.types.ALERT) {
            this.setAttributes({ role: 'alertdialog' });
            this.querySelector('.ui-modal__body').appendChild(
                this.createElement({
                    tagName: 'ui-buttonbar',
                    classList: {
                        'ui-modal__buttons': true,
                    },
                    children: [
                        {
                            tagName: 'button',
                            attributes: {
                                class: 'button -dismiss',
                                type: 'button',
                            },
                            children: [
                                this.labelDismiss || UIModal.labels.dismiss,
                            ],
                        },
                    ],
                })
            );
        }

        if (this.type === UIModal.types.CONFIRMATION) {
            this.querySelector('.ui-modal__body').appendChild(
                this.createElement({
                    tagName: 'ui-buttonbar',
                    classList: {
                        'ui-modal__buttons': true,
                    },
                    children: [
                        {
                            tagName: 'button',
                            attributes: {
                                class: 'button -destructive -decline',
                                type: 'button',
                            },
                            children: [
                                this.labelDecline || UIModal.labels.decline,
                            ],
                        },
                        {
                            tagName: 'button',
                            attributes: {
                                class: 'button -accept',
                                type: 'button',
                            },
                            children: [
                                this.labelAccept || UIModal.labels.accept,
                            ],
                        },
                    ],
                })
            );
        }

        this.updateClassList({
            '-shifted': this.shifted,
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleCloseAction = this.handleCloseAction.bind(this);
        this.handleAcceptAction = this.handleAcceptAction.bind(this);
        this.handleDeclineAction = this.handleDeclineAction.bind(this);

        if (this.type !== UIModal.types.POPOVER) {
            updateElement(this.backdrop, {
                events: {
                    click: this.handleCloseAction,
                },
            });
        }

        if (this.closeAction) {
            updateElement(this.closeAction, {
                events: {
                    click: this.handleCloseAction,
                },
            });
        }

        if (this.type === UIModal.types.ALERT) {
            updateElement(this.buttonBar.querySelector('button.-dismiss'), {
                events: {
                    click: this.handleCloseAction,
                },
            });
        }

        if (this.type === UIModal.types.CONFIRMATION) {
            updateElement(this.buttonBar.querySelector('button.-accept'), {
                events: {
                    click: this.handleAcceptAction,
                },
            });
            updateElement(this.buttonBar.querySelector('button.-decline'), {
                events: {
                    click: this.handleDeclineAction,
                },
            });
        }

        this.disableBodyScroll();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        if (this.isActive()) {
            dispatchCustomEvent(document.body, 'modal-updatelist', {
                action: 'remove',
            });
        }
    }
}

UIModal.defineElement('ui-modal', styles);
export { UIModal };

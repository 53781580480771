import { UIElement } from '../ui-element.js';
import styles from './ui-layout-content.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UILayoutContent
 * @element ui-layout-content
 * @classdesc Represents a class for <code>ui-layout-content</code> element.
 * @example
 * <ui-layout-content>
 *     <section class="frame">Content</section>
 * </ui-layout-content>
 */
class UILayoutContent extends UIElement {
    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'main');
    }
}
UILayoutContent.defineElement('ui-layout-content', styles);
export { UILayoutContent };

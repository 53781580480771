import { UIElement } from '../ui-element.js';
import styles from './ui-brandingbar.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIBrandingBar
 * @element ui-brandingbar
 * @classdesc Represents a class for <code>ui-brandingbar</code> element.
 * The most top orange bar on the every page. Button bar cannot be animated
 * or used as progress bar by branding rules.
 * @property {boolean} [animated] {@attr animated} Loading animation enabled/disabled
 * when '-loading' class added to <html>.
 * @example <ui-brandingbar></ui-brandingbar>
 */
class UIBrandingBar extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                animated: String,
            },
        };
    }
}

UIBrandingBar.defineElement('ui-brandingbar', styles);
export { UIBrandingBar };

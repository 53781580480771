import {
    PRIMARY_INTERACTIVE_ELEMENTS,
    SECONDARY_INTERACTIVE_ELEMENTS,
} from './constants.js';
import { dispatchCustomEvent } from './events.js';
import {
    insertElements,
    revertClassList,
    updateClassList,
} from './render-api.js';
import { tagRegistry } from './tag-registry.js';
import { TabIndex } from './keyboard.js';

export * from './config.js';
export * from './mutations.js';
export * from './reactive-elements.js';
export * from './events.js';
export * from './render-api.js';
export * from './template-literals.js';
export * from './loading-indicator.js';
export * from './constants.js';
export * from './tag-registry.js';

/**
 * @memberof SharedComponents
 * @class UiHelpers
 * @alias uiHelpers
 * @classdesc
 * Helpers for manipulating DOM.
 */

/**
 * Creates style element from string and adds to head.
 * @memberof UiHelpers
 * @deprecated Used as fallback for adoptStyle(), use adoptStyle() method.
 * @param {string} cssText
 * @param {InsertPosition} [position] Position for style tag.
 * @param {string} [id] id attribute for style.
 * @param {HTMLElement} [placement] Default is HTMLHeadElement
 * @returns {HTMLStyleElement}
 */
const addStyle = (cssText, position = 'beforeend', id, placement) => {
    placement = placement || document.head;
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    if (id) {
        const exists = document.querySelector(`style#${id}`);
        if (exists) {
            return exists;
        }
        style.setAttribute('id', id);
    }

    if (placement instanceof HTMLHeadElement) {
        switch (position) {
            case 'beforebegin':
                position = 'afterbegin';
                break;
            case 'afterend':
                position = 'beforeend';
                break;
        }
    }

    placement.insertAdjacentElement(position, style);

    style.appendChild(document.createTextNode(cssText));
    return style;
};

/**
 * Adopts styles to the document.
 * @memberof UiHelpers
 * @param {string} cssText
 * @param {string} [id] needed for fallback if document.adoptedStyleSheets is not
 * supported by browser.
 * @returns {Promise<void>}
 */
const adoptStyle = (cssText, id = '') => {
    if (document.adoptedStyleSheets) {
        const stylesheet = new CSSStyleSheet({ media: 'all' });
        return stylesheet
            .replace(cssText)
            .then(
                () =>
                    (document.adoptedStyleSheets = [
                        ...document.adoptedStyleSheets,
                        stylesheet,
                    ])
            )
            .catch((err) => err);
    } else {
        // #fallback, check time-to-time when it can be removed.
        addStyle(cssText, 'afterend', id);
        return Promise.resolve(void 0);
    }
};

/**
 * Search the closest parent node matched by given predicate.
 * @memberof UiHelpers
 * @example
 * // Search the closest parent node with "id" equals "foo"
 * closestByCond(element, function (node) {
 *     return node.id === "id"
 * });
 * @param {HTMLElement | ParentNode} element
 * @param {Function} searchPredicate - the callback should return true value if node is matched
 * @param {Function} [stopPredicate] - if the callback returns true then search will be stopped
 * @returns { null | HTMLElement }
 */
const closestByCond = (element, searchPredicate, stopPredicate) => {
    const searchCallback = searchPredicate || (() => true);
    const stopCallback = stopPredicate || (() => false);
    while (element) {
        if (searchCallback(element)) {
            return element;
        }
        if (stopCallback(element)) {
            return null;
        }
        element = element.parentNode;
    }
    return null;
};

/**
 * Gets next siblings by conditions.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {Function} searchPredicate
 * @returns { null | HTMLElement}
 */
const nextByCond = (element, searchPredicate) => {
    const searchCallback = searchPredicate || (() => true);
    let nextElem = element.nextElementSibling;

    while (nextElem) {
        if (searchCallback(nextElem)) {
            return nextElem;
        }
        nextElem = nextElem.nextElementSibling;
    }
    return null;
};

/**
 * Gets previous sibling by conditions.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {Function} searchPredicate
 * @returns {null | HTMLElement}
 */
const previousByCond = (element, searchPredicate) => {
    const searchCallback = searchPredicate || (() => true);
    let prevElem = element.previousElementSibling;

    while (prevElem) {
        if (searchCallback(prevElem)) {
            return prevElem;
        }
        prevElem = prevElem.previousElementSibling;
    }
    return null;
};

/**
 * Returns closest node with attribute 'data-event'
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {Node} [stopNode]
 * @returns {HTMLElement}
 */
const closestEventHolder = (element, stopNode) => {
    return closestByCond(
        element,
        (node) =>
            node.nodeType === Node.ELEMENT_NODE &&
            node.hasAttribute('data-event'),
        (node) => stopNode && node === stopNode
    );
};

/**
 * Returns the closest node with tag name
 * @deprecated User native Element.closest() method.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {string} tagName
 * @param {Node} [stopNode]
 * @returns {HTMLElement}
 */
const closestTag = (element, tagName, stopNode) => {
    return closestByCond(
        element,
        (node) => node.nodeName.toLowerCase() === tagName,
        (node) => stopNode && node === stopNode
    );
};

/**
 * Detaches child nodes from element
 * @memberof UiHelpers
 * @param {Node} element
 * @returns {Array<HTMLElement>}
 * @protected
 */
const detachChildNodes = (element) => {
    return [].slice.call(element.childNodes).map((node) => {
        element.removeChild(node);
        return node;
    });
};

/**
 * Focus first interactive element inside of the node. If the focus is already on the any
 * node's child, then focus will not be changed.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {boolean} [skipPriorities]
 * @returns {Element}
 */
const focusInteractiveElement = (element, skipPriorities = false) => {
    const active = document.activeElement;
    if (active && (active === element || element.contains(active))) {
        // Already has focused element
        return element;
    }

    let target;
    if (skipPriorities) {
        target = element.querySelector(
            PRIMARY_INTERACTIVE_ELEMENTS.concat(
                SECONDARY_INTERACTIVE_ELEMENTS
            ).join(', ')
        );
    } else {
        target = element.querySelector(PRIMARY_INTERACTIVE_ELEMENTS.join(', '));
        if (!target) {
            target = element.querySelector(
                SECONDARY_INTERACTIVE_ELEMENTS.join(', ')
            );
        }
    }

    if (target) {
        target.focus();
    }
    return element;
};

/**
 * Returns elements for slot
 * @memberof UiHelpers
 * @param {Element} element
 * @param {string} key
 * @returns {Array<HTMLElement>}
 * @protected
 */
const getChildrenForSlot = (element, key) => {
    return [].filter
        .call(element.children, (node) => {
            return node.getAttribute('slot') === key;
        })
        .map((node) => {
            node.removeAttribute('slot');
            node.parentElement.removeChild(node);
            return node;
        });
};

/**
 * Returns the list of focusable elements for the given node.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @returns {Array<HTMLElement> | *}
 */
const getFocusableElements = (element) => {
    const targets = element.querySelectorAll(
        PRIMARY_INTERACTIVE_ELEMENTS.concat(
            SECONDARY_INTERACTIVE_ELEMENTS
        ).join(', ')
    );
    return [].slice.call(targets, 0).filter((node) => isVisible(node));
};

/**
 * Returns the first focusable element for the given node.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @returns {HTMLElement | null}
 */
const getFirstFocusableElement = (element) => {
    return element.querySelector(
        PRIMARY_INTERACTIVE_ELEMENTS.concat(
            SECONDARY_INTERACTIVE_ELEMENTS
        ).join(',')
    );
};

/**
 * Add class -hidden to hide the element.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
const hide = (element) => {
    element.classList.add('-hidden');
    return element;
};

/**
 * Checks if document is ready for Web Components to be rendered.
 * @memberof UiHelpers
 * @returns {boolean}
 */
const isDOMReady = () =>
    /complete|interactive|loaded/.test(document.readyState);

/**
 * Checks if the element is parent of given node.
 * @memberof UiHelpers
 * @param {HTMLElement} parent element
 * @param {Element} node - given node
 * @returns {boolean} - true if current node is parent to given node
 */
const isParentOf = (parent, node) => node === parent || parent.contains(node);

/**
 * @param {HTMLElement} element
 * @memberof UiHelpers
 * Checks if the element is visible.
 * @returns {boolean} true if visible
 */
const isVisible = (element) => {
    return !!(
        element.offsetWidth ||
        element.offsetHeight ||
        element.getClientRects().length
    );
};

/**
 * Returns the node's position in the parent DOM tree.
 * @memberof UiHelpers
 * @param {Element} element
 * @returns {number}
 */
const position = (element) => {
    return [].slice.call(element.parentElement.children).indexOf(element);
};

/**
 * Finds direct children matched by condition.
 * @memberof UiHelpers
 * @param {Element} element
 * @param {string} selector
 * @returns {Array<HTMLElement>}
 */
const queryChildren = (element, selector) => [
    ...element.querySelectorAll(`:scope > ${selector}`),
];

/**
 * HTML parser needs to know the children's data.
 * So we will re-detach them to make browser renders them first.
 * @memberof UiHelpers
 * @param {Element} element
 * @protected
 */
const redetachChildNodes = (element) => {
    insertElements(element, detachChildNodes(element));
};

/**
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {Record<string, boolean>} config - hash map where key is classname value is flag
 * @param {number} timeout
 * @returns {Promise<void>}
 */
const runTransition = (element, config, timeout) => {
    const prepareTransition = () => {
        return new Promise((resolve) => {
            updateClassList(element, config);
            element.setAttribute('data-transition', 'true');
            requestAnimationFrame(() => {
                updateClassList(element, {
                    '-animating': true,
                });
                resolve();
            });
        });
    };

    const waitForTransitionEnd = (timeout) => {
        return new Promise((resolve) => {
            let completed = false;
            let timer = null;
            const complete = () => {
                if (completed) {
                    return;
                }
                completed = true;
                if (transitionHandler) {
                    element.removeEventListener(
                        'transitionend',
                        transitionHandler
                    );
                }
                if (timer) {
                    clearTimeout(timer);
                }
                resolve();
            };
            timer = setTimeout(complete, timeout || 500);
            const transitionHandler = (e) => {
                if (completed || e.target !== element) {
                    return;
                }
                complete();
            };
            element.addEventListener('transitionend', transitionHandler);
        });
    };

    return prepareTransition()
        .then(() => waitForTransitionEnd(timeout))
        .then(() => {
            updateClassList(element, {
                '-animating': false,
            });
            revertClassList(element, config);
            element.removeAttribute('data-transition');
        });
};

/**
 * Remove class -hidden to show the element.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
const show = (element) => {
    element.classList.remove('-hidden');
    return element;
};

/**
 * Adds DOMLoaded handler.
 * @memberof UiHelpers
 * @param {Function} callback Callback function.
 * @param {boolean} [isSync] Is rendering synchronous.
 */
const whenDOMReady = (callback, isSync) => {
    if (typeof callback !== 'function') {
        return;
    }
    const done = () => {
        if (isSync) {
            callback();
        } else {
            requestAnimationFrame(callback);
        }
    };
    if (isDOMReady()) {
        done();
    } else {
        document.addEventListener('DOMContentLoaded', done, false);
    }
};

/**
 * @typedef {object} ICustomElementOptions
 * @property {Array<string> | string} [styles] styles to be added to the document
 * @property {HTMLElement} [stylePlacement] where to place the styles
 */

/**
 * Defines custom element
 * @memberof UiHelpers
 * @param {string} tag
 * @param {CustomElementConstructor} constructor
 * @param {ICustomElementOptions} [opts]
 */
const defineElement = (tag, constructor, opts = {}) => {
    if (customElements.get(tag)) {
        return;
    }
    customElements.define(tag, constructor);
    tagRegistry.set(constructor, tag);

    let styles = opts.styles;
    if (styles) {
        if (Array.isArray(styles)) {
            styles = styles.join('');
        }
        adoptStyle(styles, `${tag}-style`).catch((err) => console.error(err));
    }
};

/**
 * Merge two attributes objects.
 * @memberof UiHelpers
 * @param {IAttributeProps | NamedNodeMap} origin
 * @param {IAttributeProps | NamedNodeMap} update
 * @returns {IAttributeProps | NamedNodeMap}
 */
const mergeAttributes = (origin, update) => {
    const source = update || {};
    return Object.keys(source).reduce((result, key) => {
        result[key] = source[key];
        return result;
    }, origin || {});
};

/**
 * Smoothly remove node from DOM tree.
 * @memberof UiHelpers
 * @param  {UIElement | HTMLElement} node
 * @param {Record<string, boolean>} config - hash map where key is classname value is flag
 * @param {number} timeout
 * @returns {Promise<void>}
 */
const runSmoothRemove = (node, config, timeout) => {
    if (!node.parentElement) {
        return Promise.resolve();
    }
    config['-removing'] = true;
    return runTransition(node, config, timeout).then(() => {
        if (node.parentElement) {
            node.parentElement.removeChild(node);
        }
    });
};

/**
 * Smoothly append the NODE to target element.
 * @memberof UiHelpers
 * @param  {UIElement | HTMLElement} node
 * @param {HTMLElement} target
 * @param {Record<string, boolean>} config - hash map where key is classname value is flag
 * @param {number} timeout
 * @returns {Promise<void>}
 */
const runSmoothAppend = (node, target, config, timeout) => {
    hide(node);
    target.appendChild(node);
    return new Promise((resolve) => {
        config['-inserting'] = true;
        requestAnimationFrame(resolve);
    })
        .then(() => runTransition(node, config, timeout))
        .then(() => show(node));
};

/**
 * Get object data from the elements nested controls, where the name attribute is a key property.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @returns {Record<string, string>}
 */
const getData = (element) => {
    const selectors = [
        'select[name]',
        'textarea[name]',
        'input[name]:not([type=radio]):not([type=checkbox])',
        'input[name][type=radio]:checked',
        'input[name][type=checkbox]:checked',
    ];
    const controls = element.querySelectorAll(selectors.join(', '));

    return /** @type {Record<string, string>} */ [].reduce.call(
        controls,
        (data, node) => {
            data[node.getAttribute('name')] = node.value;
            return data;
        },
        {}
    );
};

/**
 * Set object data to the elements nested controls, where the name attribute is a key property.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {Record<string, string | null>} data Data to be applied to the node.
 * @returns {HTMLElement}
 */
const setData = (element, data) => {
    const updatedNodes = [];
    if (typeof data === 'object' && !Array.isArray(data)) {
        Object.keys(data || {}).forEach((key) => {
            [].forEach.call(
                element.querySelectorAll('[name=' + key + ']'),
                (node) => {
                    const isInput = node.nodeName.toLowerCase() === 'input';
                    if (isInput && node.getAttribute('type') === 'checkbox') {
                        node.checked = Boolean(data[key]);
                    } else if (
                        isInput &&
                        node.getAttribute('type') === 'radio'
                    ) {
                        node.checked = data[key] === node.value;
                    } else {
                        node.value = data[key];
                    }
                    updatedNodes.push(node);
                }
            );
        });
        dispatchCustomEvent(element, 'form-update', {
            updatedNodes: updatedNodes,
        });
    }
    return element;
};

/**
 * Check if given element is a form element.
 * @memberof UiHelpers
 * @param {HTMLElement} elem
 * @returns {boolean}
 */
const isFormElement = (elem) => {
    return ['input', 'select', 'textarea'].includes(elem.localName);
};

/**
 * Check if given element has synthetic focus visible
 * @memberof UiHelpers
 * @param {HTMLElement} node
 * @returns {boolean}
 */
const hasSyntheticFocusVisible = (node) => {
    return node.classList.contains('-focus-visible');
};

/**
 * Add synthetic focus visible state to given element
 * @memberof UiHelpers
 * @param {HTMLElement} node
 */
const addSyntheticFocusVisible = (node) => {
    if (!hasSyntheticFocusVisible(node)) {
        node.classList.add('-focus-visible');
        const blurHandler = () => {
            node.removeEventListener('blur', blurHandler);
            node.classList.remove('-focus-visible');
        };
        node.addEventListener('blur', blurHandler);
    }
    node.focus();
};

/**
 * @param {string} controlId
 * @memberof UiHelpers
 * @param {string} baseClass
 * @returns {IElementConfig}
 */
const buildSyntheticFocusControl = (controlId, baseClass) => {
    return {
        tagName: 'button',
        attributes: {
            type: 'button',
            class: baseClass + '__focus-trigger -visually-hidden',
            tabindex: TabIndex.Inactive,
            id: controlId,
            // #pally
            // 'aria-label': 'virtual'
        },
    };
};

/**
 * Mapping theme name with the presentational color in dropdown for preview.
 * Put all themes here that are active.
 * @memberof UiHelpers
 * @type {Record<string, string>}
 */
const Themes = {
    'theme-default': 'var(--ui-color-orange)',
};

/**
 * Sets the theme and keep in localStorage. To unset the theme pass emtpy
 * string or null as first argument.
 * @memberof UiHelpers
 * @param {string|null} theme
 * @param {Element} [root]
 */
const setTheme = (theme, root = document.documentElement) => {
    const storageKey = 'theme';
    if (!theme || theme.includes('default')) {
        // skip default
        root.classList.forEach((t) => {
            if (t.match(/^theme-/)) {
                root.classList.remove(t);
            }
        });
        window.localStorage.removeItem(storageKey);
        return;
    }
    if (!theme.match(/^theme-/)) {
        theme = `theme-${theme}`;
    }
    root.classList.add(theme);
    window.localStorage.setItem(storageKey, theme);
};

/**
 * Gets current design theme. If no theme returns empty string.
 * @memberof UiHelpers
 * @returns {string}
 */
const getTheme = () => {
    const theme = window.localStorage.getItem('theme');
    return theme ? theme.trim() : '';
};

/**
 * Checks that click was done outside of element's rect.
 * @param {HTMLElement} element
 * @param {MouseEvent | PointerEvent} mouseEvent
 * @returns {boolean}
 */
const doesClickedInsideRect = (element, mouseEvent) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.y <= mouseEvent.clientY &&
        mouseEvent.clientY <= rect.y + rect.height &&
        rect.x <= mouseEvent.clientX &&
        mouseEvent.clientX <= rect.x + rect.width
    );
};

export {
    buildSyntheticFocusControl,
    hasSyntheticFocusVisible,
    addSyntheticFocusVisible,
    addStyle,
    adoptStyle,
    nextByCond,
    previousByCond,
    closestByCond,
    closestEventHolder,
    closestTag,
    defineElement,
    detachChildNodes,
    focusInteractiveElement,
    getChildrenForSlot,
    getFirstFocusableElement,
    getFocusableElements,
    hide,
    isDOMReady,
    isParentOf,
    isVisible,
    position,
    queryChildren,
    redetachChildNodes,
    runTransition,
    show,
    whenDOMReady,
    mergeAttributes,
    runSmoothAppend,
    runSmoothRemove,
    getData,
    setData,
    isFormElement,
    Themes,
    getTheme,
    setTheme,
    doesClickedInsideRect,
};

import { UITable } from '../../components/table/ui-table.js';
import { duplicateIndexSetter } from '../../global/helpers.js';
import { Labels } from '../../global/labels.js';
import { closestByCond, dispatchCustomEvent } from '../../global/ui-helpers.js';
import { UIIcon } from '../../components/icon/ui-icon.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table cloneable plugin.
 * @alias UITableCloneable
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 * @fires event:row-added
 * @fires event:row-removed
 */
class UITableCloneable {
    /**
     * @param {UITable} target
     */
    constructor(target) {
        this.target = target;
    }

    static get ADD_BUTTON_SELECTOR() {
        return '.-add';
    }

    static get REMOVE_BUTTON_SELECTOR() {
        return '.-remove';
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-table-cloneable', {
            addRow: 'Add row',
            removeRow: 'Remove row',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                labelAdd: {
                    name: 'label-add',
                    type: String,
                },
                labelRemove: {
                    name: 'label-remove',
                    type: String,
                },
            },
        };
    }

    /**
     * Adds table cell with remove button after the last row.
     * @param {HTMLTableRowElement} tr
     * @returns {HTMLButtonElement}
     */
    addRemoveButton(tr) {
        const button = this.target.createElement({
            tagName: 'button',
            classList: {
                '-remove': true,
                '-iconed': true,
            },
            attributes: {
                type: 'button',
                title: this.labelRemove || UITableCloneable.labels.removeRow,
            },
            children: [
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'cross',
                        bgcolor: UIIcon.colors.ALERT_RED,
                    },
                },
            ],
        });
        const newTd = this.target.createElement({
            tagName: 'td',
            classList: {
                col: true,
                '-remove': true,
            },
            children: [button],
        });
        const td = tr.querySelector('td:last-child');
        if (td) {
            td.parentNode.insertBefore(newTd, td.nextSibling);
        }
        button.addEventListener('click', this.removeRowHandler.bind(this));
        return button;
    }

    /**
     * Removes the last cell with remove button.
     * @param {HTMLTableRowElement} tr
     */
    removeRemoveButton(tr) {
        const rembtn = tr.querySelector(
            UITableCloneable.REMOVE_BUTTON_SELECTOR
        );
        const cell = closestByCond(rembtn, (node) => node.tagName === 'TD');
        if (cell) {
            cell.parentNode.removeChild(cell);
        }
    }

    /**
     * Inits remove cells if needed.
     */
    initRemovalCells() {
        [].forEach.call(this.target.querySelectorAll('tbody tr'), (tr) => {
            this.addRemoveButton(tr);
        });
    }

    /**
     * Creates add button and appends it to the end of the table.
     */
    createAddButton() {
        const div = this.target.createElement({
            tagName: 'ui-buttonbar',
            children: [
                {
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                    },
                    classList: {
                        '-add': true,
                        '-iconed': true,
                    },
                    children: [
                        {
                            tagName: 'ui-caption',
                            attributes: {
                                position: 'right',
                            },
                            children: [
                                {
                                    tagName: 'ui-icon',
                                    attributes: {
                                        glyph: 'add',
                                        bgcolor: UIIcon.colors.TURQUOISE,
                                    },
                                },
                                {
                                    tagName: 'span',
                                    children: [
                                        this.target.labelAdd ||
                                            UITableCloneable.labels.addRow,
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        });
        if (this.target.querySelectorAll('tbody tr').length) {
            this.target.appendChild(div);
        }
    }

    /**
     * Clones the last table row, resets it's values and appends after
     * the latest row.
     * @returns {HTMLTableRowElement}
     */
    cloneRow() {
        const tr = this.target.querySelector(
            'tbody tr:not(.-fixed):first-child'
        );
        const newTr = this.prepareTemplate(tr);
        this.removeRemoveButton(newTr);
        const button = this.addRemoveButton(newTr);
        button.addEventListener('click', this.removeRowHandler.bind(this));
        return newTr;
    }

    /**
     * Prepares template for cloning. Handles id, name, for attributes.
     * @param {HTMLElement} tpl
     * @private
     * @returns {HTMLElement}
     */
    prepareTemplate(tpl) {
        const clone = tpl.cloneNode(true);
        const index = this.getNewRowIndex();
        [].forEach.call(
            clone.querySelectorAll('input,select,textarea'),
            (elem) => {
                switch (elem.type) {
                    case 'radio':
                    case 'checkbox':
                        elem.checked = false;
                        elem.removeAttribute('checked');
                        break;
                    default:
                        elem.value = '';
                        break;
                }
            }
        );

        if (index > 0) {
            ['id', 'for', 'name'].forEach((attrName) => {
                [].forEach.call(
                    clone.querySelectorAll('[' + attrName + ']'),
                    (node) => duplicateIndexSetter(node, attrName, index)
                );
            });
        }
        return clone;
    }

    /**
     * Function callback for adding rows.
     * @private
     * @param {Event} e
     */
    addRowHandler(e) {
        const newRow = this.cloneRow();
        newRow.dataset.index = String(this.getNewRowIndex());
        const tbody = this.target.table.tBodies[0];
        if (tbody && newRow) {
            tbody.appendChild(newRow);
        }
        newRow.classList.add('-highlight-fade');
        dispatchCustomEvent(this.target, 'row-added', {
            index: this.getNewRowIndex(),
        });
        this.checkAndToggleRemoveButtons();
    }

    /**
     * Function callback for removing rows.
     * @private
     * @param {Event} e
     */
    removeRowHandler(e) {
        const tr = closestByCond(e.target, (node) => node.tagName === 'TR');
        const fixedRow = tr.nextElementSibling;
        if (fixedRow && fixedRow.classList.contains('-fixed')) {
            fixedRow.parentNode.removeChild(fixedRow);
        }
        if (tr && tr.parentNode) {
            tr.parentNode.removeChild(tr);
        }
        dispatchCustomEvent(this.target, 'row-removed', {
            index: tr.dataset.index,
        });
        this.checkAndToggleRemoveButtons();
    }

    /**
     * Handles TH and TD elements visibility. If there aren't any removable rows
     * hide final empty TH cell and in table footer will be added empty cell.
     */
    checkAndToggleRemoveButtons() {
        const rows = this.target.querySelectorAll('tbody tr');
        if (!rows.length) {
            return;
        }
        const lastRow = this.target.querySelector('tbody tr:last-child');
        const firstRow = this.target.querySelector('tbody tr:first-child');
        if (rows.length === 1) {
            this.removeRemoveButton(lastRow);
        }

        const rbtn = firstRow.querySelector(
            UITableCloneable.REMOVE_BUTTON_SELECTOR
        );
        if (rows.length > 1 && !rbtn) {
            this.addRemoveButton(firstRow);
        }
    }

    /**
     * Return new row index.
     * @private
     * @returns {number}
     */
    getNewRowIndex() {
        const tbody = this.target.table.tBodies[0];
        const lastRow = tbody.rows[tbody.rows.length - 1];
        return lastRow && lastRow.dataset.hasOwnProperty('index')
            ? parseInt(lastRow.dataset.index) + 1
            : 0;
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-cloneable');
        this.createAddButton();
        this.initRemovalCells();
        this.checkAndToggleRemoveButtons();

        // Adds empty cells to the end of table head and foot to keep layout.
        const emptyTh =
            this.target.querySelector('.col.-empty') ||
            this.target.createElement({
                tagName: 'th',
                classList: {
                    col: true,
                    '-empty': true,
                },
            });
        const emptyTd =
            this.target.querySelector('.col.-empty') ||
            this.target.createElement({
                tagName: 'td',
                classList: {
                    col: true,
                    '-empty': true,
                },
            });
        const th = this.target.querySelector('thead th:last-child');
        const td = this.target.querySelector('tfoot td:last-child');
        if (th) {
            th.parentNode.insertBefore(emptyTh, th.nextSibling);
        }
        if (td) {
            td.parentNode.insertBefore(emptyTd, td.nextSibling);
        }

        [].forEach.call(
            this.target.querySelectorAll('tbody tr'),
            (row, idx) => {
                row.dataset.index = idx;
            }
        );
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        const addRowHandler = this.addRowHandler.bind(this);
        const removeRowHandler = this.removeRowHandler.bind(this);
        [].forEach.call(
            this.target.querySelectorAll(UITableCloneable.ADD_BUTTON_SELECTOR),
            (btn) => btn.addEventListener('click', addRowHandler)
        );
        [].forEach.call(
            this.target.querySelectorAll(
                UITableCloneable.REMOVE_BUTTON_SELECTOR
            ),
            (btn) => btn.addEventListener('click', removeRowHandler)
        );
    }
}
UITable.registerPlugin('cloneable', UITableCloneable);

export { UITableCloneable };

/**
 * @memberof UITableCloneable
 * @event row-added
 * @alias event:row-added
 * @type {string}
 * @augments {CustomEvent}
 */

/**
 * @memberof UITableCloneable
 * @event row-removed
 * @alias event:row-removed
 * @type {string}
 * @augments {CustomEvent}
 */

import { UITable } from '../../components/table/ui-table.js';
import { setInnerText, updateClassList } from '../../global/ui-helpers.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table comparison plugin.
 * @alias UITableComparison
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableComparison {
    constructor(target) {
        this.target = target;
    }

    /**
     * @type {number}
     */
    static get COLUMNS_COUNT_TO_SHRINK() {
        return 3;
    }

    /**
     * Builds comparison table, transform markup.
     * @private
     */
    prepareMarkup() {
        const caption = this.target.table.querySelector('caption');
        let colgroup = this.target.table.querySelector('colgroup');
        let colspan;
        let rows;
        for (let i = 0; i < this.target.table.tBodies.length; ++i) {
            rows = this.target.table.tBodies[i].querySelectorAll('tr');
            colspan = this.countColumns(rows);
            [].forEach.call(rows, (row) => {
                const rowElement = this.target.createElement({
                    tagName: 'tr',
                    classList: {
                        row: true,
                        '-fixed': true,
                        '-sub': row.classList.contains('-sub'),
                        '-expand': row.classList.contains('-expand'),
                        '-extra': row.classList.contains('-extra'),
                        '-open': row.classList.contains('-open'),
                    },
                    children: [
                        {
                            tagName: 'td',
                            attributes: {
                                colspan: colspan - 1,
                            },
                            children: [
                                this.target.createElement({
                                    tagName: 'div',
                                    classList: {
                                        'fixed-title': true,
                                    },
                                }),
                            ],
                        },
                    ],
                });
                setInnerText(
                    rowElement.querySelector('.fixed-title'),
                    row.cells[0].innerHTML,
                    true
                );
                row.insertAdjacentElement('beforebegin', rowElement);
            });
        }

        const tHeadCells =
            this.target.table.tHead?.querySelectorAll('th[scope="col"]');
        tHeadCells?.forEach((cell) => {
            const container = this.target.createElement({
                tagName: 'div',
                classList: {
                    'table-header-container': true,
                },
            });
            const nodes = Array.from(cell.children);
            nodes.map((node) => container.appendChild(node));
            cell.appendChild(container);
        });

        if (!colgroup) {
            colgroup = document.createElement('colgroup');
            for (let i = 0; i < colspan; i++) {
                colgroup.appendChild(document.createElement('col'));
            }
        }

        if (!this.target.table.contains(colgroup)) {
            if (caption) {
                caption.insertAdjacentElement('afterend', colgroup);
            } else {
                this.target.table.insertAdjacentElement('afterbegin', colgroup);
            }
        }
    }

    /**
     * Returns total number of columns in table, accounting for colspans.
     * Takes into account colspans.
     * @private
     * @param {NodeListOf<HTMLTableRowElement>} rows - Array of row nodes selected from table body.
     * @returns {number} Maximum number of rows
     */
    countColumns(rows) {
        const children = rows[0]?.children || [];
        return [].reduce.call(children, (total, td) => total + td.colSpan, 0);
    }

    /**
     * Highlights column or columns in comparison table.
     * @param {number | Array<number>} input - Index or indexes of columns to be highlighted.
     */
    highlightColumns(input) {
        if (!input || (Array.isArray(input) && input.length)) {
            return;
        }
        [].forEach.call(
            this.target.table.querySelectorAll('col'),
            (col, index) => {
                updateClassList(col, {
                    '-highlight': Array.isArray(input)
                        ? input.indexOf(index) > -1
                        : index === input,
                });
            }
        );
    }

    /**
     * Handles comparison expandable table's horizontal scroll.
     * TODO: when CSS will have good position: sticky support remove this and do no-js solution.
     * @param {Event} e
     * @private
     */
    handleScroll(e) {
        const stickyDivs = this.target.querySelectorAll('.fixed-title');
        const BASE_OFFSET = 10;
        [].forEach.call(stickyDivs, (div) => {
            div.style.left = BASE_OFFSET + e.target.scrollLeft + 'px';
            div.style.width = window.innerWidth - BASE_OFFSET - 100 + 'px';
        });
    }

    /**
     * Shrink columns on smaller screens to fit columns on screen if
     * column's count is less than COLUMNS_COUNT_TO_SHRINK(3).
     * @private
     */
    shrinkColumns() {
        const colsCount = this.target.querySelectorAll('col').length;
        if (colsCount <= UITableComparison.COLUMNS_COUNT_TO_SHRINK) {
            this.target.table.classList.add('-shrink');
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-comparison');
        this.shrinkColumns();
        this.prepareMarkup();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.scrollHandler = this.handleScroll.bind(this);
        this.target.addEventListener('scroll', this.scrollHandler);
        this.target.dispatchEvent(new Event('scroll'));
        window.addEventListener('resize', this.scrollHandler);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.target.addEventListener('scroll', this.scrollHandler);
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.target.removeEventListener('scroll', this.scrollHandler);
    }
}

UITable.registerPlugin('comparison', UITableComparison);

export { UITableComparison };

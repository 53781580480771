import { UIElement } from '../ui-element.js';
import * as UIDropdownHelpers from '../dropdown/ui-dropdown.helpers.js';
import * as UISliderHelpers from '../slider/ui-slider.helpers.js';
import * as UIDatePickerHelpers from '../datetime/ui-datepicker.helpers.js';
import * as UIAutocompleteHelpers from '../autocomplete/ui-autocomplete.helpers.js';
import {
    getData,
    setData,
    mergeAttributes,
    NATIVE_FORM_ELEMENTS,
    CUSTOM_FORM_ELEMENTS,
    CUSTOM_VIRTUAL_FORM_ELEMENTS,
} from '../../global/ui-helpers.js';
import styles from './ui-field.css';
import { Digest } from '../../global/digest.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIField
 * @element ui-field
 * @classdesc Represents a class for <code>ui-field</code> element.
 * Container for input elements, ui-field is very useful element that
 * handles errors, gives more opportunities to input elements. If you are
 * working with form, it is strongly recommended to wrap all input with
 * ui-field.
 * @property {boolean} [required] {@attr required} If true, will be set
 * a red '*' symbol to the label.
 * @property {string} [label] {@attr label} Label of the field.
 * @property {("fluid" | "inline" | "equal")} [layout] {@attr layout} Layout of field.
 *  {@desc fluid: Full width container.}
 *  {@desc inline: Field and label are align to left.}
 *  {@desc equal: Label and field are aligned to left and have 50% width.}
 * @property {HTMLDivElement} control {@readonly} Shortcut for control wrapper.
 * Wrapper can contain many inputs.
 * @property {HTMLDivElement} labelContainer {@readonly} Shortcut for label wrapper.
 * Wrapper can contain labels and tooltips.
 * @slot
 * @example
 * <ui-field required="true" label="Your name">
 *   <input type="text" name="your-name">
 * </ui-field>
 */
class UIField extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['label', 'required'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                required: Boolean,
                label: String,
            },
            children: {
                labelContainer: '.ui-field__label',
                control: '.ui-field__control',
            },
        };
    }

    /**
     * Convert data config to element config
     * @param {IFieldConfig} config
     * @returns {IElementConfig}
     */
    static fromFieldConfig(config) {
        let controls = [];
        if (config.control) {
            const ctrl = UIField.buildControlFromConfig(config.control);
            if (Array.isArray(ctrl)) {
                controls = controls.concat(ctrl);
            } else {
                controls.push(ctrl);
            }
        }

        if (config.controls) {
            Object.keys(config.controls).forEach((key) => {
                const ctrl = UIField.buildControlFromConfig(
                    config.controls[key]
                );
                if (Array.isArray(ctrl)) {
                    controls = controls.concat(ctrl);
                } else {
                    controls.push(ctrl);
                }
            });
        }

        if (Array.isArray(config.hints) && config.hints.length > 0) {
            config.hints.forEach((hint) => {
                controls.push({
                    tagName: hint.type !== 'span' ? 'ui-hint' : 'span',
                    attributes: hint.type && {
                        type: hint.type,
                        'error-type': hint.errorType || null,
                    },
                    children: [hint.content],
                });
            });
        }

        if (config.tooltip) {
            controls.push({
                tagName: 'ui-tooltip',
                attributes: {
                    type: 'question',
                    slot: 'label',
                },
                children: [config.tooltip.content],
            });
        }

        const isControlRequired = (ctrl) => {
            if (ctrl.attributes && ctrl.attributes.required) {
                return true;
            }
            if (Array.isArray(ctrl.children) && ctrl.children.length) {
                return ctrl.children.some(isControlRequired);
            }
            return false;
        };

        return {
            tagName: 'ui-field',
            attributes: {
                key: 'id' in config ? config.id : null,
                label: config.label,
                'data-index': 'index' in config ? config.index : null,
                'aria-hidden':
                    'visible' in config && !config.visible ? 'true' : null,
                required: controls.some(isControlRequired) || null,
            },
            classList: {
                '-hidden': 'visible' in config ? !config.visible : false,
                '-error': 'invalid' in config ? config.invalid : false,
            },
            children: controls,
        };
    }

    /**
     * Create an IElementConfig based on IFieldControl.
     * @param {IFieldControl} control
     * @returns {IElementConfig | Array<IElementConfig>}
     */
    static buildControlFromConfig(control) {
        const inputControls = [];
        switch (control.type) {
            case 'input':
                return {
                    tagName: 'input',
                    attributes: mergeAttributes(
                        {
                            type: 'text',
                            value: 'value' in control ? control.value : null,
                        },
                        control.attributes
                    ),
                    classList: control.classList || {},
                };
            case 'textarea':
                return {
                    tagName: 'textarea',
                    attributes: control.attributes,
                };
            case 'select':
                return {
                    tagName: 'select',
                    attributes: control.attributes,
                    classList: control.classList || {},
                    children:
                        'options' in control &&
                        control.options.map((option) => {
                            return {
                                tagName: 'option',
                                attributes: {
                                    value: option.value,
                                    selected:
                                        option.value === control.value
                                            ? 'selected'
                                            : null,
                                },
                                children: [option.content],
                            };
                        }),
                };

            case 'radio':
                return {
                    tagName: 'ul',
                    classList: {
                        'input-list': true,
                    },
                    children:
                        'options' in control &&
                        control.options.map((option) => {
                            return {
                                tagName: 'li',
                                children: [
                                    control.options.length > 0 && {
                                        tagName: 'input',
                                        classList: option.classList || null,
                                        attributes: mergeAttributes(
                                            {
                                                type: 'radio',
                                                name: option.name,
                                                value: option.value,
                                                id: option.value,
                                                checked:
                                                    option.value ===
                                                    control.value
                                                        ? 'checked'
                                                        : null,
                                            },
                                            option.attributes
                                        ),
                                    },
                                    {
                                        tagName: 'label',
                                        attributes: {
                                            for: option.value,
                                        },
                                        children: [option.content],
                                    },
                                ],
                            };
                        }),
                };

            case 'radio-horizontal':
                return (
                    'options' in control &&
                    control.options.reduce((elements, option) => {
                        return elements.concat([
                            control.options.length > 0 && {
                                tagName: 'input',
                                classList: option.classList || null,
                                attributes: mergeAttributes(
                                    {
                                        type: 'radio',
                                        name: option.name,
                                        value: option.value,
                                        id: option.id,
                                        checked:
                                            option.value === control.value
                                                ? 'checked'
                                                : null,
                                    },
                                    option.attributes
                                ),
                            },
                            {
                                tagName: 'label',
                                attributes: {
                                    for: option.id,
                                },
                                children: [option.content],
                            },
                        ]);
                    }, [])
                );
            case 'inputgroup':
                if (control.control) {
                    inputControls.push(control.control);
                }
                if (control.controls) {
                    Object.keys(control.controls).forEach((key) => {
                        inputControls.push(control.controls[key]);
                    });
                }
                return {
                    tagName: 'ui-inputgroup',
                    attributes: control.attributes,
                    children: inputControls.map(UIField.buildControlFromConfig),
                };
            case 'autocomplete':
                return UIAutocompleteHelpers.fromControlConfig(control);
            case 'dropdown':
                return UIDropdownHelpers.fromControlConfig(control);
            case 'slider':
                return UISliderHelpers.fromControlConfig(control);
            case 'date':
                return UIDatePickerHelpers.fromControlConfig(control);

            default:
                return null;
        }
    }

    /**
     * Set object data to nested controls, where the name attribute is a key property.
     * @param {Record<string, string>} data Data to be applied to the node.
     * @returns {UIField}
     */
    setData(data) {
        return setData(this, data);
    }

    /**
     * Get object data from nested controls, where the name attribute is a key property.
     * @returns {Record<string, string>}
     */
    getData() {
        return getData(this);
    }

    /**
     * Adds validation error under the field.
     * @param {string} message
     * @param {string} errorType
     * @returns {UIField}
     */
    addError(message, errorType) {
        this.addHint({ type: 'error', content: message, errorType: errorType });
        return this;
    }

    /**
     * Adds validation error under the field.
     * @param {string} message
     * @param {string} errorType
     * @returns {UIField}
     */
    setError(message, errorType) {
        const prevError = this.querySelector(
            'ui-hint[error-type=' + errorType + ']'
        );
        if (prevError) {
            prevError.setMessage(message);
        } else {
            this.addError(message, errorType);
        }
        return this;
    }

    /**
     * Remove all hints from ui-field. All hints will be removed
     * doesn't matter what type they have.
     * @returns {UIField}
     */
    removeHints() {
        const hints = this.querySelectorAll('ui-hint');
        [].forEach.call(hints, (hint) => {
            hint.parentElement.removeChild(hint);
        });
        this.checkErrors();
        return this;
    }

    /**
     * Adds hint under the field.
     * @param {IFieldHint} hint
     * @returns {UIField}
     */
    addHint(hint) {
        this.querySelector('.ui-field__control').appendChild(
            this.createElement({
                tagName: 'ui-hint',
                attributes: {
                    type: hint.type,
                    'error-type': hint.errorType ? hint.errorType : null,
                },
                children: [hint.content],
            })
        );
        this.checkErrors();
        return this;
    }

    /**
     * Updates label content. Also
     * adds / removes an asterisk to indicate if field is required.
     * @returns {UIField}
     */
    updateLabel() {
        this.setLabelContent(
            [
                this.label || '',
                this.required ? '<em aria-hidden="true">*</em>' : '',
            ].join('')
        );
        return this;
    }

    /**
     * Check if field has error hints inside.
     * @returns {UIField}
     */
    checkErrors() {
        const hasError =
            this.querySelectorAll('ui-hint[type=error]:not(.-hidden)').length >
            0;
        this.updateClassList({ '-error': hasError });
        const control = this.querySelector('input,textarea,select');
        if (!control) {
            return this;
        }
        if (hasError) {
            control.setAttribute('aria-invalid', 'true');
        } else {
            control.removeAttribute('aria-invalid');
        }

        return this;
    }

    /**
     * Gets first validation error for field.
     * @returns {Element | null}
     */
    getError() {
        return this.querySelector('ui-hint[type=error]');
    }

    /**
     * Gets all validation errors for field.
     * @returns {NodeList}
     */
    getAllErrors() {
        return this.querySelectorAll('ui-hint[type=error]');
    }

    /**
     * Remove all validation errors. Errors are hints and only hints with type="errors"
     * will be removed.
     * @param {boolean} force Remove errors with force and ignores all other conditions.
     * @returns {UIField}
     */
    removeErrors(force) {
        this.querySelectorAll('.ui-field__control ui-hint[type=error]').forEach(
            (/** @type {UIHint} */ node) => {
                const elem = node.parentElement.querySelector(
                    'input,textarea,select'
                );

                if (
                    force ||
                    (elem.validity.valid && node.errorType) ||
                    (elem.dataset.errorType &&
                        elem.dataset.errorType === node.errorType)
                ) {
                    this.updateClassList({ '-error': false });
                    elem.removeAttribute('aria-invalid');
                    node.parentElement.removeChild(node);
                }
            }
        );
        return this;
    }

    /**
     * Reads assitive error for a11y.
     */
    announceError() {
        const err = this.getError();
        if (err) {
            err.setAttribute('aria-live', 'polite');
        }
    }

    /**
     * Get id of the field control/input.
     * @returns {string | null}
     */
    getControlId() {
        const control = this.getControl();
        if (!control) {
            return null;
        }

        const nativeBaseNames = NATIVE_FORM_ELEMENTS.map(
            (selector) => selector.split(':')[0]
        );

        // Native case
        if (nativeBaseNames.includes(control.tagName.toLowerCase())) {
            let id = control.getAttribute('id');
            if (!id) {
                id = Digest.randomId();
                control.setAttribute('id', id);
            }
            return id;
        }

        // Custom form elements with virtual control case
        if (
            CUSTOM_VIRTUAL_FORM_ELEMENTS.includes(control.tagName.toLowerCase())
        ) {
            let id = control.getAttribute('control-id');
            if (!id) {
                id = Digest.randomId();
                control.setAttribute('control-id', id);
            }
            return id;
        }

        // Custom form elements with native control case
        const subControl = control.querySelector(
            NATIVE_FORM_ELEMENTS.join(',')
        );
        if (subControl) {
            const id = subControl.getAttribute('id');
            if (id) {
                control.setAttribute('control-id', id);
                return id;
            }
        }

        let id = control.getAttribute('control-id');
        if (!id) {
            id = Digest.randomId();
            control.setAttribute('control-id', id);
        }

        return id;
    }

    /**
     * Gets the control.
     * @returns {UIElement | HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement}
     */
    getControl() {
        const selectors =
            NATIVE_FORM_ELEMENTS.concat(CUSTOM_FORM_ELEMENTS).join(',');
        return this.querySelector(selectors);
    }

    /**
     * Sets content to label element inside ui-field.
     * @param {string} content
     * @returns {UIField}
     */
    setLabelContent(content) {
        let label = this.querySelector('.ui-field__label label');
        if (!label) {
            if (!content) {
                return this;
            }
            label = this.createElement({ tagName: 'label', attributes: {} });
            const controlId = this.getControlId();
            if (controlId) {
                label.setAttribute('for', controlId);
            }
            const container = this.labelContainer;
            if (container) {
                if (container.childNodes.length > 0) {
                    container.insertBefore(label, container.firstChild);
                } else {
                    container.appendChild(label);
                }
            }
        }
        label.innerHTML = content;
        return this;
    }

    /**
     * Links labels to controls.
     * @returns {UIField}
     */
    linkLabels() {
        const label = this.querySelector('.ui-field__label label');
        if (!label) {
            return this;
        }
        const controlId = this.getControlId();
        if (controlId) {
            label.setAttribute('for', controlId);
            const currencyLabel = this.querySelector('label.units');
            if (currencyLabel) {
                currencyLabel.setAttribute('for', controlId);
            }
        }
        return this;
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'required':
            case 'label':
                this.updateLabel();
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        /**
         * @type {IElementConfig}
         */
        const definitionElementConfig = {
            tagName: 'div',
            classList: {
                'ui-field__label': true,
            },
            children: [
                !!this.label && {
                    tagName: 'label',
                    children: [
                        this.label,
                        this.required && {
                            tagName: 'em',
                            attributes: {
                                'aria-hidden': true,
                            },
                            children: ['*'],
                        },
                    ],
                },
            ].concat(this.getChildrenForSlot('label')),
        };

        /**
         * @type {IElementConfig}
         */
        const controlElementConfig = {
            tagName: 'div',
            classList: {
                'ui-field__control': true,
            },
            children: [].filter.call(this.childNodes, (node) => {
                return !node.tagName || !node.hasAttribute('slot');
            }),
        };

        this.updateElement({
            children: [definitionElementConfig, controlElementConfig],
        });

        this.linkLabels();
    }

    hydrate() {
        this.checkErrors();
        this.updateLabel();
    }
}

UIField.defineElement('ui-field', styles);
export { UIField };

import { UIElement } from '../ui-element.js';
import styles from './ui-buttonbar.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIButtonbar
 * @element ui-buttonbar
 * @classdesc Represents a class for <code>ui-buttonbar</code> element.
 * Sets buttons for correct position according to brand-book.
 * Buttons have classes:
 * <ul class="regular-list">
 *   <li><b>-destructive</b> - destructive gray button, should be on left side
 *   in the most cases.</li>
 *   <li><b>-guiding</b> - turquiose button, always should be placed on right
 *   side in button bar</li>
 * </ul>
 * There is posibility to force elements inside ui-buttonbar, so CSS modifiers
 * can be added like:
 * <ul class="regular-list">
 *   <li><b>-left</b> - forces element to be on the left side;</li>
 *   <li><b>-right</b> - forces element to be on the right side.</li>
 * </ul>
 * @property {boolean} [responsive] {@attr responsive} The layout is responsive or not.
 * @property {UIButtonBarLayout} [layout] {@attr layout} Layout of the buttonbar.
 *  {@desc fluid: Full width container}
 *  {@desc inline: Stays in same line}
 * @property {HTMLDivElement} leftContainer {@readonly}
 * @property {HTMLDivElement} rightContainer {@readonly}
 * @slot
 * @example
 * <ui-buttonbar>
 *   <button class="button">Submit</button>
 *   <button class="button -guiding">Save</button>
 *   <button class="button -destructive">Cancel</button>
 * </ui-buttonbar>
 */
class UIButtonbar extends UIElement {
    /**
     * @private
     * @type {string[]}
     */
    static get LeftSlotClasses() {
        return ['-back', '-left', '-destructive', 'default-link'];
    }

    /**
     * (empty string) is a placeholder for unspecified elements
     * @private
     * @type {string[]}
     */
    static get RightSlotClasses() {
        return ['-guiding', '', '-right', '-next'];
    }

    /**
     * @private
     * @returns {UIButtonBarPositions}
     */
    static get Position() {
        return {
            Default: 'default',
            Left: 'left',
            Right: 'right',
        };
    }

    /**
     * @type {string}
     * @private
     */
    static get AllowedElementsSelectors() {
        return 'button,a,input[type="button"],input[type="submit"],input[type="reset"]';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                responsive: { type: Boolean, default: true },
            },
            children: {
                leftContainer: '.ui-buttonbar__left',
                rightContainer: '.ui-buttonbar__right',
            },
        };
    }

    /**
     * Orders in buttons.
     * @param {Node|Element} node Node in which node should be ordered in.
     * @param {Array<string>} array Arrays of classes.
     * @returns {number}
     */
    orderIn(node, array) {
        if (node.nodeType !== Node.ELEMENT_NODE) {
            return array.indexOf('');
        }
        for (let i = 0; i < array.length; i++) {
            if (array[i] && node.classList.contains(array[i])) {
                return i;
            }
        }
        return array.indexOf('');
    }

    /**
     * To add button to rendered button bar.
     * @param {HTMLElement} node Button or link element.
     * @param {UIButtonBarPosition} position
     */
    addButton(node, position) {
        if (
            !node ||
            !(
                this.leftContainer &&
                this.isParentOf(this.leftContainer) &&
                this.rightContainer &&
                this.isParentOf(this.rightContainer)
            )
        ) {
            return;
        }

        if (position && position === this.constructor.Position.Left) {
            this.leftContainer.appendChild(node);
        }
        if (position && position === this.constructor.Position.Right) {
            this.rightContainer.insertBefore(
                node,
                this.rightContainer.firstChild
            );
        }

        if (!position) {
            if (this.orderIn(node, this.constructor.LeftSlotClasses) > -1) {
                this.leftContainer.appendChild(node);
            }
            if (this.orderIn(node, this.constructor.RightSlotClasses) > -1) {
                this.rightContainer.insertBefore(
                    node,
                    this.rightContainer.firstChild
                );
            }
        }
    }

    /**
     * Fires callback when component mutates.
     * @param {Array<MutationRecord>} mutations
     * @private
     */
    handleMutations(mutations) {
        this.updateClassList({
            '-empty': !this.querySelector(
                this.constructor.AllowedElementsSelectors
            ),
        });
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateClassList({
            '-responsive': this.responsive,
            '-empty':
                this.querySelectorAll(this.constructor.AllowedElementsSelectors)
                    .length === 0,
        });

        if (
            (this.leftContainer && this.isParentOf(this.leftContainer)) ||
            (this.rightContainer && this.isParentOf(this.rightContainer))
        ) {
            return;
        }

        const leftSlot = [];
        const rightSlot = [];

        this.detachChildNodes().forEach((node) => {
            const slot =
                this.orderIn(node, this.constructor.LeftSlotClasses) > -1
                    ? leftSlot
                    : rightSlot;
            slot.push(node);
        });

        this.insertElements([
            {
                tagName: 'div',
                classList: {
                    'ui-buttonbar__left': true,
                },
                children: leftSlot.sort((nodeA, nodeB) => {
                    const orderA = this.orderIn(
                        nodeA,
                        this.constructor.LeftSlotClasses
                    );
                    const orderB = this.orderIn(
                        nodeB,
                        this.constructor.LeftSlotClasses
                    );
                    return orderA - orderB;
                }),
            },
            {
                tagName: 'div',
                classList: {
                    'ui-buttonbar__right': true,
                },
                children: rightSlot.sort((nodeA, nodeB) => {
                    const orderA = this.orderIn(
                        nodeA,
                        this.constructor.RightSlotClasses
                    );
                    const orderB = this.orderIn(
                        nodeB,
                        this.constructor.RightSlotClasses
                    );
                    return orderA - orderB;
                }),
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.observer = new MutationObserver(this.handleMutations.bind(this));
        this.observer.observe(this, { childList: true, subtree: true });
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.observer.disconnect();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.observer.observe(this, { childList: true, subtree: true });
    }
}

UIButtonbar.defineElement('ui-buttonbar', styles);
export { UIButtonbar };

import { UIElement } from '../ui-element.js';
import { Labels } from '../../global/labels.js';
import { element, html, trustedHTML } from '../../global/template-literals.js';
import styles from './ui-meterbar.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIMeterBar
 * @element ui-meterbar
 * @classdesc Represents a class for <code>ui-meterbar</code> element.
 * Element to display meter.
 * @property {string} [labelMin] {@attr label-min} Label for low level.
 * @property {string} [labelMid] {@attr label-mid} Label for medium level.
 * @property {string} [labelMax] {@attr label-max} Label for high level.
 * @property {string} [labelLevel] {@attr label-level} Label for level, used in accessibility only.
 * @property {string} [unit] {@attr unit} Scale unit.
 * @property {number} [min=0] {@attr min} The minimum level.
 * @property {number} [max=7] {@attr max} The maximum level.
 * @property {number} [value=4] {@attr value} The current value of the level.
 * @property {number} [step=1] {@attr step} The step of the scale.
 * @property {boolean} [noscale=false] {@attr noscale} Do not render the scale.
 * @property {boolean} [trusted=false] {@attr trusted} Defines if element's strings are
 * trusted HTML.
 * @property {HTMLDivElement} [gradesElem] {@readonly} Shortcut to grades container element.
 * @property {HTMLDivElement} [labelsElem] {@readonly} Shortcut to labels container element.
 * @slot
 * @example
 * <ui-meterbar min="0" max="7" value="4" step="1"></ui-meterbar>
 */
class UIMeterBar extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                min: { type: Number, default: 0 },
                max: { type: Number, default: 7 },
                value: { type: Number, default: 4 },
                step: { type: Number, default: 1 },
                unit: { type: String, default: '' },
                labelMin: String,
                labelMid: String,
                labelMax: String,
                labelLevel: String,
                noscale: Boolean,
                trusted: Boolean,
            },
            children: {
                labelsElem: '.ui-meterbar__labels',
                gradesElem: '.ui-meterbar__scale',
            },
        };
    }

    /**
     * @returns {Array<string>}
     */
    static get observedAttributes() {
        return [
            'min',
            'max',
            'value',
            'step',
            'unit',
            'label-min',
            'label-mid',
            'label-max',
            'noscale',
        ];
    }

    /**
     * @inheritDoc
     */
    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-meterbar', {
            labelMin: 'Low',
            labelMax: 'High',
            level: 'Level',
        });
    }

    /**
     * Gets the scale points step.
     * @type {number}
     * @readonly
     */
    get getStep() {
        return this.step || this.constructor.props.attributes.step.default;
    }

    /**
     * Gets the scale points array.
     * @type {Array<string>}
     * @readonly
     */
    get scalePoints() {
        const scalePoints = [];
        const step = this.getStep;
        for (
            let scalePoint = this.min;
            scalePoint <= this.max;
            scalePoint += step
        ) {
            scalePoints.push(`${scalePoint}${this.unit}`);
        }
        return scalePoints;
    }

    /**
     * @type {UIMeterBarLevels}
     * @readonly
     */
    static get levels() {
        return {
            LOW: Math.ceil((1 / 3) * 100),
            HIGH: Math.ceil((2 / 3) * 100),
            MAX: 100,
        };
    }

    /**
     * Calculates the position of the meter in percent.
     * @returns {number}
     */
    calculatePositionPct() {
        if (this.max === this.min) {
            return 0;
        }
        return ((this.value - this.min) / (this.max - this.min)) * 100;
    }

    /**
     * Updates the position of the meter.
     */
    updatePosition() {
        if (this.min > this.max) {
            console.warn(
                `${this.nodeName.toLowerCase()}: minimum value is larger than maximum.`
            );
        }
        if (this.value > this.max) {
            console.warn(
                `${this.nodeName.toLowerCase()}: the value is larger than maximum.`
            );
            this.value = this.max;
        }
        /** @type {string} */
        let color;
        /** @type {number} */
        let pos = this.calculatePositionPct();
        if (pos < this.constructor.levels.LOW) {
            color = '--ui-color-alert-green';
        } else if (
            pos >= this.constructor.levels.LOW &&
            pos < this.constructor.levels.HIGH
        ) {
            color = '--ui-color-pineapple';
        } else {
            color = '--ui-color-orange';
        }

        if (pos > this.constructor.levels.MAX) {
            // Position should not be larger than 100%.
            pos = this.constructor.levels.MAX;
        } else if (pos < 0) {
            pos = 0;
        }
        this.style.setProperty('--ui-meterbar-color', `var(${color})`);
        this.style.setProperty('--ui-meterbar-position', `${Math.round(pos)}%`);
    }

    /**
     * Builds grades elements.
     * @private
     * @returns {NodeList<HTMLSpanElement>|Array<Node>}
     */
    buildScale() {
        return this.scalePoints.map(
            (scalePoint) =>
                element`<span class="ui-meterbar__scale-point">${scalePoint}</span>`
        );
    }

    /**
     * Build labels elements.
     * @private
     * @returns {DocumentFragment}
     */
    buildLabels() {
        const renderer = this.trusted ? trustedHTML : html;
        return renderer`
        <span>${this.labelMin || this.getLabel('labelMin')}</span>
        ${this.labelMid && renderer`<span>${this.labelMid}</span>`}        
        <span>${this.labelMax || this.getLabel('labelMax')}</span>`;
    }

    /**
     * Builds content for the ui-meterbar.
     * @private
     * @returns {DocumentFragment}
     */
    buildContent() {
        return html` <div class="ui-meterbar__labels">
                ${this.buildLabels()}
            </div>
            <div class="ui-meterbar__meter"></div>
            <div class="ui-meterbar__scale">
                ${!this.noscale ? this.buildScale() : ''}
            </div>`;
    }

    /**
     * Applies accessibility ot meter.
     * @private
     */
    applyA11y() {
        this.setAttribute('role', 'meter');
        this.setAttribute('aria-valuemin', `${String(this.min)}${this.unit}`);
        this.setAttribute('aria-valuemax', `${String(this.max)}${this.unit}`);
        this.setAttribute('aria-valuenow', `${String(this.value)}${this.unit}`);
        this.setAttribute(
            'aria-valuetext',
            `${this.labelLevel || this.getLabel('level')} ${this.value}${
                this.unit
            }`
        );
        this.setAttribute('aria-live', 'polite');
    }

    /**
     * Sets the value. This method exists for API consistency.
     * @param {string|number} value
     */
    setValue(value) {
        this.value = value;
    }

    /**
     * @inheritDoc
     */
    observeAttributes() {
        this.updatePosition();
        this.applyA11y();
    }

    /**
     * @inheritDoc
     */
    render() {
        this.rebuildChildren([this.buildContent()]);
        this.updatePosition();
        this.applyA11y();
    }
}

UIMeterBar.defineElement('ui-meterbar', styles);
export { UIMeterBar };

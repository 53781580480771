import { UIElement } from '../ui-element.js';
import styles from './ui-layout-sidebar.css';
import { browser } from '../../global/index.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UILayoutSidebar
 * @element ui-layout-sidebar
 * @classdesc Represents a class for <code>ui-layout-sidebar</code> element.
 * @example
 * <ui-layout-sidebar>
 *     <section class="frame">Sidebar content</section>
 * </ui-layout-sidebar>
 */
class UILayoutSidebar extends UIElement {
    /**
     * Toggle visibility of the sidebar depending on the children length.
     */
    toggleVisibility() {
        if (!this.children.length) {
            this.classList.add('-hidden');
        } else {
            this.classList.remove('-hidden');
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'complementary');
        this.toggleVisibility();
    }

    hydrate() {
        browser.desktopMedia.addListener(() => {
            this.toggleVisibility();
        });
    }
}
UILayoutSidebar.defineElement('ui-layout-sidebar', styles);
export { UILayoutSidebar };

import { createElement, setAttributes } from '../../global/render-api.js';
import { UIElement } from '../ui-element.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-caption.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICaption
 * @element ui-caption
 * @classdesc Represents a class for <code>ui-caption</code> element.
 * Text label with icon position left of right.
 * @property {("left" | "right")} [pos="left"] {@attr position} Position of icon.
 *  {@desc left}
 *  {@desc right}
 * @property {("chip" | "mark")} [layout] {@attr layout} Layout type.
 *  {@desc chip}
 *  {@desc mark}
 * @slot {@type "ui-icon"}
 * @example
 * <ui-caption>
 *   <ui-icon glyph="home"></ui-icon>
 *   Home insurance.
 * </ui-caption>
 */
class UICaption extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                pos: { type: String, default: 'left', name: 'position' },
                layout: String,
            },
        };
    }

    /**
     * Renders 'mark' layout.
     */
    renderLayoutMark() {
        /**
         * @type {SharedComponents.UIIcon}
         */
        const icon =
            this.querySelector('ui-icon') ||
            createElement({
                tagName: 'ui-icon',
                attributes: {
                    glyph: 'agree',
                    bgcolor: UIIcon.colors.WHITE,
                    color: UIIcon.colors.ORANGE,
                    size: 'medium',
                },
            });
        /**
         * @type {Record<string, string>}
         */
        const attributes = {};
        if (icon.glyph !== 'agree') {
            attributes.glyph = 'agree';
            if (icon.glyph) {
                this.warnAttributes('glyph', icon.glyph, ['agree']);
            }
        }
        if (
            ![
                UIIcon.colors.ORANGE,
                UIIcon.colors.ROSE,
                UIIcon.colors.WHITE,
            ].includes(icon.bgcolor)
        ) {
            attributes.bgcolor = UIIcon.colors.WHITE;
            if (icon.bgcolor) {
                this.warnAttributes('bgcolor', icon.bgcolor, [
                    UIIcon.colors.ORANGE,
                    UIIcon.colors.ROSE,
                    UIIcon.colors.WHITE,
                ]);
            }
        }
        if (
            ![
                UIIcon.colors.WHITE,
                UIIcon.colors.ORANGE,
                UIIcon.colors.TULIP,
                UIIcon.colors.TEAL,
            ].includes(icon.color)
        ) {
            attributes.color = UIIcon.colors.ORANGE;
            if (icon.color) {
                this.warnAttributes('color', icon.color, [
                    UIIcon.colors.WHITE,
                    UIIcon.colors.ORANGE,
                    UIIcon.colors.TULIP,
                    UIIcon.colors.TEAL,
                ]);
            }
        }
        if (!['small', 'medium'].includes(icon.size)) {
            attributes.size = 'medium';
            if (icon.size) {
                this.warnAttributes('size', icon.size, ['small', 'medium']);
            }
        }

        if (Object.keys(attributes).length) {
            setAttributes(icon, attributes);
        }

        if (this.firstChild !== icon) {
            this.prepend(icon);
        }
    }

    /**
     * Warns about invalid attributes.
     * @private
     * @param {string} key
     * @param {string} value
     * @param {string[]} allowedValues
     */
    warnAttributes(key, value, allowedValues) {
        const allowedOptions = `Allowed options are: ${allowedValues.join(
            ', '
        )}.`;
        this.constructor.DEBUG_MODE &&
            console.warn(
                `${value} - ${key} attribute is not allowed. ${allowedOptions}`,
                this
            );
    }

    /**
     * @inheritDoc
     */
    render() {
        if (this.layout === 'mark') {
            this.renderLayoutMark();
        }
    }
}

UICaption.defineElement('ui-caption', styles);
export { UICaption };

import { createElement, dispatchCustomEvent } from '../../global/ui-helpers.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-dynamic-table pagination plugin.
 * @alias UIPaginationPlugin
 * @implements {IComponentPlugin}
 * @param {UIDynamicTable} target Target instance.
 * @property {UIDynamicTable|HTMLElement} target Target instance.
 */
class UIPaginationPlugin {
    constructor(target) {
        this.target = target;
    }

    /**
     * @type {number}
     */
    static get MIN_PAGE_SIZE() {
        return 10;
    }

    get primaryControl() {
        return this.target.querySelector('ui-pagination[key=primary]');
    }

    get secondaryControl() {
        return this.target.querySelector('ui-pagination[key=secondary]');
    }

    /**
     * @inheritDoc
     */
    modifyTable(uiTable) {
        return uiTable;
    }

    /**
     * Handle UIPagination change event
     * @param {CustomEvent} e
     */
    handleChangePage(e) {
        const currentPage = e.target.currentPage;
        const pageSize = e.target.pageSize;

        const nextControl =
            this.primaryControl !== e.target
                ? this.primaryControl
                : this.secondaryControl;

        nextControl.currentPage = currentPage;
        nextControl.pageSize = pageSize;

        dispatchCustomEvent(this.target, 'pagination-change', {
            currentPage: currentPage,
            pageSize: pageSize,
        });
    }

    /**
     * @param {number} currentPage
     * @param {number} pageSize
     * @param {number} total
     */
    update(currentPage, pageSize, total) {
        this.pageSize = pageSize;
        [this.primaryControl, this.secondaryControl].forEach((control) => {
            if (total <= UIPaginationPlugin.MIN_PAGE_SIZE) {
                control.hide();
            } else {
                control.show();
            }
            control.pageSize = pageSize;
            control.total = total;
            control.currentPage = currentPage;
        });
        if (total > UIPaginationPlugin.MIN_PAGE_SIZE) {
            const visibleInCurrentPage = total - (currentPage - 1) * pageSize;
            if (visibleInCurrentPage >= 10) {
                this.primaryControl.show();
            } else {
                this.secondaryControl.hide();
            }
        }
    }

    /**
     * Inserts a pagination element before and after the table element
     * @inheritDoc
     */
    render() {
        /** @type {SharedComponents.UIPagination} */
        const primaryPagination = createElement({
            tagName: 'ui-pagination',
            attributes: {
                key: 'primary',
                'show-page-size': 'show-page-size',
                'justify-desktop': 'left',
            },
        });

        /** @type {SharedComponents.UIPagination} */
        const secondaryPagination = createElement({
            tagName: 'ui-pagination',
            attributes: {
                key: 'secondary',
                'show-page-size': 'show-page-size',
                'page-size': 20,
                'justify-desktop': 'left',
            },
        });

        const pageSize = this.target.dataset.pagesize;
        if (pageSize) {
            primaryPagination.pageSize = Number(pageSize);
            secondaryPagination.pageSize = Number(pageSize);
        }
        try {
            const pageSizeList = JSON.parse(
                this.target.dataset.pagesizeOptions
            );
            if (Array.isArray(pageSizeList)) {
                primaryPagination.pageSizeList = pageSizeList;
                secondaryPagination.pageSizeList = pageSizeList;
            }
        } catch (e) {
            // No options provided
        }

        const total = this.target.dataset.totalRecords;
        if (total) {
            primaryPagination.total = Number(total);
            secondaryPagination.total = Number(total);
        } else {
            // FIXME: Otherwise no event will be dispatched from ui-pagination
            primaryPagination.total = 1;
            secondaryPagination.total = 1;
        }

        primaryPagination.currentPage = 1;
        secondaryPagination.currentPage = 1;

        this.target.insertAdjacentElement('afterbegin', secondaryPagination);
        this.target.insertAdjacentElement('beforeend', primaryPagination);
    }

    hydrate() {
        this.primaryControl.addEventListener(
            'change',
            this.handleChangePage.bind(this)
        );
        this.secondaryControl.addEventListener(
            'change',
            this.handleChangePage.bind(this)
        );
    }
}

export { UIPaginationPlugin };

import { UIDateElement } from './ui-dateelement.js';
import {
    addSyntheticFocusVisible,
    buildSyntheticFocusControl,
    updateElement,
} from '../../global/ui-helpers.js';
import { Labels } from '../../global/labels.js';
import styles from './ui-timerange.css';

/**
 * @memberof SharedComponents
 * @augments {UIDateElement}
 * @alias UITimeRange
 * @element ui-timerange
 * @classdesc Represents a class for <code>ui-timerange</code> element.
 * Input two ui-timepickers to select time range.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {string} [valueType="time"] {@readonly} Type of value in this case is 'time' only.
 * @property {UITimePicker} pickerFrom {@readonly} Shortcut to timepicker-from element.
 * @property {UITimePicker} pickerTo {@readonly} Shortcut to timepicker-to element.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @slot {@type "ui-timepicker"}
 * @example
 * <ui-timerange min="08:00" max="19:00">
 *   <ui-timepicker>
 *     <input name="time-from" type="time">
 *   </ui-timepicker>
 *   <ui-timepicker>
 *     <input name="time-to" type="time">
 *   </ui-timepicker>
 * </ui-timerange>
 */
class UITimeRange extends UIDateElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                controlId: String,
            },
            children: {
                pickerFrom: 'ui-timepicker.-from',
                pickerTo: 'ui-timepicker.-to',
                focusTrigger: '.ui-timerange__focus-trigger',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-timerange', {
            TIME_START: 'Time start',
            TIME_END: 'Time end',
        });
    }

    get valueType() {
        return UIDateElement.VALUE_TYPES.TIME;
    }

    get control() {
        return this;
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * Syncs start and end values.
     * @override
     */
    syncControls() {
        if (!this.pickerFrom || !this.pickerTo) {
            return;
        }
        if (!this.isValidTime(this.pickerTo.value)) {
            this.pickerFrom.max = this.max;
        } else {
            this.pickerFrom.max = this.pickerTo.value;
        }

        if (!this.isValidTime(this.pickerFrom.value)) {
            this.pickerTo.min = this.min;
        } else {
            this.pickerTo.min = this.pickerFrom.value;
        }
    }

    /**
     * Fires callback when a value of the date-from control is changed.
     * @param {Event} e
     * @private
     */
    handleChangeDateFrom(e) {
        this.syncControls();
    }

    /**
     * Fires callback when a value of the date-to control is changed.
     * @param {Event} e
     * @private
     */
    handleChangeDateTo(e) {
        this.syncControls();
    }

    /**
     * @inheritDoc
     */
    render() {
        const pickers = this.queryChildren('ui-timepicker');
        const pickerInputs = [
            pickers[0] ? pickers[0].querySelector('input') : null,
            pickers[1] ? pickers[1].querySelector('input') : null,
        ];

        this.detachChildNodes();
        this.insertElements([
            buildSyntheticFocusControl(this.controlId, 'ui-timerange'),
            {
                tagName: 'ui-timepicker',
                element: pickers[0],
                classList: {
                    '-from': true,
                },
                children: [
                    {
                        tagName: 'input',
                        element: pickerInputs[0],
                        attributes: {
                            'aria-label': UITimeRange.labels.TIME_START,
                            type: pickerInputs[0]
                                ? pickerInputs[0].getAttribute('type')
                                : 'time',
                            min: this.min,
                            max: this.max,
                        },
                    },
                ],
            },
            {
                tagName: 'span',
                attributes: { class: 'ui-timerange__separator' },
            },
            {
                tagName: 'ui-timepicker',
                element: pickers[1],
                classList: {
                    '-to': true,
                },
                children: [
                    {
                        tagName: 'input',
                        element: pickerInputs[1],
                        attributes: {
                            'aria-label': UITimeRange.labels.TIME_END,
                            type: pickerInputs[1]
                                ? pickerInputs[1].getAttribute('type')
                                : 'time',
                            min: this.min,
                            max: this.max,
                        },
                    },
                ],
            },
        ]);
    }

    hydrate() {
        this.syncControls();
        updateElement(this.pickerFrom, {
            events: {
                change: this.handleChangeDateFrom.bind(this),
            },
        });
        updateElement(this.pickerTo, {
            events: {
                change: this.handleChangeDateTo.bind(this),
            },
        });

        this.focusTrigger.addEventListener('click', () =>
            addSyntheticFocusVisible(this.pickerFrom.control)
        );
    }
}

UITimeRange.defineElement('ui-timerange', styles);
export { UITimeRange };

import { UIElement } from '../ui-element.js';
import { closestByCond, setInnerText } from '../../global/ui-helpers.js';
import styles from './ui-checkbox-account.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICheckboxAccount
 * @element ui-checkbox-account
 * @classdesc Represents a class for <code>ui-checkbox-account</code> element.
 * Element is used in PFM project.
 * @property {string} [label=""] {@attr label} Specifies label.
 * @property {string|number} [number=""] {@attr number} Specifies account number.
 * @property {HTMLInputElement} input {@readonly} Shortcut to input element.
 * @slot
 * @example
 * <ui-checkbox-account label="John Dough" number="EE5122000001102304345">
 *   <input id="test-render-id-1" name="test-render-name-1" value="123" checked="">
 * </ui-checkbox-account>
 */
class UICheckboxAccount extends UIElement {
    /**
     * Slice account number for mobile: number of symbols at the beginning.
     * @type {number}
     */
    static get SLICE_ACCOUNT_START() {
        return 6;
    }

    /**
     * Slice account number for mobile: number of symbols at the end.
     * @type {number}
     */
    static get SLICE_ACCOUNT_END() {
        return -4;
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['label', 'number'];
    }

    /**
     * Provides getter and setter for "label", "number", "checked", "value" properties
     * and link to the "label", "number" attributes
     */
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: { type: String, default: '' },
                number: { type: String, default: '' },
            },
            children: {
                input: {
                    selector: '.ui-checkbox-account__input',
                    fallback: function () {
                        return (
                            this.queryChildren('input')[0] ||
                            UIElement.prototype.createElement({
                                tagName: 'input',
                                attributes: {
                                    type: 'checkbox',
                                },
                            })
                        );
                    },
                },
            },
        };
    }
    get value() {
        return this.input.value;
    }
    set value(value) {
        const input = this.input;
        if (input.value === value) {
            return;
        }
        input.value = value;
    }

    get checked() {
        return this.input.checked;
    }
    set checked(value) {
        const input = this.input;
        if (input.checked === value) {
            return;
        }
        input.checked = value;
    }

    /**
     * Check if single view is used.
     * @returns {boolean}
     */
    isSingle() {
        return Boolean(
            closestByCond(this, function (node) {
                return !!node.tagName && node.classList.contains('-singleview');
            })
        );
    }

    /**
     * Return account number with *** for tablet and mobile.
     * @returns {string}
     */
    renderAccountNumber() {
        return this.number &&
            this.mobileMediaQueryList &&
            this.mobileMediaQueryList.matches &&
            !this.isSingle()
            ? this.number.slice(0, UICheckboxAccount.SLICE_ACCOUNT_START) +
                  '***' +
                  this.number.slice(UICheckboxAccount.SLICE_ACCOUNT_END)
            : this.number;
    }

    /**
     * Updates checkbox group.
     */
    updateCheckboxAccount() {
        const field = this.querySelector('input');

        if (this.isSingle()) {
            this.checked = true;
        }

        this.getLabelElement().setAttribute(
            'for',
            field.getAttribute('id') ? field.getAttribute('id') : ''
        );
        setInnerText(this.getLabelText(), this.label);
        setInnerText(this.getLabelNumber(), this.renderAccountNumber());
    }

    /**
     * Get or create element for label wrapper
     * @returns {Element}
     */
    getLabelElement() {
        let el = this.querySelector('label');
        if (!el) {
            el = document.createElement('label');
            this.appendChild(el);
        }

        return el;
    }

    /**
     * Get or create element for label text
     * @returns {Element}
     */
    getLabelText() {
        const container = this.querySelector('label');
        let el = container.querySelector('.ui-checkbox-account__label');
        if (!el) {
            el = document.createElement('span');
            el.className = 'ui-checkbox-account__label';
            container.appendChild(el);
        }

        return el;
    }

    /**
     * Get or create element for account number
     * @returns {Element}
     */
    getLabelNumber() {
        const container = this.querySelector('label');
        let el = container.querySelector('.ui-checkbox-account__number');
        if (!el) {
            el = document.createElement('span');
            el.className = 'ui-checkbox-account__number';
            container.appendChild(el);
        }
        return el;
    }

    /**
     * Handle event when media changed desktop to tablet and vice versa.
     */
    handleChangeAppearance() {
        setInnerText(this.getLabelNumber(), this.renderAccountNumber());
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }

        switch (name) {
            case 'label':
                setInnerText(this.getLabelText(), this.label);
                break;
            case 'number':
                setInnerText(this.getLabelNumber(), this.renderAccountNumber());
                break;
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const children = this.detachChildNodes();
        const input = children.filter(function (node) {
            return !!node.tagName && node.tagName.toLowerCase() === 'input';
        })[0];

        const checkbox = input || this.input;
        checkbox.classList.add('ui-checkbox-account__input');

        this.mobileMediaQueryList = window.matchMedia('(max-width: 1023px)');
        this.appendChild(checkbox);
        this.updateCheckboxAccount();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.mobileMediaQueryList = window.matchMedia('(max-width: 1023px)');
        this.handleChangeAppearance();
        this.mobileMediaQueryList.addListener(
            this.handleChangeAppearance.bind(this)
        );
    }
}

UICheckboxAccount.defineElement('ui-checkbox-account', styles);
export { UICheckboxAccount };

import '../field/ui-field.js';
import { UITab } from './ui-tab.js';
import '../views/ui-views.js';
import { UIElement } from '../ui-element.js';
import {
    closestByCond,
    hide,
    position,
    show,
    updateClassList,
    updateElement,
} from '../../global/ui-helpers.js';
import { buildNewWindowIcon, buildTabsBadge } from './ui-tabs.helpers.js';
import { isKeyPressed, keyCodes, TabIndex } from '../../global/keyboard.js';
import { Digest } from '../../global/digest.js';
import { Labels } from '../../global/labels.js';

import styles from './ui-tabs.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UITabs
 * @element ui-tabs
 * @classdesc Represents a class for <code>ui-tabs</code> element. Accepts only UITab children.
 * @property {("outer" | "inner" | "buttons")} [layout="outer"] {@attr layout} Layout of the tabs.
 *  {@desc outer: default tabs}
 *  {@desc inner: tabs used as sections}
 *  {@desc buttons: NB! This is EXPERIMENTAL layout for marketing purposes, use it only on own risk,
 *  might be removed in future.}
 * @property {string} [label] {@attr label} Label for dropdown.
 * @property {("vertical" | "dropdown" | "menu")} [layoutMobile] {@attr layout-mobile}
 *   Layout for smaller screen.
 *  {@desc vertical: vertically aligned tabs}
 *  {@desc dropdown: uses native select as dropdown}
 *  {@desc menu: displays tabs as dropdown menu}
 * @property {boolean} [focusonchange=false] {@attr focusonchange} Force focus to
 * the first interactive element
 * @property {HTMLUListElement} captions {@readonly}
 * @property {UIViews} views {@readonly}
 * @property {NodeList<HTMLButtonElement>} controls {@readonly} Collection of tab buttons.
 * @property {HTMLSelectElement} select {@readonly}
 * @property {UIDropdown} dropdown {@readonly}
 * @property {UIField} dropdownField {@readonly}
 * @slot {@type "ui-tab"}
 * @example
 <ui-tabs>
   <ui-tab label="Tab 1">
     Tab 1 content
   </ui-tab>
   <ui-tab label="Tab 2" active="true">
    Tab 2 content
   </ui-tab>
   <ui-tab label="Tab 3" label-badge="Badge">
    Tab 3 content
   </ui-tab>
 </ui-tabs>
 */
class UITabs extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: UITabs.LAYOUT.OUTER },
                layoutMobile: String,
                label: String,
                focusonchange: Boolean,
            },
            children: {
                select: '.ui-tabs__dropdown select',
                dropdownField: '.ui-tabs__dropdown',
                dropdown: '.ui-tabs__dropdown ui-dropdown',
            },
        };
    }

    /**
     * @type {{OUTER: string, INNER: string}}
     */
    static get LAYOUT() {
        return {
            OUTER: 'outer',
            INNER: 'inner',
            BUTTONS: 'buttons',
        };
    }

    /**
     * @type {{DROPDOWN: string, MENU: string}}
     */
    static get LAYOUT_MOBILE() {
        return {
            DROPDOWN: 'dropdown',
            MENU: 'menu',
        };
    }

    static get WATCHED_ATTRIBUTES() {
        return ['label', 'label-badge', 'link-to', 'target', 'active'];
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['layout-mobile', 'focusonchange'];
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-tabs', {
            arrowKeys: 'Arrow keys can navigate between tabs',
            selectTab: 'Select tab',
        });
    }

    get captions() {
        return this.queryChildren('.ui-tabs__captions')[0];
    }

    get views() {
        return this.queryChildren('.ui-tabs__views')[0];
    }

    get controls() {
        return this.captions.querySelectorAll('.ui-tabs__control');
    }

    /**
     * Checks if layout list contains mobile layout.
     * @returns {boolean}
     * @private
     */
    containsLayoutMobile() {
        const layoutMobile = this.layoutMobile;
        const layouts = [
            UITabs.LAYOUT_MOBILE.DROPDOWN,
            UITabs.LAYOUT_MOBILE.MENU,
        ];
        return !!layouts.find((layout) => layout === layoutMobile);
    }

    /**
     * Gets control element.
     * @param {string} viewId
     * @returns {string | null}
     */
    getControlId(viewId) {
        return viewId ? viewId + '-control' : null;
    }

    /**
     * Shows tab contents.
     */
    showContent() {
        this.updateClassList({
            '-nocontent': false,
        });
    }

    /**
     * Hides tab content, only captions are presented.
     */
    hideContent() {
        this.updateClassList({
            '-nocontent': true,
        });
    }

    /**
     * Changes active tab.
     * @returns {UITabs}
     */
    changeActiveTab() {
        this.observeStop();
        this.captions
            .querySelectorAll('.ui-tabs__caption')
            .forEach((node, index) => {
                updateClassList(node, {
                    '-active': index === this.views.selectedIndex,
                });
                const control = node.querySelector('.ui-tabs__control');
                control.setAttribute(
                    'aria-selected',
                    String(index === this.views.selectedIndex)
                );
            });

        if (this.dropdownField) {
            /** @type {UIDropdown | HTMLSelectElement} */
            const element =
                this.dropdown && this.dropdown.nativeSelect
                    ? this.dropdown
                    : this.select;
            element.selectedIndex = this.views.selectedIndex;
        }
        this.observe();
        return this;
    }

    /**
     * Fires callback when the popover state has been changed.
     * @private
     * @returns {UITabs}
     */
    handleChangeAppearance() {
        const controlField = closestByCond(this.dropdownField, (node) => {
            return !!node.tagName && node.tagName.toLowerCase() === 'ui-field';
        });

        if (this.mobileMediaQueryList && this.mobileMediaQueryList.matches) {
            show(controlField);
            hide(this.captions);
        } else {
            // Hide popover when not matched the media query.
            if (this.dropdown) {
                this.dropdown.expanded = false;
            }
            show(this.captions);
            if (controlField) {
                hide(controlField);
            }
        }

        return this;
    }

    /**
     * Fires callback when the tab caption has been clicked.
     * @private
     * @param {PointerEvent} e
     */
    handleChangeTab(e) {
        const caption = e.target.closest('.ui-tabs__caption');
        const elem = e.target;
        if (elem.localName === 'a' && elem.target) {
            return;
        }
        this.views.selectByIndex(position(caption));
    }

    /**
     * Fires callback when the tab caption has been clicked.
     * @private
     * @param {MouseEvent} e
     */
    handleChangeView(e) {
        if (e.target.nodeName !== 'UI-VIEWS') {
            return;
        }
        this.changeActiveTab();
    }

    /**
     * Checks if has some 'active' attribute set.
     * @returns {boolean}
     * @private
     */
    hasAnyActiveAttribute() {
        return [...this.children].some((e) => e.hasAttribute('active'));
    }

    /**
     * Gets active tab index.
     * @returns {number}
     */
    getFocusedControlIndex() {
        try {
            return Number(document.activeElement.dataset.tabIndex);
        } catch (ex) {
            return 0;
        }
    }

    /**
     * @param {UITab} tab
     * @param {number} i
     * @returns {IElementConfig}
     */
    renderControl(tab, i) {
        const linkTo = tab.linkTo;
        const hasLink = !!linkTo;
        const target = tab.target;

        if (!tab.hasAttribute('id')) {
            tab.id = Digest.randomId();
        }
        /**
         * @type {IElementConfig}
         */
        const config = {
            tagName: hasLink ? 'a' : 'button',
            events: {},
            attributes: {
                target: target || null,
                rel: target ? 'noopener' : null,
                role: 'tab',
                'data-tab-index': String(i),
                tabindex: String(this.resolveRenderTabindex(tab, i)),
                'aria-controls': tab.id,
                'aria-selected': tab.active,
            },
            classList: {
                'ui-tabs__control': true,
            },
            children: [
                {
                    tagName: 'span',
                    classList: { 'ui-tabs__text': true },
                    children: [document.createTextNode(tab.label)],
                },
            ],
        };

        if (tab.labelBadge) {
            config.children.push(buildTabsBadge(tab.labelBadge));
        }

        if (!hasLink) {
            config.attributes.type = 'button';
        } else {
            config.attributes.href = linkTo;
            if (target) {
                config.children.push(buildNewWindowIcon());
            }
        }
        return config;
    }

    /**
     * Resolves tab index on render.
     * @param {Node} node
     * @param {number} i
     * @returns {number}
     * @private
     */
    resolveRenderTabindex(node, i) {
        return (i === 0 && !this.hasAnyActiveAttribute()) ||
            node.hasAttribute('active')
            ? TabIndex.Active
            : TabIndex.Inactive;
    }
    /**
     * Removes dropdownField element
     * @private
     * @returns {UITabs}
     */
    removeLayoutMobile() {
        if (this.dropdownField) {
            this.removeChild(this.dropdownField);
        }
        return this;
    }

    /**
     * Renders select option.
     * @param {UITab} tab
     * @returns {IElementConfig}
     */
    renderSelectOption(tab) {
        /**
         * @type {IElementConfig}
         */
        return {
            tagName: 'option',
            children: [document.createTextNode(tab.label || '')],
            attributes: {
                'data-badge': tab.labelBadge || null,
                'data-link-to': tab.linkTo || null,
                'data-target': tab.target || null,
                'data-layout': this.layout,
                selected: tab.active ? 'selected' : null,
            },
        };
    }

    /**
     * Renders mobile layouts in dropdown and menu
     * @param {Array<UITab>} tabs
     * @returns {UITabs}
     */
    renderLayoutMobile(tabs) {
        if (this.containsLayoutMobile()) {
            const selectElement = this.createElement({
                tagName: 'select',
                attributes: {
                    'aria-label': UITabs.labels.selectTab,
                },
                classList: {
                    '-long': true,
                },
                children: tabs.map(this.renderSelectOption.bind(this)),
            });
            const dropdownField = this.createElement({
                tagName: 'ui-field',
                attributes: {
                    class: 'ui-tabs__dropdown',
                    label: this.label,
                    layout: 'fluid',
                },
                children: [selectElement],
            });
            if (this.layoutMobile === UITabs.LAYOUT_MOBILE.MENU) {
                dropdownField.updateElement({
                    children: [
                        {
                            tagName: 'ui-dropdown',
                            attributes: {
                                layout: 'tab',
                                type: 'tabs',
                                'hide-selected': true,
                            },
                            children: [selectElement],
                        },
                    ],
                });
            }
            this.insertBefore(dropdownField, this.firstChild);
        }
        return this;
    }

    /**
     * Renders caption
     * @param {UITab} tab
     * @param {number} i
     * @private
     * @returns {IElementConfig}
     */
    renderCaption(tab, i) {
        /**
         * @type {IElementConfig}
         */
        return {
            tagName: 'li',
            attributes: {
                id: this.getControlId(tab.getAttribute('id')),
            },
            classList: {
                'ui-tabs__caption': true,
            },
            children: [this.renderControl(tab, i)],
        };
    }

    /**
     * Handles component mutations
     * @param {MutationRecord} mutation
     * @private
     */
    handleAttributesMutation(mutation) {
        const attribute = mutation.attributeName;
        const target = mutation.target;
        if (['label', 'label-badge', 'link-to', 'target'].includes(attribute)) {
            const tab = /** @type {UITab} */ target;
            const index = [...this.views.children].findIndex(
                (view) => view === tab
            );
            const caption = this.captions.children[index];
            const config = this.renderControl(tab, index);
            const control = this.createElement(config);
            caption.replaceChild(control, caption.firstChild);
            this.subscribeClickEvent(control);
            if (this.select) {
                const oldOption = this.select.children[index];
                const newOption = this.createElement(
                    this.renderSelectOption(tab)
                );
                this.select.replaceChild(newOption, oldOption);
            }
        } else if (mutation.attributeName === 'active') {
            this.changeActiveTab();
        }
    }

    /**
     * Fires callback when component mutates.
     * @param {Array<MutationRecord>} mutations
     * @private
     */
    handleMutations(mutations) {
        for (let i = 0; i < mutations.length; ++i) {
            const mutation = mutations[i];
            const target = mutation.target;
            if (!(target instanceof UITab)) {
                continue;
            }
            const parentTabs = target.closest('ui-tabs');
            if (parentTabs !== this) {
                continue;
            }
            if (mutation.type === 'attributes') {
                this.handleAttributesMutation(mutation);
            }
        }
    }

    /**
     * Starts observing component
     * @private
     */
    observe() {
        if (!this.viewsObserver) {
            return;
        }
        this.viewsObserver.observe(this.views, {
            childList: true,
            subtree: true,
            characterData: true,
            attributes: true,
            attributeFilter: UITabs.WATCHED_ATTRIBUTES,
        });
    }

    /**
     * Stops observing component
     * @private
     */
    observeStop() {
        if (this.viewsObserver) {
            this.viewsObserver.disconnect();
        }
    }

    /**
     * Adds click event listener for tab control button.
     * @param {HTMLElement} control
     */
    subscribeClickEvent(control) {
        updateElement(control, {
            events: {
                click: this.handleChangeTab.bind(this),
            },
        });
    }

    /**
     * Adds keydown event listener for tab control button (role="tab" as well).
     * @param {HTMLElement} control
     */
    subscribeKeydownEvent(control) {
        updateElement(control, {
            events: {
                keydown: (event) => {
                    if (
                        isKeyPressed(event, keyCodes.SPACE) &&
                        event.target.getAttribute('role') === 'tab'
                    ) {
                        event.preventDefault();
                        event.target.click();
                    }
                },
            },
        });
    }

    /**
     * Listens to DOM events
     * @private
     * @returns {UITabs}
     */
    listenEvents() {
        updateElement(this.views, {
            events: {
                change: this.handleChangeView.bind(this),
            },
        });

        this.controls.forEach((btn) => {
            this.subscribeClickEvent(btn);
            this.subscribeKeydownEvent(btn);
        });
        return this;
    }

    /**
     * Listens mobile-layout events
     * @private
     * @returns {UITabs}
     */
    listenLayoutEvents() {
        if (this.containsLayoutMobile()) {
            updateElement(this.select, {
                events: {
                    change: (e) => {
                        const option = e.target.selectedOptions[0];
                        if (option && option.dataset.linkTo) {
                            window.open(
                                option.dataset.linkTo,
                                option.dataset.target || '_self'
                            );
                            e.target.selectedIndex = this.views.selectedIndex;
                        } else {
                            this.views.selectByIndex(e.target.selectedIndex);
                        }
                    },
                },
            });
            this.mobileMediaQueryList = window.matchMedia(
                'screen and (max-width: 767px)'
            );
            this.mobileMediaQueryList.addListener(
                this.handleChangeAppearance.bind(this)
            );
        } else {
            this.mobileMediaQueryList = null;
        }

        return this;
    }

    /**
     * Activates default view for views
     * @param {Array<UITab>} tabs
     * @returns {UITabs}
     */
    activateDefaultView(tabs) {
        this.views.selectedIndex = tabs.findIndex((tab) => tab.active);
        this.views.activateDefaultView();
        return this;
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }
        const tabs = this.views.queryChildren('ui-tab');
        switch (name) {
            case 'layout-mobile':
                this.removeLayoutMobile()
                    .renderLayoutMobile(tabs)
                    .listenLayoutEvents()
                    .handleChangeAppearance();
                break;
            case 'focusonchange':
                updateElement(this.views, {
                    attributes: {
                        focusonchange: this.focusonchange ? true : null,
                    },
                });
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * Handles key press.
     * @param {KeyboardEvent} event
     * @private
     */
    handleKeyDown(event) {
        const len = this.views.children.length;
        switch (event.keyCode) {
            case keyCodes.RIGHT:
            case keyCodes.UP:
                this.focusToNextTab();
                event.preventDefault();
                break;
            case keyCodes.LEFT:
            case keyCodes.DOWN:
                this.focusToPreviousTab();
                event.preventDefault();
                break;
            case keyCodes.HOME:
                this.focusControlByIndex(0);
                event.preventDefault();
                break;
            case keyCodes.END:
                this.focusControlByIndex(len - 1);
                event.preventDefault();
                break;
            default:
                this.focusByKey(event.key);
                break;
        }
    }

    /**
     * Switches to next tab.
     */
    focusToNextTab() {
        const len = this.views.children.length;
        let idx = this.getFocusedControlIndex();
        if (++idx >= len) {
            idx = 0;
        }
        this.focusControlByIndex(idx);
    }

    /**
     * Switches to previous tab.
     */
    focusToPreviousTab() {
        const len = this.views.children.length;
        let idx = this.getFocusedControlIndex();
        if (--idx < 0) {
            idx = len - 1;
        }
        this.focusControlByIndex(idx);
    }

    /**
     * Foucses tab by it's index.
     * @param {number} idx
     */
    focusControlByIndex(idx) {
        try {
            this.resetControlsIndexes();
            const control = this.querySelector(
                `.ui-tabs__control[data-tab-index="${idx}"]`
            );
            const controls = this.querySelectorAll(
                '.ui-tabs__control[data-tab-index]'
            );
            controls.forEach((c) => c.setAttribute('aria-selected', 'false'));
            const parent = control.closest('li');
            if (parent && parent.classList.contains('-active')) {
                control.setAttribute('aria-selected', 'true');
            }
            control.tabIndex = TabIndex.Active;
            control.focus();
        } catch (ex) {
            // Not found, do something?
        }
    }

    /**
     * Focuses the tab by key character.
     * @param {string} key
     */
    focusByKey(key) {
        const controls = [].filter.call(this.controls, (ctrl) => {
            return (
                ctrl.innerText.toString().toLowerCase().charAt(0) ===
                key.toLowerCase()
            );
        });
        let idx = controls.indexOf(document.activeElement);
        idx = idx < 0 || idx >= controls.length - 1 ? 0 : ++idx;
        if (controls[idx]) {
            this.focusControlByIndex(+controls[idx].dataset.tabIndex);
        }
    }

    /**
     * Resets all controls tabIndexes and set to -1;
     * @private
     */
    resetControlsIndexes() {
        this.controls.forEach((c) => (c.tabIndex = TabIndex.Inactive));
    }

    /**
     * @inheritDoc
     */
    render() {
        const elements = [];
        const tabs = this.queryChildren('ui-tab');
        this.redetachChildNodes();
        const captions = this.createElement({
            tagName: 'ul',
            classList: {
                'ui-tabs__captions': true,
            },
            attributes: {
                role: 'tablist',
                'aria-label': UITabs.labels.arrowKeys,
            },
            children: [...tabs].map(this.renderCaption.bind(this)),
        });
        elements.push(captions);

        const views = this.createElement({
            tagName: 'ui-views',
            classList: {
                'ui-tabs__views': true,
            },
            attributes: {
                filter: 'ui-tab',
                focusonchange: this.focusonchange ? true : null,
            },
            children: tabs,
        });
        elements.push(views);

        this.renderLayoutMobile(
            /** @type {Array<UITab>} */ tabs
        ).insertElements(elements);
        this.activateDefaultView(/** @type {Array<UITab>} */ tabs)
            .changeActiveTab()
            .handleChangeAppearance();
        this.updateElement({
            attributes: {
                layout: this.layout,
            },
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.listenEvents().listenLayoutEvents().handleChangeAppearance();

        this.views.querySelectorAll('ui-tab').forEach((tab) => {
            tab.addEventListener('focus', () => {
                [].forEach.call(this.controls, (control) => {
                    control.tabIndex =
                        +control.dataset.tabIndex === +this.views.selectedIndex
                            ? TabIndex.Active
                            : TabIndex.Inactive;
                });
            });
        });

        this.captions.addEventListener(
            'keydown',
            this.handleKeyDown.bind(this)
        );
        this.viewsObserver = new MutationObserver(
            this.handleMutations.bind(this)
        );
        this.observe();
    }
}

UITabs.defineElement('ui-tabs', styles);
export { UITabs };

import { UIElement } from '../ui-element.js';
import styles from './ui-navlist-menu.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UINavListMenu
 * @element ui-navlist-menu
 * @property {string} [label] {@attr label} Title for the menu.
 * @classdesc Represents a class for <code>ui-navlist-menu</code> element
 * @example
 * <ui-navlist-menu></ui-navlist-menu>
 */
class UINavListMenu extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: String,
            },
        };
    }

    static get observedAttributes() {
        return ['label'];
    }

    /**
     * Sets caption value from the caption attribute
     */
    updateCaption() {
        let el = this.querySelector('.ui-navlist-menu__caption');
        if (!el) {
            el = this.createElement({
                tagName: 'h3',
                classList: {
                    'ui-navlist-menu__caption': true,
                },
            });
            this.insertBefore(el, this.firstChild);
        }
        el.innerHTML = '';
        el.appendChild(document.createTextNode(this.label));
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'label':
                this.updateCaption();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'menu');
        this.updateClassList({ '-secondary': true }).insertElements([
            this.label && {
                tagName: 'h3',
                classList: {
                    'ui-navlist-menu__caption': true,
                },
                children: [this.label],
            },
            {
                tagName: 'div',
                classList: {
                    'ui-navlist-menu__nav': true,
                },
                children: [
                    {
                        tagName: 'ul',
                        classList: {
                            'ui-navlist-menu__items': true,
                        },
                        children: this.childNodes,
                    },
                ],
            },
        ]);
    }
}

UINavListMenu.defineElement('ui-navlist-menu', styles);
export { UINavListMenu };

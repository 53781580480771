/**
 * @name OverlayHandler
 * @function
 * @param {UIIFrameController} controller
 * @param {CustomEvent | MediaQueryListEvent | Event} e
 */

import { MessageType } from './message-type.js';
import { UIModal } from '../modal/ui-modal.js';
import { browser } from '../../global/browser.js';

/**
 * Edge case when menu inside nav-toggle.
 * @param {EventTarget | Element} target
 * @returns {boolean}
 */
const shouldMenuToggle = (target) => !target.closest('ui-modal,ui-navtoggle');

/**
 * @param {UIIFrameController} controller
 * @returns {boolean}
 */
const getIsDesktopMenu = (controller) => {
    return controller.getMainMenu()?.dropdown || browser.desktopMediaMatches();
};

/**
 * Edge case when tooltip is the last popover.
 * @param {UIIFrameController} controller
 * @returns {boolean}
 */
const isTooltipLast = (controller) => {
    const t = controller.modalController.getActiveModals().pop();
    return t && t.classList.contains('-tooltip');
};

/**
 * @type {OverlayHandler}
 */
export const mainMenuOverlayHandler = (controller, e) => {
    const isDesktopMenu = getIsDesktopMenu(controller);

    switch (e.type) {
        case 'menu-open':
            if (
                !shouldMenuToggle(e.target) ||
                (controller.menuOpened && isDesktopMenu)
            ) {
                break;
            }
            controller.sendOverlayMessage(MessageType.OverlayOpened, {
                animate: isDesktopMenu,
                blockScroll: !isDesktopMenu,
                closable: true,
                partial: true,
            });
            controller.menuOpened = true;
            break;
        case 'menu-close':
            if (!shouldMenuToggle(e.target)) {
                break;
            }
            controller.sendOverlayMessage(MessageType.OverlayClosed, {
                animate: isDesktopMenu,
                blockScroll: false,
                closable: true,
                partial: true,
            });
            controller.menuOpened = false;
            break;
    }
};

/**
 * @type {OverlayHandler}
 */
export const modalOverlayHandler = (controller, e) => {
    // Normal modals use event 'modal-open-enter' and 'modal-close-enter'.
    // All popover like uses mostly 'modal-updatelist'.
    const isPopover = e.target.type === UIModal.types.POPOVER;
    const isFixedAlignment = e.target.align === 'fixed';
    const modalAction = e.detail?.action || '';
    const isRemovedAction = modalAction === 'remove';
    const isAddedAction = modalAction === 'add';
    const isNavToggle = e.target.classList.contains('-navtoggle');
    const hasLastOverlay = controller.hasLastOverlay();
    const isNotBubble = e.target.type !== UIModal.types.BUBBLE;

    switch (e.type) {
        case 'modal-open-enter':
            if (!isPopover && !isFixedAlignment) {
                controller.sendOverlayMessage(MessageType.OverlayOpened, {
                    animate: true,
                    blockScroll: isNotBubble,
                    closable: !!e.target.closable,
                    partial: false,
                });
            }
            break;
        case 'modal-close-enter': {
            if (!isPopover && !isFixedAlignment) {
                controller.sendOverlayMessage(MessageType.OverlayClosed, {
                    animate: true,
                    blockScroll: !hasLastOverlay && isNotBubble,
                    closable: true,
                    partial: false,
                });
            }
            break;
        }
        case 'modal-updatelist':
            if (isPopover && isFixedAlignment) {
                const hasOverlay =
                    isAddedAction || !(isRemovedAction || isNavToggle);
                const blockScroll = isAddedAction || hasLastOverlay;
                const type = hasOverlay
                    ? MessageType.OverlayOpened
                    : MessageType.OverlayClosed;
                controller.sendOverlayMessage(type, {
                    animate: false,
                    blockScroll,
                    closable: true,
                    partial: isNavToggle,
                });
                controller.popoverOpened = isAddedAction;
            }
            break;
    }
};

/**
 * @type {OverlayHandler}
 */
export const handleMenuOnResize = (controller, e) => {
    if (isTooltipLast(controller)) {
        return;
    }
    // Really weird and complex menu/navtoggle logic.
    const isMobileMediaMatches = e.matches;
    const type = isMobileMediaMatches
        ? MessageType.OverlayOpened
        : MessageType.OverlayClosed;
    const tempFn = (type) => {
        controller.sendOverlayMessage(type, {
            animate: !isMobileMediaMatches,
            blockScroll: isMobileMediaMatches,
            closable: true,
            partial: true,
        });
    };
    if (controller.popoverOpened) {
        tempFn(type);
        return;
    }
    if (controller.menuOpened) {
        controller.menuOpened = false;
        controller.popoverOpened = false;
        tempFn(MessageType.OverlayClosed);
    }
};

/**
 * @type {OverlayHandler}
 */
export const handleTooltipsOnResize = (controller, e) => {
    if (!isTooltipLast(controller)) {
        return;
    }
    const isMobileMediaMatches = e.matches;
    const type = isMobileMediaMatches
        ? MessageType.OverlayOpened
        : MessageType.OverlayClosed;
    controller.sendOverlayMessage(type, {
        animate: false,
        blockScroll: isMobileMediaMatches,
        closable: true,
        partial: false,
    });
};

import './ui-modal.js';
import { UIElement } from '../ui-element.js';
import { UIModal } from '../modal/ui-modal.js';
import { isKeyPressed, keyCodes } from '../../global/keyboard.js';
import { nextFrame } from '../../global/helpers.js';
import { isInFixedLayer } from '../../global/helpers.js';
import { eventBus } from '../../global/event-bus.js';
import {
    createElement,
    dispatchCustomEvent,
    isVisible,
} from '../../global/ui-helpers.js';
import styles from './ui-modal-controller.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIModalController
 * @element ui-modal-controller
 * @classdesc Represents a class for <code>ui-modal-controller</code> element.
 * Technical element to control modals. Open, close, place, etc. modals correctly,
 * based on events.
 * @fires event:modal-close
 * @listens keydown
 * @listens event:modal-open
 * @listens event:modal-open-enter
 * @listens event:modal-close
 * @listens event:modal-close-enter
 * @listens event:modal-close-top
 * @listens event:modal-updatelist
 * @listens event:popover-updated
 * @example <ui-modal-controller></ui-modal-controller>
 */
class UIModalController extends UIElement {
    /**
     * @type {string}
     */
    static get FullViewPortModalSelector() {
        return ':not([type="popover"]):not([type="bubble"])';
    }

    /**
     * Gets all currently action modals.
     * @returns {Array<UIModal>}
     */
    getActiveModals() {
        return [].filter.call(
            document.querySelectorAll('ui-modal.-active:not([type="bubble"])'),
            isVisible
        );
    }

    /**
     * Gets all currently active full viewport modals (also popovers[align=fixed] on mobile).
     * @returns {Array<UIModal>}
     */
    getActiveFullViewModals() {
        const isMobile = window.matchMedia(
            'screen and (max-width: 767px)'
        ).matches;
        const isTablet = window.matchMedia(
            'screen and (min-width: 768px) and (max-width: 1023.98px)'
        ).matches;
        const selector =
            'ui-modal.-active' +
            UIModalController.FullViewPortModalSelector +
            (isMobile
                ? ', ui-modal.-active[type="popover"][align="fixed"]'
                : '') +
            (isTablet
                ? ', ui-modal.-active[type="popover"][align="fixed"][tablet-full]'
                : '');
        return [...document.querySelectorAll(selector)].filter(isVisible);
    }

    /**
     * Get the top modal window dialog, not a type popover (no tooltip, no dropdown, etc.).
     * @returns {UIModal}
     */
    getActiveTopModalWindow() {
        const selector =
            'ui-modal.-active' + UIModalController.FullViewPortModalSelector;
        const modals = this.sortModalsByOrder(
            [].filter.call(document.querySelectorAll(selector), isVisible)
        );
        if (modals.length > 0) {
            return modals[modals.length - 1];
        }
        return null;
    }

    /**
     * Sort all modals by order
     * @param {Array<UIModal>} modals
     * @param {boolean} [reverse]
     * @returns {Array<UIModal>}
     */
    sortModalsByOrder(modals, reverse = false) {
        const sortFn = (a, b) =>
            a.order > b.order
                ? reverse
                    ? -1
                    : 1
                : a.order === b.order
                  ? 0
                  : -1;
        return [...modals].sort(sortFn);
    }

    /**
     * Shifted modals are moved a bit by side.
     * @private
     * @returns {UIModalController | undefined}
     */
    shiftModalWindow() {
        const modals = this.getActiveFullViewModals().filter(
            (m) => !['popover', 'bubble'].includes(m.type)
        );
        const unshiftedModals = this.sortModalsByOrder(modals).filter(
            (m) => !m.shifted
        );

        if (unshiftedModals.length < 2) {
            return;
        }

        /** @type {UIModal} */
        const modal = unshiftedModals[0];

        if (modal && modal.isVisible()) {
            modal.shifted = true;
        }
        return this;
    }

    /**
     * Un-shifted modals are placed exactly under each-other.
     * @private
     * @returns {UIModalController}
     */
    unshiftModalWindow() {
        const modals = this.getActiveFullViewModals().filter(
            (m) => !['popover', 'bubble'].includes(m.type)
        );
        const shiftedModals = this.sortModalsByOrder(modals).filter(
            (m) => m.shifted
        );

        /** @type {UIModal} */
        const modal = shiftedModals[shiftedModals.length - 1];

        if (modal && modal.isVisible()) {
            modal.shifted = false;
        }
        return this;
    }

    /**
     * Create a new window by given params.
     * @param {IModalConfig} config
     * @returns { * | UIModal }
     */
    createNewModal(config) {
        const params = config.params || {};
        const attributes = params.attributes || {};
        attributes.type = config.type;
        attributes.dynamic = true;
        // TODO: Remove it later, use general solution for attributes
        if (params.hasOwnProperty('id')) {
            attributes.id = params.id;
        }

        const isFixed = isInFixedLayer(config.params && config.params.target);
        /**
         * @type {IElementConfig}
         */
        const element = {
            tagName: 'ui-modal',
            attributes: attributes,
            classList: {
                '-position-fixed': isFixed,
            },
            events: {},
            children: [],
        };

        if (
            [UIModal.types.ALERT, UIModal.types.CONFIRMATION].includes(
                config.type
            )
        ) {
            element.children.push(
                {
                    tagName: 'h2',
                    children: [config.content.title],
                },
                {
                    tagName: 'p',
                    children: [config.content.description],
                }
            );
        }

        if (
            [UIModal.types.POPOVER, UIModal.types.BUBBLE].includes(
                config.type
            ) &&
            config.content instanceof HTMLElement
        ) {
            element.children.push(config.content);
            element.attributes.bubble = config.type === UIModal.types.BUBBLE;
            const target = config.content;
            const nextPosition = target.nextSibling;
            const parent = target.parentElement;
            element.events['modal-updatelist'] = (event) => {
                if (parent && event.detail.action === 'remove') {
                    if (nextPosition) {
                        parent.insertBefore(target, nextPosition);
                    } else {
                        parent.appendChild(target);
                    }
                }
            };
        }

        if (
            [UIModal.types.PAGE, UIModal.types.ELEMENT].includes(config.type) &&
            config.content instanceof HTMLElement
        ) {
            element.children.push(config.content);
        }

        if (config.params) {
            // TODO: remove it later, use
            if (config.params.hasOwnProperty('compact')) {
                console.warn(
                    'The property config.params.compact is deprecated.' +
                        ' Use config.params.attributes for additional attributes instead'
                );
                element.attributes['compact'] = String(config.params.compact);
            }
            if (config.params.hasOwnProperty('closable')) {
                element.attributes['closable'] = String(config.params.closable);
                console.warn(
                    'The property config.params.closable is deprecated.' +
                        ' Use config.params.attributes for additional attributes instead'
                );
            }
            if (config.params.hasOwnProperty('layout')) {
                element.attributes['layout'] = String(config.params.layout);
                console.warn(
                    'The property config.params.layout is deprecated.' +
                        ' Use config.params.attributes for additional attributes instead'
                );
            }
        }

        const modal = createElement(element);
        document.body.appendChild(modal);

        if (config.params && typeof config.params.position === 'function') {
            const handleViewportChanges = () => {
                const pos = config.params.position();
                modal.style.left = pos.hasOwnProperty('x') ? pos.x : null;
                modal.style.top = pos.hasOwnProperty('y') ? pos.y : null;
                modal.setAttribute('align', pos.align);
                modal.setAttribute('shift', pos.shift);
                modal.setAttribute('adjusted', 'adjusted');
            };
            let closestModal;
            const lockId = Date.now();
            if (config.params.target) {
                closestModal = config.params.target.closest('ui-modal');
                if (closestModal) {
                    closestModal.locked = true;
                    closestModal.lockedBy = lockId;
                }
            }
            window.addEventListener('resize', handleViewportChanges);
            eventBus.addEventListener('popover-updated', handleViewportChanges);
            modal.addEventListener('modal-updatelist', (event) => {
                if (event.detail.action === 'add') {
                    handleViewportChanges();
                }
                if (event.detail.action === 'remove') {
                    window.removeEventListener('resize', handleViewportChanges);
                    eventBus.removeEventListener(
                        'popover-updated',
                        handleViewportChanges
                    );
                    if (closestModal && closestModal.lockedBy === lockId) {
                        closestModal.locked = false;
                        closestModal.lockedBy = null;
                    }
                }
            });
        }
        return modal;
    }

    /**
     * If modal is opened and this method recieves 'true' argument it prevents body
     * or other modal to scroll. Otherwise body and other modals scrolling will be
     * enabled.
     * @param {boolean} lock
     */
    toggleBodyScroll(lock) {
        const doc = document.documentElement;
        if (doc.classList.contains('-no-scroll') === lock) {
            /* Is already in correct state */
            return;
        }
        if (lock) {
            const scrollBarWidth = window.innerWidth - doc.clientWidth;
            if (scrollBarWidth > 0) {
                doc.classList.add('-preserve-scroll');
                const scrollTop = doc.scrollTop
                    ? doc.scrollTop
                    : document.body.scrollTop;
                if (scrollTop > 0) {
                    doc.style.top = -1 * scrollTop + 'px';
                }
            }
            doc.classList.add('-no-scroll');
        } else {
            doc.classList.remove('-no-scroll');
            doc.classList.remove('-preserve-scroll');
            const scrollValue = Math.abs(parseInt(doc.style.top));
            if (scrollValue) {
                doc.style.top = null;
                doc.scrollTop = scrollValue;
                if (!doc.scrollTop) {
                    document.body.scrollTop = scrollValue;
                }
            }
        }
    }

    /**
     * Modal open callback.
     * @param {CustomEvent<IModalConfig>} e
     * @private
     */
    handleModalOpen(e) {
        /**
         * @type {IModalConfig}
         */
        const config = e.detail;

        /**
         * @type {UIModal}
         */
        let modal;

        if (!config.selector && !config.content) {
            return;
        }

        if (config.selector) {
            modal = document.querySelector(config.selector);
            if (
                !modal ||
                modal.tagName.toLowerCase() !== 'ui-modal' ||
                modal.isActive()
            ) {
                return;
            }
        }

        if (config.content) {
            modal = this.createNewModal(config);
        }

        const hasActionHandler =
            !!config.params && typeof config.params.onAction === 'function';
        const hasCloseHandler =
            !!config.params && typeof config.params.onClose === 'function';
        const hasOpenHandler =
            !!config.params && typeof config.params.onOpen === 'function';
        const classList = config.params ? config.params.classList || {} : {};
        const closestModal = e.target.closest('ui-modal');
        const modalParent = modal.parentElement;
        const modalNextSibling = modal.nextSibling;
        if (closestModal) {
            classList['-sub-modal'] = true;
        }
        if (modal.detached) {
            document.body.appendChild(modal);
        }

        const modals = this.sortModalsByOrder(this.getActiveModals());
        const order =
            (modals.length ? modals[modals.length - 1].order : modals.length) +
            1;

        modal.updateElement({
            attributes: { order: order },
            classList: classList,
            events: {
                'modal-action': hasActionHandler && config.params.onAction,
                'modal-close': hasCloseHandler && config.params.onClose,
                'modal-updatelist': (event) => {
                    if (event.detail.action === 'add') {
                        if (hasOpenHandler) {
                            config.params.onOpen();
                        }
                        return;
                    }
                    if (modal.detached && event.detail.action === 'remove') {
                        if (modalNextSibling) {
                            modalParent.insertBefore(modal, modalNextSibling);
                        } else {
                            modalParent.appendChild(modal);
                        }
                    }
                },
            },
        });

        const focusFirstElement = () => {
            // Do not save return point of the invoker (like button) in following cases:
            // - ui-annotation
            // - ui-messagebox[bubbles]
            // - dropdowns and autocompletes have own focus system.
            const hasReturnPoint = ![
                '-dropdown',
                '-autocomplete',
                '-messagebox',
                '-annotation',
            ].some((s) => modal.classList.contains(s));
            if (hasReturnPoint) {
                this.focusReturnPoints.push(document.activeElement);
                modal.focus();
            }
        };

        nextFrame(() => {
            const modalOpenComplete = () => {
                modal.removeEventListener('transitionend', modalOpenComplete);
                focusFirstElement();
            };

            modal.open();
            if (modal.isAnimated()) {
                modal.addEventListener('transitionend', modalOpenComplete);
            } else {
                focusFirstElement();
            }
        });
    }

    /**
     * Modal close callback.
     * @param {CustomEvent<IModalConfig>} e
     * @private
     */
    handleModalClose(e) {
        /** @type {UIModal} */
        const modal = e.target.closest('ui-modal');
        if (modal && ![UIModal.types.POPOVER].includes(modal.type)) {
            modal.close();
            if (this.focusReturnPoints.length > 0) {
                const target = this.focusReturnPoints.pop();
                if (target) {
                    target.focus();
                }
            }
        }
    }

    /**
     * Closes top active modal window.
     * @param {CustomEvent<IModalConfig>} e
     */
    handleTopModalClose(e) {
        const modal = this.getActiveTopModalWindow();
        if (modal) {
            modal.close();
        }
    }

    /**
     * Modal update list callback.
     * @param {CustomEvent<IModalConfig>} e
     * @private
     */
    handleUpdateList(e) {
        const modal = e.target;
        const order = modal.getAttribute('order');
        const isPopover = [
            UIModal.types.POPOVER,
            UIModal.types.BUBBLE,
        ].includes(modal.type);

        if (!isPopover || modal.align === 'fixed') {
            const activeModals = this.getActiveFullViewModals();
            this.toggleBodyScroll(activeModals.length > 0);
        }

        if (!isPopover && order > 1) {
            switch (e.detail.action) {
                case 'add':
                    this.shiftModalWindow();
                    break;
                case 'remove':
                    this.unshiftModalWindow();
                    break;
            }
        }
    }

    /**
     * Fires callback when user presses esc.
     * @param {KeyboardEvent} event
     * @private
     */
    handleKeyDown(event) {
        if (event.defaultPrevented) {
            return;
        }
        if (isKeyPressed(event, keyCodes.ESCAPE)) {
            this.handleEscapePressed(event);
        } else if (isKeyPressed(event, keyCodes.TAB)) {
            this.handleTabPressed(event);
        }
    }

    handleTabPressed(event) {
        if (event.defaultPrevented) {
            return;
        }

        const modals = [
            ...document.querySelectorAll('ui-modal.-active'),
        ].filter(isVisible);
        if (!modals.length) {
            return;
        }

        /** @type {UIModal} */
        const topModal = modals.pop();
        event.preventDefault();

        const elements = topModal.getInteractiveElements();
        if (!elements.length) {
            return;
        }
        const index = elements.indexOf(document.activeElement);
        if (index === -1) {
            elements[event.shiftKey ? elements.length - 1 : 0].focus();
            return;
        }

        if (!event.shiftKey && index === elements.length - 1) {
            elements[0].focus();
            return;
        }

        if (index === 0 && event.shiftKey) {
            elements[elements.length - 1].focus();
            return;
        }

        elements[index + (event.shiftKey ? -1 : 1)].focus();
    }

    /**
     * @param {CustomEvent<IModalConfig>} event
     * @fires event:modal-close
     */
    handleEscapePressed(event) {
        /**
         * @type {NodeList<UIModal>}
         */
        const activeModals = document.querySelectorAll(
            'ui-modal.-active:not(.-annotation)'
        );

        /**
         * @type {Array<UIModal>}
         */
        const modals = [].filter.call(activeModals, isVisible);
        if (!modals.length) {
            return;
        }

        /**
         * @type {UIModal}
         */
        const topModal = this.sortModalsByOrder(modals).pop();
        if (!topModal.closable) {
            return;
        }

        event.preventDefault();
        dispatchCustomEvent(topModal, 'modal-close');
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        document.removeEventListener('keydown', this.handleKeyDown);
        eventBus.removeEventListener('modal-open', this.handleModalOpen);
        eventBus.removeEventListener('modal-close', this.handleModalClose);
        eventBus.removeEventListener(
            'modal-close-top',
            this.handleTopModalClose
        );
        eventBus.removeEventListener('modal-updatelist', this.handleUpdateList);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        document.addEventListener('keydown', this.handleKeyDown);
        eventBus.addEventListener('modal-open', this.handleModalOpen);
        eventBus.addEventListener('modal-close', this.handleModalClose);
        eventBus.addEventListener('modal-close-top', this.handleTopModalClose);
        eventBus.addEventListener('modal-updatelist', this.handleUpdateList);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleModalOpen = this.handleModalOpen.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleUpdateList = this.handleUpdateList.bind(this);
        this.handleTopModalClose = this.handleTopModalClose.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        document.addEventListener('keydown', this.handleKeyDown);
        eventBus.addEventListener('modal-open', this.handleModalOpen);
        eventBus.addEventListener('modal-close', this.handleModalClose);
        eventBus.addEventListener('modal-close-top', this.handleTopModalClose);
        eventBus.addEventListener('modal-updatelist', this.handleUpdateList);

        this.focusReturnPoints = [];
    }
}

UIModalController.defineElement('ui-modal-controller', styles);
export { UIModalController };

import { UIView } from '../views/ui-view.js';
import { TabIndex } from '../../global/keyboard.js';
import styles from './ui-tab.css';

/**
 * @memberof SharedComponents
 * @augments {UIView}
 * @alias UITab
 * @element ui-tab
 * @implements {IActivatable}
 * @classdesc Represents a class for <code>ui-tab</code> element.
 * Single tab element, should be child of ui-tabs container.
 * Can be only child of UITabs.
 * @property {string} [labelBadge] {@attr label-badge} Label for orange badge.
 * @property {string} [linkTo] {@attr link-to} Link for orange badge.
 * @property {"_self" | "_blank"} [target] {@attr target}
 * @property {boolean} [active] {@attr active} Make current tab active.
 * @slot
 * @example <ui-tab>Content here</ui-tab>
 */
class UITab extends UIView {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                labelBadge: String,
                linkTo: String,
                target: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttributes({
            role: 'tabpanel',
            tabindex: this.getAttribute('tabindex') || TabIndex.Active,
        });
    }
}

UITab.defineElement('ui-tab', styles);
export { UITab };

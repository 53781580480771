import { browser } from '../../global/browser.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for lazy-loading marketing plugin.
 * @alias LazyLoadingPlugin
 * @param {UIElement} target
 * @implements {IComponentPlugin}
 */
class LazyLoadingPlugin {
    constructor(target) {
        /**
         * @type {UIElement|HTMLElement}
         */
        this.target = target;
    }

    /**
     * Intersection threshold, 0 - when the first pixel is intersected, 1 - when all pixels,
     * are intersected.
     * @type {number}
     */
    static get THRESHOLD() {
        return 0;
    }

    /**
     * Intersection handler.
     * @param {Array<IntersectionObserverEntry>} entries
     * @param {IntersectionObserver} observer
     * @private
     */
    intersectionHandler(entries, observer) {
        [].forEach.call(entries, (entry) => {
            if (entry.isIntersecting) {
                const images = entry.target.querySelectorAll('img[data-src]');
                [].forEach.call(images, (img) => {
                    img.src = img.dataset.src;
                    delete img.dataset.src;
                    // When image is loaded we don't need observer anymore.
                    observer.disconnect();
                });
            }
        });
    }

    /**
     * @inheritDoc
     */
    render() {
        if (browser.supportsIntersectionObserver()) {
            const images = this.target.querySelectorAll('img[src]');
            [].forEach.call(images, (img) => {
                if (img.src) {
                    img.dataset.src = img.src;
                    img.removeAttribute('src');
                }
            });
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (browser.supportsIntersectionObserver()) {
            const params = {
                rootMargin: '0px',
                threshold: LazyLoadingPlugin.THRESHOLD,
            };
            this.observer = new IntersectionObserver(
                this.intersectionHandler,
                params
            );
            this.observer.observe(this.target);
        }
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        if (browser.supportsIntersectionObserver()) {
            this.observer.disconnect();
        }
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        if (browser.supportsIntersectionObserver()) {
            this.observer.observe(this.target);
        }
    }
}

export { LazyLoadingPlugin };

import { UIElement } from '../ui-element.js';
import styles from './ui-option-plain.css';
import { browser } from '../../global/browser.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionPlain
 * @element ui-option-plain
 * @classdesc Represents a class for <code>ui-option-plain</code> element.
 * Similar to ui-option, but holds symbolic icon/flag icon. Used with
 * combination ui-dropdown.
 * @property {boolean} control Flag to distinguish the location where option will be shown.
 * @property {string} [symbol] {@attr symbol} Icon symbol.
 * @property {string} [flag] {@attr flag} Badge flag symbol for icon.
 * @slot
 * @example
 * <ui-dropdown layout="plain">
 *   <select>
 *     <option data-symbol="RR">Rand AB</option>
 *     <option data-symbol="D">"Rand", AB</option>
 *   </select>
 * </ui-dropdown>
 */
class UIOptionPlain extends UIElement {
    static get POPOVER_TYPE() {
        return 'balloon';
    }

    static get POPOVER_H_OFFSET() {
        return browser.desktopMediaMatches() ? -12 : 6;
    }

    static get POPOVER_V_OFFSET() {
        return 12;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                symbol: String,
                flag: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        const label = this.getAttribute('text') || this.getAttribute('value');
        this.updateClassList({
            '-control': this.control,
            'ui-option-plain': true,
        });

        this.insertElements([
            this.symbol && {
                tagName: 'ui-icon-symbolic',
                attributes: {
                    class: 'ui-option-plain__icon',
                    value: this.symbol ? this.symbol : null,
                    bgshape: this.symbol ? 'square' : null,
                },
            },
            this.flag && {
                tagName: 'ui-icon-flag',
                attributes: {
                    class: 'ui-option-plain__icon',
                    code: this.flag,
                },
            },
            label && {
                tagName: 'span',
                attributes: {
                    class: 'ui-option-plain__label',
                },
                children: [document.createTextNode(label)],
            },
            this.control && {
                tagName: 'ui-icon',
                attributes: {
                    class: 'ui-option-plain__control-icon',
                    glyph: 'down',
                },
            },
        ]);
    }
}

UIOptionPlain.defineElement('ui-option-plain', styles);
export { UIOptionPlain };

import { UIElement } from '../ui-element.js';
import { getChildrenForSlot } from '../../global/ui-helpers.js';
import { createElement } from '../../global/render-api.js';
import styles from './ui-offer.css';
import { Labels } from '../../global/labels.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOffer
 * @element ui-offer
 * @classdesc Represents a class for <code>ui-offer</code> element.
 * Element for marketing offer in general. On close component fires event
 * 'offer-close'.
 * @fires event:offer-close
 * @property {("personal" | "introduction")} [layout="introduction"] {@attr layout} Layout of offer.
 *  {@desc personal}
 *  {@desc introduction}
 * @property {string} [image] {@attr image} Proxy image attribute for &lt;figure> tag.
 * @property {string} [alt] {@attr alt} Alternate text for image same as 'alt=""'.
 * @property {boolean} [highlighted] {@attr highlighted} Adds background (white) to ui-offer.
 * @property {string} [category] {@attr category} Category of personal offer.
 * Only for layout="personal".
 * @property {string} [thumbText] {@attr thumb-text} Text for ui-slides thumb.
 * Works only inside ui-slides.
 * @property {boolean} [closable] {@attr closable} add close functionality for offers.
 * Only layout="personal".
 * @property {boolean} [forceRow] {@attr force-row} Forces offer to stay in row direction.
 * @property {boolean} [imageNocrop] {@attr image-nocrop} image-no-crop (legacy).
 * @property {("top" | "bottom")} [imagePosition] {@attr image-position} image-position (legacy).
 *  {@desc top}
 *  {@desc bottom}
 * @property {"bottom" | "none"} [mobileImage] {@attr mobile-image} Position of the image
 * for smaller screens.
 *  {@desc bottom: bottom from the offer's body}
 *  {@desc none: do not show the image on the small screen}
 * @property {string} [offerId] {@attr offer-id} Offer's ID for closable functionality
 * it will be sent to back system of ibank or any other.
 * @property {string} [offerStatus] {@attr offer-status} Offer's status for closable
 * functionality it will be sent to back system of ibank or any other system.
 * @property {UIButtonbar} buttonbar {@readonly} Shortcut to button bar inside offer if exists.
 * @property {HTMLButtonElement|null} closeBtn {@readonly} Shortcut to close button if offer have
 * 'closable' attribute.
 * @slot
 * @example
 * <ui-offer layout="personal">
 *   <figure>
 *     <img src="assets/mocks/images/offer06.jpg" alt="Discount">
 *   </figure>
 *   <article>
 *     <h2>Only today is discount 20%</h2>
 *     <p>
 *       Travel insurance only today 20 percent cheaper.
 *     </p>
 *     <ui-buttonbar>
 *       <button class="button">Apply</a>
 *     </ui-buttonbar>
 *   </article>
 * </ui-offer>
 * @example <caption>or shorten version</caption>
 * <ui-offer layout="personal" image="/proinfo-files/discount.jpg">
 *   <h2>Only today is discount 20%</h2>
 *   <p>
 *     Travel insurance only today 20 percent cheaper.
 *   </p>
 *   <ui-buttonbar>
 *     <button class="button">Apply</a>
 *   </ui-buttonbar>
 * </ui-offer>
 */
class UIOffer extends UIElement {
    /**
     * Layouts list.
     * @type {{PERSONAL: string, INTRODUCTION: string}}
     */
    static get layouts() {
        return {
            INTRODUCTION: 'introduction',
            PERSONAL: 'personal',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                image: String,
                alt: String,
                category: String,
                highlighted: Boolean,
                layout: { type: String, default: UIOffer.layouts.INTRODUCTION },
                imageNocrop: Boolean,
                imagePosition: {
                    type: String,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component
                    ) => {
                        console.warn(
                            'image-position - attribute is deprecated and will be removed soon',
                            component
                        );
                    },
                },
                mobileImage: String,
                closable: Boolean,
                forceRow: String,
                offerId: String,
                offerStatus: String,
            },
            children: {
                buttonbar: 'ui-buttonbar',
                closeBtn: '.ui-offer__close-button',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-offer', {
            dismissOffer: 'Dismiss offer',
        });
    }

    /**
     * Creates HTML for category section.
     * @private
     * @returns {HTMLDivElement}
     */
    createCategoryElem() {
        return createElement({
            tagName: 'div',
            classList: {
                'ui-offer__category': true,
            },
            children: [this.category],
        });
    }

    /**
     * Creates layout from given attributes.
     * @private
     */
    createOffer() {
        this.positionButtons();
        let article = this.querySelector('article');
        const badge = this.querySelector('ui-badge');
        const remark = this.querySelector('ui-remark');
        if (!article) {
            article = this.createElement({
                tagName: 'article',
                children: [].filter.call(
                    this.childNodes,
                    (node) => node.tagName !== 'FIGURE'
                ),
            });
            this.insertAdjacentElement('beforeend', article);
        }

        const childrenForSlot = getChildrenForSlot(article, 'mobile-footer');
        if (childrenForSlot.length) {
            this.updateClassList({ '-mobile-footer': true });
            article.appendChild(
                this.createElement({
                    tagName: 'div',
                    classList: {
                        'ui-offer__footer': true,
                    },
                    children: childrenForSlot,
                })
            );
        }

        if (this.image) {
            this.insertBefore(
                this.createElement({
                    tagName: 'figure',
                    children: [
                        {
                            tagName: 'img',
                            attributes: {
                                src: this.image,
                                alt: this.alt || '',
                            },
                        },
                        remark,
                        badge,
                    ],
                }),
                article
            );
        }

        // If user does not wrap them in <figure> tag
        const picture = this.querySelector('ui-shape,img,ui-video');
        if (picture && picture.parentElement.tagName !== 'FIGURE') {
            const figure = document.createElement('figure');
            const elems = [picture];
            badge && elems.push(badge);
            remark && elems.push(remark);
            figure.append(...elems);
            this.insertBefore(figure, article);
        }

        if (this.closable) {
            this.appendChild(
                this.createElement({
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                        title: UIOffer.labels.dismissOffer,
                    },
                    classList: {
                        'ui-offer__close-button': true,
                        '-iconed': true,
                    },
                    children: [
                        {
                            tagName: 'ui-icon',
                            attributes: {
                                glyph: 'cross',
                            },
                        },
                    ],
                })
            );
        }

        if (this.category) {
            article.insertAdjacentElement(
                'afterbegin',
                this.createCategoryElem()
            );
        }
    }

    /**
     * Position buttons inside button-bar correctly.
     * @private
     */
    positionButtons() {
        if (!this.buttonbar) {
            return;
        }
        const buttonsAndLinks = this.buttonbar.querySelectorAll('button, a');
        [].forEach.call(buttonsAndLinks, (item) => {
            if (this.layout === UIOffer.layouts.INTRODUCTION) {
                item.classList.add('-left');
            }
        });
    }

    /**
     * Fires callback when changed between mobile and desktop
     */
    onChangeAppearance() {
        const mobileFooter = this.querySelector('.ui-offer__footer');
        if (!mobileFooter) {
            return;
        }

        const newParent = this.mobileMediaQueryList.matches
            ? this
            : this.querySelector('article');
        mobileFooter.parentNode.removeChild(mobileFooter);
        newParent.appendChild(mobileFooter);
    }

    /**
     * @inheritDoc
     */
    render() {
        this.createOffer();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (this.closable && this.closeBtn) {
            this.closeBtn.addEventListener('click', () => {
                this.dispatchCustomEvent('offer-close', {
                    id: this.offerId,
                    status: this.offerStatus,
                });
            });
        }

        if (this.classList.contains('-mobile-footer')) {
            this.mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
            this.mobileMediaQueryList.addListener(() =>
                this.onChangeAppearance()
            );
            this.onChangeAppearance();
        }
    }
}

UIOffer.defineElement('ui-offer', styles);
export { UIOffer };

import { UIElement } from '../ui-element.js';
import styles from './ui-footer.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIFooter
 * @element ui-footer
 * @classdesc Represents a class for <code>ui-footer</code> element.
 * Container for legal terms and conditions.
 * @property {boolean} withBar {@attr with-bar} Draws branding bar like ribbon.
 * @property {"box"} type {@attr type} Styling for the box layout.
 * @slot
 * @example
 * <ui-footer></ui-footer>
 */
class UIFooter extends UIElement {
    static get props() {
        return {
            attributes: {
                withBar: Boolean,
                type: String,
            },
        };
    }
    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'contentinfo');
    }
}

UIFooter.defineElement('ui-footer', styles);
export { UIFooter };

import { EventObserver } from '../../global/event-observer.js';
import { isEnterPressed, TabIndex } from '../../global/keyboard.js';
import { Labels } from '../../global/labels.js';
import {
    rebuild,
    createElement,
    dispatchCustomEvent,
} from '../../global/ui-helpers.js';
import { UIElement } from '../ui-element.js';
import styles from './ui-signing.css';

/**
 * @memberof SharedComponents
 * @augments UIElement
 * @alias UISigning
 * @element ui-signing
 * @classdesc Represents a class for <code>ui-signing</code> element.
 * User interface for DigiPass, Mobile ID, Smart ID signing.
 * @fires event:confirm
 * @fires event:cancel
 * @listens event:signing-cancel
 * @listens event:signing-confirm
 * @listens event:submit-on-enter
 * @property {"CERTIFICATE" | "MOBILE_ID" | "DIGIPASS" | "PIN_CALCULATOR"
 * | "ADVANCED_PIN_CALCULATOR" | "SMART_ID" | "SMART_ID_NQ" | "SMART_ID_Q" | "SIMPLE_ID"
 * | "EPARAKSTS_MOBILE"} [method="SMART_ID"] {@attr method} the signing method.
 * @property {string} [code] {@attr code} the challenge code.
 * @property {boolean} [trusted=false] {@attr trusted} defines if element's
 * strings are trusted HTML
 * @property {string} [labelCancel="Cancel"] {@attr label-cancel} Label for cancel
 * destructive button.
 * @property {string} [labelConfirm="Confirm"] {@attr label-confirm} Label for confirm button.
 * @property {string} [labelRunning] {@attr label-running} Label for running action.
 * @property {string} [labelIntro] {@attr label-intro} Label for intro.
 * @property {string} [labelExplanation] {@attr label-explanation} Label for explanation block.
 * @property {string} [labelStatus] {@attr label-status} Label for sending status
 * @property {string} [labelCode] {@attr label-code} Label for challenge code's field
 * @property {string} [labelPassword] {@attr label-password} Label for challenge password's field
 * @property {string} [labelPlaceholder] {@attr label-placeholder} Label for
 * challenge password's field placeholder
 * @property {string} [challengeText] {@attr challenge-text}
 * @property {boolean} [hideActions] {@attr hide-actions}
 * @property {boolean} [errorActions] {@attr error-actions}
 * @property {boolean} [error] {@attr error}
 * @property {string} [errorStatus] {@attr error-status}
 * @property {string} [errorMessage] {@attr error-message}
 * @property {string} [errorDescription] {@attr error-description}
 * @property {("on" | "off")} [cancelable="on"] {@attr cancelable} Adds cancel button.
 * @property {"fluid" | "centered"} [layout="default"] {@attr layout} Layout type.
 * @property {UIMessageBox} messageBox {@readonly} UIMessageBox child.
 * @property {HTMLHeadingElement} codeRef {@readonly}
 * @property {UIButtonbar} buttonBar {@readonly} UIButtonbar child.
 * @property {HTMLDivElement} view {@readonly} View reference.
 * @example
 * <ui-signing method="SMART_ID" code="1234"></ui-signing>
 */
class UISigning extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return [
            'code',
            'method',
            'trusted',
            'label-running',
            'label-intro',
            'label-code',
            'label-explanation',
            'label-status',
            'label-password',
            'label-placeholder',
            'label-cancel',
            'label-confirm',
            'error',
            'error-message',
            'error-status',
            'error-description',
            'hide-actions',
            'error-actions',
            'cancelable',
        ];
    }

    static get DEFAULT_METHOD() {
        return 'SMART_ID';
    }

    static get METHODS() {
        return {
            CERTIFICATE: 'CERTIFICATE',
            MOBILE_ID: 'MOBILE_ID',
            DIGIPASS: 'DIGIPASS',
            PIN_CALCULATOR: 'PIN_CALCULATOR',
            ADVANCED_PIN_CALCULATOR: 'ADVANCED_PIN_CALCULATOR',
            SMART_ID: 'SMART_ID',
            SMART_ID_NQ: 'SMART_ID_NQ',
            SMART_ID_Q: 'SMART_ID_Q',
            SIMPLE_ID: 'SIMPLE_ID',
            EPARAKSTS_MOBILE: 'EPARAKSTS_MOBILE',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: String,
                code: String,
                trusted: Boolean,
                labelCancel: String,
                labelConfirm: String,
                labelRunning: String,
                labelIntro: String,
                labelCode: String,
                labelExplanation: String,
                labelStatus: String,
                labelPassword: String,
                labelPlaceholder: String,
                hideActions: Boolean,
                errorActions: Boolean,
                cancelable: { type: String, default: 'on' },
                error: Boolean,
                errorMessage: String,
                errorStatus: String,
                errorDescription: String,
            },
            children: {
                view: '.ui-signing__view',
                buttonBar: 'ui-buttonbar',
                codeRef: '.ui-signing__code',
                hint: '.ui-signing__hint',
            },
        };
    }
    get method() {
        const method = this.getAttribute('method');
        if (!method) {
            return UISigning.DEFAULT_METHOD;
        }
        switch (method.toUpperCase()) {
            case 'MOBILE_ID':
            case UISigning.METHODS.MOBILE_ID:
                return 'MOBILE_ID';
            case 'DIGIPASS':
            case 'PIN_CALCULATOR':
            case UISigning.METHODS.DIGIPASS:
                return 'DIGIPASS';
            case 'SMART_ID':
            case UISigning.METHODS.SMART_ID:
            case UISigning.METHODS.SMART_ID_NQ:
            case UISigning.METHODS.SMART_ID_Q:
                return 'SMART_ID';
            case UISigning.METHODS.EPARAKSTS_MOBILE:
                return 'EPARAKSTS_MOBILE';
            case UISigning.METHODS.SIMPLE_ID:
                return 'SIMPLE_ID';
            case UISigning.METHODS.CERTIFICATE:
                return 'CERTIFICATE';
            default:
                return UISigning.DEFAULT_METHOD;
        }
    }
    set method(value) {
        this.setAttribute('method', value);
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-signing', {
            /* eslint-disable max-len */
            TITLE: 'Digital signing',
            DEFAULT_ERROR_MESSAGE: 'Signing failed.',
            DIGIPASS_CHALLENGE:
                'Select the "APPLI 2" function on the PIN calculator and enter the code',
            DIGIPASS_PASSWORD: 'Password from PIN-calculator',
            DIGIPASS_PLACEHOLDER: 'APPLI 2',
            DIGIPASS_CONFIRM: 'Confirm',
            DIGIPASS_ERROR_MESSAGE: 'Confirming with PIN calculator failed.',
            CERTIFICATE_ERROR_MESSAGE: 'Confirming with ID-Card failed.',
            SMART_ID_RUNNING:
                'Notification sent to your Smart-ID device. Check the code!',
            SMART_ID_CHALLENGE: 'Your verification code:',
            SMART_ID_INTRO_MESSAGE:
                'The document will receive a digital signature after the PIN2 code' +
                ' required for digital signature is entered. Make sure that you agree with the information' +
                ' you sign. If unsure, go back to the previous page and check the document.',
            SMART_ID_EXPLANATION:
                'Please compare verification code you see above with the one requested' +
                'in Smart-ID! In order to sign with Smart-ID, enter PIN2.',
            SMART_ID_SENDING_MESSAGE:
                'Signing request is sent to your smart device!' +
                ' Please check the code and enter PIN2.',
            SMART_ID_ERROR_MESSAGE: 'Signing with Smart-ID failed.',
            MOBILE_ID_RUNNING:
                'Message sent to your telephone. Check the code!',
            MOBILE_ID_CHALLENGE: 'Please enter your code:',
            MOBILE_ID_INTRO_MESSAGE:
                'The document will receive a digital signature after the sPIN code' +
                ' required for digital signature is entered and will have the same legal force as your' +
                ' handwritten signature. Make sure that you agree with the information you sign.' +
                ' If unsure, go back to the previous page and check the document.',
            MOBILE_ID_EXPLANATION:
                'In order to signing using mobile ID, enter the mobile ID sPIN' +
                ' into your phone after you receive an message with the same verification code you' +
                ' can see here on the internet bank page.',
            MOBILE_ID_SENDING_MESSAGE: 'Message is being sent! Please wait.',
            MOBILE_ID_ERROR_MESSAGE: 'Signing with Mobile-ID failed.',
            EPARAKSTS_MOBILE_RUNNING:
                'Sign with eParaksts mobile. Compare the code!',
            EPARAKSTS_MOBILE_CHALLENGE: 'Your verification code:',
            EPARAKSTS_MOBILE_INTRO_MESSAGE:
                'Make sure that you agree with the information you sign. If unsure, return to the previous step and check the document.',
            EPARAKSTS_MOBILE_EXPLANATION:
                'Always compare this verification code with the one requested in eParaksts mobile. In order to sign, open eParaksts mobile app and enter PIN2.',
            EPARAKSTS_MOBILE_SENDING_MESSAGE:
                'Request is sent to your device. Compare the code and enter PIN2.',
            EPARAKSTS_MOBILE_ERROR_MESSAGE:
                'Signing with eParaksts mobile failed.',
            SIMPLE_ID_RUNNING: 'Approve with biometrics. Compare the code!',
            SIMPLE_ID_CHALLENGE: 'Your verification code:',
            SIMPLE_ID_INTRO_MESSAGE:
                'Make sure that you agree with the information you approve. If unsure, return to the previous step and check the document.',
            SIMPLE_ID_EXPLANATION:
                'Always compare this verification code with the one requested in Swedbank app. In order to approve, open Swedbank app and scan biometrics.',
            SIMPLE_ID_SENDING_MESSAGE:
                'Request is sent to your device. Compare the code and approve with biometrics.',
            SIMPLE_ID_ERROR_MESSAGE: 'Signing with biometrics failed.',
            CANCEL_BUTTON_LABEL: 'Cancel',
            CONFIRM_BUTTON_LABEL: 'Confirm',
            SMART_ID_BUTTON_LABEL: 'Confirm with Smart-ID PIN2',
            MOBILE_ID_BUTTON_LABEL: 'Confirm',
            SIMPLE_ID_BUTTON_LABEL: 'Confirm with biometrics',
            CERTIFICATE_BUTTON_LABEL: 'Confirm',
            DIGIPASS_BUTTON_LABEL: 'Confirm',
            EPARAKSTS_MOBILE_BUTTON_LABEL: 'Sign with eParaksts mobile',
            HWCRYPTO_DEFAULT_ERROR:
                'ID card signing error has occurred, plugin does not work. Restart your browser and try again. {{info-link}}',
            HWCRYPTO_NO_CERTIFICATES:
                'ID card signing error has occurred, no valid certificate was found. Please, pull out eID card from the reader, put it back and try again. {{info-link}}',
            HWCRYPTO_USER_CANCEL:
                'You have clicked "Cancel" button in eID card PIN entry window and that has terminated signing attempt. Shall we try again?',
            HWCRYPTO_DRIVER_ERROR:
                'ID card signing error has occurred, middleware does not work. Check if eID middleware is updated, then restart the browser and try again. {{info-link}}',
            HWCRYPTO_NO_IMPLEMENTATION:
                'ID-card plugin error occurred. Check if the ID-card software is up to date and plugin Web eID or "eParaksts signing extension" in your browser is enabled. Then  Restart your browser and try again.',
            LINK_PLACEHOLDER: 'Read more instructions.',
            /* eslint-enable max-len */
        });
    }

    /**
     * Parses label template and replaces placeholders with appropriate labels.
     * @param {string} template
     * @param {string} replaceValue
     * @returns {string}
     */
    prepareLabel(template, replaceValue) {
        return template.replace(
            '{{info-link}}',
            this.buildInfoLink(replaceValue).outerHTML
        );
    }

    /**
     * Creates more info link with label.
     * @param {string} href
     * @returns {HTMLAnchorElement | string}
     * @private
     */
    buildInfoLink(href) {
        return createElement({
            tagName: 'a',
            attributes: {
                href: href || '#',
            },
            children: [UISigning.labels.LINK_PLACEHOLDER],
        });
    }

    /**
     * Set the code for challenge.
     * @param {string} code
     * @returns {UISigning}
     */
    setCode(code) {
        this.setAttribute('code', code);
        return this;
    }

    /**
     * Checks if signing can be confirmed.
     * @returns {boolean}
     */
    isConfirmable() {
        return !this.error && this.method === 'DIGIPASS';
    }

    /**
     * Checks if signing can be canceled.
     * @returns {boolean}
     */
    isCancelable() {
        return this.cancelable !== 'off' && !this.hideActions;
    }

    /**
     * Get default error message according to given method
     * @param {string} methodId
     * @returns {string}
     */
    getDefaultErrorMessage(methodId) {
        switch (methodId) {
            case 'SMART_ID':
                return UISigning.labels.SMART_ID_ERROR_MESSAGE;
            case 'MOBILE_ID':
                return UISigning.labels.MOBILE_ID_ERROR_MESSAGE;
            case 'SIMPLE_ID':
                return UISigning.labels.SIMPLE_ID_ERROR_MESSAGE;
            case 'EPARAKSTS_MOBILE':
                return UISigning.labels.EPARAKSTS_MOBILE_ERROR_MESSAGE;
            case 'DIGIPASS':
                return UISigning.labels.DIGIPASS_ERROR_MESSAGE;
            case 'CERTIFICATE':
                return UISigning.labels.CERTIFICATE_ERROR_MESSAGE;
            default:
                return UISigning.labels.DEFAULT_ERROR_MESSAGE;
        }
    }

    /**
     * Check if given method supported by component
     * @param {string|number} methodId
     * @returns {boolean}
     */
    isMethodSupported(methodId) {
        return (
            [
                UISigning.METHODS.CERTIFICATE,
                UISigning.METHODS.DIGIPASS,
                UISigning.METHODS.PIN_CALCULATOR,
                UISigning.METHODS.ADVANCED_PIN_CALCULATOR,
                UISigning.METHODS.SMART_ID,
                UISigning.METHODS.SMART_ID_NQ,
                UISigning.METHODS.SMART_ID_Q,
                UISigning.METHODS.MOBILE_ID,
                UISigning.METHODS.SIMPLE_ID,
                UISigning.METHODS.EPARAKSTS_MOBILE,
            ].indexOf(String(methodId)) > -1
        );
    }

    /**
     * Return custom label or empty string if the label disabled or default value
     * @param {string} customValue
     * @param {string} defaultValue
     * @returns {string}
     */
    defineLabel(customValue, defaultValue) {
        if (customValue === 'false') {
            return '';
        }
        return customValue || defaultValue;
    }

    /**
     * @returns {Array<IElementConfig>}
     */
    buildDigipassElements() {
        return [
            {
                tagName: 'ui-field',
                attributes: {
                    label: this.defineLabel(
                        this.labelCode,
                        UISigning.labels.DIGIPASS_CHALLENGE
                    ),
                },
                children: [
                    {
                        tagName: 'h3',
                        children: [
                            {
                                tagName: 'output',
                                attributes: {
                                    id: 'ui-signing-digipass-code',
                                    tabindex: TabIndex.Active,
                                    'aria-live': 'polite',
                                },
                                children: [
                                    this.code || {
                                        tagName: 'ui-icon',
                                        attributes: {
                                            glyph: 'loader',
                                        },
                                    },
                                ],
                            },
                        ],
                        attributes: {
                            class: 'ui-signing__code',
                        },
                    },
                ],
            },
            {
                tagName: 'ui-field',
                attributes: {
                    label: this.defineLabel(
                        this.labelPassword,
                        UISigning.labels.DIGIPASS_PASSWORD
                    ),
                },
                children: [
                    {
                        tagName: 'input',
                        attributes: {
                            id: 'ui-signing-code-digipass-input',
                            type: 'password',
                            class: 'ui-signing__value',
                            name: 'signing-value',
                            'data-event': 'submit-on-enter',
                            placeholder: this.defineLabel(
                                this.labelPlaceholder,
                                UISigning.labels.DIGIPASS_PLACEHOLDER
                            ),
                        },
                    },
                ],
            },
        ];
    }

    /**
     * @returns {Array<IElementConfig>}
     */
    buildMobileIdElements() {
        const labelRunning = this.defineLabel(
            this.labelRunning,
            UISigning.labels.MOBILE_ID_RUNNING
        );
        return [
            labelRunning && {
                tagName: 'h2',
                attributes: {
                    class: 'ui-signing__title',
                },
                children: [labelRunning],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-signing__container',
                },
                children: [
                    {
                        tagName: 'label',
                        children: [
                            this.defineLabel(
                                this.labelCode,
                                UISigning.labels.MOBILE_ID_CHALLENGE
                            ),
                        ],
                        attributes: {
                            class: 'ui-signing__label',
                            for: 'ui-signing-code-mid',
                        },
                    },
                    {
                        tagName: 'output',
                        attributes: {
                            id: 'ui-signing-code-mid',
                            class: 'ui-signing__code',
                            tabindex: TabIndex.Active,
                            'aria-live': 'polite',
                            'aria-busy': this.code ? null : 'true',
                        },
                        children: [
                            this.code || {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'loader',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                tagName: 'ui-messagebox',
                children: [
                    {
                        tagName: 'ui-message',
                        attributes: { type: 'info' },
                        children: [
                            this.defineLabel(
                                this.labelStatus,
                                UISigning.labels.MOBILE_ID_SENDING_MESSAGE
                            ),
                        ],
                    },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-signing__hint',
                },
                children: [
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelIntro,
                                UISigning.labels.MOBILE_ID_INTRO_MESSAGE
                            ),
                        ],
                    },
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelExplanation,
                                UISigning.labels.MOBILE_ID_EXPLANATION
                            ),
                        ],
                    },
                ],
            },
        ];
    }

    /**
     * @returns {Array<IElementConfig>}
     */
    buildSmartIdElements() {
        const labelRunning = this.defineLabel(
            this.labelRunning,
            UISigning.labels.SMART_ID_RUNNING
        );
        return [
            labelRunning && {
                tagName: 'h2',
                attributes: {
                    class: 'ui-signing__title',
                },
                children: [labelRunning],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-signing__container',
                },
                children: [
                    {
                        tagName: 'label',
                        children: [
                            this.defineLabel(
                                this.labelCode,
                                UISigning.labels.MOBILE_ID_CHALLENGE
                            ),
                        ],
                        attributes: {
                            class: 'ui-signing__label',
                            for: 'ui-signing-code-sid',
                        },
                    },
                    {
                        tagName: 'output',
                        attributes: {
                            id: 'ui-signing-code-sid',
                            class: 'ui-signing__code',
                            tabindex: TabIndex.Active,
                            'aria-live': 'polite',
                            'aria-busy': this.code ? null : 'true',
                        },
                        children: [
                            this.code || {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'loader',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                tagName: 'ui-messagebox',
                children: [
                    {
                        tagName: 'ui-message',
                        attributes: { type: 'info' },
                        children: [
                            this.defineLabel(
                                this.labelStatus,
                                UISigning.labels.SMART_ID_SENDING_MESSAGE
                            ),
                        ],
                    },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-signing__hint',
                },
                children: [
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelIntro,
                                UISigning.labels.SMART_ID_INTRO_MESSAGE
                            ),
                        ],
                    },
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelExplanation,
                                UISigning.labels.SMART_ID_EXPLANATION
                            ),
                        ],
                    },
                ],
            },
        ];
    }

    /**
     * @returns {Array<IElementConfig>}
     */
    buildEparakstsMobileElements() {
        const labelRunning = this.defineLabel(
            this.labelRunning,
            UISigning.labels.EPARAKSTS_MOBILE_RUNNING
        );
        return [
            labelRunning && {
                tagName: 'h2',
                attributes: {
                    class: 'ui-signing__title',
                },
                children: [labelRunning],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-signing__container',
                },
                children: [
                    {
                        tagName: 'label',
                        children: [
                            this.defineLabel(
                                this.labelCode,
                                UISigning.labels.EPARAKSTS_MOBILE_CHALLENGE
                            ),
                        ],
                        attributes: {
                            class: 'ui-signing__label',
                            for: 'ui-signing-code-sid',
                        },
                    },
                    {
                        tagName: 'output',
                        attributes: {
                            id: 'ui-signing-code-sid',
                            class: 'ui-signing__code',
                            tabindex: TabIndex.Active,
                            'aria-live': 'polite',
                            'aria-busy': this.code ? null : 'true',
                        },
                        children: [
                            this.code || {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'loader',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                tagName: 'ui-messagebox',
                children: [
                    {
                        tagName: 'ui-message',
                        attributes: { type: 'info' },
                        children: [
                            this.defineLabel(
                                this.labelStatus,
                                UISigning.labels
                                    .EPARAKSTS_MOBILE_SENDING_MESSAGE
                            ),
                        ],
                    },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-signing__hint',
                },
                children: [
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelIntro,
                                UISigning.labels.EPARAKSTS_MOBILE_INTRO_MESSAGE
                            ),
                        ],
                    },
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelExplanation,
                                UISigning.labels.EPARAKSTS_MOBILE_EXPLANATION
                            ),
                        ],
                    },
                ],
            },
        ];
    }

    /**
     * @returns {Array<IElementConfig>}
     */
    buildSimpleIdElements() {
        const labelRunning = this.defineLabel(
            this.labelRunning,
            UISigning.labels.SIMPLE_ID_RUNNING
        );
        return [
            {
                tagName: 'h2',
                attributes: {
                    class: 'ui-signing__title',
                },
                children: [labelRunning],
            },
            {
                tagName: 'div',
                classList: {
                    'ui-signing__container': true,
                },
                children: [
                    {
                        tagName: 'label',
                        attributes: {
                            for: 'ui-signing-code-simple-input',
                        },
                        children: [
                            this.defineLabel(
                                this.labelCode,
                                UISigning.labels.SIMPLE_ID_CHALLENGE
                            ),
                        ],
                        classList: {
                            'ui-signing__label': true,
                        },
                    },
                    {
                        tagName: 'output',
                        attributes: {
                            id: 'ui-signing-code-simple-input',
                            tabindex: TabIndex.Active,
                            'aria-live': 'polite',
                            'aria-busy': this.code ? null : 'true',
                        },
                        classList: {
                            'ui-signing__code': true,
                        },
                        children: [
                            this.code || {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'loader',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                tagName: 'ui-messagebox',
                children: [
                    {
                        tagName: 'ui-message',
                        attributes: { type: 'info' },
                        children: [
                            this.defineLabel(
                                this.labelStatus,
                                UISigning.labels.SIMPLE_ID_SENDING_MESSAGE
                            ),
                        ],
                    },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-signing__hint',
                },
                children: [
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelIntro,
                                UISigning.labels.SIMPLE_ID_INTRO_MESSAGE
                            ),
                        ],
                    },
                    {
                        tagName: 'p',
                        children: [
                            this.defineLabel(
                                this.labelExplanation,
                                UISigning.labels.SIMPLE_ID_EXPLANATION
                            ),
                        ],
                    },
                ],
            },
        ];
    }

    /**
     * @param {string} methodId
     * @returns {Array<Element>}
     */
    buildErrorElements(methodId) {
        return [
            createElement(
                {
                    tagName: 'ui-messagebox',
                    children: [
                        {
                            tagName: 'ui-message',
                            attributes: { type: 'error' },
                            children: [
                                this.defineLabel(
                                    this.errorMessage,
                                    this.getDefaultErrorMessage(methodId)
                                ),
                            ],
                        },
                    ],
                },
                this.trusted
            ),
            createElement(
                {
                    tagName: 'div',
                    attributes: {
                        class: 'ui-signing__hint',
                    },
                    children: [
                        {
                            tagName: 'p',
                            children: [this.errorStatus],
                        },
                        {
                            tagName: 'p',
                            children: [this.errorDescription],
                        },
                    ],
                },
                this.trusted
            ),
        ];
    }

    /**
     * @param {string} methodId
     * @returns {HTMLDivElement}
     */
    renderViewFor(methodId) {
        /**
         * @type {IElementConfig}
         */
        const config = {
            tagName: 'div',
            classList: {
                'ui-signing__view': true,
            },
        };
        if (this.error) {
            config.children = this.buildErrorElements(methodId);
        } else {
            switch (methodId) {
                case 'SMART_ID':
                    config.children = this.buildSmartIdElements();
                    break;
                case 'MOBILE_ID':
                    config.children = this.buildMobileIdElements();
                    break;
                case 'SIMPLE_ID':
                    config.children = this.buildSimpleIdElements();
                    break;
                case 'EPARAKSTS_MOBILE':
                    config.children = this.buildEparakstsMobileElements();
                    break;
                case 'DIGIPASS':
                    config.children = this.buildDigipassElements();
                    break;
                default:
                    break;
            }
        }

        return createElement(config, this.trusted);
    }

    /**
     * @returns {boolean}
     */
    isActionsVisible() {
        if (this.hideActions) {
            return false;
        }
        return !(this.error && !this.errorActions);
    }

    /**
     * @returns {UIButtonbar}
     */
    renderButtonBar() {
        return createElement(
            {
                tagName: 'ui-buttonbar',
                attributes: {
                    layout: this.layout,
                },
                children: [
                    this.isCancelable() && {
                        tagName: 'button',
                        children: [
                            this.defineLabel(
                                this.labelCancel,
                                UISigning.labels.CANCEL_BUTTON_LABEL
                            ),
                        ],
                        attributes: {
                            type: 'button',
                            name: 'ui-signing-cancel',
                            'data-event': 'signing-cancel',
                            class: 'button -destructive',
                        },
                    },
                    this.isConfirmable() && {
                        tagName: 'button',
                        children: [
                            this.defineLabel(
                                this.labelConfirm,
                                UISigning.labels.CONFIRM_BUTTON_LABEL
                            ),
                        ],
                        attributes: {
                            type: 'button',
                            name: 'ui-signing-confirm',
                            'data-event': 'signing-confirm',
                            class: 'button',
                        },
                    },
                ],
            },
            this.trusted
        );
    }

    /**
     * Gets the cancel button
     * @returns {HTMLButtonElement | null}
     * @private
     */
    getCancelButton() {
        return this.querySelector('[data-event="signing-cancel"]');
    }

    /**
     * Gets the cancel button
     * @returns {HTMLButtonElement | null}
     * @private
     */
    getConfirmButton() {
        return this.querySelector('[data-event="signing-confirm"]');
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'code':
                // PC-2492 GUI-1508 Sign with PIN-calculator, when asking APPLI2
                // click "Back" button, the 'code' attribute changes (removed) from
                // signing and attributeChangedCallback() is triggered, but this.code and
                // this.codeRef are null.
                if (!this.codeRef) {
                    return;
                }
                this.codeRef.innerHTML = '';
                this.insertElements.call(this.codeRef, [
                    newValue
                        ? document.createTextNode(newValue)
                        : {
                              tagName: 'ui-icon',
                              attributes: {
                                  glyph: 'loader',
                              },
                          },
                ]);
                if (newValue) {
                    this.codeRef.removeAttribute('aria-busy');
                } else {
                    this.codeRef.setAttribute('aria-busy', 'true');
                }
                break;
            case 'method': {
                const newMethod = this.method;
                this.updateClassList({
                    '-mobileid': newMethod === 'MOBILE_ID',
                    '-smartid': newMethod === 'SMART_ID',
                    '-digipass': newMethod === 'DIGIPASS',
                    '-simpleid': newMethod === 'SIMPLE_ID',
                    '-eparakstsmobile': newMethod === 'EPARAKSTS_MOBILE',
                });
                rebuild(this.view, this.renderViewFor(newMethod));
                rebuild(this.buttonBar, this.renderButtonBar());
                break;
            }
            case 'trusted':
            case 'label-running':
            case 'label-intro':
            case 'label-code':
            case 'label-explanation':
            case 'label-status':
            case 'label-password':
            case 'label-placeholder':
            case 'label-confirm':
            case 'label-cancel':
            case 'error':
            case 'error-message':
            case 'error-status':
            case 'error-description':
            case 'hide-actions':
            case 'cancelable':
            case 'error-actions': {
                // TODO: optimize lazy mutation
                rebuild(this.view, this.renderViewFor(this.method));
                const buttonBar = this.buttonBar;
                if (this.isActionsVisible()) {
                    if (buttonBar) {
                        rebuild(buttonBar, this.renderButtonBar());
                    } else {
                        this.appendChild(this.renderButtonBar());
                    }
                } else if (buttonBar) {
                    this.removeChild(buttonBar);
                }
                break;
            }
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     * @fires event:confirm
     */
    handleSubmitOnEnter(e) {
        if (!isEnterPressed(e.detail.originalEvent)) {
            return;
        }
        dispatchCustomEvent(this, 'confirm', {
            value: this.querySelector('input[name=signing-value]').value,
        });
    }

    /**
     * @inheritDoc
     * @fires event:cancel
     */
    handleSigningCancel() {
        dispatchCustomEvent(this, 'cancel', {});
    }

    /**
     * @inheritDoc
     * @fires event:confirm
     */
    handleSigningConfirm() {
        dispatchCustomEvent(this, 'confirm', {
            value: this.querySelector('input[name=signing-value]').value,
        });
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.clickObserver.disconnect();
        this.keypressObserver.disconnect();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.clickObserver.observe(this);
        this.keypressObserver.observe(this);
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateClassList({
            '-mobileid': this.method === 'MOBILE_ID',
            '-smartid': this.method === 'SMART_ID',
            '-digipass': this.method === 'DIGIPASS',
            '-simpleid': this.method === 'SIMPLE_ID',
            '-eparakstsmobile': this.method === 'EPARAKSTS_MOBILE',
        });
        this.insertElements([
            this.renderViewFor(this.method),
            this.isActionsVisible() && this.renderButtonBar(),
        ]);

        this.insertElements([
            {
                tagName: 'div',
                attributes: {
                    id: 'ui-signing-title',
                },
                classList: {
                    '-visually-hidden': true,
                },
                children: [UISigning.labels.TITLE],
            },
        ]);
        this.setAttributes({
            role: 'region',
            'aria-labelledby': 'ui-signing-title',
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.clickObserver = new EventObserver({ name: 'click' });
        this.keypressObserver = new EventObserver({ name: 'keypress' });
        this.clickObserver.observe(this);
        this.keypressObserver.observe(this);

        this.addEventListener(
            'signing-cancel',
            this.handleSigningCancel.bind(this)
        );
        this.addEventListener(
            'signing-confirm',
            this.handleSigningConfirm.bind(this)
        );
        this.addEventListener(
            'submit-on-enter',
            this.handleSubmitOnEnter.bind(this)
        );
    }
}

UISigning.defineElement('ui-signing', styles);
export { UISigning };

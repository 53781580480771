import { UIDateElement } from './ui-dateelement.js';
import { isKeyPressed, keyCodes } from '../../global/keyboard.js';
import { updateElement } from '../../global/ui-helpers.js';
import styles from './ui-timepicker.css';

/**
 * @memberof SharedComponents
 * @augments {UIDateElement}
 * @alias UITimePicker
 * @element ui-timepicker
 * @classdesc Represents a class for <code>ui-timepicker</code> element.
 * Input element which allows to pick time.
 * @fires event:change
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {HTMLInputElement} control Shortcut to control/input element.
 * @slot
 * @example
 * <ui-timepicker>
 *   <input name="timeFrom" type="time">
 * </ui-timepicker>
 */
class UITimePicker extends UIDateElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                controlId: String,
            },
            children: {
                control: '.ui-timepicker__input',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    get valueType() {
        return UIDateElement.VALUE_TYPES.TIME;
    }

    get value() {
        if (!this.control) {
            return null;
        }
        return this.isValidTime(this.control.value) ? this.control.value : null;
    }
    set value(value) {
        if (value === this.value) {
            return;
        }
        this.setControlValue(value);
    }

    /**
     * Sets value in string representation to input control.
     * @param {string} value
     */
    setControlValue(value) {
        if (this.focused) {
            return;
        }

        let controlValue = value;
        if (this.control.type === 'text') {
            controlValue = value.replace(/[,.-]/g, ':');
        }
        if (!this.isValidTime(controlValue)) {
            this.control.value = null;
        } else {
            const res = this.parseTimeValue(controlValue, this.min, this.max);
            if (this.control.value !== res.value) {
                this.control.value = res.value;
            }
        }
    }

    /**
     * Sets hours and minutes to input control.
     * @param {number} hh
     * @param {number} mm
     */
    setTime(hh, mm) {
        this.control.value = this.parseTimeValue(
            [hh < 10 ? '0' + hh : hh, mm < 10 ? '0' + mm : mm].join(':'),
            this.min,
            this.max
        ).value;
    }

    /**
     * Fires callback when the input value is changed.
     * @param {Event} e
     * @private
     */
    handleChangeValue(e) {
        this.setControlValue(e.target.value);
    }

    /**
     * Fires callback when the input get focused.
     * @param {Event} e
     * @private
     */
    handleFocusInput(e) {
        this.focused = true;
        if (this.control.type !== 'text') {
            return;
        }
        if (!this.isValidTime(this.control.value)) {
            this.control.value = '00:00';
        }
        this.control.setSelectionRange(0, 2);
    }

    /**
     * Fires callback when the input get focused.
     * @param {Event} e
     * @private
     */
    handleBlurInput(e) {
        this.focused = false;
        this.setControlValue(this.control.value);
    }

    /**
     * Fires callback when input's selection is changed.
     * @private
     */
    handleSelectionChanged() {
        if (this.control.type !== 'text') {
            return;
        }
        if (this.control.selectionStart < 2) {
            this.control.setSelectionRange(0, 2);
        } else {
            this.control.setSelectionRange(3, 5);
        }
    }

    /**
     * Fires callback when the input get clicked.
     * @param {Event} e
     * @private
     */
    handleClickInInput(e) {
        if (this.control.type !== 'text') {
            return;
        }
        this.handleSelectionChanged();
    }

    /**
     * Fires callback when the input get a key down event.
     * @param {MouseEvent|KeyboardEvent} e
     * @private
     */
    handleKeydownInInput(e) {
        if (this.control.type !== 'text') {
            return;
        }
        const parsed = this.parseTimeValue(
            this.control.value,
            this.min,
            this.max
        );
        let hours = parsed.hours;
        let minutes = parsed.minutes;
        if (
            isKeyPressed(e, keyCodes.BACKSPACE) ||
            isKeyPressed(e, keyCodes.DEL)
        ) {
            return;
        }
        if (isKeyPressed(e, keyCodes.DOWN)) {
            if (this.control.selectionStart < 2) {
                hours = hours - 1;
                if (hours < 0) {
                    hours = 24;
                }
                this.setTime(hours, minutes);
                this.control.setSelectionRange(0, 2);
            } else {
                minutes = minutes - 1;
                if (minutes < 0) {
                    minutes = 59;
                }
                this.setTime(hours, minutes);
                this.dispatchNativeEvent('change');
                this.control.setSelectionRange(3, 5);
            }
            e.preventDefault();
        } else if (isKeyPressed(e, keyCodes.UP)) {
            if (this.control.selectionStart < 2) {
                hours = hours + 1;
                if (hours > 24) {
                    hours = 0;
                }
                this.setTime(hours, minutes);
                this.dispatchNativeEvent('change');
                this.control.setSelectionRange(0, 2);
            } else {
                minutes = minutes + 1;
                if (minutes > 59) {
                    minutes = 0;
                }
                this.setTime(hours, minutes);
                this.control.setSelectionRange(3, 5);
            }
            e.preventDefault();
        } else if (isKeyPressed(e, keyCodes.LEFT)) {
            this.control.setSelectionRange(0, 2);
            e.preventDefault();
        } else if (isKeyPressed(e, keyCodes.RIGHT)) {
            this.control.setSelectionRange(3, 5);
            e.preventDefault();
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (!this.rendered) {
            return;
        }
        switch (name) {
            case 'control-id':
                if (this.control) {
                    if (newValue) {
                        this.control.setAttribute('id', newValue);
                    } else {
                        this.control.removeAttribute('id');
                    }
                }
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const element = this.queryChildren('input')[0];
        this.detachChildNodes();

        const type = element?.getAttribute('type') || 'time';
        this.insertElements([
            {
                tagName: 'input',
                element: element,
                attributes: {
                    type: type,
                    id: element?.id || this.controlId,
                },
                classList: {
                    'ui-timepicker__input': true,
                },
            },
        ]);

        if (this.control.type === 'text') {
            this.control.setAttribute(
                'pattern',
                '^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])|(24:00)$'
            );
            this.control.setAttribute('maxlength', '5');
        }
    }

    hydrate() {
        updateElement(this.control, {
            events: {
                change: this.handleChangeValue.bind(this),
                focus: this.handleFocusInput.bind(this),
                blur: this.handleBlurInput.bind(this),
                click: this.handleClickInInput.bind(this),
                keydown: this.handleKeydownInInput.bind(this),
            },
        });
    }
}

UITimePicker.defineElement('ui-timepicker', styles);
export { UITimePicker };

import { UIElement } from '../ui-element.js';
import { browser } from '../../global/browser.js';
import { show } from '../../global/ui-helpers.js';
import styles from './ui-videobackground.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIVideoBackground
 * @element ui-videobackground
 * @classdesc Represents a class for <code>ui-videobackground</code> element.
 * Plays video on background with leaves.
 * @property {string} [color] {@attr color} Color of background color of video tag.
 * @property {string} [poster] {@attr poster} Poster for video tag.
 * @property {boolean} [enabled=true] {@attr enabled} Is video enabled.
 * @slot
 * @example
 * <ui-videobackground
 *  poster="/video/background-hd.jpg"
 *  enabled="true"
 *  color="#51971B">
 *   <video autoplay="true" muted="muted" loop="loop" poster="/video/background-hd.jpg">
 *     <source type="video/mp4" src="/video/background-hd.mp4">
 *   </video>
 * </ui-videobackground>
 */
class UIVideoBackground extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['poster', 'color'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                color: String,
                poster: String,
                enabled: { type: Boolean, default: true },
            },
        };
    }

    isVideoEnabled() {
        /**
         * Check if disabled manually
         */
        if (!this.enabled) {
            return false;
        }

        /**
         * Check if it is mobile by user agent
         */
        if (browser.isMobile()) {
            return false;
        }

        /**
         * Check screen media
         */
        if (this.mobileMediaQueryList.matches) {
            return false;
        }

        /**
         * Do not run video if we have slow or cellular connection
         */
        return !browser.isSlowOrCellularConnection();
    }

    /**
     * Gets video element.
     * @returns {boolean}
     */
    hasVideo() {
        return !!this.querySelector('video');
    }

    /**
     * Get video or create if this isn't has created video.
     * @returns {HTMLVideoElement}
     */
    getVideo() {
        return this.querySelector('video');
    }

    /**
     * Updates poster.
     */
    updatePoster() {
        this.style.backgroundImage = this.poster
            ? 'url(' + this.poster + ')'
            : '';
        if (this.hasVideo()) {
            this.getVideo().poster = this.poster ? this.poster : '';
        }
    }

    /**
     * Updates color.
     */
    updateColor() {
        this.style.backgroundColor = this.color ? this.color : 'initial';
    }

    /**
     * Runs video after users' interaction.
     */
    runVideoAfterInteraction() {
        const video = this.getVideo();

        if (!video.paused) {
            return;
        }

        const handleEvents = ['scroll', 'mouseover', 'keydown'];
        const complete = () => {
            handleEvents.forEach((eventName) => {
                document.removeEventListener(eventName, handler);
            });
        };

        const handler = () => {
            if (!video.paused) {
                complete();
            }
            video
                .play()
                .then(complete)
                .catch(() => {}); // ignored
        };

        handleEvents.forEach((eventName) => {
            document.addEventListener(eventName, handler);
        });
    }

    /**
     * Plays the video.
     */
    runVideo() {
        const video = this.getVideo();
        if (!video) {
            return;
        }
        show(video);
        if (video.paused) {
            const prom = video.play().catch(() => {}); // ignored
            if (!prom) {
                return;
            }
            prom.catch((err) => {
                console.log(err, err.message);
                this.runVideoAfterInteraction();
            });
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'poster':
                this.updatePoster();
                break;
            case 'color':
                this.updateColor();
                break;
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('aria-hidden', 'true');
        this.updatePoster();
        this.updateColor();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
        if (this.isVideoEnabled()) {
            const picture = this.querySelector('picture');
            this.runVideo();
            if (picture) {
                this.removeChild(picture);
            }
        } else if (this.hasVideo()) {
            let video = this.querySelector('video');
            video.src = '';
            this.removeChild(video);
            video = null;
        }
    }
}

UIVideoBackground.defineElement('ui-videobackground', styles);
export { UIVideoBackground };

import { rebuildChildren } from '../../global/ui-helpers.js';
import styles from './ui-icon-symbolic.css';
import { UIIcon } from './ui-icon.js';

/**
 * @memberof SharedComponents
 * @augments {UIIcon}
 * @alias UIIconSymbolic
 * @element ui-icon-symbolic
 * @classdesc Represents a class for <code>ui-icon-symbolic</code> element.
 * @property {string} value {@attr value} Symbol(s) for the inner content.
 * @property {("circle" | "square")} [bgshape] {@attr bgshape} Shape for the background.
 * @property {UIIconGlyph} [badgeGlyph] {@attr badge-glyph} Glyph (symbol) icon
 * from library provided by branding for badge.
 * @property {HTMLSpanElement} badge {@readonly} Container for badge icon.
 * @example
 * <ui-icon-symbolic value="dd"></ui-icon-symbolic>
 */
class UIIconSymbolic extends UIIcon {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                value: String,
                bgshape: { type: String, default: 'circle' },
                badgeGlyph: String,
            },
            children: {
                badge: '.ui-icon__badge',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['value', 'badge-glyph'];
    }

    /**
     * Build and return config for element with symbols.
     * @private
     * @returns {DocumentFragment | *}
     */
    buildSymbols() {
        return {
            tagName: 'span',
            classList: {
                'ui-icon-symbolic__content': true,
            },
            children: [
                document.createTextNode(
                    this.value.substring(0, 2).toUpperCase()
                ),
            ],
        };
    }

    /**
     * Render or rebuild ui-icon mark up.
     */
    renderIconLayout() {
        rebuildChildren(
            this,
            [
                this.value && this.buildSymbols(),
                this.badgeGlyph && this.buildBadge(),
            ].filter(Boolean)
        );
        this.classList.toggle('-badged', !!this.badgeGlyph);
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'value':
            case 'badge-glyph':
                this.renderIconLayout();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.renderIconLayout();
    }
}

UIIconSymbolic.defineElement('ui-icon-symbolic', styles);
export { UIIconSymbolic };

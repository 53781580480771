/**
 * @typedef {object} ISortParams
 * @property {string|null} field Field name
 * @property {number} direction 1 or -1
 */

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-dynamic-table pagination plugin.
 * @alias UISortablePlugin
 * @implements {IComponentPlugin}
 * @fires event:sort-table
 * @param {UIDynamicTable} target Target instance.
 * @property {UIDynamicTable} target Target instance.
 */
class UISortablePlugin {
    constructor(target) {
        this.target = target;
    }

    /**
     * @inheritDoc
     */
    modifyTable(uiTable) {
        const isAsc = this.target.sortDir === 'asc';
        const order = this.target.sortOrder;
        const hasNoDefaultOrder = !order;

        [].forEach.call(
            uiTable.querySelectorAll('th[data-sort-column]'),
            (th) => {
                const targetOrder = th.dataset.sortColumn;
                const isSameOrder = order && order === targetOrder;
                uiTable.updateElement.call(th, {
                    classList: {
                        sort: true,
                        '-asc': hasNoDefaultOrder || isAsc,
                        '-desc': !isAsc,
                        '-sorted': isSameOrder,
                    },
                    attributes: {
                        'data-event': 'sort',
                    },
                    children: [
                        {
                            tagName: 'button',
                            attributes: { type: 'button' },
                            children: [].slice.call(th.childNodes, 0),
                        },
                    ],
                });
            }
        );
        return uiTable;
    }

    /**
     * Apply sorting by given column
     * @fires event:sort-table
     * @param  {HTMLTableCellElement} th
     */
    sortByColumn(th) {
        let direction = th.classList.contains('-desc') ? 1 : -1;
        if (th.classList.contains('-sorted')) {
            direction = direction * -1;
        }
        const col = th.dataset.sortColumn;
        [].forEach.call(
            th.parentElement.querySelectorAll('th.sort'),
            (node) => {
                this.target.updateClassList.call(node, {
                    '-sorted': node === th,
                    '-desc': node === th && direction === 1,
                    '-asc': node !== th || direction === -1,
                });
            }
        );
        this.target.dispatchCustomEvent('sort-table', {
            direction: direction,
            column: col,
        });
    }

    /**
     * @param {ISortParams} params
     */
    setSortParams(params) {
        if (!params.field) {
            return;
        }

        const direction = params.direction || -1;
        const th = this.target.querySelector(
            'th.sort[data-sort-column=' + params.field + ']'
        );

        [].forEach.call(
            th.parentElement.querySelectorAll('th.sort'),
            (node) => {
                this.target.updateClassList.call(node, {
                    '-sorted': node === th,
                    '-desc': node === th && direction === -1,
                    '-asc': node !== th || direction === 1,
                });
            }
        );
    }

    /**
     * @returns {ISortParams}
     */
    getSortParams() {
        let th = this.target.querySelector('th.sort.-sorted');
        if (!th) {
            th = this.target.querySelector('th.sort');
        }
        if (!th) {
            return { field: null, direction: 1 };
        }
        return {
            field: th.dataset.sortColumn,
            direction: th.classList.contains('-desc') ? -1 : 1,
        };
    }

    /**
     * Fires callback when target table got a click inside
     * @param {CustomEvent} event
     * @private
     */
    handleClickEvent(event) {
        const eventHolder = this.target.closestEventHolder.call(
            event.target,
            this.target
        );
        if (eventHolder && eventHolder.dataset.event === 'sort') {
            event.preventDefault();
            this.sortByColumn(eventHolder);
        }
    }

    /** @inheritDoc */
    render() {}

    /**
     * @inheritDoc
     */
    hydrate() {
        this.target
            .querySelector('thead')
            .addEventListener('click', this.handleClickEvent.bind(this));
    }
}

export { UISortablePlugin };

/**
 *
 * @memberof UISortablePlugin
 * @event sort-table
 * @alias event:sort-table
 * @type {object}
 * @property {number} direction - 1 or -1
 * @property {string} column - column name
 * @augments {CustomEvent}
 */

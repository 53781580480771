import { UIElement } from '../ui-element.js';
import styles from './ui-option-account.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionAccount
 * @element ui-option-account
 * @classdesc Represents a class for <code>ui-option-account</code> element.
 * Similar to ui-option, but holds account and owner. Used with combination
 * with ui-dropdown.
 * @property {boolean} optgroup {@attr optgroup}
 * @property {boolean} control Flag to distinguish the location where option will be shown.
 * @property {string} [accountnumber] {@attr accountnumber} Account number
 * @property {string} [accountid] {@attr accountid} Bank account id (ibank, local, etc.).
 * @property {string} [holder] {@attr holder} Name of the account holder/owner.
 * @property {string} [amount] {@attr amount} Amount of available money in the account.
 * @property {string} [currency="EUR"] {@attr currency} Currency of account.
 * be located in control of the dropdown.
 * @slot
 * @example
 * <ui-dropdown layout="account">
 *  <select>
 *   <option data-holder="Tiina Puu" data-amount="100" data-account-number="EE111149490"></option>
 *   <option data-holder="Mari Muul" data-amount="200" data-account-number="EE222249450"></option>
 *  </select>
 * </ui-dropdown>
 */
class UIOptionAccount extends UIElement {
    static get POPOVER_TYPE() {
        return 'basic';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                optgroup: Boolean,
                control: Boolean,
                currency: { type: String, default: 'EUR' },
                accountid: String,
                amount: String,
                holder: String,
                accountnumber: String,
            },
        };
    }

    /**
     * @param {HTMLOptionElement} option
     * @param {string} text
     * @returns {boolean}
     */
    findMatch(option, text) {
        const strings = [
            option.innerText,
            option.getAttribute('value'),
            option.getAttribute('data-holder'),
            option.getAttribute('data-account-number'),
        ];
        return (
            strings.filter((str) => {
                return (
                    str && str.toLowerCase().indexOf(text.toLowerCase()) > -1
                );
            }).length > 0
        );
    }

    /**
     * Gets object as data from this option.
     * @returns {{
     *   amount: string,
     *   accountnumber: string,
     *   currency: string,
     *   holder: string,
     *   id: string
     * }}
     */
    getData() {
        return {
            id: this.accountid,
            currency: this.currency,
            holder: this.holder,
            amount: this.amount,
            accountnumber: this.accountnumber,
        };
    }

    getValue() {
        return this.accountnumber;
    }

    getText() {
        return this.holder;
    }
    /**
     * @inheritDoc
     */
    render() {
        this.updateClassList({
            '-control': this.control,
        });

        this.insertElements(
            this.optgroup
                ? [this.getAttribute('text')]
                : [
                      {
                          tagName: 'span',
                          classList: {
                              'ui-option-account__holder': true,
                              '-full': !this.amount,
                          },
                          children: [this.holder || ''],
                      },
                      this.amount && {
                          tagName: 'ui-amount',
                          attributes: {
                              currency: this.currency,
                              value: this.amount,
                          },
                      },
                      {
                          tagName: 'span',
                          attributes: {
                              class: 'ui-option-account__number',
                          },
                          children: [this.accountnumber || ''],
                      },
                  ]
        );

        if (this.control) {
            this.insertElements([
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'dropdown',
                    },
                },
            ]);
        }
    }
}

UIOptionAccount.defineElement('ui-option-account', styles);
export { UIOptionAccount };

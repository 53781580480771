import '../field/ui-field.js';
import '../curtains/ui-curtain.js';
import '../curtains/ui-curtains.js';
import { Labels } from '../../global/labels.js';
import { browser } from '../../global/browser.js';
import { UIElement } from '../ui-element.js';
import { getLanguageForCountry, deepFreeze } from '../../global/helpers.js';
import { updateElement } from '../../global/ui-helpers.js';
import { TabIndex } from '../../global/keyboard.js';
import styles from './ui-cookie-consent.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICookieConsent
 * @element ui-cookie-consent
 * @classdesc Represents a class for <code>ui-cookie-consent</code> element.
 * @fires event:cookie-consent-changed
 * @property {number} [expiresDays=365] {@attr expires-days} Cookies consent expiration in days.
 * @property {boolean} [forceShow=false] {@attr force-show} Force display cookie consent.
 * @property {boolean} [forceHide=false] {@attr force-hide} Forces to hide cookie consent.
 * consent UI, doesn't respect if consent is given or not.
 * @property {string} hrefPolicy {@attr href-policy} Link to page about cookie, where
 * are details described.
 * @property {string} hrefEntities {@attr href-entities} Link to page about Swedbank entities.
 * @property {string} hrefCookies {@attr href-cookies} Link to page about cookies.
 * @property {string} hrefSettings {@attr href-settings} Link to page change settings.
 * @property {string} hrefUsage {@attr href-usage} Link to cookies usage page.
 * @property {string} target {@attr target} Specifies were 'about-link' will be opened,
 * values are similar like for &lt;a> attribute.
 * @property {("EE" | "LV" | "LT")} country=EE {@attr country} Sets the country
 * where consent is shown.
 * @property {("ENG" | "RUS" | "EST" | "LAT" | "LIT")} language {@attr language} Sets the language
 * of the cookie consent element.
 * @property {boolean} handleStorage {@attr handle-storage} Also deletes localStorage data
 * @property {string} prefix Prefix of the storage key
 * @property {IConsentCategoriesList} whitelist Whitelist of the cookies and storage, set from the
 * external service. Only very basic with one COOKIE_CONSENT is provided by default.
 * @property {UIModal} container {@readonly} Shortcut to container div.
 * @property {UICurtain} settings {@readonly} Shortcut to settings curtain.
 * @property {HTMLAnchorElement} declineButton {@readonly} Shortcut to decline button.
 * @property {HTMLButtonElement} acceptButton {@readonly} Shortcut to accept all button.
 * @property {HTMLButtonElement} saveChoiceButton {@readonly} Shortcut to accept settings button.
 * @property {HTMLAnchorElement} acceptLink {@readonly} Shortcut to accept link in info text.
 * @property {HTMLAnchorElement} entitiesLink {@readonly} Shortcut to change link in info text.
 * @property {UIDropdown} langSelector {@readonly} Shortcut to change language selector.
 * @example
 * <ui-cookie-consent></ui-cookie-consent>
 */
class UICookieConsent extends UIElement {
    constructor() {
        super();
        /** @type {Set<UICookieConsentStorageDriver>} */
        this._storageDrivers = new Set();
        /** @type {string} */
        this._prefix = '';

        // Add native localStorage. If somebody from business decides to add more
        // absurd things like cache, sessionStorage, RAM, etc. Can be added with
        // similar way. Just implementing interface.
        this.addStorageDriver({
            key: (i) => window.localStorage.key.call(window.localStorage, i),
            length: () => window.localStorage.length,
            set: window.localStorage.setItem.bind(window.localStorage),
            get: window.localStorage.getItem.bind(window.localStorage),
            remove: window.localStorage.removeItem.bind(window.localStorage),
            clear: window.localStorage.clear.bind(window.localStorage),
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                expiresDays: { type: Number, default: 365 },
                forceShow: Boolean,
                forceHide: Boolean,
                hrefPolicy: String,
                hrefEntities: String,
                hrefCookies: String,
                hrefUsage: String,
                target: String,
                country: { type: String, default: 'EE' },
                language: String,
                handleStorage: Boolean,
            },
            children: {
                container: '.ui-cookie-consent__container',
                settings: '.ui-cookie-consent__settings',
                declineButton: '.ui-cookie-consent__decline-button',
                acceptButton: '.ui-cookie-consent__accept-button',
                saveChoiceButton: '.ui-cookie-consent__save-choice-button',
                langSelector: '.ui-cookie-consent__lang',
            },
        };
    }

    /**
     * Adds a storage service to the consent.
     * @param {UICookieConsentStorageDriver} service
     */
    addStorageDriver(service) {
        this._storageDrivers.add(service);
    }

    /**
     * Gets all storage drivers for the consent component.
     * @returns {Set<UICookieConsentStorageDriver>}
     */
    getStorageDrivers() {
        return this._storageDrivers;
    }

    /**
     * Prefix for storage, external client might use prefixes.
     * @returns {string}
     */
    get prefix() {
        return this._prefix || '';
    }
    set prefix(prefix) {
        this._prefix = prefix;
    }

    /**
     * @returns {Function}
     */
    get languageSwitcher() {
        return this._languageSwitcher;
    }
    set languageSwitcher(fn) {
        this._languageSwitcher = fn;
    }

    /**
     * Warning function for disallowed cookies is set from outside.
     * @type {Function}
     */
    static get warn() {
        return undefined;
    }

    /**
     * @type {string}
     */
    static get CONSENT_NAME() {
        return 'COOKIE_CONSENT';
    }

    /**
     * @type {IConsentCategoriesList}
     */
    static get categories() {
        return {
            NECESSARY: 'NECESSARY',
            ANALYTICAL: 'ANALYTICAL',
            TARGETING: 'TARGETING',
        };
    }

    /**
     * @type {number}
     * @private
     */
    static get CATEGORY_ALLOWED() {
        return 1;
    }

    /**
     * @type {number}
     * @private
     */
    static get CATEGORY_DISALLOWED() {
        return 0;
    }

    /* eslint-disable max-len */
    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-cookie-consent', {
            title: 'We use cookies',
            info: 'Swedbank Group {entitiesLink} registered in {country} use {cookiesLink} (or similar technologies) to provide you personalized information about our products and services and to improve performance of the website. If you give your consent to all these cookies, please press “Accept”. If you want to manage your preferences, please press “Select your own cookies”, or press “Decline” to decline all these cookies. Your choice for cookies will be stored for one year.',
            disclaimer:
                'Please note that some cookies are necessary for this website functioning. These cookies are processed based on our legitimate interest, hence user’s consent is not asked. For more information, see our {policyLink}.',
            accept: 'Accept',
            decline: 'Decline',
            saveChoice: 'Save my choice',
            myCookies: 'Select your own cookies',
            policyLinkLabel: 'Cookie Policy',
            HELP_NECESSARY:
                'Are necessary for the website to function. These cookies cannot be switched off; hence the user consent is not asked.',
            HELP_ANALYTICAL:
                'Help to improve performance of the website by counting site visits and traffic sources.  If declined, your browsing of this website will not be part of our statistics.',
            HELP_TARGETING:
                'Help to provide you with personalized information about our products and services. If declined, personalized content will not be presented to you.',
            CATEGORY_NECESSARY: 'Necessary',
            CATEGORY_ANALYTICAL: 'Analytical',
            CATEGORY_TARGETING: 'Targeting',
            EE: 'Estonia',
            LV: 'Latvia',
            LT: 'Lithuania',
            entities: 'entities',
            cookies: 'cookies',
            language: 'Language',
        });
        /* eslint-enable max-len */
    }

    /**
     * Whitelist of all Swedbank's approved cookies. This can be overridden or
     * expanded from other services.
     * @returns {Readonly<IConsentCategoriesList>}
     */
    static get whitelist() {
        if (!this._whitelist) {
            this._whitelist = {
                NECESSARY: ['COOKIE_CONSENT'],
                ANALYTICAL: [],
                TARGETING: [],
            };
        }
        return deepFreeze(this._whitelist);
    }
    static set whitelist(list) {
        this._whitelist = list;
    }

    /**
     * Deletes absolutely all cookies for the domain.
     */
    destroyAllCookies() {
        document.cookie.split(';').forEach(this.destroyCookieByName.bind(this));
    }

    /**
     * Withdraws with cookie consent, remove COOKIE_CONSENT and shows
     * cookie dialog again.
     */
    withdraw() {
        document.cookie =
            UICookieConsent.CONSENT_NAME +
            '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        this.show();
    }

    /**
     * Show view with info view (first layer).
     */
    showInfoView() {
        this.open();
    }

    /**
     * Show view with options, ignoring intro text (second layer).
     */
    showOptionsView() {
        this.settings.activate();
        this.open();
    }

    /**
     * Opens the cookie consent dialog.
     */
    open() {
        this.dispatchCustomEvent('modal-open', {
            selector: this.constructor.props.children.container,
        });
        this.container.focus();
    }

    /**
     * Closes the cookie consent dialog.
     */
    close() {
        this.container.close();
    }

    /**
     * Destroys all unwanted cookies.
     */
    destroyUnwantedCookies() {
        const whitelistedCookies = this.getWhitelisted();
        // 1. Destroy every cookie which isn't whitelisted categories.
        // 2. Destroy every cookie which isn't in whitelist at all.
        // But usually set manually or by 3rd parties.
        this.getAllCookiesList().forEach((cookieName) => {
            let isAllowedCookie = false;
            for (let i = 0; i < whitelistedCookies.length; ++i) {
                if (cookieName.indexOf(whitelistedCookies[i]) === 0) {
                    isAllowedCookie = true;
                    break;
                }
            }
            if (!isAllowedCookie) {
                this.destroyCookieByName(cookieName);
            }
            if (
                !this.isCookieWhitelisted(cookieName) &&
                typeof UICookieConsent.warn === 'function'
            ) {
                UICookieConsent.warn(
                    'COOKIES WARNING: %c' +
                        cookieName +
                        '%c is not approved in' +
                        ' Swedbank group. It is automatically removed!',
                    'font-weight: bold;',
                    'font-weight: normal;'
                );
            }
        });
    }

    /**
     * Destroys all unwanted storage.
     */
    destroyUnwantedStorage() {
        if (!this.handleStorage) {
            return;
        }
        const whiteListedStorage = this.getWhitelisted();
        this.getStorageDrivers().forEach((driver) => {
            const storageList = [];
            for (let i = 0; i < driver.length(); ++i) {
                storageList.push(driver.key(i));
            }
            storageList.forEach((storageKey) => {
                let isStorageKeyAllowed = false;
                for (let i = 0; i < whiteListedStorage.length; ++i) {
                    if (
                        (this.prefix + storageKey).indexOf(
                            whiteListedStorage[i]
                        ) !== -1 ||
                        storageKey.indexOf(whiteListedStorage[i]) === 0
                    ) {
                        isStorageKeyAllowed = true;
                        break;
                    }
                }
                if (!isStorageKeyAllowed) {
                    driver.remove(storageKey);
                    if (typeof UICookieConsent.warn === 'function') {
                        UICookieConsent.warn(
                            'STORAGE WARNING: %c' +
                                storageKey +
                                '%c is not approved in' +
                                ' Swedbank group. It is automatically removed!',
                            'font-weight: bold;',
                            'font-weight: normal;'
                        );
                    }
                }
            });
        });
    }

    /**
     * Get whitelisted cookies or storage.
     * @private
     */
    getWhitelisted() {
        return this.getAllowedCategories().reduce((acc, category) => {
            const cookies = UICookieConsent.whitelist[category].filter(
                (cookieName) => {
                    // Some cookies hava dynamic names and begins with prefix given in whitelist.
                    return cookieName.indexOf(cookieName) === 0;
                }
            );
            return acc.concat(cookies);
        }, []);
    }

    /**
     * Get allowed categories by user.
     * @private
     * @returns {string[]}
     */
    getAllowedCategories() {
        const selectedCategories = this.getSelectedCategories();
        return Object.keys(selectedCategories).filter((category) => {
            return (
                UICookieConsent.CATEGORY_ALLOWED ===
                selectedCategories[category]
            );
        });
    }

    /**
     * Destroys cookies by its name.
     * @param {string} cookieName
     * @private
     */
    destroyCookieByName(cookieName) {
        const params = [
            cookieName + '=',
            'expires=Thu, 01 Jan 1970 00:00:00 GMT',
        ];
        if (this.isSecureContext()) {
            params.push('Secure=true');
            params.push('SameSite=Strict');
        }
        params.push('path=/');
        const entry = params.join(';');
        document.cookie = entry;
        [
            location.hostname,
            location.hostname.replace(/^([a-z0-9]+\.)?/, '.'),
        ].forEach((domain) => {
            document.cookie = entry + ';domain=' + domain;
        });
    }

    /**
     * Creates checkboxes for categories in 'settings' view.
     * @returns {HTMLUListElement}
     * @private
     */
    buildCategoriesList() {
        const consentValues = this.getSelectedCategories();
        return this.createElement(
            {
                tagName: 'ul',
                classList: {
                    'input-list': true,
                },
                children: Object.keys(UICookieConsent.categories).map(
                    (category) => {
                        return {
                            tagName: 'li',
                            classList: {
                                '-hidden': false,
                            },
                            children: [
                                {
                                    tagName: 'input',
                                    classList: {
                                        'ui-cookie-consent__category-checkbox': true,
                                    },
                                    attributes: {
                                        name: category,
                                        id:
                                            'cookie-consent-category-' +
                                            category,
                                        type: 'checkbox',
                                        disabled:
                                            category ===
                                            UICookieConsent.categories
                                                .NECESSARY,
                                        checked:
                                            category ===
                                                UICookieConsent.categories
                                                    .NECESSARY ||
                                            consentValues[category] ===
                                                UICookieConsent.CATEGORY_ALLOWED
                                                ? 'checked'
                                                : null,
                                    },
                                },
                                {
                                    tagName: 'label',
                                    attributes: {
                                        for:
                                            'cookie-consent-category-' +
                                            category,
                                    },
                                    children: [
                                        UICookieConsent.labels[
                                            'CATEGORY_' + category
                                        ],
                                        this.createElement(
                                            this.createCategoryTooltip(category)
                                        ),
                                    ],
                                },
                            ],
                        };
                    }
                ),
            },
            true
        );
    }

    /**
     * Gets all selected settings.
     * @returns {IConsentCategoriesValues}
     */
    getSelectedCategories() {
        /**
         * @type {IConsentCategoriesValues}
         */
        let json = {
            [UICookieConsent.categories.NECESSARY]: 0,
        };
        let ctgStr = this.getConsentString();
        ctgStr = ctgStr
            ? ctgStr.split('=')[1]
            : `{"${UICookieConsent.categories.NECESSARY}": 0}`;
        try {
            json = JSON.parse(this.normalizeQuotes(ctgStr));
        } catch (e) {
            console.warn(
                'Cannot parse consent value from cookies. Withdrawing consent.'
            );
            this.withdraw();
        }
        return json;
    }

    /**
     * Due to old Jetty server in ibank, we need to replaced single quotes
     * to double.
     * @param {string} str
     * @returns {string}
     * @private
     */
    normalizeQuotes(str) {
        return str.replace(/'/g, '"');
    }

    /**
     * Gets consent string.
     * @returns {string}
     */
    getConsentString() {
        return document.cookie
            .split(';')
            .filter(
                (cookie) => cookie.indexOf(UICookieConsent.CONSENT_NAME) !== -1
            )[0];
    }

    /**
     * Create consent cookie in browser.
     * NB! There might be an issue for Chrome/Safari to set cookies on 'localhost'
     * without HTTPS enabled. If you have such issue please use IP address
     * 127.0.0.1 or any other local IP address instead of 'http://localhost/'.
     * @param {string} values
     */
    createCookieConsent(values) {
        const cookieParts = [
            UICookieConsent.CONSENT_NAME + '=' + values,
            'path=/',
            'domain=' + browser.resolveMainDomain(),
            'max-age=' + this.expiresDays * 86400,
        ];
        if (this.isSecureContext()) {
            cookieParts.push('Secure=true');
            cookieParts.push('SameSite=Strict');
        }
        document.cookie = cookieParts.join('; ');
    }

    /**
     * Checks that context of the page is secure (https).
     * @returns {boolean}
     */
    isSecureContext() {
        return window.location.protocol === 'https:';
    }

    /**
     * Creates about cookie link with label and link.
     * @returns {HTMLAnchorElement | string}
     * @private
     */
    createPolicyLink() {
        return this.createElement({
            tagName: 'a',
            attributes: {
                href: this.hrefPolicy || '#',
                target: this.target || null,
                rel: this.target === '_blank' ? 'noopener' : null,
            },
            classList: {
                'ui-cookie-consent__policy-link': true,
            },
            children: [UICookieConsent.labels.policyLinkLabel],
        });
    }

    /**
     * Creates change cookie link with label and link.
     * @returns {HTMLAnchorElement}
     * @private
     */
    createEntitiesLink() {
        return this.createElement({
            tagName: 'a',
            attributes: {
                href: this.hrefEntities || '#',
            },
            classList: {
                'ui-cookie-consent__entities-link': true,
            },
            children: [UICookieConsent.labels.entities],
        });
    }

    /**
     * Creates cookies usage link with label and link.
     * @returns {HTMLAnchorElement}
     * @private
     */
    createUsageLink() {
        return this.createElement({
            tagName: 'a',
            attributes: {
                href: this.hrefUsage || '#',
            },
            classList: {
                'ui-cookie-consent__usage-link': true,
            },
            children: [UICookieConsent.labels.usage],
        });
    }

    /**
     * Creates cookie link.
     * @returns {HTMLAnchorElement}
     * @private
     */
    createCookieLink() {
        return this.createElement({
            tagName: 'a',
            attributes: {
                href: this.hrefCookies || '#',
            },
            classList: {
                'ui-cookie-consent__cookie-link': true,
            },
            children: [UICookieConsent.labels.cookies],
        });
    }

    /**
     * Gets the correct country name based on attribute.
     * @returns {string}
     * @private
     */
    resolveCountryName() {
        return UICookieConsent.labels[this.country];
    }

    /**
     * Check if cookies has consent given.
     * @returns {boolean}
     */
    hasConsentGiven() {
        return (
            document.cookie.indexOf(UICookieConsent.CONSENT_NAME) > -1 &&
            this.isConsentValid()
        );
    }

    /**
     * Checks if cookie value of consent is a valid object.
     * @returns {boolean}
     */
    isConsentValid() {
        const consent = this.getConsentString().split('=').pop();
        let valid = true;
        try {
            JSON.parse(this.normalizeQuotes(consent));
        } catch {
            valid = false;
        }
        return valid;
    }

    /**
     * Saves cookie consent values.
     * @fires event:cookie-consent-changed
     */
    save() {
        const values = JSON.stringify(this.buildConsentValuesFromCheckboxes());
        this.createCookieConsent(values);

        this.destroyUnwantedCookies();
        this.destroyUnwantedStorage();
        this.close();
        this.dispatchCustomEvent('cookie-consent-changed', values);
    }

    /**
     * Builds string for cookies.
     * @returns {IConsentCategoriesValues | object}
     */
    buildConsentValuesFromCheckboxes() {
        const checkboxes = this.querySelectorAll(
            '.ui-cookie-consent__category-checkbox'
        );
        return [].reduce.call(
            checkboxes,
            (acc, chbox) => {
                acc[chbox.name] = chbox.checked
                    ? UICookieConsent.CATEGORY_ALLOWED
                    : UICookieConsent.CATEGORY_DISALLOWED;
                return acc;
            },
            {}
        );
    }

    /**
     * Handles accept all action
     * @param {Event} event
     * @private
     */
    handleAccept(event) {
        this.selectAllCategories();
        this.save();
    }

    /**
     * Handles save choice selected settings action.
     * @param {Event} event
     * @private
     */
    handleSaveChoice(event) {
        this.save();
    }

    /**
     * Handles decline selected settings action.
     * @param {Event} event
     * @private
     */
    handleDecline(event) {
        this.deselectAdditionalCategories();
        this.save();
    }

    /**
     * Selects all categories checkboxes.
     * @private
     */
    selectAllCategories() {
        this.querySelectorAll('.ui-cookie-consent__category-checkbox').forEach(
            (chbox) => {
                chbox.checked = true;
            }
        );
    }

    /**
     * Deselects all categories checkboxes.
     * @private
     */
    deselectAdditionalCategories() {
        [].filter
            .call(
                this.querySelectorAll('.ui-cookie-consent__category-checkbox'),
                (chbox) => {
                    return chbox.name !== UICookieConsent.categories.NECESSARY;
                }
            )
            .forEach((chbox) => (chbox.checked = false));
    }

    /**
     * Creates help tooltip for category.
     * @param {string} category
     * @returns {IElementConfig}
     * @private
     */
    createCategoryTooltip(category) {
        return {
            tagName: 'ui-tooltip',
            children: [UICookieConsent.labels['HELP_' + category]],
        };
    }

    /**
     * Checks if cookie is whitelisted and allowed.
     * @param {string} cookieName
     * @returns {boolean}
     */
    isCookieWhitelisted(cookieName) {
        const found = Object.keys(UICookieConsent.whitelist).reduce(
            (acc, key) => {
                const ctg = UICookieConsent.whitelist[key].filter(
                    (cn) => cookieName.indexOf(cn) === 0
                );
                return acc.concat(ctg);
            },
            []
        );
        if (found.length === 0) {
            return false;
        }
        return cookieName.indexOf(found[0]) !== -1;
    }

    /**
     * Gets all cookies for current domain
     * @returns {Array<string>}
     */
    getAllCookiesList() {
        return document.cookie.split(';').map((c) => c.trim().split('=')[0]);
    }

    /**
     * Prepare placeholders with links and appropriate labels.
     *
     * Note! {{days}} isn't used anymore, but keep it, might again change in the future.
     * @returns {Record<string, string>}
     * @private
     */
    getTextSubstitutions() {
        return {
            country: this.resolveCountryName(),
            days: String(this.expiresDays),
            cookiesLink: this.hrefCookies
                ? this.createCookieLink().outerHTML
                : this.createCookieLink().innerHTML,
            policyLink: this.createPolicyLink().outerHTML,
            entitiesLink: this.createEntitiesLink().outerHTML,
            usageLink: this.createUsageLink().outerHTML,
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!this.country) {
            console.warn(
                'The country attribute is mandatory for ui-cookie-consent'
            );
            console.warn('EE, LV or LT should be set to country');
        }

        const substitutions = this.getTextSubstitutions();

        this.insertElements(
            [
                {
                    tagName: 'ui-modal',
                    attributes: {
                        type: 'page',
                        compact: true,
                        closable: false,
                        tabindex: TabIndex.Active,
                    },
                    classList: {
                        'ui-cookie-consent__container': true,
                    },
                    children: [
                        {
                            tagName: 'div',
                            classList: {
                                'ui-cookie-consent__inner': true,
                            },
                            children: [
                                this.createLanguageSelector(),
                                {
                                    tagName: 'div',
                                    classList: {
                                        'ui-cookie-consent__views': true,
                                    },
                                    children: [
                                        {
                                            tagName: 'h2',
                                            classList: {
                                                'ui-cookie-consent__title': true,
                                            },
                                            children: [
                                                this.getLabel(
                                                    'title',
                                                    substitutions
                                                ),
                                            ],
                                        },
                                        {
                                            tagName: 'p',
                                            children: [
                                                this.getLabel(
                                                    'info',
                                                    substitutions
                                                ).replace(/\n/g, '<br/>'),
                                            ],
                                        },
                                        {
                                            tagName: 'div',
                                            classList: {
                                                'ui-cookie-consent__controls': true,
                                            },
                                            children: [
                                                {
                                                    tagName: 'button',
                                                    attributes: {
                                                        type: 'button',
                                                    },
                                                    classList: {
                                                        button: true,
                                                        'ui-cookie-consent__accept-button': true,
                                                    },
                                                    children: [
                                                        UICookieConsent.labels
                                                            .accept,
                                                    ],
                                                },
                                                {
                                                    tagName: 'button',
                                                    attributes: {
                                                        type: 'button',
                                                    },
                                                    classList: {
                                                        'ui-cookie-consent__decline-button': true,
                                                        button: true,
                                                    },
                                                    children: [
                                                        UICookieConsent.labels
                                                            .decline,
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            tagName: 'ui-curtains',
                                            children: [
                                                {
                                                    tagName: 'ui-curtain',
                                                    classList: {
                                                        'ui-cookie-consent__settings': true,
                                                    },
                                                    attributes: {
                                                        label: UICookieConsent
                                                            .labels.myCookies,
                                                    },
                                                    children: [
                                                        {
                                                            tagName: 'ui-field',
                                                            attributes: {
                                                                layout: 'fluid',
                                                            },
                                                            children: [
                                                                this.buildCategoriesList(),
                                                            ],
                                                        },
                                                        {
                                                            tagName: 'div',
                                                            classList: {
                                                                'ui-cookie-consent__controls': true,
                                                            },
                                                            children: [
                                                                {
                                                                    tagName:
                                                                        'button',
                                                                    attributes:
                                                                        {
                                                                            type: 'button',
                                                                        },
                                                                    classList: {
                                                                        button: true,
                                                                        'ui-cookie-consent__save-choice-button': true,
                                                                    },
                                                                    children: [
                                                                        UICookieConsent
                                                                            .labels
                                                                            .saveChoice,
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            tagName: 'p',
                            classList: {
                                'ui-cookie-consent__footer': true,
                                disclaimer: true,
                            },
                            children: [
                                this.getLabel('disclaimer', substitutions),
                            ],
                        },
                    ],
                },
            ],
            null,
            true
        );

        if (this.forceShow) {
            this.open();
            return;
        }

        if (this.hasConsentGiven() || this.forceHide) {
            if (this.container.isActive()) {
                this.close();
            }
        } else {
            this.open();
        }
    }

    /**
     * Create language selector for ui-cookie-consent.
     * @returns {IElementConfig}
     * @private
     */
    createLanguageSelector() {
        const langs = getLanguageForCountry(this.country.toUpperCase());
        const currentLang = this.language;
        return {
            tagName: 'ui-dropdown',
            classList: {
                'ui-cookie-consent__lang': true,
                '-hidden': !this.language,
            },
            attributes: {
                layout: 'lang',
                'mobile-native': true,
                'hide-selected': true,
            },
            children: [
                {
                    tagName: 'select',
                    attributes: {
                        'aria-label': UICookieConsent.labels.language,
                    },
                    children: langs.short.map((lang, i) => {
                        return {
                            tagName: 'option',
                            attributes: {
                                value: langs.long[i],
                                selected:
                                    langs.long[i] === currentLang
                                        ? 'selected'
                                        : null,
                            },
                            children: [langs.short[i]],
                        };
                    }),
                },
            ],
        };
    }

    /**
     * Handles language switch.
     * @param {CustomEvent} event
     */
    handleLanguageSwitch(event) {
        if (typeof this.languageSwitcher === 'function') {
            this.languageSwitcher(event?.target.value || '');
        } else {
            console.warn(
                "UICookieConsent.languageSwitcher function isn't defined"
            );
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        updateElement(this.acceptButton, {
            events: {
                click: this.handleAccept.bind(this),
            },
        });
        updateElement(this.declineButton, {
            events: {
                click: this.handleDecline.bind(this),
            },
        });
        updateElement(this.saveChoiceButton, {
            events: {
                click: this.handleSaveChoice.bind(this),
            },
        });

        this.langSelector.addEventListener(
            'change',
            this.handleLanguageSwitch.bind(this)
        );

        // Some cookies set on weird images on ibank, so clean-up again on window.load.
        this.windowLoadHandler = () => {
            this.destroyUnwantedCookies();
            this.destroyUnwantedStorage();
        };
        window.addEventListener('load', this.windowLoadHandler);
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        window.removeEventListener('load', this.windowLoadHandler);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        window.addEventListener('load', this.windowLoadHandler);
    }
}

UICookieConsent.defineElement('ui-cookie-consent', styles);
export { UICookieConsent };

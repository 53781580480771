import { UIElement } from '../ui-element.js';
import { TabIndex } from '../../global/keyboard.js';
import styles from '../scrollable-frame/ui-scrollable-frame.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIScrollableFrame
 * @element ui-scrollable-frame
 * @classdesc Represents a class for <code>ui-scrollable-frame</code> element.
 * Frame with given max height and scroll if the content is too huge.
 * @property {string} [maxHeight="140px"] {@attr max-height} Fixed height for frame.
 * @slot
 * @example
 * <ui-scrollable-frame max-height="10vh">
 *   <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
 *   Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
 *   Nullam quis risus eget urna mollis ornare vel eu leo.</p>
 *   <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
 *   Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum.
 *   Nullam quis risus eget urna mollis ornare vel eu leo.</p>
 * </ui-scrollable-frame>
 */
class UIScrollableFrame extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['max-height'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                maxHeight: { type: String, default: '140px' },
            },
        };
    }

    /**
     * Updates height styles for the frame
     */
    updateAppearance() {
        this.style.maxHeight = this.maxHeight || '140px';
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }

        switch (name) {
            case 'max-height':
                this.updateAppearance();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        // Set tabindex to allow scroll with arrows without scrolling the window.
        this.setAttribute('tabindex', TabIndex.Active);
        this.updateAppearance();
    }
}

UIScrollableFrame.defineElement('ui-scrollable-frame', styles);
export { UIScrollableFrame };

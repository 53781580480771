import { UIElement } from '../ui-element.js';
import styles from './ui-option-search.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionSearch
 * @element ui-option-search
 * @classdesc Represents a class for <code>ui-option-basic</code> element.
 * Similar to ui-option, but holds basic value. Used with combination
 * with ui-dropdown.
 * @property {boolean} control {@readonly} It shows if the element will be located in control.
 * @property {string} area Defines an area of search result.
 * @property {string} formatted Defines an area of search result.
 * @example
 * <ui-option-basic></ui-option-basic>
 */
class UIOptionSearch extends UIElement {
    static get POPOVER_TYPE() {
        return 'basic';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                area: String,
                formatted: String,
            },
        };
    }

    /**
     * Finds match in option value and given texts.
     * @param {HTMLOptionElement} option
     * @param {string} text
     * @returns {boolean}
     */
    findMatch(option, text) {
        const val = option.getAttribute('value').toLowerCase();
        return val.indexOf(text.toLowerCase()) > -1;
    }

    /**
     * For basic option returns it's own dataset only.
     * @returns {Record<string, string>}
     */
    getData() {
        return this.getAttributes();
    }

    renderTextValue() {
        const baseTextValue =
            this.getAttribute('text') || this.getAttribute('value') || '';
        const children = [];
        if (this.formatted && baseTextValue !== this.formatted) {
            const formatted = this.formatted;
            children.push(
                ...formatted.split('**').map((chunk, index) => {
                    if (index % 2 === 1) {
                        return { tagName: 'strong', children: [chunk] };
                    } else {
                        return chunk;
                    }
                })
            );
        } else {
            children.push(baseTextValue);
        }

        if (!this.area || this.control) {
            return {
                tagName: 'span',
                children: children,
            };
        }

        return {
            tagName: 'div',
            classList: {
                'ui-option-search__group': true,
            },
            children: [
                {
                    tagName: 'span',
                    children: children,
                },
                {
                    tagName: 'span',
                    classList: {
                        'ui-option-search__area': true,
                    },
                    children: [this.area],
                },
            ],
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateElement({
            classList: {
                '-control': this.control,
            },
            children: [
                this.renderTextValue(),
                this.control && {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'dropdown',
                    },
                },
            ],
        });
    }
}

UIOptionSearch.defineElement('ui-option-search', styles);
export { UIOptionSearch };

import '../calendar/ui-calendar.js';
import { UIDateElement } from './ui-dateelement.js';
import {
    makePopoverHandler,
    appendModalControllerIfRequired,
} from '../../global/helpers.js';
import { keyCodes, TabIndex } from '../../global/keyboard.js';
import { Labels } from '../../global/labels.js';
import {
    addSyntheticFocusVisible,
    buildSyntheticFocusControl,
    dispatchNativeEvent,
    insertElements,
    updateElement,
    hide,
    show,
    redetachChildNodes,
    PopoverAlignment,
} from '../../global/ui-helpers.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-datepicker.css';

/**
 * @memberof SharedComponents
 * @augments {UIDateElement}
 * @alias UIDatePicker
 * @element ui-datepicker
 * @classdesc Represents a class for <code>ui-datepicker</code> element.
 * Creates input field with opening dialog to pick date.
 * @property {string} value {@attr value} Value of datepicker.
 * @property {Locale} [locale="en"] {@attr locale} Localization rules.
 * @property {boolean} expanded {@attr expanded} If true, datepicker is expanded,
 * otherwise hidden.
 * @property {boolean} allowEmpty {@attr allow-empty} If true, empty values won't be replaced
 * with default date
 * @property {boolean} disabled {@attr disabled} If true, datepicker is disabled,
 * otherwise enabled. NB! this is only for make-up to disabled datepicker use
 * <code>datepicker.control.disabled = true</code>.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {AlignmentValue} [alignment] {@attr alignment}
 * Forced alignment for datepicker.
 * @property {HTMLInputElement} control {@readonly} Shortcut to control element.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @property {UICalendar} calendar {@readonly} Shortcut to calendar element.
 * @property {HTMLButtonElement} button {@readonly} Shortcut to button element.
 * @property {UIData} configSource {@readonly} Shortcut to data element.
 * @property {HTMLButtonElement} closeBtn {@readonly} Shortcut to close button element.
 * @property {HTMLButtonElement} clearBtn {@readonly} Shortcut to clean button if present.
 * @slot
 * @example
 * <ui-datepicker>
 *   <input type="text" name="date" min="20.09.2018" max="25.10.2020" value="23.09.2018">
 * </ui-datepicker>
 */
class UIDatePicker extends UIDateElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['expanded', 'locale', 'control-id', 'allow-empty'];
    }

    static get VERTICAL_OFFSET() {
        return 5;
    }

    /**
     * Defines class properties.
     */
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                defaultValue: String,
                expanded: Boolean,
                disabled: Boolean,
                allowEmpty: Boolean,
                controlId: String,
                alignment: String,
            },
            children: {
                control: {
                    selector: '.ui-datepicker__input',
                    fallback: function () {
                        return (
                            this.queryChildren('input')[0] ||
                            document.createElement('input')
                        );
                    },
                },
                button: '.ui-datepicker__control',
                focusTrigger: '.ui-datepicker__focus-trigger',
                configSource: 'ui-data',
                clearBtn: '.ui-datepicker__clear',
            },
        };
    }

    get calendar() {
        if (this._detachedPopover) {
            return this._detachedPopover.querySelector('ui-calendar');
        }
        return this.querySelector('ui-calendar');
    }

    get value() {
        if (!this.isValidDate(this.control.value, this.allowEmpty)) {
            return null;
        }
        return this.reformatDateString(this.control.value);
    }
    set value(value) {
        const control = this.control;
        if (
            !this.isValidDate(value, this.allowEmpty) ||
            control.value === value
        ) {
            return;
        }
        control.value = value;
    }

    /**
     * Checks that the datepicker's value can be editable, which means neither disabled nor read-only.
     * @type {boolean}
     * @readonly
     */
    get isEditable() {
        return !(this.control.readOnly || this.control.disabled);
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-datepicker', {
            chooseDate: 'Choose date',
            selectedDate: 'Selected date',
            close: 'Close',
            clear: 'Clear input',
        });
    }

    /**
     * Checks if node is detached child.
     * @param {Node} node
     * @returns {boolean}
     */
    isDetachedChild(node) {
        return this._detachedPopover && this._detachedPopover.contains(node);
    }

    /**
     * Checks if node is internal element.
     * @param {Node} node
     * @returns {boolean}
     */
    isInternalChild(node) {
        return this.contains(node) || this.isDetachedChild(node);
    }

    /**
     * Closes datepicker popover.
     */
    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        const modal = this._detachedPopover.closest('ui-modal');
        if (modal) {
            this._detachedPopover = null;
            modal.close();
        }
    }

    /**
     * Opens detached popover.
     * @fires event:modal-open
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }

        // If control is readonly do not open calendar.
        if (this.control.readOnly) {
            this.expanded = false;
            return;
        }

        this._detachedPopover = this.createElement(this.renderPopover());
        this.prerenderElementsTree.call(this._detachedPopover, true);
        if (this._config) {
            this.calendar.setConfig(this._config);
        }
        this.calendar.updateControlValue(this.value, 'ui-datepicker');
        updateElement(this.calendar, {
            attributes: {
                locale: this.locale,
            },
            events: {
                change: this.handleChangeCalendarValue.bind(this),
            },
        });
        const closeBtn = this._detachedPopover.querySelector(
            '.ui-datepicker__close-btn'
        );
        closeBtn &&
            closeBtn.addEventListener(
                'click',
                this.closeDetachedPopover.bind(this)
            );
        appendModalControllerIfRequired();
        this.dispatchCustomEvent('modal-open', {
            type: 'popover',
            content: this._detachedPopover,
            params: {
                classList: {
                    '-datepicker': true,
                },
                position: makePopoverHandler(this.control, {
                    popoverHeight: () =>
                        this.calendar ? this.calendar.offsetHeight : 0,
                    popoverWidth: () =>
                        this.calendar ? this.calendar.offsetWidth : 0,
                    verticalOffset: UIDatePicker.VERTICAL_OFFSET,
                    ...this.resolveAlignment(),
                }),
                target: this,
                onOpen: () => {
                    if (this._detachedPopover) {
                        let btn = this._detachedPopover.querySelector(
                            '[data-date="' + this.value + '"]'
                        );
                        if (!btn) {
                            btn = this.calendar.today;
                        }
                        if (!btn) {
                            btn =
                                this.calendar.activeDayButtons[
                                    this.calendar.activeDayButtons.length - 1
                                ];
                            btn.tabIndex = TabIndex.Active;
                        }
                        this.calendar.monthPicker.recalculateMonthWidth();
                        btn && requestAnimationFrame(() => btn.focus());
                    }
                },
                onClose: () => {
                    if (
                        !document.activeElement ||
                        document.activeElement === document.body
                    ) {
                        this.button.focus({
                            preventScroll: true,
                        });
                    }
                    this.expanded = false;
                },
                attributes: {
                    dynamic: true,
                },
            },
        });
    }

    /**
     * @private
     * @returns {
     *   {
     *     supportBottomAlignment: boolean,
     *     supportTopAlignment: boolean,
     *     supportMiddleAlignment: boolean,
     *     verticalAxisSide: Extract<AlignmentValue, "top" | "bottom">
     *   } | {}
     * }
     */
    resolveAlignment() {
        if (!this.alignment) {
            return {};
        }
        return {
            supportMiddleAlignment: this.alignment === PopoverAlignment.Middle,
            supportTopAlignment: this.alignment === PopoverAlignment.Top,
            supportBottomAlignment: this.alignment === PopoverAlignment.Bottom,
            verticalAxisSide:
                this.alignment === PopoverAlignment.Top
                    ? PopoverAlignment.Top
                    : PopoverAlignment.Bottom,
        };
    }

    /**
     * Fires callback when the popover state has been changed.
     * @private
     */
    onChangePeriod() {
        if (this.calendar) {
            this.calendar.min = this.min;
            this.calendar.max = this.max;
        }
    }

    /**
     * Fires callback when the popover state has been changed.
     * @private
     */
    onChangePopoverState() {
        if (this.expanded) {
            this.openDetachedPopover();
            document.addEventListener('click', this.handleClickOutside);
            document.addEventListener('keydown', this.handleKeyDown);
        } else {
            this.closeDetachedPopover();
            document.removeEventListener('click', this.handleClickOutside);
            document.removeEventListener('keydown', this.handleKeyDown);
        }
    }

    /**
     * Fires callback when user clicked outside of element.
     * @param {Event} event
     * @private
     */
    handleClickOutside(event) {
        if (!this.expanded) {
            return;
        }
        if (this.contains(event.target) || this.isDetachedChild(event.target)) {
            return;
        }
        this.button.focus({
            preventScroll: true,
        });
        this.expanded = false;
    }

    /**
     * Fires callback when control button is clicked.
     * @private
     */
    handleTogglePopover() {
        this.expanded = !this.expanded;
    }

    /**
     * Fires callback when the calendar's value is updated.
     * @param {CustomEvent} e
     * @private
     */
    handleChangeCalendarValue(e) {
        if (e.target !== this.calendar) {
            return;
        }
        this.control.value = this.calendar.value;
        if (
            !e.detail ||
            ['month-changed', 'ui-datepicker'].indexOf(e.detail.source) === -1
        ) {
            this.button.focus({
                preventScroll: true,
            });
            if (this.expanded) {
                this.expanded = false;
            }
        }
        dispatchNativeEvent(this.control, 'input', true);
        if (this.control.willValidate && this.control.required) {
            dispatchNativeEvent(this.control, 'invalid', true);
        }
    }

    /**
     * Fires callback when the input's value is updated.
     * @param {Event} e
     * @private
     */
    handleChangeInputValue(e) {
        const value = this.control.value;
        const nextValue =
            value.trim() === '' && this.allowEmpty
                ? ''
                : this.parseDateValue(value, this.min, this.max).value;
        if (this.control.value !== nextValue) {
            this.control.value = nextValue;
            dispatchNativeEvent(this.control, 'change', true);
        }
        if (this.calendar && nextValue) {
            this.calendar.value = nextValue;
            this.calendar.updateControlValue(nextValue);
        }
        this.updateButtons();
    }

    /**
     * Fires callback when the input control gets an input event.
     * @param {Event} e
     * @private
     */
    handleInputValue(e) {
        const value = this.control.value;
        if (!this.isValidDate(value, this.allowEmpty)) {
            return;
        }
        const values = this.parseDateString(value);
        const date = new Date(values.year, values.month - 1, values.date);
        if (!this.isDateInRange(date)) {
            return;
        }
        updateElement(this.button, {
            attributes: {
                'aria-label': this.resolveSelectedDateStr(),
            },
        });
        this.handleChangeInputValue(e);
    }

    /**
     * Fires callback when the input control gets a focus event.
     * @param {Event} e
     * @private
     */
    handleFocusInput(e) {
        if (e.isTrusted && !this.expanded) {
            this.expanded = true;
        }
    }

    /**
     * Handles clean event.
     * @param {Event} e
     * @private
     */
    handleClear(e) {
        if (!this.allowEmpty) {
            return;
        }

        this.control.value = '';
        dispatchNativeEvent(this.control, 'change', true);
        this.control.focus();
    }

    /**
     * @inheritDoc
     */
    handleControlMutations(mutations) {
        for (let i = 0; i < mutations.length; i++) {
            const mutation = mutations[i];
            if (mutation.type !== 'attributes') {
                continue;
            }
            if (['value', 'min', 'max'].indexOf(mutation.attributeName) > -1) {
                if (
                    mutation.oldValue !==
                    mutation.target.getAttribute(mutation.attributeName)
                ) {
                    this.onChangePeriod();
                    return;
                }
            }
            if (mutation.attributeName === 'disabled') {
                this.disabled = mutation.target.disabled;
                this.button.disabled = mutation.target.disabled;
                if (this.clearBtn) {
                    this.clearBtn.disabled = mutation.target.disabled;
                }
                if (this.disabled) {
                    this.expanded = false;
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'expanded':
                this.onChangePopoverState();
                break;
            case 'control-id':
                if (this.focusTrigger) {
                    if (newValue) {
                        this.focusTrigger.setAttribute('id', newValue);
                    } else {
                        this.focusTrigger.removeAttribute('id');
                    }
                }
                if (this.control) {
                    this.control.setAttribute('aria-labelledby', newValue);
                }
                break;
            case 'allow-empty':
                if (this.state !== 'hydrated') {
                    return;
                }

                if (this.allowEmpty) {
                    insertElements(this, [this.renderClearButton()]);
                    updateElement(this.clearBtn, {
                        events: {
                            click: this.handleClear.bind(this),
                        },
                    });
                    this.updateButtons();
                } else {
                    this.clearBtn.parentElement.removeChild(this.clearBtn);
                }
                break;
            default:
                break;
        }
    }

    /**
     * Updates appearance regarding to input value.
     */
    updateButtons() {
        if (!this.allowEmpty) {
            return;
        }
        if (this.control.value) {
            show(this.clearBtn);
            hide(this.button);
        } else {
            hide(this.clearBtn);
            show(this.button);
        }
    }

    /**
     * Resolves the regexp pattern.
     * @returns {string}
     * @private
     */
    resolvePattern() {
        return this.locale === 'lt'
            ? '[0-9]{4}-[0-9]{2}-[0-9]{2}'
            : '[0-9]{1,2}\\.[0-9]{1,2}\\.[0-9]{4}';
    }

    /**
     * Returns popover calendar content config.
     * @returns {IElementConfig}
     * @private
     */
    renderPopover() {
        return {
            tagName: 'div',
            attributes: {
                class: 'ui-datepicker__popover',
            },
            children: [
                {
                    tagName: 'ui-calendar',
                    attributes: {
                        locale: this.locale || UIDatePicker.LOCALE,
                    },
                    children: [
                        {
                            tagName: 'input',
                            attributes: {
                                type: 'hidden',
                                value: this.value,
                                min: this.min,
                                max: this.max,
                            },
                        },
                    ],
                },
                {
                    tagName: 'button',
                    classList: {
                        '-link': true,
                        'ui-datepicker__close-btn': true,
                    },
                    children: [this.getLabel('close')],
                },
            ],
        };
    }

    /**
     * Returns clear button config.
     * @returns {IElementConfig}
     * @private
     */
    renderClearButton() {
        return {
            tagName: 'button',
            attributes: {
                class: 'ui-datepicker__clear -iconed -hidden',
                type: 'button',
                'aria-label': UIDatePicker.labels.clear,
                disabled: this.hasAttribute('disabled') ? '' : null,
            },
            children: [
                {
                    tagName: 'ui-icon',
                    attributes: {
                        bgcolor: UIIcon.colors.BARK_30,
                        glyph: 'cross',
                    },
                },
            ],
        };
    }

    /**
     * Fires callback when user presses tab.
     * @param {KeyboardEvent} event
     * @private
     */
    handleKeyDown(event) {
        if (keyCodes.ESCAPE === event.keyCode) {
            this.expanded = false;
            this.closeDetachedPopover();
            this.button.focus({
                preventScroll: true,
            });
        }
    }

    /**
     * Resolves accessibility selected date string.
     * @returns {string}
     * @private
     */
    resolveSelectedDateStr() {
        let str = UIDatePicker.labels.chooseDate;
        if (this.value) {
            str += ', ' + UIDatePicker.labels.selectedDate + ' ' + this.value;
        }
        return str;
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.closeDetachedPopover();
    }

    /**
     * @inheritDoc
     */
    render() {
        const control = this.queryChildren('input')[0];
        if (control) {
            control.classList.add('ui-datepicker__input');
            ['value', 'min', 'max'].forEach((a) => {
                if (control.hasAttribute(a)) {
                    control.setAttribute(a, this.reformatDateString(this[a]));
                }
            });
            if (control.hasAttribute('disabled')) {
                this.setAttribute('disabled', 'disabled');
            }
        }

        const pattern = this.resolvePattern();
        this.insertElements([
            buildSyntheticFocusControl(this.controlId, 'ui-datepicker'),
            {
                tagName: 'input',
                element: control,
                attributes: {
                    type: 'text',
                    pattern: pattern,
                    autocomplete: 'off',
                    'aria-autocomplete': 'none',
                    'aria-labelledby': this.controlId,
                },
                classList: {
                    'ui-datepicker__input': true,
                },
            },
            {
                tagName: 'button',
                attributes: {
                    class: 'ui-datepicker__control',
                    type: 'button',
                    'aria-label': this.resolveSelectedDateStr(),
                    disabled: this.hasAttribute('disabled') ? '' : null,
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'calendar',
                        },
                    },
                ],
            },
        ]);

        if (this.allowEmpty) {
            insertElements(this, [this.renderClearButton()]);
            this.updateButtons();
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.onChangePeriod();
        updateElement(this.control, {
            events: {
                click: this.handleTogglePopover.bind(this),
                // focus: this.handleFocusInput.bind(this),
                change: this.handleChangeInputValue.bind(this),
                input: this.handleInputValue.bind(this),
                keydown: this.handleKeyDown.bind(this),
            },
        });
        updateElement(this.button, {
            events: {
                click: this.handleTogglePopover.bind(this),
                keydown: this.handleKeyDown,
            },
        });
        if (this.allowEmpty) {
            updateElement(this.clearBtn, {
                events: {
                    click: this.handleClear.bind(this),
                },
            });
        }
        this.observer = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.observer.observe(this.control, { attributes: true });
        this.focusTrigger.addEventListener('click', () =>
            addSyntheticFocusVisible(this.control)
        );

        if (this.configSource) {
            redetachChildNodes(this);
            this.configSource.get((config) => {
                this._config = config;
                if (this.calendar) {
                    this.calendar.setConfig(config);
                }
            });
        }
    }
}

UIDatePicker.defineElement('ui-datepicker', styles);
export { UIDatePicker };

import { UIElement } from '../ui-element.js';
import styles from './ui-curtains.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICurtains
 * @element ui-curtains
 * @classdesc Represents a class for <code>ui-curtains</code> element.
 * Container for ui-curtain elements. Can container for ui-curtain element.
 * @listens event:curtain-toggle
 * @property {boolean} [autoclose=false] {@attr autoclose} If true, curtain will
 * close automatically, when other curtain inside ui-curtains is opened. Acts like accordion.
 * @property {boolean} [autoscroll=false] {@attr autoscroll} Enables curtain scroll
 * in smaller viewport. When user opens a curtain window scrolls to it's offset.
 * @property {("default" | "numbered")} [layout] {@attr layout} Layout of curtains.
 *  {@desc numbered}
 * @slot {@type "ui-curtain"}
 * @example
 * <ui-curtains autoclose="false" layout="numbered">
 *   <ui-curtain></ui-curtain>
 *   <ui-curtain></ui-curtain>
 *   <ui-curtain></ui-curtain>
 * </ui-curtains>
 */
class UICurtains extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['autoclose', 'layout'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                autoclose: Boolean,
                layout: String,
                autoscroll: Boolean,
            },
        };
    }

    /**
     * Close each curtain except first if 'autoclose' functionality is switched on.
     */
    prepareAutocloseCurtains() {
        [].forEach.call(
            this.queryChildren('ui-curtain[open] ~ ui-curtain[open]'),
            (element) => (element.open = false)
        );
    }

    /**
     * Fires callback when curtain-toggle event will bubble.
     * @param {UIEvent} e
     * @private
     */
    handleCurtainToggle(e) {
        if (!this.autoclose) {
            return;
        }

        if (this !== e.target.closest('ui-curtains')) {
            return;
        }

        [].forEach.call(this.queryChildren('ui-curtain[open]'), (target) => {
            if (target !== e.target) {
                target.open = false;
            }
        });
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'autoclose':
                this.prepareAutocloseCurtains();
                break;
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.addEventListener(
            'curtain-toggle',
            this.handleCurtainToggle.bind(this)
        );
    }
}

UICurtains.defineElement('ui-curtains', styles);
export { UICurtains };

import { createElement } from '../../global/ui-helpers.js';
import { UIBadge } from '../badge/ui-badge.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import { UIIcon } from '../icon/ui-icon.js';

/**
 * Create element to store label for tab's badge.
 * @param {string} label Label for tab's badge.
 * @returns {HTMLSpanElement}
 * @private
 */
export function buildTabsBadge(label) {
    return createElement({
        tagName: 'ui-badge',
        attributes: {
            color: BrandColors.Orange,
            layout: UIBadge.Layout.Pin,
        },
        children: [document.createTextNode(label)],
    });
}

/**
 * Builds new window icon.
 * @returns {UIIcon}
 * @private
 */
export function buildNewWindowIcon() {
    return createElement({
        tagName: 'ui-icon',
        attributes: {
            glyph: 'new-window',
            color: UIIcon.colors.DEFAULT,
        },
    });
}

import { UIElement } from '../ui-element.js';
import styles from './ui-option-color.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionColor
 * @element ui-option-color
 * @classdesc Represents a class for <code>ui-option-icon</code> element.
 * Similar to ui-option, but holds color. Used with combination ui-dropdown.
 * @property {boolean} control Flag to distinguish the location where option will be shown.
 * @property {string} [color] {@attr color} Icon color. Custom property or color name/hex.
 * @property {boolean} [customproperty] {@attr customproperty} Is custom property.
 * @slot
 * @example
 * <ui-dropdown layout="color">
 *   <select>
 *     <option data-color="--ui-color-orange" data-customproperty="true">Orange</option>
 *     <option data-color="#5B8AD6">Blue</option>
 *   </select>
 * </ui-dropdown>
 */
class UIOptionColor extends UIElement {
    static get POPOVER_TYPE() {
        return 'basic';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                color: String,
                customproperty: Boolean,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateClassList({
            '-control': this.control,
        });

        this.insertElements([
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-option-color__box',
                    style: `background-color: ${
                        this.customproperty ? `var(${this.color})` : this.color
                    };`,
                },
            },
            {
                tagName: 'span',
                attributes: {
                    class: 'ui-option-color__label',
                },
                children: [
                    this.getAttribute('text') ||
                        this.getAttribute('value') ||
                        '',
                ],
            },
        ]);

        if (this.control) {
            this.insertElements([
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'dropdown',
                    },
                },
            ]);
        }
    }
}

UIOptionColor.defineElement('ui-option-color', styles);
export { UIOptionColor };

import { UIElement } from '../ui-element.js';
import styles from './ui-amount.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAmount
 * @element ui-amount
 * @classdesc Represents a class for <code>ui-amount</code> element.
 * Create element with bigger sum and additional feature to control such element,
 * like leading +/- characters, percious, format, etc.
 * @property {number} [value=0] {@attr value} Value of amount like 13.9, 134.3 etc.
 * @property {number} [accuracy=1] {@attr accuracy} Formats a number using fixed-point notation
 * (-1 to disable accuracy).
 * @property {string} [currency] {@attr currency} Currency label like USD, EUR, SEK etc.
 * @property {boolean} [leadingPlus=false] {@attr leading-plus} If true, positive value would be
 * with + at the beginning.
 * @property {boolean} [format=false] {@attr format} If true, thousands separators will be added.
 * @slot {@name additional} Additional info
 * @example
 * <ui-amount value="100.56" currency="EUR" leading-plus>
 *   <a href="#" slot="additional">65.87</a>
 *   <span slot="additional"> Reserved</span>
 * </ui-amount>
 */
class UIAmount extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['accuracy', 'value', 'currency', 'leading-plus'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                accuracy: { type: Number, default: -1 },
                currency: String,
                leadingPlus: Boolean,
                value: { type: Number, default: 0 },
                format: Boolean,
            },
        };
    }

    /**
     * Get or create element for main value in needed format
     * @returns {Element}
     */
    getValue() {
        const container = this.getMainContainer();
        let _value = this.value;
        if (this.accuracy > -1) {
            _value = _value.toFixed(this.accuracy);
        }
        _value = _value > 0 && this.leadingPlus ? '+' + _value : _value;
        let el =
            container.querySelector('span.ui-amount__value-positive') ||
            container.querySelector('span.ui-amount__value-negative');
        if (!el) {
            el = document.createElement('span');
            container.appendChild(el);
        }
        el.innerText = this.format
            ? _value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
            : _value;
        el.className =
            _value >= 0
                ? 'ui-amount__value-positive'
                : 'ui-amount__value-negative';

        return el;
    }

    /**
     * Get or create element for currency
     * @returns {Element}
     */
    getCurrency() {
        const container = this.getMainContainer();
        let el = this.querySelector('span.ui-amount__currency');
        if (!el) {
            el = document.createElement('span');
            el.className = 'ui-amount__currency';
            container.appendChild(el);
        }
        el.innerText = ' ' + this.currency;

        return el;
    }

    /**
     * Get or create container element for main value and currency
     * @returns {Element}
     */
    getMainContainer() {
        let el = this.querySelector('div.ui-amount__main');
        if (!el) {
            el = document.createElement('div');
            el.className = 'ui-amount__main';
            this.appendChild(el);
        }

        return el;
    }

    /**
     * Get or create container element for additional info
     * @returns {Element}
     */
    getAdditionalContainer() {
        let el = this.querySelector('div.ui-amount__additional');
        if (!el) {
            el = document.createElement('div');
            el.className = 'ui-amount__additional';
            this.appendChild(el);
        }

        return el;
    }

    /**
     * Attach slots to needed places
     * @param {Array<Element>} children
     */
    renderSlots(children) {
        if (!children.length) {
            return;
        }

        const container = this.getAdditionalContainer();
        [].forEach.call(children, (child) => container.appendChild(child));
    }

    /**
     * Render layout for common amount
     */
    renderLayout() {
        this.getValue();

        if (this.currency) {
            this.getCurrency();
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }

        switch (name) {
            case 'accuracy':
            case 'leading-plus':
            case 'value':
                this.getValue();
                break;
            case 'currency':
                this.getCurrency();
                break;
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const childrenForSlot = this.getChildrenForSlot('additional');
        this.renderLayout();
        if (childrenForSlot.length) {
            this.renderSlots(childrenForSlot);
        }
    }
}

UIAmount.defineElement('ui-amount', styles);
export { UIAmount };

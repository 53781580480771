import { UIElement } from '../ui-element.js';
import styles from './ui-awards.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAwards
 * @element ui-awards
 * @classdesc Represents a class for <code>ui-awards</code> element.
 * Container for ui-award. Can have only UIAward children.
 * @property {("fixed" | "stretched")} [layout="fixed"] {@attr layout} Layout for ui-awards:
 *   {@desc fixed: default layout, all ui-award elements has fixed size}
 *   {@desc stretched: all ui-award elements will fit the page in full width with fluid width}
 * @slot {@type "ui-award"}
 * @example
 * <ui-awards layout="stretched">
 *   <ui-award price="5400" label-price="Points" group-id="1" award-id="11"
 *      image="assets/mocks/images/award01.jpg"
 *   >
 *     <ui-badge color="turquoise">Business</ui-badge>
 *     <p>New image size: 600x600 px! Old ones will be scaled up.</p>
 *   </ui-award>
 *   <ui-award price="5" label-price="Points" group-id="2" award-id="22"
 *      image="assets/mocks/images/award02.jpg"
 *   >
 *     <ui-badge color="lilac">Campaign</ui-badge>
 *     <p>20% discount on the agreement fee of a business loan (of up to 100,000 euros)</p>
 *   </ui-award>
 *   <ui-award price="10" label-price="Points" group-id="3" award-id="33"
 *     image="assets/mocks/images/award03.jpg"
 *   >
 *     <ui-badge color="orange">Gold</ui-badge>
 *     <p>A 20% discount on Ampstükk raw energy bars</p>
 *   </ui-award>
 * </ui-awards>
 */
class UIAwards extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: 'fixed' },
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'list');
        this.querySelectorAll('ui-award').forEach((aw) => {
            aw.setAttribute('role', 'listitem');
        });
    }
}

UIAwards.defineElement('ui-awards', styles);
export { UIAwards };

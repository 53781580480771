import { UIElement } from '../ui-element.js';
import { isEnterPressed, TabIndex } from '../../global/keyboard.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-product.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIProduct
 * @element ui-product
 * @classdesc Represents a class for <code>ui-product</code> element.
 * Should be child of ui-products container.
 * @fires event:product-open
 * @property {string} [pageId] {@attr page-id} Page ID where it will be redirected after
 * click on product. Should be in iBank's format eg. 'private.home.more'.
 * @property {string} [externalUrl] {@attr external-url} URL where you will be redirected
 * after click.
 * @property {string} [labelLink] {@attr label-link} Label for link.
 * @property {string} [icon] {@attr icon} Product icon, insert correct glyph as in ui-icon.
 * @property {string} [label] {@attr label} Title of product.
 * @property {string} [tagline] {@attr tagline} Tagline of product.
 * @property {string} [link] {@attr link} {@readonly} Shortcut to first link in product.
 * @slot
 * @example
 * <ui-product pageId="private.home.more" title="Hi" icon="transport">
 *   <p>This is a cool product</p>
 * </ui-product>
 */
class UIProduct extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                pageId: { type: String, default: '' },
                externalUrl: { type: String, default: '' },
                labelLink: { type: String, default: '' },
                icon: { type: String, default: '' },
                label: { type: String, default: '' },
                tagline: { type: String, default: '' },
            },
            children: {
                link: 'a',
            },
        };
    }

    /**
     * There are several scenarios how product selection happeing.
     * 1. Has attribute page-id, then custom event with 'product-open' will be fired
     * with option 'page-id'.
     * 2. Has attribute external-url. Just opens the link in new window.
     * 3. Has link in markup then link from markup will be opened.
     * 4. Has more than one link, then they open separately on each click on them.
     * @param {KeyboardEvent | MouseEvent} event
     * @private
     */
    handleProductSelection(event) {
        if (event.type === 'keypress' && !isEnterPressed(event)) {
            return;
        }

        const params = {};
        if (this.link && this.link.getAttribute('href')) {
            params['external-url'] = this.link.getAttribute('href');
        }
        if (this.pageId) {
            params['page-id'] = this.pageId;
        }
        if (this.externalUrl) {
            params['external-url'] = this.externalUrl;
        }
        this.dispatchCustomEvent('product-open', params);
    }

    /**
     * Checks if the offer has badge inside.
     * @returns {boolean}
     * @private
     */
    hasBadge() {
        return !!this.querySelector('ui-badge');
    }

    /**
     * @inheritDoc
     */
    render() {
        this.tabIndex = TabIndex.Active;
        this.setAttribute('role', 'link');
        const firstChild = this.firstChild;
        [
            this.icon && {
                tagName: 'ui-icon',
                attributes: {
                    size: 'large',
                    color: UIIcon.colors.PINEAPPLE,
                    glyph: this.icon,
                },
            },
            this.label && {
                tagName: 'h3',
                children: [this.label],
            },
            this.tagline && {
                tagName: 'p',
                classList: {
                    highlight: true,
                    'ui-product__tagline': true,
                },
                children: [this.tagline],
            },
        ].forEach((config) => {
            if (!config) {
                return;
            }
            const element = this.createElement(config);
            if (firstChild) {
                this.insertBefore(element, firstChild);
            } else {
                this.appendChild(element);
            }
        });

        if (this.hasBadge()) {
            this.classList.add('-has-badge');
        }

        if (this.labelLink || this.pageId || this.externalUrl) {
            this.insertElements([
                {
                    tagName: 'a',
                    attributes: {
                        tabindex: TabIndex.Inactive,
                        rel: 'nofollow',
                    },
                    classList: {
                        'default-link': true,
                        '-faked': true,
                    },
                    children: [this.labelLink],
                },
            ]);
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.addEventListener('click', this.handleProductSelection.bind(this));
        this.addEventListener(
            'keypress',
            this.handleProductSelection.bind(this)
        );
    }
}

UIProduct.defineElement('ui-product', styles);
export { UIProduct };

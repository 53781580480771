import { UIElement } from '../ui-element.js';
import styles from './ui-grid.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIGrid
 * @element ui-grid
 * @classdesc Represents a class for <code>ui-grid</code> element.
 * This element is done for styling grid layout.
 * @property {string} [colwidth="25%"] {@attr colwidth} Columns width in percent.
 * @property {string} [halign="center"] {@attr halign} Columns horizontal alignment.
 * @slot {@type "ui-griditem"}
 * @example
 * <ui-grid colwidth="25%">
 * </ui-grid>
 */
class UIGrid extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                colwidth: { type: String, default: '25%' },
                halign: { type: String, default: 'center' },
            },
        };
    }

    /**
     * Update grid with needed styling.
     */
    styleGridView() {
        const children = this.querySelectorAll('ui-griditem');
        this.updateElement({
            classList: {
                '-singleview': children.length === 1,
            },
        });
    }

    /**
     * Render grid layout.
     */
    renderGridLayout() {
        const children = this.detachChildNodes().filter(function (node) {
            return !!node.tagName;
        });

        this.updateElement({
            children: [
                {
                    tagName: 'ui-columns',
                    attributes: {
                        wrap: '',
                        colwidth: this.colwidth,
                        halign: this.halign,
                    },
                    children: children,
                },
            ],
        });
    }

    /**
     * @inheritDoc
     */
    handleControlMutations() {
        this.styleGridView();
    }

    /**
     * @inheritDoc
     */
    render() {
        this.styleGridView();
        this.renderGridLayout();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.observer = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.observer.observe(this.querySelector('ui-columns'), {
            childList: true,
        });
    }
}

UIGrid.defineElement('ui-grid', styles);
export { UIGrid };

import '../modal/ui-modal-controller.js';
import '../option/ui-option-account.js';
import '../option/ui-option-basic.js';
import '../option/ui-option-color.js';
import '../option/ui-option-dial.js';
import '../option/ui-option-icon.js';
import '../option/ui-option-lang.js';
import '../option/ui-option-multipayment.js';
import '../option/ui-option-tab.js';
import '../option/ui-option-plain.js';
import { UIElement } from '../ui-element.js';
import { keyCodes } from '../../global/keyboard.js';
import { Digest } from '../../global/digest.js';
import { Labels } from '../../global/labels.js';
import {
    makePopoverHandler,
    debounce,
    appendModalControllerIfRequired,
} from '../../global/helpers.js';
import {
    addSyntheticFocusVisible,
    buildSyntheticFocusControl,
    closestByCond,
    dispatchNativeEvent,
    hide,
    insertElements,
    prerenderElementsTree,
    rebuild,
    show,
    updateClassList,
} from '../../global/ui-helpers.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-dropdown.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIDropdown
 * @element ui-dropdown
 * @classdesc Represents a class for <code>ui-dropdown</code> element.
 * Create custom select dropdown with different types.
 * @property {("account" | "basic" | "color" | "dial" | "icon"
 * | "lang" | "multipayment" | "plain" | "tab")} [layout="basic"] {@attr layout}
 * Type of the option.
 *  {@desc account}
 *  {@desc basic}
 *  {@desc color}
 *  {@desc dial}
 *  {@desc icon}
 *  {@desc lang}
 *  {@desc multipayment}
 *  {@desc plain}
 *  {@desc tab: layout for tabs (internal usage only)}
 * @property {("basic" | "balloon" | "plain" | "tabs")} type {@attr type} Type of the dropdown.
 *  {@desc basic}
 *  {@desc balloon}
 *  {@desc plain}
 *  {@desc tabs: only for internal usage}
 * @property {boolean} [mobileNative] {@attr mobile-native} If true on mobile
 * it will turn to native select.
 * @property {boolean} [hideSelected] {@attr hide-selected} If true,
 * selected option will be hidden in dropdown.
 * @property {boolean} [expanded] {@attr expanded} If true, dropdown will be expanded.
 * @property {string} [titleClose] {@attr title-close} Title of close button.
 * @property {string} [titleOpen] {@attr title-open} Title of open button.
 * @property {number} [maxlength] {@attr maxlength} Maximum length of options to show.
 * @property {string} [glyphColor] {@attr glyph-color} Color of glyph for lang-layout.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {number} popoverHOffset {@readonly} Popover horizontal offset.
 * @property {number} popoverVOffset {@readonly} Popover vertical offset.
 * @property {HTMLButtonElement} closePopoverBtn {@readonly} Shortcut to popover
 * close button element.
 * @property {HTMLButtonElement} control {@readonly} Shortcut to control element.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @property {HTMLSelectElement} nativeSelect {@readonly} Shortcut to select element.
 * @property {HTMLDivElement} customSelect {@readonly} Shortcut to custom select element.
 * @property {HTMLDivElement} popoverElement {@readonly} Shortcut to popover element.
 * @property {HTMLDivElement} options {@readonly} Shortcut to options element.
 * @classdesc Represents a class for <code>ui-dropdown</code> element
 * @slot
 * @example
 * <ui-dropdown title-open="Open" title-close="Close">
 *  <select>
 *    <option>First</option>
 *    <option>Second</option>
 *  </select>
 * </ui-dropdown>
 */
class UIDropdown extends UIElement {
    static get POPOVER_V_OFFSET() {
        return 0;
    }

    static get POPOVER_H_OFFSET() {
        return 0;
    }

    static POPOVER_WIDTH() {
        return 0;
    }

    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['expanded', 'option', 'control-id'];
    }

    /**
     * Layouts of dropdown.
     * @type {{ACCOUNT: string, BASIC: string, COLOR: string, DIAL: string, ICON: string,
     * LANG: string, MULTIPAYMENT: string, PLAIN: string, TAB: string}}
     */
    static get layouts() {
        return {
            ACCOUNT: 'account',
            BASIC: 'basic',
            COLOR: 'color',
            DIAL: 'dial',
            ICON: 'icon',
            LANG: 'lang',
            MULTIPAYMENT: 'multipayment',
            PLAIN: 'plain',
            TAB: 'tab',
        };
    }

    /**
     * Types of dropdown.
     * @type {{PLAIN: string, BALLOON: string, TABS: string, BASIC: string}}
     */
    static get types() {
        return {
            PLAIN: 'plain',
            BASIC: 'basic',
            BALLOON: 'balloon',
            TABS: 'tabs',
        };
    }

    /**
     * Provides getter and setter for "value" property
     * and link it to the "value" attribute
     * syncs with nativeSelect.value
     */
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: 'basic' },
                mobileNative: Boolean,
                hideSelected: Boolean,
                expanded: Boolean,
                titleClose: String,
                titleOpen: String,
                maxlength: Number,
                glyphColor: String,
                controlId: String,
            },
            children: {
                control: '.ui-dropdown__control',
                focusTrigger: '.ui-dropdown__focus-trigger',
                options: '.ui-dropdown__options',
                nativeSelect: '.ui-dropdown__select',
                customSelect: '.ui-dropdown__custom',
                closePopoverBtn: '.ui-dropdown__popover-close',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-dropdown', {
            close: 'Close',
        });
    }

    get uid() {
        if (!this._id) {
            this._id = Digest.randomId();
        }
        return this._id;
    }

    get value() {
        return this.nativeSelect.value;
    }
    set value(value) {
        const select = this.nativeSelect;
        if (select.value === value) {
            return;
        }
        select.value = value;
        this.onChangeValue();
    }

    get selectedIndex() {
        return this.nativeSelect.selectedIndex;
    }
    set selectedIndex(value) {
        const select = this.nativeSelect;
        if (
            select.selectedIndex !== value &&
            value > -1 &&
            value < select.options.length
        ) {
            select.selectedIndex = value;
            this.onChangeValue();
        }
    }

    get popoverElement() {
        if (this._detachedPopover) {
            return this._detachedPopover;
        }
        return this.querySelector('.ui-dropdown__popover');
    }

    get option() {
        return 'ui-option-' + this.layout;
    }
    set option(value) {
        if (!value || value.indexOf('ui-option-') !== 0) {
            this.layout = null;
        }
        this.layout = value.replace('ui-option-', '');
    }

    get type() {
        const type = this.getAttribute('type');
        const hasValue = Object.keys(UIDropdown.types).some(function (key) {
            return UIDropdown.types[key] === type;
        });
        if (hasValue) {
            return type;
        }
        this.removeAttribute('type');
        const elementFactory = customElements.get(this.option);
        if (!elementFactory) {
            return null;
        }
        return elementFactory.POPOVER_TYPE;
    }

    get readonly() {
        return this.nativeSelect.hasAttribute('readonly');
    }

    get popoverHOffset() {
        const elementFactory = customElements.get(this.option);
        return (
            (elementFactory && elementFactory.POPOVER_H_OFFSET) ||
            UIDropdown.POPOVER_H_OFFSET
        );
    }

    get popoverVOffset() {
        const elementFactory = customElements.get(this.option);
        return (
            (elementFactory && elementFactory.POPOVER_V_OFFSET) ||
            UIDropdown.POPOVER_V_OFFSET
        );
    }

    get inputValue() {
        return this._inputValue || '';
    }
    set inputValue(value) {
        this._inputValue = value;
        this.clearInputValueDebounce();
    }

    /**
     * Check if mobile mode enabled.
     * @returns {boolean}
     */
    isMobile() {
        return this.mobileMediaQueryList
            ? this.mobileMediaQueryList.matches
            : false;
    }

    /**
     *  Returns all options.
     *  @returns {UIElement|NodeListOf<UIElement>|undefined}
     *  @param {number} [index]
     */
    getOptions(index) {
        const options = this.popoverElement.querySelectorAll(
            '.ui-dropdown__option:not(.-hidden)'
        );
        return index > -1 ? options[index] : options;
    }

    /**
     * Returns active option. If active option is hidden then undefined.
     * @returns {HTMLLIElement|undefined}
     */
    getActiveOption() {
        return [].filter.call(this.getOptions(), (opt) =>
            opt.classList.contains('-selected')
        )[0];
    }

    /**
     * Returns focused option, if no focused options then undefined.
     * @returns {HTMLLIElement|undefined}
     */
    getFocusedOption() {
        return [].filter.call(
            this.getOptions(),
            (opt) => opt === document.activeElement.parentElement
        )[0];
    }

    /**
     * Get popover height in pixels.
     * @returns {number}
     */
    getPopoverHeight() {
        let height = 0;
        const options = this.popoverElement.querySelectorAll(
            '.ui-dropdown__option:not(.-hidden), [optgroup]'
        );
        const limit = this.maxlength
            ? Math.min(this.maxlength, options.length)
            : options.length;

        for (let i = 0; i < limit; i++) {
            if (window.innerHeight - 10 < height + options[i].clientHeight) {
                break;
            }
            height = height + options[i].clientHeight + (i + 1 < limit ? 1 : 0);
        }
        return height > 0 ? height : 0;
    }

    /**
     * Sets popover height in pixels.
     * @param {number} value
     */
    setPopoverHeight(value) {
        this.popoverElement.querySelector(
            '.ui-dropdown__options'
        ).style.maxHeight = value > 0 ? value + 'px' : null;
    }

    /**
     * Sets popover width in pixels.
     * @param {number} value
     */
    setPopoverWidth(value) {
        this.popoverElement.style.width = value > 0 ? value + 'px' : null;
    }

    /**
     * Opens detached popover.
     * @fires event:modal-open
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }
        this._detachedPopover = this.popoverElement;
        this.dispatchCustomEvent('modal-open', {
            type: 'popover',
            content: this.popoverElement,
            params: {
                classList: {
                    '-dropdown': true,
                    '-balloon': this.type === UIDropdown.types.BALLOON,
                    '-basic': this.type === UIDropdown.types.BASIC,
                    '-plain': this.type === UIDropdown.types.PLAIN,
                    '-tabs': this.type === UIDropdown.types.TABS,
                    '-dial': this.layout === UIDropdown.layouts.DIAL,
                },
                position: makePopoverHandler(this.control, {
                    popoverHeight: () => {
                        const h = this.getPopoverHeight();
                        this.setPopoverHeight(h);
                        if (
                            [
                                UIDropdown.types.PLAIN,
                                UIDropdown.types.BALLOON,
                            ].indexOf(this.type) === -1
                        ) {
                            this.setPopoverWidth(this.offsetWidth);
                        }
                        return this.popoverElement.offsetHeight;
                    },
                    verticalOffset: this.popoverVOffset,
                    popoverWidth: UIDropdown.POPOVER_WIDTH,
                    horizontalOffset: this.popoverHOffset,
                    supportMiddleAlignment: this.canBeMiddleAligned(),
                }),
                target: this,
                onOpen: () => {
                    const options = this.popoverElement.querySelector(
                        '.ui-dropdown__options'
                    );
                    const selected = this.getActiveOption();
                    const button = selected
                        ? selected.querySelector('button')
                        : options.querySelector(
                              '.ui-dropdown__option:not(.-hidden) button'
                          );

                    // Looks like we encounter same or similar Apple VO bug. This is because
                    // we use requestAnimationFrame() here.
                    // https://bugs.webkit.org/show_bug.cgi?id=167671
                    requestAnimationFrame(() => button.focus());
                },
                onClose: () => (this.expanded = false),
            },
        });
    }

    /**
     * @private
     * @returns {boolean}
     */
    canBeMiddleAligned() {
        return this.constructor.types.BALLOON !== this.type;
    }

    /**
     * Closes detached popover.
     */
    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        const modal = closestByCond(this._detachedPopover, (node) => {
            return node && node.tagName.toLowerCase() === 'ui-modal';
        });
        if (modal) {
            this._detachedPopover = null;
            modal.close();
        }
    }

    /**
     * Clears current input value
     */
    clearInputValue() {
        this._inputValue = '';
    }

    /**
     * Updates dropdown control when selected item is changed.
     * @returns {UIElement|NodeListOf<UIElement>|undefined}
     */
    updateSelected() {
        const selectedLi = this.popoverElement.querySelector('.-selected');
        if (selectedLi) {
            selectedLi.removeAttribute('aria-selected');
            updateClassList(selectedLi, {
                '-selected': false,
                '-hidden': false,
            });
        }
        const option = this.getOptions(this.selectedIndex);
        if (option) {
            updateClassList(option, {
                '-selected': true,
                '-hidden': this.hideSelected,
            });
            option.setAttribute('aria-selected', 'true');
        }
        return option;
    }

    /**
     * Close popover element.
     */
    closePopover() {
        this.removeAttribute('popover-align');
        this.expanded = false;
    }

    /**
     * Toggle popover status - show / hide.
     * @private
     */
    togglePopover() {
        if (this.readonly || this.disabled) {
            this.expanded = false;
        } else {
            this.expanded = !this.expanded;
        }
    }

    /**
     * Fires callback when the dropdown value has been changed.
     * @private
     */
    onChangeValue() {
        const control = this.control;
        if (!control) {
            return;
        }

        /**
         * @type {HTMLSelectElement}
         */
        const nativeSelect = this.nativeSelect;
        const selectedOption =
            nativeSelect.options[nativeSelect.options.selectedIndex];

        if (selectedOption) {
            control.innerHTML = '';
            const newItem = this.createElement(
                this.renderOption(selectedOption, true)
            );
            control.appendChild(newItem);
            this.updateSelected();
            this.selectedIndex = nativeSelect.selectedIndex;
        }

        this.closePopover();
    }

    /**
     * Fires callback when the popover state has been changed
     * @private
     */
    onChangePopoverState() {
        if (this.isMobile() && this.mobileNative) {
            return;
        }
        const isActive = this.expanded;
        const popover = this.popoverElement;
        const control = this.control;
        if (!isActive && control) {
            control.removeAttribute('popover-align');
        }
        if (!popover || !control) {
            return;
        }

        control.setAttribute('aria-expanded', String(!!isActive));

        if (isActive) {
            show(popover);
            this.setPopoverHeight(this.getPopoverHeight());
            this.openDetachedPopover();
            document.addEventListener('click', this.handleClickOutside);
        } else {
            if (this._detachedPopover) {
                this.closeDetachedPopover();
            }
            hide(popover);
            document.removeEventListener('click', this.handleClickOutside);
        }

        this.expanded = isActive;
    }

    /**
     * Fires callback when changed between mobile and desktop
     * @private
     */
    onChangeAppearance() {
        if (this.isMobile()) {
            hide(this.customSelect);
            show(this.nativeSelect);
        } else {
            show(this.customSelect);
            hide(this.nativeSelect);
            this.onChangePopoverState();
        }
    }

    /**
     * Fires callback when option renderer type is changed
     * @private
     */
    onChangeOptionRenderer() {
        // TODO: implement
        const nativeSelect = this.nativeSelect;
        const control = this.control;
        const popover = this.popoverElement;

        if (!nativeSelect || !control || !popover) {
            return;
        }
        const selectedOption =
            nativeSelect.querySelectorAll('option')[nativeSelect.selectedIndex];

        if (selectedOption) {
            control.innerHTML = '';
            const newItem = this.createElement(
                this.renderOption(selectedOption, true)
            );
            control.appendChild(newItem);
        }

        const optionsList = popover.querySelector('.ui-dropdown__options');
        optionsList.innerHTML = '';

        insertElements(
            optionsList,
            [].map.call(
                nativeSelect.querySelectorAll('option'),
                (option, i) => {
                    return {
                        tagName: 'ui-option',
                        classList: {
                            'ui-dropdown__option': true,
                            '-selected': i === this.selectedIndex,
                            '-hidden':
                                this.hideSelected && i === this.selectedIndex,
                        },
                        attributes: {
                            'aria-selected':
                                i === this.selectedIndex ? true : null,
                        },
                        children: [
                            {
                                tagName: 'button',
                                attributes: { type: 'button' },
                                children: [this.renderOption(option, false)],
                            },
                        ],
                    };
                }
            )
        );
        this.querySelectorAll('.ui-dropdown__option').forEach((opt) => {
            opt.addEventListener('click', this.handleClickOnOption.bind(this));
        });
    }

    /**
     * Fires callback when the native select's value has been changed.
     * @private
     */
    handleNativeSelectChange() {
        this.onChangeValue();
    }

    /**
     * Fires callback when the select's parent form has been reset.
     * @private
     */
    handleFormReset() {
        const selectedOption = [...this.nativeSelect.options]
            .filter((node) => node.defaultSelected)
            .shift();

        if (selectedOption) {
            this.nativeSelect.value = selectedOption.value;
        } else {
            this.nativeSelect.selectedIndex = 0;
        }
        this.onChangeValue();
    }

    /**
     * Fires callback when user clicked outside of dropdown element.
     * @param {MouseEvent} event
     * @private
     */
    handleClickOutside(event) {
        if (
            event.target.hasOwnProperty('hasAttribute') &&
            (event.target.hasAttribute('optgroup') ||
                event.target.parentElement.hasAttribute('optgroup'))
        ) {
            return;
        }
        if (this.expanded && !this.isParentOf(event.target)) {
            this.closePopover();
        }
    }

    /**
     * Fires callback when user clicked on option
     * @param {MouseEvent} event
     * @private
     */
    handleClickOnOption(event) {
        /** @type {HTMLAnchorElement} */
        const link = event.target.parentNode.querySelector('a');
        if (link) {
            const href = link.href;
            const target = link.target;
            if (href) {
                target
                    ? window.open(href, target)
                    : (window.location.href = href);
                return;
            }
        }
        event.preventDefault();
        const opts = event.target
            .closest('.ui-dropdown__options')
            .querySelectorAll('.ui-dropdown__option');
        const targetOption = event.target.closest(
            '.ui-dropdown__option, .ui-dropdown__group'
        );
        const disallowToClick = ['-disabled', 'ui-dropdown__group'];
        if (
            disallowToClick.some((cls) => targetOption.classList.contains(cls))
        ) {
            return;
        }
        this.nativeSelect.selectedIndex = [].indexOf.call(opts, targetOption);
        dispatchNativeEvent(this.nativeSelect, 'change');
        this.closePopover();
        this.control.focus();
    }

    /**
     * Fires callback when user presses tab, and up&down arrows.
     * @param {KeyboardEvent} event
     * @private
     */
    handleKeyDown(event) {
        if (!this.expanded) {
            return;
        }
        if (keyCodes.TAB === event.keyCode) {
            this.control.focus();
            this.closePopover();
        }

        if (keyCodes.ESCAPE === event.keyCode) {
            this.control.focus();
        }

        const options = [...this.getOptions()];
        if (keyCodes.UP === event.keyCode || keyCodes.DOWN === event.keyCode) {
            const step = keyCodes.UP === event.keyCode ? -1 : 1;
            const currentIndex = options.indexOf(
                document.activeElement.parentElement
            );
            let nextIndex = currentIndex + step;
            if (nextIndex < 0) {
                nextIndex = nextIndex + options.length;
            }
            if (nextIndex > options.length - 1) {
                nextIndex = nextIndex - options.length;
            }

            while (options[nextIndex].classList.contains('-disabled')) {
                if (keyCodes.UP === event.keyCode) {
                    nextIndex--;
                }
                if (
                    keyCodes.DOWN === event.keyCode &&
                    ++nextIndex > options.length - 1
                ) {
                    nextIndex = 0;
                }
            }

            if (options[nextIndex]) {
                options[nextIndex].querySelector('button').focus();
            }
            event.preventDefault();
        }

        const inputKey = event.key;
        // match all except whitespace
        if (inputKey.length === 1 && /\S/i.test(inputKey)) {
            this.inputValue = this.inputValue + inputKey;
            const inputValue = this.inputValue;
            const nextOption = options.find(function (option) {
                const results = [...inputValue].map((char, index) => {
                    return (
                        option.innerText.charAt(index).toLowerCase() ===
                        char.toLowerCase()
                    );
                });
                return results.every(Boolean);
            });
            if (nextOption) {
                nextOption.querySelector('button').focus();
            }

            event.preventDefault();
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'expanded':
                this.onChangePopoverState();
                break;
            case 'option':
                this.onChangeOptionRenderer();
                break;
            case 'control-id':
                if (this.focusTrigger) {
                    if (newValue) {
                        this.focusTrigger.setAttribute('id', newValue);
                    } else {
                        this.focusTrigger.removeAttribute('id');
                    }
                }
                break;
        }
    }

    /**
     * Updates dropdown.
     * @fires event:popover-updated
     */
    updateDropdown() {
        const select = this.querySelector('select');
        const options = [...select.children];

        const newOptions = this.createElement(
            this.renderOptionsWrapper(options)
        );
        const oldOptions = this.popoverElement.querySelector(
            '.ui-dropdown__options'
        );
        prerenderElementsTree(oldOptions, true);
        rebuild(oldOptions, newOptions);

        const selectedIndex =
            select.options.selectedIndex > -1
                ? select.options.selectedIndex
                : 0;

        const newControl = this.createElement(
            this.renderOption(
                select.options[selectedIndex] ||
                    document.createElement('option'),
                true
            )
        );
        const oldControl = this.control.firstElementChild;
        prerenderElementsTree(oldControl, true);
        rebuild(oldControl, newControl);
        this.dispatchCustomEvent('popover-updated');
    }

    /**
     * Renders wrapper for options.
     * @param {Array<HTMLOptionElement|HTMLOptGroupElement>} options
     * @returns {object}
     */
    renderOptionsWrapper(options) {
        return {
            tagName: 'ul',
            attributes: {
                id: 'ui-dropdown-' + this.uid,
                role: 'listbox',
            },
            classList: {
                'ui-dropdown__options': true,
            },
            children: options.map((option) => {
                if (option instanceof HTMLOptGroupElement) {
                    return this.renderOptionGroup(option);
                }
                return this.renderOptionWrapper(option);
            }),
        };
    }

    /**
     * Renders wrapper for one option.
     * @param {HTMLOptGroupElement} group
     * @returns {IElementConfig}
     */
    renderOptionGroup(group) {
        return {
            tagName: 'li',
            classList: {
                'ui-dropdown__group': true,
            },
            children: [
                this.renderOption(group, false),
                {
                    tagName: 'ul',
                    attributes: {
                        role: 'listbox',
                    },
                    classList: {
                        'ui-dropdown__group-options': true,
                    },
                    children: [...group.children].map((node) =>
                        this.renderOptionWrapper(node)
                    ),
                },
            ],
        };
    }

    /**
     * Renders wrapper for one option.
     * @param {HTMLOptionElement} option
     * @returns {IElementConfig}
     */
    renderOptionWrapper(option) {
        return {
            tagName: 'li',
            classList: {
                'ui-dropdown__option': true,
                '-selected': option.selected,
                '-disabled': option.disabled,
                '-hidden': this.hideSelected && option.selected,
            },
            attributes: {
                'aria-selected': option.selected ? 'true' : null,
            },
            children: [
                {
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                        role: 'option',
                    },
                    children: [
                        this.renderOption(option, false),
                        this.layout === UIDropdown.layouts.PLAIN &&
                            !this.hideSelected && {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'agree',
                                    color: UIIcon.colors.ORANGE,
                                },
                            },
                    ],
                },
            ],
        };
    }

    /**
     * Renders one option.
     * @param {HTMLOptionElement | HTMLOptGroupElement} option
     * @param {boolean} control
     * @returns {IElementConfig}
     */
    renderOption(option, control) {
        const isGroup = option.tagName.toLowerCase() === 'optgroup';
        const attributes = {
            optgroup: isGroup ? true : null,
        };

        if (!option) {
            return {
                tagName: this.option,
                attributes: attributes,
            };
        }

        for (const key in option.dataset) {
            if (option.dataset.hasOwnProperty(key)) {
                attributes[key] = option.dataset[key];
            }
        }

        if (isGroup) {
            attributes.text = option.getAttribute('label');
        } else {
            if (option.hasAttribute('value')) {
                attributes.value = option.value;
            }

            if (option.innerText !== '') {
                attributes.text = option.innerText;
            }
        }

        if (this.layout === UIDropdown.layouts.LANG) {
            attributes.color = this.glyphColor || null;
        }

        attributes.control = control;
        return {
            tagName: this.option,
            attributes: attributes,
        };
    }

    /**
     * @inheritDoc
     */
    handleControlMutations(mutationRecord) {
        for (let i = 0; i < mutationRecord.length; ++i) {
            if (mutationRecord[i].attributeName === 'disabled') {
                this.disabled = mutationRecord[i].target.disabled;
                this.control.disabled = mutationRecord[i].target.disabled;
                if (this.disabled) {
                    this.expanded = false;
                    this.classList.add('-disabled');
                } else {
                    this.classList.remove('-disabled');
                }
            }
            if (mutationRecord[i].attributeName === 'readonly') {
                if (this.readonly) {
                    this.expanded = false;
                    this.classList.add('-readonly');
                } else {
                    this.classList.remove('-readonly');
                }
            }
        }
        this.updateDropdown();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.closeDetachedPopover();
    }

    /**
     * @inheritDoc
     */
    render() {
        const node = this.detachChildNodes()
            .filter(
                (node) =>
                    node.tagName && node.tagName.toLowerCase() === 'select'
            )
            .shift();

        /** @type {HTMLSelectElement}*/
        const select =
            node ||
            this.createElement({
                tagName: 'select',
                children: [
                    {
                        tagName: 'option',
                    },
                ],
            });

        const options = [...select.options];
        const optionNodes = [...select.children];
        const index = select.options.selectedIndex;

        this.insertElements([
            {
                element: select,
                classList: {
                    'ui-dropdown__select': true,
                },
                attributes: {
                    // 'aria-labelledby': this.controlId,
                    // #pally
                    'aria-label': 'virtual',
                },
            },
            {
                tagName: 'div',
                classList: {
                    'ui-dropdown__custom': true,
                },
                children: [
                    buildSyntheticFocusControl(this.controlId, 'ui-dropdown'),
                    {
                        tagName: 'button',
                        attributes: {
                            type: 'button',
                            class: 'ui-dropdown__control',
                            'aria-haspopup': 'listbox',
                            'aria-controls': 'ui-dropdown-' + this.uid,
                            'aria-labelledby': this.controlId,
                        },
                        children: [
                            this.renderOption(
                                options[index] ||
                                    document.createElement('option'),
                                true
                            ),
                        ],
                    },
                    {
                        tagName: 'div',
                        classList: {
                            'ui-dropdown__popover': true,
                            '-plain-layout': [
                                UIDropdown.layouts.PLAIN,
                                UIDropdown.layouts.DIAL,
                            ].includes(this.layout),
                        },
                        children: [
                            this.renderOptionsWrapper(optionNodes),
                            ![
                                UIDropdown.layouts.PLAIN,
                                UIDropdown.layouts.DIAL,
                            ].includes(this.layout) && {
                                tagName: 'button',
                                attributes: {
                                    type: 'button',
                                    'aria-label':
                                        this.titleClose ||
                                        UIDropdown.labels.close,
                                },
                                classList: {
                                    'ui-dropdown__popover-close': true,
                                    '-iconed': true,
                                },
                                children: [
                                    {
                                        tagName: 'ui-icon',
                                        attributes: {
                                            glyph: 'cross',
                                        },
                                    },
                                ],
                            },
                            {
                                tagName: 'div',
                                classList: {
                                    'ui-dropdown__pointer': true,
                                },
                            },
                        ],
                    },
                ],
            },
        ]);

        hide(select);
        hide(this.popoverElement);

        this.updateClassList({
            '-balloon': this.type === UIDropdown.types.BALLOON,
            '-basic': this.type === UIDropdown.types.BASIC,
            '-plain': this.type === UIDropdown.types.PLAIN,
            '-tabs': this.type === UIDropdown.types.TABS,
        });

        const disabled = this.nativeSelect.disabled;
        if (disabled) {
            this.classList.add('-disabled');
            this.control.disabled = true;
        }

        if (this.readonly) {
            this.classList.add('-readonly');
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        appendModalControllerIfRequired();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.options.addEventListener(
            'click',
            this.handleClickOnOption.bind(this)
        );
        this.nativeSelect.addEventListener(
            'change',
            this.handleNativeSelectChange.bind(this)
        );
        this.customSelect.addEventListener(
            'keydown',
            this.handleKeyDown.bind(this)
        );
        this.options.addEventListener('keydown', this.handleKeyDown.bind(this));
        this.control.addEventListener('click', this.togglePopover.bind(this));
        this.focusTrigger.addEventListener('click', (e) =>
            addSyntheticFocusVisible(this.control)
        );
        if (this.closePopoverBtn) {
            this.closePopoverBtn.addEventListener('click', (e) => {
                e.preventDefault();
                this.closePopover();
            });
        }

        if (this.mobileNative) {
            this.mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
            this.mobileMediaQueryList.addListener(() =>
                this.onChangeAppearance()
            );
            this.onChangeAppearance();
        } else {
            hide(this.nativeSelect);
        }
        if (this.nativeSelect.form) {
            this.nativeSelect.form.addEventListener(
                'reset',
                this.handleFormReset.bind(this)
            );
        }

        this.observer = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.observer.observe(this.nativeSelect, {
            childList: true,
            subtree: true,
            attributes: true,
            characterData: true,
        });
        this.updateDropdown();

        this.clearInputValueDebounce = debounce(this.clearInputValue, 500);
    }
}

UIDropdown.defineElement('ui-dropdown', styles);
export { UIDropdown };

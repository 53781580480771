import {
    createElement,
    prerenderElementsTree,
    rebuildChildren,
} from '../../global/ui-helpers.js';
import { UIElement } from '../ui-element.js';

/**
 * @memberof SharedComponents
 * @abstract
 * @augments {UIElement}
 * @alias UIDynamicTable
 * @classdesc Represents a base abstract class for dynamic tables
 * @property {string} sortOrder {@attr sort-order}
 * @property {("asc"|"desc")} [sortDir="desc"] {@attr sort-dir}
 * @property {number} [limit] {@attr limit}
 * @property {string} [labelEmpty] {@attr empty-label}
 * @property {UITable} uiTable {@readonly} Table element
 * @property {HTMLTableSectionElement} tbody {@readonly} Table body
 * @property {Array<HTMLTableRowElement>} rows {@readonly} Array of table rows
 */
class UIDynamicTable extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                sortOrder: String,
                sortDir: {
                    type: String,
                    default: 'desc',
                },
                limit: Number,
                labelEmpty: String,
            },
            children: {
                uiTable: 'ui-table',
                tbody: 'ui-table tbody',
                rows: 'ui-table tbody tr',
            },
        };
    }

    /**
     * Return a unique key for given record.
     * The method to be overridden for descendants classes.
     * @param {object} record
     * @returns {string}
     */
    getUniqueKeyOf(record) {
        return record.id;
    }

    /**
     * Build a comparison predicate for sorting.
     * The method to be overridden for descendants classes
     * @param {string} [key]
     * @protected
     * @returns {Function | null | boolean}
     */
    buildCompareFn(key) {
        return false;
    }

    /**
     * Build a rendering config for ui-table.
     * The method to be overridden for descendants classes
     * @protected
     * @returns {IElementConfig}
     */
    buildTable() {
        return {
            tagName: 'ui-table',
            attributes: {
                plugins: 'sortable invert',
            },
            children: [
                {
                    tagName: 'table',
                    children: [
                        {
                            tagName: 'thead',
                            children: [],
                        },
                        {
                            tagName: 'tbody',
                            children: [],
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Build a rendering config for table row.
     * The method to be overridden for descendants classes
     * @protected
     * @param {object} record
     * @returns {IElementConfig}
     */
    buildTableRow(record) {
        return {
            tagName: 'tr',
            attributes: {},
            children: [],
        };
    }

    /**
     * Inserts records to the table. Render related table rows for each record.
     * @param {Array<object>} records
     * @returns {UIDynamicTable}
     */
    setRecords(records) {
        this._records = records;
        if (!records.length) {
            this.onEmptyResults();
            return this;
        }

        const entries = records.slice(0);
        const comparator = this.buildCompareFn();
        if (typeof comparator === 'function') {
            entries.sort(comparator);
        }

        rebuildChildren(
            this.tbody,
            entries
                .slice(0, this.limit > 0 ? this.limit : records.length)
                .map(this.renderTableRow.bind(this))
        );

        /** @type {UITable} */
        const uiTable = this.querySelector('ui-table');
        uiTable.runLifecycleHook('prepareLayout');
        return this;
    }

    /**
     * Handle case when no records presented
     */
    onEmptyResults() {
        this.rebuildChildren.call(
            this.querySelector('tbody'),
            !this.labelEmpty
                ? []
                : [
                      {
                          tagName: 'tr',
                          children: [
                              {
                                  tagName: 'td',
                                  attributes: {
                                      colspan: String(
                                          this.querySelectorAll('thead th')
                                              .length
                                      ),
                                  },
                                  children: [
                                      {
                                          tagName: 'ui-placement',
                                          attributes: {
                                              align: 'center',
                                          },
                                          children: [
                                              {
                                                  tagName: 'strong',
                                                  children: [this.labelEmpty],
                                              },
                                          ],
                                      },
                                  ],
                              },
                          ],
                      },
                  ]
        );
    }

    /**
     * Update the table row for given record from table with same unique key
     * @param {object} record
     */
    updateRecord(record) {
        const targetTr = this.querySelector(
            'tr[data-key="' + this.getUniqueKeyOf(record) + '"]'
        );
        if (targetTr) {
            targetTr.replaceWith(this.renderTableRow(record));
        }
    }

    /**
     * Remove the given record from table if a table row with same unique key is exist
     * @param {object} record
     */
    removeRecord(record) {
        const targetTr = this.querySelector(
            'tr[data-key="' + this.getUniqueKeyOf(record) + '"]'
        );
        if (targetTr) {
            targetTr.parentElement.removeChild(targetTr);
        }
    }

    /**
     * Add a new record if the table does not have a table row with the same unique key
     * @param {object} record
     */
    addRecord(record) {
        const targetTr = this.querySelector('tr[data-key="' + record.id + '"]');
        if (!targetTr) {
            this.tbody.insertRow(0).replaceWith(this.renderTableRow(record));
        }
    }

    /**
     * Render a table row and applies the modifyRow method from connected plugins to the row
     * @param {object} record
     * @returns {HTMLTableRowElement}
     */
    renderTableRow(record) {
        const rowData = this.buildTableRow(record);
        const row = rowData instanceof Node ? rowData : createElement(rowData);
        const plugins = this.getInstancePlugins();

        Object.keys(plugins).forEach(function (key) {
            if (typeof plugins[key].modifyRow === 'function') {
                plugins[key].modifyRow(row, record);
            }
        });
        prerenderElementsTree(row, true);
        return row;
    }

    /**
     * Render a table and applies the modifyTable method from connected plugins to the table
     * @returns {UITable}
     */
    renderTable() {
        const tableData = this.buildTable();
        const table =
            tableData instanceof Element ? tableData : createElement(tableData);
        const plugins = this.getInstancePlugins();
        Object.keys(plugins).forEach(function (key) {
            if (typeof plugins[key].modifyTable === 'function') {
                plugins[key].modifyTable(table);
            }
        });
        return table;
    }

    /**
     * Fires callback when related record is updated
     * @param {CustomEvent} event
     * @private
     */
    handleRecordUpdated(event) {
        this.updateRecord(event.detail);
    }

    /**
     * Fires callback when related record is created
     * @param {CustomEvent} event
     * @private
     */
    handleRecordCreated(event) {
        this.addRecord(event.detail);
    }

    /**
     * @inheritDoc
     */
    render() {
        this.rebuildChildren([this.renderTable()]);
    }
}
UIDynamicTable.defineAbstractElement();

export { UIDynamicTable };

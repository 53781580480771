import '../searchinput/ui-searchinput.js';
import '../modal/ui-modal-controller.js';
import '../option/ui-option-basic.js';
import '../option/ui-option-account.js';
import '../option/ui-option-multipayment.js';
import '../option/ui-option-icon.js';
import { UIElement } from '../ui-element.js';
import {
    debounce,
    appendModalControllerIfRequired,
    makePopoverHandler,
} from '../../global/helpers.js';
import {
    isKeyPressed,
    keyCodes,
    keyDirections,
    TabIndex,
} from '../../global/keyboard.js';
import {
    closestByCond,
    dispatchNativeEvent,
    isParentOf,
} from '../../global/ui-helpers.js';
import { Digest } from '../../global/digest.js';
import { Labels } from '../../global/labels.js';
import styles from './ui-autocomplete.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAutocomplete
 * @element ui-autocomplete
 * @classdesc Represents a class for <code>ui-autocomplete</code> element.
 * After typing, it suggests an option to chose for auto-completion.
 * @fires event:search
 * @fires event:pick
 * @listens onkeydown
 * @listens event:clear
 * @property {"account" | "basic" | "multipayment" | "icon" | null} [layout="basic"]
 *  {@attr layout}
 * Layout for options inside
 *  {@desc account}
 *  {@desc basic}
 *  {@desc multipayment}
 *  {@desc icon}
 * @property {number} [maxlength] {@attr maxlength} Sets how many options should be shown in
 * popover, the rest will be scrolled.
 * @property {number} [minmatch=-1] {@attr minmatch} This determines minimum number
 * of matching characters
 * @property {number} [debounce=0] {@attr debounce}  Debounce time to requests.
 * @property {number} [external] {@attr external} Make the component controllable by external data
 * provider.
 * @property {("plain" | "basic"| "balloon")} type {@attr type} Type of the autocomplete popover.
 *  {@desc plain}
 *  {@desc basic}
 *  {@desc balloon}
 * @property {string} value Value of the field
 * @property {string} option Tag name of the option
 * @property {HTMLInputElement} control {@readonly}
 * @property {Function} comparator Comparator function to find matches.
 * @property {UIDatalist} datalist {@readonly} Shortcut to UIDatalist child element.
 * @property {HTMLUListElement} options {@readonly} Shortcut to list of entries.
 * @property {HTMLDivElement} popoverElement {@readonly} Shortcut to popover element.
 * @property {HTMLDivElement} assistant {@readonly} Shortcut to assistant element.
 * @property {HTMLDivElement} hint {@readonly} Shortcut to hint element (removed after input).
 * @slot
 * @example
 * <ui-field>
 *   <ui-autocomplete>
 *     <input type="search">
 *     <ui-datalist>
 *       <option value="value" data-whatever="whatever">
 *       <option value="somevalue" data-whatever="somedata">
 *     </ui-datalist>
 *   </ui-autocomplete>
 * </ui-field>
 */
class UIAutocomplete extends UIElement {
    /**
     * Provides getter and setter for "value" property
     * and link it to the "value" attribute
     * syncs with inner input
     */
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: 'basic' },
                minmatch: { type: Number, default: -1 },
                maxlength: Number,
                external: Boolean,
                debounce: { type: Number, default: 0 },
            },
            children: {
                control: '.ui-autocomplete__control',
                datalist: 'ui-datalist',
                assistant: '.ui-autocomplete__assistant',
                hint: '.ui-autocomplete__hint',
            },
        };
    }
    get value() {
        return this.control.value;
    }
    set value(value) {
        const input = this.control;
        if (input.value === value) {
            return;
        }
        input.value = value;
    }

    get option() {
        return 'ui-option-' + this.layout;
    }
    set option(value) {
        if (!value || value.indexOf('ui-option-') !== 0) {
            this.layout = null;
        }
        this.layout = value.replace('ui-option-', '');
    }

    get options() {
        return this.popoverElement.querySelector('.ui-autocomplete__options');
    }

    get popoverElement() {
        if (this._detachedPopover) {
            return this._detachedPopover;
        }
        return this.querySelector('.ui-autocomplete__popover');
    }

    get type() {
        if (this.layout === 'search') {
            return this.layout;
        }
        const type = this.getAttribute('type');
        if (type === 'plain' || type === 'basic' || type === 'balloon') {
            return type;
        }
        this.removeAttribute('type');
        const elementFactory = customElements.get(this.option);
        if (!elementFactory) {
            return null;
        }
        return elementFactory.POPOVER_TYPE;
    }
    set type(value) {
        // do nothing
    }

    get comparator() {
        const el = customElements.get(this.option);
        return el && typeof el.prototype.findMatch === 'function'
            ? el.prototype.findMatch
            : () => true;
    }
    set comparator(value) {
        // do nothing
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-autocomplete', {
            hint: `Use up and down arrows to review and enter to select. Touch device users,
             explore by touch or with swipe gestures.`,
            results: 'Results',
            resultsFound: 'Results found: ',
            noResultsFound: 'No results found',
        });
    }

    /**
     *  Returns thes list of options.
     *  @param {number} [index] If index is set return options with index.
     *  @returns {UIElement}
     */
    getOptions(index) {
        const options = this.popoverElement.querySelectorAll(
            '.ui-autocomplete__option'
        );
        return index > -1 ? options[index] : options;
    }

    /**
     * Get popover height in pixels.
     * @returns {boolean}
     */
    isValueValid() {
        const value = this.control.value;
        if (!(this.minmatch > -1)) {
            return true;
        }
        return value && value.length >= this.minmatch;
    }

    /**
     * Get popover height in pixels.
     * @returns {number}
     */
    getPopoverHeight() {
        const options = this.popoverElement.querySelectorAll(
            '.ui-autocomplete__option, .ui-autocomplete__group > [optgroup]'
        );
        if (!options.length) {
            this.closePopover();
            return 0;
        }

        const limit = this.maxlength
            ? Math.min(this.maxlength, options.length)
            : options.length;

        const lastVisibleItem = options[limit - 1];

        return lastVisibleItem.offsetTop + lastVisibleItem.clientHeight;
    }

    /**
     * Sets popover height in pixels.
     * @param {number} value
     */
    setPopoverHeight(value) {
        const options = this.popoverElement.querySelector(
            '.ui-autocomplete__options'
        );
        if (!options) {
            return;
        }
        const newHeight = value > 0 ? value + 'px' : null;
        const oldHeight = options.style.maxHeight;

        if ((!oldHeight && !newHeight) || oldHeight === newHeight) {
            return;
        }

        options.style.maxHeight = newHeight;
    }

    /**
     * Sets popover width in pixels.
     * @param {number} value
     */
    setPopoverWidth(value) {
        this.popoverElement.style.width = value > 0 ? value + 'px' : null;
    }

    /**
     * Checks if autocomplete has options.
     * @returns {boolean}
     */
    hasOptions() {
        const popover = this.popoverElement;
        return popover && popover.querySelector('.ui-autocomplete__option');
    }

    /**
     * Checks if the popover is visible.
     * @returns {boolean}
     */
    isPopoverVisible() {
        return !!this._detachedPopover;
    }

    /**
     * Opens detached popover.
     * @fires event:modal-open
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }
        this._detachedPopover = this.popoverElement;
        this.dispatchCustomEvent('modal-open', {
            type: 'popover',
            content: this.popoverElement,
            params: {
                classList: {
                    '-autocomplete': true,
                    '-balloon': this.type === 'balloon',
                    '-basic': this.type === 'basic',
                    '-plain': this.type === 'plain',
                    '-search': this.layout === 'search',
                },
                position: makePopoverHandler(this, {
                    supportMiddleAlignment: false,
                    supportTopAlignment: this.layout !== 'search',
                    popoverHeight: () => {
                        const h = this.getPopoverHeight();
                        this.setPopoverHeight(h);
                        this.setPopoverWidth(this.offsetWidth);
                        return h;
                    },
                }),
                target: this,
                onClose: () => this.closePopover(),
            },
        });
        this.updateClassList({
            '-active': true,
        });
    }

    /**
     * Closes detached popover.
     */
    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        const modal = this._detachedPopover.closest('ui-modal');
        if (modal) {
            this._detachedPopover = null;
            modal.close();
        }
        this.updateClassList({
            '-active': false,
        });
    }

    /**
     * Opens dropdown popup.
     */
    openPopover() {
        if (this.isPopoverVisible()) {
            return;
        }
        this.popoverElement['offsetWidth'].toString();
        this.options.addEventListener('click', this.handleClickOnOption);
        this.options.addEventListener('keydown', this.handleKeyDown);
        this.options.addEventListener('keydown', this.handleArrowKey);
        this.setPopoverHeight(this.getPopoverHeight());
        this.openDetachedPopover();
        document.addEventListener('click', this.handleClickOutside);
    }

    /**
     * Closes dropdown popup.
     */
    closePopover() {
        this.control.setAttribute('aria-expanded', 'false');
        this.removeAttribute('popover-align');
        this.removeAttribute('aria-activedescendant');
        if (!this.isPopoverVisible()) {
            return;
        }
        this.options.removeEventListener('click', this.handleClickOnOption);
        if (this._detachedPopover) {
            if (
                !document.activeElement ||
                document.activeElement === document.body
            ) {
                this.control.focus();
            }
            this.options.removeEventListener('keydown', this.handleKeyDown);
            this.options.removeEventListener('keydown', this.handleArrowKey);
            this.closeDetachedPopover();
        }
        document.removeEventListener('click', this.handleClickOutside);
    }

    /**
     * Updates content of popover.
     */
    syncOptions() {
        this.rebuildChildren.call(this.options, this.buildOptions());
        this.setPopoverHeight(this.getPopoverHeight());
    }

    /**
     * Updates content of popover.
     * @fires event:popover-updated
     */
    updatePopover() {
        this.syncOptions();
        this.setPopoverHeight(this.getPopoverHeight());
        if (
            document.activeElement === this.control &&
            // && this.control.value
            this._prevValue !== this.control.value &&
            this.hasOptions()
        ) {
            if (!this.isPopoverVisible()) {
                this.openPopover();
            } else {
                this.dispatchCustomEvent('popover-updated');
            }
        }
    }
    /**
     * Renders wrapper for one option.
     * @param {HTMLOptGroupElement} group
     * @returns {IElementConfig}
     */
    renderOptionGroup(group) {
        let children = [...group.children];
        if (!this.external) {
            const search = this.control.value;
            children = children.filter(
                (option) => !search.trim() || this.comparator(option, search)
            );
        }
        return {
            tagName: 'li',
            classList: {
                'ui-autocomplete__group': true,
                // '-hidden': this.hideSelected && option.selected // TODO: hide zero options ?
            },
            children: [
                this.renderOption(group),
                {
                    tagName: 'ul',
                    classList: {
                        'ui-autocomplete__group-options': true,
                    },
                    children: children.map((node, index) =>
                        this.renderOptionWrapper(node, index)
                    ),
                },
            ],
        };
    }

    /**
     * Renders wrapper for options.
     * @param {
     *   Array<HTMLOptionElement | HTMLOptGroupElement> |
     *   HTMLOptionElement|HTMLOptGroupElement
     * } options
     * @param {number} index
     * @returns {object}
     */
    renderOptionWrapper(options, index) {
        return {
            tagName: 'li',
            attributes: {
                key: options.getAttribute('data-key') || null,
                role: 'option',
                'aria-setsize': String(options.length),
                'aria-posinset': index + 1,
                tabindex: TabIndex.Active,
                'aria-label': options.value || options.innerText,
                'aria-selected': 'false',
                id: options.id || Digest.randomId(),
            },
            classList: {
                'ui-autocomplete__option': true,
            },
            events: {
                keydown: (event) => {
                    if (
                        isKeyPressed(event, keyCodes.SPACE) ||
                        isKeyPressed(event, keyCodes.ENTER)
                    ) {
                        event.target.querySelector('button').click();
                    }
                },
            },
            children: [
                {
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                        tabindex: TabIndex.Inactive,
                    },
                    children: [this.renderOption(options)],
                },
            ],
        };
    }
    /**
     * Renders one option.
     * @param {HTMLOptionElement | HTMLOptGroupElement} option
     * @returns {IElementConfig}
     */
    renderOption(option) {
        const isGroup = option.tagName.toLowerCase() === 'optgroup';
        const additionalAttributes = {
            'aria-hidden': 'true',
        };

        const inputText = this.control.value;
        const origValue = option.value || '';
        if (origValue.trim() && inputText.trim()) {
            const reg = new RegExp(inputText, 'gi');
            additionalAttributes['formatted'] = origValue
                .replace(/\*/g, '')
                .replace(reg, '**$&**');
        }

        return {
            tagName: this.option,
            attributes: {
                ...additionalAttributes,
                ...(isGroup
                    ? { optgroup: true, text: option.getAttribute('label') }
                    : this.datalist.getOptionAttributes(option, false)),
            },
        };
    }
    /**
     * Builds options/entries for autocomplete.
     * @returns {Array<IElementConfig>}
     */
    buildOptions() {
        let options = this.datalist
            ? [].slice.call(this.datalist.children, 0)
            : [];
        const inputText = this.control.value;
        const isValid = this.isValueValid();
        if (!this.external) {
            const matchFn = (option) => {
                if (option instanceof HTMLOptGroupElement) {
                    return [].some.call(option.children, matchFn);
                }
                if (!isValid) {
                    return false;
                }
                return inputText ? this.comparator(option, inputText) : true;
            };
            options = [].filter.call(options, matchFn);
        }
        this.control.setAttribute('aria-expanded', String(options.length > 0));
        if (this.debounce) {
            setTimeout(
                () => this.setAssistiveFoundText(options.length),
                this.debounce
            );
        } else {
            this.setAssistiveFoundText(options.length);
        }
        return options.map((optionItem, index) => {
            if (optionItem instanceof HTMLOptGroupElement) {
                return this.renderOptionGroup(optionItem);
            }
            return this.renderOptionWrapper(optionItem, index);
        });
    }

    /**
     * Sets assistive text how many record found.
     * @param {number} count
     */
    setAssistiveFoundText(count) {
        this.assistant.innerText =
            count > 0
                ? UIAutocomplete.labels.resultsFound + count
                : UIAutocomplete.labels.noResultsFound;
    }

    /**
     * Fires callback when the input got a focus state.
     * @param {Event} event
     * @private
     */
    handleInputFocus(event) {
        if (this._preventSearch) {
            this._preventSearch = false;
            return;
        }
        if (this.isPopoverVisible()) {
            return;
        }
        this.syncOptions();
        const popover = this.popoverElement;
        if (popover && popover.querySelector('.ui-autocomplete__option')) {
            this.openPopover();
            if (this.maxlength) {
                this.setPopoverHeight(this.getPopoverHeight());
            }
        }
        this.handleSearchRequest(
            this.control.value,
            this.control.getAttribute('name')
        );
    }

    /**
     * Fires callback when the input's value is updated.
     * @param {Event} event
     * @private
     */
    handleInputChange(event) {
        if (!this.isValueValid()) {
            this.closePopover();
            return;
        }
        this.updatePopover();
        const popover = this.popoverElement;

        /* if matches are empty don't show empty popover */
        if (popover && popover.querySelector('.ui-autocomplete__option')) {
            this.openPopover();
            if (this.maxlength) {
                this.setPopoverHeight(this.getPopoverHeight());
            }
        } else {
            this.closePopover();
        }

        this.handleSearchRequest(
            this.control.value,
            this.control.getAttribute('name')
        );
        // remove after any input.
        if (this.hint) {
            this.hint.remove();
            this.removeAttribute('aria-describedby');
        }
    }

    /**
     * Fires callback when debounced search request should be called.
     * @param {string} value
     * @param {string} [key]
     * @private
     */
    handleSearchRequest(value, key) {
        this.dispatchCustomEvent('search', { value: value || '', key: key });
    }

    /**
     * Fires callback when user clicked on option.
     * @param {MouseEvent} event
     * @private
     */
    handleClickOnOption(event) {
        const targetOption = closestByCond(event.target, (node) => {
            return node.classList.contains('ui-autocomplete__option');
        });
        const optionElement = targetOption.querySelector('button > *');
        if (!targetOption || !optionElement) {
            return;
        }
        event.preventDefault();
        this.control.value =
            typeof optionElement.getText === 'function'
                ? optionElement.getText()
                : optionElement.getAttribute('value');
        this._prevValue = this.control.value;
        dispatchNativeEvent(this.control, 'change');
        this._preventSearch = false;
        this.control.focus();
        this.closePopover();
        this.dispatchCustomEvent('pick', {
            option: targetOption,
            value: this.control.value,
            data:
                typeof optionElement.getData === 'function'
                    ? optionElement.getData()
                    : {},
        });
    }

    /**
     * @param {HTMLElement} element
     * @returns {boolean}
     */
    isInnerElement(element) {
        if (this.isParentOf(element)) {
            return true;
        }
        return !!(
            this._detachedPopover && isParentOf(this._detachedPopover, element)
        );
    }

    /**
     * Fires callback when user clicked outside of autocomplete element.
     * @param {MouseEvent} event
     * @private
     */
    handleClickOutside(event) {
        if (!this.isInnerElement(event.target)) {
            this.closePopover();
        }
    }

    /**
     * Fires callback when user clears input.
     * @param {CustomEvent} event
     * @private
     */
    handleClear(event) {
        if (this.isPopoverVisible()) {
            this.closePopover();
        }
    }

    /**
     * Fires callback when user presses up&down arrows.
     * @param {KeyboardEvent} event
     * @private
     */
    handleKeyDown(event) {
        if (!this.isPopoverVisible()) {
            return;
        }
        switch (event.keyCode) {
            case keyCodes.TAB:
                this.control.focus();
                this.closePopover();
                break;
            case keyCodes.ESCAPE:
                this.control.focus();
                break;
            default:
                break;
        }
    }

    /**
     * @param {KeyboardEvent} event
     * @private
     */
    handleArrowKey(event) {
        if (
            isKeyPressed(event, keyCodes.UP) ||
            isKeyPressed(event, keyCodes.DOWN)
        ) {
            event.preventDefault();
            const step = -keyDirections[event.keyCode];
            const options = this.getOptions();
            const currentIndex = [].indexOf.call(
                options,
                document.activeElement
            );
            let nextIndex = currentIndex + step;
            if (nextIndex < 0) {
                nextIndex = nextIndex + options.length;
            }
            if (nextIndex > options.length - 1) {
                nextIndex = nextIndex - options.length;
            }
            if (options[nextIndex]) {
                [].forEach.call(options, (o) => {
                    o.setAttribute('aria-selected', 'false');
                });
                options[nextIndex].setAttribute('aria-selected', 'true');
                options[nextIndex].focus();
                this.setAttribute(
                    'aria-activedescendant',
                    options[nextIndex].id
                );
            }
        }
    }

    /**
     * Fires callback when datalist got mutations.
     * @param {Event} event
     * @private
     */
    handleDatalistMutations(event) {
        this.updatePopover();
    }

    /**
     * Fires callback when datalist got mutations.
     * @param {Event} event
     * @private
     */
    handlePopoverMutations(event) {
        if (!this.isPopoverVisible()) {
            return;
        }
        this.setPopoverHeight(this.getPopoverHeight());
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.closeDetachedPopover();
    }

    /**
     * @inheritDoc
     */
    render() {
        const uid = Digest.randomId();
        const hintId = 'autocomplete-hint-' + uid;
        const optionsId = 'autocomplete-options-' + uid;
        const assistId = 'autocomplete-live-' + uid;
        const control = this.queryChildren('input')[0];
        const datalist = this.queryChildren('ui-datalist')[0];
        this.detachChildNodes();
        this.insertElements([
            {
                tagName: 'ui-searchinput',
                attributes: {
                    cleanable: true,
                },
                children: [
                    {
                        tagName: 'input',
                        element: control,
                        attributes: {
                            type: 'search',
                            role: 'combobox',
                            autocomplete: 'off',
                            'aria-autocomplete': 'list',
                            'aria-expanded': 'false',
                            'aria-describedby': hintId,
                            'aria-owns': optionsId,
                        },
                        classList: {
                            'ui-autocomplete__control': true,
                            '-long': true,
                        },
                    },
                ],
            },
            {
                tagName: 'ui-datalist',
                element: datalist,
            },
            {
                tagName: 'div',
                classList: {
                    'ui-autocomplete__popover': true,
                    ['-layout-' + this.layout]: this.layout === 'search',
                },
                children: [
                    {
                        tagName: 'ul',
                        attributes: {
                            id: optionsId,
                            role: 'listbox',
                            'aria-label': [UIAutocomplete.labels.results],
                            tabindex: TabIndex.Inactive,
                        },
                        classList: {
                            'ui-autocomplete__options': true,
                        },
                    },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    id: assistId,
                    'aria-live': 'assertive',
                },
                classList: {
                    'ui-autocomplete__assistant': true,
                    '-visually-hidden': true,
                },
            },
            {
                tagName: 'div',
                attributes: {
                    id: hintId,
                    'aria-live': 'polite',
                },
                classList: {
                    'ui-autocomplete__hint': true,
                    '-hidden': true,
                },
                children: [UIAutocomplete.labels.hint],
            },
        ]);

        this.updateClassList({
            '-balloon': this.type === 'balloon',
            '-basic': this.type === 'basic',
            '-plain': this.type === 'plain',
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        appendModalControllerIfRequired();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputFocus = this.handleInputFocus.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleClickOnOption = this.handleClickOnOption.bind(this);
        this.handleSearchRequest = this.handleSearchRequest.bind(this);
        this.handleArrowKey = this.handleArrowKey.bind(this);
        if (this.debounce) {
            this.handleInputChange = debounce(
                this.handleInputChange,
                this.debounce,
                true
            );
        }
        this.control.addEventListener('input', this.handleInputChange);
        this.control.addEventListener('focus', this.handleInputFocus);
        this.addEventListener('keydown', this.handleKeyDown);
        this.control.addEventListener('keydown', this.handleArrowKey);
        this.options.addEventListener('keydown', this.handleArrowKey);
        this.addEventListener('clear', this.handleClear);

        this.observer = new MutationObserver(
            this.handleDatalistMutations.bind(this)
        );
        this.observer.observe(this.datalist, {
            childList: true,
            subtree: true,
            attributes: true,
            characterData: true,
        });
    }
}

UIAutocomplete.defineElement('ui-autocomplete', styles);
export { UIAutocomplete };

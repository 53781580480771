import { UIElement } from '../ui-element.js';
import {
    dispatchNativeEvent,
    setInnerText,
    updateElement,
} from '../../global/ui-helpers.js';
import { Labels } from '../../global/labels.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-rating.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @classdesc Represents a class for <code>ui-rating</code> element.
 * @alias UIRating
 * @element ui-rating
 * @property {string} [labelMin] {@attr label-min} Label for minimum value.
 * @property {string} [labelMax] {@attr label-max} Label for maximum value.
 * @property {string} [labelLegend] {@attr label-legend} Label for accessibility
 * (hidden from user view).
 * @property {string} [radiogroupName="ui-rating"] {@attr radiogroup-name} Name for radio buttons.
 * If not set, can be taken from control id.
 * @property {HTMLFieldSetElement} fieldset Fieldset of inputs with type radio.
 * @property {HTMLInputElement} control Main input for storing value.
 * @example
 * <ui-rating label-min="Difficult" label-max="Easy" label-legend="Rate this website">
 *   <input id="test-rating-field" type="range" min="1" max="5" step="1">
 * </ui-rating>
 */
class UIRating extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['label-min', 'label-max', 'label-legend'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                labelMin: { type: String, default: '' },
                labelMax: { type: String, default: '' },
                labelLegend: { type: String, default: '' },
            },
            children: {
                fieldset: 'fieldset',
                control: '.ui-rating__control',
            },
        };
    }

    get radiogroupName() {
        return (
            this.getAttribute('radiogroup-name') ||
            this.control.getAttribute('id') ||
            'ui-rating'
        );
    }
    set radiogroupName(value) {
        this.setAttribute('radiogroup-name', value);
    }

    /**
     * @type {number}
     */
    get min() {
        return Number(this.control.min) || 1;
    }
    set min(value) {
        this.control.min = value;
    }

    /**
     * @type {number}
     */
    get max() {
        return Number(this.control.max) || 5;
    }
    set max(value) {
        this.control.max = value;
    }

    /**
     * @type {number}
     */
    get step() {
        return Number(this.control.step) || 1;
    }
    set step(value) {
        this.control.step = value;
    }

    /**
     * @type {number | null}
     */
    get value() {
        return parseInt(this.control.value) || null;
    }
    set value(value) {
        this.control.value = value;
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-rating', {
            navigateArrows: 'Use arrow keys to select rating.',
        });
    }

    /**
     * Return value aligned to the range and steps
     * @param {number} value
     * @returns {number | null}
     */
    alignValueToRange(value) {
        if (value < this.min) {
            return null;
        }
        if (value > this.max) {
            return this.max;
        }
        return value;
    }

    /**
     * Sets a value to the controls
     * @param {number} value
     * @fires change
     */
    setValue(value) {
        this.value = this.alignValueToRange(value);
        this.updateRadioFieldsetValue();
        dispatchNativeEvent(this.control, 'change', true);
    }

    /**
     * Updating icons appearance according to the value.
     */
    updateRadioFieldsetValue() {
        [].forEach.call(
            this.querySelectorAll('input[name=' + this.radiogroupName + ']'),
            (node) => {
                const icon = this.querySelector(
                    'label[for=' + node.getAttribute('id') + '] ui-icon'
                );
                icon.glyph =
                    Number(node.value) <= this.value
                        ? 'star-full'
                        : 'star-hollow';
            }
        );
    }

    /**
     * Build configs for radio fields
     * @returns {Array<IElementConfig>}
     */
    buildRadioFieldset() {
        let radioFields = [];
        for (let i = this.min; i < this.max + 1; i += this.step) {
            radioFields = [
                {
                    tagName: 'input',
                    attributes: {
                        type: 'radio',
                        value: i,
                        name: this.radiogroupName,
                        id: this.radiogroupName + '-' + i,
                    },
                },
                {
                    tagName: 'label',
                    attributes: {
                        for: this.radiogroupName + '-' + i,
                    },
                    children: [
                        {
                            tagName: 'ui-icon',
                            attributes: {
                                color: UIIcon.colors.ORANGE,
                                glyph: 'star-hollow',
                            },
                        },
                    ],
                },
            ].concat(radioFields);
        }

        return radioFields;
    }

    /**
     * Fires callback when user clicked on ui-icon.
     * @param {Event} e
     * @private
     */
    handleRateClick(e) {
        e.preventDefault();
        this.setValue(e.target.value);
    }

    /**
     * Fires callback when attributes of control mutates.
     * @param {Array<MutationRecord>} mutations
     * @private
     */
    handleControlMutations(mutations) {
        for (let i = 0; i < mutations.length; i++) {
            const mutation = mutations[i];
            if (mutation.type !== 'attributes') {
                continue;
            }
            if (['min', 'max', 'step'].indexOf(mutation.attributeName) > -1) {
                if (
                    mutation.oldValue !==
                    mutation.target.getAttribute(mutation.attributeName)
                ) {
                    this.rebuildChildren.call(
                        this.fieldset.querySelector('.ui-rating__radio-group'),
                        this.buildRadioFieldset()
                    );
                    this.setValue(this.value);
                    return;
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (!this.hydrated) {
            return;
        }

        switch (name) {
            case 'label-min':
                setInnerText(
                    this.querySelector('.ui-rating__label.-min'),
                    this.labelMin
                );
                break;
            case 'label-max':
                setInnerText(
                    this.querySelector('.ui-rating__label.-max'),
                    this.labelMax
                );
                break;
            case 'label-legend':
                setInnerText(this.querySelector('legend'), this.labelLegend);
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const control = this.queryChildren('input')[0];
        this.detachChildNodes();

        this.insertElements([
            {
                tagName: 'input',
                element: control,
                attributes: {
                    type: 'hidden',
                },
                classList: {
                    'ui-rating__control': true,
                },
            },
            {
                tagName: 'span',
                classList: {
                    'ui-rating__label': true,
                    '-min': true,
                },
                children: [this.labelMin],
            },
            {
                tagName: 'fieldset',
                attributes: {
                    'aria-label': UIRating.labels.navigateArrows,
                },
                children: [
                    {
                        tagName: 'legend',
                        classList: {
                            '-hidden': true,
                        },
                        children: [this.labelLegend],
                    },
                    {
                        tagName: 'div',
                        classList: {
                            'ui-rating__radio-group': true,
                        },
                    },
                ],
            },
            {
                tagName: 'span',
                classList: {
                    'ui-rating__label': true,
                    '-max': true,
                },
                children: [this.labelMax],
            },
        ]);

        updateElement(this.fieldset.querySelector('.ui-rating__radio-group'), {
            children: this.buildRadioFieldset(),
        });
        this.value = parseInt(this.control.getAttribute('value'));
        this.setValue(this.value);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.fieldset.addEventListener('click', (e) => {
            if (e.target.nodeName === 'INPUT' && e.target.type === 'radio') {
                this.handleRateClick(e);
            }
        });

        this.observer = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.observer.observe(this.control, {
            attributes: true,
        });
    }
}

UIRating.defineElement('ui-rating', styles);
export { UIRating };

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-dynamic-table selectable plugin.
 * @alias UISelectablePlugin
 * @implements {IComponentPlugin}
 * @fires event:select-record
 * @param {UIDynamicTable} target Target instance.
 * @property {UIDynamicTable} target Target instance.
 */
class UISelectablePlugin {
    constructor(target) {
        this.target = target;
    }

    /**
     * Resets array of selected records
     */
    initRowSelection() {
        this.target.selectedRecords = [];
    }

    /**
     * Selects the primary checkbox in header
     * @returns {HTMLInputElement}
     */
    getPrimaryControl() {
        return this.target.querySelector(
            'input[name=toggle-all-page' + this.resolveKey() + ']'
        );
    }

    /**
     * Updates the "toggle all in a page" checkbox
     * @param {boolean} active
     */
    updateToggleAllControl(active) {
        this.getPrimaryControl().checked = active;
    }

    /**
     * Updates checkbox states for every row
     */
    updateSelectedRows() {
        const selectedRows = this.target.selectedRecords;
        const selectableCheckboxes = this.target.rowCheckboxes;
        [].forEach.call(
            this.target.disabledCheckboxes,
            (checkbox) => (checkbox.checked = false)
        );

        if (selectableCheckboxes.length === 0) {
            this.updateToggleAllControl(false);
            this.getPrimaryControl().disabled = true;
            return;
        }
        this.getPrimaryControl().disabled = false;
        let allChecked = true;
        [].forEach.call(selectableCheckboxes, (checkbox) => {
            const result =
                selectedRows.indexOf(Number(checkbox.dataset.recordId)) > -1;
            if (!result) {
                allChecked = false;
            }
            checkbox.checked = result;
        });
        this.updateToggleAllControl(allChecked);
    }

    /**
     * Set checkbox state for every row in a current page
     * @param {boolean} value
     */
    toggleAllPage(value) {
        [].forEach.call(
            this.target.rowCheckboxes,
            (input) => (input.checked = value)
        );
    }

    /**
     * Fires callback when row checkbox is toggled
     * @param {CustomEvent} event
     * @fires event:select-record
     * @private
     */
    handleChange(event) {
        if (event.target.dataset.event !== 'select-record') {
            return;
        }
        const checkboxes = this.target.activeRowCheckboxes;
        this.updateToggleAllControl(
            checkboxes.length === this.target.rowCheckboxes.length
        );
        this.target.dispatchCustomEvent('select-record', {
            records: [
                {
                    id: event.target.dataset.recordId,
                    selected: event.target.checked,
                },
            ],
        });
    }

    /**
     * Fires callback when primary checkbox is toggled
     * @param {CustomEvent} event
     * @fires event:select-record
     * @private
     */
    handleToggleAllPage(event) {
        this.toggleAllPage(event.target.checked);
        this.target.dispatchCustomEvent('select-record', {
            records: [].map.call(this.target.rowCheckboxes, (el) => ({
                id: el.dataset.recordId,
                selected: el.checked,
            })),
        });
    }

    /**
     * Modify table by inserting a selection checkbox to the first column
     * @inheritDoc
     */
    modifyRow(rowElement, record) {
        const key = this.target.getUniqueKeyOf(record);
        const selectable = this.target.isSelectable
            ? this.target.isSelectable(record)
            : true;
        rowElement.insertAdjacentElement(
            'afterBegin',
            this.target.createElement({
                tagName: 'td',
                classList: {
                    col: true,
                    '-checkbox': true,
                },
                children: [
                    {
                        tagName: 'input',
                        attributes: {
                            id:
                                'select-record-' +
                                String(key) +
                                this.resolveKey(),
                            type: 'checkbox',
                            disabled: !selectable ? 'disabled' : null,
                            name:
                                'select-record-' +
                                String(key) +
                                this.resolveKey(),
                            'data-record-id': String(key),
                            'data-event': 'select-record',
                            checked:
                                this.target.selectedRecords.indexOf(
                                    String(key)
                                ) > -1
                                    ? 'checked'
                                    : null,
                        },
                    },
                    {
                        tagName: 'label',
                        attributes: {
                            for:
                                'select-record-' +
                                String(key) +
                                this.resolveKey(),
                        },
                    },
                ],
            })
        );
        return rowElement;
    }

    /**
     * Modify table by inserting a primary selection checkbox to the first column in header
     * @inheritDoc
     */
    modifyTable(uiTable) {
        const targetTr = uiTable.querySelector('thead tr');
        if (targetTr) {
            targetTr.insertAdjacentElement(
                'afterBegin',
                this.target.createElement({
                    tagName: 'th',
                    attributes: {
                        class: 'col -checkbox -mobile-highlight',
                    },
                    children: [
                        {
                            tagName: 'input',
                            attributes: {
                                id: 'toggle-all-page' + this.resolveKey(),
                                name: 'toggle-all-page' + this.resolveKey(),
                                type: 'checkbox',
                            },
                        },
                        {
                            tagName: 'label',
                            attributes: {
                                for: 'toggle-all-page' + this.resolveKey(),
                            },
                        },
                    ],
                })
            );
        }
        return uiTable;
    }

    /**
     * @returns {string}
     */
    resolveKey() {
        const customKey = this.target.dataset.selectableKey;
        if (customKey) {
            return '-' + customKey;
        }
        return '';
    }

    /**
     * @inheritDoc
     */
    render() {}

    /**
     * @inheritDoc
     */
    hydrate() {
        this.initRowSelection();
        const toggleAllCheckbox = this.target.querySelector(
            'input[name=toggle-all-page' + this.resolveKey() + ']'
        );
        if (toggleAllCheckbox) {
            toggleAllCheckbox.addEventListener(
                'change',
                this.handleToggleAllPage.bind(this)
            );
        }
        this.target
            .querySelector('tbody')
            .addEventListener('change', this.handleChange.bind(this));
    }
}
export { UISelectablePlugin };

/**
 * @memberof UISelectablePlugin
 * @event select-record
 * @alias event:select-record
 * @type {object}
 * @property {Array<{id: string, selected: boolean}>} records - Array of selected records
 * @augments {CustomEvent}
 */

import {
    boundAttributeSuffix,
    HTML_RESULT,
    marker,
    parseTemplateLiteral,
    rawTextElement,
    walker,
} from './html-parser.js';
import { insertElements } from './render-api.js';

/**
 * @param {string} value
 * @param {Array<*>} renderNodes
 * @returns {null|*}
 */
const resolveAttributeValue = (value, renderNodes) => {
    const attributeParts = value.split(marker);
    // Single value mode
    if (
        attributeParts.length === 2 &&
        attributeParts[0] === '' &&
        attributeParts[1] === ''
    ) {
        return renderNodes.shift();
    }
    if (attributeParts.length > 0) {
        return attributeParts.reduce(
            (acc, entry, index, list) =>
                `${acc}${entry}${
                    index + 1 < list.length ? renderNodes.shift() : ''
                }`,
            ''
        );
    }
    return null;
};

/**
 * @param {Element} node
 * @param {Array<string>} attributes
 * @param {Array<*>} renderNodes
 * @param {WeakMap<Node, *>} replacementMap
 */
const populateChild = (node, attributes, renderNodes, replacementMap) => {
    let config;
    switch (node.nodeType) {
        case Node.ELEMENT_NODE:
            config = {
                tagName: node.tagName.toLowerCase(),
                attributes: {},
                events: {},
                props: {},
                children: [],
            };
            if (node.hasAttributes()) {
                for (const name of node.getAttributeNames()) {
                    if (
                        name.endsWith(boundAttributeSuffix) ||
                        name.startsWith(marker)
                    ) {
                        const realName = attributes.shift();
                        if (realName !== undefined) {
                            const value = node.getAttribute(
                                realName.toLowerCase() + boundAttributeSuffix
                            );
                            const m = /([.?@])?(.*)/.exec(realName);
                            const attributeValue = resolveAttributeValue(
                                value,
                                renderNodes
                            );
                            switch (m[1]) {
                                case '@':
                                    if (typeof attributeValue === 'function') {
                                        config.events[m[2]] = attributeValue;
                                    }
                                    break;
                                case '.':
                                    config.props[m[2]] = attributeValue;
                                    break;
                                default:
                                    config.attributes[m[2]] = attributeValue;
                                    break;
                            }
                        }
                    } else {
                        config.attributes[name] = node.getAttribute(name);
                    }
                }
            }

            if (rawTextElement.test(config.tagName)) {
                const content = /** @type {string} */ node.innerHTML;
                node.innerHTML = '';
                const result = content.replaceAll(marker, function () {
                    return String(renderNodes.shift());
                });
                config.children.push(result);
            }
            replacementMap.set(node, config);
            break;
        case Node.COMMENT_NODE:
            replacementMap.set(node, renderNodes.shift());
            break;
        default:
            break;
    }
};

/**
 * @param {Node} rootNode
 * @param {Map<Node> | Array<Node>} renderNodes
 * @param {boolean} trusted
 * @returns {unknown[]}
 */
const populateNodeChildren = (rootNode, renderNodes, trusted) => {
    return [].map.call(rootNode.childNodes, (child) => {
        const hasReplacement = renderNodes.has(child);
        let replacement = hasReplacement ? renderNodes.get(child) : null;
        if (typeof replacement === 'number') {
            replacement = String(replacement);
        }
        switch (child.nodeType) {
            case Node.ELEMENT_NODE:
                if (hasReplacement) {
                    if (!rawTextElement.test(child.tagName)) {
                        replacement.children = populateNodeChildren(
                            child,
                            renderNodes,
                            trusted
                        );
                    }
                    return replacement;
                }
                return child;
            case Node.COMMENT_NODE:
                return hasReplacement ? replacement : child;
            case Node.TEXT_NODE:
                return trusted ? child.textContent : child;
            default:
                return child;
        }
    });
};

/**
 *
 * @param {HTMLTemplateElement} template
 * @param {Array<string>} attrNames
 * @param {Array<*>} values
 * @param {boolean} trusted
 * @returns {*}
 */
const populateTemplate = (template, attrNames, values, trusted) => {
    let node;
    walker.currentNode = template.content;
    const attributeMap = attrNames;
    const renderNodes = values;
    const replacementMap = new WeakMap();
    while ((node = walker.nextNode()) !== null) {
        populateChild(node, attributeMap, renderNodes, replacementMap);
    }
    return populateNodeChildren(template.content, replacementMap, trusted);
};

/**
 * Creates html template from string literal.
 * @param {boolean} trusted
 * @param {Array<string>} strings
 * @param {*} renderNodes
 * @returns {DocumentFragment | *}
 */
const processHTML = (trusted, strings, ...renderNodes) => {
    // TODO: rawTemplate might be cached.
    const { tpl: rawTemplate, attrNames } = parseTemplateLiteral(
        strings,
        HTML_RESULT
    );

    const children = populateTemplate(
        rawTemplate,
        attrNames,
        renderNodes,
        trusted
    );

    const resultTemplate = document.createElement('template');
    document.importNode(resultTemplate.content, true);
    insertElements(resultTemplate.content, children, null, trusted);

    return resultTemplate.content;
};

/**
 * Creates safe html template from string literal.
 * @memberof UiHelpers
 * @param {Array<string>} strings
 * @param {*} renderNodes
 * @returns {DocumentFragment | *}
 */
const html = (strings, ...renderNodes) => {
    return processHTML(false, strings, ...renderNodes);
};

/**
 * Creates unsafe html template from string literal.
 * @memberof UiHelpers
 * @param {Array<string>} strings
 * @param {*} renderNodes
 * @returns {DocumentFragment | *}
 */
const trustedHTML = (strings, ...renderNodes) => {
    return processHTML(true, strings, ...renderNodes);
};

/**
 * Creates safe html element from string literal.
 * @memberof UiHelpers
 * @param {Array<string>} strings
 * @param {*} renderNodes
 * @returns {HTMLElement}
 */
const element = (strings, ...renderNodes) => {
    return processHTML(false, strings, ...renderNodes).firstElementChild;
};

/**
 * Creates unsafe html element from string literal.
 * @memberof UiHelpers
 * @param {Array<string>} strings
 * @param {*} renderNodes
 * @returns {HTMLElement}
 */
const trustedElement = (strings, ...renderNodes) => {
    return processHTML(true, strings, ...renderNodes).firstElementChild;
};

/**
 * Creates css template from string literal.
 * @memberof UiHelpers
 * @param {Array<string>} strings
 * @param {*} keys
 * @returns {string}
 */
const css = (strings, ...keys) => {
    return strings.reduce((tpl, item, index) => {
        let entity = keys[index - 1];
        if (typeof entity === 'function') {
            entity = entity();
        }
        if (typeof entity === 'string') {
            return tpl + entity + item;
        }
        return tpl + item;
    }, '');
};

export { html, trustedHTML, element, trustedElement, css };

import '../icon/ui-icon.js';
import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import {
    insertElements,
    updateElement,
    createElement,
    updateClassList,
} from '../../global/ui-helpers.js';
import { Digest } from '../../global/digest.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-pagination.css';

/**
 * @memberof SharedComponents
 * @augments UIElement
 * @alias UIPagination
 * @element ui-pagination
 * @classdesc Represents a class for <code>ui-pagination</code> element.
 * @fires event:change
 * @property {number} total {@attr total} Total count of items.
 * @property {number} [pageSize=50] {@attr page-size} Items count for each page.
 * @property {number} [currentPage=1] {@attr current-page} Current page number.
 * @property {number} [rangeBefore=2] {@attr range-before}
 * Pagination items to show before current page.
 * @property {number} [rangeAfter=2] {@attr range-after}
 * Pagination items to show after current page.
 * @property {boolean} [showArrows=false] {@attr show-arrows} Shows arrows in list layout.
 * @property {boolean} [showPageSize=false] {@attr show-page-size}
 * @property {"left" | "center" | "right"} [justifyDesktop="left"] {@attr justify-desktop}
 * Buttons order.
 * {@desc left: Aligns left}
 * {@desc center: Aligns center}
 * {@desc right: Aligns right}
 *  @property {"left" | "right"} [justifyMobile="right"] {@attr justify-mobile}
 * Buttons order for smaller screens.
 * @property {"list" | "dropdown" | "simple" } [layoutDesktop="list"] {@attr layout-desktop}
 * Pagination layout.
 * {@desc list: Pagination buttons}
 * {@desc dropdown: Dropdown select}
 * {@desc simple: Page info with button arrows}
 * @property {"dropdown" | "simple" } [layoutMobile="dropdown"] {@attr layout-mobile}
 * Pagination layout for smaller screens.
 * @property {"small" | "large" } [size="small"] {@attr size} Pagination size for list layout.
 * {@desc small}
 * {@desc large}
 * @property {boolean} [syncControls=true] {@attr sync-controls}
 * two-way binding for currentPage and pageSize.
 * @property {string} layout {@readonly} Layout state.
 * @property {string} justify {@readonly} Justify state.
 * @property {HTMLUListElement} list {@readonly} Shortcut to list element.
 * @property {HTMLSelectElement} select {@readonly} Shortcut to select element.
 * @property {HTMLSelectElement} pageSizeControl {@readonly} Shortcut to page-size select element.
 * @property {HTMLDivElement} info {@readonly} Shortcut to simple layout element.
 * @property {HTMLButtonElement} nextButton {@readonly} Shortcut to next control element.
 * @property {HTMLButtonElement} prevButton {@readonly} Shortcut to previous control element.
 * @property {HTMLButtonElement} active {@readonly} Shortcut to active control element.
 * @property {NodeListOf<HTMLLinkElement>} links {@readonly} Shortcut to link elements.
 * @property {HTMLDivElement} wrapper {@readonly}
 * @property {HTMLLabelElement} currentPageLabel {@readonly}
 * @example
 * <ui-pagination
 *  total="200"
 *  page-size="10"
 *  current-page="2"
 *  range-before="1"
 *  range-after="1"
 *  justify-desktop="right"
 *  justify-mobile="left">
 * </ui-pagination>
 */
class UIPagination extends UIElement {
    /**
     * @type {{select: string, simple: string, list: string}}
     */
    static get LAYOUT() {
        return { list: 'list', select: 'dropdown', simple: 'simple' };
    }

    /**
     * Localization
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-pagination', {
            title: 'Pagination navigation',
            previousPage: 'Previous page',
            nextPage: 'Next page',
            currentPage: 'Current page',
            page: 'Page',
            show: 'Show per page',
        });
    }

    /**
     * Default page size list
     * @type {number[]}
     */
    static get DEFAULT_PAGE_SIZE_LIST() {
        return this._DEFAULT_PAGE_SIZE_LIST || [10, 25, 50, 100];
    }

    static set DEFAULT_PAGE_SIZE_LIST(value) {
        this._DEFAULT_PAGE_SIZE_LIST = value;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                total: { type: Number, default: 1 },
                pageSize: { type: Number, default: 50 },
                currentPage: { type: Number, default: 1 },
                showPageSize: Boolean,
                syncControls: { type: Boolean, default: true },
                rangeBefore: { type: Number, default: 2 },
                rangeAfter: { type: Number, default: 2 },
                justify: String,
                justifyDesktop: { type: String, default: 'left' },
                justifyMobile: { type: String, default: 'right' },
                layout: String,
                layoutDesktop: { type: String, default: 'list' },
                layoutMobile: { type: String, default: 'dropdown' },
            },
            children: {
                select: '.ui-pagination__select',
                list: '.ui-pagination__list',
                info: '.ui-pagination__info',
                prevButton: '.ui-pagination__control.-prev',
                nextButton: '.ui-pagination__control.-next',
                active: '.-active',
                pageSizeControl: '.ui-pagination__page-size-control select',
                wrapper: '.ui-pagination__wrapper',
                currentPageLabel: '.ui-pagination__page',
                links: {
                    selector: '.ui-pagination__link',
                    multiple: true,
                },
            },
        };
    }

    /**
     * Changes page size select.
     * @type {number[]}
     */
    get pageSizeList() {
        return this._pageSizeList || UIPagination.DEFAULT_PAGE_SIZE_LIST;
    }
    set pageSizeList(value) {
        this._pageSizeList = value;
        this.refreshPageSizeList();
    }

    /**
     * @private
     * @returns {string}
     */
    get randomId() {
        return this._randomId || (this._randomId = Digest.randomId());
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     * @readonly
     */
    static get observedAttributes() {
        return [
            'total',
            'page-size',
            'current-page',
            'range-before',
            'range-after',
            'layout',
            'layout-mobile',
            'layout-desktop',
            'justify',
            'justify-mobile',
            'justify-desktop',
        ];
    }

    /**
     * @type {number}
     * @readonly
     */
    get beforeCurrent() {
        return this.rangeBefore > 0 ? this.rangeBefore : 1;
    }

    /**
     * @type {number}
     * @readonly
     */
    get afterCurrent() {
        return this.rangeAfter > 0 ? this.rangeAfter : 1;
    }

    /**
     * Returns pagination id
     * @type {string}
     * @readonly
     */
    get paginationId() {
        return this.id ? this.id : 'ui-pagination-' + this.randomId;
    }

    /**
     * Returns near pages for list layout, all pages for dropdown.
     * @type {Array<number>}
     * @readonly
     */
    get pages() {
        const pages = [];

        if (this.layout === UIPagination.LAYOUT.list) {
            let left = Math.max(1, this.currentPage - this.beforeCurrent);
            if (left - 1 === 2) {
                left--;
            }

            let right = Math.min(
                this.currentPage + this.afterCurrent,
                this.pageCount
            );
            if (this.pageCount - right === 2) {
                right++;
            }

            for (let i = left; i <= right; i++) {
                pages.push(i);
            }
        }

        if (this.layout === UIPagination.LAYOUT.select) {
            Array.from({ length: this.pageCount }).map((_, i) => {
                pages.push(i + 1);
            });
        }

        return pages;
    }

    /**
     * Is previous page available.
     * @type {boolean}
     * @readonly
     */
    get hasPrev() {
        return this.currentPage > 1;
    }

    /**
     * Is next page available.
     * @type {boolean}
     * @readonly
     */
    get hasNext() {
        return this.currentPage < this.pageCount;
    }

    /**
     * Is first page visible.
     * @type {boolean}
     * @readonly
     */
    get hasFirst() {
        return this.currentPage >= 2 + this.beforeCurrent;
    }

    /**
     * Is first ellipsis visible.
     * @type {boolean}
     * @readonly
     */
    get hasFirstEllipsis() {
        return this.currentPage >= this.beforeCurrent + 4;
    }

    /**
     * Is last page visible.
     * @type {boolean}
     * @readonly
     */
    get hasLast() {
        return this.currentPage <= this.pageCount - (1 + this.afterCurrent);
    }

    /**
     * Is last ellipsis visible.
     * @type {boolean}
     * @readonly
     */
    get hasLastEllipsis() {
        return this.currentPage < this.pageCount - (2 + this.afterCurrent);
    }

    /**
     * Total page count.
     * @type {number}
     * @readonly
     */
    get pageCount() {
        return Math.ceil(this.total / this.pageSize);
    }

    /**
     * Current page total count
     * @type {number}
     * @readonly
     */
    get currentPageCount() {
        const itemCount = this.currentPage * this.pageSize - this.pageSize + 1;
        return itemCount >= 0 ? itemCount : 0;
    }

    /**
     * Current layout element.
     * @type {HTMLElement | undefined}
     * @readonly
     */
    get currentLayout() {
        let currentLayout;
        if (this.layout === UIPagination.LAYOUT.list) {
            currentLayout = this.list;
        } else if (this.layout === UIPagination.LAYOUT.select) {
            currentLayout = this.select;
        } else if (this.layout === UIPagination.LAYOUT.simple) {
            currentLayout = this.info;
        }
        return currentLayout;
    }

    /**
     * Returns label for page
     * @param {number} page
     * @private
     * @returns {string}
     */
    getPageLabel(page) {
        const isCurrentPage = page === this.currentPage;
        let label = isCurrentPage ? UIPagination.labels.currentPage + ', ' : '';
        label += UIPagination.labels.page + ' ' + page + '.';
        return label;
    }

    /**
     * Changes paginate page. Sends custom event.
     * @param {number} value Selected page.
     * @param {number} [pageSize] Selected page size.
     * @param {boolean} [focus] Should focus on current active paginate item.
     * @private
     */
    changePage(value, pageSize, focus = false) {
        pageSize = pageSize !== undefined ? pageSize : this.pageSize;
        if (value < 1 || value > this.pageCount) {
            return;
        }

        if (this.syncControls) {
            this.currentPage = value;

            if (focus) {
                this.active.focus();
            }
        }
        this.dispatchCustomEvent('change', {
            currentPage: value,
            pageSize: pageSize,
        });
    }

    /**
     * Renders button control.
     * @param {string} label
     * @param {UIIcon} icon
     * @param {string} cssClass
     * @private
     * @returns {HTMLElement}
     */
    renderControl(label, icon, cssClass) {
        return createElement({
            tagName: 'button',
            classList: ['ui-pagination__control', '-iconed', cssClass].reduce(
                (acc, cur) => {
                    acc[cur] = true;
                    return acc;
                },
                {}
            ),
            attributes: {
                'aria-label': label,
            },
            children: [icon],
        });
    }

    /**
     * Updates control disabled attribute.
     * @param {HTMLElement} control
     * @param {boolean} disabled
     * @private
     */
    updateControlDisabled(control, disabled) {
        updateElement(control, {
            attributes: {
                disabled: disabled,
            },
        });
    }

    /**
     * Renders icon.
     * @param {string} glyph
     * @private
     * @returns {UIIcon}
     */
    renderIcon(glyph) {
        return createElement({
            tagName: 'ui-icon',
            classList: {
                '-navicon': true,
            },
            attributes: {
                glyph: glyph,
                bgcolor: UIIcon.colors.WHITE,
            },
        });
    }

    /**
     * Renders button controls.
     * @private
     * @returns {Array<HTMLElement>}
     */
    renderControls() {
        const iconPrev = this.renderIcon('left');
        const buttonPrev = this.renderControl(
            UIPagination.labels.previousPage,
            iconPrev,
            '-prev'
        );
        const iconNext = this.renderIcon('right');
        const buttonNext = this.renderControl(
            UIPagination.labels.nextPage,
            iconNext,
            '-next'
        );
        return [buttonPrev, buttonNext];
    }

    /**
     * Renders layout select option.
     * @param {number} value
     * @private
     * @returns {HTMLElement}
     */
    renderLayoutSelectOption(value) {
        const isCurrentPage = value === this.currentPage;
        return createElement({
            tagName: 'option',
            attributes: {
                value: value.toString(),
                selected: isCurrentPage ? 'selected' : null,
                'aria-label': this.getPageLabel(value),
                'aria-current': isCurrentPage ? 'true' : null,
            },
            children: [value.toString()],
        });
    }

    /**
     * Renders simple info.
     * @private
     * @returns {HTMLElement}
     */
    renderLayoutInfo() {
        let template = String(this.currentPageCount);
        if (this.pageSize === 1) {
            template += ' / ' + this.total;
        } else {
            template +=
                '-' +
                Math.min(this.currentPage * this.pageSize, this.total) +
                ' / ' +
                this.total;
        }
        return createElement({
            tagName: 'div',
            classList: {
                'ui-pagination__info': true,
            },
            children: [
                createElement({
                    tagName: 'p',
                    children: [document.createTextNode(template)],
                }),
            ],
        });
    }

    /**
     * Renders page size list.
     * @private
     * @returns {HTMLElement}
     */
    renderPageSizeControl() {
        const id = this.paginationId + '-page-size';
        return createElement({
            tagName: 'div',
            classList: {
                'ui-pagination__page-size-control': true,
            },
            children: [
                createElement({
                    tagName: 'label',
                    attributes: {
                        for: id,
                    },
                    children: [
                        document.createTextNode(UIPagination.labels.show),
                    ],
                }),
                createElement({
                    tagName: 'select',
                    attributes: {
                        id: id,
                    },
                    children: this.pageSizeList.map(
                        this.renderPageSizeControlOption.bind(this)
                    ),
                }),
            ],
        });
    }

    /**
     * Renders page size list option.
     * @param {number} value
     * @private
     * @returns {HTMLElement}
     */
    renderPageSizeControlOption(value) {
        const isSelected = value === this.pageSize;
        const label = UIPagination.labels.show + ' ' + value;
        return createElement({
            tagName: 'option',
            attributes: {
                value: value.toString(),
                selected: isSelected ? 'selected' : null,
                'aria-label': label,
                'aria-current': isSelected ? 'true' : null,
            },
            children: [value.toString()],
        });
    }

    /**
     * Renders layout select.
     * @private
     * @returns {HTMLElement}
     */
    renderLayoutSelect() {
        const id = this.paginationId + '-page';
        return createElement({
            tagName: 'select',
            classList: {
                'ui-pagination__select': true,
            },
            attributes: {
                id: id,
            },
            children: this.pages.map(this.renderLayoutSelectOption.bind(this)),
        });
    }

    /**
     * Renders list item.
     * @param {number} value
     * @private
     * @returns {HTMLElement}
     */
    renderListItem(value) {
        const isCurrentPage = value === this.currentPage;
        return createElement({
            tagName: 'li',
            attributes: {
                value: value,
            },
            children: [
                createElement({
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                        'aria-label': this.getPageLabel(value),
                        'aria-current': isCurrentPage ? true : null,
                    },
                    classList: {
                        'ui-pagination__link': true,
                        '-active': isCurrentPage,
                    },
                    children: [document.createTextNode(value.toString())],
                }),
            ],
        });
    }

    /**
     * Renders ellipsis.
     * @private
     * @returns {HTMLElement}
     */
    renderEllipsis() {
        return createElement({
            tagName: 'li',
            children: [
                createElement(
                    {
                        tagName: 'span',
                        classList: {
                            'ui-pagination__ellipsis': true,
                        },
                        children: ['&hellip;'],
                    },
                    true
                ),
            ],
        });
    }

    /**
     * Renders list.
     * @private
     * @returns {HTMLElement}
     */
    renderLayoutList() {
        const items = [];
        if (this.hasFirst) {
            items.push(this.renderListItem(1));
        }
        if (this.hasFirstEllipsis) {
            items.push(this.renderEllipsis());
        }
        items.push.apply(items, this.pages.map(this.renderListItem.bind(this)));
        if (this.hasLastEllipsis) {
            items.push(this.renderEllipsis());
        }
        if (this.hasLast) {
            items.push(this.renderListItem(this.pageCount));
        }
        return createElement({
            tagName: 'ul',
            classList: {
                'ui-pagination__list': true,
            },
            children: items,
        });
    }

    /**
     * Renders current page label
     * @private
     * @returns {HTMLElement}
     */
    renderCurrentPageLabel() {
        const id = this.paginationId + '-page';
        return this.createElement({
            tagName: 'label',
            attributes: {
                for: id,
            },
            classList: {
                'ui-pagination__page': true,
            },
            children: [document.createTextNode(UIPagination.labels.page)],
        });
    }

    /**
     * Renders layout.
     * @private
     * @returns {HTMLElement | undefined}
     */
    renderLayout() {
        if (this.layout === UIPagination.LAYOUT.list) {
            return this.renderLayoutList();
        } else if (this.layout === UIPagination.LAYOUT.select) {
            return this.renderLayoutSelect();
        } else if (this.layout === UIPagination.LAYOUT.simple) {
            return this.renderLayoutInfo();
        }
    }

    /**
     * Handles select change.
     * @private
     * @param {Event} event
     */
    handleChange(event) {
        event.stopPropagation();
        const target = event.target;
        /**
         * @type {string}
         */
        const valueString = target.value;
        const value = parseInt(valueString);
        this.changePage(value);
    }

    /**
     * Handles page size select change.
     * @private
     * @param {Event} event
     */
    handlePageSizeChange(event) {
        event.stopPropagation();
        const target = event.target;
        /**
         * @type {string}
         */
        const valueString = target.value;
        const value = parseInt(valueString);
        if (this.syncControls) {
            this.pageSize = value;
        }
        this.refreshPageSizeList();
        this.changePage(this.currentPage, value);
    }

    /**
     * Next page.
     */
    next() {
        this.changePage(this.currentPage + 1);
    }

    /**
     * Previous page.
     */
    prev() {
        this.changePage(this.currentPage - 1);
    }

    /**
     * Handles paginate click.
     * @private
     * @param {MouseEvent} event
     */
    handleClick(event) {
        const target = event.target;
        /**
         * @type {HTMLLIElement}
         */
        const parent = target.parentElement;
        const value = parent.value;
        this.changePage(value, this.pageSize, true);
    }

    /**
     * Clears layout from DOM.
     * @param {string} layout
     */
    clearLayout(layout) {
        if (layout === UIPagination.LAYOUT.list) {
            this.list && this.list.remove();
        } else if (layout === UIPagination.LAYOUT.select) {
            this.select && this.select.remove();
        } else if (layout === UIPagination.LAYOUT.simple) {
            this.info && this.info.remove();
        }
    }

    /**
     * Rebuilds current layout children
     */
    rebuildLayoutChildren() {
        const currentLayout = this.currentLayout;
        if (currentLayout) {
            this.rebuildChildren.call(
                currentLayout,
                Array.from(this.renderLayout().children)
            );
        }
    }

    /**
     * Subscribes event listeners.
     */
    subscribeEvents() {
        [].forEach.call(this.links, (/** HTMLLinkElement*/ link) => {
            if (link['hasClickListener']) {
                return;
            }
            updateElement(link, {
                events: {
                    click: this.handleClick.bind(this),
                },
            });
            Object.defineProperty(link, 'hasClickListener', {
                value: true,
            });
        });

        if (this.select && !this.select['hasChangeListener']) {
            updateElement(this.select, {
                events: {
                    change: this.handleChange.bind(this),
                },
            });
            Object.defineProperty(this.select, 'hasChangeListener', {
                value: true,
            });
        }

        if (
            this.pageSizeControl &&
            !this.pageSizeControl['hasChangeListener']
        ) {
            updateElement(this.pageSizeControl, {
                events: {
                    change: this.handlePageSizeChange.bind(this),
                },
            });
            Object.defineProperty(this.pageSizeControl, 'hasChangeListener', {
                value: true,
            });
        }
    }

    /**
     * Fires callback when the screen size changes.
     * @private
     */
    handleSyncAttributes() {
        this.syncAttributeLayout();
        this.syncAttributeJustify();
    }

    /**
     * Syncs layout attribute.
     * @private
     */
    syncAttributeLayout() {
        const isMobile = this.mobileMediaQueryList.matches;
        this.layout = isMobile ? this.layoutMobile : this.layoutDesktop;
    }

    /**
     * Syncs justify attribute.
     * @private
     */
    syncAttributeJustify() {
        const isMobile = this.mobileMediaQueryList.matches;
        this.justify = isMobile ? this.justifyMobile : this.justifyDesktop;
    }

    /**
     * Wraps element(s) with class
     * @param {HTMLElement[]} elements
     * @returns {HTMLElement}
     */
    wrapElements(elements) {
        return createElement({
            tagName: 'div',
            classList: {
                'ui-pagination__wrapper': true,
            },
            children: elements,
        });
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }
        const currentLayout = this.currentLayout;

        switch (name) {
            case 'range-before':
            case 'range-after':
                // only for list layout
                this.list && this.rebuildLayoutChildren();
                break;
            case 'current-page':
            case 'total':
            case 'page-size':
                currentLayout && this.rebuildLayoutChildren();
                this.updateControlDisabled(this.nextButton, !this.hasNext);
                this.updateControlDisabled(this.prevButton, !this.hasPrev);
                if (this.select) {
                    this.select.value = this.currentPage.toString();
                }
                this.pageSizeControl.value = this.pageSize.toString();
                break;
            case 'layout':
                this.clearLayout(oldValue || UIPagination.LAYOUT.list);
                insertElements(this.wrapper, [this.renderLayout()]);
                this.syncAttributeLayout();
                break;
            case 'justify':
            case 'justify-mobile':
            case 'justify-desktop':
                this.syncAttributeJustify();
                break;
            case 'layout-mobile':
            case 'layout-desktop':
                this.syncAttributeLayout();
                break;
            /* istanbul ignore next */
            default:
                break;
        }

        if (this.layout === 'dropdown') {
            updateClassList(this.select, { '-hidden': this.pageCount < 2 });
            updateClassList(this.currentPageLabel, {
                '-hidden': this.pageCount < 2,
            });
        }

        this.subscribeEvents();
        if (this.currentPage > this.pageCount) {
            this.currentPage = Math.max(this.pageCount, 1);
        }
    }

    /**
     * Re-renders pageSizeControl select values
     */
    refreshPageSizeList() {
        const children =
            this.renderPageSizeControl().querySelector('select').children;
        this.rebuildChildren.call(this.pageSizeControl, Array.from(children));
    }

    /**
     * @inheritDoc
     */
    render() {
        this.layout = this.layoutDesktop;
        const controls = this.renderControls();
        const currentPageLabel = this.renderCurrentPageLabel();
        const layout = this.renderLayout();
        const pageSize = this.renderPageSizeControl();
        const elements = controls.concat([currentPageLabel, layout]);
        this.insertElements([this.wrapElements(elements)]);
        this.insertElements([pageSize]);
        this.updateElement({
            attributes: {
                layout: this.layout,
                role: 'navigation',
                'aria-label': UIPagination.labels.title,
            },
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.subscribeEvents();
        updateElement(this.nextButton, {
            events: {
                click: this.next.bind(this),
            },
        });
        updateElement(this.prevButton, {
            events: {
                click: this.prev.bind(this),
            },
        });
        this.updateControlDisabled(this.nextButton, !this.hasNext);
        this.updateControlDisabled(this.prevButton, !this.hasPrev);
        this.mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
        this.mobileMediaQueryList.addListener(
            this.handleSyncAttributes.bind(this)
        );

        // after-hydrate
        requestAnimationFrame(() => this.handleSyncAttributes());
    }
}

UIPagination.defineElement('ui-pagination', styles);
export { UIPagination };

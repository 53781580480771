import { UIElement } from '../ui-element.js';
import { css } from '../../global/template-literals.js';

/**
 * @memberof SharedComponents
 * @element ui-focus-visible-controller
 * @alias UIFocusVisibleController
 * @augments UIElement
 * @classdesc Represents a class for <code>ui-focus-visible-controller</code> element.
 * Normalizes focus-visible behavior across supported browsers.
 * @example
 * <ui-focus-visible-controller></ui-focus-visible-controller>
 */
class UIFocusVisibleController extends UIElement {
    /**
     * @type {string}
     */
    static get FOCUS_CLASS() {
        return '-focus-visible';
    }

    /**
     * @type {Array<string>}
     */
    static get allowedNavKeys() {
        return [
            'Tab',
            'Escape',
            'Hone',
            'End',
            'ArrowDown',
            'ArrowUp',
            'ArrowLeft',
            'ArrowRight',
        ];
    }

    /**
     * @type {Array<string>}
     */
    static get focusAcceptElements() {
        return ['input', 'textarea', 'select', 'button.-iconed'];
    }

    /**
     * Adds focus-visible class to the document element.
     * @param {KeyboardEvent} event
     */
    onKeyDown(event) {
        if (
            document.documentElement.classList.contains(
                this.constructor.FOCUS_CLASS
            )
        ) {
            return;
        }
        if (this.constructor.allowedNavKeys.includes(event.key)) {
            document.documentElement.classList.add(
                this.constructor.FOCUS_CLASS
            );
        }
    }

    /**
     * Removes focus-visible class from the document element.
     * @param {PointerEvent} event
     */
    onPointerDown(event) {
        document.documentElement.classList.remove(this.constructor.FOCUS_CLASS);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.onKeyDownHandler = this.onKeyDown.bind(this);
        this.onPointerDownHandler = this.onPointerDown.bind(this);
        document.addEventListener('keydown', this.onKeyDownHandler);
        document.addEventListener('pointerdown', this.onPointerDownHandler);
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        document.removeEventListener('keydown', this.onKeyDownHandler);
        document.removeEventListener('pointerdown', this.onPointerDownHandler);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        document.addEventListener('keydown', this.onKeyDownHandler);
        document.addEventListener('pointerdown', this.onPointerDownHandler);
    }
}

/* eslint-disable max-len */
UIFocusVisibleController.defineElement(
    'ui-focus-visible-controller',
    css`
        ui-focus-visible-controller {
            display: none;
        }
        html:not(.${UIFocusVisibleController.FOCUS_CLASS})
            :not(
                ${UIFocusVisibleController.focusAcceptElements.join(',')}
            ):focus-visible {
            outline: none;
        }
    `
);
/* eslint-enable max-len */

export { UIFocusVisibleController };

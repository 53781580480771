import { UIElement } from '../ui-element.js';
import { UIField } from '../field/ui-field.js';
import { getAttributes } from '../../global/ui-helpers.js';
import styles from './ui-fieldset.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIFieldset
 * @element ui-fieldset
 * @classdesc Represents a class for <code>ui-fieldset</code> element.
 * @property {boolean} [autosort] {@attr autosort} Auto-sorting of fields is enabled/disabled.
 * @property {UIField} fields {@readonly} Gets all fields of UIFieldset.
 * @property {object} listeners List of all listeners.
 * @deprecated This element is used only in ibank payment form.
 * @example
 * <ui-fieldset autosort="true"></ui-fieldset>
 */
class UIFieldset extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                autosort: Boolean,
            },
            children: {
                fields: {
                    selector: 'ui-field',
                    multiple: true,
                },
            },
        };
    }

    static get OBESERVABLE_ELEMENTS() {
        return ['input[name]', 'select[name]', 'textarea[name]'];
    }

    static sortPredicate(fieldA, fieldB) {
        return fieldA.index - fieldB.index;
    }

    /**
     * @param {Array<IFieldConfig>} fieldSet
     * @returns {Record<string, IFieldConfig>}
     */
    static toModel(fieldSet) {
        return fieldSet.reduce(function (res, oldField) {
            res[oldField.id] = oldField;
            return res;
        }, {});
    }

    /**
     * @param {Record<string, IFieldConfig>} fieldSet
     * @returns {Array<IFieldConfig>}
     */
    static toArray(fieldSet) {
        return Object.keys(fieldSet).map(function (key) {
            return fieldSet[key];
        });
    }

    /**
     * Convert HTMLElement node to control config.
     * @param {HTMLElement} node
     * @param {string} value
     * @returns {IFieldControl}
     * @deprecated
     */
    static readControlConfig(node, value) {
        const dataControl = node;
        let target = node;

        while (
            node.parentElement &&
            node.parentElement.tagName.toLowerCase() !== 'ui-field'
        ) {
            node = node.parentElement;
            if (
                [
                    'input',
                    'select',
                    'textarea',
                    'ui-dropdown',
                    'ui-slider',
                    'ui-datepicker',
                ].indexOf(node.tagName.toLowerCase()) > -1
            ) {
                target = node;
            }
        }

        if (!target) {
            return {};
        }

        const result = {};

        const tagName = target.tagName.toLowerCase();
        switch (tagName) {
            case 'input':
            case 'select':
            case 'textarea':
                result.type = target.tagName.toLowerCase();
                break;
            case 'ui-slider':
                result.type = 'slider';
                result.config = {
                    units: target.getAttribute('units'),
                    class: target.getAttribute('class'),
                };
                break;
            case 'ui-dropdown':
                result.type = 'dropdown';
                result.config = {
                    view: target.getAttribute('layout'),
                    maxlength: Number(target.getAttribute('maxlength')) || null,
                };
                break;
            case 'ui-datepicker':
                result.type = 'date';
                break;
        }

        const attributes = getAttributes(dataControl);

        if (Object.keys(attributes).length > 0) {
            result.attributes = attributes;
        }

        if (tagName === 'select' || tagName === 'ui-dropdown') {
            result.options = [].map.call(
                target.querySelectorAll('option'),
                function (opt) {
                    const result = {
                        value: opt.getAttribute('value'),
                        content: opt.innerHTML,
                    };

                    if (Object.keys(opt.dataset).length > 0) {
                        result.data = UIElement.mergeAttributes(
                            {},
                            opt.dataset
                        );
                    }
                    return result;
                }
            );
        }

        if (value !== '') {
            result.value = value;
        }

        return result;
    }

    /**
     * Convert ui-field node to config.
     * @param {UIField | HTMLElement} field
     * @returns {IFieldConfig}
     * @deprecated
     */
    static readFieldConfig(field) {
        let id = field.getAttribute('key');
        let control;
        const data = field.getData();
        const controls = [].filter.call(
            field.querySelectorAll('[name]'),
            function (node) {
                if (!id) {
                    id = node.getAttribute('name');
                }
                if (node.getAttribute('name') === id) {
                    control = UIFieldset.readControlConfig(node, data[id]);
                    return false;
                }
                return true;
            }
        );

        const result = {
            id: id,
            label: field.label,
            visible: !field.classList.contains('-hidden'),
            index: Number(field.dataset.index) || null,
        };

        if (control) {
            result.control = control;
        }

        if (controls.length > 0) {
            result.controls = controls.reduce(function (res, node) {
                const key = node.getAttribute('name');
                res[key] = UIFieldset.readControlConfig(node, data[key]);
                return res;
            }, {});
        }

        return result;
    }

    /**
     * Convert data config to element config.
     * @param {IFieldConfig} config
     * @returns {UIField | HTMLElement}
     */
    static toFieldElement(config) {
        return UIElement.prototype.createElement(
            UIField.fromFieldConfig(config)
        );
    }

    /**
     * @param {string} name
     * @param {IFieldControl} oldControl
     * @param {IFieldControl} newControl
     * @returns {Function | undefined}
     * @deprecated
     */
    static diffSelectOptions(name, oldControl, newControl) {
        return function (root, node) {
            // Atm we have just simply rebuild all options
            const control = node.querySelector('select[name=' + name + ']');
            root.detachChildNodes.call(control);
            root.insertElements.call(
                control,
                newControl.options.map(function (option) {
                    return {
                        tagName: 'option',
                        attributes: {
                            value: option.value,
                            selected:
                                option.value === newControl.value
                                    ? 'selected'
                                    : null,
                        },
                        children: [option.content],
                    };
                })
            );
        };
    }

    /**
     * @param {IFieldConfig} oldField
     * @param {IFieldConfig} newField
     * @returns {Function | undefined}
     * @deprecated
     */
    static diffHints(oldField, newField) {
        // console.log('Rebuild hint list');
        return function (root, node) {
            // Atm we have just simply rebuild all hints
            node.removeHints();
            newField.hints.forEach(function (hint) {
                node.addHint(hint);
            });
        };
    }

    /**
     * @param {string} name
     * @param {IFieldConfig} oldField
     * @param {IFieldConfig} newField
     * @returns {Function | undefined}
     * @deprecated
     */
    static diffFieldProperties(name, oldField, newField) {
        if (oldField[name] === newField[name] || !(name in newField)) {
            return;
        }

        switch (name) {
            case 'index':
                // console.log('Change index for field');
                return function (root, node) {
                    node.setAttribute('data-index', newField.index);
                };
            case 'label':
                // console.log('Change label for field');
                return function (root, node) {
                    node.setAttribute('label', newField.label);
                };
            case 'visible':
                // console.log('Change visibility for field');
                return function (root, node) {
                    if (newField.visible) {
                        node.show();
                    } else {
                        node.hide();
                    }
                };
            case 'hints':
                // console.log('Change hints for field');
                return UIFieldset.diffHints(oldField, newField);
            default:
                return;
        }
    }

    /**
     * @param {string} name
     * @param {IFieldControl} oldControl
     * @param {IFieldControl} newControl
     * @returns {Function}
     * @deprecated
     */
    static diffControl(name, oldControl, newControl) {
        if ('type' in newControl && newControl.type !== oldControl.type) {
            // console.log('Type is different, rebuild the whole control');
            return function (root, node) {
                const oldData = node.getData();
                const oldNode = node.querySelector('[name=' + name + ']');
                newControl.attributes = UIElement.mergeAttributes(
                    newControl.attributes,
                    {
                        id: name,
                        name: name,
                    }
                );
                const newNode = root.createElement(
                    UIField.buildControlFromConfig(newControl)
                );
                oldNode.parentElement.insertBefore(newNode, oldNode);
                oldNode.parentElement.removeChild(oldNode);
                if (!('value' in newControl) && name in oldData) {
                    // Restore previous value if new value is not presented
                    const newData = {};
                    newData[name] = oldData[name];
                    node.setData(newData);
                }
            };
        }

        const controlPatches = [];

        if ('attributes' in newControl) {
            // console.log('Apply new attributes set', newControl.attributes);
            controlPatches.push(function (root, field, control) {
                // console.log('*****', control, '->', newControl.attributes);
                root.setAttributes.call(control, newControl.attributes);
            });
        }

        if ('options' in newControl) {
            // console.log('Apply new options set');
            controlPatches.push(function (root, field, control) {
                const patch = UIFieldset.diffSelectOptions(
                    name,
                    oldControl,
                    newControl
                );
                patch(root, field, control);
            });
        }

        if ('value' in newControl && newControl.value !== oldControl.value) {
            controlPatches.push(function (root, field, control) {
                const data = {};
                data[name] = newControl.value;
                field.setData(data);
            });
        }

        if (controlPatches.length > 0) {
            // console.log('Will apply control patches');
            return function (root, node) {
                // console.log('DO apply control patches', controlPatches.length);
                const ctrl = node.querySelector('[name=' + name + ']');
                controlPatches.forEach(function (patch) {
                    patch(root, node, ctrl);
                });
            };
        }

        // console.log('No changes for controls');
        return function () {
            // do nothing
        };
    }

    /**
     * @param {IFieldConfig} oldField
     * @param {IFieldConfig} newField
     * @returns {Function}
     * @deprecated
     */
    static diffField(oldField, newField) {
        // console.log('Compare', oldField, newField);
        if (!newField) {
            // console.log('Remove old field');
            return function (root) {
                const node = root.querySelector('[key=' + oldField.id + ']');
                if (!node) {
                    return;
                }
                node.parentElement.removeChild(node);
            };
        }

        const fieldPatches = [];

        const propertyPatches = ['visible', 'index', 'label', 'hints']
            .map(function (fieldName) {
                return UIFieldset.diffFieldProperties(
                    fieldName,
                    oldField,
                    newField
                );
            })
            .filter(function (patch) {
                return typeof patch === 'function';
            });

        if (propertyPatches.length) {
            // console.log('Apply field patches');
            fieldPatches.push(function (root, node) {
                propertyPatches.forEach(function (patch) {
                    patch(root, node);
                });
            });
        }

        const controlPatches = [];

        if ('control' in newField) {
            controlPatches.push(
                UIFieldset.diffControl(
                    oldField.id,
                    oldField.control,
                    newField.control
                )
            );
        }

        if (newField.controls) {
            Object.keys(newField.controls).forEach(function (key) {
                controlPatches.push(
                    UIFieldset.diffControl(
                        key,
                        oldField.controls[key],
                        newField.controls[key]
                    )
                );
            });
        }

        if (controlPatches.length) {
            fieldPatches.push(function (root, node) {
                controlPatches.forEach(function (patch) {
                    patch(root, node);
                });
            });
        }

        if (fieldPatches.length) {
            return function (root) {
                const node = root.querySelector('[key=' + oldField.id + ']');
                if (!node) {
                    return;
                }
                fieldPatches.forEach(function (patch) {
                    patch(root, node);
                });
            };
        }

        // console.log('DO nothing');
        return function () {
            // do nothing
        };
    }

    /**
     * @param {Array<IFieldConfig>} oldFieldSet
     * @param {Array<IFieldConfig> | Record<string, IFieldConfig>} newFieldSet
     * @returns {Function}
     * @deprecated
     */
    static diffFieldSet(oldFieldSet, newFieldSet) {
        const oldFieldsMap = UIFieldset.toModel(oldFieldSet);
        const newFieldsMap = Array.isArray(newFieldSet)
            ? UIFieldset.toModel(newFieldSet)
            : newFieldSet;

        const newFieldsArray = Array.isArray(newFieldSet)
            ? newFieldSet
            : UIFieldset.toArray(newFieldSet);

        const patches = [];
        oldFieldSet.sort(UIFieldset.sortPredicate).forEach(function (oldField) {
            patches.push(
                UIFieldset.diffField(oldField, newFieldsMap[oldField.id])
            );
        });

        const additionalFields = newFieldsArray
            .sort(UIFieldset.sortPredicate)
            .filter(function (newField) {
                return !oldFieldsMap[newField.id];
            });

        if (additionalFields.length) {
            // console.log('Add new', additionalFields.length, 'fields');
            patches.push(function (root) {
                root.insertElements(
                    additionalFields.map(function (additionalField) {
                        const newNode = root.createElement(
                            UIField.fromFieldConfig(additionalField)
                        );
                        newNode.checkErrors();
                        return newNode;
                    })
                );
            });
        }
        return function (root) {
            patches.forEach(function (patch) {
                patch(root);
            });
        };
    }

    /**
     * @returns {Record<string, IFieldConfig>}
     */
    getModelData() {
        return UIFieldset.toModel(this.getFieldConfigs());
    }

    /**
     * @param {string} key
     * @returns {UIField}
     */
    getField(key) {
        return this.querySelector('ui-field[key=' + key + ']');
    }

    /**
     * @returns {Array<IFieldConfig>}
     */
    getFieldConfigs() {
        return [].map.call(this.fields, UIFieldset.readFieldConfig);
    }

    /**
     * @param {Array<IFieldConfig> | Record<string, IFieldConfig>} newFieldSet
     */
    updateFieldSet(newFieldSet) {
        const newFieldsArray = Array.isArray(newFieldSet)
            ? newFieldSet
            : UIFieldset.toArray(newFieldSet);

        const newChildren = newFieldsArray
            .filter(function (config) {
                return !!config;
            })
            .sort(UIFieldset.sortPredicate);

        this.rebuildChildren(newChildren);
        this.onFieldSetUpdate();
    }

    /**
     * Updates a field in the fieldset.
     * @param {IFieldConfig} newField
     * @deprecated
     */
    updateField(newField) {
        const target = this.getField(newField.id);
        if (!target) {
            this.appendChild(
                this.createElement(UIField.fromFieldConfig(newField))
            );
        } else {
            const patch = UIFieldset.diffField(
                UIFieldset.readFieldConfig(target),
                newField
            );
            patch(this, target);
        }
        this.onFieldSetUpdate();
    }

    /**
     * Sort fields based by index.
     */
    sortFieldSet() {
        if (!this.autosort) {
            return;
        }
        const configs = this.getFieldConfigs()
            .sort(UIFieldset.sortPredicate)
            .reverse();
        if (configs.length < 2) {
            return;
        }

        for (let i = 1; i < configs.length; i++) {
            const current = configs[i];
            const previous = configs[i - 1];

            this.insertBefore(
                this.getField(current.id),
                this.getField(previous.id)
            );
        }
    }

    /**
     * Sets fields to this current field set.
     * @param {Array<IFieldConfig>} fields
     */
    setFields(fields) {
        this.insertElements(
            fields.sort(UIFieldset.sortPredicate).map(UIField.fromFieldConfig)
        );
    }

    /**
     * Lyfecycle hook called whenever fielset is updated.
     */
    onFieldSetUpdate() {
        this.sortFieldSet();

        // Resubscribe listeners
        if (!this.listeners) {
            return;
        }
        Object.keys(this.listeners).forEach(
            function (eventName) {
                this.listeners[eventName].forEach(
                    function (handler) {
                        this.unsubscribe(
                            UIFieldset.OBESERVABLE_ELEMENTS.join(','),
                            eventName,
                            handler
                        );
                        this.subscribe(
                            UIFieldset.OBESERVABLE_ELEMENTS.join(','),
                            eventName,
                            handler
                        );
                    }.bind(this)
                );
            }.bind(this)
        );
    }

    /**
     * Add event handler to controls.
     * @param {string} eventName
     * @param {Function} callback
     * @returns {UIFieldset}
     */
    addControlEventListener(eventName, callback) {
        if (!this.listeners) {
            this.listeners = {};
        }
        if (!this.listeners[eventName]) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName].push(callback);
        this.subscribe(
            UIFieldset.OBESERVABLE_ELEMENTS.join(','),
            eventName,
            callback
        );
        return this;
    }

    /**
     * Smoothly remove node from DOM tree
     * @returns {Promise<void>}
     */
    smoothRemove() {
        return UIElement.runSmoothRemove(
            this,
            { '-fade-out': true, '-slide-out': true },
            400
        );
    }

    /**
     * Smoothly append the NODE to target element
     * @param {HTMLElement} target
     * @returns {Promise<void>}
     */
    smoothAppend(target) {
        return UIElement.runSmoothAppend(
            this,
            target,
            { '-fade-in': true, '-slide-in': true },
            400
        );
    }

    /**
     * @inheritDoc
     */
    render() {}
}

UIFieldset.defineElement('ui-fieldset', styles);
export { UIFieldset };

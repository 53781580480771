import { Labels } from '../global/labels.js';
import {
    nextByCond,
    previousByCond,
    closestByCond,
    closestEventHolder,
    closestTag,
    createElement,
    defineElement,
    detachChildNodes,
    dispatchCustomEvent,
    dispatchNativeEvent,
    focusInteractiveElement,
    getAttributes,
    getChildrenForSlot,
    getFocusableElements,
    hide,
    insertElements,
    isDOMReady,
    isParentOf,
    isVisible,
    mergeAttributes,
    position,
    queryChildren,
    redetachChildNodes,
    revertClassList,
    runTransition,
    setAttributes,
    setInnerText,
    show,
    updateClassList,
    updateElement,
    whenDOMReady,
    mutateAttributesTo,
    mutateChildrenTo,
    mutateTo,
    prerenderElementsTree,
    rebuild,
    rebuildChildren,
    runSmoothRemove,
    runSmoothAppend,
    tagRegistry,
    setDebugMode,
    getDebugMode,
    getRenderMode,
    RenderMode,
    setRenderMode,
} from '../global/ui-helpers.js';

/**
 * @memberof SharedComponents
 * @abstract
 * @augments {HTMLElement}
 * @alias UIElement
 * @element ui-element
 * @classdesc Core interface for classes that represent a Custom Element.
 * Don't use this element, this is only for testing purposes mostly.
 * This can be used as "smart" div, if you know exactly what are you doing.
 * Methods of this class are inherited by all other UI components.<br>
 *   <ui-message type="urgent">
 *     You should never change state attribute <b>state=""</b> manually!
 *   </ui-message>
 *   <ui-message type="warning">
 *     In the most cases you should not create <b>&lt;ui-element></b>.
 *   </ui-message>
 * @property {boolean} rendered {@readonly} Shows if the component is in rendered state.
 * @property {boolean} hydrated {@readonly} Shows if the component is in hydrated state.
 * @property {string} state {@attr state} {@readonly} Shows the current component state,
 * return values [rendered/hydrated].
 * @property {string} plugins {@attr plugins} Space-separated plugins for component.
 * @property {IProps} props - Component attribute, children bindings.
 * True for all components at this version.
 */
class UIElement extends HTMLElement {
    /**
     * Locale for UIElements. Possible values are: en, ru, ee, lv, lt.
     * @type {Locale}
     */
    static get LOCALE() {
        return Labels.LOCALE;
    }

    static set LOCALE(value) {
        Labels.LOCALE = value;
    }

    /**
     * Debug mode, default is false.
     * @type {boolean}
     */
    static get DEBUG_MODE() {
        return getDebugMode();
    }

    /**
     * @deprecated use config.setDebugMode(true);
     * @param {boolean} value
     */
    static set DEBUG_MODE(value) {
        setDebugMode(value);
    }

    /**
     * Rendering mode, default is synchronous.
     * @type {boolean}
     */
    static get ASYNC_MODE() {
        return getRenderMode() === RenderMode.Asynchronous;
    }

    /**
     * @deprecated
     * use config.setRenderMode(RenderMode.Asynchronous);
     * @param {boolean} value
     */
    static set ASYNC_MODE(value) {
        setRenderMode(value ? RenderMode.Asynchronous : RenderMode.Synchronous);
    }

    /**
     * Language for UIElements. Possible values are: en, et, lv, lt, ru.
     * @type {string}
     */
    static get LANGUAGE() {
        return Labels.LANGUAGE;
    }

    static set LANGUAGE(value) {
        Labels.LANGUAGE = value;
    }

    /**
     * Checks if document is ready for Web Components to be rendered.
     * @deprecated use UI.isDOMReady()
     * @returns {boolean}
     */
    static get isDOMReady() {
        return isDOMReady;
    }

    /**
     * Adds DOMLoaded handler.
     * @deprecated use UI.whenDOMReady()
     * @returns {*}
     */
    static get whenDOMReady() {
        return whenDOMReady;
    }

    /**
     * Defines attribute on component construct.
     * @private
     * @param {string} name
     * @param {BooleanConstructor | NumberConstructor | StringConstructor | string} [type]
     * @param {string | number | boolean} [defaultValue]
     * @param {string} [propertyName]
     * @returns {UIElement}
     */
    static defineAttribute(name, type, defaultValue, propertyName) {
        let descriptor;
        switch (type) {
            /**
             * Getter:
             * If no attribute attached to the element then the property value is false,
             * If the attribute equals either empty string (attribute without value)
             * or itself the property value is true
             * Otherwise it checks content using the 'parseBooleanAttr' logic.
             * Setter:
             * If value is true it will add an empty attribute
             * If value is false it will remove the attribute
             */
            case 'boolean':
            case Boolean:
                descriptor = {
                    get() {
                        if (!this.hasAttribute(name)) {
                            return defaultValue || false;
                        }
                        const value = this.getAttribute(name);
                        return (
                            value === '' ||
                            value === name ||
                            this.parseBooleanAttr(value)
                        );
                    },
                    set(value) {
                        if (value) {
                            this.setAttribute(name, '');
                        } else {
                            if (defaultValue === true) {
                                this.setAttribute(name, false);
                            } else {
                                this.removeAttribute(name);
                            }
                        }
                    },
                };
                break;

            /**
             * Getter:
             * Gets number value from the attribute, if attribute is not exist
             * the default value will be returned (if presented, otherwise - null)
             * Setter:
             * Sets string representation of number value to the attribute
             */
            case 'number':
            case Number:
                descriptor = {
                    get() {
                        const value = this.getAttribute(name);
                        if (value !== null || defaultValue === undefined) {
                            return parseFloat(value || 0);
                        }
                        return defaultValue;
                    },
                    set(value) {
                        this.setAttribute(name, String(value));
                    },
                };
                break;

            case 'string':
            case String:
            default:
                /**
                 * Getter:
                 * Gets string value from the attribute, if attribute is not exist
                 * the default value will be returned (if presented, otherwise - null)
                 * Setter:
                 * Converts any value to string and sets it to the attribute
                 */
                descriptor = {
                    get() {
                        const value = this.getAttribute(name);
                        if (value !== null || defaultValue === undefined) {
                            return value;
                        }
                        return defaultValue;
                    },
                    set(value) {
                        if (value === null) {
                            this.removeAttribute(name);
                        } else {
                            this.setAttribute(name, value);
                        }
                    },
                };
        }

        propertyName = (propertyName || name).replace(
            /-([a-z])/g,
            (matches) => {
                return matches[1].toUpperCase();
            }
        );

        descriptor.configurable = true;
        Object.defineProperty(this.prototype, propertyName, descriptor);
        return this;
    }

    /**
     * Defines child shortcut on component construct.
     * @private
     * @param {string} propertyName - name of property
     * @param {string} selector
     * @param {Function} [fallback] - fallback for case if element is not yet rendered
     * @param {boolean} [multiple] - targeting multiple nodes.
     * @returns {UIElement}
     */
    static defineChild(propertyName, selector, fallback, multiple = false) {
        const privateProp = '_'.concat(propertyName);
        Object.defineProperty(this.prototype, propertyName, {
            configurable: true,
            get() {
                const target =
                    this[privateProp] || multiple
                        ? this.querySelectorAll(selector)
                        : this.querySelector(selector);
                if (
                    ['rendered', 'hydrated'].indexOf(this.state) > -1 ||
                    !fallback
                ) {
                    return target;
                }
                return fallback.bind(this)();
            },
            set(value) {
                this[privateProp] = value;
            },
        });
        return this;
    }

    /**
     * Defines children shortcut on component construct.
     * @deprecated This method is strongly deprecated and exists only for backwards
     * compatibility of the long time ago create project. You should not ever use it,
     * it will be removed! Define children in props instead.
     * @param {string} propertyName - name of property
     * @param {string} selector
     * @param {Function} [fallback] - fallback for case if element is not yet rendered
     * @returns {UIElement}
     */
    static defineChildren(propertyName, selector, fallback) {
        return this.defineChild(propertyName, selector, fallback, true);
    }

    /**
     * Defines abstract element.
     * Used for creating abstract elements that can be used as a base for other elements.
     * @param {IProps} [props] Define properties for the element.
     * @returns {UIElement}
     */
    static defineAbstractElement(props = this.props) {
        if (typeof props === 'object') {
            const attributes = props.attributes;
            const children = props.children;
            for (const key in attributes) {
                if (Object.hasOwnProperty.call(attributes, key)) {
                    const attribute = attributes[key];
                    let name = key.replace(
                        /([A-Z])/g,
                        (matches) => '-' + matches[0].toLowerCase()
                    );
                    /** @type {IAttributeType} */
                    let type = null;
                    /** @type {string | number | boolean | null} */
                    let defaultValue = null;
                    /** @type {IAttributeValidationHandler | null} */
                    let validate = null;
                    if (typeof attribute === 'object' && attribute !== null) {
                        if (attribute.name) {
                            name = attribute.name;
                        }
                        type = attribute.type;
                        defaultValue =
                            attribute.default !== undefined
                                ? attribute.default
                                : defaultValue;
                        validate = attribute.validate;
                    } else {
                        type = attribute;
                    }
                    if (validate) {
                        /** @type {Array<string>} */
                        (
                            this.propsToValidate = this.propsToValidate || []
                        ).push(name);
                    }
                    this.defineAttribute(name, type, defaultValue, key);
                }
            }
            for (const key in children) {
                if (Object.hasOwnProperty.call(children, key)) {
                    const child = children[key];
                    let selector = null;
                    let fallback = null;
                    let multiple = false;
                    if (typeof child === 'object' && child !== null) {
                        selector = child.selector;
                        fallback = child.fallback;
                        multiple = child.multiple;
                    } else {
                        selector = child;
                    }
                    this.defineChild(key, selector, fallback, multiple);
                }
            }
        }
        return this;
    }

    /**
     * Defines custom element
     * @param {string} tag
     * @param {Array<string> | string} [styles]
     * @returns {UIElement}
     */
    static defineElement(tag, styles) {
        this.defineAbstractElement();
        const placement = document.getElementById('shared-components-style');
        defineElement(tag, this, { styles: styles, stylePlacement: placement });
        return this;
    }

    /**
     * Defines property on component construct.
     * @deprecated This method is strongly deprecated and exists only for backwards
     * compatibility of the long time ago create project. You should not ever use it,
     * it will be removed! Define properties in props instead.
     * @param {string} propertyName - name of property
     * @param {object} descriptor
     * @returns {UIElement}
     */
    static defineProperty(propertyName, descriptor) {
        Object.defineProperty(this.prototype, propertyName, descriptor);
        return this;
    }

    /**
     * Merge two attributes objects.
     * @deprecated use UI.mergeAttributes()
     * @returns {IAttributeProps | NamedNodeMap}
     */
    static get mergeAttributes() {
        return mergeAttributes;
    }

    /**
     * Smoothly remove node from DOM tree.
     * @deprecated use UI.runSmoothRemove()
     * @returns {Promise<void>}
     */
    static get runSmoothRemove() {
        return runSmoothRemove;
    }

    /**
     * Smoothly append the NODE to target element.
     * @deprecated use UI.runSmoothAppend()
     * @returns {Promise<void>}
     */
    static get runSmoothAppend() {
        return runSmoothAppend;
    }

    /**
     * Mutates children object.
     * @deprecated use UI.mutateChildrenTo()
     * @returns {Function}
     */
    static get mutateChildrenTo() {
        return mutateChildrenTo;
    }

    /**
     * Mutates attributes object.
     * @deprecated use UI.mutateAttributesTo()
     * @returns {Function}
     */
    static get mutateAttributesTo() {
        return mutateAttributesTo;
    }

    /**
     * It will create a path to mutate old node element to new node.
     * @deprecated use UI.mutateTo()
     * @returns {Function}
     */
    static get mutateTo() {
        return mutateTo;
    }

    /**
     * Gets element tag name.
     * @returns {string}
     */
    static get is() {
        return tagRegistry.get(this);
    }

    /**
     * Defines element attributes, children.
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                state: String,
            },
        };
    }

    get rendered() {
        return ['rendered', 'hydrated'].indexOf(this.state) > -1;
    }

    get hydrated() {
        return this.state === 'hydrated';
    }

    /**
     * Checks if the element is parent of given node.
     * @param {Element} node - given node
     * @returns {boolean} - true if current node is parent to given node
     */
    isParentOf(node) {
        return isParentOf(this, node);
    }

    /**
     * Checks if the element is visible.
     * @returns {boolean} true if visible
     */
    isVisible() {
        return isVisible(this);
    }

    /**
     * Converts value to attribute.
     * @param {boolean} value
     * @returns {string}
     */
    convertBooleanAttr(value) {
        return value ? 'true' : 'false';
    }

    /**
     * Parses attribute's boolean value.
     * @param {string} value
     * @returns {boolean}
     */
    parseBooleanAttr(value) {
        return Boolean(value === 'yes' || value === 'true' || value === '1');
    }

    /**
     * Returns list of subscriptions for given target and event name.
     * @deprecated
     * @private
     * @param {string} target - child selector or :root by default for self
     * @param {string} eventName - the event name
     * @returns {Array<Function>} list of callbacks are bond to the event
     */
    getSubscriptions(target, eventName) {
        const key = !target ? ':root' : target;
        if (!this.subscriptions) {
            this.subscriptions = {};
        }
        if (!this.subscriptions[key]) {
            this.subscriptions[key] = {};
        }
        if (!this.subscriptions[key][eventName]) {
            this.subscriptions[key][eventName] = [];
        }
        return this.subscriptions[key][eventName];
    }

    /**
     * return list of the node's attributes.
     * @returns {Record<string, string | null>}
     */
    getAttributes() {
        return getAttributes(this);
    }

    /**
     * @param {Record<string, string | null>} attributes to be applied to the node
     * @example
     * // Set attributes "foo" and "bar" to attributes "id" and "name" respectively
     * element.setAttributes({id: "foo", name: "bar"});
     * @returns {UIElement | HTMLElement}
     */
    setAttributes(attributes) {
        return setAttributes(this, attributes);
    }

    /**
     * Remove class -hidden to show the element.
     * @returns {UIElement | HTMLElement}
     */
    show() {
        return show(this);
    }

    /**
     * Add class -hidden to hide the element.
     * @returns {UIElement | HTMLElement}
     */
    hide() {
        return hide(this);
    }

    /**
     * Focus first interactive element inside of the node.
     * @param {boolean} skipPriorities
     * @returns {UIElement | Element}
     */
    focusInteractiveElement(skipPriorities) {
        return focusInteractiveElement(this, skipPriorities);
    }

    /**
     * Return list of focusable elements
     * @returns {Array<HTMLElement> | *}
     */
    getFocusableElements() {
        return getFocusableElements(this);
    }

    /**
     * Search the closest parent node matched by given predicate.
     * @example
     * // Search closest parent node with "id" equals "foo"
     * element.closestByCond(function (node) {
     *     return node.id === "id"
     * });
     * @param {Function} searchPredicate - the callback should return true value if node is matched
     * @param {Function} [stopPredicate] - if the callback returns true than search will be stopped
     * @returns { * | HTMLElement }
     */
    closestByCond(searchPredicate, stopPredicate) {
        return closestByCond(this, searchPredicate, stopPredicate);
    }

    nextByCond(searchPredicate) {
        return nextByCond(this, searchPredicate);
    }

    previousByCond(searchPredicate) {
        return previousByCond(this, searchPredicate);
    }

    /**
     * @deprecated
     * @ignore
     */
    closestTag(tagName, stopNode) {
        return closestTag(this, tagName, stopNode);
    }

    closestEventHolder(stopNode) {
        return closestEventHolder(this, stopNode);
    }

    /**
     * @param {string} text
     * @param {boolean} [trusted] - defines if the text is trusted HTML
     * @returns {UIElement | HTMLElement}
     */
    setInnerText(text, trusted) {
        return setInnerText(this, text, trusted);
    }

    /**
     * Creates element by given tagName and config.
     * @param {IElementConfig} config
     * @param {boolean} [trusted] - defines if element's strings are trusted HTML
     * @returns {* | HTMLElement}
     */
    createElement(config, trusted) {
        return createElement(config, trusted);
    }

    /**
     * Updates element by given config.
     * @param {IElementConfig} config
     * @param {boolean} [trusted] - defines if element's strings are trusted HTML
     * @returns {* | HTMLElement}
     */
    updateElement(config, trusted) {
        return updateElement(this, config, trusted);
    }

    /**
     * Inserts elements inside component.
     * @param {Array<IElementConfig | HTMLElement>} configs
     * @param {InsertPosition} [position]
     * @param {boolean} [trusted] - defines if element's strings are trusted HTML
     * @returns {HTMLElement}
     */
    insertElements(configs, position, trusted) {
        return insertElements(this, configs, position, trusted);
    }

    /**
     * Dispatches custom event.
     * @param {string} name - the event name
     * @param {*} [detail] - the event data object or value
     * @param {boolean} [bubbles] - enable event bubbling. Enabled by default
     * @param {boolean} [cancelable] - enable preventing default action. Enabled by default
     * @returns {CustomEvent}
     */
    dispatchCustomEvent(name, detail, bubbles, cancelable) {
        return dispatchCustomEvent(this, name, detail, bubbles, cancelable);
    }

    /**
     * Dispatches native event.
     * @param {string} name - the event name
     * @param {boolean} [bubbles] - enable event bubbling. Enabled by default
     * @returns {Event}
     */
    dispatchNativeEvent(name, bubbles) {
        return dispatchNativeEvent(this, name, bubbles);
    }

    /**
     * @param {string} key
     * @returns {Array<HTMLElement>}
     * @protected
     */
    getChildrenForSlot(key) {
        return getChildrenForSlot(this, key);
    }

    /**
     * @returns {Array<HTMLElement>}
     * @protected
     */
    detachChildNodes() {
        return detachChildNodes(this);
    }

    /**
     * HTML parser needs to know the children's data.
     * So we will re-detach them to make browser renders them first.
     * @protected
     */
    redetachChildNodes() {
        redetachChildNodes(this);
    }

    /**
     * Finds direct children matched by condition.
     * @param {string} selector
     * @returns {Array<HTMLElement>}
     */
    queryChildren(selector) {
        return queryChildren(this, selector);
    }

    /**
     * Returns the node's position in the parent DOM tree.
     * @returns {number}
     */
    position() {
        return position(this);
    }

    /**
     * Add event listener for event to this node.
     * @deprecated use element.addEventListener() instead.
     * @variation 1
     * @param {string} param1 - event name
     * @param {Function} param2 - callback
     * @param {boolean} [param3] - skip duplication check to add
     *                                       multiple identical listeners to the same event
     * @example
     * // attach click event to the node
     * element.subscribe("click", function (event) { console.log("On host element clicked"); })
     * @returns {UIElement}
     */
    /**
     * Add event listener for event to this node.
     * @deprecated use element.addEventListener() instead.
     * @variation 2
     * @param {string} param1 - selector to the child element
     * @param {string} param2 - event name
     * @param {Function} param3 - callback
     * @param {boolean} [param4] - skip duplication check to add
     *                                       multiple identical listeners to the same event
     * @example
     * // attach click event to the child element matched by selector ".submit-btn"
     * element.subscribe(".submit-btn", "click", function (event) {
     *   console.log("On child element clicked");
     * })
     * @returns {UIElement}
     */
    subscribe(param1, param2, param3, param4) {
        let target;
        let eventName;
        let handler;
        let allowDuplication;

        if (
            arguments.length === 2 ||
            (arguments.length === 3 && typeof arguments[2] === 'boolean')
        ) {
            target = null;
            eventName = arguments[0];
            handler = arguments[1];
            allowDuplication = arguments[2] || false;
        } else if (
            arguments.length === 4 ||
            (arguments.length === 3 && typeof arguments[2] === 'function')
        ) {
            target = arguments[0];
            eventName = arguments[1];
            handler = arguments[2];
            allowDuplication = arguments[3] || false;
        } else {
            throw new Error('Wrong arguments passed to subscribe function');
        }

        const element = !target ? this : this.querySelectorAll(target);
        if (!element || (element instanceof NodeList && !element.length)) {
            return this;
        }
        const subscriptions = this.getSubscriptions(target, eventName);
        if (subscriptions.indexOf(handler) > -1 && !allowDuplication) {
            return this;
        }
        subscriptions.push(handler);

        [].forEach.call(
            element instanceof NodeList ? element : [element],
            (node) => node.addEventListener(eventName, handler)
        );

        return this;
    }

    /**
     * Remove event listener from this node.
     * @deprecated use element.removeEventListener() instead.
     * @variation 1
     * @param {string} param1 - event name
     * @param {Function} param2 - callback
     * @example
     * // detach the handler for click event from host element
     * element.unsubscribe("click", handler)
     * @returns {UIElement}
     */
    /**
     * Remove event listener from this node.
     * @deprecated use element.removeEventListener() instead.
     * @variation 2
     * @param {string} param1 - selector to the child element
     * @param {string} param2 - event name
     * @param {Function} param3 - callback
     * @example
     * // detach the handler for click event from child element matched by selector
     * element.unsubscribe(".submit-btn", "click", handler)
     * @returns {UIElement} reference to itself
     */
    unsubscribe(param1, param2, param3) {
        let target;
        let eventName;
        let handler;

        if (arguments.length === 2) {
            target = null;
            eventName = arguments[0];
            handler = arguments[1];
        } else if (arguments.length === 3) {
            target = arguments[0];
            eventName = arguments[1];
            handler = arguments[2];
        } else {
            throw new Error('Wrong arguments passed to unsubscribe function');
        }

        const element = !target ? this : this.querySelectorAll(target);
        if (!element || (element instanceof NodeList && !element.length)) {
            return this;
        }
        const subscriptions = this.getSubscriptions(target, eventName);
        const index = subscriptions.indexOf(handler);
        if (index > -1) {
            subscriptions.splice(index, 1);
        }

        [].forEach.call(
            element instanceof NodeList ? element : [element],
            (node) => node.removeEventListener(eventName, handler)
        );
        return this;
    }

    /**
     * Remove all event listeners from this node.
     * @deprecated use element.removeEventListener() instead.
     * @variation 1
     * @param {string} param1 - event name
     * @example
     * // detach all handlers for click event from host element
     * element.unsubscribeAll("click")
     * @returns {UIElement}
     */
    /**
     * Remove all event listeners from this node.
     * @deprecated use element.removeEventListener() instead.
     * @variation 2
     * @param {string} param1 - selector to the child element
     * @param {string} param2 - event name
     * @example
     * // detach all handlers for click event from child element matched by selector
     * element.unsubscribe(".submit-btn", "click", handler)
     * @returns {UIElement}
     */
    unsubscribeAll(param1, param2) {
        let target;
        let eventName;

        if (arguments.length === 1) {
            target = null;
            eventName = arguments[0];
        } else if (arguments.length === 2) {
            target = arguments[0];
            eventName = arguments[1];
        } else {
            throw new Error(
                'Wrong arguments passed to unsubscribeAll function'
            );
        }

        const element = !target ? this : this.querySelectorAll(target);
        if (!element || (element instanceof NodeList && !element.length)) {
            return this;
        }
        const subscriptions = this.getSubscriptions(target, eventName);
        while (subscriptions.length) {
            const handler = subscriptions.shift();
            const collection =
                element instanceof NodeList ? element : [element];
            for (let i = 0; i < collection.length; i++) {
                collection[i].removeEventListener(eventName, handler);
            }
        }
        return this;
    }

    /**
     * Update class list of the node by given config.
     * @example
     * // adds -active class to the node classList and removes -awesome from the node classList
     * element.updateClassList({"-active": true, "-awesome": false})
     * @param {Record<string, boolean>} config - hash map where key is classname value is flag
     *                                            to add / remove the it
     * @returns {UIElement | HTMLElement}
     */
    updateClassList(config) {
        return updateClassList(this, config);
    }

    /**
     * Update class list of the node by reverting given config,
     * could be used for animations to rollback last applied class config.
     * @example
     * // adds -active class to the node classList and removes -awesome from the node classList
     * element.revertClassList({"-active": true, "-awesome": false})
     * @param {Record<string, boolean>} config - hash map where key is classname value is flag
     * to add / remove it.
     * @deprecated use UI.revertClassList();
     * @returns {UIElement | HTMLElement}
     */
    revertClassList(config) {
        return revertClassList(this, config);
    }

    /**
     * @deprecated use UI.runTransition();
     * @param {Record<string, boolean>} config - hash map where key is classname value is flag
     * @param {number} timeout
     * @returns {Promise<void>}
     */
    runTransition(config, timeout) {
        return runTransition(this, config, timeout);
    }

    /**
     * Recursively check attribute for validate() callback through UI parents
     * @param {string} name
     * @returns {IAttributeValidationHandler | undefined}
     * @private
     */
    findValidateCallback(name) {
        if (this.constructor.name === UIElement.name) {
            return;
        }

        const attr = this.constructor.props.attributes[name];
        return attr
            ? attr.validate
            : UIElement.prototype.findValidateCallback.call(
                  Object.getPrototypeOf(this.constructor.prototype),
                  name
              );
    }

    /**
     * Validate attribute's value if attribute has validate() callback
     * @param {string} name
     * @param {string} value
     */
    validateAttribute(name, value) {
        const validate = this.findValidateCallback(name);
        typeof validate === 'function' &&
            /** @type {IAttributeValidationHandler} */ validate(this, value);
    }

    /**
     * A lifecycle hook called when element's attribute has been changed.
     * attributeChangedCallback should not be used directly. Use observeAttributes instead.
     * @param {string} name - attribute name
     * @param {string} oldValue - previous value
     * @param {string} newValue - current value
     * @protected
     */
    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue) {
            return;
        }
        if (
            UIElement.DEBUG_MODE &&
            this.hydrated &&
            this.hasAttribute(name) &&
            this.constructor.propsToValidate?.includes(name)
        ) {
            this.validateAttribute(name, newValue);
        }
        const callback = this.observeAttributes.bind(
            this,
            name,
            oldValue,
            newValue
        );
        whenDOMReady(callback, true);
    }

    /**
     * Wrapper for attributeChangedCallback to use this dynamically on document state
     * and DOMContentLoaded. Should be used instead of attributeChangedCallback if needed.
     * @param {string} name - attribute name
     * @param {string} oldValue - previous value
     * @param {string} newValue - current value
     * @protected
     */
    observeAttributes(name, oldValue, newValue) {}

    /**
     * Register plugin for class.
     * @param {string} key
     * @param {object} klass
     * @param {boolean} [autoload]
     */
    static registerPlugin(key, klass, autoload = false) {
        if (!this.plugins) {
            this.plugins = {};
        }
        if (autoload) {
            if (!this.autoloadPlugins) {
                this.autoloadPlugins = {};
            }
            this.autoloadPlugins[key] = klass;
        }
        this.plugins[key] = klass;
        if (klass.props) {
            this.defineAbstractElement(klass.props);
        }
        if (isDOMReady()) {
            const tagName = this.is;
            const selector = autoload
                ? tagName
                : `${tagName}[plugins~="${key}"]`;
            document.querySelectorAll(selector).forEach((node) => {
                const plugin = node.getPlugin(key);
                node.plugins[key] = plugin;
                if (plugin.render) {
                    plugin.render();
                }
                if (plugin.hydrate) {
                    plugin.hydrate();
                }
            });
        }
    }

    /**
     * Un-register plugin for class.
     * @param {string} key
     */
    static unregisterPlugin(key) {
        delete this.plugins[key];
        delete this.autoloadPlugins[key];
    }

    /**
     * Gets the plugin by key.
     * @param {string} key
     * @returns {IComponentPlugin}
     */
    getPlugin(key) {
        if (!this.plugins) {
            this.plugins = {};
        }
        if (this.plugins[key]) {
            return this.plugins[key];
        }
        if (!this.constructor.plugins[key]) {
            return null;
        }
        return new this.constructor.plugins[key](this);
    }

    /**
     * Gets all plugins for instance.
     * @returns {Record<string, string> | string}
     * @protected
     */
    getInstancePlugins() {
        if (this.plugins) {
            return this.plugins;
        }

        this.plugins = {};
        if (this.constructor.autoloadPlugins) {
            Object.keys(this.constructor.autoloadPlugins).forEach((key) => {
                this.plugins[key] = new this.constructor.autoloadPlugins[key](
                    this
                );
            });
        }

        const pluginsStr = this.getAttribute('plugins');
        if (pluginsStr) {
            pluginsStr.split(/\s+/).forEach((key) => {
                if (!this.constructor.plugins[key]) {
                    console.warn(
                        'Plugin ' +
                            key +
                            ' is not registered for class ' +
                            this.constructor.name
                    );
                    return;
                }
                this.plugins[key] = new this.constructor.plugins[key](this);
            });
        }
        return this.plugins;
    }

    /**
     * @param {string} key
     * @param {number|Array<string>|Record<string, string>} [substitutions]
     * @returns {string}
     */
    getLabel(key, substitutions) {
        return Labels.processLabel(this.constructor.labels, key, substitutions);
    }

    /**
     * Mutates the host element by given config or snapshot of another node
     * @param {IElementConfig | Node} newNode
     * @returns {HTMLElement}
     */
    rebuild(newNode) {
        return rebuild(this, newNode);
    }

    /**
     * @param {Array<IElementConfig | Node>} elements
     * @param {Function} [smoothRemove]
     * @param {Function} [smoothAppend]
     * @returns {HTMLElement}
     */
    rebuildChildren(elements, smoothRemove, smoothAppend) {
        return rebuildChildren(this, elements, smoothRemove, smoothAppend);
    }
    /**
     * Recursively pre-render Element tree.
     * @param {boolean} [recursive]
     */
    prerenderElementsTree(recursive) {
        prerenderElementsTree(this, recursive);
    }

    /**
     * Call a specific lifecycle hook for component and for it's plugins
     * @param {("render"|"hydrate"|"disconnect"|"reconnect")} hookName
     */
    runLifecycleHook(hookName) {
        // console.log(this.constructor.name, 'runLifecycleHook()', hookName);
        if (typeof this[hookName] === 'function') {
            this[hookName]();
        }
        if (!this.constructor.plugins) {
            return;
        }
        const plugins = this.getInstancePlugins();
        Object.keys(plugins).forEach((key) => {
            if (typeof plugins[key][hookName] === 'function') {
                plugins[key][hookName]();
            }
        });
    }

    /**
     * A lifecycle hook called each time after the component's
     * element is inserted into the document.
     * Do not override the base hook directly.
     * The "render/hydrate/reconnect" methods should be used instead
     * @private
     */
    connectedCallback() {
        const render = () => {
            try {
                if (!this.rendered) {
                    this.runLifecycleHook('render');
                    this.state = 'rendered';
                }
                if (!this.hydrated) {
                    this.runLifecycleHook('hydrate');
                    this.state = 'hydrated';
                    if (UIElement.DEBUG_MODE) {
                        this.constructor.propsToValidate?.forEach((name) => {
                            if (this.hasAttribute(name)) {
                                this.validateAttribute(
                                    name,
                                    this.getAttribute(name)
                                );
                            }
                        });
                    }
                } else {
                    this.runLifecycleHook('reconnect');
                }
            } catch (e) {
                console.log(this.constructor.name, this);
                console.error(e);
            }
        };
        whenDOMReady(render, !UIElement.ASYNC_MODE);
    }

    /**
     * A lifecycle hook called each time after the component's
     * element is removed from the document.
     * Do not override the base hook directly the "disconnect" method should be used instead
     * @private
     */
    disconnectedCallback() {
        if (this.hydrated) {
            this.runLifecycleHook('disconnect');
        }
    }

    /**
     * This method should build initial markup, has to be overwritten in child class.
     * This method won't be called in runtime if the component was already pre-rendered.
     * Do not set local variables here.
     * Do not set any handlers here.
     */
    render() {}

    /**
     * This method should hydrate existing markup, has to be overwritten in child class.
     * This method won't be called in prerendering step only for runtime.
     * You should set local variables here
     * You should set handlers here
     */
    hydrate() {}

    /**
     * A lifecycle hook called when the component's
     * element is removed from the document.
     * @protected
     */
    disconnect() {}

    /**
     * A lifecycle hook called when after the hydrated component's
     * element is attached to the DOM tree
     * @protected
     */
    reconnect() {}
}

UIElement.defineElement('ui-element');
export { UIElement };

import { Labels } from '../../global/labels.js';
import { UIDateElement } from './ui-dateelement.js';
import { browser } from '../../global/browser.js';
import { eventBus } from '../../global/event-bus.js';
import { Digest } from '../../global/digest.js';
import { addLeadingZero } from '../../global/helpers.js';

import {
    detachChildNodes,
    hide,
    insertElements,
    setAttributes,
    show,
    createElement,
    updateElement,
    addSyntheticFocusVisible,
    buildSyntheticFocusControl,
} from '../../global/ui-helpers.js';
import styles from './ui-monthpicker.css';

/**
 * @memberof SharedComponents
 * @augments {UIDateElement}
 * @alias UIMonthPicker
 * @element ui-monthpicker
 * @classdesc Represents a class for <code>ui-monthpicker</code> element.
 * Allows to pick months. Usually used with combination with ui-calendar.
 * @fires event:change
 * @fires event:blur
 * @property {Locale} [locale="en"] {@attr locale} Localization rules.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {string} [layout] {@attr layout} Layout for month picker.
 * @property {string} [valueType="month"] {@attr value-type} {@readonly}
 * @property {HTMLSelectElement} monthSelect {@readonly} Shortcut for native month select.
 * @property {HTMLSelectElement} yearSelect {@readonly} Shortcut for native year select.
 * @property {HTMLInputElement} input {@readonly} Shortcut for input element.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @property {HTMLInputElement} control {@readonly}, Shortcut to control element.
 * @slot
 * @example
 * <ui-monthpicker>
 *   <input type="text" name="month">
 * </ui-monthpicker>
 */
class UIMonthPicker extends UIDateElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                locale: String,
                controlId: String,
                layout: String,
            },
            children: {
                control: 'input',
                monthSelect: '.ui-monthpicker__control.-month',
                yearSelect: '.ui-monthpicker__control.-year',
                focusTrigger: '.ui-monthpicker__focus-trigger',
            },
        };
    }
    get valueType() {
        return 'month';
    }
    get value() {
        const value = this.control.value;
        return this.isValidMonth(value) ? value : null;
    }
    set value(value) {
        if (!this.isValidMonth(value) || value === this.value) {
            return;
        }
        this.setControlValue(value);
    }

    get mode() {
        return browser.isMobile()
            ? this.constructor.modes.INPUT
            : this.constructor.modes.SELECT;
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * Layouts for ui-monthpicker.
     * @type {{REFINED: string}}
     */
    static get layouts() {
        return {
            REFINED: 'refined',
        };
    }

    /**
     * @type {{INPUT: string, SELECT: string}}
     */
    static get modes() {
        return {
            INPUT: 'input',
            SELECT: 'select',
        };
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'control-id':
                if (this.focusTrigger) {
                    if (newValue) {
                        this.focusTrigger.setAttribute('id', newValue);
                    } else {
                        this.focusTrigger.removeAttribute('id');
                    }
                }
                break;
        }
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-monthpicker', {
            months: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            year: 'Year',
            month: 'Month',
        });
    }

    static get defaultMinYear() {
        return this._defaultMinYear || 1900;
    }

    static set defaultMinYear(value) {
        this._defaultMinYear = value;
    }

    static get defaultMaxYear() {
        return this._defaultMaxYear || 2100;
    }

    static set defaultMaxYear(value) {
        this._defaultMaxYear = value;
    }

    /**
     * Updates appearance according to current mode.
     * @returns {UIMonthPicker}
     */
    updateAppearance() {
        if (this.mode === this.constructor.modes.INPUT) {
            setAttributes(this.control, { type: 'month' });
            hide(this.monthSelect);
            hide(this.yearSelect);
        } else {
            setAttributes(this.control, { type: 'hidden' });
            show(this.monthSelect);
            show(this.yearSelect);
        }
        return this;
    }

    /**
     * Sets value to native controls
     * @param {string} value
     * @returns {UIMonthPicker}
     */
    setControlValue(value) {
        if (!value && this.mode === this.constructor.modes.INPUT) {
            return this;
        }
        const data = this.parseMonthValue(value, this.min, this.max);
        const inputControl = this.control;
        if (inputControl && inputControl.value !== data.value) {
            inputControl.value = data.value;
        }

        const monthSelect = this.monthSelect;
        const yearSelect = this.yearSelect;

        if (monthSelect && parseInt(monthSelect.value) !== data.month) {
            monthSelect.value = data.month;
        }

        if (yearSelect && parseInt(yearSelect.value) !== data.year) {
            yearSelect.value = data.year;
        }

        return this;
    }

    /**
     * Returns min and max month values for select element based on given params
     * @param {string} value
     * @param {string} min
     * @param {string} max
     * @returns {Record<string, number>}
     */
    getVisibleMonths(value, min, max) {
        let monthMin = 1;
        let monthMax = 12;
        let currentYear = UIMonthPicker.defaultMinYear;

        if (this.isValidMonth(value)) {
            currentYear = this.parseMonthValue(value, min, max).year;
        } else if (min) {
            currentYear = this.parseMonthValue(min, min, max).year;
        }

        if (min) {
            const minData = this.parseMonthValue(min, min, max);
            if (currentYear === minData.year) {
                monthMin = minData.month;
            }
        }

        if (max) {
            const maxData = this.parseMonthValue(max, min, max);
            if (currentYear === maxData.year) {
                monthMax = maxData.month;
            }
        }

        return {
            min: monthMin,
            max: monthMax,
        };
    }

    /**
     * Updates available options in the years select
     * @returns {UIMonthPicker}
     */
    updateYearList() {
        const control = this.yearSelect;
        const value = control.value;
        detachChildNodes(control);
        insertElements(
            control,
            this.buildYearList().map((year) => {
                return {
                    tagName: 'option',
                    attributes: { value: year },
                    children: [String(year)],
                };
            })
        );
        control.value = value;
        return this;
    }

    /**
     * Get available years
     * @returns {Array<number>}
     */
    buildYearList() {
        const years = [];
        let yearMin = UIMonthPicker.defaultMinYear;
        let yearMax = UIMonthPicker.defaultMaxYear;

        if (this.min) {
            yearMin = parseInt(this.min.split('-')[0]);
        }
        if (this.max) {
            yearMax = parseInt(this.max.split('-')[0]);
        }

        yearMin = Math.min(yearMin, yearMax);
        yearMax = Math.max(yearMin, yearMax);

        for (let i = yearMin - 1; i < yearMax; i++) {
            years.push(i + 1);
        }

        return years;
    }

    /**
     * Updates available options in the months select
     * @returns {UIMonthPicker}
     */
    updateMonthList() {
        const control = this.monthSelect;
        const value = control.value;
        detachChildNodes(control);
        insertElements(
            control,
            this.buildMonthList().map((month) => {
                return {
                    tagName: 'option',
                    attributes: { value: month },
                    children: [UIMonthPicker.labels.months[month - 1]],
                };
            })
        );
        control.value = value;
        return this;
    }

    /**
     * Get available months
     * @returns {Array<number>}
     */
    buildMonthList() {
        const months = [];
        const data = this.parseMonthValue(this.value, this.min, this.max);
        const visibleRange = this.getVisibleMonths(
            data.value,
            this.min,
            this.max
        );
        // Cannot use Array.from() because of legacy ibank
        for (let i = visibleRange.min - 1; i < visibleRange.max; i++) {
            months.push(i + 1);
        }

        return months;
    }

    /**
     * Fires callback when the host's value is updated
     * @param {Event} e
     * @private
     */
    handleValueUpdate(e) {
        this.setControlValue(this.value);
    }

    /**
     * Fires callback when the month/year selects value is updated
     * @param {Event} e
     * @private
     */
    handleMonthUpdate(e) {
        e.stopPropagation();
        const month = Number(this.monthSelect.value);
        const year = Number(this.yearSelect.value);
        this.setControlValue(year + '-' + month).dispatchNativeEvent('change');
        if (this.layout === this.constructor.layouts.REFINED) {
            this.recalculateMonthWidth();
        }
        this.updateMonthList();
    }

    /**
     * Fires callback when the input's value is updated
     * @param {Event} e
     * @private
     */
    handleInputChange(e) {
        this.setControlValue(this.control.value).dispatchNativeEvent('change');
    }

    /**
     * Fires callback when browser params are updated
     * @private
     */
    handleBrowserUpdate() {
        this.updateAppearance().setControlValue(this.value);
    }

    /**
     * Fires callback when any of control gets a blur event
     * @private
     */
    handleControlBlur() {
        this.dispatchNativeEvent('blur');
    }

    /**
     * @inheritDoc
     */
    handleControlMutations(mutations) {
        for (let i = 0; i < mutations.length; i++) {
            const mutation = mutations[i];
            if (mutation.type !== 'attributes') {
                continue;
            }
            if (['value', 'min', 'max'].indexOf(mutation.attributeName) > -1) {
                if (
                    mutation.oldValue !==
                    mutation.target.getAttribute(mutation.attributeName)
                ) {
                    this.updateYearList();
                    this.updateMonthList();
                    this.setControlValue(this.value);
                    return;
                }
            }
        }
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        eventBus.removeEventListener(
            'browser:update',
            this.handleBrowserUpdate
        );
        window.removeEventListener('pageshow', this.handlePageShow);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        eventBus.addEventListener('browser:update', this.handleBrowserUpdate);
        window.addEventListener('pageshow', this.handlePageShow);
    }

    /**
     * Convert to date string based on locale.
     * @param {string} str
     * @returns {string}
     */
    formatMonthValue(str) {
        if (!str) {
            return '';
        }
        const value = this.parseDateString(str);
        return [value.year, addLeadingZero(value.month)].join('-');
    }

    handlePageShow() {
        const month = Number(this.monthSelect.value);
        const year = Number(this.yearSelect.value);
        if (year && month) {
            this.setControlValue(year + '-' + month);
        }
    }

    /**
     * Recalculate element's width. Should not be called manually.
     */
    recalculateMonthWidth() {
        const helper = this.querySelector('.ui-monthpicker__month-w-helper');
        if (!helper) {
            return;
        }
        const compStyles = window.getComputedStyle(this.monthSelect);
        const pr = parseInt(compStyles.getPropertyValue('padding-right'));
        const pl = parseInt(compStyles.getPropertyValue('padding-left'));
        const opt = this.monthSelect.querySelector('option:checked');
        helper.innerText = opt.innerText;
        const w = helper.offsetWidth + pr + pl;
        this.style.setProperty('--month-width', `${w}px`);
        helper.innerText = '';
    }

    /**
     * @inheritDoc
     */
    render() {
        const id = Digest.randomId();
        /** @type {HTMLInputElement} */
        const control = this.queryChildren('input')[0];
        if (control) {
            if (control.min) {
                control.setAttribute('min', this.formatMonthValue(control.min));
            }
            if (control.max) {
                control.setAttribute('max', this.formatMonthValue(control.max));
            }
            const valueStr = control.getAttribute('value');
            if (valueStr) {
                control.setAttribute('value', this.formatMonthValue(valueStr));
            }
        }
        let elements = [
            buildSyntheticFocusControl(this.controlId, 'ui-monthpicker'),
            {
                tagName: 'input',
                element: control,
                attributes: {
                    class: 'ui-monthpicker__input',
                    pattern: '[0-9]{4}-[0-9]{2}',
                },
            },
        ];
        let monthControl = {
            tagName: 'select',
            attributes: {
                id: id + '-month',
            },
            classList: {
                'ui-monthpicker__control': true,
                '-month': true,
                '-refined': this.layout === this.constructor.layouts.REFINED,
            },
        };
        let yearControl = {
            tagName: 'select',
            attributes: {
                id: id + '-year',
            },
            classList: {
                'ui-monthpicker__control': true,
                '-year': true,
                '-refined': this.layout === this.constructor.layouts.REFINED,
            },
        };

        if (this.layout === this.constructor.layouts.REFINED) {
            yearControl = {
                tagName: 'div',
                classList: {
                    'ui-monthpicker__control-container': true,
                    '-year': true,
                    '-hidden': this.mode === this.constructor.modes.INPUT,
                },
                children: [yearControl],
            };
        }

        if (this.layout === this.constructor.layouts.REFINED) {
            monthControl = {
                tagName: 'div',
                classList: {
                    'ui-monthpicker__control-container': true,
                    '-month': true,
                    '-hidden': this.mode === this.constructor.modes.INPUT,
                },
                children: [
                    monthControl,
                    {
                        tagName: 'div',
                        attributes: {
                            'aria-hidden': true,
                            'aria-live': 'none',
                        },
                        classList: {
                            'ui-monthpicker__month-w-helper': true,
                        },
                    },
                ],
            };
        }

        const accessibilityLabels = ['year', 'month'].map((t) => {
            return createElement({
                tagName: 'label',
                attributes: {
                    for: id + '-' + t,
                },
                classList: {
                    '-visually-hidden': true,
                },
                children: [UIMonthPicker.labels[t]],
            });
        });

        elements = elements.concat(
            this.locale !== 'lt'
                ? [monthControl, yearControl]
                : [yearControl, monthControl],
            accessibilityLabels
        );

        this.insertElements(elements);
        updateElement(this.yearSelect, {
            children: this.buildYearList().map((year) => {
                return {
                    tagName: 'option',
                    attributes: { value: year },
                    children: [String(year)],
                };
            }),
        });

        updateElement(this.monthSelect, {
            children: this.buildMonthList().map((month) => {
                const label = UIMonthPicker.labels.months[month - 1];
                return {
                    tagName: 'option',
                    attributes: { value: month },
                    children: [label],
                };
            }),
        });

        this.updateAppearance().setControlValue(control ? control.value : null);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleBrowserUpdate = this.handleBrowserUpdate.bind(this);
        this.handlePageShow = this.handlePageShow.bind(this);
        const blurHandler = this.handleControlBlur.bind(this);
        eventBus.addEventListener('browser:update', this.handleBrowserUpdate);

        this.focusTrigger.addEventListener('click', () =>
            addSyntheticFocusVisible(this.monthSelect)
        );

        this.updateAppearance().setControlValue(this.value);

        this.updateElement({
            events: {
                change: this.handleValueUpdate.bind(this),
            },
        });

        updateElement(this.control, {
            events: {
                change: this.handleInputChange.bind(this),
                blur: blurHandler,
            },
        });

        updateElement(this.monthSelect, {
            events: {
                change: this.handleMonthUpdate.bind(this),
                blur: blurHandler,
            },
        });

        updateElement(this.yearSelect, {
            events: {
                change: this.handleMonthUpdate.bind(this),
                blur: blurHandler,
            },
        });

        this.observer = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.observer.observe(this.control, { attributes: true });

        window.addEventListener('pageshow', this.handlePageShow);
    }
}

UIMonthPicker.defineElement('ui-monthpicker', styles);
export { UIMonthPicker };

import { UITable } from '../../components/table/ui-table.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table info plugin.
 * @alias UITableInfo
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableInfo {
    constructor(target) {
        this.target = target;
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-info');
    }

    /**
     * @inheritDoc
     */
    hydrate() {}
}

UITable.registerPlugin('info', UITableInfo);

export { UITableInfo };

import { UITable } from '../../components/table/ui-table.js';
import { setAttributes } from '../../global/ui-helpers.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table json plugin.
 * @alias UITableJSON
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableJSON {
    constructor(target) {
        this.target = target;
    }

    buildFromJson(json) {
        let row;
        if (!json || (!(typeof json === 'object') && !Array.isArray(json))) {
            return;
        }
        if (json.caption) {
            this.target.table.appendChild(
                this.target.createElement({
                    tagName: 'caption',
                    children: [json.caption],
                })
            );
        }

        setAttributes(this.target.table, json.attributes);

        if (json.thead) {
            const thead = document.createElement('thead');
            this.target.table.appendChild(thead);
            for (let i = 0; i < json.thead.length; ++i) {
                row = thead.insertRow(-1);
                for (let j = 0; j < json.thead[i].length; ++j) {
                    const th = document.createElement('th');
                    th.innerHTML = json.thead[i][j].value;
                    row.appendChild(th);
                    setAttributes(th, json.thead[i][j].attributes);
                }
            }
        }

        ['tbody', 'tfoot'].forEach((section) => {
            if (json[section]) {
                const elem = document.createElement(section);
                this.target.table.appendChild(elem);
                for (let i = 0; i < json[section].length; ++i) {
                    row = elem.insertRow(-1);
                    for (let j = 0; j < json[section][i].length; ++j) {
                        const cell = row.insertCell(-1);
                        cell.innerHTML = json[section][i][j].value;
                        setAttributes(cell, json[section][i][j].attributes);
                    }
                }
            }
        });
        this.applyMethods('render');
        this.applyMethods('hydrate');
    }

    /**
     * Applies other plugins while json is ready.
     * @param {string} method - "render/hydrate"
     * @private
     */
    applyMethods(method) {
        const plugins = this.target.getInstancePlugins();
        delete plugins.json;
        for (const plug in plugins) {
            /* istanbul ignore else */
            if (plugins.hasOwnProperty(plug)) {
                plugins[plug][method]();
            }
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-json');
    }

    /**
     * @inheritDoc
     */
    hydrate() {}
}

UITable.registerPlugin('json', UITableJSON);

export { UITableJSON };

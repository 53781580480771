import { UITable } from '../../components/table/ui-table.js';
import {
    createElement,
    prerenderElementsTree,
} from '../../global/render-api.js';
import { Digest } from '../../global/digest.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table invert plugin.
 * @alias UITableInvert
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableInvert {
    constructor(target) {
        /**
         * @type {UITable}
         */
        this.target = target;
        /**
         * @private
         * @type {number}
         */
        this.maxRowSpan = 1;
    }

    /**
     * Checks if table cell contains input field.
     * @param {HTMLTableCellElement} td
     * @returns {boolean}
     * @private
     */
    doesCellContainField(td) {
        const allowedElems = ['ui-field', 'input', 'select', 'textarea'];
        return td.querySelectorAll(allowedElems.join(',')).length > 0;
    }

    /**
     * Inserts label into cell element directly with span.
     * @param {HTMLTableCellElement} td
     * @param {NodeList<*>} childNodes
     * @param {HTMLDivElement} container
     * @private
     */
    insertLabelIntoCell(td, childNodes, container) {
        // Do not do anything for extra rows.
        if (td.parentElement.classList.contains('-extra')) {
            return;
        }
        const nodes = [].map.call(childNodes, (child) => {
            prerenderElementsTree(child, true);
            const ch = child.cloneNode(true);
            if (ch.localName === 'ui-tooltip') {
                this.updateTooltipIds(ch);
            }
            return ch;
        });
        container.appendChild(
            createElement({
                tagName: 'span',
                attributes: {
                    class: 'cell-label',
                },
                children: nodes,
            })
        );

        td.classList.add('col');
        if (this.hasOnlyTextualContent(td)) {
            td.classList.add('-has-text-only');
        }
        if (this.isEmptyCell(td)) {
            td.classList.add('-is-empty-field');
        }
        td.insertAdjacentElement('afterbegin', container);
    }

    /**
     * Checks if table hasn't element nodes.
     * @param {HTMLTableCellElement} td
     * @returns {boolean}
     * @private
     */
    hasOnlyTextualContent(td) {
        return (
            [].filter.call(
                td.childNodes,
                (node) => node.nodeType === Node.ELEMENT_NODE
            ).length === 0
        );
    }

    /**
     * Checks if table hasn't element nodes.
     * @param {HTMLTableCellElement} td
     * @returns {boolean}
     * @private
     */
    isEmptyCell(td) {
        const noElements =
            [].filter.call(
                td.childNodes,
                (node) => node.nodeType === Node.ELEMENT_NODE
            ).length === 0;
        return noElements && !td.textContent.trim();
    }

    /**
     *
     * @param {HTMLTableCellElement} td
     * @param {NodeList<*>} content
     * @private
     */
    insertLabelIntoField(td, content) {
        const allowedToClone = ['ui-tooltip', 'a'];
        const field = td.querySelector('ui-field');
        if (!field || field.nodeName !== 'UI-FIELD') {
            return;
        }
        let text = '';
        [].forEach.call(content, (child) => {
            if (child.nodeType === Node.TEXT_NODE) {
                text += child.textContent.trim();
                return;
            }

            if (allowedToClone.indexOf(child.nodeName.toLowerCase()) > -1) {
                prerenderElementsTree(child, true);
                const newClone = child.cloneNode(true);
                if (newClone.localName === 'ui-tooltip') {
                    this.updateTooltipIds(newClone);
                }
                if (!field.state) {
                    newClone.setAttribute('slot', 'label');
                    field.appendChild(newClone);
                } else {
                    field.labelContainer.appendChild(newClone);
                }
            }
        });
        if (!field.label) {
            field.label = text;
        }
    }

    /**
     * @param {UITooltip} tooltip
     * @private
     */
    updateTooltipIds(tooltip) {
        const control = tooltip.querySelector('.ui-tooltip__control');
        const popover = tooltip.querySelector('.ui-tooltip__popover');
        const newPopoverId = Digest.randomId();
        control && control.setAttribute('aria-controls', newPopoverId);
        popover && popover.setAttribute('id', newPopoverId);
    }

    /**
     * Prepares row for small screen transform.
     * @param {HTMLTableRowElement} row
     * @private
     */
    prepareRow(row) {
        // If row already reverted do not do anything.
        if (row.dataset.inverted) {
            return;
        }
        const currentRowSpan = Number(--this.maxRowSpan > 0);
        const thead = this.target.table.tHead;
        const cells = row.querySelectorAll('td, th');
        const ths = thead.querySelectorAll('th');
        let nextSpan = 0;
        [].forEach.call(cells, (cell, i) => {
            this.maxRowSpan = Math.max(this.maxRowSpan, cell.rowSpan);
            const correctedIndex = i + nextSpan;
            if (cell.colSpan > 0) {
                nextSpan += cell.colSpan - 1;
            }
            if (this.doesCellContainField(cell) && ths[correctedIndex]) {
                this.insertLabelIntoField(cell, ths[correctedIndex].childNodes);
            } else if (ths[correctedIndex]) {
                const div = createElement({
                    tagName: 'div',
                    classList: { '-cell-label-container': true },
                });
                for (
                    let j = correctedIndex;
                    j < correctedIndex + cell.colSpan;
                    j++
                ) {
                    try {
                        this.insertLabelIntoCell(
                            cell,
                            ths[j + currentRowSpan].childNodes,
                            div
                        );
                    } catch (e) {
                        // colspan is out of bounds table. Skip and do nothing.
                    }
                }
            }
        });
        row.dataset.inverted = '1';
    }

    /**
     * Prepares HTML markup. If cell has UIField insert label into
     * UIField, otherwise add additional span element to keep the label.
     * @private
     */
    prepareLayout() {
        const tbody = this.target.table.tBodies[0];
        const thead = this.target.table.tHead;
        if (!tbody || !thead) {
            return;
        }
        const rowsSelector =
            'tbody tr:not([data-inverted]), tfoot tr:not([data-inverted])';
        const rows = this.target.table.querySelectorAll(rowsSelector);
        [].forEach.call(rows, this.prepareRow.bind(this));
    }

    /**
     * @param {Array<MutationRecord>} mutations
     * @private
     */
    handleMutations(mutations) {
        mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((/** HTMLTableRowElement */ node) => {
                switch (node.nodeName.toLowerCase()) {
                    case 'tr':
                        return this.prepareRow(node);
                    case 'td':
                        return this.prepareRow(node.parentElement);
                    case 'tbody':
                    case 'tfoot':
                    case 'thead':
                        return [].map.call(node.querySelectorAll('tr'), (tr) =>
                            this.prepareRow(tr)
                        );
                }
            });
        });
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-invert');
        this.prepareLayout();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.observer = new MutationObserver(this.handleMutations.bind(this));
        this.observer.observe(this.target.table, {
            childList: true,
            subtree: true,
        });
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.observer.disconnect();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.observer.observe(this.target.table, {
            childList: true,
            subtree: true,
        });
    }
}

UITable.registerPlugin('invert', UITableInvert);

export { UITableInvert };

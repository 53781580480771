import { UIElement } from '../ui-element.js';
import styles from './ui-option-tab.css';
import { buildNewWindowIcon, buildTabsBadge } from '../tabs/ui-tabs.helpers.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionTab
 * @element ui-option-tab
 * @classdesc Represents a class for <code>ui-option-tab</code> element.
 * Similar to ui-option, but holds basic value. Used with combination
 * with ui-dropdown.
 * @property {string} [linkto] External page URL.
 * @property {string} [target] Target window (same as in &lt;a href>.
 * @property {string} [badge] Badge for tab's caption.
 * @property {boolean} control {@readonly} It shows if the element will be located in control.
 * @example
 * <ui-option-tab></ui-option-tab>
 */
class UIOptionTab extends UIElement {
    static get POPOVER_TYPE() {
        return 'tabs';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                linkto: String,
                target: String,
                badge: String,
                layout: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        const isLink = !!this.linkto;
        const element = this.createElement({
            tagName: isLink ? 'a' : 'span',
            events: {},
            attributes: {
                target: this.target || null,
            },
            children: [
                document.createTextNode(
                    this.getAttribute('text') ||
                        this.getAttribute('value') ||
                        ''
                ),
            ],
            classList: {
                'ui-option-tab__control': true,
            },
        });

        if (this.linkto) {
            element.href = this.linkto;
            if (this.target) {
                element.appendChild(buildNewWindowIcon());
            }
        }

        if (this.badge) {
            element.appendChild(buildTabsBadge(this.badge));
        }

        this.appendChild(element);
        this.updateClassList({
            '-control': this.control,
        });
        if (this.control) {
            this.insertElements([
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'options',
                    },
                },
            ]);
        }
    }
}

UIOptionTab.defineElement('ui-option-tab', styles);
export { UIOptionTab };

import { UIElement } from '../ui-element.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-icon-progress.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @classdesc Represents a class for <code>ui-icon-progress</code> element.
 * @alias UIIconProgress
 * @element ui-icon-progress
 * @property {number} [total] {@attr total} Total icons to show.
 * @property {number} [fillPercentage] {@attr fill-percentage}
 * Fills percentage of all icons with fill-color.
 * @property {UIIconGlyph} glyph {@attr glyph} Represents glyph (symbol) icon
 * @property {("small" | "medium" | "large")} [size] {@attr size} Size of the icon
 *  {@desc small}
 *  {@desc medium}
 *  {@desc large}
 * @property {UIIconColor} [color="bark-30"] {@attr color} Color to be set on icon.
 * @property {UIIconColor} [fillColor="orange"] {@attr fill-color} Color to be set on filled icon.
 * @example
 * <ui-icon-progress total="10" fill-percentage="60" glyph="face-smile"></ui-icon-progress>
 */
class UIIconProgress extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                total: { type: Number, default: 0 },
                fillPercentage: { type: Number, default: 0 },
                glyph: String,
                size: String,
                color: { type: String, default: UIIcon.colors.BARK_30 },
                fillColor: { type: String, default: UIIcon.colors.ORANGE },
            },
        };
    }
    get fillRatio() {
        return (this.fillPercentage * this.total) / 100;
    }

    get fillRatioRounded() {
        return Math.round(this.fillRatio);
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return [
            'total',
            'fill-percentage',
            'glyph',
            'size',
            'color',
            'fill-color',
        ];
    }

    /**
     * Replaces elements.
     * @param {Array<IElementConfig | Node>} elements
     * @returns {HTMLElement}
     */
    replaceElements(elements) {
        while (this.firstChild) {
            this.removeChild(this.lastChild);
        }
        return this.insertElements(elements);
    }

    /**
     * Returns icon color based on fill ratio.
     * @param {number} current
     * @returns {string}
     */
    getColor(current) {
        const fillRatio = this.fillRatioRounded;
        return current <= fillRatio ? this.fillColor : this.color;
    }

    /**
     * Creates new icon component.
     * @param {string?} color
     * @returns {UIIcon}
     */
    getIcon(color) {
        return this.createElement({
            tagName: 'ui-icon',
            attributes: {
                glyph: this.glyph,
                size: this.size,
                color: color || this.color,
            },
        });
    }

    /**
     * Adds half icon to progress when fillRatio is rounded higher.
     * @param {UIIcon[]} icons
     */
    setHalfIcon(icons) {
        if (this.fillRatioRounded > this.fillRatio) {
            const halfIconIndex = this.fillRatioRounded - 1;
            const icon = icons.splice(halfIconIndex, 1)[0];
            icon.classList.add('ui-icon-progress__half-icon');

            const wrapper = this.createElement({
                tagName: 'div',
                classList: { 'ui-icon-progress__wrapper-half-icon': true },
                children: [this.getIcon(), icon],
            });
            icons.splice(halfIconIndex, 0, wrapper);
        }
    }

    /**
     * Renders component
     */
    renderIconProgress() {
        const icons = new Array(this.total).fill(null).map((_, i) => {
            const color = this.getColor(i + 1);
            return this.getIcon(color);
        });

        this.setHalfIcon(icons);
        this.replaceElements(icons);
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (!this.hydrated) {
            return;
        }

        switch (name) {
            case 'total':
            case 'fill-percentage':
            case 'color':
            case 'fill-color':
                this.renderIconProgress();
                this.setAccessibilityAttrs();
                break;
            case 'size':
                this.querySelectorAll('ui-icon').forEach(
                    (icon) => (icon.size = this.size)
                );
                break;
            case 'glyph':
                this.querySelectorAll('ui-icon').forEach(
                    (icon) => (icon.glyph = this.glyph)
                );
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @private
     */
    setAccessibilityAttrs() {
        this.setAttribute('role', 'meter');
        if (this.total > 0) {
            this.setAttribute('aria-valuemin', '0');
            this.setAttribute('aria-valuemax', String(this.total));
            this.setAttribute('aria-valuenow', this.fillRatio.toFixed(1));
            this.setAttribute('aria-valuetext', this.fillRatio.toFixed(1));
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.renderIconProgress();
        this.setAccessibilityAttrs();
    }
}

UIIconProgress.defineElement('ui-icon-progress', styles);
export { UIIconProgress };

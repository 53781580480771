import { UIElement } from '../ui-element.js';
import { UIDropdown } from '../dropdown/ui-dropdown.js';
import { createElement } from '../../global/render-api.js';
import { Labels } from '../../global/labels.js';
import { FormValidator } from '../../global/form-validator.js';
import { rebuild } from '../../global/mutations.js';
import countries from './baltic-region-countries.js';
import styles from './ui-phonefield.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIPhoneField
 * @element ui-phonefield
 * @classdesc Represents a class for <code>ui-phonefield</code> element.
 * Element is a simple way to add phone field, with required functionality.
 * @property {("ee" | "lv" | "lt" | "default")} rules {@attr rules} Country of the element.
 * @property {UIDropdown} dropdown {@attr rules} {@readonly} Shortcut to dropdown with countries.
 * @property {HTMLInputElement} control {@attr control} {@readonly} Shortcut to dropdown
 * with control with phone number.
 * @property {HTMLInputElement} valueControl {@readonly} Control
 * full value with code and phone number.
 * @slot
 * @example
 * <ui-phonefield rules="ee">
 *   <input type="tel" name="some-phone" id="some-phone-1"/>
 * </ui-phonefield>
 */
class UIPhoneField extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                rules: String,
            },
            children: {
                dropdown: '.ui-phonefield__dropdown',
                control: '.ui-phonefield__control',
                valueControl: '.ui-phonefield__value',
            },
        };
    }

    get value() {
        return this.valueControl.value;
    }
    set value(v) {
        this.control.value = v.replace(this.dropdown.value, '');
        this.valueControl.value = v;
    }

    /**
     * How many countries will be displayed in dropdown.
     * @type {number}
     */
    static get MAX_COUNTRIES_DISPLAY() {
        return 7;
    }

    /**
     * Gets the validator if exists.
     * @returns {null|SharedComponents.FormValidator}
     */
    get validator() {
        const form = this.closest('form');
        if (form && form.validator) {
            return form.validator;
        } else {
            return null;
        }
    }

    static get RULES() {
        return {
            ee: {
                sortOrder: ['ee', 'lv', 'lt', 'se', 'fi', 'ru'],
                pattern: '[0-9]{5,11}',
            },
            lv: {
                sortOrder: ['lv', 'ee', 'lt', 'se', 'fi', 'ru'],
                pattern: '[0-9]{5,11}',
            },
            lt: {
                sortOrder: ['lt', 'lv', 'ee', 'se', 'fi', 'ru'],
                pattern: '[0-9]{5,11}',
            },
            default: {
                sortOrder: ['ee', 'lv', 'lt', 'se', 'fi', 'ru'],
                pattern: '[0-9]{5,11}',
            },
        };
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-phonefield', {
            INVALID: 'The phone number entered is invalid.',
            CHOOSE_COUNTRY: 'Select country',
            PHONENR: 'Phone number',
        });
    }

    /**
     * Fetches countries from external source like ibank or any other
     * 3rd-party service.
     * @param {Array<UIPhoneFieldCountry>} data
     */
    static setCountriesData(data) {
        this.countries = data;
    }

    /**
     * Sets global country from external source like ibank or any other
     * 3rd-party service. Attribute has more priority rather than global country.
     * Globally it is good to set for whole project.
     * @param {("ee" | "lv" | "lt"| "default")} rules
     */
    static setRules(rules) {
        if (!UIPhoneField.isBalticCountry(rules)) {
            rules = 'default';
        }
        this.rules = rules;
    }

    /**
     * Checks if value is Estonia, Latvia, Lithuania country code iso2.
     * @param {string} iso2 - ISO2 country code.
     * @returns {boolean}
     */
    static isBalticCountry(iso2) {
        return ['ee', 'lv', 'lt'].includes(iso2);
    }

    /**
     * Builds option elements with countries data.
     * @param {string} [selectedCode]
     * @returns {Array<HTMLOptionElement>}
     * @private
     */
    buildCountriesOptions(selectedCode) {
        return UIPhoneField.RULES[this.resolveRules()].sortOrder
            .map((o) => UIPhoneField.countries.find((c) => c.iso2 === o))
            .map((c) => {
                return createElement({
                    tagName: 'option',
                    attributes: {
                        'data-flag': c.iso2,
                        value: '+' + c.dialCode,
                        'data-text': c.name,
                        selected:
                            this.clean(selectedCode) === c.dialCode || null,
                        'aria-label': c.name,
                    },
                });
            });
    }

    /**
     * Gets the country by global class property or attribute. Attribute
     * has higher priority.
     * @returns {string}
     */
    resolveRules() {
        return this.rules || UIPhoneField.rules;
    }

    /**
     * Resolve input pattern
     * @param {HTMLInputElement} control
     * @returns {string}
     */
    resolveInputPattern(control) {
        if (control.dataset.customPattern) {
            return control.dataset.customPattern;
        }
        let countryCode;
        try {
            countryCode =
                this.dropdown.nativeSelect.selectedOptions[0].dataset.flag;
        } catch (ex) {
            countryCode = this.resolveRules();
        }
        if (!UIPhoneField.isBalticCountry(countryCode)) {
            countryCode = 'default';
        }
        return UIPhoneField.RULES[countryCode].pattern;
    }

    /**
     * Returns value without '+' plus sign.
     * @param {string} [code]
     * @returns {string}
     */
    clean(code) {
        return code.replace(/^\+*/, '');
    }

    /**
     * Gets the country data by value.
     * @param {string} [code]
     * @returns {UIPhoneFieldCountry}
     */
    getCountryByDialCode(code) {
        const dialCode = this.clean(code);
        return UIPhoneField.countries.find((c) => c.dialCode === dialCode);
    }

    /**
     * Sets the controls value depends on the prefilled value in control.
     * @param {string} val
     * @returns {{dialCode: string, phoneNumber: string}}
     */
    parseValues(val) {
        let code = '';
        let country = null;
        let dialCode = '';
        val = this.clean(val);
        for (let i = 0; i < 4; ++i) {
            code += val.charAt(i);
            country = this.getCountryByDialCode(code);
            if (country) {
                dialCode = code;
            }
        }
        const phoneNumber = val.substring(dialCode.length);
        if (!dialCode && phoneNumber) {
            dialCode = this.getDefaultDialCode();
        }
        return {
            dialCode: dialCode ? '+' + dialCode : '',
            phoneNumber: phoneNumber,
        };
    }

    /**
     * Updates the dropdown and control values, based on forced or argument value.
     * @param {string} [forceValue]
     */
    update(forceValue) {
        const parts = this.parseValues(
            this.clean(forceValue || this.control.value)
        );
        this.dropdown.value = parts.dialCode;
        this.control.value = parts.phoneNumber;
        this.value = parts.dialCode + parts.phoneNumber;
    }

    /**
     * Gets default dial code for current country.
     * @returns {string}
     */
    getDefaultDialCode() {
        return UIPhoneField.countries.find((c) => {
            return (
                c.iso2 === UIPhoneField.RULES[this.resolveRules()].sortOrder[0]
            );
        }).dialCode;
    }

    /**
     * This is a bit ugly, but need to provide backwards compatibility for
     * ibank where phone fields aren't yet in ui-fields. Actually this is only
     * for visual part and doesn't perform real validation.
     *
     * TODO This method should be removed when the ibank's forms will be refactored to ui-field.
     * @private
     */
    performCustomValidation() {
        if (this.dropdown.nativeSelect.dataset.novalidate) {
            return;
        }
        const isValid = FormValidator.validateField(this.control);
        const field = this.closest('ui-field');
        let errHint = this.parentElement.querySelector(
            'ui-hint[error-type="customPhoneFieldError"]'
        );

        if (!isValid && !field) {
            this.classList.add('-error');

            const msg = FormValidator.translateValidationMessage(this.control);
            errHint =
                errHint ||
                createElement({
                    tagName: 'ui-hint',
                    attributes: {
                        type: 'error',
                        'error-type': 'customPhoneFieldError',
                        'aria-live': 'polite',
                    },
                    children: [msg],
                });
            this.control.setAttribute('aria-invalid', 'true');
            this.insertAdjacentElement('afterend', errHint);
        } else {
            this.control.removeAttribute('aria-invalid');
            this.classList.remove('-error');
            errHint && errHint.remove();
        }
    }

    /**
     * @private
     */
    handleDropdownChange() {
        this.control.pattern = this.resolveInputPattern(this.control);
        this.handleValueChange();
    }

    /**
     * Sets the value inside hidden field, dropdown + input value.
     * @private
     */
    handleValueChange() {
        this.performCustomValidation();
        const foundDialCode = this.control.value.match(/^\+\d{1,4}/);
        if (foundDialCode) {
            // We use filter for compatibility in iBank, there is overriden native
            // Array.prototype.find() method. So we use filter() as workaround.
            const opt = [].filter.call(
                this.dropdown.nativeSelect.options,
                (opt) => {
                    return opt.value && opt.value === foundDialCode[0];
                }
            )[0];
            if (opt) {
                this.dropdown.value = foundDialCode[0];
                this.control.value = this.control.value.replace(
                    foundDialCode[0],
                    ''
                );
            }
        }
        if (this.control.value.trim()) {
            this.value = this.dropdown.value + this.control.value;
        } else {
            this.valueControl.value = '';
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        /** @type {HTMLInputElement} */
        const control = this.queryChildren('input')[0];
        if (!control) {
            throw new Error(
                this.constructor.name + ' : non-input element given'
            );
        }

        this.initialValue = control.value;
        const parts = this.parseValues(this.clean(control.value));
        const countriesOptions = this.buildCountriesOptions(parts.dialCode);
        const cName = control.name;
        const cId = control.id;

        if (control.pattern) {
            control.dataset.customPattern = control.pattern;
        }

        rebuild(control, {
            tagName: 'input',
            attributes: {
                type: 'tel',
                required: control.required || null,
                pattern: this.resolveInputPattern(control),
                maxlength: 12,
                'data-pattern-error': UIPhoneField.labels.INVALID,
                'data-novalidate': control.dataset.novalidate || null,
                value: parts.phoneNumber || null,
                'aria-label': UIPhoneField.labels.PHONENR,
                'data-custom-pattern': control.dataset.customPattern || null,
                // should remove name and id, then set it to actual this.valueControl
                // which will go to the server or other handler.
                // 'name': null,
                // 'id': null
            },
            classList: {
                'ui-phonefield__control': true,
            },
        });

        this.insertElements([
            {
                tagName: 'ui-dropdown',
                classList: {
                    'ui-phonefield__dropdown': true,
                },
                attributes: {
                    layout: 'dial',
                    type: UIDropdown.types.BALLOON,
                    maxlength: UIPhoneField.MAX_COUNTRIES_DISPLAY,
                },
                children: [
                    {
                        tagName: 'select',
                        attributes: {
                            'aria-hidden': 'true',
                        },
                        children: countriesOptions,
                    },
                ],
            },
            {
                element: control,
            },
            {
                tagName: 'input',
                classList: {
                    'ui-phonefield__value': true,
                },
                attributes: {
                    type: 'hidden',
                    name: cName || null,
                    id: cId || null,
                    value: parts.dialCode + parts.phoneNumber,
                },
            },
        ]);

        // Accessibility
        if (this.dropdown.control) {
            this.dropdown.control.setAttribute(
                'aria-label',
                UIPhoneField.labels.CHOOSE_COUNTRY
            );
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.dropdown.addEventListener(
            'change',
            this.handleDropdownChange.bind(this)
        );
        this.control.addEventListener(
            'input',
            this.handleValueChange.bind(this)
        );

        // Force ignore validation for native select.
        requestAnimationFrame(() => {
            if (this.dropdown.nativeSelect) {
                this.dropdown.nativeSelect.dataset.novalidate = 'true';
            }
        });
    }
}

UIPhoneField.setRules('default');
UIPhoneField.setCountriesData(countries[UIElement.LANGUAGE]);

UIPhoneField.defineElement('ui-phonefield', styles);
export { UIPhoneField };

import { UIElement } from '../ui-element.js';
import styles from './ui-layout-head.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UILayoutHead
 * @element ui-layout-head
 * @classdesc Represents a class for <code>ui-layout-head</code> element.
 * @example
 * <ui-layout-head>
 *     <h1>Page title</h1>
 * </ui-layout-head>
 */
class UILayoutHead extends UIElement {
    /**
     * @inheritDoc
     */
    render() {}
}
UILayoutHead.defineElement('ui-layout-head', styles);
export { UILayoutHead };

import { UIElement } from '../ui-element.js';
import { FormValidator } from '../../global/form-validator.js';
import { getData, setData } from '../../global/ui-helpers.js';
import styles from './ui-form.css';
import { validationLabels } from './validation-labels.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIForm
 * @element ui-form
 * @classdesc Represents a class for <code>ui-form</code> element.
 * Wrapper for native HTML <b>&lt;form></b> which extends form
 * native form functionality.
 * @fires event:form-update
 * @listens event:reset
 * @property {("default" | "inline" | "fluid")} layout {@attr layout} Layout of the ui-form.
 *  {@desc default}
 *  {@desc inline}
 *  {@desc fluid}
 * @property {string} form {@attr form} ID of the native HTML form that
 * ui-form will be linked with.
 * @property {string} novalidate {@attr novalidate} Same as 'novalidate in native form'.
 * @slot
 * @example <ui-form></ui-form>
 */
class UIForm extends UIElement {
    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get validationLabels() {
        return validationLabels;
    }

    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['layout'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: String,
                form: String,
            },
        };
    }

    /**
     * Finds or creates form element.
     * @returns {HTMLFormElement}
     */
    getFormElement() {
        let externalForm;
        if (this.form) {
            externalForm = document.getElementById(this.form);
        }
        return externalForm
            ? externalForm
            : this.querySelector('form') || this.createFormElement();
    }

    /**
     * Finds or creates form element.
     * @returns {FormData}
     */
    getFormData() {
        return new FormData(this.getFormElement());
    }

    /**
     * Serializes form and returns data as an object.
     * Invert procedure is setDate().
     * @returns {Record<string, string>}
     */
    getData() {
        return getData(this.getFormElement());
    }

    /**
     * Sets the date to form from object. Invert procedure is getDate().
     * @param {object} data
     * @fires event:form-update
     * @returns {UIForm}
     */
    setData(data) {
        setData(this.getFormElement(), data);
        return this;
    }

    /**
     * Finds input group either matched by selector or is parent
     * of element matched by selector.
     * @param {string} selector
     * @returns {UIField}
     */
    getFieldGroup(selector) {
        const foundElem = this.querySelector(selector);
        return foundElem ? foundElem.closest('ui-field') : null;
    }

    /**
     * Finds input group either matched by element or is parent of
     * element matched by selector.
     * @param {HTMLElement} element
     * @returns {HTMLElement}
     */
    getFieldGroupByElement(element) {
        return element.closest('ui-field');
    }

    /**
     * Finds or creates a message box.
     * @returns {UIMessageBox}
     */
    getMessageBox() {
        return this.querySelector('ui-messagebox') || this.createMessageBox();
    }

    /**
     * Renders child nodes and creates a form element.
     * @returns {HTMLFormElement}
     */
    createFormElement() {
        const currentForm = this.querySelector('form');
        if (currentForm) {
            return currentForm;
        }

        const formElement = this.createElement({
            tagName: 'form',
            attributes: {
                method: this.getAttribute('method') || 'get',
                autocomplete: this.getAttribute('autocomplete') || 'off',
                action: this.getAttribute('action') || '#',
                novalidate: this.getAttribute('novalidate') || null,
            },
            children: this.childNodes,
        });
        this.appendChild(formElement);
        return formElement;
    }

    /**
     * Creates message box.
     * @returns {UIMessageBox}
     */
    createMessageBox() {
        const form = this.getFormElement();
        const box = document.createElement('ui-messagebox');
        form.insertBefore(box, form.firstChild);
        return box;
    }

    /**
     * Show target field group matched by selector.
     * @param {string} selector
     * @returns {UIForm}
     */
    showFieldGroup(selector) {
        const group = this.getFieldGroup(selector);
        if (group) {
            group.show();
        }
        return this;
    }

    /**
     * Hide target field group matched by selector.
     * @param {string} selector
     * @returns {UIForm}
     */
    hideFieldGroup(selector) {
        const group = this.getFieldGroup(selector);
        if (group) {
            group.hide();
        }
        return this;
    }

    /**
     * Unify layout type for all input group.
     */
    onLayoutUpdated() {
        if (!this.layout) {
            return;
        }

        this.querySelectorAll('ui-field, ui-buttonbar').forEach((node) =>
            node.setAttribute('layout', this.layout)
        );
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'layout':
                this.onLayoutUpdated();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * Get all elements which could be validated.
     * @returns {HTMLFormControlsCollection}
     */
    getAllFormControls() {
        return this.getFormElement().elements;
    }

    /**
     * Get submit control like button or input.
     * @returns {HTMLButtonElement | HTMLInputElement}
     */
    getSubmitControl() {
        return this.getFormElement().querySelector('[type="submit"]');
    }

    /**
     * Attaches validator to current form.
     * @param {FormValidator} ValidatorClass
     */
    setValidator(ValidatorClass) {
        this._validator = new ValidatorClass(this.getFormElement());
    }

    /**
     * Gets the validator object.
     * @returns {FormValidator}
     */
    getValidator() {
        return this._validator;
    }

    /**
     * Removes validator from form.
     */
    removeValidator() {
        this._validator = null;
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!this.form) {
            this.createFormElement();
        }
        this.onLayoutUpdated();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.setValidator(FormValidator);
        this.addEventListener('reset', () => {
            [].forEach.call(this.querySelectorAll('ui-field'), (field) => {
                field.removeErrors();
            });
        });
    }
}

UIForm.defineElement('ui-form', styles);
export { UIForm };

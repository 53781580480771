import { UIElement } from '../ui-element.js';
import styles from './ui-steps.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UISteps
 * @element ui-steps
 * @classdesc Represents a class for <code>ui-steps</code> element.
 * Draws steps horizontally in desktop, vertically in mobile.
 * @property {number} [activeStep] {@attr active-step} Which step is activated.
 * @property {NodeList<UIStep>} steps {@readonly} Shortcut to ui-steps collection.
 * @slot {@type "ui-step"}
 * @example
 * <ui-steps>
 *    <ui-step label="First step"></ui-step>
 *    <ui-step label="Second step"></ui-step>
 *    <ui-step label="Finished step" completed="true"></ui-step>
 * </ui-steps>
 */
class UISteps extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                activeStep: { type: Number, default: 0 },
            },
            children: {
                steps: {
                    selector: 'ui-step',
                    multiple: true,
                },
            },
        };
    }

    /**
     * Sets active step by selectedIndex if previously no active steps set.
     */
    activateStep() {
        const activeSteps = this.queryChildren('ui-step[active]');
        if (activeSteps.length > 0) {
            return;
        }
        const step = this.steps[this.activeStep];
        if (step) {
            step.active = true;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'list');
        this.updateElement({
            classList: {
                'ui-steps__list': true,
            },
            children: this.detachChildNodes(),
        });
        this.activateStep();
    }
}

UISteps.defineElement('ui-steps', styles);
export { UISteps };

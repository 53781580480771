import { eventBus } from '../../global/event-bus.js';
import { UIAnnotation } from '../../components/annotation/ui-annotation.js';
import { isVisible } from '../../global/ui-helpers.js';

/**
 * @classdesc Plugin for UIAnnotation to fix overlapping while menu is active.
 * @alias UIAnnotationOverlap
 * @implements {ComponentPlugin}
 */
class UIAnnotationOverlap {
    /**
     * @param {UIAnnotation} target
     */
    constructor(target) {
        this.annotation = target;
        this.mainNav = document.getElementById('nav-main');
        // If not main navigation silent early exit to do not spam console.
        if (!this.mainNav) {
            return;
        }
        eventBus.addEventListener(
            'menu-open',
            this.handleAnnotationsOverlap.bind(this)
        );
        eventBus.addEventListener(
            'menu-close',
            this.handleAnnotationsOverlap.bind(this)
        );
    }

    /**
     * @param {CustomEvent} event
     * @private
     */
    handleAnnotationsOverlap(event) {
        if (!this.mainNav.isDesktopMode()) {
            return;
        }
        if (event.type === 'menu-open') {
            if (!this.isInsideMainNav()) {
                const z = getComputedStyle(
                    this.mainNav.secondaryColumns
                ).zIndex;
                if (isVisible(this.annotation)) {
                    this.annotation.lock();
                    this.annotation.damp(z);
                }
            }
        } else if (event.type === 'menu-close') {
            this.annotation.unlock();
            this.annotation.reveal();
            if (!this.isInsideMainNav()) {
                this.annotation.showAnnotation(
                    this.annotation.constructor.MIN_DELAY,
                    this.annotation.constructor.MAX_DELAY
                );
            }
        }
    }

    /**
     * @returns {boolean}
     */
    isInsideMainNav() {
        return this.mainNav.contains(this.annotation.annotatedElement);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.annotation.popoverElement.addEventListener('click', (e) => {
            // Conditions can be even more complex.
            const hold =
                e.target.localName === 'div' ||
                !!e.target.closest('.ui-annotation__close');
            if (this.mainNav.dropdown && hold) {
                e.preventDefault();
            }
        });
    }
}

UIAnnotation.registerPlugin('overlap', UIAnnotationOverlap, true);

export { UIAnnotationOverlap };

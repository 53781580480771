/**
 * @type {Record<string, MessageType>}
 */
export const MessageType = {
    Navigation: 'NAVIGATION',
    Initial: 'INITIAL',
    Click: 'CLICK',
    HeightChanged: 'HEIGHT_CHANGED',
    LayoutUpdate: 'LAYOUT_UPDATE',
    PopoverUpdate: 'POPOVER_UPDATE',
    OverlayOpened: 'OVERLAY_OPENED',
    OverlayClosed: 'OVERLAY_CLOSED',
};

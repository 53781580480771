import { UIElement } from '../ui-element.js';
import { setInnerText } from '../../global/ui-helpers.js';
import styles from './ui-hint.css';

/**
 * @memberof SharedComponents
 * @element ui-hint
 * @augments {UIElement}
 * @alias UIHint
 * @element ui-hint
 * @classdesc Represents a class for <code>ui-hint</code> element.
 * Hint is usually used inside ui-field to provide a help hint for field.
 * @property {("error" | "info")} type {@attr type} Type of the hint.
 *  {@desc error}
 *  {@desc info}
 * @property {string} errorType {@attr error-type} Error for validity type, usually you never
 * should add this manually.
 * @slot
 * @example
 * <ui-hint>Hello world I am hint.</ui-hint>
 * @example
 * <ui-hint type="error">Hint error</ui-hint>
 */
class UIHint extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                errorType: String,
            },
        };
    }

    /**
     * @type {{ERROR: string, INFO: string}}
     */
    static get types() {
        return {
            INFO: 'info',
            ERROR: 'error',
        };
    }

    /**
     * Sets message content for hint.
     * @param {string} message
     * @returns {UIHint}
     */
    setMessage(message) {
        setInnerText(this, message);
        return this;
    }
}

UIHint.defineElement('ui-hint', styles);
export { UIHint };

import { UIElement } from '../ui-element.js';
import { BrandColors, isBrandColor } from '../../global/helpers-marketing.js';
import { insertElements } from '../../global/render-api.js';
import styles from './ui-shape.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIShape
 * @element ui-shape
 * @classdesc Represents a class for <code>ui-shape</code> element.
 * Marketing elements to use shapes in offer.
 * @property {UIBrandColor} [color="lilac"] {@attr color} Color of the shape.
 * @property {UIShapeLayout} [layout="circle"] {@attr layout} Layout for ui-shape.
 * @property {boolean} [animated] {@attr animated} Rotating text in shape.
 * Texts should be inside &lt;ul> tag.
 * @slot
 * @example
 * <ui-shape layout="circle" color="lilac">
 *   Hello, Shape!
 * </ui-shape>
 */
class UIShape extends UIElement {
    /**
     * @type {UIShapeLayouts}
     */
    static get Layout() {
        return {
            Circle: 'circle',
            Speech: 'speech',
            SpeechSquare: 'speech-square',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                animated: Boolean,
                color: {
                    type: String,
                    default: BrandColors.Lilac,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        const info = `Allowed options are: turquoise, lilac, pineapple, orange.`;
                        if (
                            Object.values(BrandColors).some((v) =>
                                ['yellow', 'blue'].includes(v)
                            )
                        ) {
                            console.warn(
                                `${value} - color is deprecated. ${info}`,
                                component
                            );
                            return;
                        }
                        if (!isBrandColor(value)) {
                            console.warn(
                                `${value} - value does not exist. ${info}`,
                                component
                            );
                        }
                    },
                },
                layout: {
                    type: String,
                    default: this.Layout.Circle,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        if (value === 'round') {
                            const info = `Use 'circle' layout instead`;
                            console.warn(
                                `${value}} - layout is deprecated: ${info}`,
                                component
                            );
                        }
                    },
                },
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        insertElements(this, [
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-shape__content',
                },
                children: this.childNodes,
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (this.animated) {
            this.setAttribute('aria-live', 'polite');
            const items = this.querySelectorAll('li');
            if (items.length > 0) {
                let i = 1;
                items[0].classList.add('-animated');

                [].forEach.call(items, (item) => {
                    item.addEventListener('animationend', () => {
                        item.classList.remove('-animated');
                        items[i++].classList.add('-animated');
                        if (i > items.length - 1) {
                            i = 0;
                        }
                    });
                });
            }
        }
    }
}

UIShape.defineElement('ui-shape', styles);
export { UIShape };

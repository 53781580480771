import { UIElement } from '../ui-element.js';
import styles from './ui-table.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UITable
 * @element ui-table
 * @classdesc Represents a class for <code>ui-table</code> element. Wrapper
 * form <b>&lt;table></b> element. Transforms tables for smaller viewports
 * and add different functionality via plugins. For examples see
 * <a href="guidelines-tables.html">tables examples</a>.
 * @property {HTMLTableElement} table {@readonly} Shortcut to table element.
 * @property {boolean} [compact=false] Forces to hide &lt;thead>. Only works with
 * 'sortable' plugin.
 * @param {string} label {@attr label} Label for sorting. Only works with
 * 'sortable' plugin.
 * @property {string} labelAdd {@attr label-add} Label for add row. Only works
 * with 'cloneable' plugin.
 * @property {string} labelRemove {@attr label-remove} Label for remove row. Only
 * works with 'cloneable' plugin.
 * @property {UITablePluginType} plugins {@attr plugins}
 * @fires event:tablesort
 * @slot {@type "table"}
 * @example
 * <ui-table>
 *   <table>
 *     <thead>
 *       <tr>
 *         <th>Title 1</th>
 *         <th>Title 2</th>
 *       </tr>
 *     </thead>
 *     <tbody>
 *       <tr>
 *         <td>Cell 1</td>
 *         <td>Cell 2</td>
 *       </tr>
 *     </tbody>
 *   </table>
 * </ui-table>
 */
class UITable extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            children: {
                table: 'table',
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!this.table) {
            this.appendChild(
                this.createElement({
                    tagName: 'table',
                })
            );
        }
        this.table.classList.add('table');
    }
}

UITable.defineElement('ui-table', styles);
export { UITable };

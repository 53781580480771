import { UITable } from '../../components/table/ui-table.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table sticky headers plugin.
 * @alias UITableSticky
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target
 */
class UITableSticky {
    constructor(target) {
        this.target = target;
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-sticky');
    }

    /**
     * @inheritDoc
     */
    hydrate() {}
}

UITable.registerPlugin('sticky', UITableSticky);

export { UITableSticky };

import '../icon/ui-icon.js';
import { UIElement } from '../ui-element.js';
import { browser } from '../../global/browser.js';
import { Labels } from '../../global/labels.js';
import { throttle, scrollTo } from '../../global/helpers.js';
import {
    getFirstFocusableElement,
    updateElement,
    mutateTo,
} from '../../global/ui-helpers.js';
import styles from './ui-anchor.css';
import { TabIndex } from '../../global/keyboard.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAnchor
 * @element ui-anchor
 * @classdesc Represents a class for <code>ui-anchor</code> element.
 * Scrolls to element.
 * @fires event:scroll
 * @fires event:visibility
 * @property {string} [placement="bottom-right"] {@attr placement} Position for arrow.
 *   {@desc top-left}
 *   {@desc top-middle}
 *   {@desc top-right}
 *   {@desc bottom-left}
 *   {@desc bottom-middle}
 *   {@desc bottom-right}
 * @property {string} [for] {@attr for} Scrolls to element id or top if not supplied.
 * @property {string} [direction="top"] {@attr direction} Glyph icon direction
 *  {@desc top}
 *  {@desc bottom}
 *  {@desc right}
 *  {@desc left}
 * @property {HTMLButtonElement} button {@readonly} Shortcut to anchor button.
 * @property {HTMLButtonElement} icon {@readonly} Shortcut to ui-icon.
 * @example
 * <ui-anchor placement="bottom-right"></ui-anchor>
 */
class UIAnchor extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                placement: { type: String, default: 'bottom-right' },
                direction: { type: String, default: 'top' },
                for: String,
            },
            children: {
                button: 'button',
                icon: 'ui-icon',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-anchor', {
            top: 'Scroll to the top of the page',
            bottom: 'Scroll to the bottom of the page',
            left: 'Scroll to the left',
            right: 'Scroll to the right',
        });
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['direction'];
    }

    /**
     * @type {boolean}
     * @private
     */
    get supportsIntersectionObserver() {
        return browser.supportsIntersectionObserver();
    }

    /**
     * Glyph direction mapping.
     * @type {{top: string, left: string, bottom: string, right: string}}
     */
    static get glyphDirection() {
        return {
            top: 'up',
            bottom: 'down',
            right: 'right',
            left: 'left',
        };
    }

    /**
     * Focuses scroll target.
     * @param {number | HTMLElement} target
     */
    static focusScrollTarget(target) {
        /** @type {HTMLElement} */
        if (target instanceof HTMLElement) {
            const previousTabIndex = target.tabIndex;
            target.tabIndex = TabIndex.Inactive;
            target.focus();
            target.tabIndex = previousTabIndex;
        } else {
            const targetToFocus = getFirstFocusableElement(document.body);
            if (targetToFocus) {
                targetToFocus.focus();
            }
        }
    }

    /**
     * Gets the position of an element relative to the document
     * @param {HTMLElement} element
     * @returns {{left: number, top: number}}
     */
    static getElementOffset(element) {
        const rect = element.getBoundingClientRect();
        return {
            left: rect.left + window.scrollX,
            top: rect.top + window.scrollY,
        };
    }

    /**
     * Finds object key by value.
     * @param {object} object
     * @param {string} value
     * @returns {string}
     */
    static getKeyByValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }

    /**
     * Gets scroll target
     * @param {string} [elementId]
     * @returns {HTMLElement | number}
     */
    static getScrollTarget(elementId) {
        let element;
        if (elementId) {
            element = document.getElementById(elementId);
        }
        return element || 0;
    }

    /**
     * Handles click event for button.
     * @private
     */
    async handleClickEvent() {
        await this.scrollToElement(this.for);
    }

    /**
     * Handles intersection observer entries.
     * @param {Array<IntersectionObserverEntry>} entries
     * @private
     */
    handleIntersectionEntries(entries) {
        entries.forEach((/** IntersectionObserverEntry*/ entry) => {
            this.toggleVisibility(entry.isIntersecting);
        });
    }

    /**
     * Handles scroll listener event.
     * @private
     */
    handleScrollEvent() {
        this.toggleVisibility(window.scrollY < window.innerHeight);
    }

    /**
     * Scrolls to element.
     * @param {string} [elementId]
     * @returns {Promise<void>}
     */
    scrollToElement(elementId) {
        this.dispatchCustomEvent('scroll', { completed: false });
        const target = UIAnchor.getScrollTarget(elementId);
        return scrollTo(target).then(() => {
            UIAnchor.focusScrollTarget(target);
            this.dispatchCustomEvent('scroll', { completed: true });
        });
    }

    /**
     * Toggles component visibility.
     * @param {boolean} [visible]
     */
    toggleVisibility(visible) {
        const intersecting = '-intersecting';
        const shouldToggle = this.classList.contains(intersecting) !== visible;
        if (shouldToggle) {
            visible
                ? this.classList.add(intersecting)
                : this.classList.remove(intersecting);
            this.dispatchCustomEvent('visibility', { visible: visible });
        }
    }

    /**
     * Updates icon
     */
    updateIcon() {
        const element = this.renderIcon();
        if (element.glyph !== this.icon.glyph) {
            mutateTo(this.icon, element, true);
        }
    }

    /**
     * Renders button
     * @param {UIIcon} icon
     * @returns {HTMLButtonElement}
     */
    renderButton(icon) {
        const glyph =
            UIAnchor.glyphDirection[this.direction] ||
            UIAnchor.glyphDirection.top;
        const labelDirection = UIAnchor.getKeyByValue(
            UIAnchor.glyphDirection,
            glyph
        );
        return this.createElement({
            tagName: 'button',
            attributes: {
                'aria-label': UIAnchor.labels[labelDirection],
            },
            classList: {
                '-iconed': true,
            },
            children: [icon],
        });
    }

    /**
     * Renders icon
     * @returns {UIIcon}
     */
    renderIcon() {
        const glyph =
            UIAnchor.glyphDirection[this.direction] ||
            UIAnchor.glyphDirection.top;
        return this.createElement({
            tagName: 'ui-icon',
            attributes: {
                glyph: glyph,
            },
            classList: {
                '-navicon': true,
                'ui-anchor__icon': true,
            },
        });
    }

    /**
     * Sets component top offset
     * @returns {UIAnchor}
     */
    setTopOffset() {
        if (this.for) {
            const target = UIAnchor.getScrollTarget(this.for);
            if (target instanceof HTMLElement) {
                const offset = UIAnchor.getElementOffset(target);
                this.style.top = offset.top + 'px';
            }
        }
        return this;
    }

    /**
     * Subscribes to events
     * @returns {UIAnchor}
     */
    subscribeEvents() {
        updateElement(this.button, {
            events: {
                click: this.handleClickEvent.bind(this),
            },
        });

        if (this.supportsIntersectionObserver) {
            this.intersectionObserver = new IntersectionObserver(
                this.handleIntersectionEntries.bind(this)
            );
            this.intersectionObserver.observe(this);
        } else {
            // For old browsers
            this.throttledScroll = throttle(
                this.handleScrollEvent.bind(this),
                300
            );
            window.addEventListener('scroll', this.throttledScroll);
            this.handleScrollEvent();
        }
        return this;
    }

    /**
     * @inheritDoc
     */
    render() {
        const icon = this.renderIcon();
        const button = this.renderButton(icon);
        this.insertElements([button]);
        this.updateElement({
            classList: { '-intersecting': true },
            attributes: {
                placement: this.placement,
                direction: this.direction,
            },
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.setTopOffset().subscribeEvents();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        if (this.supportsIntersectionObserver) {
            this.intersectionObserver && this.intersectionObserver.disconnect();
        } else {
            window.removeEventListener('scroll', this.throttledScroll);
        }
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        if (this.supportsIntersectionObserver) {
            this.intersectionObserver.observe(this);
        } else {
            window.addEventListener('scroll', this.throttledScroll);
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }

        switch (name) {
            case 'direction':
                this.updateIcon();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }
}

UIAnchor.defineElement('ui-anchor', styles);
export { UIAnchor };

import { UIElement } from '../ui-element.js';
import styles from './ui-asset.css';
import { getChildrenForSlot } from '../../global/ui-helpers.js';
import { element } from '../../global/template-literals.js';
import { keyCodes } from '../../global/keyboard.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAsset
 * @element ui-asset
 * @classdesc Represents a class for <code>ui-asset</code> element.
 * @property {string} [label] {@attr label} Main label of the asset.
 * @property {string} [labelSecondary] {@attr label-secondary} Secondary label of the asset.
 * @property {string} [valueSecondary] {@attr value-secondary} Secondary value of the asset.
 * @property {boolean} [interactiveValue=false] - If value slot is interactive element.
 * @property {("default"  | "investment" )} [type="default"] {@attr type} Type of the asset.
 *  {@desc default}
 *  {@desc investment}
 *  @property {("neutral" | "success" | "danger")} [theme="neutral"]
 *  {@attr theme} Theme of the asset.
 *  {@desc neutral}
 *  {@desc success}
 *  {@desc danger}
 *   @property {HTMLDivElement} contentElement {@readonly} Content element.
 *  @property {HTMLDivElement} valueElement {@readonly} Main value element.
 *  @property {HTMLDivElement} valueSecondaryElement {@readonly} Secondary value element.
 *  @slot {@name figure} Figure of the asset.
 *  @slot {@name badge} Badge of the asset.
 *  @slot {@name value} Main value of the asset.
 * @example
 * <ui-asset
 *   label="Main account"
 *   label-secondary="EE345678909870901345"
 * >
 *   <ui-badge slot="badge" layout="pin" color="pineapple">Default</ui-badge>
 *   <span slot="value"><ui-currency value="1200.48"></ui-currency> EUR</span>
 * </ui-asset>
 */

class UIAsset extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: String,
                labelSecondary: String,
                valueSecondary: String,
                interactiveValue: Boolean,
                type: { type: String, default: UIAsset.type.Default },
                theme: { type: String, default: UIAsset.theme.Neutral },
            },
            children: {
                contentElement: '.ui-asset__content',
                valueElement: '.ui-asset__value',
                valueSecondaryElement: '.ui-asset__value-secondary',
            },
        };
    }

    /**
     * @returns {Array<string>}
     * @readonly
     */
    static get observedAttributes() {
        return ['value-secondary', 'theme'];
    }

    /**
     * Asset type.
     * @type {{Default: string, Investment: string}}
     * @readonly
     */
    static get type() {
        return {
            Default: 'default',
            Investment: 'investment',
        };
    }

    /**
     * Asset theme.
     * @type {{Neutral: string, Success: string, Danger: string}}
     * @readonly
     */
    static get theme() {
        return {
            Neutral: 'neutral',
            Success: 'success',
            Danger: 'danger',
        };
    }

    /**
     * Set element click, enter/space callback.
     * @param {Function} onClickCallback function is called whenever the asset
     * click or keyboard enter/space event is fired.
     */
    setOnClickCallback(onClickCallback) {
        if (typeof onClickCallback === 'function') {
            this.onClick = onClickCallback;
        }
    }

    /**
     * Extract asset figure element.
     * @returns {HTMLElement | undefined}
     * @private
     */
    getFigure() {
        const figureSlotNodes = getChildrenForSlot(this, 'figure');
        const iconElementNodes = this.querySelectorAll('ui-icon');
        const imageElementNodes = this.querySelectorAll('img');

        iconElementNodes.forEach((iconElementNode) => {
            iconElementNode.remove();
        });

        imageElementNodes.forEach((imageElementNode) => {
            imageElementNode.remove();
        });

        return (
            figureSlotNodes[0] || iconElementNodes[0] || imageElementNodes[0]
        );
    }

    /**
     * Extract asset badge element.
     * @private
     * @returns {HTMLElement | undefined}
     */
    getBadge() {
        const badgeSlotNodes = getChildrenForSlot(this, 'badge');
        const badgeElementNodes = this.querySelectorAll('ui-badge');

        badgeElementNodes.forEach((badgeElementNode) => {
            badgeElementNode.remove();
        });

        return badgeSlotNodes[0] || badgeElementNodes[0];
    }

    /**
     * Handles asset click.
     * @private
     * @param {Event} event
     */
    handleClick(event) {
        if (this.valueElement.contains(event.target)) {
            return;
        }

        this.onClick?.();
    }

    /**
     * Handles enter/space keyboard event.
     * @private
     * @param {KeyboardEvent} event
     */
    handleKeyboard(event) {
        [keyCodes.ENTER, keyCodes.SPACE].includes(event.keyCode) &&
            this.clickHandler(event);
    }

    /**
     * Util for connecting event handlers.
     * @private
     */
    addEventListeners() {
        this.contentElement.addEventListener('click', this.clickHandler);
        this.contentElement.addEventListener('keydown', this.keyboardHandler);
    }

    /**
     * Util for disconnecting event handlers.
     * @private
     */
    removeEventListeners() {
        this.contentElement.removeEventListener('click', this.clickHandler);
        this.contentElement.removeEventListener(
            'keydown',
            this.keyboardHandler
        );
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }

        if (name.includes('theme')) {
            this.setAttribute('theme', newValue);
        } else {
            this.valueSecondaryElement.innerText = newValue;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const value = getChildrenForSlot(this, 'value')?.[0] || '';
        const badge = this.getBadge();
        // Figure extractor function needs to run after the value slot extraction
        // as there can be ui-icon/img element for value as well.
        const figure = this.getFigure();

        const attributes = {};
        if (this.type === UIAsset.type.Default) {
            attributes.type = UIAsset.type.Default;
        }

        if (this.theme === UIAsset.theme.Neutral) {
            attributes.theme = UIAsset.theme.Neutral;
        }

        this.setAttributes(attributes);

        const renderInvestmentArrowIcon =
            this.type === UIAsset.type.Investment &&
            [UIAsset.theme.Success, UIAsset.theme.Danger].includes(this.theme);

        this.append(
            element`
        <div class="ui-asset__content" role="button" tabindex="0">
          ${
              figure &&
              element`
            <div class="ui-asset__figure-wrapper">
              ${figure}
            </div>
          `
          }
          <div class="ui-asset__labels-wrapper">
            <div class="ui-asset__label-wrapper">
              <div class="ui-asset__label">
                ${this.label}
              </div>
              ${
                  badge &&
                  element`
                <div class="ui-asset__badge-wrapper">
                  ${badge}
                </div>
              `
              }
            </div>
            <div class="ui-asset__label-secondary">
              ${this.labelSecondary}
            </div>
          </div>
          <div class="ui-asset__values-wrapper">
            <div class="ui-asset__value">
              ${value}
            </div>
            <div class="ui-asset__value-secondary-wrapper">
              ${
                  renderInvestmentArrowIcon &&
                  element`
                <ui-icon glyph="dropdown-arrow-down" size="small"></ui-icon>
              `
              }
              <span class="ui-asset__value-secondary">
                ${this.valueSecondary}
              </span>
            </span>
          </div>
        </div>
    `
        );
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.clickHandler = this.handleClick.bind(this);
        this.keyboardHandler = this.handleKeyboard.bind(this);
        this.addEventListeners();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.removeEventListeners();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.addEventListeners();
    }
}

UIAsset.defineElement('ui-asset', styles);
export { UIAsset };

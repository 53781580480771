import { UIElement } from '../ui-element.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import styles from './ui-badge.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIBadge
 * @element ui-badge
 * @classdesc Represents a class for <code>ui-badge</code> element.
 * Small badge in the corner for advertising purposes.
 * @property {UIBadgeLayout} [layout] {@attr layout} Shape of the badge.
 * @property {UIBrandColor} [color="turquoise"] {@attr color} Color of the badge.
 * @slot
 * @example
 * <ui-badge color="turquoise">-50%</ui-badge>
 */
class UIBadge extends UIElement {
    /**
     * @type {UIBadgeLayouts}
     */
    static get Layout() {
        return {
            Pin: 'pin',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                color: {
                    type: String,
                    default: BrandColors.Turquoise,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        const info =
                            'Allowed options are: turquoise, lilac, pineapple, orange, bark.';
                        if (['yellow', 'blue'].includes(value)) {
                            console.warn(
                                `${value} - color is deprecated. ${info}`,
                                component
                            );
                            return;
                        }
                        if (
                            !Object.values(BrandColors).find((v) => v === value)
                        ) {
                            console.warn(
                                `${value} - value does not exist. ${info}`,
                                component
                            );
                        }
                    },
                },
                layout: {
                    type: String,
                    default: UIBadge.Layout.Pin,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        if (
                            !Object.values(UIBadge.Layout).find(
                                (v) => v === value
                            )
                        ) {
                            console.warn(
                                `${value} - value does not exist. ` +
                                    `Allowed options are: pin.`,
                                component
                            );
                        }
                    },
                },
            },
        };
    }
}

UIBadge.defineElement('ui-badge', styles);

export { UIBadge };

import { UIElement } from '../ui-element.js';
import styles from './ui-described-control.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIDescribedControl
 * @element ui-described-control
 * @classdesc Represents a class for <code>ui-described-control</code> element.
 * Control for visual presenting checkbox label with inline ui-tooltip right after.
 * @slot
 * @example
 * <ui-described-control>
 *   <input></input>
 *   <label></label>
 *   <ui-tooltip></ui-tooltip>
 * </ui-described-control>
 */
class UIDescribedControl extends UIElement {
    /**
     * @inheritDoc
     */
    render() {
        const childNodes = this.detachChildNodes();
        const label = childNodes
            .filter((node) => {
                return node.tagName && node.tagName.toLowerCase() === 'label';
            })
            .shift();

        if (label) {
            label.innerHTML += '&zwj;';
        }

        this.updateElement({
            children: [
                {
                    tagName: 'span',
                    classList: {
                        'ui-described-control__target': true,
                    },
                    children: [
                        childNodes
                            .filter((node) => {
                                return (
                                    node.tagName &&
                                    node.tagName.toLowerCase() === 'input'
                                );
                            })
                            .shift(),
                        label,
                    ],
                },
                childNodes
                    .filter((node) => {
                        return (
                            node.tagName &&
                            node.tagName.toLowerCase() === 'ui-tooltip'
                        );
                    })
                    .shift(),
            ],
        });

        this.innerHTML = this.innerHTML.replace(/>\s{2,}/gm, '>');
    }
}

UIDescribedControl.defineElement('ui-described-control', styles);
export { UIDescribedControl };

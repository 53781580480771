import { UIElement } from '../ui-element.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-salesribbon.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UISalesRibbon
 * @element ui-salesribbon
 * @classdesc Represents a class for <code>ui-salesribbon</code> element.
 * Marketing element for sales ribbon.
 * @property {UIIconGlyph} [icon] {@attr icon} Product icon glyph like in ui-icon.
 * @property {UISalesRibbonIconType} [iconType="product"] {@attr icon-type}
 *  {@desc product}
 *  {@desc image}
 *  {@desc badge}
 * @property {UIBrandColor} [badgeColor="turquoise"] {@attr badge-color}
 * @slot
 * @example
 * <ui-salesribbon icon="calculator" icon-type="badge">
 *   The best offer!
 * </ui-salesribbon>
 */
class UISalesRibbon extends UIElement {
    /**
     * @type {UISalesRibbonIconTypes}
     */
    static get IconTypes() {
        return {
            Product: 'product',
            Image: 'image',
            Badge: 'badge',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                icon: String,
                iconType: {
                    type: String,
                    default: this.IconTypes.Product,
                },
                badgeColor: {
                    type: String,
                    default: BrandColors.Turquoise,
                },
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        const content = this.createElement({
            tagName: 'div',
            classList: {
                'ui-salesribbon__content': true,
            },
            children: this.childNodes,
        });
        this.appendChild(content);

        if (this.icon) {
            let iconAttributes = {};
            switch (this.iconType) {
                case this.constructor.IconTypes.Product:
                    iconAttributes = {
                        glyph: this.icon,
                        color: BrandColors.Pineapple,
                        size: 'large',
                    };
                    break;
                case this.constructor.IconTypes.Image:
                    iconAttributes = {
                        src: this.icon,
                    };
                    break;
                case this.constructor.IconTypes.Badge:
                    iconAttributes = {
                        glyph: this.icon,
                        color:
                            this.badgeColor === BrandColors.Pineapple
                                ? UIIcon.colors.BARK_70
                                : UIIcon.colors.WHITE,
                        size: 'small',
                    };
                    break;
                /* istanbul ignore next */
                default:
                    break;
            }
            const icon = this.createElement({
                tagName: 'ui-icon',
                attributes: iconAttributes,
            });
            if (this.iconType === this.constructor.IconTypes.Badge) {
                const badgeWithIcon = this.createElement({
                    tagName: 'ui-badge',
                    attributes: {
                        color: this.badgeColor,
                    },
                    children: [icon],
                });
                this.insertAdjacentElement('afterbegin', badgeWithIcon);
            } else {
                this.insertAdjacentElement('afterbegin', icon);
            }
        }
    }
}

UISalesRibbon.defineElement('ui-salesribbon', styles);
export { UISalesRibbon };

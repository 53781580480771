import { UIElement } from '../ui-element.js';
import styles from './ui-aspect.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAspect
 * @element ui-aspect
 * @classdesc Represents a class for <code>ui-ratio</code> element.
 * ui-ratio should have a nested child element to work correctly.
 * @property {string} ratio {@attr ratio} Aspect ratio in format M:N.
 * @property {boolean} centered {@attr centered} Sets the first child to centered relatively
 * ui-ratio container.
 * @slot
 * @example
 * <ui-aspect ratio="16:9">
 *   <div>Some first child element.</div>
 * </ui-aspect>
 */
class UIAspect extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                ratio: String,
                centered: Boolean,
            },
        };
    }

    /**
     * Parses the aspect ratio in format n:m. Returns empty array if invalid aspect ratio.
     * @returns {Array<number>}
     * @private
     */
    parseAspect() {
        return this.ratio.split(':').map((x) => Number(x.trim()));
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!/^([0-9]*[.])?[0-9]+:([0-9]*[.])?[0-9]+$/.test(this.ratio)) {
            console.error(
                this.constructor.name +
                    ': ratio attribute should be in format M:N.'
            );
            return;
        }
        const ratio = this.parseAspect();

        // Check for old browsers
        if (CSS && CSS.supports('aspect-ratio: 1 / 1')) {
            this.style.aspectRatio = ratio.join(' / ');
        } else {
            const r = (ratio[1] / ratio[0]) * 100;
            if (Number.isFinite(r)) {
                this.style.paddingTop = r + '%';
            } else {
                console.error(this.constructor.name + ': division by zero.');
            }
        }
    }
}

UIAspect.defineElement('ui-aspect', styles);
export { UIAspect };

import { Labels } from '../global/labels.js';
/**
 * @memberof SharedComponents
 * @alias UITemplate
 */
class UITemplate {
    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return {};
    }

    /**
     * @param {*} args
     * @returns {DocumentFragment}
     */
    static render(...args) {
        const instance = new this(...args);
        return instance.render();
    }

    constructor(data) {
        this._data = data;
    }

    /**
     * @param {string} key
     * @param {number|Array<string>|Record<string, string>} [substitutions]
     * @returns {string}
     */
    getLabel(key, substitutions) {
        return Labels.processLabel(this.constructor.labels, key, substitutions);
    }

    /**
     * @returns {DocumentFragment|HTMLElement}
     */
    render() {
        return new DocumentFragment();
    }
}

export { UITemplate };

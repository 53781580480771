import { Digest } from '../../global/digest.js';
import {
    appendModalControllerIfRequired,
    makePopoverHandler,
} from '../../global/helpers.js';
import { Labels } from '../../global/labels.js';
import { createElement } from '../../global/render-api.js';
import { UIElement } from '../ui-element.js';
import { html, element } from '../../global/template-literals.js';
import { hide, show } from '../../global/ui-helpers.js';
import styles from './ui-chip.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIChip
 * @element ui-chip
 * @classdesc Represents a class for <code>ui-chip</code> element.
 * Chips are used for filtering and invoking actions.
 * @fires popover-open
 * @fires popover-close
 * @property {string} label {@attr label} Label of the chip.
 * @property {UIIconGlyph} [glyph] {@attr glyph} Glyph of the chip.
 * @property {("filter" | "button" | "popover")} [layout="filter"] {@attr layout}
 * Layout of the chip.
 * @property {boolean} [expanded] {@attr expanded} Indicates if popover is expanded.
 * @property {HTMLInputElement} control {@readonly} Control element.
 * @slot
 * @example
 * <ui-chip label="Chip"></ui-chip>
 * @example
 * <ui-chip label="Radio chip">
 *   <input type="radio" name="radio-chip-group">
 * </ui-chip>
 * @example
 * <ui-chip label="Button" layout="button"></ui-chip>
 * @example
 * <ui-chip label="Popover" layout="popover">
 *   <template>Popover content</template>
 * </ui-chip>
 */
class UIChip extends UIElement {
    constructor() {
        super();
        this.onControlClick = this.onControlClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    /**
     * Localization labels.
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-chip', {
            close: 'Close',
        });
    }

    /**
     * Layouts of the chip.
     * @returns {{FILTER: string, BUTTON: string, POPOVER: string}}
     */
    static get layouts() {
        return {
            FILTER: 'filter',
            BUTTON: 'button',
            POPOVER: 'popover',
        };
    }

    /**
     * @returns {IProps}
     */
    static get props() {
        return {
            attributes: {
                label: String,
                glyph: String,
                layout: { type: String, default: UIChip.layouts.FILTER },
                expanded: Boolean,
            },
            children: {
                control: '.ui-chip__control',
            },
        };
    }

    /**
     * @inheritDoc
     */
    static get observedAttributes() {
        return ['layout', 'label', 'expanded', 'glyph'];
    }

    static get VERTICAL_OFFSET() {
        return 5;
    }

    get hasPopover() {
        return this.popoverElement != null;
    }

    get popoverElement() {
        if (this._detachedPopover) {
            return this._detachedPopover;
        }
        return this.querySelector('.ui-chip__popover');
    }

    get template() {
        const input = this.querySelector(':scope > input, :scope > button');
        const control = createElement({
            element: input || this.buildControlElement(),
            classList: {
                'ui-chip__control': true,
            },
        });
        const controlId = control.id;
        if (!controlId) {
            control.id = Digest.randomId();
        }

        return html`
            ${control}
            <label
                for="${control.id}"
                class="ui-chip__label"
            >
                <span class="ui-chip__label-wrapper">
                    ${this.glyph &&
                    html`<ui-icon glyph="${this.glyph}"></ui-icon>`}${this
                        .label}
                </span>
                ${this.layout === UIChip.layouts.POPOVER &&
                html` <ui-icon glyph="dropdown-arrow-down"></ui-icon> `}
            </label>
            ${this.layout === UIChip.layouts.POPOVER &&
            html`
                <div class="ui-chip__popover -hidden">
                    <div class="ui-chip__popover-header">
                        <label>${this.label}</label>
                        <button
                            class="ui-chip__popover-close -iconed"
                            aria-label="${UIChip.labels.close}"
                        >
                            <ui-icon glyph="cross"></ui-icon>
                        </button>
                    </div>
                    ${this.querySelector('.ui-chip__popover-content')}
                </div>
            `}
        `;
    }

    /**
     * Builds control element based on layout.
     * @returns {HTMLElement}
     */
    buildControlElement() {
        switch (this.layout) {
            case UIChip.layouts.FILTER:
            case UIChip.layouts.POPOVER:
                return element`<input type="checkbox" />`;
            case UIChip.layouts.BUTTON:
                return element`<button aria-label="${this.label}"></button>`;
            default:
                return element`<input type="checkbox" />`;
        }
    }

    /**
   Changes popover state.
   Dispatches <code>popover-open</code> and <code>popover-close</code> events.
   */
    onChangePopoverState() {
        if (this.expanded) {
            this.openDetachedPopover();
            document.addEventListener('click', this.handleClickOutside);
            this.dispatchCustomEvent('popover-open', {
                popover: this.popoverElement,
            });
        } else {
            this.closeDetachedPopover();
            document.removeEventListener('click', this.handleClickOutside);
            this.dispatchCustomEvent('popover-close', {
                popover: this.popoverElement,
            });
        }
    }

    /**
     * Opens popover.
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }
        this._detachedPopover = this.popoverElement;
        show(this.popoverElement);

        appendModalControllerIfRequired();
        this.dispatchCustomEvent('modal-open', {
            type: 'popover',
            content: this.popoverElement,
            params: {
                position: makePopoverHandler(this, {
                    popoverHeight: this.popoverElement.offsetHeight,
                    popoverWidth: this.popoverElement.offsetWidth,
                    verticalOffset: UIChip.VERTICAL_OFFSET,
                }),
                target: this,
                onOpen: () => {
                    const closeBtn = this.popoverElement.querySelector(
                        '.ui-chip__popover-close'
                    );
                    closeBtn.addEventListener('click', () => {
                        this.closePopover();
                        this.control.focus();
                    });
                },
                onClose: () => {
                    this.closePopover();
                    this.control.focus();
                },
            },
        });
    }

    /**
     * Closes popover.
     */
    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        hide(this.popoverElement);
        const modal = this._detachedPopover.closest('ui-modal');
        if (modal) {
            this._detachedPopover = null;
            modal.close();
        }
    }

    /**
     * Fires callback when user clicked outside of element.
     * @param {Event} event
     * @private
     */
    handleClickOutside(event) {
        if (
            !this.expanded ||
            this.popoverElement.contains(event.target) ||
            this.contains(event.target) ||
            event.target.closest('ui-modal')
        ) {
            return;
        }
        this.closePopover();
    }

    /**
     * Fires callback when user clicks on control.
     * @param {Event} e
     */
    onControlClick(e) {
        if (this.hasPopover) {
            e.preventDefault();
            e.stopPropagation();
            this.expanded = !this.expanded;
        }
    }

    /**
     * Closes popover.
     */
    closePopover() {
        this.expanded = false;
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return void 0;
        }

        if (name === 'expanded' && this.layout === UIChip.layouts.POPOVER) {
            this.onChangePopoverState();
        } else {
            this.rebuildChildren([this.template]);
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        if (this.layout === UIChip.layouts.POPOVER) {
            let content;
            const popoverTemplate = this.querySelector('template');
            if (popoverTemplate) {
                content = popoverTemplate.content;
                popoverTemplate.remove();
            }
            this.appendChild(
                html`<div class="ui-chip__popover-content">${content}</div>`
            );
        }

        this.rebuildChildren([this.template]);
        this.setAttribute('role', 'group');
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.control.addEventListener('click', this.onControlClick);
        if (this.hasPopover && this.expanded) {
            requestAnimationFrame(() => {
                this.onChangePopoverState();
            });
        }
    }
}

UIChip.defineElement('ui-chip', styles);
export { UIChip };

import { formatCurrencyValue } from '../../global/helpers.js';
import { UIElement } from '../ui-element.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICurrency
 * @element ui-currency
 * @classdesc Represents a class for <code>ui-currency</code> element.
 * Formats currency value.
 * @property {number} [value] {@attr value} raw value
 * @property {string} [separator] {@attr separator} separate groups of thousands
 * @property {number} [accuracy] {@attr accuracy} amount of decimals numbers after comma
 * @example
 * <ui-currency value="12345678.98"></ui-currency
 * @example
 * <ui-currency value="12345678.98" accuracy="0" separator=","></ui-currency>
 */
class UICurrency extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                value: Number,
                separator: String,
                accuracy: { type: Number, default: 2 },
            },
        };
    }

    /**
     * @inheritDoc
     */
    static get observedAttributes() {
        return ['value', 'separator', 'accuracy'];
    }

    updateContent() {
        this.setInnerText(
            formatCurrencyValue(
                this.value,
                this.separator || ' ',
                this.accuracy
            )
        );
    }

    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        this.updateContent();
    }

    render() {
        this.updateContent();
    }
}

UICurrency.defineElement('ui-currency');

export { UICurrency };

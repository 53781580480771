import { UIElement } from '../ui-element.js';
import styles from './ui-status.css';

/**
 * @memberof SharedComponents
 * @element ui-status
 * @augments {UIElement}
 * @alias UIStatus
 * @element ui-status
 * @classdesc Represents a class for <code>ui-status</code> element.
 * Status is a label with icon. There are only 6 types of statuses, see types.
 * @property {IStatusValue} type {@attr type}
 *  The type of status.
 *  {@desc active}
 *  {@desc inactive}
 *  {@desc pending}
 *  {@desc process}
 *  {@desc domestic-payment}
 *  {@desc international-payment}
 * @property {UIIcon | null} icon {@readonly} Shortcut to icon if exists.
 * @slot
 * @example
 * <ui-status type="active" class="-iconed">
 *   Your service is active.
 * </ui-status>
 */
class UIStatus extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['type'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                type: String,
            },
            children: {
                icon: 'ui-icon',
            },
        };
    }

    /**
     * Checks if ui-status has icon inside.
     * @returns {boolean}
     * @private
     */
    hasIcon() {
        return this.classList.contains('-iconed');
    }

    /**
     * Builds content.
     * @private
     */
    buildContent() {
        let glyph = null;
        switch (this.type) {
            case 'active':
                glyph = 'agree';
                break;
            case 'inactive':
                glyph = 'minus';
                break;
            case 'pending':
                glyph = 'time-hand';
                break;
            case 'process':
                glyph = 'options';
                break;
            /* istanbul ignore if */
            default:
                break;
        }
        const newIcon = this.createElement({
            tagName: 'ui-icon',
            attributes: {
                glyph: glyph,
            },
        });
        if (glyph && this.hasIcon()) {
            if (this.icon) {
                this.icon.replaceWith(newIcon);
            } else {
                this.insertAdjacentElement('afterbegin', newIcon);
            }
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'type':
                this.buildContent();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.buildContent();
    }
}

UIStatus.defineElement('ui-status', styles);
export { UIStatus };

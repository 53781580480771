import { UITable } from '../../components/table/ui-table.js';
import { scrollTo } from '../../global/helpers.js';
import {
    closestByCond,
    createElement,
    updateElement,
} from '../../global/ui-helpers.js';
import { Labels } from '../../global/labels.js';
import { UIIcon } from '../../components/icon/ui-icon.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table auto plugin.
 * @implements {IActivatable}
 * @alias UITableExpandable
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableExpandable {
    constructor(target) {
        this.target = target;
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-table-expandable', {
            expandRow: 'Expand row',
            collapseRow: 'Collapse row',
        });
    }

    /**
     * Checks if row can be expanded.
     * @param {HTMLElement} elem
     * @returns {boolean}
     * @private
     */
    canExpand(elem) {
        return (
            ['TD', 'TH'].indexOf(elem.tagName) !== -1 ||
            elem.classList.contains('fixed-title') ||
            closestByCond(elem, (node) => {
                return (
                    node.tagName === 'BUTTON' &&
                    node.classList.contains('expander-button')
                );
            })
        );
    }

    /**
     * Changes current view to active (open) state.
     * @param {string} [rowId] Row id to be expanded, if not set window.location.hash is used.
     * @returns {HTMLTableRowElement}
     */
    async activate(rowId) {
        const id = window.location.hash.replace('#', '') || rowId;
        const row = document.getElementById(id);
        if (row && !row.classList.contains('-open')) {
            try {
                row.querySelector('button.expander-button').click();
            } catch (e) {
                // catch me!
            }
            await scrollTo(row, 0);
        }
        return row;
    }

    /**
     * @param {HTMLTableRowElement} row
     * @param {boolean} [open]
     * @private
     */
    toggleExtraRows(row, open = false) {
        let next = row.nextElementSibling;
        while (next) {
            // We search only until next expandable row.
            if (next.classList.contains('-expand')) {
                break;
            }
            next.setAttribute('aria-expanded', String(open));
            open
                ? next.classList.add('-expanded')
                : next.classList.remove('-expanded');
            next = next.nextElementSibling;
        }
    }

    /**
     * @param {HTMLTableRowElement} row
     * @returns {boolean}
     * @private
     */
    isRowOpened(row) {
        return row.classList.contains('-open');
    }

    /**
     * @param {HTMLTableRowElement} row
     * @returns {boolean}
     * @private
     */
    hasNextExtra(row) {
        let ret = false;
        let next = row.nextElementSibling;
        while (next) {
            if (next.classList.contains('-extra')) {
                ret = true;
                break;
            }
            next = next.nextElementSibling;
        }
        return ret;
    }

    /**
     * @inheritDoc
     */
    render() {
        const isComparison = !!this.target.getInstancePlugins()['comparison'];
        this.target.table.classList.add('-expandable');
        const rows = this.target.querySelectorAll('tbody tr.-expand');
        if (rows.length > 0 && this.target.table.tHead) {
            const row = this.target.table.tHead.rows[0];
            if (row && !isComparison) {
                row.appendChild(document.createElement('th'));
            }
        }

        const expanderCell = createElement({
            tagName: 'td',
            classList: {
                expander: true,
                col: true,
                '-last': true,
            },
        });

        // If there aren't next row with class .row.-extra, just do not
        // add the expand button to it.
        [].forEach.call(rows, (row) => {
            const isOpened = this.isRowOpened(row);
            const hasNextExtraRow = this.hasNextExtra(row);

            this.toggleExtraRows(row, isOpened);

            const button = createElement({
                tagName: 'button',
                classList: {
                    'expander-button': true,
                },
                attributes: {
                    type: 'button',
                    'aria-label': isOpened
                        ? UITableExpandable.labels.collapseRow
                        : UITableExpandable.labels.expandRow,
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: isOpened ? 'up' : 'down',
                            color: isOpened
                                ? UIIcon.colors.BARK_70
                                : UIIcon.colors.ORANGE,
                        },
                    },
                ],
            });

            const cell = expanderCell.cloneNode(false);
            if (hasNextExtraRow) {
                updateElement(cell, {
                    children: [button],
                });
            }

            if (isComparison) {
                row.cells[0].appendChild(button);
            } else if (!row.classList.contains('-sub')) {
                row.appendChild(cell);
            }
        });

        const nonExpandableRows = this.target.querySelectorAll(
            'tbody tr:not(.-expand)'
        );
        [].forEach.call(nonExpandableRows, (row) => {
            row.appendChild(expanderCell.cloneNode(false));
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        [].forEach.call(
            this.target.querySelectorAll('button.expander-button'),
            (btn) => {
                const icon = btn.querySelector('ui-icon');
                const row = btn.closest('tr.-expand');
                if (!row) {
                    console.warn(
                        `${this.constructor.name}: expander button should have row parent.`
                    );
                    return;
                }
                row.addEventListener('click', (e) => {
                    if (!this.canExpand(e.target)) {
                        return;
                    }

                    const isOpened = this.isRowOpened(row);
                    this.toggleExtraRows(row, !isOpened);
                    if (isOpened) {
                        icon.glyph = 'down';
                        icon.color = UIIcon.colors.ORANGE;
                        row.classList.remove('-open');
                    } else {
                        icon.glyph = 'up';
                        icon.color = UIIcon.colors.BARK_70;
                        row.classList.add('-open');
                    }
                });
            }
        );
        this.target.getPlugin('expandable').activate();
    }
}

UITable.registerPlugin('expandable', UITableExpandable);

export { UITableExpandable };

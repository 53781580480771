import { UIElement } from '../ui-element.js';
import { isPlainObject } from '../../global/helpers.js';
import { setInnerText } from '../../global/ui-helpers.js';
import styles from './ui-calculator.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICalculator
 * @element ui-calculator
 * @classdesc Represents a class for <code>ui-calculator</code> element.
 * @property {string} [label] {@attr label} Heading text.
 * @property {string} [labelDisclaimer] {@attr label-disclaimer} Disclaimer text,
 * shows at the bottom of calculator.
 * @property {("columns" | "fullwidth")} [layout="columns"] {@attr layout} Layout of calculator.
 *  {@desc columns: layout with 2 columns}
 *  {@desc fullwidth: layout will full width}
 * @property {Function} calculationFn - calculation middleware.
 * @property {Function} calculationCallback - callback when calculation is done.
 * @slot
 * @example
 * <ui-calculator label="A calculator">
 *   Your markup here.
 * </ui-calculator>
 */
class UICalculator extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: String,
                labelDisclaimer: String,
            },
        };
    }

    /**
     * Result accessor.
     * @returns {object}
     */
    getResult() {
        return this._result;
    }

    /**
     * Set calculation middleware for calculator. Also can be added a callback
     * when calculation is success.
     * @param {Function} fn Middleware function should return the object with
     * mappings.
     * <code-preview language="json">
     * {
     *   propName1: value,
     *   propName2: value,
     *   ...
     *   propNameN: value
     * }
     * </code-preview>
     * Output will be automatically mapped to DOM elements with ID-s
     * in following format: #propName1-result, #propName2-result.
     * @param {Function} cb Callback then calculation is finished.
     */
    setCalculation(fn, cb) {
        if (typeof fn === 'function') {
            this.calculationFn = fn;
        }
        if (typeof cb === 'function') {
            this.calculationCallback = cb;
        }
    }

    /**
     * Runs the calculations.
     */
    calculate() {
        if (typeof this.calculationFn === 'function') {
            this._result = this.calculationFn();
            this.applyMappings(this._result);
        }
        if (typeof this.calculationCallback === 'function') {
            this.calculationCallback();
        }
    }

    /**
     * Apply mappings to map return object with HTMLElement IDs. Returns
     * how many mappings were applied.
     * @param {object} obj
     * @returns {number}
     * @private
     */
    applyMappings(obj) {
        let mappingsCount = 0;
        if (isPlainObject(obj)) {
            for (const key in obj) {
                /* istanbul ignore if */
                if (obj.hasOwnProperty(key)) {
                    const resultElem = document.getElementById(key + '-result');
                    if (resultElem) {
                        setInnerText(resultElem, obj[key]);
                        ++mappingsCount;
                    }
                }
            }
        }
        return mappingsCount;
    }

    /**
     * @inheritDoc
     */
    render() {
        if (this.label) {
            this.insertAdjacentElement(
                'afterbegin',
                this.createElement({
                    tagName: 'h2',
                    children: [this.label],
                })
            );
        }

        if (this.labelDisclaimer) {
            this.insertAdjacentElement(
                'afterend',
                this.createElement({
                    tagName: 'p',
                    children: [this.labelDisclaimer],
                    attributes: {
                        class: 'disclaimer',
                    },
                })
            );
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        const inputs = this.querySelectorAll(
            'input,select,textarea,.ui-slider__input'
        );
        [].forEach.call(inputs, (input) => {
            input.addEventListener('change', this.calculate.bind(this));
        });
    }
}

UICalculator.defineElement('ui-calculator', styles);
export { UICalculator };

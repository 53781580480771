import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import { keyCodes, TabIndex } from '../../global/keyboard.js';
import { Digest } from '../../global/digest.js';
import {
    show,
    hide,
    createElement,
    insertElements,
} from '../../global/ui-helpers.js';
import { browser } from '../../global/browser.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-slides.css';

/**
 * @memberof SharedComponents
 * @element ui-slides
 * @augments {UIElement}
 * @alias UISlides
 * @element ui-slides
 * @classdesc Represents a class for <code>ui-slides</code> element.
 * Carousel like element which plays ui-offers with interval or switched
 * manually with click, thumbnails, swipes, etc.
 * Requires only <b>ui-offer</b> and <b>ui-product</b> children elements.
 * @property {boolean} [thumbnails=false] {@attr thumbnails} Thumbnails will
 * be generated for slides based on images inside ui-offers.
 * Not compatible with ui-products.
 * @property {number} [duration=6000] {@attr duration}
 * Delay of one slide how long it will be shown in milliseconds.
 * @property {boolean} [mobileOnly=false] {@attr mobile-only}
 * Show carousel only in 'mobile' mode when media meets media query
 * mobile device breakpoint.
 * @property {HTMLDivElement} container - {@readonly} Shortcut to ui-slides container.
 * @property {UIOffer|UIProduct} offers - {@readonly} Shortcut to offers collection.
 * @slot
 * @example
 * <ui-slides>
 *   <ui-offer>...</ui-offer>
 *   <ui-offer>...</ui-offer>
 *   <ui-offer>...</ui-offer>
 * </ui-slides>
 * @example
 * <ui-products>
 *   <ui-slides>
 *     <ui-product>...</ui-product>
 *     <ui-product>...</ui-product>
 *     <ui-product>...</ui-product>
 *   </ui-slides>
 * </ui-products>
 */
class UISlides extends UIElement {
    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-slides', {
            titleNav: 'Slides navigation',
            prev: 'Previous',
            next: 'Next',
            slide: 'Slide',
        });
    }

    /**
     * Threshold of swipe
     * @type {number}
     */
    static get SWIPE_THRESHOLD() {
        return 50;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                thumbnails: Boolean,
                duration: { type: Number, default: 6000 },
                mobileOnly: Boolean,
            },
            children: {
                container: '.ui-slides__container',
                offers: {
                    selector: 'ui-offer,ui-product',
                    multiple: true,
                },
            },
        };
    }

    /**
     * @type {string}
     * @readonly
     */
    get uid() {
        if (!this._id) {
            this._id = Digest.randomId();
        }
        return this._id;
    }

    /**
     * Gets the navigation if exists, otherwise create it.
     * @private
     * @returns {HTMLElement}
     */
    getOrCreateNavigation() {
        return (
            this.querySelector('.ui-slides__navigation') ||
            this.createNavigation()
        );
    }

    /**
     * Gets container if exists, otherwise create it.
     * @private
     * @returns {HTMLDivElement}
     */
    getOrCreateContainer() {
        return this.container || this.createContainer();
    }

    /**
     * Creates container for slides.
     * @private
     * @returns {HTMLDivElement}
     */
    createContainer() {
        const container = this.appendChild(
            this.createElement({
                tagName: 'div',
                attributes: {
                    id: 'ui-slides-container-' + this.uid,
                    class: 'ui-slides__container',
                },
            })
        );
        this.appendChild(container);

        [].forEach.call(this.offers, (offer) => {
            container.appendChild(offer);
        });
        return container;
    }

    /**
     * Reset all classes (animations) and slides' state.
     */
    reset() {
        [].map.call(this.navigation.children, (child, i) => {
            child.classList.remove('-active');
            this.offers[i].classList.add('-out-of-view');
            this.offers[i].setAttribute('aria-hidden', 'true');
        });
    }

    /**
     * Create navigation element and binds required events.
     * @private
     * @returns {HTMLElement}
     */
    createNavigation() {
        const nav = createElement({
            tagName: 'nav',
            attributes: {
                'aria-label': UISlides.labels.titleNav,
            },
            classList: {
                'ui-slides__navigation': true,
            },
        });
        const ul = document.createElement('ul');

        [].forEach.call(this.offers, (offer, i) => {
            const item = this.createElement({
                tagName: 'li',
                attributes: {
                    class: 'ui-slides__item',
                    role: 'button',
                    'aria-label': `${UISlides.labels.slide} ${i + 1}`,
                    'aria-controls': 'ui-slides-container-' + this.uid,
                    tabindex: TabIndex.Active,
                },
            });

            if (!this.thumbnails) {
                item.appendChild(document.createElement('span'));
                if (this.duration && !browser.prefersReducedMotion()) {
                    item.querySelector('span').style.animationDuration =
                        this.duration + 'ms';
                }
            } else {
                const img = document.createElement('div');
                const head = document.createElement('h3');
                const parsedImg = offer.querySelector('img');
                let bgUrl = offer.image;
                if (parsedImg) {
                    bgUrl =
                        parsedImg.getAttribute('data-src') ||
                        parsedImg.getAttribute('src');
                }
                img.style.backgroundImage = 'url(' + bgUrl + ')';
                head.innerHTML = offer.getAttribute('thumb-text');
                item.appendChild(img);
                item.appendChild(head);
            }

            ul.appendChild(item);
        });

        nav.appendChild(ul);
        this.appendChild(nav);

        // If slides have thumbnails then buttons should be added if all items don't fit the screen.
        if (this.thumbnails) {
            const createButton = (glyph) => {
                return {
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                    },
                    classList: {
                        '-iconed': true,
                        'ui-slides__icon': true,
                    },
                    children: [
                        {
                            tagName: 'ui-icon',
                            classList: {
                                '-navicon': true,
                            },
                            attributes: {
                                glyph: glyph,
                                bgcolor: UIIcon.colors.WHITE,
                                'aria-controls':
                                    'ui-slides-container-' + this.uid,
                            },
                        },
                    ],
                };
            };

            insertElements(
                nav,
                [
                    {
                        element: this.createElement(createButton('left')),
                        attributes: {
                            'aria-label': UISlides.labels.prev,
                        },
                        classList: {
                            '-prev': true,
                        },
                    },
                ],
                'afterbegin'
            );

            insertElements(nav, [
                {
                    element: this.createElement(createButton('right')),
                    attributes: {
                        'aria-label': UISlides.labels.next,
                    },
                    classList: {
                        '-next': true,
                    },
                },
            ]);
        }

        return nav;
    }

    /**
     * Handles swipe event for touch screens.
     * @private
     */
    initSwipeEventsHandler() {
        this.touchXStart = 0;
        this.touchXEnd = 0;

        this.addEventListener('touchstart', (event) => {
            this.touchXStart = event.changedTouches[0].screenX;
        });

        this.addEventListener('touchmove', (event) => {
            const xd = this.touchXStart - event.changedTouches[0].screenX;
            this.offers[this.currentItem].style.left = -xd + 'px';
        });

        this.addEventListener('touchend', (event) => {
            this.touchXEnd = event.changedTouches[0].screenX;
            this.offers[this.currentItem].style.left = '0px';
            if (this.touchXEnd + UISlides.SWIPE_THRESHOLD <= this.touchXStart) {
                this.playNextItem();
            }
            if (this.touchXEnd - UISlides.SWIPE_THRESHOLD >= this.touchXStart) {
                this.playPrevItem();
            }
        });
    }

    /**
     * Handles foucsed events.
     * @private
     */
    initFocusEventsHandler() {
        this.addEventListener('mouseenter', () => {
            this.focused = true;
        });
        this.addEventListener('mouseleave', () => {
            this.focused = false;
        });
    }

    /**
     * Sets the width of navigation. It calculates based on width of items and
     * total navigation width.
     */
    setNavContainerWidth() {
        if (!this.thumbnails) {
            return;
        }

        // Get calculated navigation items width and total navigation width
        this.navItemWidth = parseInt(
            window
                .getComputedStyle(this.navigation.firstChild)
                .getPropertyValue('width')
        );
        this.navWidth = parseInt(
            window
                .getComputedStyle(this.navigationContainer)
                .getPropertyValue('width')
        );

        // Calculate offset 2 for margins.
        this.navWidth -= this.navItemWidth * 2;
        this.itemsToShow = Math.floor(this.navWidth / this.navItemWidth);

        // If fetches for items that exists, items to show should be equals to number of offers.
        if (this.itemsToShow > this.offers.length) {
            this.itemsToShow = this.offers.length;
        }

        if (this.itemsToShow < this.offers.length) {
            this.navigation.style.width =
                this.itemsToShow * this.navItemWidth + 'px';
        } else {
            this.navigation.style.width = 'auto';
        }

        [].forEach.call(this.querySelectorAll('.ui-slides__icon'), (icon) => {
            this.itemsToShow < this.offers.length ? show(icon) : hide(icon);
        });

        // Reset margin
        this.navigation.children[0].style.marginLeft = '0px';
    }

    /**
     * Plays animation with index {i}.
     * @param {number} i - Index of slide to play.
     */
    playItem(i) {
        this.container.style.transform = 'translateX(' + -i * 100 + '%)';

        if (this.thumbnails) {
            let marginLeft;
            const delta = Math.round(this.itemsToShow / 2);
            if (i >= delta && i <= this.offers.length - delta) {
                marginLeft = (-i + delta - 1) * this.navItemWidth;
            } else {
                marginLeft = 0;
            }
            this.navigation.children[0].style.marginLeft = `${marginLeft}px`;
        }

        this.reset();
        this.navigation.children[i].classList.add('-active');
        this.offers[i].classList.remove('-out-of-view');
        this.offers[i].setAttribute('aria-hidden', 'false');

        // Trigger layout reflow.
        this['offsetWidth'].toString();
        this.currentItem = i;
    }

    /**
     * Plays next item.
     */
    playNextItem() {
        if (++this.currentItem > this.offers.length - 1) {
            this.currentItem = 0;
        }
        this.reset();
        this.playItem(this.currentItem);
    }

    /**
     * Plays previous item.
     */
    playPrevItem() {
        if (--this.currentItem < 0) {
            this.currentItem = this.offers.length - 1;
        }
        this.reset();
        this.playItem(this.currentItem);
    }

    /**
     * Checks if the slides as at least 2 offers, if only 1 offer then navigation
     * pointless.
     * @returns {boolean}
     */
    hasAtLeastTwoOffers() {
        return this.offers.length >= 2;
    }

    /**
     * @inheritDoc
     */
    render() {
        this.getOrCreateContainer();
        if (this.hasAtLeastTwoOffers()) {
            this.getOrCreateNavigation();
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (!this.hasAtLeastTwoOffers()) {
            return;
        }
        this.redetachChildNodes();
        this.navItemWidth = 0;
        this.navWidth = 0;
        this.itemsToShow = 0;
        this.focused = false;
        this.currentItem = -1;
        this.timeout = null;
        this.navigationContainer = this.getOrCreateNavigation();
        this.navigation = this.navigationContainer.querySelector('ul');
        this.initFocusEventsHandler();
        this.initSwipeEventsHandler();

        // Recalculate on window resize? Do we really need this?
        window.addEventListener('resize', this.setNavContainerWidth.bind(this));
        this.setNavContainerWidth.call(this);

        const items = this.querySelectorAll('.ui-slides__item');
        [].forEach.call(items, (item, i) => {
            if (!this.thumbnails && !browser.prefersReducedMotion()) {
                item.addEventListener('animationend', () => {
                    if (!this.focused) {
                        this.playNextItem();
                    } else {
                        this.timeout = setTimeout(() => {
                            item.dispatchEvent(new Event('animationend'));
                        }, this.duration);
                    }
                });
            }

            item.addEventListener('click', () => {
                this.reset();
                this.playItem(i);
            });

            item.addEventListener('keydown', (event) => {
                switch (event.keyCode) {
                    case keyCodes.ENTER:
                    case keyCodes.SPACE:
                        this.playItem(i);
                        event.preventDefault();
                        break;
                    case keyCodes.LEFT:
                        this.playPrevItem();
                        items[this.currentItem].focus();
                        event.preventDefault();
                        break;
                    case keyCodes.RIGHT:
                        this.playNextItem();
                        items[this.currentItem].focus();
                        event.preventDefault();
                        break;
                }
            });
        });

        const prevBtn = this.querySelector('.ui-slides__icon.-prev');
        if (prevBtn) {
            prevBtn.addEventListener('click', this.playPrevItem.bind(this));
        }
        const nextBtn = this.querySelector('.ui-slides__icon.-next');
        if (nextBtn) {
            nextBtn.addEventListener('click', this.playNextItem.bind(this));
        }

        if (this.mobileOnly) {
            this.mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
            this.mobileMediaQueryList.addListener(this.playItem.bind(this, 0));
        }
        this.playNextItem();
    }
}

UISlides.defineElement('ui-slides', styles);
export { UISlides };

import { UITable } from '../../components/table/ui-table.js';
/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table auto plugin.
 * @alias UITableAuto
 * @implements {IComponentPlugin}
 * TODO: auto layout should automatically decide how to apply table.
 * @param {HTMLTableElement} target
 */
class UITableAuto {
    constructor(target) {
        this.target = target;
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-auto');
    }

    /**
     * @inheritDoc
     */
    hydrate() {}
}

UITable.registerPlugin('auto', UITableAuto);

export { UITableAuto };

import { Labels } from '../../global/labels.js';

export const validationLabels = Labels.attach('validation', {
    input: 'Please enter a valid value.',
    date: 'Please enter a valid value.',
    time: 'Please enter a time.',
    range: 'Invalid input.',
    month: 'Please enter a valid month.',
    'datetime-local': 'Please enter a datetime.',
    custom: 'Validation custom error.',
    pattern: 'Please match the format.',
    rangeOverflow: 'Value must be less than or equal %s.',
    rangeUnderflow: 'Value must be greater than or equal %s.',
    step: 'Step should be %s, nearest values are %s and %s.',
    long: 'Value is too long, maximum length is %s symbols.',
    short: 'Value is too short, minimum length is %s symbols.',
    type: 'Please enter a valid value.',
    email: 'Please enter an email address.',
    url: 'Please enter a URL.',
    value: 'Please fill out this field.',
    matches: "Fields values don't match.",
    checkboxes: 'Please select at least %s option(s).',
    onlyOneOf: 'Only one field is allowed',
    maxfilesize: 'File(s) size limit exceeded %s kb.',
    requiredMultiple: 'Please fill at least %s field(s).',
});

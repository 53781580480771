import { UIElement } from '../ui-element.js';
import { makePopoverHandler } from '../../global/helpers.js';
import { eventBus } from '../../global/event-bus.js';
import { Labels } from '../../global/labels.js';
import {
    createElement,
    insertElements,
    isParentOf,
} from '../../global/ui-helpers.js';
import { UIBadge } from '../badge/ui-badge.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import { TabIndex } from '../../global/keyboard.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-navtoggle.css';

/**
 * @memberof SharedComponents
 * @implements {IActivatable}
 * @augments {UIElement}
 * @alias UINavToggle
 * @element ui-navtoggle
 * @classdesc Represents a class for <code>ui-navtoggle</code> element
 * @fires event:navtoggle-closed
 * @fires event:menu-toggle
 * @listens event:menu-toggle
 * @property {("default" | "link" | "button")} layout {@attr layout} Layout for toggle:
 *   {@desc default: default layout, toggle with icon}
 *   {@desc link: layout for toggle with icon and text}
 *   {@desc button: layout for toggle with button appearance}
 * @property {("default" | "bounded")} contentLayout {@attr content-layout} Layout for popover content:
 *   {@desc default: default layout}
 *   {@desc bounded: layout without spaces around, but with max-height}
 * @property {string} label {@attr label} Label for accessibility.
 * @property {string} for {@attr for} Specify for 'menu-toggle' event details property
 * @property {string} group {@attr group} Specify group of togglers
 * @property {UIIconGlyph} glyph {@attr glyph} Render icon inside component with this glyph
 * (for default layout)
 * @property {boolean} [active=false] {@attr active} State of toggle
 * @property {string} badge {@attr badge} Content for badge (for default/link layouts)
 * @property {HTMLButtonElement} control Shortcut to actual toggler button
 * @property {HTMLDivElement} popoverElement Shortcut to popover element.
 * @property {HTMLDivElement} content Shortcut to content element.
 * @property {UIBadge | HTMLElement} uiBadge Shortcut to badge element.
 * @example
 * <ui-navtoggle></ui-navtoggle>
 */
class UINavToggle extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     */
    static get observedAttributes() {
        return ['active', 'badge'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: UINavToggle.layouts.DEFAULT },
                label: String,
                for: String,
                group: String,
                glyph: String,
                active: Boolean,
                badge: String,
                contentLayout: {
                    type: String,
                    default: UINavToggle.contentLayouts.DEFAULT,
                },
            },
            children: {
                content: '.ui-navtoggle__content',
                control: '.ui-navtoggle__control',
                uiBadge: 'ui-badge',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-navtoggle', {
            toggleMenu: 'Toggle menu',
            closeMenu: 'Close menu',
        });
    }

    /**
     * Layouts list.
     * @type {{DEFAULT: string, LINK: string, BUTTON: string}}
     */
    static get layouts() {
        return {
            DEFAULT: 'default',
            LINK: 'link',
            BUTTON: 'button',
        };
    }

    /**
     * Content layouts list.
     * @type {{DEFAULT: string, BOUNDED: string}}
     */
    static get contentLayouts() {
        return {
            DEFAULT: 'default',
            BOUNDED: 'bounded',
        };
    }

    /**
     * Minimum popover height value in px
     * @type {number}
     */
    static get PopoverHeightMin() {
        return 100;
    }

    /**
     * Bottom gap for popover in px, which takes more height than viewport height
     * @type {number}
     */
    static get BottomGap() {
        return 60;
    }

    get popoverElement() {
        if (this._detachedPopover) {
            return this._detachedPopover;
        }
        return this.querySelector('.ui-navtoggle__popover');
    }

    /**
     * Get popover client height
     * @returns {number}
     */
    getPopoverHeight() {
        return this.popoverElement.clientHeight;
    }

    /**
     * Get popover client width
     * @returns {number}
     */
    getPopoverWidth() {
        return this.popoverElement.clientWidth;
    }

    /**
     * Get right margin of the page content for popover position
     * @returns {number}
     */
    getContentMargin() {
        if (window.innerWidth > 1300) {
            return Math.floor((window.innerWidth - 1260) / 2);
        } else {
            return 20;
        }
    }

    /**
     * Checks is popover is outside the window width with default position
     * @returns {boolean}
     */
    isPopoverOutsideWindow() {
        return (
            this.control.getBoundingClientRect().left +
                this.getPopoverWidth() +
                this.getContentMargin() >
            window.innerWidth
        );
    }

    /**
     * Sets popover pointer left position in pixels.
     * @param {number} value
     */
    setPointerPosition(value) {
        this.popoverElement.querySelector('.ui-navtoggle__pointer').style.left =
            value > 0 ? value + 'px' : null;
    }

    /**
     * Sets popover transform origin.
     * @param {string} value
     */
    setTransformOrigin(value) {
        this.popoverElement.style.transformOrigin = value ? value : '';
    }

    /**
     * Calculate possible height for popover on the desktop
     * @param { number } [top]
     * @returns { number }
     */
    static resolvePopoverMaxHeight(top) {
        const maxHeight =
            document.documentElement.clientHeight -
            (top || 0) -
            UINavToggle.BottomGap;
        return maxHeight > UINavToggle.PopoverHeightMin
            ? maxHeight
            : UINavToggle.PopoverHeightMin;
    }

    /**
     * Open popover in 'modal'
     * @fires event:modal-open
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }
        this._detachedPopover = this.popoverElement;

        const popoverHandler = makePopoverHandler(this.control, {
            popoverHeight: this.getPopoverHeight.bind(this),
            popoverWidth: this.getPopoverWidth.bind(this),
            horizontalOffset: () => {
                const toggleRect = this.control.getBoundingClientRect();
                const horizontalOffset = Math.floor(
                    window.innerWidth -
                        toggleRect.right -
                        this.getContentMargin()
                );

                this.setTransformOrigin(
                    this.getPopoverWidth() -
                        (horizontalOffset +
                            Math.floor(toggleRect.width / 2) +
                            10) +
                        'px -' +
                        Math.floor(toggleRect.height / 2) +
                        'px'
                );

                this.setPointerPosition(
                    this.isPopoverOutsideWindow()
                        ? this.getPopoverWidth() -
                              (horizontalOffset +
                                  Math.floor(toggleRect.width / 2) +
                                  10)
                        : Math.floor(toggleRect.width / 2) - 10
                );

                return this.isPopoverOutsideWindow()
                    ? -1 * horizontalOffset
                    : 0;
            },
            invertHorizontalAlignment: this.isPopoverOutsideWindow.bind(this),
            supportMiddleAlignment: false,
            supportTopAlignment: false,
        });

        this._detachedPopover = this.popoverElement;
        this.dispatchCustomEvent('modal-open', {
            type: 'popover',
            content: this.popoverElement,
            params: {
                classList: {
                    '-navtoggle': true,
                },
                attributes: {
                    animation: this.isAnimated() ? true : null,
                    'open-timeout': 100,
                    'tablet-full': true,
                },
                position: () => {
                    if (window.innerWidth < 1024) {
                        return {
                            align: 'fixed',
                        };
                    }
                    document.documentElement.classList.remove('-no-scroll');
                    return popoverHandler();
                },
                target: this,
                onClose: () => (this.active = false),
            },
        });
    }

    /**
     * Closes detached popover.
     * @fires event:navtoggle-closed
     */
    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        const modal =
            this._detachedPopover && this._detachedPopover.closest('ui-modal');
        if (modal) {
            this._detachedPopover = null;
            modal.close();
            this.dispatchCustomEvent('navtoggle-closed', {});
        }
    }

    /**
     * Closes popover element.
     */
    closePopover() {
        this.active = false;
    }

    /**
     * Checks if current popover is animated.
     * @returns {boolean}
     */
    isAnimated() {
        if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
            return false;
        }

        return (
            window.matchMedia('(min-width: 1024px)').matches &&
            (this.active || this.animated)
        );
    }

    /**
     * Created popover section with content for navtoggle
     * @param {IElementConfigChildren} children
     * @returns {HTMLDivElement}
     */
    createPopoverElement(children) {
        const el = this.createElement({
            tagName: 'div',
            attributes: {
                class: 'ui-navtoggle__popover',
                tabindex: TabIndex.Active,
            },
            children: [
                {
                    tagName: 'span',
                    classList: {
                        'ui-navtoggle__pointer': true,
                    },
                },
                {
                    tagName: 'div',
                    classList: {
                        'ui-navtoggle__content': true,
                        '-bounded':
                            this.contentLayout ===
                            UINavToggle.contentLayouts.BOUNDED,
                    },
                    children:
                        this.contentLayout ===
                        UINavToggle.contentLayouts.BOUNDED
                            ? [
                                  {
                                      tagName: 'div',
                                      classList: {
                                          'ui-navtoggle__content-wrapper': true,
                                      },
                                      children: children,
                                  },
                              ]
                            : children,
                },
                {
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                        'aria-label': UINavToggle.labels.closeMenu,
                    },
                    classList: {
                        'ui-navtoggle__close': true,
                        '-iconed': true,
                    },
                    events: {
                        click: (e) => {
                            e.preventDefault();
                            this.closePopover();
                        },
                    },
                    children: [
                        {
                            tagName: 'ui-icon',
                            attributes: {
                                glyph: 'cross',
                                color: UIIcon.colors.DEFAULT,
                            },
                        },
                    ],
                },
            ],
        });
        this.appendChild(el);
        return el;
    }

    /**
     * @inheritDoc
     */
    onChangeState() {
        this.updateClassList({
            '-on': this.active,
            '-off': !this.active,
        });

        if (!this.popoverElement) {
            return;
        }

        if (this.active) {
            window.scroll({ top: 0 });
            this.openDetachedPopover();
            document.addEventListener('click', this.handleClickOutside);
        } else {
            this.closeDetachedPopover();
            document.removeEventListener('click', this.handleClickOutside);
        }
    }

    /**
     * Fires callback when user clicked outside of the ui-navtoggle element.
     * @param {Event} e
     * @private
     */
    handleClickOutside(e) {
        if (e.target.closest('ui-navtoggle') && e.defaultPrevented) {
            return;
        }

        if (
            this.active &&
            !this.isParentOf(e.target) &&
            !isParentOf(this.popoverElement, e.target)
        ) {
            const modal = e.target.closest('ui-modal');
            const popoverModal = this.popoverElement.closest('ui-modal');

            if (modal && popoverModal && modal.order > popoverModal.order) {
                return;
            }

            this.closePopover();
        }
    }

    /**
     * Fires callback when element is clicked
     * @param {CustomEvent} e
     * @private
     */
    handleClickEvent(e) {
        if (e.defaultPrevented || !this.control.contains(e.target)) {
            return;
        }

        e.preventDefault();
        this.dispatchCustomEvent('menu-toggle', {
            active: !this.active,
            for: this.for,
            group: this.group,
        });
    }

    /**
     * Fires callback when the "menu-toggle" event is bubbled
     * @param {CustomEvent} e
     * @private
     */
    handleToggleMenu(e) {
        if (!e.detail.for && !e.detail.active && !e.detail.group) {
            this.active = false;
            return;
        }

        if (e.detail.group) {
            if (e.detail.group === this.group) {
                this.animated = e.detail.for === this.for;
                this.active = e.detail.for === this.for && !!e.detail.active;
            }
        } else if (e.detail.for === this.for) {
            this.animated = true;
            this.active = !!e.detail.active;
        }
    }

    /**
     * Activate method to open nav-toggle.
     * @fires event:menu-toggle
     */
    activate() {
        eventBus.dispatchCustomEvent('menu-toggle', {
            active: true,
            for: this.for,
            group: this.group,
        });
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'active':
                this.onChangeState();
                break;
            case 'badge':
                if (!this.control) {
                    return;
                }
                if (this.uiBadge) {
                    if (this.badge) {
                        this.uiBadge.textContent = this.badge;
                    } else {
                        this.uiBadge.remove();
                    }
                } else {
                    this.control.appendChild(this.createUIBadge());
                }
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @returns {UIBadge}
     * @private
     */
    createUIBadge() {
        return createElement({
            tagName: 'ui-badge',
            attributes: {
                color: BrandColors.Pineapple,
                layout: UIBadge.Layout.Pin,
            },
            children: [this.badge],
        });
    }

    /**
     * @inheritDoc
     */
    render() {
        const childNodes = this.detachChildNodes();
        const icon = childNodes.filter((node) => {
            return (
                node.tagName &&
                ['ui-icon', 'ui-icon-symbolic'].includes(
                    node.tagName.toLowerCase()
                )
            );
        })[0];
        const content = childNodes.filter((node) => {
            return node.tagName && node.tagName.toLowerCase() === 'section';
        })[0];
        if (childNodes.indexOf(content) > -1) {
            childNodes.splice(childNodes.indexOf(content), 1);
        }

        const control = this.createElement({
            tagName: 'button',
            attributes: {
                type: 'button',
                'aria-haspopup': 'true',
                'aria-label': this.label || UINavToggle.labels.toggleMenu,
            },
            classList: {
                'ui-navtoggle__control': true,
            },
            element: childNodes.filter((node) => {
                return node.tagName && node.tagName.toLowerCase() === 'button';
            })[0],
        });

        if (
            this.layout === UINavToggle.layouts.DEFAULT ||
            this.layout === UINavToggle.layouts.LINK
        ) {
            insertElements(control, [
                {
                    tagName: 'ins',
                    children: [
                        { tagName: 'span' },
                        { tagName: 'span' },
                        { tagName: 'span' },
                        { tagName: 'span' },
                    ],
                },
            ]);

            insertElements(
                control,
                this.layout === UINavToggle.layouts.LINK
                    ? childNodes
                    : [
                          this.glyph || icon
                              ? {
                                    tagName: 'ui-icon',
                                    attributes: {
                                        glyph:
                                            this.glyph ||
                                            icon.getAttribute('glyph'),
                                    },
                                    element: icon,
                                }
                              : null,
                      ]
            );
        }

        // Insert badge as last element inside a control, to do not break the flow
        // and avoid z-index usage.
        this.badge && insertElements(control, [this.createUIBadge()]);

        this.updateElement({
            classList: {
                '-on': this.active,
                '-off': !this.active,
                '-iconed': this.glyph || !!icon,
                '-symbolic': !!control.querySelector('ui-icon-symbolic'),
            },
            children: [control],
        });

        if (content) {
            this.animated = true;
            this.createPopoverElement(content.childNodes);
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleClickEvent = this.handleClickEvent.bind(this);
        this.handleToggleMenu = this.handleToggleMenu.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        eventBus.addEventListener('menu-toggle', this.handleToggleMenu);
        this.addEventListener('click', this.handleClickEvent);
        this.onChangeState();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.closeDetachedPopover();
    }
}

UINavToggle.defineElement('ui-navtoggle', styles);

export { UINavToggle };

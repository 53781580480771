import { closestEventHolder } from './ui-helpers.js';
import { dispatchCustomEvent } from './ui-helpers.js';

/**
 * @typedef {CustomEvent} DeclarativeEvent
 * @property {{originalEvent: Event, relatedTarget: Element}} detail Event details
 */

/**
 * @memberof SharedComponents
 * @classdesc Class for handling declarative events
 * @alias EventObserver
 * @example
 * const clickObserver = new EventObserver({name: 'click'});
 * clickObserver.observe(this);
 * this.addEventListener('open-record', (e) => console.log('Open record triggered!', e));
 * clickObserver.disconnect();
 */
export class EventObserver {
    /**
     * @param {{name: string}} [config]
     * @property {{name: string}} _config Event configuration
     */
    constructor(config) {
        this._config = config || { name: 'click' };
        this.handleInternalEvent = this.handleInternalEvent.bind(this);
    }

    /**
     * Destroy listener
     */
    disconnect() {
        this._target.removeEventListener(
            this._config.name,
            this.handleInternalEvent
        );
        this._target = null;
    }

    /**
     * Start observing
     * @param {Element} target
     */
    observe(target) {
        this._target = target;
        this._target.addEventListener(
            this._config.name,
            this.handleInternalEvent
        );
    }

    /**
     * This method transforms declarative event defined in the attribute data-event="name"
     * and dispatches the custom event with the same name
     * @private
     * @param {Event} originalEvent
     * @param {Element} relatedTarget
     */
    handleDeclarativeEvent(originalEvent, relatedTarget) {
        if (originalEvent.processedAsDeclarative) {
            return;
        }
        originalEvent.processedAsDeclarative = true;
        dispatchCustomEvent(this._target, relatedTarget.dataset.event, {
            relatedTarget: relatedTarget,
            originalEvent: originalEvent,
        });
    }

    /**
     * This method filters all events out what are not related to declarative event.
     * What do not contain data-event attribute
     * @param {Event} e
     * @private
     */
    handleInternalEvent(e) {
        const relatedTarget = closestEventHolder(e.target, this._target);
        if (!relatedTarget) {
            return;
        }
        this.handleDeclarativeEvent(e, relatedTarget);
    }
}

import { UIElement } from '../ui-element.js';
import { duplicateIndexSetter, INDEX_REGEXP } from '../../global/helpers.js';
import {
    closestByCond,
    focusInteractiveElement,
    hide,
    show,
    dispatchCustomEvent,
} from '../../global/ui-helpers.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-duplicate.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIDuplicate
 * @element ui-duplicate
 * @classdesc Represents a class for <code>ui-duplicate</code> element.
 * Adds UI for duplicate fields.
 * @property {string} template Template that is using for duplication
 * @property {("default" | "block" | "field")} [layout="default"]
 *  {@attr layout} Layout for ui-element element.
 *  {@desc default: default layout}
 *  {@desc block: places controls on new line}
 *  {@desc field: places controls inside ui-field content }
 * @property {string} [labelAdd="Add"] {@attr label-add} Label for add button.
 * @property {string} [labelRemove="Remove"]  {@attr label-remove} Label for remove button.
 * @property {number} [limit=0] {@attr limit} Max available duplicates.
 * @property {boolean} [rebuildIndexes=false] {@attr rebuild-indexes} Keeps order of indexes
 * in sorted way 0 to n. Always ordered sequence.
 * @property {boolean} [keepFirst=false] {@attr keep-first} Keeps first element not removable
 * @property {boolean} [showAddLabel=false] {@attr show-add-label} Show label for add buttons.
 * @property {NodeList<HTMLDivElement>} rows - {@readonly} Duplicated rows reference.
 * @property {HTMLTemplateElement} templateElement - {@readonly} Link to template block.
 * @fires event:row-added
 * @fires event:row-removed
 * @slot
 * @example
 * <ui-duplicate>
 *   <div>Content needs to be duplicated</div>
 * </ui-duplicate>
 */
class UIDuplicate extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: String,
                limit: { type: Number, default: 0 },
                labelAdd: { type: String, default: 'Add' },
                labelRemove: { type: String, default: 'Remove' },
                rebuildIndexes: Boolean,
                keepFirst: Boolean,
                showAddLabel: Boolean,
            },
            children: {
                rows: {
                    selector: '.ui-duplicate__row',
                    multiple: true,
                },
                templateElement: 'script[type="text/x-template"]',
            },
        };
    }
    get template() {
        if (this.templateElement) {
            return this.templateElement.innerHTML;
        }
        return this.innerHTML;
    }
    set template(htmlString) {
        let tpl = this.templateElement;
        if (!tpl) {
            tpl = this.createElement({
                tagName: 'script',
                attributes: {
                    type: 'text/x-template',
                },
            });
            if (this.firstElementChild) {
                this.insertBefore(tpl, this.firstElementChild);
            } else {
                this.appendChild(tpl);
            }
        }
        tpl.innerHTML = htmlString;
    }

    /**
     * @type {{FIELD: string, BLOCK: string, DEFAULT: string}}
     */
    static get LAYOUTS() {
        return {
            DEFAULT: 'default',
            BLOCK: 'block',
            FIELD: 'field',
        };
    }

    /**
     * Replacer for index.
     * @param {string} matches
     * @param {string} $1
     * @param {string} $2
     * @param {string} $3
     * @returns {string}
     * @private
     */
    static indexDecrementReplacer(matches, $1, $2, $3) {
        let index = Number($2);
        let sign = '';
        if (index < 0) {
            index = Math.abs(index);
            sign = '-';
        }
        return --index > 0 ? ($1 || '') + sign + index + ($3 || '') : '';
    }

    /**
     * Decrements attribute index for duplicated row.
     * @param {Element} node
     * @param {string} attributeName
     * @private
     */
    static decrementAttributeIndex(node, attributeName) {
        node.setAttribute(
            attributeName,
            node
                .getAttribute(attributeName)
                .replace(INDEX_REGEXP, UIDuplicate.indexDecrementReplacer)
        );
    }

    /**
     * Build a new row from stored template.
     * @returns {*|HTMLElement}
     */
    buildRow() {
        const controls = [
            {
                tagName: 'button',
                attributes: {
                    class: 'ui-duplicate__button -add -iconed',
                    title: this.labelAdd,
                    type: 'button',
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'add',
                            bgcolor: UIIcon.colors.TURQUOISE,
                        },
                    },
                    (this.layout === UIDuplicate.LAYOUTS.BLOCK ||
                        this.showAddLabel) && {
                        tagName: 'span',
                        children: [this.labelAdd],
                    },
                ],
            },
            {
                tagName: 'button',
                attributes: {
                    class: 'ui-duplicate__button -remove -iconed',
                    title: this.labelRemove,
                    type: 'button',
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'cross',
                            bgcolor: UIIcon.colors.ALERT_RED,
                        },
                    },
                    this.layout === UIDuplicate.LAYOUTS.BLOCK && {
                        tagName: 'span',
                        children: [this.labelRemove],
                    },
                ],
            },
        ];
        const index = this.getRowIndex();
        const element = this.createElement({
            tagName: 'div',
            attributes: {
                class: 'ui-duplicate__row',
                'data-index': index.toString(),
            },
            children: [
                {
                    tagName: 'div',
                    attributes: { class: 'ui-duplicate__content' },
                },
                {
                    tagName: 'div',
                    attributes: { class: 'ui-duplicate__control' },
                    children:
                        this.layout !== UIDuplicate.LAYOUTS.BLOCK
                            ? controls
                            : [
                                  {
                                      tagName: 'ui-field',
                                      classList: {
                                          '-remove': true,
                                      },
                                      children: [controls[1]],
                                  },
                                  { tagName: 'hr' },
                                  {
                                      tagName: 'ui-field',
                                      classList: {
                                          '-add': true,
                                      },
                                      children: [controls[0]],
                                  },
                              ],
                },
            ],
        });

        /**
         * #XSS: Trusted exception
         */
        element.querySelector('.ui-duplicate__content').innerHTML =
            this.template;
        const field = element.querySelector('ui-field');
        if (field && this.layout === UIDuplicate.LAYOUTS.FIELD) {
            field.insertElements([
                element.querySelector('.ui-duplicate__control'),
            ]);
        }

        if (index > 0) {
            [].forEach.call(element.querySelectorAll('[id]'), (node) =>
                duplicateIndexSetter(node, 'id', index)
            );
            [].forEach.call(element.querySelectorAll('label[for]'), (node) =>
                duplicateIndexSetter(node, 'for', index)
            );
            [].forEach.call(element.querySelectorAll('[name]'), (node) => {
                if (node.getAttribute('name').indexOf('[]') > -1) {
                    return;
                }
                duplicateIndexSetter(node, 'name', index);
            });
        }
        this.appendChild(element);
        return this;
    }

    /**
     * Return parent row for element.
     * @param {ParentNode|Element} target
     * @returns {HTMLElement}
     */
    getRow(target) {
        return closestByCond(target, (node) => {
            return (
                node instanceof Element &&
                node.classList.contains('ui-duplicate__row')
            );
        });
    }

    /**
     * Create a new row.
     * @fires event:row-added
     * @returns {HTMLDivElement}
     */
    duplicateRow() {
        if (this.limit > 0 && this.limit <= this.rows.length) {
            return null;
        }
        this.buildRow();
        dispatchCustomEvent(this, 'row-added', {
            index: this.getRowIndex() - 1,
        });
        focusInteractiveElement(this.lastElementChild);
        return this.lastElementChild;
    }

    /**
     * Return last row index.
     * @returns {number}
     */
    getRowIndex() {
        return this.lastElementChild &&
            this.lastElementChild.getAttribute('data-index')
            ? parseInt(this.lastElementChild.getAttribute('data-index')) + 1
            : 0;
    }

    /**
     * Removes given row.
     * @fires event:row-removed
     * @param {HTMLElement} row
     */
    removeRow(row) {
        if (this.rebuildIndexes) {
            let next = row.nextElementSibling;
            while (next) {
                this.decrementRowIndex(next);
                next = next.nextElementSibling;
            }
        }
        const nextRowSibling = row.nextElementSibling;
        row.parentElement.removeChild(row);
        dispatchCustomEvent(this, 'row-removed', { index: row.dataset.index });
        focusInteractiveElement(nextRowSibling || this.lastElementChild);
    }

    /**
     * Resets components to initial state
     */
    reset() {
        [].forEach.call(this.rows, (row) => this.removeChild(row));
        this.buildRow();
        focusInteractiveElement(this.lastElementChild);
    }

    /**
     * Decrements indexes of duplicated for id, name, for attributes.
     * @param {HTMLElement} row
     * @private
     */
    decrementRowIndex(row) {
        const index = Number(row.dataset.index) - 1;
        row.dataset.index = index.toString();
        [].forEach.call(row.querySelectorAll('[id]'), (node) =>
            UIDuplicate.decrementAttributeIndex(node, 'id')
        );
        [].forEach.call(row.querySelectorAll('label[for]'), (node) =>
            UIDuplicate.decrementAttributeIndex(node, 'for')
        );
        [].forEach.call(row.querySelectorAll('[name]'), (node) => {
            if (node.getAttribute('name').indexOf('[]') > -1) {
                return;
            }
            UIDuplicate.decrementAttributeIndex(node, 'name');
        });
    }

    /**
     * Shows remove button for all element except the last.
     * @returns {UIDuplicate}
     */
    syncRowsControls() {
        [].forEach.call(this.rows, (node, index, list) => {
            const isLimitExceed = this.limit > 0 && this.limit <= list.length;
            const isLastRow = index + 1 === list.length;
            let canRemove =
                (!isLastRow ||
                    this.layout === UIDuplicate.LAYOUTS.BLOCK ||
                    isLimitExceed) &&
                list.length > 1 &&
                !(this.keepFirst && !index);
            let canDuplicate = isLastRow && !isLimitExceed;

            if (this.keepFirst && this.layout !== UIDuplicate.LAYOUTS.BLOCK) {
                canRemove = !!index;
                canDuplicate = !isLimitExceed && !index;
            }

            if (canDuplicate) {
                show(node.querySelector('.-add'));
            } else {
                hide(node.querySelector('.-add'));
            }

            if (canRemove) {
                show(node.querySelector('.-remove'));
            } else {
                hide(node.querySelector('.-remove'));
            }
        });
        return this;
    }

    /**
     * Fires callback when user click inside of component.
     * @param {Event} e
     * @private
     */
    handleClickInside(e) {
        const button = closestByCond(e.target, (node) => {
            return (
                node instanceof Element &&
                node.classList.contains('ui-duplicate__button')
            );
        });
        if (!button) {
            return;
        }
        if (button.classList.contains('-add')) {
            this.duplicateRow();
        } else if (button.classList.contains('-remove')) {
            this.removeRow(this.getRow(button));
        }
        this.syncRowsControls();
    }

    /**
     * Sets the value of input. We set attributes instead of
     * properties due to pre-render mode.
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} input
     * @param {string|number} value
     * @private
     */
    setInputValue(input, value) {
        if (input.type === 'radio' && input.value === value) {
            input.setAttribute('checked', 'checked');
        } else if (input.type === 'checkbox') {
            const v = this.parseBooleanAttr(value);
            if (v) {
                input.setAttribute('checked', 'checked');
            } else {
                input.removeAttribute('checked');
            }
        } else if (input.type === 'select-one') {
            for (let i = 0; i < input.options.length; ++i) {
                if (input.options[i].value === value) {
                    input.options[i].setAttribute('selected', 'selected');
                    break;
                }
            }
        } else {
            input.setAttribute('value', value);
        }
    }

    /**
     * Pre-fills rows with data.
     * @param {Array<object>} data
     */
    feedWithData(data) {
        data.forEach((record) => {
            const row = this.duplicateRow();
            for (const key in record) {
                if (record.hasOwnProperty(key)) {
                    const inputs = row.querySelectorAll(
                        '[name^="' + key + '" i]'
                    );
                    [].forEach.call(inputs, (input) => {
                        this.setInputValue(input, record[key]);
                    });
                }
            }
        });
    }

    /**
     * Fetches template markup from element of from script[type=text/x-template].
     * element.
     * @returns {string}
     * @private
     */
    fetchTemplate() {
        let html;
        const tpl = this.templateElement;
        if (!tpl) {
            html = [].reduce.call(
                this.childNodes,
                (str, node) => {
                    if (node.nodeName.toLowerCase() !== 'ui-datalist') {
                        if (node.nodeType === Node.TEXT_NODE) {
                            str += node.textContent || '';
                        } else if (node.nodeType === Node.ELEMENT_NODE) {
                            str += node.outerHTML;
                        } else {
                            str += '';
                        }
                    }
                    return str;
                },
                ''
            );
        } else {
            html = tpl.innerHTML;
        }
        return html;
    }

    /**
     * Fetches the pre-filled data from JSON.
     * @returns {Array<object>}
     * @private
     */
    fetchData() {
        const dl = this.querySelector('ui-datalist');
        const retval = [];
        if (dl) {
            [].forEach.call(dl.getOptions(), (opt) => {
                retval.push(dl.getOptionAttributes(opt, true));
            });
        }
        return retval;
    }

    /**
     * @inheritDoc
     */
    render() {
        const templateHTML = this.fetchTemplate();
        const dl = this.querySelector('ui-datalist');
        const data = this.fetchData();

        // Reset default HTML
        this.innerHTML = '';
        this.template = templateHTML;
        if (dl) {
            this.appendChild(dl);
        }

        if (data.length > 0) {
            this.feedWithData(data);
        } else {
            this.buildRow();
        }
        this.syncRowsControls();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.updateElement({
            events: {
                click: this.handleClickInside.bind(this),
            },
        });
    }
}

UIDuplicate.defineElement('ui-duplicate', styles);
export { UIDuplicate };

import { UIElement } from '../ui-element.js';
import { Labels } from '../../global/labels.js';
import { insertElements } from '../../global/render-api.js';
import { rebuildChildren } from '../../global/ui-helpers.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-upload-item.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIUploadItem
 * @element ui-upload-item
 * @classdesc Represents a class for <code>ui-upload-item</code> element.
 * @fires event:remove-upload-item
 * @property {string} filename {@attr filename} File name.
 * @property {string} filesize {@attr filesize} File size placeholder.
 * @property {string} link {@attr link} File URL that can be used to reference
 * the contents of the file.
 * @property {HTMLDivElement} wrapper {@readonly} Shortcut to item wrapper.
 * @property {HTMLButtonElement} removeAction {@readonly} Shortcut to remove button element.
 * @example
 * <ui-upload-item filename="Third file" link="#" filesize="35 Mb"></ui-upload-item>
 */
class UIUploadItem extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                filename: String,
                filesize: String,
                link: String,
            },
            children: {
                wrapper: '.ui-upload-item__wrapper',
                removeAction: '.ui-upload-item__button.-remove',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['filename', 'filesize', 'link'];
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-upload-item', {
            remove: 'Remove',
        });
    }

    /**
     * Build config for uploaded file item.
     * @returns {Array<IElementConfig>}
     */
    buildFileItem() {
        return [
            {
                tagName: 'a',
                attributes: {
                    class: 'ui-upload-item__link',
                    href: this.link || null,
                    target: '_blank',
                },
                children: [this.filename || ''],
            },
            this.filesize && {
                tagName: 'span',
                classList: {
                    'ui-upload-item__detail': true,
                },
                children: ['(' + this.filesize + ')'],
            },
            {
                tagName: 'button',
                attributes: {
                    class: 'ui-upload-item__button -remove -iconed',
                    title: UIUploadItem.labels.remove,
                    type: 'button',
                    'data-event': 'remove-upload-item',
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'cross',
                            bgcolor: UIIcon.colors.ALERT_RED,
                        },
                    },
                    {
                        tagName: 'span',
                        children: [UIUploadItem.labels.remove],
                    },
                ],
            },
        ].filter(Boolean);
    }

    /**
     * Removes the file element.
     */
    remove() {
        this.hide();
        this.parentElement.removeChild(this);
    }

    /**
     * Handle remove action for ui-upload-item
     */
    handleRemoveAction() {
        this.remove();
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'filename':
            case 'filesize':
            case 'link':
                rebuildChildren(this.wrapper, this.buildFileItem());
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        insertElements(this, [
            {
                tagName: 'div',
                classList: {
                    'ui-upload-item__wrapper': true,
                },
                children: this.buildFileItem(),
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleRemoveAction = this.handleRemoveAction.bind(this);

        if (this.removeAction) {
            this.removeAction.addEventListener(
                'click',
                this.handleRemoveAction
            );
        }
    }
}

UIUploadItem.defineElement('ui-upload-item', styles);
export { UIUploadItem };

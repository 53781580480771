import { UIElement } from '../ui-element.js';
import { rebuildChildren } from '../../global/ui-helpers.js';
import { html } from '../../global/template-literals.js';
import styles from './ui-icon-flag.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIIconFlag
 * @element ui-icon-flag
 * @classdesc Represents a class for <code>ui-icon-flag</code> element.
 * @property {UIFlagGlyph} code {@attr code} Flag for the inner content.
 * @example
 * <ui-icon-flag code="ee"></ui-icon-flag>
 */
class UIIconFlag extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                code: String,
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['code'];
    }

    /**
     * Render or rebuild ui-icon mark up.
     */
    renderIconLayout() {
        const flag = html` <span class="${'-flag ' + this.code}"></span> `;
        rebuildChildren(this, this.code ? flag : []);
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'code':
                this.renderIconLayout();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.renderIconLayout();
    }
}

UIIconFlag.defineElement('ui-icon-flag', styles);
export { UIIconFlag };

/**
 * @classdesc Class for ui-dynamic-table pagination plugin.
 * @alias UIClientSortPlugin
 * @implements {IComponentPlugin}
 * @memberof {SharedComponents}
 */
class UIClientSortPlugin {
    /**
     * @param {UIDynamicTable} target Target instance.
     * @property {UIDynamicTable} target Target instance.
     */
    constructor(target) {
        this.target = target;
    }

    /**
     * @inheritDoc
     */
    handleSortTable(e) {
        this.target.sortDir = e.detail.direction === 1 ? 'asc' : 'desc';
        this.target.sortOrder = e.detail.column;

        this.target.rebuildChildren.call(
            this.target.tbody,
            this.target._records
                .sort(this.target.buildCompareFn())
                .slice(
                    0,
                    this.target.limit > 0
                        ? this.target.limit
                        : this.target._records.length
                )
                .map(this.target.buildTableRow.bind(this.target))
        );
        this.target.querySelector('ui-table').runLifecycleHook('prepareLayout');
    }

    /**
     * @inheritDoc
     */
    render() {}

    /**
     * @inheritDoc
     */
    hydrate() {
        this.target.addEventListener(
            'sort-table',
            this.handleSortTable.bind(this)
        );
    }
}

export { UIClientSortPlugin };

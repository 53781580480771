import { UIElement } from '../ui-element.js';
import styles from './ui-option-icon.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionIcon
 * @element ui-option-icon
 * @classdesc Represents a class for <code>ui-option-icon</code> element.
 * Similar to ui-option, but holds icon. Used with combination ui-dropdown.
 * @property {boolean} control Flag to distinguish the location where option will be shown.
 * @property {UIIconGlyph} [icon] {@attr icon} Icon glyph.
 * @property {UIIconColor} [color] {@attr color} icon color.
 * @slot
 * @example
 * <ui-dropdown layout="icon">
 *   <select>
 *     <option data-color="pineapple" data-icon="star-full">Superstar</option>
 *     <option data-color="red" data-icon="heart" value="health">Health</option>
 *   </select>
 * </ui-dropdown>
 */
class UIOptionIcon extends UIElement {
    static get POPOVER_TYPE() {
        return 'basic';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                icon: String,
                color: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateClassList({
            '-control': this.control,
            'ui-option-icon': true,
        });

        this.insertElements([
            this.icon && {
                tagName: 'ui-icon',
                attributes: {
                    class: 'ui-option-icon__icon',
                    glyph: this.icon || '',
                    color: this.color,
                },
            },
            {
                tagName: 'span',
                attributes: {
                    class: 'ui-option-icon__label',
                },
                children: [
                    this.getAttribute('text') ||
                        this.getAttribute('value') ||
                        '',
                ],
            },
        ]);

        if (this.control) {
            this.insertElements([
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'dropdown',
                    },
                },
            ]);
        }
    }
}

UIOptionIcon.defineElement('ui-option-icon', styles);
export { UIOptionIcon };

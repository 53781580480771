import { UIElement } from '../ui-element.js';
import { UINavList } from './ui-navlist.js';
import { UINavToggle } from './ui-navtoggle.js';
import { eventBus } from '../../global/event-bus.js';
import { EventObserver } from '../../global/event-observer.js';
import { nextFrame } from '../../global/helpers.js';
import {
    closestEventHolder,
    focusInteractiveElement,
    insertElements,
    queryChildren,
    setAttributes,
    updateClassList,
    position,
    isVisible,
    isFormElement,
} from '../../global/ui-helpers.js';
import {
    isEscapePressed,
    isKeyPressed,
    keyCodes,
    keyDirections,
    TabIndex,
} from '../../global/keyboard.js';
import { Labels } from '../../global/labels.js';
import styles from './ui-nav.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UINav
 * @element ui-nav
 * @classdesc Represents a class for <code>ui-nav</code> element
 * @fires event:menu-toggle
 * @fires event:menu-open
 * @fires event:menu-close
 * @listens event:spa-navigation-change
 * @listens event:menu-toggle
 * @listens event:menu-search-toggle
 * @listens event:menu-back
 * @listens event:open-shortcuts
 * @listens event:edit-shortcuts
 * @listens click
 * @listens window.onpopstate
 * @property {INavigationConfig} config navigation config
 * @property {Function} buildLinkHandler function to build link handler
 * @property {boolean | attr} business - indicate if view is business, (affects breadcrumbs for eg)
 * @property {UIBreadCrumbs} breadcrumbs {@readonly}
 * @property {HTMLDivElement} primaryColumns {@readonly}
 * @property {HTMLDivElement} secondaryColumns {@readonly}
 * @property {HTMLDivElement} auxiliaryColumns {@readonly}
 * @property {HTMLDivElement} wrapper {@readonly}
 * @property {string | attr} label label for menu toggle button
 * @property {string | attr} labelSearch label for search input
 * @property {string | attr} labelSearchopen label for search open button
 * @property {string | attr} labelSearchclose label for search close button
 * @property {string | attr} homeId home id
 * @property {string | attr} useSearch indicates if search is used
 * @property {boolean | attr} simpleSearch - indicates if simple search is used
 * @property {string | attr} searchHomeId search home id
 * @property {string | attr} useHome indicates if home is used
 * @property {string | attr} useBreadcrumbs indicates if breadcrumbs are used
 * @property {string | attr} useShortcuts indicates if shortcuts are used
 * @property {"popover" | attr} layout - layout for ex., ui-nav inside ui-navtoggle's popover
 * @example
 * <ui-nav></ui-nav>
 */
class UINav extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return [
            'open',
            'dropdown',
            'label-search',
            'label',
            'business',
            'label-searchopen',
            'label-searchclose',
            'use-breadcrumbs',
        ];
    }

    /**
     * @type {number}
     */
    static get FOCUS_A11Y_DELAY() {
        return 100;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                open: Boolean,
                dropdown: Boolean,
                business: Boolean,
                homeId: String,
                useSearch: String,
                simpleSearch: Boolean,
                searchHomeId: String,
                useHome: String,
                useBreadcrumbs: String,
                useShortcuts: String,
                label: String,
                labelSearch: String,
                labelSearchopen: String,
                labelSearchclose: String,
                layout: String,
            },
            children: {
                breadcrumbs: 'ui-breadcrumbs',
                wrapper: '.ui-nav__columns-wrapper',
                primaryColumns: '.ui-nav__columns.-primary',
                secondaryColumns: '.ui-nav__columns.-secondary',
                auxiliaryColumns: '.ui-nav__columns.-auxiliary',
            },
        };
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-nav', {
            arrowKeys: 'Use arrow keys to navigate menu.',
        });
    }

    get desktopMediaQueryList() {
        return window.matchMedia('(min-width: 1024px)');
    }

    get mobileMediaQueryList() {
        return window.matchMedia('(max-width: 1023.98px)');
    }

    /**
     * @returns {boolean}
     */
    isSecondaryMenuOpen() {
        return this.secondaryColumns.classList.contains('-on');
    }

    /**
     * @returns {boolean}
     */
    isAuxiliaryMenuOpen() {
        return this.auxiliaryColumns.classList.contains('-on');
    }

    /**
     * @returns {boolean}
     */
    isDesktopMode() {
        return this.desktopMediaQueryList.matches;
    }

    /**
     * @returns {boolean}
     */
    isMobileMode() {
        return this.mobileMediaQueryList.matches;
    }

    /**
     * @returns {boolean}
     */
    isActiveDesktopMode() {
        return this.isDesktopMode() && this.dropdown;
    }

    /**
     * @returns {boolean}
     */
    isActiveMobileMode() {
        return this.isMobileMode() && this.open;
    }

    /**
     * @returns {boolean}
     */
    isSearchBarOpen() {
        return this.querySelector('.ui-nav__items').classList.contains(
            '-search-open'
        );
    }

    rebuildShortcuts(shortcuts) {
        const list = this.querySelector('ui-navlist[ref="shortcuts"]');
        const editMode = list.edit;

        if (editMode) {
            list.toggleEditMode();
        }

        this.rebuildChildren.call(
            list.querySelector('.ui-navlist-menu__items'),
            shortcuts.map((shortcut) => {
                return {
                    tagName: 'ui-navitem',
                    attributes: {
                        type: 'secondary-item',
                        label: shortcut.label || shortcut.labelENG,
                        'label-id': shortcut.labelENG,
                        item: shortcut.id,
                        href: this.buildLink(shortcut.id),
                        edit: list.edit ? '' : null,
                    },
                    children: [],
                };
            })
        );

        if (editMode) {
            list.toggleEditMode();
        }

        return this;
    }

    updateShortcuts(pageId) {
        const shortcutControl = this.querySelector(
            'ui-navitem[controls="shortcuts-nav-container"]'
        );
        if (!shortcutControl) {
            return;
        }
        const hasShortcut =
            (this.config.shortcutsList || []).filter(function (item) {
                return item.id === pageId;
            }).length > 0;

        shortcutControl.updateClassList({ '-selected': hasShortcut });
        const shortcutMenu = this.querySelector('ui-navlist[ref="shortcuts"]');

        shortcutMenu.setActiveShortcut(pageId);
    }

    /**
     * @param { IMenuItem } item
     * @returns { boolean }
     */
    isPrimaryItemExpandable(item) {
        return (
            [].filter.call(item.children || [], (child) => {
                if (!child.visible || !child.children) {
                    return false;
                }
                return (
                    [].filter.call(child.children, (grandChild) => {
                        return grandChild.visible;
                    }).length > 0
                );
            }).length > 0
        );
    }

    /**
     * @param { IMenuItem } item
     * @returns { boolean }
     */
    isSecondaryItemExpandable(item) {
        return (
            [].filter.call(item.children || [], (child) => {
                return child.visible;
            }).length > 0
        );
    }

    /**
     * Fires callback when user clicked
     * @param {string} url
     * @private
     */
    selectMenuItem(url) {
        this.selectMenuItemById(this.buildId(url));
    }

    /**
     * Fires callback when user clicked
     * @param {string} pageId
     * @private
     */
    selectMenuItemById(pageId) {
        const newBreadcrumbs = [];

        if (!this.isMobileMode()) {
            this.updateAuxiliaryMenu(pageId);
        }

        if (!pageId) {
            this.updateShortcuts(pageId);
            if (this.useBreadcrumbs !== 'off') {
                this.breadcrumbs.setItems([]);
            }
            return;
        }

        const navItemSelector =
            'ui-navitem:not([controls="shortcuts-nav-container"])';
        this.querySelectorAll(navItemSelector + '.-selected').forEach((item) =>
            item.deactivate()
        );

        if (pageId === this.homeId) {
            this.updateShortcuts(pageId);
            if (this.useBreadcrumbs !== 'off') {
                this.breadcrumbs.setItems([]);
            }
            const homeItem = this.querySelector(
                navItemSelector + '[item="' + pageId + '"]'
            );
            if (homeItem) {
                homeItem.activate();
                return;
            }
        }

        if (this.simpleSearch && pageId === this.searchHomeId) {
            const searchItem = this.querySelector(
                navItemSelector + '[item="search"]'
            );
            if (searchItem) {
                searchItem.activate();
                return;
            }
        }

        const ids = pageId.split('.');

        const lvl1 = ids.length > 1 ? [ids[0], ids[1]].join('.') : null;
        const lvl1Item = lvl1
            ? this.querySelector(navItemSelector + '[item="' + lvl1 + '"]')
            : null;

        const lvl4 =
            ids.length > 3 ? [ids[0], ids[1], ids[2], ids[3]].join('.') : null;
        const lvl4Item =
            lvl4 && lvl1Item
                ? lvl1Item
                      .getControlList()
                      .querySelector('ui-navitem[item="' + lvl4 + '"]')
                : null;

        const lvl2Item = lvl4Item ? lvl4Item.closest('ui-navlist-menu') : null;

        const lvl5 =
            ids.length > 4
                ? [ids[0], ids[1], ids[2], ids[3], ids[4]].join('.')
                : null;
        const lvl5Item = lvl5
            ? this.auxiliaryColumns.querySelector(
                  'ui-navitem[item="' + lvl5 + '"]'
              )
            : null;

        if (this.homeId && this.label) {
            newBreadcrumbs.push({
                label: this.label,
                href: this.buildLink(this.homeId),
            });
        }

        if (lvl1Item) {
            lvl1Item.activate();
            newBreadcrumbs.push({ label: lvl1Item.getAttribute('label') });
        }

        if (lvl2Item) {
            newBreadcrumbs.push({ label: lvl2Item.getAttribute('label') });
        }

        if (lvl4Item) {
            lvl4Item.activate();
            newBreadcrumbs.push({
                label: lvl4Item.getAttribute('label'),
                href: lvl5Item ? this.buildLink(lvl4) : null,
            });
        }

        if (lvl5Item) {
            lvl5Item.activate();
            newBreadcrumbs.push({ label: lvl5Item.getAttribute('label') });
        }

        this.updateShortcuts(pageId);
        if (this.useBreadcrumbs !== 'off') {
            this.breadcrumbs.setItems(newBreadcrumbs);
        }
    }

    /**
     * Build link for given id
     * @param { string } itemId
     * @returns { string }
     */
    buildLink(itemId) {
        if (typeof this.buildLinkHandler !== 'function') {
            return itemId;
        }
        return this.buildLinkHandler(itemId);
    }

    /**
     * Build id for given link
     * @param { string } url
     * @returns { string }
     */
    buildId(url) {
        if (typeof this.buildIdHandler !== 'function') {
            return url;
        }
        return this.buildIdHandler(url);
    }

    /**
     * Get id of current page
     * @returns { string }
     */
    currentPageId() {
        if (typeof this.currentIdHandler !== 'function') {
            return '';
        }
        return this.currentIdHandler();
    }

    /**
     * @param { Function } buildLinkHandler
     * @returns { UINav }
     */
    setBuildLinkHandler(buildLinkHandler) {
        if (typeof buildLinkHandler === 'function') {
            this.buildLinkHandler = buildLinkHandler;
        }
        return this;
    }

    /**
     * @param { Function } buildIdHandler
     * @returns { UINav }
     */
    setBuildIdHandler(buildIdHandler) {
        if (typeof buildIdHandler === 'function') {
            this.buildIdHandler = buildIdHandler;
        }
        return this;
    }

    /**
     * @param { Function } currentIdHandler
     * @returns { UINav }
     */
    setCurrentIdHandler(currentIdHandler) {
        if (typeof currentIdHandler === 'function') {
            this.currentIdHandler = currentIdHandler;
        }
        return this;
    }

    /**
     * Initialize menu using given config
     * @param { INavigationConfig } config
     * @returns { UINav }
     */
    init(config) {
        this.config = config;
        this.renderMenu(config);
        return this;
    }

    /**
     * Set visibility for a search bar
     * @param {boolean} state
     */
    toggleSearchBar(state) {
        updateClassList(this.querySelector('.ui-nav__items'), {
            '-search-open': state,
        });
    }

    /**
     * Open a secondary menu
     * @param {UINavItem} element
     * @param {UINavList} menu
     */
    openSecondaryMenu(element, menu) {
        if (this.isDesktopMode()) {
            this.dropdown = true;
        }

        updateClassList(this.primaryColumns, {
            '-on': false,
            '-off': true,
        });

        updateClassList(this.secondaryColumns, {
            '-on': true,
            '-off': false,
            '-expanded': true,
            '-next': !this.open,
        });

        updateClassList(this.auxiliaryColumns, {
            '-on': false,
            '-off': false,
            '-next': true,
        });

        // debugger;
        // it closes previously opened menus
        this.closeSecondaryMenu();

        updateClassList(menu, {
            '-expanded': true,
        });

        this.setAttributes.call(menu, {
            'aria-hidden': 'false',
        });

        if (this.layout === 'popover' && this.isDesktopMode()) {
            const popover = this.closest('.ui-navtoggle__content');
            const modal = popover.closest('ui-modal');
            const maxHeight = UINavToggle.resolvePopoverMaxHeight(
                modal?.getBoundingClientRect().top
            );
            popover.style.height =
                (maxHeight > this.secondaryColumns.clientHeight
                    ? this.secondaryColumns.clientHeight
                    : maxHeight) + 'px';
            this.secondaryColumns.style.height = '100%';
        }
        this.handleFocusMobileMenu(menu.querySelectorAll('ui-navitem'));
    }

    /**
     * Focuses mobile menu navitem;
     * @param {NodeList<UINavItem>} items
     */
    handleFocusMobileMenu(items) {
        if (!this.isMobileMode()) {
            return;
        }
        const firstInteractive = [].filter.call(items, (i) => {
            return isVisible(i);
        })[0];
        // Delay to finish animation.
        if (firstInteractive && firstInteractive.firstElementChild) {
            setTimeout(
                () => firstInteractive.firstElementChild.focus(),
                UINav.FOCUS_A11Y_DELAY
            );
        }
    }

    /**
     * Closes a secondary menu
     */
    closeSecondaryMenu() {
        const targetMenu = this.querySelector(
            'ui-navlist.-secondary.-expanded'
        );

        updateClassList(this.secondaryColumns, {
            '-next': this.open && !this.isSecondaryMenuOpen(),
        });

        if (targetMenu) {
            updateClassList(targetMenu, {
                '-expanded': false,
            });

            setAttributes(targetMenu, {
                'aria-hidden': 'true',
            });
        }

        if (!this.dropdown) {
            const activePrimary = this.querySelector('ui-navitem.-active');
            if (activePrimary) {
                activePrimary.updateClassList({ '-active': false });
            }
        }

        this.primaryColumns.querySelectorAll('a').forEach((el) => {
            el.setAttribute('aria-expanded', 'false');
        });
    }

    /**
     * Update auxiliary menu visibility
     * @param {string} itemId
     */
    updateAuxiliaryMenu(itemId) {
        const ids = itemId ? itemId.split('.') : [];
        const lvl4 =
            ids.length > 3 ? [ids[0], ids[1], ids[2], ids[3]].join('.') : null;
        const auxiliaryMenu = lvl4
            ? this.auxiliaryColumns.querySelector(
                  'ui-navlist[ref="' + lvl4 + '"]'
              )
            : null;

        this.closeAuxiliaryMenu();

        if (auxiliaryMenu) {
            updateClassList(this.secondaryColumns, {
                '-on': false,
                '-off': true,
                '-expanded': false,
                '-next': false,
            });

            updateClassList(this.auxiliaryColumns, {
                '-on': true,
                '-off': false,
                '-next': false,
            });

            auxiliaryMenu.activate();
            const mainLink = auxiliaryMenu.querySelector(
                'ui-navitem.-frontpage-link'
            );
            if (mainLink) {
                mainLink.activate();
            }
        } else {
            updateClassList(this.primaryColumns, {
                '-off': false,
                '-on': true,
            });
        }
    }

    /**
     * Closes an auxiliary menu
     */
    closeAuxiliaryMenu() {
        updateClassList(this.secondaryColumns, {
            '-off': false,
        });

        updateClassList(this.auxiliaryColumns, {
            '-on': false,
            '-off': false,
            '-next': false,
        });

        this.auxiliaryColumns
            .querySelectorAll('ui-navlist.-expanded')
            .forEach((item) => item.deactivate());
    }

    /**
     * Close menu if opened
     */
    close() {
        if (!this.isActiveDesktopMode() && !this.isActiveMobileMode()) {
            return;
        }

        this.dropdown = false;

        updateClassList(this.primaryColumns, {
            '-off': false,
            '-on': true,
        });

        updateClassList(this.secondaryColumns, {
            '-off': this.isActiveDesktopMode(),
            '-on': false,
            '-expanded': false,
            '-next': true,
        });

        updateClassList(this.auxiliaryColumns, {
            '-off': false,
            '-on': false,
            '-next': false,
        });

        if (this.isActiveMobileMode()) {
            this.closeAuxiliaryMenu();
        }

        const activePrimary = this.querySelector('ui-navitem.-active');
        if (activePrimary) {
            activePrimary.updateClassList({ '-active': false });
        }

        if (this.layout === 'popover' && this.isDesktopMode()) {
            const popover = this.closest('.ui-navtoggle__content');
            popover.removeAttribute('style');
            this.secondaryColumns.removeAttribute('style');
        }

        if (this.isDesktopMode()) {
            this.dispatchCustomEvent('menu-close', {});
        }
    }

    /**
     * @param {UINavList} navList
     */
    backFromList(navList) {
        if (navList.type === 'secondary') {
            this.close();
        } else if (navList.type === 'auxiliary') {
            const ids = navList.getAttribute('ref').split('.');
            const lvl2 = ids.length > 1 ? [ids[0], ids[1]].join('.') : null;
            const lvl2Item = lvl2
                ? this.secondaryColumns.querySelector(
                      'ui-navlist[ref="' + lvl2 + '"]'
                  )
                : null;
            this.openSecondaryMenu(null, lvl2Item);
        }
        this.handleFocusMobileMenu(this.querySelectorAll('ui-navitem'));
    }

    /**
     * Fires callback when navigation is changed
     * @param {CustomEvent} event
     * @listens event:spa-navigation-change
     * @fires menu-toggle
     * @private
     */
    handleNavigationChange(event) {
        if (this.isActiveDesktopMode() || this.isActiveMobileMode()) {
            this.close();
        }
        this.selectMenuItem(event.detail.urlPath);
        if (this.isMobileMode()) {
            this.dispatchCustomEvent('menu-toggle', {
                active: false,
            });
        }
    }

    /**
     * Fires callback when user clicked
     * @param {CustomEvent | PointerEvent} event
     * @private
     */
    handleDocumentClicks(event) {
        if (!this.isParentOf(event.target)) {
            this.handleClickOutside(event);
        }
    }

    /**
     * Fires callback when media query changed
     * @param {*} event
     * @private
     */
    handleMobileMediaChanges(event) {
        const navBack = this.querySelector('.-backnav');
        if (event.matches) {
            if (this.isSearchBarOpen()) {
                this.toggleSearchBar(false);
                this.querySelector('ui-navsearch').active = false;
            }
            this.open = false;
            this.dropdown = false;
            this.dispatchCustomEvent('menu-toggle', {
                active: false,
            });
            if (this.querySelector('ui-navitem.-active')) {
                this.querySelector('ui-navitem.-active').classList.remove(
                    '-active'
                );
            }
            if (navBack) {
                navBack.tabIndex = TabIndex.Active;
            }
        } else {
            document.documentElement.classList.remove('-navtoggle-expanded');
            this.updateAuxiliaryMenu(this.currentPageId());
            if (navBack) {
                navBack.tabIndex = TabIndex.Inactive;
            }
        }
    }

    /**
     * Fires callback when user clicked outside of navigation element
     * @param {MouseEvent | KeyboardEvent} event
     * @private
     */
    handleClickOutside(event) {
        if (event.defaultPrevented) {
            return;
        }
        if (this.isActiveDesktopMode()) {
            this.close();
        }

        const toggleElement = document.querySelector(
            'ui-navtoggle[for=nav-main]'
        );
        const isToggle =
            toggleElement && toggleElement.isParentOf(event.target);

        if (this.isActiveMobileMode() && !isToggle) {
            this.dispatchCustomEvent('menu-toggle', {
                active: false,
            });
            if (toggleElement && toggleElement.firstElementChild) {
                toggleElement.firstElementChild.focus();
            }
        }
    }

    /**
     * @param  {DeclarativeEvent} e
     */
    handleOpenShortcuts(e) {
        this.handleOpenSecondaryMenu(
            e.detail.originalEvent,
            e.detail.relatedTarget
        );
    }

    /**
     * @param  {DeclarativeEvent} e
     */
    handleEditShortcuts(e) {
        e.detail.originalEvent.preventDefault();
        const navList = e.detail.relatedTarget.closest('ui-navlist');
        navList.toggleEditMode();
    }

    /**
     * Fires callback when user clicked inside of navigation element
     * @param {MouseEvent | KeyboardEvent} event
     * @private
     */
    handleClickInside(event) {
        const eventHolder = closestEventHolder(event.target, this);
        if (eventHolder) {
            return;
        }

        // Apparently it could be simplified with declarative events
        if (this.isActiveMobileMode()) {
            if (
                event.target.classList.contains('ui-nav__columns') ||
                event.target.parentElement.classList.contains(
                    'ui-nav__columns'
                ) ||
                event.target === this
            ) {
                this.dispatchCustomEvent('menu-toggle', {
                    active: false,
                });
                return;
            }
        }

        const closestNavItem = event.target.closest('ui-navitem');
        if (closestNavItem) {
            if (closestNavItem.isPrimaryItem()) {
                this.handleOpenSecondaryMenu(event, closestNavItem);
            }
            if (closestNavItem.isSecondaryItem()) {
                this.handleOpenAuxiliaryMenu(event, closestNavItem);
            }
        }
    }

    /**
     * @param {string} itemId
     * @returns {boolean}
     */
    canOpenMenu(itemId) {
        return (
            itemId &&
            this.config &&
            (itemId === 'shortcuts' ||
                this.config.menuStructure.children.filter((menuitem) => {
                    return (
                        itemId === menuitem.id &&
                        menuitem.children &&
                        menuitem.children.length > 0
                    );
                }).length > 0)
        );
    }

    /**
     * Opens secondary menu.
     * @param {MouseEvent | KeyboardEvent} event
     * @param {UINavItem} navItem
     */
    handleOpenSecondaryMenu(event, navItem) {
        const itemId = navItem.getAttribute('item');
        if (!this.canOpenMenu(itemId) && this.config) {
            return;
        }
        const menuId = navItem.getControlId();

        const menu = this.querySelector(
            'ui-navlist.-secondary[id="' + menuId + '"]'
        );
        if (menu) {
            if (event) {
                event.preventDefault();
            }

            if (navItem.labelBack) {
                menu.setAttribute('label-back', navItem.labelBack);
            }
            const previous = this.querySelector('ui-navitem.-active');
            if (previous) {
                previous.updateClassList({ '-active': false });
                navItem.firstElementChild.setAttribute(
                    'aria-expanded',
                    'false'
                );
            }
            if (previous !== navItem) {
                navItem.updateClassList({ '-active': true });
                navItem.firstElementChild.setAttribute('aria-expanded', 'true');
                this.openSecondaryMenu(navItem, menu);

                if (this.isDesktopMode()) {
                    this.dispatchCustomEvent('menu-open', {});
                }
            } else {
                this.close();
            }
        }
    }

    handleOpenAuxiliaryMenu(event, navItem) {
        if (!navItem.expandable) {
            return;
        }

        const itemId = navItem.getAttribute('item');
        const auxiliaryMenu = this.auxiliaryColumns.querySelector(
            'ui-navlist[ref="' + itemId + '"]'
        );

        if (auxiliaryMenu && this.isActiveMobileMode()) {
            event.preventDefault();
            this.updateAuxiliaryMenu(itemId);
        }
    }

    /**
     * Fires callback when menu state is updated
     * @private
     */
    onChangeOpenState() {
        this.updateClassList({
            '-open': this.open,
        });

        if (this.isDesktopMode()) {
            return;
        }

        if (this.open) {
            nextFrame(() =>
                document.documentElement.classList.add('-navtoggle-expanded')
            );
            this.dispatchCustomEvent('menu-open', {});
        } else {
            document.documentElement.classList.remove('-navtoggle-expanded');
            this.dispatchCustomEvent('menu-close', {});
        }
    }

    /**
     * Fires callback when environment state is updated
     * @private
     */
    onChangeEnvironment() {
        if (!this.breadcrumbs) {
            return;
        }
        this.breadcrumbs.business = this.business;
    }

    /**
     * Fires callback when dropdown state is updated
     * @private
     */
    onChangeDropdownState() {
        this.updateClassList({
            '-dropdown': this.dropdown,
        });
    }

    /**
     * Fires callback when menu is toggled
     * @param {CustomEvent} e
     * @private
     */
    handleToggleMenu(e) {
        this.open = e.detail.for === this.id && !!e.detail.active;

        updateClassList(this.secondaryColumns, {
            '-next': this.open && !this.isSecondaryMenuOpen(),
        });

        this.handleFocusMobileMenu(
            this.primaryColumns.querySelectorAll('ui-navitem')
        );
        if (
            this.isActiveMobileMode() &&
            (this.isSecondaryMenuOpen() || this.isAuxiliaryMenuOpen())
        ) {
            this.close();
        }
    }

    /**
     * Fires callback when searchbar is toggled
     * @param {CustomEvent|Event} e
     * @private
     */
    handleToggleSearch(e) {
        if (this.isActiveDesktopMode()) {
            this.close();
        }
        this.toggleSearchBar(!!e.detail.active);
    }

    /**
     * Fires callback when back button pressed
     * @param {Event} e
     * @private
     */
    handleBackMenu(e) {
        const closestNavList = e.target.closest('ui-navlist');

        if (!closestNavList || !this.contains(closestNavList)) {
            return;
        }

        this.backFromList(closestNavList);
    }

    /**
     * Fires callback when popover with ui-nav was closed
     * @param {Event} e
     * @private
     */
    handleNavtoggleClose(e) {
        if (this.layout === 'popover') {
            if (this.isActiveDesktopMode()) {
                this.close();
            } else if (this.isSecondaryMenuOpen()) {
                updateClassList(this.primaryColumns, {
                    '-off': false,
                    '-on': true,
                });

                updateClassList(this.secondaryColumns, {
                    '-off': this.isActiveDesktopMode(),
                    '-on': false,
                    '-expanded': false,
                    '-next': true,
                });

                updateClassList(this.auxiliaryColumns, {
                    '-off': false,
                    '-on': false,
                    '-next': false,
                });
                this.closeSecondaryMenu();
                this.closest('.ui-navtoggle__content').removeAttribute('style');
            }
        }
    }

    /**
     * Fires callback when browser history is updated
     * @param {Event} e
     * @private
     */
    handleBrowserHistoryState(e) {
        const pathname = e.target.location.pathname.replace('/', '');
        this.selectMenuItem(pathname);
    }

    /**
     * Initialize menu using given config
     * @param { INavigationConfig } config
     * @returns { UINav }
     */
    renderMenu(config) {
        const items = config.menuStructure.children || [];

        /**
         * @type {Array<IElementConfig>}
         */
        const primaryMenu = [];

        /**
         * @type {Array<IElementConfig>}
         */
        const secondaryMenu = [];

        /**
         * @type {Array<IElementConfig>}
         */
        let auxiliaryMenu = [];

        if (this.useHome !== 'off' && this.homeId) {
            primaryMenu.push({
                tagName: 'ui-navitem',
                attributes: {
                    type: 'primary-icon',
                    glyph: 'home',
                    label: this.label,
                    item: this.homeId,
                    href: this.buildLink(this.homeId),
                },
            });
        }

        if (
            Array.isArray(config.shortcutsList) &&
            this.useShortcuts !== 'off'
        ) {
            primaryMenu.push({
                tagName: 'ui-navitem',
                attributes: {
                    type: 'primary-icon',
                    glyph: 'favorites',
                    label: UINavList.labels.MY_SHORTCUTS,
                    item: 'shortcuts',
                    controls: 'shortcuts-nav-container',
                    'data-event': 'open-shortcuts',
                },
            });
            secondaryMenu.push(
                this.buildNavList({
                    id: 'shortcuts',
                    children: [
                        {
                            labelENG: UINavList.labels.MY_SHORTCUTS,
                            visible: true,
                            children: config.shortcutsList
                                .slice(
                                    0,
                                    Math.min(
                                        config.shortcutsList.length,
                                        config.maxShortcuts
                                    )
                                )
                                .map((item) => {
                                    item.visible = true;
                                    return item;
                                }),
                        },
                    ],
                })
            );
        }

        [].forEach.call(
            items,
            /** @param {IMenuItem} item - menu item */
            (item) => {
                if (!item.visible) {
                    return;
                }

                /**
                 * @type {IElementConfig}
                 */
                const menuItem = {
                    tagName: 'ui-navitem',
                    attributes: {
                        type: 'primary-item',
                        label: item.label || item.labelENG,
                        'label-id': item.labelENG,
                        item: item.id,
                        controls: item.id + '-nav-container',
                        href: this.buildLink(item.id),
                    },
                    children: [],
                };

                primaryMenu.push(menuItem);
                if (this.isPrimaryItemExpandable(item)) {
                    secondaryMenu.push(this.buildNavList(item));
                }
                auxiliaryMenu = auxiliaryMenu.concat(
                    this.buildAuxiliaryNavLists(item)
                );
            }
        );

        if (this.simpleSearch) {
            primaryMenu.push({
                tagName: 'ui-navitem',
                attributes: {
                    type: 'primary-icon',
                    glyph: 'search-form',
                    label: this.labelSearch,
                    item: 'search',
                    href: this.buildLink(this.searchHomeId),
                },
            });
        } else if (this.useSearch !== 'off') {
            /**
             * @type {IElementConfig}
             */
            const searchMenuBtn = {
                tagName: 'ui-navsearch',
                attributes: {
                    label: this.labelSearch || 'Search',
                    'label-open': this.labelSearchopen || 'Open search form',
                    'label-close': this.labelSearchclose || 'Close search form',
                    class: '-search',
                },
            };
            primaryMenu.push(searchMenuBtn);
        }

        const primaryMenuContainer = this.querySelector('.ui-nav__items');
        const customItems = queryChildren(
            primaryMenuContainer,
            'ui-navitem[type="custom"]'
        );
        primaryMenuContainer.innerHTML = '';
        insertElements(primaryMenuContainer, primaryMenu);

        if (customItems.length) {
            const firstNavItem =
                primaryMenuContainer.querySelector('ui-navitem');
            customItems.forEach((item) =>
                primaryMenuContainer.insertBefore(item, firstNavItem)
            );
        }

        const secondaryMenuContainer = this.querySelector(
            '.ui-nav__columns.-secondary'
        );
        secondaryMenuContainer.innerHTML = '';
        insertElements(secondaryMenuContainer, secondaryMenu);

        const auxiliaryMenuContainer = this.querySelector(
            '.ui-nav__columns.-auxiliary'
        );
        auxiliaryMenuContainer.innerHTML = '';
        insertElements(auxiliaryMenuContainer, auxiliaryMenu);

        return this;
    }

    /**
     * @param {IMenuItem} item
     * @returns {IElementConfig}
     */
    buildNavList(item) {
        /**
         * @type {IElementConfig}
         */
        const itemNavList = {
            tagName: 'ui-navlist',
            attributes: {
                ref: item.id,
                id: item.id + '-nav-container',
            },
            children: [],
        };

        [].forEach.call(
            item.children || [],
            /** @param {IMenuItem} subItem - sub menu item */
            (subItem) => {
                if (!subItem.visible) {
                    return;
                }
                /** @type {IElementConfig} */
                const navListMenu = {
                    tagName: 'ui-navlist-menu',
                    attributes: {
                        label: subItem.label || subItem.labelENG,
                        'label-id': subItem.labelENG,
                    },
                    children: [],
                };

                [].forEach.call(
                    subItem.children || [],
                    /** @param {IMenuItem} subSubItem - sub menu item */
                    (subSubItem) => {
                        if (
                            !subSubItem.visible ||
                            !(subSubItem.targetPageId || subSubItem.id)
                        ) {
                            return;
                        }
                        /** @type {IElementConfig} */
                        const subMenuItem = {
                            tagName: 'ui-navitem',
                            attributes: {
                                type: 'secondary-item',
                                label: subSubItem.label || subSubItem.labelENG,
                                'label-id': subSubItem.labelENG,
                                badge: subSubItem.badge || null,
                                item: subSubItem.id,
                                href: this.buildLink(
                                    subSubItem.targetPageId || subSubItem.id
                                ),
                                expandable:
                                    this.isSecondaryItemExpandable(
                                        subSubItem
                                    ) || null,
                            },
                            children: [],
                        };
                        navListMenu.children.push(subMenuItem);
                    }
                );

                if (
                    navListMenu.children.length > 0 ||
                    item.id === 'shortcuts'
                ) {
                    itemNavList.children.push(navListMenu);
                }
            }
        );
        return itemNavList;
    }

    /**
     * @param {IMenuItem} item
     * @returns {Array<IElementConfig>}
     */
    buildAuxiliaryNavLists(item) {
        /**
         * @type {Array<IElementConfig>}
         */
        const auxiliaryNavLists = [];

        [].forEach.call(
            item.children || [],

            /** @param {IMenuItem} subItem - sub menu item */
            (subItem) => {
                if (!subItem.visible) {
                    return;
                }

                [].forEach.call(
                    subItem.children || [],
                    /** @param {IMenuItem} subSubItem - sub menu item */
                    (subSubItem) => {
                        if (!subSubItem.visible) {
                            return;
                        }

                        /**
                         * @type {IElementConfig}
                         */
                        const itemNavList = {
                            tagName: 'ui-navlist',
                            attributes: {
                                type: 'auxiliary',
                                ref: subSubItem.id,
                            },
                            children: [
                                {
                                    tagName: 'ui-navitem',
                                    attributes: {
                                        class: '-frontpage-link',
                                        type: 'auxiliary-item',
                                        label:
                                            subSubItem.label ||
                                            subSubItem.labelENG,
                                        'label-id': subSubItem.labelENG,
                                        badge: subSubItem.badge || null,
                                        item: subSubItem.id,
                                        href: this.buildLink(
                                            subSubItem.targetPageId ||
                                                subSubItem.id
                                        ),
                                    },
                                    children: [],
                                },
                            ],
                        };

                        /** @type {IElementConfig} */
                        const auxiliaryMenuItems = {
                            tagName: 'ui-columns',
                            attributes: {
                                wrap: 'wrap',
                                halign: 'left',
                            },
                            children: [],
                        };

                        [].forEach.call(
                            subSubItem.children || [],
                            /** @param {IMenuItem} auxiliaryItem - auxiliary (fifth level) menu item */
                            (auxiliaryItem) => {
                                if (!auxiliaryItem.visible) {
                                    return;
                                }
                                /** @type {IElementConfig} */
                                const auxiliaryMenuItem = {
                                    tagName: 'ui-navitem',
                                    attributes: {
                                        type: 'auxiliary-item',
                                        label:
                                            auxiliaryItem.label ||
                                            auxiliaryItem.labelENG,
                                        'label-id': auxiliaryItem.labelENG,
                                        badge: auxiliaryItem.badge || null,
                                        item: auxiliaryItem.id,
                                        href: auxiliaryItem.href
                                            ? auxiliaryItem.href
                                            : this.buildLink(
                                                  auxiliaryItem.targetPageId ||
                                                      auxiliaryItem.id
                                              ),
                                    },
                                    children: [],
                                };
                                auxiliaryMenuItems.children.push(
                                    auxiliaryMenuItem
                                );
                            }
                        );

                        if (auxiliaryMenuItems.children.length > 0) {
                            itemNavList.children.push(auxiliaryMenuItems);
                            auxiliaryNavLists.push(itemNavList);
                        }
                    }
                );
            }
        );
        return auxiliaryNavLists;
    }

    /**
     * @inheritDoc
     */
    onChangeSearchLabels() {
        const searchItem = this.querySelector('ui-navsearch');
        if (!searchItem) {
            return;
        }
        searchItem.updateElement({
            attributes: {
                label: this.labelSearch || 'Search',
                'label-open': this.labelSearchopen || 'Open search form',
                'label-close': this.labelSearchclose || 'Close search form',
            },
        });
    }

    /**
     * Handles keyboard shortcuts.
     * @param {KeyboardEvent} event
     */
    handleEscape(event) {
        if (isEscapePressed(event)) {
            const activeMenu = this.getSelectedMenu();
            if (activeMenu && this.isDesktopMode()) {
                activeMenu.querySelector('a,button').focus();
            }
            this.handleClickOutside(event);
        }
    }

    /**
     * @returns {UINavItem|HTMLElement}
     */
    getSelectedMenu() {
        return this.querySelector('.-primary.-active');
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'business':
                this.onChangeEnvironment();
                break;
            case 'open':
                this.onChangeOpenState();
                break;
            case 'dropdown':
                this.onChangeDropdownState();
                break;
            case 'label':
            case 'label-searchopen':
            case 'label-searchclose':
                this.onChangeSearchLabels();
                break;
            case 'use-breadcrumbs':
                if (this.useBreadcrumbs === 'off' && this.breadcrumbs) {
                    this.removeChild(this.breadcrumbs);
                }
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * Accessibility. Navigation with keyboard.
     * @param {KeyboardEvent} event
     */
    handlePrimaryMenuKeyboard(event) {
        if (isFormElement(event.target)) {
            return;
        }
        const items = this.querySelectorAll(
            '.ui-nav__items ui-navitem, .ui-nav__items ui-navsearch'
        );
        const parent = event.target.closest('ui-navitem, ui-navsearch');
        const active = [].indexOf.call(items, parent);
        let nextActive = active + keyDirections[event.keyCode];
        if (
            nextActive < 0 ||
            keyCodes.END === event.keyCode ||
            keyCodes.PAGEDOWN === event.keyCode
        ) {
            nextActive = items.length - 1;
        } else if (
            nextActive > items.length - 1 ||
            keyCodes.HOME === event.keyCode ||
            keyCodes.PAGEUP === event.keyCode
        ) {
            nextActive = 0;
        }

        const focusInSecondaryMenu = (focusLast = false) => {
            if (this.isSecondaryMenuOpen()) {
                const controls = event.target.getAttribute('aria-controls');
                let navlist = null;
                if (controls) {
                    navlist = document.getElementById(controls);
                }
                if (navlist) {
                    if (focusLast) {
                        const a = [
                            ...navlist.querySelectorAll('a,button'),
                        ].pop();
                        a && a.focus();
                    } else {
                        focusInteractiveElement(navlist);
                    }
                }
            }
        };

        const areShortcuts = (ni) =>
            ni.parentElement.getAttribute('item') === 'shortcuts';

        switch (event.keyCode) {
            case keyCodes.LEFT:
            case keyCodes.RIGHT:
            case keyCodes.HOME:
            case keyCodes.END:
            case keyCodes.PAGEUP:
            case keyCodes.PAGEDOWN:
                if (items[nextActive]) {
                    // There is 2 buttons in ui-navsearch, we are interested in #nav-search-button.
                    const elem = items[nextActive].querySelector(
                        'a, #nav-search-button'
                    );
                    elem && elem.focus();
                }
                break;
            case keyCodes.DOWN:
            case keyCodes.ENTER:
            case keyCodes.SPACE:
                // on mobile use everything native.
                if (this.isMobileMode()) {
                    return;
                }
                if ([keyCodes.SPACE, keyCodes.DOWN].includes(event.keyCode)) {
                    event.preventDefault(); // dont break search
                }
                if (!this.isSecondaryMenuOpen()) {
                    if (areShortcuts(event.target)) {
                        this.handleOpenSecondaryMenu(
                            event,
                            event.target.parentElement
                        );
                    } else {
                        this.handleClickInside(event);
                    }
                }
                focusInSecondaryMenu();
                break;
            case keyCodes.UP:
                event.preventDefault();
                if (!this.isSecondaryMenuOpen()) {
                    if (areShortcuts(event.target)) {
                        this.handleOpenSecondaryMenu(
                            event,
                            event.target.parentElement
                        );
                    } else {
                        this.handleClickInside(event);
                    }
                }
                focusInSecondaryMenu(true);
                break;
            case keyCodes.TAB: {
                // Don't focus first element on desktop mode.
                if (this.isDesktopMode()) {
                    return;
                }
                const visibleItems = [].filter.call(items, (i) => isVisible(i));
                const submitSearchBtn =
                    document.getElementById('nav-search-submit');
                if (
                    submitSearchBtn === document.activeElement &&
                    !event.shiftKey
                ) {
                    event.preventDefault();
                    visibleItems[0] &&
                        visibleItems[0].querySelector('a,button').focus();
                }
                break;
            }
        }
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleSecondaryMenuKeyboard(event) {
        if (isFormElement(event.target)) {
            return;
        }
        const parent = event.target.closest('ui-navitem, .ui-navlist__actions');
        if (parent && parent.localName === 'ui-navsearch') {
            // ignore search events
            return;
        }
        const navlist = event.target.closest('ui-navlist');
        const items = this.isActiveMobileMode()
            ? navlist.querySelectorAll('ui-navitem, .-backnav')
            : navlist.querySelectorAll('ui-navitem');
        let active = [].indexOf.call(items, parent);

        const isBackNav =
            this.isActiveMobileMode() &&
            event.target.classList.contains('-backnav');
        if (
            isBackNav &&
            (isKeyPressed(event, keyCodes.ENTER) ||
                isKeyPressed(event, keyCodes.SPACE))
        ) {
            this.backFromList(navlist);
            return;
        }
        switch (event.keyCode) {
            case keyCodes.TAB:
                event.shiftKey ? --active : ++active;
                if (!isBackNav) {
                    event.preventDefault();
                }
                break;
            case keyCodes.UP:
                --active;
                event.preventDefault();
                break;
            case keyCodes.DOWN:
                ++active;
                event.preventDefault();
                break;
            case keyCodes.RIGHT:
            case keyCodes.LEFT: {
                event.preventDefault();
                const menus = navlist.querySelectorAll('ui-navlist-menu');
                const currentMenu = event.target.closest('ui-navlist-menu');
                const itemIndex = position(
                    document.activeElement.parentElement
                );
                const activeMenuIndex = [].indexOf.call(menus, currentMenu);
                let nextMenuIndex =
                    activeMenuIndex + keyDirections[event.keyCode];
                if (nextMenuIndex < 0) {
                    nextMenuIndex = menus.length - 1;
                } else if (nextMenuIndex > menus.length - 1) {
                    nextMenuIndex = 0;
                }
                const nextMenuItems =
                    menus[nextMenuIndex].querySelectorAll('ui-navitem');
                if (itemIndex > nextMenuItems.length - 1) {
                    active = [].indexOf.call(
                        items,
                        nextMenuItems[nextMenuItems.length - 1]
                    );
                } else {
                    active = [].indexOf.call(items, nextMenuItems[itemIndex]);
                }
                break;
            }
            case keyCodes.HOME:
            case keyCodes.PAGEUP:
                active = 0;
                event.preventDefault();
                break;
            case keyCodes.END:
            case keyCodes.PAGEDOWN:
                active = items.length - 1;
                event.preventDefault();
                break;
        }

        if (active < 0) {
            active = items.length - 1;
        } else if (active > items.length - 1) {
            active = 0;
        }

        if (items[active]) {
            if (items[active].localName === 'button') {
                items[active].focus();
            } else {
                items[active].querySelector('a,button').focus();
            }
        }
    }

    /**
     * @param {KeyboardEvent} event
     */
    handlePrimaryArrowKeys(event) {
        const lnk = event.target;
        const items = this.querySelectorAll('.ui-nav__items ui-navitem');
        const active = [].indexOf.call(items, lnk.parentElement);
        let nextActive = active + keyDirections[event.keyCode];
        if (nextActive < 0) {
            nextActive = items.length - 1;
        } else if (nextActive > items.length - 1) {
            nextActive = 0;
        }
        switch (event.keyCode) {
            case keyCodes.LEFT:
            case keyCodes.RIGHT:
                if (items[nextActive]) {
                    items[nextActive].querySelector('a').focus();
                }
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'menubar');
        this.setAttribute('aria-label', UINav.labels.arrowKeys);
        const items = this.queryChildren('ui-navitem');
        const menus = this.queryChildren('ui-navlist');
        const wrapper = this.wrapper;
        const breadcrumbs =
            this.useBreadcrumbs === 'off'
                ? null
                : this.createElement({
                      tagName: 'ui-breadcrumbs',
                      element: this.querySelector('ui-breadcrumbs'),
                      attributes: {
                          business: this.business ? 'business' : null,
                      },
                  });

        this.updateClassList({
            '-main': true,
            '-columns': true,
            '-dropdown': false,
        });

        this.insertAdjacentElement(
            'afterbegin',
            this.createElement({
                tagName: 'div',
                element: wrapper,
                attributes: {
                    class: 'ui-nav__columns-wrapper',
                },
                children: [
                    {
                        tagName: 'div',
                        classList: {
                            'ui-nav__columns': true,
                            '-primary': true,
                            '-on': true,
                        },
                        children: [
                            {
                                tagName: 'div',
                                classList: {
                                    'ui-nav__items': true,
                                },
                                children: items,
                            },
                        ],
                    },
                    {
                        tagName: 'div',
                        classList: {
                            'ui-nav__columns': true,
                            '-secondary': true,
                        },
                        children: menus,
                    },
                    {
                        tagName: 'div',
                        classList: {
                            'ui-nav__columns': true,
                            '-auxiliary': true,
                        },
                        children: this.getChildrenForSlot('menu-auxiliary'),
                    },
                ],
            })
        );

        if (breadcrumbs) {
            this.appendChild(breadcrumbs);
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleDocumentClicks = this.handleDocumentClicks.bind(this);
        this.handleClickInside = this.handleClickInside.bind(this);
        this.handleNavigationChange = this.handleNavigationChange.bind(this);
        this.handleMobileMediaChanges =
            this.handleMobileMediaChanges.bind(this);

        eventBus.addEventListener(
            'spa-navigation-change',
            this.handleNavigationChange
        );
        eventBus.addEventListener(
            'menu-toggle',
            this.handleToggleMenu.bind(this)
        );
        eventBus.addEventListener(
            'menu-search-toggle',
            this.handleToggleSearch.bind(this)
        );
        eventBus.addEventListener('menu-back', this.handleBackMenu.bind(this));
        document.addEventListener(
            'navtoggle-closed',
            this.handleNavtoggleClose.bind(this)
        );
        document.addEventListener('click', this.handleDocumentClicks);
        this.addEventListener('click', this.handleClickInside);
        this.mobileMediaQueryList.addListener(this.handleMobileMediaChanges);
        window.addEventListener(
            'popstate',
            this.handleBrowserHistoryState.bind(this)
        );

        this.clickObserver = new EventObserver({ name: 'click' });
        this.clickObserver.observe(this);
        this.addEventListener(
            'open-shortcuts',
            this.handleOpenShortcuts.bind(this)
        );
        this.addEventListener(
            'edit-shortcuts',
            this.handleEditShortcuts.bind(this)
        );
        this.addEventListener('keydown', this.handleEscape.bind(this));

        this.primaryColumns.addEventListener(
            'keydown',
            this.handlePrimaryMenuKeyboard.bind(this)
        );
        this.secondaryColumns.addEventListener(
            'keydown',
            this.handleSecondaryMenuKeyboard.bind(this)
        );
    }
}

UINav.defineElement('ui-nav', styles);
export { UINav };

import { UIElement } from '../ui-element.js';
import styles from './ui-datalist.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIDatalist
 * @element ui-datalist
 * @classdesc Represents a class for <code>ui-datalist</code> element.
 * Helpers element, which holds data as list. Used mostly with custom selects.
 * @slot
 * @example
 * <ui-datalist>
 *   <option value="name1">
 *   <option value="name2">
 *   <option value="family1">
 *   <option value="family2">
 * </ui-datalist>
 */
class UIDatalist extends UIElement {
    /**
     * Returns all options.
     * @returns {Array<HTMLOptionElement>}
     */
    getOptions() {
        return [...this.querySelectorAll('option,ui-option')];
    }

    /**
     * Returns attributes of given option.
     * @param {HTMLOptionElement} option Option inside datalist.
     * @param {boolean} [keepDatasetNames] Keeps dataset attribute names and
     * don't convert them in camel case.
     * @returns {object}
     */
    getOptionAttributes(option, keepDatasetNames = false) {
        const attributes = {};
        if (keepDatasetNames) {
            [].forEach.call(option.attributes, (attr) => {
                const name = attr.name.replace(/^data-/, '');
                attributes[name] = attr.value;
            });
        } else {
            for (const key in option.dataset) {
                // skip data-key attribute for purpose.
                if (option.dataset.hasOwnProperty(key) && key !== 'key') {
                    attributes[key] = option.dataset[key];
                }
            }
        }

        if (option.hasAttribute('value')) {
            attributes.value = option.value;
        }
        return attributes;
    }

    /**
     * @inheritDoc
     */
    render() {}
}

UIDatalist.defineElement('ui-datalist', styles);
export { UIDatalist };

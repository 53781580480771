import { UIElement } from '../ui-element.js';
import styles from './ui-products.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIProducts
 * @element ui-products
 * @classdesc Represents a class for <code>ui-products</code> element.
 * Container for <b>&lt;ui-product></b>. This element should contain only
 * <b>&lt;ui-product><b> elements.
 * @property {string} [label] {@attr label} Heading before section.
 * @property {("default" | "offer")} [layout=""] {@attr layout} Layout for products.
 *  {@desc offer: displays background as offer}
 * @slot {@type "ui-product"}
 * @example
 * <ui-products label="Related products">
 *   <ui-product title="Casco insurance" icon="transport">
 *     <p>This is a cool product</p>
 *   </ui-product>
 *   <ui-product title="Home insurance" icon="home">
 *     <p>This is a cool product</p>
 *   </ui-product>
 *   <ui-product title="Travel insurance" icon="sun">
 *     <p>This is a cool product</p>
 *   </ui-product>
 * </ui-products>
 */

class UIProducts extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: String,
                layout: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'group');
        if (this.label) {
            this.insertAdjacentHTML(
                'beforebegin',
                '<h2>' + this.label + '</h2>'
            );
        }
    }
}

UIProducts.defineElement('ui-products', styles);
export { UIProducts };

import { mergeAttributes } from '../../global/ui-helpers.js';

/**
 * Create an IElementConfig based on IFieldControl.
 * @param {IFieldControl} control
 * @returns {IElementConfig}
 * @private
 */
export function fromControlConfig(control) {
    const select = control.control || {};
    return {
        tagName: 'ui-dropdown',
        attributes: control.attributes,
        children: [
            {
                tagName: 'select',
                attributes: select.attributes,
                children:
                    'options' in select &&
                    select.options.map((option) => {
                        const additional = {};
                        if (option.data) {
                            for (const key in option.data) {
                                if (option.data.hasOwnProperty(key)) {
                                    additional['data-' + key] =
                                        option.data[key];
                                }
                            }
                        }
                        return {
                            tagName: 'option',
                            attributes: mergeAttributes(
                                {
                                    value: option.value,
                                    selected:
                                        option.value === select.value
                                            ? 'selected'
                                            : null,
                                },
                                additional
                            ),
                            children: [option.content],
                        };
                    }),
            },
        ],
    };
}

import { validationLabels } from '../components/form/validation-labels.js';
import { isEnterPressed } from './keyboard.js';
import { isVisible } from './ui-helpers.js';
import { UIHint } from '../components/hint/ui-hint.js';

/**
 * @memberof SharedComponents
 * @alias FormValidator
 * @classdesc Class for form validator, it implements native browser's validation.
 */
export class FormValidator {
    /**
     * @param {HTMLFormElement} form - HTML form element from to be validated.
     */
    constructor(form) {
        this._form = form;

        Object.defineProperty(form, 'validator', {
            value: this,
            writable: true,
        });

        // This function draws errors.
        this.errorPlacementCallback = (event) => {
            // Don't show default error message tooltip.
            event.preventDefault();

            const field = event.target && event.target.closest('ui-field');
            if (!field) {
                return;
            }
            const invalidElementsCount =
                field.querySelectorAll(':invalid').length;
            field
                .querySelectorAll('input:not(.-utility),select,textarea')
                .forEach((element) => {
                    if (
                        !element ||
                        !element.willValidate ||
                        !this.shouldFormValidate()
                    ) {
                        if (field && invalidElementsCount === 0) {
                            field.removeErrors(true);
                        }
                        return;
                    }
                    const results = [];
                    for (const rule in element.dataset) {
                        if (
                            element.dataset.hasOwnProperty(rule) &&
                            /Rule$/.test(rule)
                        ) {
                            const suffix = this.prepareSuffix(rule);
                            const validityName =
                                this.getRuleNameBySuffix(suffix);
                            const ruleCustom =
                                this.resolveCustomRule(validityName);
                            if (typeof ruleCustom === 'function') {
                                results.push(ruleCustom(element));
                            }
                        }
                    }
                    const validCustom = [].every.call(results, (e) => !!e);

                    const customBtn = field.querySelector(
                        '.ui-dropdown__custom button'
                    );
                    if (!element.validity.valid || !validCustom) {
                        const msg = this.translateError(
                            element,
                            validationLabels
                        );
                        const errorType = this.resolveValidityString(element);
                        element.setAttribute('data-error-type', errorType);
                        element.setAttribute('aria-invalid', 'true');
                        if (customBtn) {
                            customBtn.setAttribute('aria-invalid', 'true');
                        }
                        field.removeErrors(true);
                        field.addError(msg, errorType);
                    } else {
                        if (field && invalidElementsCount === 0) {
                            field.removeErrors(true);
                            element.removeAttribute('aria-invalid');
                            if (customBtn) {
                                customBtn.removeAttribute('aria-invalid');
                            }
                        }
                    }
                });
        };

        // Suffix is used for translation messages.
        this._RULES = {
            badInput: {
                suffix: 'input',
            },
            customError: {
                suffix: 'custom',
            },
            patternMismatch: {
                suffix: 'pattern',
                attributes: ['pattern'],
            },
            rangeOverflow: {
                suffix: 'rangeOverflow',
                attributes: ['max'],
            },
            rangeUnderflow: {
                suffix: 'rangeUnderflow',
                attributes: ['min'],
            },
            stepMismatch: {
                suffix: 'step',
                attributes: ['step'],
                formatter: (msg, elem) => {
                    const step = Number(elem.getAttribute('step'));
                    const value = Number(elem.value);
                    let low = value - (value % step);
                    let high = value + step - (value % step);
                    const precision = String(step).replace(
                        /^\d*\.?/,
                        ''
                    ).length;
                    if (precision) {
                        low = low.toFixed(precision);
                        high = high.toFixed(precision);
                    }
                    return this.formatMessage.apply(null, [
                        msg,
                        step,
                        low,
                        high,
                    ]);
                },
            },
            tooLong: {
                suffix: 'long',
                attributes: ['maxlength'],
            },
            tooShort: {
                suffix: 'short',
                attributes: ['minlength'],
            },
            typeMismatch: {
                suffix: 'type',
                attributes: ['type'],
            },
            valueMissing: {
                suffix: 'value',
            },
        };

        this.addCustomRule('fieldMismatch', {
            suffix: 'matches',
            attributes: [],
            formatter: (msg) => this.formatMessage.apply(null, [msg]),

            rule: (elem) => {
                const target = document.querySelector(elem.dataset.matchesRule);
                if (!target) {
                    return false;
                }
                target.onkeyup = () => elem.checkValidity();

                if (elem.value !== target.value) {
                    elem.validity._fieldMismatch = true;
                    elem.setCustomValidity(validationLabels.matches);
                } else {
                    elem.validity._fieldMismatch = false;
                    elem.setCustomValidity('');
                }
                return !elem.validity._fieldMismatch;
            },
        });

        this.addCustomRule('countCheckboxes', {
            suffix: 'checkboxes',
            attributes: [],
            formatter: (msg, elem) => {
                const field = elem.closest('ui-field');
                const num = field.querySelector('[data-checkboxes-rule]')
                    .dataset.checkboxesRule;
                const translation = field.querySelector(
                    '[data-checkboxes-error]'
                );
                if (translation) {
                    msg = translation.dataset.checkboxesError;
                }
                return this.formatMessage.apply(null, [msg, num]);
            },

            rule: (elem) => {
                const limit = Number(elem.dataset.checkboxesRule) || 1;
                const field = elem.closest('ui-field');
                let checkboxes;
                let grouped = false;
                if (elem.dataset.group) {
                    checkboxes = document.querySelectorAll(
                        `[data-group="${elem.dataset.group}"]`
                    );
                    grouped = true;
                } else {
                    checkboxes = field.querySelectorAll(
                        'input[type="checkbox"]'
                    );
                }
                const checked = [].reduce.call(
                    checkboxes,
                    (out, checkbox) => {
                        return out + Number(checkbox.checked);
                    },
                    0
                );

                [].forEach.call(checkboxes, (checkbox) => {
                    if (checked >= limit) {
                        elem.validity._countCheckboxes = false;
                        checkbox.setCustomValidity('');
                        checkbox.removeAttribute('required');
                        checkbox.removeAttribute('data-required');
                        if (grouped) {
                            try {
                                const f = checkbox.closest('ui-field');
                                f.removeErrors(true);
                            } catch (e) {
                                console.warn(
                                    elem.toString() + ' is not inside ui-field.'
                                );
                            }
                        }
                    } else {
                        elem.validity._countCheckboxes = true;
                        checkbox.setCustomValidity(validationLabels.checkboxes);
                        checkbox.setAttribute('required', 'required');
                        checkbox.setAttribute('data-required', '1');
                        if (grouped && elem !== checkbox) {
                            try {
                                const f = checkbox.closest('ui-field');
                                const hint = f.querySelector('ui-hint');
                                if (hint && hint.type === UIHint.types.ERROR) {
                                    hint.remove();
                                }
                                f.addError('');
                            } catch (e) {
                                console.warn(
                                    elem.toString() + ' is not inside ui-field.'
                                );
                            }
                        }
                    }
                });
                if (!grouped) {
                    field.removeErrors(true);
                }
                return !elem.validity._countCheckboxes;
            },
        });

        this.addCustomRule('requiredMultiple', {
            suffix: 'required-multiple',
            attributes: [],
            formatter: (msg, elem) => {
                const field = elem.closest('ui-field');
                const num = field.querySelector('[data-required-multiple-rule]')
                    .dataset.requiredMultipleRule;
                const translation = field.querySelector(
                    '[data-required-multiple-error]'
                );
                if (translation) {
                    msg = translation.dataset.requiredMultipleError;
                }
                return this.formatMessage.apply(null, [msg, num]);
            },
            rule: (elem) => {
                const limit = Number(elem.dataset.requiredMultipleRule) || 1;
                const field = elem.closest('ui-field');
                let inputs;
                let grouped = false;
                if (elem.dataset.group) {
                    inputs = document.querySelectorAll(
                        `[data-group="${elem.dataset.group}"]`
                    );
                    grouped = true;
                } else {
                    inputs = field.querySelectorAll('input,select,textarea');
                }
                const valid = [].reduce.call(
                    inputs,
                    (out, input) => {
                        return out + Number(!!input.value.trim());
                    },
                    0
                );

                inputs.forEach((input) => {
                    if (valid >= limit) {
                        elem.validity._requiredMultiple = false;
                        input.setCustomValidity('');
                        input.removeAttribute('required');
                        input.removeAttribute('data-required');
                        if (grouped) {
                            try {
                                const f = input.closest('ui-field');
                                f.removeErrors(true);
                            } catch (e) {
                                console.warn(
                                    elem.toString() + ' is not inside ui-field.'
                                );
                            }
                        }
                    } else {
                        elem.validity._requiredMultiple = true;
                        input.setCustomValidity(
                            validationLabels.requiredMultiple
                        );
                        input.setAttribute('required', 'required');
                        input.setAttribute('data-required', '1');
                        if (grouped && elem !== input) {
                            try {
                                const f = input.closest('ui-field');
                                const hint = f.querySelector('ui-hint');
                                if (hint && hint.type === UIHint.types.ERROR) {
                                    hint.remove();
                                }
                                f.addError('');
                            } catch (e) {
                                console.warn(
                                    elem.toString() + ' is not inside ui-field.'
                                );
                            }
                        }
                    }
                });
                if (!grouped) {
                    field.removeErrors(true);
                }
                return !elem.validity._requiredMultiple;
            },
        });

        this.addCustomRule('remoteValidation', {
            suffix: 'remote',
            rule: (elem) => {
                const src = elem.dataset.remoteRule;
                const uidata = document.createElement('ui-data');

                if (!elem.validity.valid) {
                    return true;
                } else {
                    elem.validity._remoteValidation = true;
                    uidata.type = 'application/json';
                    uidata.src = src;
                    uidata.get((resp) => {
                        if (resp.valid) {
                            elem.validity._remoteValidation = false;
                            elem.setCustomValidity('');
                        } else {
                            elem.validity._remoteValidation = true;
                            elem.setCustomValidity(resp.message);
                            this.addForcedErrorForElement(
                                elem,
                                resp.message,
                                'customError'
                            );
                        }
                    });
                }
                return elem.validity._remoteValidation;
            },
        });

        this.addCustomRule('onlyOneOfValidation', {
            suffix: 'only-one-of',
            rule: (elem) => {
                let elementsToValidate;
                const fieldset =
                    elem.closest('fieldset') || elem.closest('ui-fieldset');
                if (fieldset) {
                    elementsToValidate = Array.from(
                        fieldset.querySelectorAll('[data-only-one-of-rule]')
                    );
                } else {
                    const els = elem.form.querySelectorAll(
                        '[data-only-one-of-rule]'
                    );
                    elementsToValidate = [].filter.call(els, (el) => {
                        return (
                            !el.closest('fieldset') &&
                            !el.closest('ui-fieldset')
                        );
                    });
                }
                const result = elementsToValidate
                    .map((el) => !!el.value)
                    .filter((v) => !!v);

                if (result.length !== 1) {
                    elementsToValidate.forEach((el) => {
                        el.validity._onlyOneOfValidation = true;
                        el.setCustomValidity(validationLabels.onlyOneOf);
                        el.required = true;
                        const err = this.translateError(el, validationLabels);
                        const field = el.closest('ui-field');
                        field.removeErrors(true);
                        this.addForcedErrorForElement(el, err, 'customError');
                    });
                    return false;
                } else {
                    elementsToValidate.forEach((el) => {
                        el.validity._onlyOneOfValidation = false;
                        el.setCustomValidity('');
                        el.required = false;
                        const field = el.closest('ui-field');
                        field.removeErrors(true);
                    });
                    return true;
                }
                // return !elem.validity._onlyOneOfValidation;
            },
        });

        this.addCustomRule('maxfilesize', {
            suffix: 'maxfilesize',
            rule: (elem) => {
                const fsize = Number(elem.dataset.maxfilesizeRule);
                elem.validity._maxfilesize = false;
                elem.setCustomValidity('');
                const field = elem.closest('ui-field');
                if (field) {
                    field.removeErrors();
                }
                for (let i = 0; i < elem.files.length; ++i) {
                    if (elem.files[i].size > fsize) {
                        elem.validity._maxfilesize = true;
                        elem.setCustomValidity(validationLabels.maxfilesize);
                        break;
                    }
                }

                return !elem.validity._maxfilesize;
            },
            formatter: (msg, elem) => {
                const fsize = Number(elem.dataset.maxfilesizeRule);
                return this.formatMessage.apply(null, [
                    msg,
                    Math.ceil(fsize / 1024),
                ]);
            },
        });

        this.getForm().addEventListener('form-update', (event) => {
            [].forEach.call(event.detail.updatedNodes, (node) => {
                node.dispatchEvent(new Event(FormValidator.DEFAULT_EVENT_NAME));
            });
        });

        this.getForm().addEventListener('submit', () => {
            if (this.beforeSubmit && typeof this.beforeSubmit === 'function') {
                this.beforeSubmit.call(this);
            }
        });

        this.getForm().addEventListener('keydown', (e) => {
            if (isEnterPressed(e)) {
                this.resolveElementsRequirability();
                this.getForm().checkValidity();
                this.validate();
                this.focusFirstInvalid();
            } else {
                // Maybe in future we need to implement this for A11y.
                const el = e.target;
                const evt = new Event(FormValidator.INVALID_EVENT_NAME);
                if (
                    ['input', 'select', 'textarea'].includes(
                        el.tagName.toLowerCase()
                    )
                ) {
                    requestAnimationFrame(() => {
                        el.dispatchEvent(evt);
                        // Read assistive error by screen readers.
                        const field = el.closest('ui-field');
                        field && field.announceError();
                    });
                }
            }
        });

        this.getForm().addEventListener('pointerdown', (e) => {
            let target = e.target;
            while (target) {
                if (
                    (target.tagName === 'BUTTON' && target.type !== 'button') ||
                    (target.tagName === 'INPUT' && target.type === 'submit') ||
                    (target.classList.contains('-next') &&
                        target.classList.contains('button')) ||
                    !target.classList.contains('-upload')
                ) {
                    this.resolveElementsRequirability();
                    this.validate();
                    if (
                        (target.tagName === 'BUTTON' ||
                            target.tagName === 'INPUT') &&
                        target.type === 'submit'
                    ) {
                        // Maybe will be needed to force manual checking.
                        this.getForm().checkValidity();
                        this.focusFirstInvalid();
                    }
                    break;
                } else {
                    target = target.parentElement;
                }
            }
        });

        // Checking hidden fields on start
        this.resolveElementsRequirability();
        this.validate();
    }

    /**
     * Get localised validation error message for given element
     * @param {HTMLElement|HTMLInputElement} element
     * @returns {boolean}
     */
    static validateField(element) {
        const field = element.closest('ui-field');

        if (element.validity.valid) {
            if (field) {
                const prevError = field.querySelector(
                    'ui-hint[error-type=ui-validation]'
                );
                if (prevError) {
                    prevError.parentElement.removeChild(prevError);
                    field.checkErrors();
                }
            }
            return true;
        }

        if (field) {
            field.removeErrors(true);
            field.setError(
                FormValidator.translateValidationMessage(element),
                'ui-validation'
            );
        }

        return false;
    }

    /**
     * Checks validity of given element, sets / removes errors to related ui-field
     * @param {HTMLElement|HTMLInputElement} element
     * @returns {string}
     */
    static translateValidationMessage(element) {
        const validator = new FormValidator(document.createElement('form'));
        return validator.translateError(element, validationLabels);
    }

    /**
     * @returns {Function}
     */
    get beforeSubmit() {
        return this._beforeSubmit || null;
    }

    /**
     * @param {Function} fn
     */
    set beforeSubmit(fn) {
        this._beforeSubmit = fn;
    }

    /**
     * Default validation event name.
     * @type {string}
     */
    static get DEFAULT_EVENT_NAME() {
        return 'change';
    }

    /**
     * Default invalid event name.
     * @type {string}
     */
    static get INVALID_EVENT_NAME() {
        return 'invalid';
    }

    /**
     * Some useful validation patterns.
     * Patters should be surrounded by : semicolons to make them different
     * from common words and avoid impact.
     * Get more patterns from http://html5pattern.com
     * @type {object}
     */
    static get PATTERNS() {
        return {
            ':basic_date:':
                '(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}', // DD.MM.YYYY
            ':time_secs:': '(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]){2}', // HH:MM:SS
            ':time:': '(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9])', // HH:MM
            ':credit_card:': '[0-9]{13,16}',
            ':alnum:': '[a-zA-Z0-9]+',
            ':alpha:': '[a-zA-Z]+',
            ':numeric:': '[0-9]+',
            ':numeric_comma_dot:': '[\\-+]?[0-9]*[.,]?[0-9]*', // Format: 9 or 9.9 or 9,9 or 9,99
        };
    }

    /**
     * Formats and prepares suffix to correct format.
     * @param {string} ruleStr
     * @returns {string}
     * @private
     */
    prepareSuffix(ruleStr) {
        return ruleStr
            .replace('Rule', '')
            .replace(/([A-Z])/g, '-$1')
            .toLowerCase();
    }

    /**
     * Add custom validation rule.
     * @param {string} name - NB! Name of curtome rules should begin with underscore (_).
     * @param {object} object - object should contain following properties:
     *  {@desc suffix: used for translation}
     *  {@desc formatter: function to format error message}
     *  {@desc rule: function to validate condition}
     *  {@desc attributes: used for message formatter}
     */
    addCustomRule(name, object) {
        if (name.charAt(0) !== '_') {
            name = '_' + name;
        }
        this._RULES[name] = object;
    }

    /**
     * Returns the UIForm where this validation is attached.
     * @returns {UIForm|HTMLFormElement}
     */
    getForm() {
        return this._form;
    }

    /**
     * Returns the all defined rules as object.
     * @returns {object}
     */
    getRules() {
        return this._RULES;
    }

    /**
     * Returns rule name by its suffix property.
     * @param {string} suffix
     * @returns {object | undefined}
     */
    getRuleNameBySuffix(suffix) {
        for (const val in this._RULES) {
            if (
                this._RULES.hasOwnProperty(val) &&
                this._RULES[val].suffix === suffix
            ) {
                return val;
            }
        }
    }

    /**
     * Gets translation suffix by element.
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} elem
     * @returns {string}
     */
    resolveTranslationSuffix(elem) {
        let validity = this.resolveValidityString(elem);
        if (validity === 'typeMismatch') {
            return elem.type;
        }
        if (validity === 'customError') {
            validity = this.resolveCustomValidityString(elem);
        }
        return this._RULES[validity || 'customError'].suffix;
    }

    /**
     * Get the key for validity.
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} elem
     * @returns {string | undefined}
     */
    resolveValidityString(elem) {
        for (const key in this._RULES) {
            if (this._RULES.hasOwnProperty(key) && elem.validity[key]) {
                return key;
            }
        }
    }

    /**
     * Gets the first key for custom validity if in ValidityState -
     * (CustomError is true).
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} elem
     * @returns {string | undefined}
     */
    resolveCustomValidityString(elem) {
        for (const key in elem.validity) {
            if (elem.validity.hasOwnProperty(key) && key.charAt(0) === '_') {
                return key;
            }
        }
    }

    /**
     * Gets translation data-[suffix]-error attribute to replace default
     * translation labels for individual elements.
     * @param {HTMLElement} elem
     * @returns {string | undefined}
     */
    getTranslationAttr(elem) {
        const suffix = this.resolveTranslationSuffix(elem).replace(
            /-./g,
            (x) => x.toUpperCase()[1]
        );
        return elem.dataset[suffix + 'Error'];
    }

    /**
     * Translates validity for element.
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} elem
     * @param {object} labels Collection of labels.
     * @returns {string}
     */
    translateError(elem, labels) {
        const suffix = this.resolveTranslationSuffix(elem);
        let translation;
        if (elem.validity.customError) {
            translation =
                this.getTranslationAttr(elem) || elem.validationMessage;
        } else {
            translation = this.getTranslationAttr(elem) || labels[suffix];
        }
        return this.formatErrorMessage(elem, translation);
    }

    /**
     * Resolves attributes from validity rule.
     * @param {string} validity
     * @returns {Array<string>}
     */
    resolveAttributes(validity) {
        return this._RULES[validity].attributes || [];
    }

    /**
     * Very-very shorten version of C's sprintf.
     * Accepts only '%s' for strings.
     * @param {string} msg
     * @returns {string}
     */
    formatMessage(msg) {
        for (let i = 1; i < arguments.length; ++i) {
            msg = msg.replace('%s', arguments[i]);
        }
        return msg;
    }

    /**
     * Default message formatter for validities which hasn't message formatter
     * defined.
     * @param {string} msg Message for error.
     * @param {HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement} elem Invalid element.
     * @returns {string}
     */
    defaultMessageFormatter(msg, elem) {
        const validity = this.resolveValidityString(elem);
        const attrsToReplace = this.resolveAttributes(validity);
        const attrs = attrsToReplace.map((attrName) => {
            return String(elem.getAttribute(attrName));
        });
        return this.formatMessage.apply(null, [msg, attrs]);
    }

    /**
     * Resolves formatter function for validity.
     * @param {HTMLElement} elem
     * @returns {Function}
     */
    resolveMessageFormatter(elem) {
        const validity = this.resolveValidityString(elem);

        // Firstly we check custom rules, then native ones.
        // Custom functions are more important than natives, IMHO.
        const customValidityKey = this.resolveCustomValidityString(elem);
        if (customValidityKey && this._RULES[customValidityKey].formatter) {
            return this._RULES[customValidityKey].formatter.bind(this);
        }

        // Default formatters
        if (validity && this._RULES[validity].hasOwnProperty('formatter')) {
            return this._RULES[validity].formatter.bind(this);
        } else {
            return this.defaultMessageFormatter.bind(this);
        }
    }

    /**
     * Gets the rule for custom validity.
     * @param {string} validity
     * @returns {Function}
     */
    resolveCustomRule(validity) {
        return validity ? this._RULES[validity].rule : null;
    }

    /**
     * Attach validator to all elements needs to be validated.
     * Sets them disabled while element isn't visible.
     */
    validate() {
        const form = this.getForm();
        if (!form.elements) {
            return;
        }
        [].forEach.call(form.elements, (elem) => {
            if (
                ['hidden', 'button', 'submit'].indexOf(elem.type) === -1 &&
                !elem.classList.contains('-utility')
            ) {
                this.appendElementToValidation(elem);
                // <ui-duplicate> case
                if (elem.closest('ui-duplicate')) {
                    delete elem.dataset.validated;
                } else {
                    if (!elem.dataset.validated) {
                        elem.dataset.validated = 'true';
                    }
                }
            }
        });
    }

    /**
     * Disable or enables elements depends on it's visibility.
     * If elements isn't visible on screen it will be disabled otherwise enabled.
     */
    resolveElementsRequirability() {
        const form = this.getForm();
        if (!form.elements) {
            return;
        }
        [].forEach.call(form.elements, (elem) => {
            const hasForcedValidation = elem.dataset.forceValidation;
            const visible = isVisible(elem);
            const isCustomDropdown = !!elem.closest('ui-dropdown');
            if (
                !isCustomDropdown &&
                !visible &&
                (elem.dataset.required || elem.required)
            ) {
                elem.required = false;
                elem.setAttribute('data-required', '1');
            }
            if ((visible && elem.dataset.required) || hasForcedValidation) {
                elem.required = true;
            }
        });
    }

    /**
     * Attach single element to validator. If there are custom events
     * they will be triggered. If there aren't any custom event specified
     * in attribute 'data-validation-events', then default events will be
     * triggered like 'change' and 'blur'.
     * @param {HTMLElement} elem
     */
    appendElementToValidation(elem) {
        if (elem.dataset.validated || elem.dataset.novalidate) {
            return;
        }
        const errorPlacer = this.errorPlacementCallback.bind(this);
        elem.addEventListener(FormValidator.INVALID_EVENT_NAME, errorPlacer);
        // Assign custom validation events if they are exists.
        if (elem.dataset.validationEvents) {
            const events = elem.dataset.validationEvents.split(/\s+/);
            events.forEach((eventName) => {
                elem.addEventListener(eventName, errorPlacer);
            });
        } else {
            elem.addEventListener(
                FormValidator.DEFAULT_EVENT_NAME,
                errorPlacer
            );
            elem.addEventListener('blur', (event) => {
                if (event.target.value) {
                    event.target.checkValidity();
                }
            });
        }
        this.replacePatterns(elem);
    }

    /**
     * Focuses the first invalid element.
     */
    focusFirstInvalid() {
        const inv = this.getForm().querySelector(':invalid');
        if (inv) {
            if (inv === document.activeElement) {
                return;
            }
            requestAnimationFrame(() => inv.focus());
        }
    }

    /**
     * Replaces predefined RegEx pattern.
     * @param {HTMLElement} elem
     * @private
     */
    replacePatterns(elem) {
        if (elem.hasAttribute('pattern')) {
            const patternValue = elem.getAttribute('pattern');
            if (FormValidator.PATTERNS[patternValue]) {
                elem.setAttribute(
                    'pattern',
                    FormValidator.PATTERNS[patternValue]
                );
            }
        }
    }

    /**
     * Formats error message depending on field.
     * @param {HTMLElement} elem
     * @param {string} msg
     * @returns {string}
     * @private
     */
    formatErrorMessage(elem, msg) {
        const formatter = this.resolveMessageFormatter(elem);
        return formatter(msg, elem);
    }

    /**
     * Force add error to element.
     * @param {HTMLInputElement} elem HTML field.
     * @param {string} msg Error message.
     * @param {string} type Error type for error message.
     * @returns {boolean}
     */
    addForcedErrorForElement(elem, msg, type) {
        const field = elem.closest('ui-field');
        if (field) {
            field.removeErrors();
            field.addError(msg, type);
            return true;
        }
        return false;
    }

    /**
     * Checks that form should be validated.
     * @returns {boolean}
     */
    shouldFormValidate() {
        return !this.getForm().noValidate;
    }
}

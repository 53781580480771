import { UIElement } from '../ui-element.js';
import { resolveVideoHost } from '../../global/helpers.js';
import { getAttributes } from '../../global/ui-helpers.js';
import { TabIndex } from '../../global/keyboard.js';
import styles from './ui-card.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICard
 * @element ui-card
 * @classdesc Represents a class for <code>ui-card</code> element.
 * Can be used for news with picture (or without) text and link.
 * @property {string} [image] {@attr image} Image URL for card.
 * @property {string} [alt=""] {@attr alt} Alternate text for image.
 * Same as alt for IMG.
 * @property {boolean} [clickable=false] {@attr clickable} The image area
 * will be clickable the same as the first link.
 * @property {("default" | "compact" | "left" | "right")} [layout] {@attr layout} Layout of
 * the card.
 *  {@desc default}
 *  {@desc compact}
 *  {@desc left}
 *  {@desc right}
 * @property {("left" | "right")} [alignment] {@attr alignment} Alignment of the media
 * inside UICard in mobile mode (deprecated).
 *  {@desc left}
 *  {@desc right}
 *  @property {string} label {@attr label} Header of the card.
 * @slot
 * @example
 * <ui-card layout="compact" image="/images/kitty.jpg" label="Hello, Kitty!">
 *   <p>Content here</p>
 * </ui-card>
 */
class UICard extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                image: String,
                alt: String,
                layout: String,
                label: String,
                alignment: {
                    type: String,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component
                    ) => {
                        console.warn(
                            `alignment - attribute is deprecated. ` +
                                `Allowed attribute - layout.`,
                            component
                        );
                    },
                },
                clickable: Boolean,
            },
        };
    }

    /**
     * Gets first link in ui-card.
     * @returns {HTMLAnchorElement}
     * @private
     */
    getFirstInnerLink() {
        return this.querySelector('a:first-of-type');
    }

    /**
     * @inheritDoc
     */
    render() {
        this.tabIndex = TabIndex.Active;
        const figure =
            this.querySelector('figure') || document.createElement('figure');

        ['ui-badge', 'ui-remark', 'ui-video'].forEach((sel) => {
            const elem = this.querySelector(sel);
            if (elem) {
                figure.appendChild(elem);
            }
        });

        if (this.image) {
            const imgElement = this.createElement({
                tagName: 'img',
                attributes: {
                    src: this.image,
                    alt: this.alt || '',
                },
            });

            const linkElement = this.getFirstInnerLink();
            if (this.clickable && linkElement) {
                const href = linkElement.getAttribute('href');
                const videoHost = resolveVideoHost(href);
                if (videoHost === 'youtube') {
                    figure.appendChild(
                        this.createElement({
                            tagName: 'ui-video',
                            attributes: {
                                src: href,
                                poster: this.image,
                            },
                        })
                    );
                } else {
                    const attrs = getAttributes(linkElement);
                    delete attrs.class;

                    figure.appendChild(
                        this.createElement({
                            tagName: 'a',
                            attributes: attrs,
                            children: [imgElement],
                        })
                    );
                }
            } else {
                figure.appendChild(imgElement);
            }
        }
        if (figure.childNodes.length > 0) {
            this.insertAdjacentElement('afterbegin', figure);
        }

        let article = this.querySelector('article');
        if (!article) {
            article = this.createElement({
                tagName: 'article',
                children: [].filter.call(this.childNodes, function (item) {
                    return item.tagName !== 'FIGURE';
                }),
            });
            this.appendChild(article);
        }

        if (this.label) {
            this.setAttribute('aria-label', this.label);
            const h3 = this.createElement({
                tagName: 'h3',
                children: [this.label],
            });
            article.insertAdjacentElement('afterbegin', h3);
        }
    }
}

UICard.defineElement('ui-card', styles);
export { UICard };

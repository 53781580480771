import { runWithLockingForm } from '../../global/loading-indicator.js';
import { UITemplate } from '../ui-template.js';
import { element, html } from '../../global/template-literals.js';

/**
 * @memberof SharedComponents
 * @alias ConfirmationDialogTemplate
 * @augments {UITemplate}
 * @property {IConfirmationDialogInit} _data Template data
 */
class ConfirmationDialogTemplate extends UITemplate {
    /**
     * @param {IConfirmationDialogInit} data
     * @param {boolean} [trusted]
     * @returns {ConfirmationDialogTemplate}
     */
    static create(data, trusted) {
        return new ConfirmationDialogTemplate(data, trusted);
    }

    /**
     * @param {IConfirmationDialogInit} data
     * @param {boolean} [trusted]
     */
    constructor(data, trusted) {
        super(data);
        this._data = Object.assign({ dynamic: true, compact: true }, data);
        this._trusted = trusted;
    }

    /**
     * @inheritDoc
     */
    render() {
        return element`
        <ui-modal 
            type="confirmation" 
            dynamic="${this._data.dynamic ? 'dynamic' : null}" 
            compact="${this._data.compact ? 'compact' : null}" 
            label-accept="${this._data.labelAccept}"
            label-decline="${this._data.labelDecline}">
            ${
                this._data.content ||
                html`
                    <h2>${this._data.title}</h2>
                    ${this._data.description &&
                    html`<p>${this._data.description}</p>`}
                `
            }    
        </ui-modal>
    `;
    }

    /**
     * @param {{onAccept: Function, onDecline: Function}} [callbacks]
     * @returns {UIModal}
     */
    prompt({ onAccept, onDecline } = {}) {
        const modal = /** @type {UIModal} */ this.render();

        const complete = () => {
            modal.closable = true;
            modal.close();
        };

        modal.addEventListener('modal-action', (e) => {
            modal.closable = false;
            if (e.detail.type === 'accept') {
                if (typeof onAccept === 'function') {
                    const acceptRes = onAccept();
                    if (acceptRes instanceof Promise) {
                        runWithLockingForm(
                            acceptRes,
                            modal.querySelector('button.-accept'),
                            modal.modalWindow
                        ).then(() => complete());
                    } else {
                        complete();
                    }
                } else {
                    complete();
                }
            } else {
                if (typeof onDecline === 'function') {
                    const declineRes = onDecline();
                    if (declineRes instanceof Promise) {
                        runWithLockingForm(
                            declineRes,
                            modal.querySelector('button.-decline'),
                            modal.modalWindow
                        ).then(() => complete());
                    } else {
                        complete();
                    }
                } else {
                    complete();
                }
            }
        });

        modal.open();
        return modal;
    }
}

export { ConfirmationDialogTemplate };

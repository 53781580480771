import { UIElement } from '../ui-element.js';
import { Labels } from '../../global/labels.js';
import { UIBadge } from '../badge/ui-badge.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-navitem.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UINavItem
 * @element ui-navitem
 * @classdesc Represents a class for <code>ui-navitem</code> element
 * @fires event:remove-shortcut-item
 * @property {string} label {@attr label}
 * @property {string} labelId {@attr label-id}
 * @property {string} [labelBack] {@attr label-back} Label to set back button label
 * inside ui-navlist.
 * @property {string} [labelHint] {@attr label-back} Label hint inside primary-item.
 * @property {string} [controls] {@attr controls} ID of related menu block.
 * @property {string} [href] {@attr href} ID of related menu block.
 * @property {string} [glyph] {@attr glyph} Glyph for item as a primary icon or primary item.
 * @property {string} [glyphColor] {@attr glyph-color} Glyph color for item as a primary icon
 * or primary item.
 * @property {string} [item] {@attr item} ID for item as link.
 * @property {string} [badge] {@attr badge} Badge for the menu usually using 1 symbol '!',
 * please also do not use large badge in the menu. The large badge won't fit.
 * @property {("fluid")} [layout] {@attr layout} Layout for navitem view.
 * @property {INavItemType} [type] {@attr type} Type for nav item:
 *   {@desc primary-item: layout for first level nav item}
 *   {@desc secondary-item: layout for second level nav item}
 *   {@desc auxiliary-item: layout for auxiliary level nav item}
 *   {@desc primary-icon: layout for nav item with icon}
 *   {@desc custom: layout for nav item with custom content}
 * @property {boolean} [edit] {@attr edit} Edit enable flag for shortcuts.
 * @property {boolean} [expandable] {@attr expandable} Expandable menu, used for
 * auxiliary and mobile menus.
 * @example
 * <ui-navitem></ui-navitem>
 */
class UINavItem extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['edit'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                badge: String,
                label: String,
                labelId: String,
                active: Boolean,
                edit: Boolean,
                expandable: Boolean,
                glyph: String,
                glyphColor: { type: String, default: UIIcon.colors.DEFAULT },
                type: String,
                layout: String,
                labelBack: String,
                labelHint: String,
            },
        };
    }

    /**
     * @returns {Record<INavItemType, string>}
     */
    static get types() {
        return {
            PRIMARY: 'primary-item',
            SECONDARY: 'secondary-item',
            AUXILIARY: 'auxiliary-item',
            PRIMARY_ICON: 'primary-icon',
            CUSTOM: 'custom',
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-navitem', {
            REMOVE_SHORTCUT: 'Delete shortcut',
        });
    }

    /**
     * Checks if the item is primary
     * @returns {boolean}
     */
    isPrimaryItem() {
        return this.type === UINavItem.types.PRIMARY;
    }

    /**
     * Checks if the item is secondary
     * @returns {boolean}
     */
    isSecondaryItem() {
        return this.type === UINavItem.types.SECONDARY;
    }

    /**
     * Get ID of related menu block
     * @returns {string}
     */
    getControlId() {
        return this.getAttribute('controls') || '';
    }

    /**
     * Get ID of related menu block
     * @returns {UINavList | *}
     */
    getControlList() {
        const id = this.getControlId();
        return document.querySelector(`ui-navlist[id="${id}"]`);
    }

    /**
     * Sets class -selected to be true to highlight the item
     * @returns {UIElement}
     */
    activate() {
        return this.updateClassList({ '-selected': true });
    }

    /**
     * Removes class -selected to be true to remove highlighting from the item
     * @returns {UIElement}
     */
    deactivate() {
        return this.updateClassList({ '-selected': false });
    }

    /**
     * Retrieve the href value
     * @returns {string}
     */
    getHref() {
        return this.getAttribute('href');
    }

    /**
     * Fires callback when item's edit state is updated
     */
    onChangeEditState() {
        if (this.type !== UINavItem.types.SECONDARY) {
            return;
        }
        this.rebuildChildren(this.buildSecondaryLinkChildren().filter(Boolean));
    }

    /**
     * Renders item as a primary icon
     */
    renderAsPrimaryIcon() {
        let canOpenMenu = false;
        const item = this.getAttribute('item');
        if (this.nav) {
            canOpenMenu = this.nav.canOpenMenu(item);
        }
        this.updateClassList({
            '-primary': true,
            '-icon': true,
        });
        this.insertElements([
            {
                tagName: 'a',
                attributes: {
                    role: canOpenMenu ? 'menuitem' : null,
                    title: this.label,
                    'aria-label': this.label,
                    'aria-controls': canOpenMenu
                        ? this.getAttribute('controls')
                        : null,
                    'aria-expanded': canOpenMenu ? 'false' : null,
                    href: this.getAttribute('href') || '#',
                },
                classList: {
                    'ui-navitem__primary-link': true,
                    '-icon': true,
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: this.glyph,
                            color: this.glyphColor,
                        },
                        classList: {
                            'ui-navitem__primary-icon': true,
                        },
                    },
                    {
                        tagName: 'span',
                        classList: {
                            'ui-navitem__caption': true,
                        },
                        children: [this.label],
                    },
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'right',
                            color:
                                this.layout === 'fluid'
                                    ? UIIcon.colors.DEFAULT
                                    : UIIcon.colors.ORANGE,
                        },
                    },
                ],
            },
        ]);
    }

    /**
     * Renders item as a primary link
     */
    renderAsPrimaryLink() {
        let icon = this.detachChildNodes().filter((node) => {
            return (
                node.tagName &&
                ['ui-icon', 'ui-icon-symbolic'].includes(
                    node.tagName.toLowerCase()
                )
            );
        })[0];

        if (!icon && this.glyph) {
            icon = this.createElement({
                tagName: 'ui-icon',
                attributes: {
                    glyph: this.glyph,
                    color: this.glyphColor,
                },
            });
        }

        this.updateClassList({ '-primary': true });

        let canOpenMenu = false;
        const item = this.getAttribute('item');
        if (this.nav) {
            canOpenMenu = this.nav.canOpenMenu(item);
        }

        const link = this.createElement({
            tagName: 'a',
            attributes: {
                role: canOpenMenu ? 'menuitem' : null,
                'aria-controls': canOpenMenu
                    ? this.getAttribute('controls')
                    : null,
                'aria-haspopup': canOpenMenu ? 'true' : null,
                'aria-expanded': canOpenMenu ? 'false' : null,
                'data-wt-label': this.labelId,
                href: this.getAttribute('href') || '#',
                id: this.getAttribute('item'),
            },
            classList: {
                'ui-navitem__primary-link': true,
            },
            children: [
                {
                    tagName: 'span',
                    classList: {
                        'ui-navitem__caption': true,
                    },
                    children: [
                        icon,
                        this.label,
                        this.labelHint && {
                            tagName: 'p',
                            classList: {
                                'ui-navitem__hint': true,
                            },
                            children: [document.createTextNode(this.labelHint)],
                        },
                    ],
                },
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'right',
                        color:
                            this.layout === 'fluid'
                                ? UIIcon.colors.DEFAULT
                                : UIIcon.colors.ORANGE,
                    },
                },
            ],
        });

        this.appendChild(link);
    }

    /**
     * @returns {Array<IElementConfig>}
     */
    buildSecondaryLinkChildren() {
        return [
            this.edit && this.buildCrossButton(),
            {
                tagName: 'a',
                attributes: {
                    'data-wt-label': this.labelId,
                    href: this.getAttribute('href'),
                    id: this.getAttribute('item'),
                    draggable: this.edit ? 'true' : null,
                },
                classList: {
                    'ui-navitem__tetriary-link': true,
                },
                children: [
                    {
                        tagName: 'span',
                        classList: {
                            'ui-navitem__caption': true,
                        },
                        children: [
                            {
                                tagName: 'span',
                                children: [document.createTextNode(this.label)],
                            },
                            this.glyph && {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: this.glyph,
                                    color: this.glyphColor,
                                },
                            },
                            this.badge && {
                                tagName: 'ui-badge',
                                attributes: {
                                    color: BrandColors.Lilac,
                                    layout: UIBadge.Layout.Pin,
                                },
                                children: [this.badge],
                            },
                        ],
                    },
                    this.expandable && {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'right',
                            color:
                                this.layout === 'fluid'
                                    ? UIIcon.colors.DEFAULT
                                    : UIIcon.colors.ORANGE,
                        },
                    },
                ],
            },
        ];
    }

    /**
     * @fires event:remove-shortcut-item
     * @returns {HTMLElement}
     */
    buildCrossButton() {
        return this.createElement({
            tagName: 'button',
            attributes: {
                type: 'button',
                'data-event': 'remove-shortcut-item',
                'aria-label': UINavItem.labels.REMOVE_SHORTCUT,
            },
            classList: {
                'ui-navitem__remove-shortcut': true,
                '-iconed': true,
            },
            children: [
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'cross',
                        bgcolor: 'alert-red',
                    },
                },
            ],
        });
    }

    /**
     * Renders item as a secondary link
     */
    renderAsSecondaryLink() {
        this.updateClassList({ '-tetriary': true });
        this.insertElements(this.buildSecondaryLinkChildren());
    }

    /**
     * Renders item as a secondary link
     */
    renderAsAuxiliaryLink() {
        this.insertElements([
            {
                tagName: 'a',
                attributes: {
                    'data-wt-label': this.labelId,
                    href: this.getAttribute('href'),
                    id: this.getAttribute('item'),
                    'data-badge': this.badge || null,
                },
                classList: {
                    'default-link': true,
                },
                children: [this.label],
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'edit':
                this.onChangeEditState();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.nav = this.closest('ui-nav');
        this.setAttribute('role', 'menuitem');
        switch (this.type) {
            case UINavItem.types.PRIMARY:
                this.renderAsPrimaryLink();
                break;
            case UINavItem.types.SECONDARY:
                this.renderAsSecondaryLink();
                break;
            case UINavItem.types.AUXILIARY:
                this.renderAsAuxiliaryLink();
                break;
            case UINavItem.types.PRIMARY_ICON:
                this.renderAsPrimaryIcon();
                break;
            case UINavItem.types.CUSTOM:
            default:
                break;
        }
    }
}

UINavItem.defineElement('ui-navitem', styles);
export { UINavItem };

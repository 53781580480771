import '../icon/ui-icon.js';
import { UIElement } from '../ui-element.js';
import { Labels } from '../../global/labels.js';
import { insertElements } from '../../global/ui-helpers.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-message.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIMessage
 * @element ui-message
 * @classdesc Represents a class for <code>ui-message</code> element.
 * Render alert message with a type.
 * @property {("default" | "light")} layout {@attr layout} Layout for message.
 * @property {AlertMessageType} type {@attr type}
 * Type of the message.
 *  {@desc info}
 *  {@desc warning}
 *  {@desc success}
 *  {@desc error}
 *  {@desc urgent}
 * @property {boolean} [closable=false] {@attr closable} add close button for 'light' layout.
 * @property {boolean} [persistent=false] {@attr persistent} persistence of message.
 * @property {HTMLDivElement} content Shortcut to content element of the message.
 * @property {HTMLButtonElement} closeAction Shortcut to the button to close message.
 * @example
 * <ui-message type="info">Info message</ui-message>
 * @example
 * <ui-message type="warning">Warning message</ui-message>
 * @example
 * <ui-message type="error">Error message</ui-message>
 * @slot
 */
class UIMessage extends UIElement {
    /**
     * Layouts list.
     * @type {{DEFAULT: 'default', LIGHT: 'light'}}
     */
    static get layouts() {
        return {
            DEFAULT: 'default',
            LIGHT: 'light',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: UIMessage.layouts.DEFAULT },
                type: String,
                closable: Boolean,
                persistent: Boolean,
            },
            children: {
                content: '.ui-message__content',
                closeAction: '.ui-message__close-action',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-message', {
            close: 'Close',
        });
    }

    /**
     * Returns the icon glyph for the message.
     * @returns {string}
     */
    get iconGlyph() {
        let glyph = this.type;
        if (
            this.type === 'urgent' ||
            (this.isLightLayout() && this.type === 'error')
        ) {
            glyph = 'warning';
        }
        return 'alert-' + glyph;
    }

    /**
     * Checks if the node is empty.
     * @returns {boolean}
     */
    isEmpty() {
        return this.content.innerHTML.trim() === '';
    }

    /**
     * Checks if the message layout is 'light'
     * @returns {boolean}
     */
    isLightLayout() {
        return this.layout === UIMessage.layouts.LIGHT;
    }

    /**
     * Sets content to message and shows the message.
     * @param {string} message
     */
    showMessage(message) {
        this.content.innerHTML = message;
        this.show();
    }

    /**
     * Clears the message element.
     */
    clear() {
        if (this.persistent) {
            return;
        }
        this.content.innerHTML = '';
        this.hide();
        this.parentElement.removeChild(this);
    }

    /**
     * Handle closing action for message
     */
    handleCloseAction() {
        this.clear();
    }

    /**
     * @inheritDoc
     */
    render() {
        this.layout = this.layout || UIMessage.layouts.DEFAULT;
        this.type = this.type || (this.isLightLayout() ? 'warning' : 'info');
        insertElements(this, [
            {
                tagName: 'ui-icon',
                attributes: {
                    color: !this.isLightLayout() ? UIIcon.colors.WHITE : null,
                    glyph: this.iconGlyph,
                    class: 'ui-message__icon',
                },
            },
            this.closable && {
                tagName: 'button',
                attributes: {
                    type: 'button',
                    'aria-label': UIMessage.labels.close,
                },
                classList: {
                    'ui-message__close-action': true,
                    '-iconed': true,
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'cross',
                            color: UIIcon.colors.DEFAULT,
                        },
                    },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-message__content',
                },
                children: this.detachChildNodes(),
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleCloseAction = this.handleCloseAction.bind(this);
        if (this.closeAction) {
            this.closeAction.addEventListener('click', this.handleCloseAction);
        }
    }
}

UIMessage.defineElement('ui-message', styles);
export { UIMessage };

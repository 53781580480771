import { element } from '../../global/template-literals.js';
import { UIElement } from '../ui-element.js';
import { Labels } from '../../global/labels.js';
import { FormValidator } from '../../global/form-validator.js';

import styles from './ui-textcounter.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UITextCounter
 * @element ui-textcounter
 * @classdesc Represents a class for <code>ui-textcounter</code> element.
 * Counts and display character remaining for input and textarea elements.
 * @property {number} [limit=0] {@attr limit} Text counter limit.
 * @property {string} [label] {@attr label} Label for text counter.
 * @property {boolean} [overflow] {@attr overflow} Allows to charters counter hint to be negative.
 * @property {boolean} [validate] {@attr validate} Forces to validate textarea if characters
 * overflow and charters count is less than limit value.
 * @property {boolean} [nolabel] {@attr nolabel} Hides the label (characters remaining).
 * @property {string} [labelError] {@attr label-error} Custom error label when length is more
 * than limit.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {HTMLTextAreaElement} textarea Shortcut to textarea element.
 * @property {UIHint} hint Shortcut to hint element.
 * @property {HTMLSpanElement} assistant Shortcut to accessibility assistant element.
 * @slot
 * @example
 *  <ui-field label="Message">
 *    <ui-textcounter limit="2000">
 *      <textarea id="test-render-id" name="message"></textarea>
 *    </ui-textcounter>
 *  </ui-field>
 */
class UITextCounter extends UIElement {
    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-textcounter', {
            remaining: 'Characters remaining:',
            invalid: 'Text length is invalid (max %d characters).',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                limit: { type: Number, default: 0 },
                label: String,
                overflow: Boolean,
                validate: Boolean,
                nolabel: Boolean,
                labelError: String,
                controlId: String,
            },
            children: {
                textarea: 'textarea',
                hint: 'ui-hint',
                assistant: '.ui-textcounter__assistant',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'control-id':
                if (this.textarea) {
                    if (newValue) {
                        this.textarea.setAttribute('id', newValue);
                    } else {
                        this.textarea.removeAttribute('id');
                    }
                }
                break;
        }
    }

    /**
     * @type {number}
     */
    static get ASSIST_DELAY() {
        return 2000;
    }

    /**
     * @type {number}
     * @readonly
     */
    get charCount() {
        return this.textarea.value.length;
    }

    /**
     * Sets character remaining text to element.
     * @param {HTMLElement} elem
     */
    setRemainingText(elem) {
        elem.innerText = [
            this.label || UITextCounter.labels.remaining,
            this.limit - this.charCount,
        ].join(' ');
    }

    /**
     * Rebuilds extra hint of the textarea.
     */
    rebuildHint() {
        this.hint.rebuild(element`
      <ui-hint class="${this.hasOverflow() ? '-negative' : ''}">
        ${this.label || UITextCounter.labels.remaining} 
        <span class="ui-textcounter__characters">
          ${String(this.limit - this.charCount)}
        </span>
      </ui-hint>
    `);
    }

    /**
     * Updates counter value, cuts the textarea value if the character limit exceeded.
     */
    updateCounter() {
        if (!this.limit) {
            return;
        }

        if (this.hasOverflow() && !this.overflow) {
            this.textarea.value = this.textarea.value.substring(0, this.limit);
        }

        if (this.overflow && this.validate) {
            this.validateLength();
        }

        if (!this.nolabel) {
            this.rebuildHint();
            let timerId;
            timerId = setTimeout(() => {
                clearTimeout(timerId);
                this.setRemainingText(this.assistant);
            }, UITextCounter.ASSIST_DELAY);
        }
    }

    /**
     * Validates and set :invalid state to textarea if the characters is more than limit.
     */
    validateLength() {
        const validityMsg = (
            this.hasAttribute('label-error')
                ? this.labelError || ' '
                : UITextCounter.labels.invalid
        ).replace('%d', String(this.limit));
        this.textarea.setCustomValidity(this.hasOverflow() ? validityMsg : '');
        FormValidator.validateField(this.textarea);
    }

    /**
     * Fires callback when user input or change textarea content.
     * @param {Event} e
     * @private
     */
    handleValueUpdate(e) {
        this.updateCounter();
    }

    /**
     * Checks if text is longer than limit.
     * @returns {boolean}
     */
    hasOverflow() {
        return this.charCount > this.limit;
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!this.limit) {
            return;
        }
        this.insertElements([
            {
                tagName: 'textarea',
                element: this.textarea,
            },
            !this.nolabel && {
                tagName: 'ui-hint',
            },
            !this.nolabel && {
                tagName: 'span',
                classList: {
                    '-visually-hidden': true,
                    'ui-textcounter__assistant': true,
                },
                attributes: {
                    'aria-live': 'polite',
                },
            },
        ]);
        this.updateCounter();
        this.assistant && this.setRemainingText(this.assistant); // init assistant
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (!this.limit) {
            return;
        }
        this.textarea.addEventListener(
            'input',
            this.handleValueUpdate.bind(this)
        );
        this.textarea.addEventListener(
            'change',
            this.handleValueUpdate.bind(this)
        );

        // Init validation on start if required.
        if (this.hasOverflow() && this.overflow && this.validate) {
            this.validateLength();
        }
    }
}

UITextCounter.defineElement('ui-textcounter', styles);
export { UITextCounter };

import { UIDateElement } from './ui-dateelement.js';
import {
    addSyntheticFocusVisible,
    buildSyntheticFocusControl,
    updateElement,
} from '../../global/ui-helpers.js';
import styles from './ui-monthrange.css';

/**
 * @memberof SharedComponents
 * @augments {UIDateElement}
 * @alias UIMonthRange
 * @element ui-monthrange
 * @classdesc Represents a class for <code>ui-monthrange</code> element.
 * Combines two ui-monthpicker to select month range.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {UIField} control {@readonly} Shortcut for control.
 * @property {UIMonthPicker} pickerFrom {@readonly} Shortcut for monthpicker-from element.
 * @property {UIMonthPicker} pickerTo {@readonly} Shortcut for monthpicker-to element.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @slot
 * @example
 * <ui-monthrange min="2017-09" max="2020-11">
 *   <ui-monthpicker>
 *     <input type="text" value="2018-03" name="period-from">
 *   </ui-monthpicker>
 *   <ui-monthpicker>
 *     <input type="text" value="2018-06" name="period-to">
 *   </ui-monthpicker>
 * </ui-monthrange>
 */
class UIMonthRange extends UIDateElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                controlId: String,
            },
            children: {
                pickerFrom: 'ui-monthpicker.-from',
                pickerTo: 'ui-monthpicker.-to',
                focusTrigger: '.ui-monthrange__focus-trigger',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'control-id':
                if (this.pickerFrom) {
                    this.pickerFrom.controlId = newValue;
                }
                break;
        }
    }

    /**
     * @returns {SharedComponents.UIMonthRange}
     */
    get control() {
        return this;
    }

    /**
     * Type of input in this case only 'month'.
     * @type {string}
     * @readonly
     */
    get valueType() {
        return 'month';
    }

    /**
     * @type {number}
     * @readonly
     */
    get tsFrom() {
        return this.convertPickerValueToTs(this.pickerFrom);
    }

    /**
     * @type {number}
     * @readonly
     */
    get tsTo() {
        return this.convertPickerValueToTs(this.pickerTo);
    }

    /**
     * @param {UIMonthPicker} picker
     * @returns {number}
     * @private
     */
    convertPickerValueToTs(picker) {
        return new Date(picker.value + '-01').getTime();
    }

    /**
     * Fires callback when a value of the date-from control is changed.
     * @param {Event} e
     * @private
     */
    handleChangeDateFrom(e) {
        this.syncControls();
        const ts = this.convertPickerValueToTs(e.target);
        if (ts > this.tsTo) {
            this.pickerTo.value = this.pickerFrom.value;
        }
    }

    /**
     * Fires callback when a value of the date-to control is changed.
     * @param {Event} e
     * @private
     */
    handleChangeDateTo(e) {
        this.syncControls();
        const ts = this.convertPickerValueToTs(e.target);
        if (ts < this.tsFrom) {
            this.pickerFrom.value = this.pickerTo.value;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const pickers = this.queryChildren('ui-monthpicker');
        const pickerInputs = [
            pickers[0] ? pickers[0].querySelector('input') : null,
            pickers[1] ? pickers[1].querySelector('input') : null,
        ];

        this.insertElements([
            buildSyntheticFocusControl(this.controlId, 'ui-monthrange'),
            {
                tagName: 'ui-monthpicker',
                element: pickers[0],
                classList: { '-from': true },
                children: [
                    {
                        tagName: 'input',
                        element: pickerInputs[0],
                        attributes: {
                            min: this.min,
                            max: this.max,
                        },
                    },
                ],
            },
            {
                tagName: 'span',
                attributes: {
                    class: 'ui-monthrange__separator',
                },
            },
            {
                tagName: 'ui-monthpicker',
                element: pickers[1],
                classList: { '-to': true },
                children: [
                    {
                        tagName: 'input',
                        element: pickerInputs[1],
                        attributes: {
                            min: this.min,
                            max: this.max,
                        },
                    },
                ],
            },
        ]);
        this.syncControls();
    }

    hydrate() {
        updateElement(this.pickerFrom, {
            events: {
                change: this.handleChangeDateFrom.bind(this),
            },
        });
        updateElement(this.pickerTo, {
            events: {
                change: this.handleChangeDateTo.bind(this),
            },
        });
        this.focusTrigger.addEventListener('click', () =>
            addSyntheticFocusVisible(this.pickerFrom.monthSelect)
        );
    }
}

UIMonthRange.defineElement('ui-monthrange', styles);
export { UIMonthRange };

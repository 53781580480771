import { UIElement } from '../ui-element.js';
import styles from './ui-breadcrumbs.css';
import { Labels } from '../../global/labels.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIBreadCrumbs
 * @element ui-breadcrumbs
 * @classdesc Represents a class for <code>ui-breadcrumbs</code> element
 * @property {boolean | attr} business Whether the breadcrumbs are for business
 * @property {Array<number> | attr} ignoreLevels Levels to ignore
 * @example
 * <ui-breadcrumbs></ui-breadcrumbs>
 */
class UIBreadCrumbs extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['active', 'business'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                active: Boolean,
                business: Boolean,
                hrefStart: String,
                labelStart: String,
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-breadcrumbs', {
            title: 'Breadcrumbs navigation',
        });
    }

    get ignoreLevels() {
        const value = this.getAttribute('ignore-levels');
        if (!value) {
            return [];
        }
        return value.split(',').map(Number);
    }

    buildItems(items) {
        return items.map(function (config, index) {
            return {
                tagName: 'li',
                classList: {
                    breadcrumb: true,
                    '-title': index === items.length - 1,
                },
                attributes: {
                    'aria-current': index === items.length - 1 ? 'page' : null,
                },
                children: [
                    config.href
                        ? {
                              tagName: 'a',
                              attributes: {
                                  href: config.href,
                              },
                              children: [config.label],
                          }
                        : config.label,
                    index < items.length - 1
                        ? {
                              tagName: 'span',
                              attributes: {
                                  'aria-hidden': true,
                              },
                              children: ['▸'],
                          }
                        : '',
                ],
            };
        });
    }

    setItems(items) {
        const ignoredLevels = this.ignoreLevels;
        this.rebuildChildren.call(
            this.querySelector('.breadcrumbs'),
            this.buildItems(
                items.filter(function (item, index) {
                    return ignoredLevels.indexOf(index + 1) === -1;
                })
            )
        );
    }

    /**
     * Fires callback when environment is updated
     * @private
     */
    onChangeEnvironment() {
        this.updateClassList({
            '-business': this.business,
            '-private': !this.business,
        });
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'business':
                this.onChangeEnvironment();
                break;
            case 'active':
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateElement({
            classList: {
                'breadcrumbs-container': true,
                '-business': this.business,
                '-private': !this.business,
            },
            children: [
                {
                    tagName: 'nav',
                    classList: {
                        'breadcrumbs-wrapper': true,
                    },
                    attributes: {
                        'aria-label': UIBreadCrumbs.labels.title,
                    },
                    children: [
                        {
                            tagName: 'ol',
                            classList: {
                                breadcrumbs: true,
                            },
                        },
                    ],
                },
            ],
        });
    }
}

UIBreadCrumbs.defineElement('ui-breadcrumbs', styles);
export { UIBreadCrumbs };

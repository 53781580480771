import '../hint/ui-hint.js';
import { UIElement } from '../ui-element.js';
import styles from './ui-progress.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIProgress
 * @element ui-progress
 * @classdesc Represents a class for <code>ui-progress</code> element.
 * Similar to ui-slider, but it it only read-only. This is a kind of
 * 'read-only' slider.
 * @property {number} [value=0] {@attr value} Value of progress.
 * @property {number} [min=0]  {@attr min} Minumum of progress.
 * @property {number} [max=100] {@attr max} Maximum of progress.
 * @property {number} [accuracy=2] {@attr accuracy} Formats a number using fixed-point notation
 * (-1 to disable accuracy).
 * @property {string} [labelLeft] {@attr label-left} Label of the text left value.
 * @property {string} [labelMiddle] {@attr label-middle} Label of the text middle value.
 * @property {string} [labelRight] {@attr label-right} Label of the text right value.
 * @property {string} [units] {@attr units} Label units like 'EUR', 'USD', '%', etc.
 * @property {HTMLDivElement} progressBar {@readonly}
 * @property {HTMLDivElement} currentValue {@readonly}
 * @property {HTMLDivElement} leftValue {@readonly}
 * @property {HTMLDivElement} limit {@readonly}
 * @property {HTMLDivElement} leftHint {@readonly}
 * @property {HTMLDivElement} middleHint {@readonly}
 * @property {HTMLDivElement} rightHint {@readonly}
 * @example
 * <ui-progress value="3" min="0" max="100" units="EUR" size="medium"></ui-progress>
 */
class UIProgress extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return [
            'min',
            'max',
            'value',
            'units',
            'label-left',
            'label-middle',
            'label-right',
        ];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                value: { type: Number, default: 0 },
                min: { type: Number, default: 0 },
                max: { type: Number, default: 100 },
                accuracy: { type: Number, default: 2 },
                labelLeft: String,
                labelMiddle: String,
                labelRight: String,
                units: { type: String, default: '%' },
            },
            children: {
                progressBar: '.ui-progress__progress',
                currentValue: '.ui-progress__value.-current',
                leftValue: '.ui-progress__value.-left',
                leftHint: '.ui-progress__hint.-start',
                middleHint: '.ui-progress__hint.-middle',
                rightHint: '.ui-progress__hint.-end',
                limit: '.ui-progress__limit',
            },
        };
    }

    /**
     * Format value base on given currency
     * @param {number|string} value
     * @returns {string}
     */
    formatValue(value) {
        let _value = Number(value);
        if (this.accuracy > -1) {
            _value = Number(_value.toFixed(this.accuracy));
        }

        switch (this.units) {
            case '€':
            case '$':
                return [
                    String(_value || 0).replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        '$1 '
                    ),
                    this.units,
                ].join(' ');
            default:
                return [String(_value), this.units].join(' ');
        }
    }
    /**
     * Sets an absolute value to the controls.
     * @param {number} value
     * @returns {UIElement | UISlider}
     */
    setValue(value) {
        this.value = value;
        if (this.value > this.max) {
            this.value = this.max;
        }
        if (this.value < this.min) {
            this.value = this.min;
        }

        this.currentValue.innerHTML = this.formatValue(this.value);
        this.leftValue.innerHTML = this.formatValue(this.max - this.value);
        this.setAttribute('aria-valuenow', String(this.value));
        this.setAttribute('aria-valuetext', this.formatValue(this.value));
        this.setAttribute('aria-valuemin', String(this.min));
        this.setAttribute('aria-valuemax', String(this.max));
        return this;
    }

    /**
     * Sets offset for progress bar.
     * @param {number} progress
     * @returns {UIProgress}
     */
    setProgressPosition(progress) {
        this.progressBar.style.transform =
            'translateX(-' + (100 - progress) + '%)';
        return this;
    }

    /**
     * Applies the current input value to user controls.
     * @returns {UIProgress}
     */
    updateValue() {
        this.setValue(this.value);
        this.setProgressPosition(this.calcProgress(this.value));
        return this;
    }

    /**
     * Update the limit label.
     * @returns {UIProgress}
     */
    updateLimit() {
        this.limit.innerHTML = this.formatValue(this.max);
        return this;
    }

    /**
     * Calculates progress value based on the given absolute value
     * @param {number} value
     * @returns {number}
     */
    calcProgress(value) {
        const min = Number(this.min);
        const max = Number(this.max);
        return ((value - min) / (max - min)) * 100;
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'min':
            case 'value':
                this.updateValue();
                break;
            case 'units':
            case 'max':
                this.updateValue().updateLimit();
                break;
            case 'label-left':
                this.rebuild.call(this.leftHint, this.buildLeftHint());
                break;
            case 'label-middle':
                this.rebuild.call(this.middleHint, this.buildMiddleHint());
                break;
            case 'label-right':
                this.rebuild.call(this.rightHint, this.buildRightHint());
                break;
        }
    }

    /**
     * @inheritDoc
     */
    buildLeftHint() {
        return {
            tagName: 'ui-hint',
            classList: {
                'ui-progress__hint': true,
                '-start': true,
            },
            children: [this.labelLeft],
        };
    }

    /**
     * @inheritDoc
     */
    buildRightHint() {
        return {
            tagName: 'ui-hint',
            classList: {
                'ui-progress__hint': true,
                '-end': true,
            },
            children: [this.labelRight],
        };
    }

    /**
     * @inheritDoc
     */
    buildMiddleHint() {
        return {
            tagName: 'div',
            classList: {
                'ui-progress__hint': true,
                '-middle': true,
            },
            children:
                this.labelMiddle !== 'off'
                    ? [
                          this.labelMiddle,
                          ' ',
                          {
                              tagName: 'strong',
                              classList: {
                                  'ui-progress__limit': true,
                              },
                              children: [this.formatValue(this.max)],
                          },
                      ]
                    : [],
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'progressbar');
        this.innerHTML = '';
        this.insertElements([
            {
                tagName: 'div',
                classList: {
                    'ui-progress__value': true,
                    '-current': true,
                },
            },
            {
                tagName: 'div',
                classList: {
                    'ui-progress__value': true,
                    '-left': true,
                },
            },
            {
                tagName: 'div',
                classList: {
                    'ui-progress__track': true,
                },
                children: [
                    {
                        tagName: 'div',
                        classList: {
                            'ui-progress__progress': true,
                        },
                    },
                ],
            },
            {
                tagName: 'div',
                classList: {
                    'ui-progress__hints': true,
                },
                children: [
                    this.buildLeftHint(),
                    this.buildMiddleHint(),
                    this.buildRightHint(),
                ],
            },
        ]);
        this.updateValue();
    }
}

UIProgress.defineElement('ui-progress', styles);
export { UIProgress };

import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import { keyCodes, TabIndex } from '../../global/keyboard.js';
import { browser } from '../../global/browser.js';
import {
    addSyntheticFocusVisible,
    buildSyntheticFocusControl,
    createElement,
    dispatchNativeEvent,
    insertElements,
    setInnerText,
    updateElement,
    updateClassList,
    dispatchCustomEvent,
} from '../../global/ui-helpers.js';
import {
    FormatterType,
    formatYearsMonthsValue,
    formatStep,
} from '../../global/formatters.js';
import styles from './ui-slider.css';

/**
 * @memberof SharedComponents
 * @element ui-slider
 * @augments {UIElement}
 * @alias UISlider
 * @element ui-slider
 * @classdesc Represents a class for <code>ui-slider</code> element.
 * @property {boolean} [editable] {@attr editable} Slider is editable or disabled.
 * @property {boolean} [unlimited] {@attr unlimited} Allow put value grater than the max one.
 * @property {FormatterType} [formatter] {@attr formatter}
 * Function to format slider value.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {string} [units] {@attr units} Shows unit as a suffix for the values.
 * @property {HTMLInputElement} control {@readonly} Main input for storing model value and
 * handle events.
 * @property {number} min The minimum slider's value (proxies from control).
 * @property {number} max The maximum slider's value (proxies from control).
 * @property {number} maxlength The maximum length of the slider value (proxies from control).
 * @property {number} step The step value of a slider (proxies from control).
 * @property {number} value The value of the slider (proxies from control).
 * @property {HTMLInputElement} input {@readonly} Additional input for mobile view.
 * @property {HTMLDivElement} sliderValue {@readonly} Shortcut to slider value div element.
 * @property {HTMLElement|UIHint} sliderMin {@readonly} Shortcut to label min container.
 * @property {HTMLElement|UIHint} sliderMax {@readonly} Shortcut to label max container.
 * @property {HTMLDivElement} container {@readonly} Shortcut to container contains whole slider bar.
 * @property {HTMLDivElement} progressBar {@readonly} Shortcut to progress bar.
 * @property {HTMLDivElement} handle {@readonly} Shortcut to slider handler.
 * @property {HTMLDivElement} track {@readonly} Shortcut to slider track.
 * @property {HTMLDivElement} bar {@readonly} Shortcut to slider bar.
 * @property {HTMLDivElement} field {@readonly} Shortcut to input field.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @slot
 * @example
 * <ui-slider units="€" class="-short">
 *   <input type="range" min="5000" max="50000" step="1" value="28000" maxlength="5"/>
 * </ui-slider>
 */
class UISlider extends UIElement {
    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-slider', {
            month: {
                1: 'month',
                2: 'months',
            },
            year: {
                1: 'year',
                2: 'years',
            },
            value: 'Value',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                editable: Boolean,
                unlimited: Boolean,
                formatter: String,
                units: { type: String, default: '' },
                controlId: String,
            },
            children: {
                control: '.ui-slider__control',
                input: '.ui-slider__input',
                container: '.ui-slider__container',
                sliderValue: '.ui-slider__value',
                track: '.ui-slider__track',
                progressBar: '.ui-slider__progress',
                handle: '.ui-slider__handle',
                bar: '.ui-slider__bar',
                field: '.ui-slider__field',
                focusTrigger: '.ui-slider__focus-trigger',
                sliderMin: '.ui-slider__limit.-min',
                sliderMax: '.ui-slider__limit.-max',
            },
        };
    }

    get min() {
        return Number(this.control.min) || 0;
    }
    /**
     * @param {number|string} value
     */
    set min(value) {
        this.control.min = value;
    }

    get max() {
        return Number(this.control.max) || 100;
    }
    /**
     * @param {number|string} value
     */
    set max(value) {
        this.control.max = value;
    }

    get step() {
        return Number(this.control.step) || 1;
    }
    /**
     * @param {number|string} value
     */
    set step(value) {
        this.control.step = value;
    }

    get value() {
        return Number(this.control.value) || 0;
    }
    /**
     * @param {number|string} value
     */
    set value(value) {
        this.control.value = value;
    }

    get maxlength() {
        return this.control.maxLength;
    }
    set maxlength(value) {
        this.control.maxLength = value;
    }

    /**
     * @type {boolean}
     */
    get interact() {
        return this.classList.contains('-interact');
    }

    static get events() {
        return {
            start: ['mousedown', 'touchstart', 'pointerdown'],
            move: ['mousemove', 'touchmove', 'pointermove'],
            end: ['mouseup', 'touchend', 'pointerup'],
        };
    }

    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * Formats value based on given formatter.
     * @param {number} value
     * @param {boolean} [isInput]
     * @param {boolean} [noUnits]
     * @returns {string}
     */
    formatValue(value, isInput = false, noUnits = false) {
        switch (this.formatter) {
            case FormatterType.Years:
            case FormatterType.Months:
                return formatYearsMonthsValue(value, {
                    noUnits,
                    labels: this.constructor.labels,
                });
            case FormatterType.String:
                return String(value);
            default: {
                /** @type Array<string | number> */
                let parts = [value];
                if (this.unlimited && !isInput && value > this.max) {
                    parts = [this.max + '+'];
                }
                if (!noUnits) {
                    parts.push(this.units);
                }
                return parts.join(' ').trim();
            }
        }
    }

    /**
     * Calculates discrete value based on the given relative value and the configured step.
     * @param {number} valueRelative
     * @returns {number}
     */
    calcDiscreteValue(valueRelative) {
        const scale = (this.max - this.min) / this.step;
        const index = Math.round(scale * valueRelative);
        return formatStep(index * this.step + this.min, { step: this.step });
    }

    /**
     * Calculates progress value based on the given absolute value.
     * @param {number} value
     * @returns {number}
     */
    calcProgress(value) {
        if (this.unlimited && this.max && value >= this.max) {
            return 100;
        }
        return ((value - this.min) / (this.max - this.min)) * 100;
    }

    /**
     * Sets the min and max values to the hint placeholders.
     * @returns {UISlider}
     */
    updateHints() {
        setInnerText(this.sliderMin, this.formatValue(this.min, false));
        setInnerText(this.sliderMax, this.formatValue(this.max, false));
        return this;
    }

    /**
     * Sets an absolute value to the controls
     * @param {number} value
     * @returns {UISlider}
     */
    setValue(value) {
        this.value = this.alignValueToRange(value);
        this.updateExtraInputValue(value);
        setInnerText(this.sliderValue, this.formatValue(this.value, false));
        dispatchNativeEvent(this.control, 'input', true);
        dispatchNativeEvent(this.control, 'change', true);
        this.handle.setAttribute('aria-valuemin', String(this.min));
        this.handle.setAttribute('aria-valuemax', String(this.max));
        this.handle.setAttribute('aria-valuenow', String(this.value));
        this.handle.setAttribute(
            'aria-valuetext',
            this.formatValue(this.value)
        );
        return this;
    }

    /**
     * @param {number} v
     * @private
     */
    updateExtraInputValue(v) {
        let noUnits = !browser.mobileMediaMatches();
        if (this.input.type === 'number') {
            noUnits = true;
        }
        this.input.value = this.formatValue(v, true, noUnits);
    }

    /**
     * Checks if value is in range and return an edge value if the value is out of the range.
     * @param {number} value
     * @returns {number}
     */
    alignValueToRange(value) {
        if (value > this.max && !this.unlimited) {
            return this.max;
        }
        if (value < this.min) {
            return this.min;
        }
        return value;
    }

    /**
     * Sets offsets for dynamic elements based on given progress.
     * @param {number} progress
     * @returns {UISlider}
     */
    setSliderPosition(progress) {
        this.adjustElementPosition(this.handle, progress);
        this.adjustElementPosition(this.sliderValue, progress);
        const x = ((100 - progress) * -1).toFixed(2) + '%';
        this.style.setProperty('--slider-progress-x', x);
        return this;
    }

    /**
     * Sets offsets for the give element based on given progress and bounding box.
     * @param {HTMLElement} element
     * @param {number} progress
     * @returns {UISlider}
     */
    adjustElementPosition(element, progress) {
        const x = this.calcElementPosition(element, progress) + 'px';
        element.style.setProperty('--slider-handle-x', x);
        return this;
    }

    /**
     * Calc position of the element based on given progress and bounding box.
     * @param {HTMLElement} element
     * @param {number} progress
     * @returns {number}
     */
    calcElementPosition(element, progress) {
        const trackRect = this.track.getBoundingClientRect();
        const startX = trackRect.left;
        const endX = trackRect.left + trackRect.width;
        const nextX = startX + (trackRect.width * progress) / 100;
        const offsetWidth = element.offsetWidth;
        const nextHandleX = nextX - offsetWidth / 2;
        let nextPosition = (progress / 100) * trackRect.width - offsetWidth / 2;
        if (nextHandleX < startX) {
            nextPosition = 0;
        } else if (nextHandleX + offsetWidth > endX) {
            nextPosition = trackRect.width - offsetWidth;
        }
        return nextPosition;
    }

    /**
     * Sets offset for slider based on current input value.
     * @returns {UISlider}
     */
    adjustSliderPosition() {
        this.setSliderPosition(this.calcProgress(this.value));
        return this;
    }

    /**
     * Applies the current input value to user controls.
     * @returns {UISlider}
     */
    updateValue() {
        this.setValue(this.value);
        this.setSliderPosition(this.calcProgress(this.value));
        return this;
    }

    /**
     * Sync maxlength property with input
     * @private
     */
    updateMaxlength() {
        updateElement(this.input, {
            attributes: {
                maxlength: this.maxlength >= 0 ? this.maxlength : null,
            },
        });
    }

    /**
     * Sync progress bar appearance
     */
    sync() {
        updateClassList(this, { '-interact': true });
        window.requestAnimationFrame(() => {
            this.setSliderPosition(this.calcProgress(this.value));
            updateClassList(this, { '-interact': false });
            dispatchCustomEvent(this, 'sync', {}, true);
        });
    }

    /**
     * Handles attributes mutation.
     * @param {MutationRecord} mutation
     * @private
     */
    handleAttributesMutation(mutation) {
        if (mutation.attributeName === 'maxlength') {
            this.updateMaxlength();
        }
    }

    /**
     * Fires callback when control mutates.
     * @param {Array<MutationRecord>} mutations
     * @private
     */
    handleControlMutations(mutations) {
        for (let i = 0; i < mutations.length; i++) {
            const mutation = mutations[i];
            if (mutation.type === 'attributes') {
                this.handleAttributesMutation(mutation);
            }
        }
    }

    /**
     * Fires callback when an user clicks on the decrement button.
     * @param {KeyboardEvent} e
     * @private
     */
    handleDecrementValue(e) {
        this.value = formatStep(this.value - this.step, { step: this.step });
        this.updateValue();
    }

    /**
     * Fires callback when an user clicks on the increment button.
     * @param {KeyboardEvent} e
     * @private
     */
    handleIncrementValue(e) {
        this.value = formatStep(this.value + this.step, { step: this.step });
        this.updateValue();
    }

    /**
     * Fires callback when a user focuses the input element.
     * @param {FocusEvent} e
     * @private
     */
    handleFocusInput(e) {
        e.target.type = 'number';
        e.target.value = this.value;
    }

    /**
     * Fires callback when a user blurs the input element.
     * @param {FocusEvent|{target: HTMLInputElement}} e
     * @private
     */
    handleBlurInput(e) {
        e.target.type = 'text';
        const value = this.alignValueToRange(Number(e.target.value));
        if (!this.unlimited || !this.max || value < this.max) {
            this.value = this.calcDiscreteValue(this.calcProgress(value) / 100);
        } else {
            this.value = value;
        }
        this.updateValue();
    }

    /**
     * Fires callback when a user starts moving slider handle.
     * @param {PointerEvent | TouchEvent | MouseEvent} e
     * @private
     */
    handleStartMove(e) {
        if (e.button === 2) {
            return;
        }
        updateClassList(this, { '-interact': true });
        UISlider.events.start.forEach((key) => {
            document.addEventListener(key, this.handleClickOutside, {
                passive: false,
            });
        });
        UISlider.events.end.forEach((key) => {
            document.addEventListener(key, this.handleEndMove, {
                passive: false,
            });
        });
        UISlider.events.move.forEach((key) => {
            document.addEventListener(key, this.handleDoMove, {
                passive: false,
            });
        });
        this.handleDoMove(e);
    }

    /**
     * Fires callback when a user is moving slider handle.
     * @param {PointerEvent | TouchEvent | MouseEvent} e
     * @private
     */
    handleDoMove(e) {
        if (!this.interact) {
            return;
        }

        // This prevents default MacOS and iOS behaviour when drag slider window also slightly shifts.
        if (e.cancelable) {
            e.preventDefault();
        }

        let pageX;
        if (e.touches && e.touches[0]) {
            pageX = e.touches[0].pageX;
        } else {
            e.preventDefault();
            pageX = e.pageX;
        }

        const data = this.track.getBoundingClientRect();

        const totalWidth = data.width;
        const relativeX = pageX - data.left;
        let valueRelative = relativeX / totalWidth;
        if (valueRelative < 0) {
            valueRelative = 0;
        }
        if (valueRelative > 1) {
            valueRelative = 1;
        }

        const valueAbsolute = this.calcDiscreteValue(valueRelative);
        this.setSliderPosition(valueRelative * 100);
        this.setValue(valueAbsolute);
    }

    /**
     * Fires callback when an user stops moving slider handle.
     * @param {Event} e
     * @private
     */
    handleEndMove(e) {
        updateClassList(this, { '-interact': false });
        UISlider.events.start.forEach((key) => {
            document.removeEventListener(key, this.handleClickOutside);
        });
        UISlider.events.end.forEach((key) => {
            document.removeEventListener(key, this.handleEndMove);
        });
        UISlider.events.move.forEach((key) => {
            document.removeEventListener(key, this.handleDoMove);
        });
        this.adjustSliderPosition();
    }

    /**
     * Fires callback when user clicked outside of slider element.
     * @param {PointerEvent|{target: HTMLElement}} e
     * @private
     */
    handleClickOutside(e) {
        if (this.interact && !this.isParentOf(e.target)) {
            this.handleEndMove(e);
        }
    }

    /**
     * Fires callback when the handle get a key down event.
     * @param {KeyboardEvent} e
     * @private
     */
    handleKeydown(e) {
        switch (e.keyCode) {
            case keyCodes.LEFT:
                this.handleDecrementValue(e);
                e.preventDefault();
                break;
            case keyCodes.RIGHT:
                this.handleIncrementValue(e);
                e.preventDefault();
                break;
            case keyCodes.PAGEDOWN: {
                this.value -= (this.max - this.min) * 0.1;
                this.updateValue();
                e.preventDefault();
                break;
            }
            case keyCodes.PAGEUP: {
                this.value += (this.max - this.min) * 0.1;
                this.updateValue();
                e.preventDefault();
                break;
            }
            case keyCodes.HOME:
                this.value = this.min;
                this.updateValue();
                e.preventDefault();
                break;
            case keyCodes.END:
                this.value = this.max;
                this.updateValue();
                e.preventDefault();
                break;
        }
    }

    /**
     * Fires callback when the window get resized event.
     * @param {Event} e
     * @private
     */
    handleWindowResize(e) {
        this.sync();
        this.setValue(this.value);
    }

    /**
     * Fires callback when user inputs text.
     * @param {InputEvent|{target: HTMLInputElement}} e
     * @private
     */
    handleInputValue(e) {
        let value = Number(e.target.value) || this.min;
        if (!this.unlimited || !this.max || value < this.max) {
            value = this.calcDiscreteValue(this.calcProgress(value) / 100);
        }
        value = this.alignValueToRange(value);
        this.setSliderPosition(this.calcProgress(value));
        setInnerText(this.sliderValue, this.formatValue(value, false));
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (!this.rendered) {
            return;
        }

        switch (name) {
            case 'control-id':
                if (newValue) {
                    this.input.setAttribute('id', newValue);
                } else {
                    this.input.removeAttribute('id');
                }
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const control = this.queryChildren('input')[0];
        this.detachChildNodes();

        const container = createElement({
            tagName: 'div',
            classList: {
                'ui-slider__container': true,
            },
        });

        insertElements(container, [
            {
                tagName: 'input',
                element: control,
                attributes: {
                    type: 'hidden',
                    // #pally
                    'aria-label': 'virtual',
                },
                classList: {
                    'ui-slider__control': true,
                },
            },
            buildSyntheticFocusControl(this.controlId, 'ui-slider'),
            {
                tagName: 'div',
                classList: {
                    'ui-slider__bar': true,
                },
                children: [
                    {
                        tagName: 'div',
                        classList: {
                            'ui-slider__track': true,
                        },
                        children: [
                            {
                                tagName: 'div',
                                classList: {
                                    'ui-slider__progress': true,
                                },
                            },
                        ],
                    },
                    {
                        tagName: 'span',
                        attributes: {
                            role: 'slider',
                            tabindex: TabIndex.Active,
                            draggable: 'true',
                        },
                        classList: {
                            'ui-slider__handle': true,
                        },
                    },
                    {
                        tagName: 'div',
                        classList: {
                            'ui-slider__value': true,
                        },
                    },
                ],
            },
            {
                tagName: 'div',
                classList: {
                    'ui-slider__limits': true,
                },
                children: [
                    {
                        tagName: 'ui-hint',
                        classList: {
                            'ui-slider__limit': true,
                            '-min': true,
                        },
                    },
                    {
                        tagName: 'ui-hint',
                        classList: {
                            'ui-slider__limit': true,
                            '-max': true,
                        },
                    },
                ],
            },
        ]);
        insertElements(this, [
            container,
            {
                tagName: 'div',
                classList: {
                    'ui-slider__field': true,
                },
                children: [
                    {
                        tagName: 'input',
                        attributes: {
                            type: 'text',
                            'aria-label': UISlider.labels.value,
                            inputmode: 'decimal',
                            step: control?.getAttribute('step') || null,
                            min: control?.getAttribute('min') || null,
                            max: control?.getAttribute('max') || null,
                        },
                        classList: {
                            'ui-slider__input': true,
                            '-hidden': !this.editable,
                        },
                    },
                    {
                        tagName: 'input',
                        classList: {
                            'ui-slider__units-input': true,
                        },
                        attributes: {
                            type: 'text',
                            'aria-readonly': 'true',
                            'aria-label': this.units ? this.units : null,
                            'aria-hidden': !this.units ? 'true' : null,
                            tabindex: TabIndex.Inactive,
                            value: this.units,
                        },
                    },
                ],
            },
        ]);

        updateClassList(this, { '-interact': true });
        this.updateHints();
        this.value = Number(this.control.getAttribute('value'));
        this.updateValue();
        updateClassList(this, { '-interact': false });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleStartMove = this.handleStartMove.bind(this);
        this.handleEndMove = this.handleEndMove.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleDoMove = this.handleDoMove.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);

        // Remove virtual focus trigger from accessibility tree.
        this.focusTrigger.setAttribute('aria-hidden', 'true');

        this.focusTrigger.addEventListener('click', () =>
            addSyntheticFocusVisible(this.handle)
        );

        window.addEventListener('resize', this.handleWindowResize);

        const barEvents = {};
        const keyEvents = {
            keydown: this.handleKeydown,
        };

        UISlider.events.start.forEach((key) => {
            keyEvents[key] = this.handleStartMove;
            barEvents[key] = this.handleStartMove;
        });

        updateElement(this.bar, { events: barEvents });
        updateElement(this.handle, { events: keyEvents });
        updateElement(this.input, {
            events: {
                focus: this.handleFocusInput.bind(this),
                blur: this.handleBlurInput.bind(this),
                input: this.handleInputValue.bind(this),
            },
        });
        this.updateExtraInputValue(this.value);
        this.updateMaxlength();
        if (browser.supportsIntersectionObserver()) {
            this.observer = new IntersectionObserver(
                (entries) => {
                    [].forEach.call(entries, (entry) => {
                        if (entry.isIntersecting) {
                            this.sync();
                            this.observer.disconnect();
                        }
                    });
                },
                { root: null, rootMargin: '0px' }
            );
            this.observer.observe(this);
        } else {
            this.sync();
        }
        this.controlObserver = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.controlObserver.observe(this.control, { attributes: true });

        // Improved accessibility for slider.
        requestAnimationFrame(() => {
            const labelForElem = document.querySelector(
                `[for=${this.controlId}]`
            );
            if (labelForElem?.textContent) {
                this.handle.setAttribute(
                    'aria-label',
                    labelForElem.textContent
                );
            }
        });
    }
}

UISlider.defineElement('ui-slider', styles);
export { UISlider };

import { UIElement } from '../ui-element.js';
import { Digest } from '../../global/digest.js';
import styles from './ui-legalblock.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UILegalBlock
 * @element ui-legalblock
 * @classdesc Represents a class for <code>ui-legalblock</code> element.
 * Container for legal terms and conditions.
 * @property {string} [color="gray"] {@attr color} color of background, default is transparent.
 * @slot
 * @example
 * <ui-legalblock color="gray">
 *   By signing this document...
 * </ui-legalblock>
 */
class UILegalBlock extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                color: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this._id = Digest.randomId();
        const header = this.querySelector('h2,h3');
        if (header && !header.id) {
            header.id = 'ui-legalblock-header-' + this._id;
        }
        const frame = this.querySelector('ui-scrollable-frame');
        if (header && frame) {
            frame.setAttribute('aria-labelledby', header.id);
        }
    }
}

UILegalBlock.defineElement('ui-legalblock', styles);
export { UILegalBlock };

import { UIElement } from '../ui-element.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import { isBrandColor } from '../../global/helpers-marketing.js';
import styles from './ui-infobox.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIInfobox
 * @element ui-infobox
 * @classdesc Represents a class for <code>ui-infobox</code> element.
 * @property {UIBrandColor} [color="pineapple"] {@attr color} Color of infobox's title.
 * @property {string} [icon] {@attr icon} Glyph of icon, same names as used in ui-icon.
 * @property {string} [label] {@attr label} Title for upper part of infobox.
 * @property {("default" | "bubble" | "centered")} [layout] {@attr layout} Layout of infobox.
 *  {@desc bubble}
 *  {@desc centered}
 * @slot
 * @example
 * <ui-infobox color="turquoise" icon="transport" layout="bubble">
 *   <p>I am turquoise car.</p>
 * </ui-infobox>
 */
class UIInfobox extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                color: {
                    type: String,
                    default: BrandColors.Pineapple,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        const info = `Allowed options are: turquoise, lilac, pineapple.`;
                        if (['yellow', 'blue'].includes(value)) {
                            console.warn(
                                `${value} - color is deprecated. ${info}`,
                                component
                            );
                            return;
                        }
                        if (!isBrandColor(value)) {
                            console.warn(
                                `${value} - value does not exist. ${info}`,
                                component
                            );
                        }
                    },
                },
                icon: String,
                label: String,
                layout: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.insertElements([
            {
                tagName: 'h3',
                attributes: {
                    'aria-hidden': !this.label ? 'true' : null,
                },
                children: [
                    this.icon && {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: this.icon,
                        },
                    },
                    this.label || '',
                ],
            },
            {
                tagName: 'article',
                children: this.childNodes,
            },
        ]);
    }
}

UIInfobox.defineElement('ui-infobox', styles);
export { UIInfobox };

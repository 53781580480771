/**
 * Create an IElementConfig based on IFieldControl.
 * @param {IFieldControl} control
 * @returns {IElementConfig}
 * @private
 */
export function fromControlConfig(control) {
    const config = control.config || {};
    return {
        tagName: 'ui-slider',
        attributes: {
            class: config.class,
            units: config.units,
        },
        children: [
            {
                tagName: 'input',
                attributes: control.attributes,
            },
        ],
    };
}

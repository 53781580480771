import { appendModalControllerIfRequired } from '../../global/helpers.js';
import { nextByCond } from '../../global/ui-helpers.js';
import { eventBus } from '../../global/index.js';
import { Labels } from '../../global/labels.js';
import { createElement, updateElement } from '../../global/render-api.js';
import { element, html } from '../../global/template-literals.js';
import { UIElement } from '../ui-element.js';
import styles from './ui-comparison-cards.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIComparisonCards
 * @element ui-comparison-cards
 * @classdesc Represents a class for <code>ui-comparison-cards</code> element.
 * @property {("table" | "cards")} [layout="table"] {@attr layout} Current layout,
 * shouldn't be changed manually.
 * @property {string} [figcaptionSelectors] {@attr figcaption-selectors}
 * Comma separated list of selectors which elements inside "figcaption"
 * should be visible additionally in modal comparison view.
 * @property {Element} comparisonModalContent Reference to comparison modal content
 * @property {HTMLTemplateElement} template {@readonly} ui-table template
 * @example
 * <ui-comparison-cards>
 *   <template>
 *     <ui-table></ui-table>
 *   </template>
 * </ui-comparison-cards>
 */
class UIComparisonCards extends UIElement {
    constructor() {
        super();
        /**
         * @type {string | number | null}
         * @private
         */
        this.previousComparisonValueA = null;
        /**
         * @type {string | number | null}
         * @private
         */
        this.previousComparisonValueB = null;
        /**
         * @type {Element | Element[] | null}
         * @private
         */
        this.comparisonModalContent = null;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: this.Layout.Table },
                'figcaption-selectors': { type: String },
            },
            children: {
                template: 'template',
            },
        };
    }

    /**
     * @type {UIComparisonCardsLayout}
     */
    static get Layout() {
        return {
            Table: 'table',
            Cards: 'cards',
        };
    }

    /**
     * @inheritDoc
     */
    static get observedAttributes() {
        return ['layout'];
    }

    /**
     * Localization
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-comparison-cards', {
            compareDetails: 'Compare details',
            available: 'Available',
            notAvailable: 'Not available',
            comparisonItem1: 'Comparison item 1',
            comparisonItem2: 'Comparison item 2',
            selectProductsCompare: 'Select which products to compare.',
        });
    }

    /**
     * Returns true for small screens
     * @returns {boolean}
     */
    get isSmallScreen() {
        return this.mobileMediaQueryList.matches;
    }

    /**
     * Gets table from template element
     * @returns {UITable}
     */
    get tableTemplate() {
        let template = this.template;
        if (!template) {
            if (this.constructor.DEBUG_MODE) {
                console.warn(`'<template></template>' is missing`, this);
            }
            template = createElement({ tagName: 'template' });
            updateElement(template.content, {
                children: [
                    {
                        tagName: 'ui-table',
                    },
                ],
            });
        }

        /** @type {UITable | Node} */
        let uiTable = template.content
            .querySelector('ui-table')
            ?.cloneNode(true);
        if (!uiTable) {
            uiTable = createElement({ tagName: 'ui-table' });
        }

        let table = uiTable.querySelector('table');
        if (!table) {
            table = createElement({ tagName: 'table' });
            updateElement(uiTable, {
                children: [table],
            });
        }

        if (!table.querySelector('thead')) {
            updateElement(table, {
                children: [
                    {
                        tagName: 'thead',
                    },
                ],
            });
        }

        if (!table.querySelector('tbody')) {
            updateElement(table, {
                children: [
                    {
                        tagName: 'tbody',
                    },
                ],
            });
        }

        if (!table.querySelector('tfoot')) {
            updateElement(table, {
                children: [
                    {
                        tagName: 'tfoot',
                    },
                ],
            });
        }

        // hide all rows with "-featured" class that don't have a header
        table.querySelectorAll('tr.-featured:not(.-hidden)').forEach((row) => {
            if (!row.querySelector(':scope > th')) {
                row.classList.add('-hidden');
            }
        });

        const plugins = (uiTable.getAttribute('plugins') || '')
            .split(' ')
            .filter((x) => x);
        const missingPlugins = ['comparison', 'auto'].filter(
            (plugin) => !plugins.includes(plugin)
        );
        if (missingPlugins.length) {
            uiTable.setAttribute(
                'plugins',
                [...plugins, ...missingPlugins].join(' ')
            );
        }

        return uiTable;
    }

    /**
     * Gets next non-hidden cell index.
     * @private
     * @param {number} index
     * @returns {number}
     */
    getNextComparedIndex(index) {
        const table = this.tableTemplate.querySelector('table');
        const headerCells = table.tHead.querySelectorAll('th[scope="col"]');
        const current = headerCells[index];
        const next = nextByCond(current, (cell) => {
            return (
                !cell.classList.contains('-hidden') &&
                cell.getAttribute('scope') !== 'row'
            );
        });
        return [].findIndex.call(headerCells, (cell) => cell === next);
    }

    /**
     * Gets comparison selects from modal element
     * @returns {NodeListOf<HTMLSelectElement>}
     */
    get comparisonModalSelects() {
        return this.comparisonModalContent.querySelectorAll(
            '.ui-comparison-cards__comparison-select'
        );
    }

    /**
     * Reference for comparison select A
     * @type {HTMLSelectElement}
     * @readonly
     */
    get comparisonSelectA() {
        return this.comparisonModalSelects[0];
    }

    /**
     * Reference for comparison select B
     * @type {HTMLSelectElement}
     * @readonly
     */
    get comparisonSelectB() {
        return this.comparisonModalSelects[1];
    }

    /**
     * Gets first comparison select value
     * @type {number}
     * @readonly
     */
    get comparisonValueA() {
        return +this.comparisonSelectA.value;
    }

    /**
     * Gets second comparison select value
     * @type {number}
     * @readonly
     */
    get comparisonValueB() {
        return +this.comparisonSelectB.value;
    }

    /**
     * Reference to comparison modal content
     * @returns {SharedComponents.UIModal | null}
     */
    get comparisonModal() {
        return this.comparisonModalContent?.closest('ui-modal');
    }

    /**
     * Constructs feature element for smaller screens
     * @param {HTMLTableRowElement} row
     * @param {number} index
     * @returns {DocumentFragment | IElementConfig}
     */
    constructFeatureListItem(row, index) {
        const header = row.querySelector('th[scope="row"]');
        const cell = row.querySelectorAll('td')[index];

        if (!cell) {
            console.error(`Missing <td></td> for column: ${index}`, row, this);
            return null;
        }

        // only show available features
        if (cell.querySelector('ui-icon[glyph="cross"]')) {
            return null;
        }

        const getHeaderChildren = () =>
            [].map.call(header.childNodes, (child) => html`${child}`);

        /**
         * @param {{lowercaseFirstChar: boolean}} [opts]
         * @returns {Array<DocumentFragment | string>}
         */
        const getCellChildren = ({ lowercaseFirstChar = false } = {}) =>
            [].map.call(cell.childNodes, (child) => {
                const node = child.cloneNode(true);
                if (node.nodeType === Node.TEXT_NODE) {
                    const text = node.textContent.trim();
                    if (!text) {
                        return null;
                    }
                    if (lowercaseFirstChar) {
                        return text.charAt(0).toLowerCase() + text.slice(1);
                    }
                    return text;
                }

                return html`${node}`;
            });

        /**
         * @returns {DocumentFragment}
         */
        const getFeatureText = () => {
            if (header?.childNodes.length) {
                if (cell.textContent.trim()) {
                    return html`${getHeaderChildren()}
                    ${getCellChildren({ lowercaseFirstChar: true })}`;
                }
                return html`${getHeaderChildren()}`;
            }

            return html`${getCellChildren()}`;
        };

        const featureText = getFeatureText();
        return featureText.childNodes.length
            ? { tagName: 'li', children: [featureText] }
            : null;
    }

    /**
     * Creates cards layout
     * @returns {HTMLElement}
     */
    createCardsLayout() {
        /**
         * @type {HTMLTableElement}
         */
        const table = this.tableTemplate.querySelector('table');
        const headerCells = table.tHead.querySelectorAll('th[scope="col"]');
        const featuredRows = table.tBodies[0].querySelectorAll('tr.-featured');

        return createElement({
            tagName: 'ui-columns',
            classList: {
                'ui-comparison-cards__layout-cards': true,
            },
            attributes: {
                role: 'group',
            },
            children: /** @type {Array<HTMLTableCellElement>} */ [
                [].map.call(headerCells, (cell, i) => {
                    const clonedHeaderCell = cell.cloneNode(true);
                    const header = clonedHeaderCell.querySelector(
                        ':is(h1, h2, h3, h4, h5, h6)'
                    );
                    const descriptions = clonedHeaderCell.querySelectorAll('p');
                    const img = clonedHeaderCell.querySelector('img');
                    const mainBtn = clonedHeaderCell.querySelector(
                        'button.button[type="button"]'
                    );
                    const moreDetailsLink =
                        clonedHeaderCell.querySelector('a.default-link');
                    const filteredElements = [mainBtn, moreDetailsLink];
                    const descriptionsFiltered = [].filter
                        .call(
                            descriptions,
                            (description) =>
                                ![].some.call(description.children, (child) =>
                                    filteredElements.includes(child)
                                )
                        )
                        .map((description) => {
                            return html`${description}`;
                        });
                    /** @type {IElementConfig} */
                    const compareBtn = {
                        tagName: 'button',
                        classList: { button: true, '-guiding': true },
                        attributes: {
                            type: 'button',
                        },
                        events: { click: () => this.openComparisonModal(i) },
                        children: [UIComparisonCards.labels.compareDetails],
                    };

                    const features = [];
                    featuredRows.forEach((row) => {
                        if (row.querySelector('td[colspan]')) {
                            console.error(
                                '-featured class rows should not have colspan',
                                row
                            );
                        } else {
                            const clonedRow =
                                /** @type {HTMLTableRowElement} */ row.cloneNode(
                                    true
                                );
                            features.push(
                                this.constructFeatureListItem(clonedRow, i)
                            );
                        }
                    });

                    const clonedClass =
                        clonedHeaderCell.getAttribute('class') || null;
                    const clonedId =
                        clonedHeaderCell.getAttribute('id') || null;
                    return createElement({
                        tagName: 'ui-column',
                        classList: {
                            [clonedClass]: !!clonedClass,
                        },
                        attributes: {
                            id: clonedId,
                        },
                        children: [
                            {
                                tagName: 'ui-card',
                                children: [
                                    html`
                                        <article>
                                            <ui-placement
                                                class="ui-comparison-cards__heading"
                                                alignment="center"
                                                spacious
                                            >
                                                ${img} ${header}
                                                ${descriptionsFiltered}
                                            </ui-placement>
                                            ${features.length > 0 &&
                                            html`
                                                <ul
                                                    class="feature-list ui-comparison-cards__features"
                                                >
                                                    ${features.map(
                                                        (feature) =>
                                                            html`${feature}`
                                                    )}
                                                </ul>
                                            `}
                                            <ui-buttonbar>
                                                ${mainBtn} ${compareBtn}
                                            </ui-buttonbar>
                                            <ui-placement
                                                class="ui-comparison-cards__more-details"
                                                alignment="center"
                                            >
                                                ${moreDetailsLink}
                                            </ui-placement>
                                        </article>
                                    `,
                                ],
                            },
                        ],
                    });
                }),
            ],
        });
    }

    /**
     * Creates table layout
     * @returns {UITable}
     */
    createTableLayout() {
        /** @type {UITable} */
        const table = this.tableTemplate;
        const availableIcons = table.querySelectorAll(
            'ui-icon[glyph="agree"][bgcolor="orange"]'
        );
        const unavailableIcons = table.querySelectorAll(
            'ui-icon[glyph="cross"][bgcolor="bark-30"]'
        );
        availableIcons.forEach((icon) => {
            icon.ariaLabel =
                icon.ariaLabel || UIComparisonCards.labels.available;
            // icon.ariaLabel ??= UIComparisonCards.labels.available;
        });
        unavailableIcons.forEach((icon) => {
            icon.ariaLabel =
                icon.ariaLabel || UIComparisonCards.labels.notAvailable;
            // icon.ariaLabel ??= UIComparisonCards.labels.notAvailable;
        });
        table.classList.add('ui-comparison-cards__layout-table');
        return table;
    }

    /**
     * Changes layout based on screen size
     */
    changeLayout() {
        this.layout = this.isSmallScreen
            ? this.constructor.Layout.Cards
            : this.constructor.Layout.Table;
        if (this.comparisonModalContent) {
            this.closeComparisonModal();
        }
    }

    /**
     * Creates comparison table
     * @param {number} comparisonIndexA
     * @param {number} comparisonIndexB
     * @returns {UITable}
     */
    createComparisonTable(comparisonIndexA, comparisonIndexB) {
        const uiTable = this.tableTemplate;
        /**
         * @type {HTMLTableElement}
         */
        const table = uiTable.querySelector('table');
        table.querySelector('colgroup')?.remove();

        /**
         * Removes cells that are not relevant for comparison
         * @param {NodeListOf<HTMLTableCellElement>} cells
         */
        const removeIrrelevantCells = (cells) => {
            cells.forEach((cell, index) => {
                if (index !== comparisonIndexA && index !== comparisonIndexB) {
                    cell.remove();
                }
            });
        };

        /**
         * When comparing to each other, the order of cells should be reversed
         * @param {NodeListOf<HTMLTableCellElement>} cells
         */
        const swapCellsPosition = (cells) => {
            const firstCell = cells[0];
            firstCell.parentNode.appendChild(firstCell);
        };

        /**
         * Removes rows with "-hidden" class
         * @param {NodeListOf<HTMLTableRowElement>} rows
         */
        const removeHiddenRows = (rows) => {
            const hiddenRows = [].filter.call(rows, (row) =>
                row.classList.contains('-hidden')
            );
            hiddenRows.forEach((row) => row.remove());
        };

        const headerRows = table.tHead.rows;
        [].forEach.call(headerRows, (row) => {
            const cells = row.querySelectorAll('th[scope="col"]');
            removeIrrelevantCells(cells);
            cells.forEach((cell) => {
                const allowedSelectors = `h3, ui-badge, ${this.figcaptionSelectors}`;
                const childrenToRemove = cell.querySelectorAll(
                    `figcaption > :not(:where(${allowedSelectors}))`
                );
                childrenToRemove.forEach((child) => child.remove());
            });
        });

        const bodyRows = table.tBodies[0].rows;
        removeHiddenRows(bodyRows);
        [].forEach.call(bodyRows, (row) => {
            const cells = row.querySelectorAll('td');
            removeIrrelevantCells(cells);
        });

        const footerRows = table.tFoot.rows;
        [].forEach.call(footerRows, (row) => {
            const cells = row.querySelectorAll('td');
            removeIrrelevantCells(cells);
        });

        // reverse order of rows
        if (comparisonIndexA > comparisonIndexB) {
            const headerRows = table.tHead.rows;
            [].forEach.call(headerRows, (row) => {
                const cells = row.querySelectorAll('th[scope="col"]');
                swapCellsPosition(cells);
            });

            const bodyRows = table.tBodies[0].rows;
            [].forEach.call(bodyRows, (row) => {
                const cells = row.querySelectorAll('td');
                swapCellsPosition(cells);
            });

            const footerRows = table.tFoot.rows;
            [].forEach.call(footerRows, (row) => {
                const cells = row.querySelectorAll('td');
                swapCellsPosition(cells);
            });
        }

        return uiTable;
    }

    /**
     * Creates comparison select
     * @param {number} comparisonIndexA
     * @param {number} comparisonIndexB
     * @returns {IElementConfig}
     */
    createComparisonSelect(comparisonIndexA, comparisonIndexB) {
        /**
         * @type {HTMLTableElement}
         */
        const table = this.tableTemplate.querySelector('table');
        const headerCells = table.tHead.querySelectorAll('th[scope="col"]');
        const values = [];
        for (let i = 0; i < headerCells.length; i++) {
            if (headerCells[i].classList.contains('-hidden')) {
                continue;
            }
            const cell = headerCells[i];
            values.push({
                index: i,
                content:
                    cell.querySelector(':is(h1, h2, h3, h4, h5, h6, p)')
                        ?.textContent || cell.textContent,
            });
        }
        return {
            tagName: 'ui-field',
            children: [
                {
                    tagName: 'ui-inputgroup',
                    children: [
                        {
                            tagName: 'select',
                            attributes: {
                                'aria-label':
                                    UIComparisonCards.labels.comparisonItem1,
                            },
                            classList: {
                                'ui-comparison-cards__comparison-select': true,
                                '-long': true,
                            },
                            events: {
                                change: (e) => {
                                    const valueA = +e.target.value;
                                    let valueB = this.comparisonValueB;
                                    if (valueA === valueB) {
                                        // swap places
                                        valueB = this.previousComparisonValueA;
                                        this.comparisonSelectB.value =
                                            String(valueB);
                                    }
                                    this.changeComparison(valueA, valueB);
                                },
                            },
                            children: values.map((option) => {
                                return {
                                    tagName: 'option',
                                    attributes: {
                                        value: option.index,
                                        selected:
                                            option.index === comparisonIndexA,
                                    },
                                    children: [option.content],
                                };
                            }),
                        },
                        {
                            tagName: 'select',
                            attributes: {
                                'aria-label':
                                    UIComparisonCards.labels.comparisonItem2,
                            },
                            classList: {
                                'ui-comparison-cards__comparison-select': true,
                                '-long': true,
                            },
                            events: {
                                change: (e) => {
                                    let valueA = this.comparisonValueA;
                                    const valueB = +e.target.value;
                                    if (valueA === valueB) {
                                        // swap places
                                        valueA = this.previousComparisonValueB;
                                        this.comparisonSelectA.value =
                                            String(valueA);
                                    }
                                    this.changeComparison(valueA, valueB);
                                },
                            },
                            children: values.map((option) => {
                                return {
                                    tagName: 'option',
                                    attributes: {
                                        value: option.index,
                                        selected:
                                            option.index === comparisonIndexB,
                                    },
                                    children: [option.content],
                                };
                            }),
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Opens comparison modal
     * @param {number} cellIndex
     */
    openComparisonModal(cellIndex) {
        let comparedWithIndex = this.getNextComparedIndex(cellIndex);
        if (comparedWithIndex === -1) {
            comparedWithIndex = cellIndex === 0 ? 1 : 0;
        }
        const select = this.createComparisonSelect(
            cellIndex,
            comparedWithIndex
        );
        const table = this.createComparisonTable(cellIndex, comparedWithIndex);
        this.comparisonModalContent = element`
        <div class="ui-comparison-cards__modal-content">
          <h3>${UIComparisonCards.labels.compareDetails}</h3>
          <p>${UIComparisonCards.labels.selectProductsCompare}</p>
          <div class="ui-comparison-cards__modal-content__select">
            ${select}
          </div>
          ${table}
        </div>
      `;
        eventBus.dispatchCustomEvent('modal-open', {
            type: 'element',
            content: this.comparisonModalContent,
            params: {
                onClose: () => {
                    this.comparisonModalContent = null;
                },
            },
        });
        this.previousComparisonValueA = this.comparisonValueA;
        this.previousComparisonValueB = this.comparisonValueB;
    }

    /**
     * Closes comparison modal if it's open
     */
    closeComparisonModal() {
        this.comparisonModal?.close();
        this.comparisonModalContent = null;
    }

    /**
     * Swaps comparison table with different values
     * @param {number} comparisonIndexA
     * @param {number} comparisonIndexB
     */
    changeComparison(comparisonIndexA, comparisonIndexB) {
        const table = this.createComparisonTable(
            comparisonIndexA,
            comparisonIndexB
        );
        const previousTable =
            this.comparisonModalContent.querySelector('ui-table');
        previousTable.replaceWith(table);
        this.previousComparisonValueA = comparisonIndexA;
        this.previousComparisonValueB = comparisonIndexB;
    }

    /**
     * Renders element layout based on layout attribute
     * @returns {UITable|HTMLElement}
     */
    renderLayout() {
        switch (this.layout) {
            case this.constructor.Layout.Cards:
                return this.createCardsLayout();
            case this.constructor.Layout.Table:
            default:
                return this.createTableLayout();
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (!oldValue) {
            return;
        }

        switch (name) {
            case 'layout': {
                const previousLayout = this.querySelector(
                    `.ui-comparison-cards__layout-${oldValue}`
                );
                if (previousLayout) {
                    const layout = this.renderLayout();
                    this.replaceChild(layout, previousLayout);
                }
                break;
            }
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('layout', this.layout);
        const layout = this.renderLayout();
        this.insertBefore(layout, this.template);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
        this.mobileMediaQueryList.addEventListener(
            'change',
            this.changeLayout.bind(this)
        );
        this.changeLayout();
        appendModalControllerIfRequired();
    }
}

UIComparisonCards.defineElement('ui-comparison-cards', styles);
export { UIComparisonCards };

/**
 * @callback IConfigFactory
 * @param {object} state
 * @returns {IElementConfig}
 */

import { rebuild } from './mutations.js';
import { createElement } from './render-api.js';

/**
 * create proxy object to given node.
 * @memberof UiHelpers
 * @param {object} initialState
 * @param {Function} handleOnChange
 * @returns {object}
 */
const observeObjectChanges = (initialState, handleOnChange) => {
    return new Proxy(initialState, {
        get(target, property) {
            const item = target[property];
            if (item && typeof item === 'object') {
                // supports nested objects
                return observeObjectChanges(item, (subTarget, subProp, val) => {
                    subTarget[subProp] = val;
                    return handleOnChange(target, property, item);
                });
            }
            return item;
        },
        set: handleOnChange,
    });
};

/**
 * Bind proxy object to given node.
 * @memberof UiHelpers
 * @param {HTMLElement} node
 * @param {IConfigFactory} configFactory
 * @param {object} initialState
 * @param {boolean} [trusted] - defines if element's strings are trusted HTML
 * @returns {HTMLElement}
 */
const bindReactiveElement = (node, configFactory, initialState, trusted) => {
    node.reactiveState = observeObjectChanges(
        initialState,
        (target, prop, val) => {
            target[prop] = val;
            try {
                rebuild(node, configFactory(target));
                return true;
            } catch (e) {
                console.log(node.constructor.name, node);
                console.error(e);
                return false;
            }
        }
    );
    return node;
};

/**
 * Creates reactive element by given state and builder function.
 * @memberof UiHelpers
 * @param {IConfigFactory} configFactory
 * @param {object} initialState
 * @param {boolean} [trusted] - defines if element's strings are trusted HTML
 * @returns {HTMLElement}
 */
const createReactiveElement = (configFactory, initialState, trusted) => {
    const node = createElement(configFactory(initialState), trusted);
    return bindReactiveElement(node, configFactory, initialState, trusted);
};

/**
 * @memberof UiHelpers
 * @param {HTMLElement} node
 * @returns {boolean}
 */
const isReactiveElement = (node) => !!node.reactiveState;

export {
    createReactiveElement,
    bindReactiveElement,
    isReactiveElement,
    observeObjectChanges,
};

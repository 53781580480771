import { Grammar } from './grammar.js';

/**
 * @memberof SharedComponents
 * @class Formatter
 * @alias formatter
 */

/**
 * @memberof Formatter
 * @type {object}
 * @property {"string"} String - Formats value as just a string
 * @property {"years"} Years - Formats value as years
 * @property {"months"} Months - Formats value as month and years both
 */
const FormatterType = {
    String: 'string',
    Years: 'years',
    Months: 'months',
};

/**
 * @memberof Formatter
 * @param {string|number} value
 * @param {YearsFormatterConfig} [config]
 * @returns {string|number}
 */
const formatYearsValue = (value, config = {}) => {
    return String(
        `${value} ` +
            `${
                !config.noUnits
                    ? config.labels.year[Grammar.quantify(value)]
                    : ''
            }`
    ).trim();
};

/**
 * @memberof Formatter
 * @param {string|number} value
 * @param {MonthFormatterConfig} [config]
 * @returns {string|number}
 */
const formatMonthsValue = (value, config = {}) => {
    return String(
        `${value} ` +
            `${
                !config.noUnits
                    ? config.labels.month[Grammar.quantify(value)]
                    : ''
            }`
    ).trim();
};

/**
 * @memberof Formatter
 * @param {string|number} value
 * @param {YearsMonthsFormatterConfig} [config]
 * @returns {string|number}
 */
const formatYearsMonthsValue = (value, config = {}) => {
    const years = Math.floor(value / 12);
    const months = value % 12;
    let yearsStr = years > 0 ? formatYearsValue(years, config) : '';
    let monthStr =
        months > 0 || years === 0 ? formatMonthsValue(months, config) : '';
    return [yearsStr, monthStr].join(' ').trim();
};

/**
 * Used to format value depends on the step in ui-slider and ui-stepper.
 * @memberof Formatter
 * @param {string|number} value
 * @param {StepFormatterConfig} [config]
 * @returns {string|number}
 */
const formatStep = (value, config = {}) => {
    const rightPart = String(config.step).split('.')[1];
    const factor = Math.pow(10, rightPart ? rightPart.length : 0);
    return Math.round(value * factor) / factor;
};

export {
    FormatterType,
    formatYearsValue,
    formatMonthsValue,
    formatYearsMonthsValue,
    formatStep,
};

/**
 * @typedef {("string" | "years" | "months")} FormatterType
 */
/**
 * @typedef {object} GenericFormatterConfig
 * @property {boolean} [noUnits] - do not add units to output
 * @property {UILabelType | null} [labels] - labels for units like (year, month, etc.)
 * @property {number} [step]
 * }
 */
/**
 * @typedef {GenericFormatterConfig} YearsFormatterConfig
 */
/**
 * @typedef {GenericFormatterConfig} MonthFormatterConfig
 */
/**
 * @typedef {GenericFormatterConfig} YearsMonthsFormatterConfig
 */
/**
 * @typedef {GenericFormatterConfig} StepFormatterConfig
 */

import { UIElement } from '../ui-element.js';
import styles from './ui-option-dial.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionDial
 * @element ui-option-dial
 * @classdesc Represents a class for <code>ui-option-dial</code> element.
 * Similar to ui-option, but holds telephone numbers. Used with combination
 * with ui-dropdown.
 * @property {string} [flag] {@attr flag} Flag of the country.
 * @property {string} [value] {@attr value} Value of the dial option
 * @property {string} [text] {@attr text} Text of the dial option.
 * @property {boolean} control {@readonly} It shows if the element will be located in control.
 * @example
 * <ui-option-dial></ui-option-dial>
 */
class UIOptionDial extends UIElement {
    static get POPOVER_TYPE() {
        return 'plain';
    }

    static get POPOVER_H_OFFSET() {
        return -2;
    }

    static get POPOVER_V_OFFSET() {
        return 12;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                text: String,
                flag: String,
                value: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateClassList({
            'ui-option-dial': true,
            '-control': this.control,
        });

        this.insertElements([
            {
                tagName: 'div',
                attributes: {
                    class: '-flag ' + (this.flag ? this.flag : ''),
                },
            },
            {
                tagName: 'span',
                attributes: {
                    class: 'dial-code',
                },
                children: [this.value],
            },
            {
                tagName: 'span',
                children: [this.text],
                attributes: {
                    class: 'dial-name',
                },
            },
            {
                tagName: 'ui-icon',
                attributes: {
                    glyph: 'area-code',
                },
            },
            {
                tagName: 'ui-icon',
                attributes: {
                    glyph: 'agree',
                },
            },
        ]);
    }
}

UIOptionDial.defineElement('ui-option-dial', styles);
export { UIOptionDial };

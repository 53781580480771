/**
 * Global boolean attributes.
 * @memberof UiHelpers
 * @type {Array<string>}
 */
const BOOLEAN_ATTRIBUTES = [
    'disabled',
    'readonly',
    'required',
    'selected',
    'checked',
];

/**
 * List of primary interactive elements. Usually used to determine
 * focusable elements.
 * @memberof UiHelpers
 * @type {Array<string>}
 */
const PRIMARY_INTERACTIVE_ELEMENTS = [
    'input:not([disabled]):not([type="hidden"])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    '[tabindex]:not([disabled]):not([tabindex="-1"])',
].map(function (selector) {
    return selector + ':not(.-hidden)';
});

/**
 * List of secondary interactive elements. Usually used to determine
 * focusable elements.
 * @memberof UiHelpers
 * @type {Array<string>}
 */
const SECONDARY_INTERACTIVE_ELEMENTS = [
    'a:not([disabled]):not([tabindex="-1"])',
    'button:not([disabled]):not([tabindex="-1"])',
].map(function (selector) {
    return selector + ':not(.-hidden)';
});

/**
 * SVG - related tags
 * @memberof UiHelpers
 * @type {Array<string>}
 */
const SVG_ELEMENTS = [
    'svg',
    'use',
    'path',
    'g',
    'defs',
    'filter',
    'feComponentTransfer',
    'feFuncR',
    'feFuncG',
    'feFuncB',
];

/**
 * Input elements.
 * @memberof UiHelpers
 * @type {Array}
 */
const NATIVE_FORM_ELEMENTS = [
    'input:not([type=radio]):not([type=checkbox])',
    'select',
    'textarea',
    'output',
];

const CUSTOM_VIRTUAL_FORM_ELEMENTS = [
    'ui-dropdown',
    'ui-slider',
    'ui-monthpicker',
    'ui-monthrange',
    'ui-daterange',
    'ui-timerange',
    'ui-datepicker',
    'ui-upload',
    'ui-stepper',
];

/**
 * Custom input elements.
 * @memberof UiHelpers
 * @type {Array}
 */
const CUSTOM_FORM_ELEMENTS = [
    'ui-autocomplete',
    'ui-textcounter',
    'ui-searchinput',
    'ui-timepicker',
    ...CUSTOM_VIRTUAL_FORM_ELEMENTS,
];

/**
 * @type {PopoverAlignment}
 */
const PopoverAlignment = {
    Top: 'top',
    Middle: 'middle',
    Bottom: 'bottom',
    Left: 'left',
    Right: 'right',
    None: 'none',
};

/**
 * @type {Pick<PopoverAlignment, "Left" | "Right" | "None">}
 */
const PopoverShifting = {
    Left: PopoverAlignment.Left,
    Right: PopoverAlignment.Right,
    None: PopoverAlignment.None,
};

export {
    SVG_ELEMENTS,
    BOOLEAN_ATTRIBUTES,
    PRIMARY_INTERACTIVE_ELEMENTS,
    SECONDARY_INTERACTIVE_ELEMENTS,
    NATIVE_FORM_ELEMENTS,
    CUSTOM_FORM_ELEMENTS,
    CUSTOM_VIRTUAL_FORM_ELEMENTS,
    PopoverAlignment,
    PopoverShifting,
};

/**
 * @typedef {("top" | "middle" | "bottom" | "left" | "right" | "none")} AlignmentValue
 */
/**
 * @typedef {object} PopoverAlignment
 * @property {Extract<AlignmentValue, "top">} Top - top alignment.
 * @property {Extract<AlignmentValue, "middle">} Middle - middle alignment.
 * @property {Extract<AlignmentValue, "bottom">} Bottom - bottom alignment.
 * @property {Extract<AlignmentValue, "left">} Left - left alignment.
 * @property {Extract<AlignmentValue, "right">} Right - right alignment.
 * @property {Extract<AlignmentValue, "none">} None - no alignment, use if you know what
 * you are doing.
 */

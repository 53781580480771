import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import styles from './ui-feedback.css';
import { setData } from '../../global/ui-helpers.js';
import { UIHint } from '../hint/ui-hint.js';
import { UIField } from '../field/ui-field.js';
import '../textcounter/ui-textcounter.js';
import { UIRating } from '../rating/ui-rating.js';
import { UIButtonbar } from '../buttonbar/ui-buttonbar.js';
import { Digest } from '../../global/digest.js';
import { EventObserver } from '../../global/event-observer.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIFeedback
 * @element ui-feedback
 * @classdesc Represents a class for <code>ui-feedback</code> element.
 * User interface for feedback form.
 * @fires event:change
 * @fires event:confirm
 * @property {("default" | "simple")} [layout="default"] {@attr layout} Layout of the feedback.
 *  {@desc default: 1-5 rating layout}
 *  {@desc simple: yes/no layout}
 * @property {("default" | "positive" | "negative")} [extensionLayout]
 * {@attr extension-layout} Feedbacks extension Layout, differs for "simple" feedback.
 * If there is no "extension-layout" - no extension will be rendered.
 *  {@desc default: display extension after any first action}
 *  {@desc positive: display extension only after positive action}
 *  {@desc negative: display extension only after negative action}
 * @property {string} [label] {@attr label} Label for feedback heading.
 * @property {string} [labelMinrating] {@attr label-minrating} Label for minimum
 * label of ui-rating.
 * @property {string} [labelMaxrating] {@attr label-maxrating} Label for maximum
 * label of ui-rating.
 * @property {string} [labelTextarea] {@attr label-textarea} Label for textarea input.
 * @property {string} [labelPositive] {@attr label-positive} Label for simple layout.
 * @property {string} [labelNegative] {@attr label-negative} Label for simple layout.
 * @property {string} [labelHint] {@attr label-hint} Content for ui-hint.
 * @property {string} [labelConfirm] {@attr label-confirm} Label for confirm button.
 * @property {("success" | "error" | null)} [result] {@attr result}
 *  {@desc success}
 *  {@desc error}
 * @property {string} [labelSuccess] {@attr label-success} Label for success.
 * @property {string} [labelCancel] {@attr label-cancel} Label for cancel button.
 * @property {string} [labelError] {@attr label-error} Label for error.
 * @property {string} [labelResult] {@attr label-result} Label for result details.
 * @property {string} [labelCharactersRemaining] {@attr label-characters-remaining} Label for
 * characters remaining.
 * @property {string} [labelLimitValidation] {@attr label-limit-validation} Label for
 * validation error message.
 * @property {string} [labelMessagePlaceholder] {@attr label-message-placeholder} Label for
 * text placeholder
 * @property {string} [labelMissingRating] {@attr label-message-placeholder} Label for
 * missing rating error
 * @property {number} [textMaxlength] {@attr text-maxlength} Textarea counter limit.
 * @property {boolean} [extended=false] {@attr extended} Activate additionally to rating bar
 * - textarea field.
 * @property {boolean} [autoExtended=false] {@attr auto-extended} Display extended layout by default
 * regardless rating activation rule.
 * @property {boolean} [overflowMaxlength=false] {@attr extended} allow to overflow max length
 * of feedback message
 * @property {boolean} [cancelable=false] {@attr cancelable} allow to cancel feedback form
 * @property {HTMLTextAreaElement} extTextarea {@readonly}
 * @property {HTMLButtonElement} submitBtn {@readonly}
 * @property {UIField} ratingBar {@readonly}
 * @property {UIField} extension {@readonly}
 * @property {UIButtonbar} buttonBar {@readonly}
 * @property {UIHint} ratingErrorHint {@readonly}
 * @example
 * <ui-feedback label="How was it?" extended="extended"></ui-feedback>
 */
class UIFeedback extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return [
            'label',
            'label-minrating',
            'label-maxrating',
            'label-textarea',
            'label-hint',
            'label-confirm',
            'result',
            'label-success',
            'label-error',
            'label-result',
            'label-positive',
            'label-negative',
            'label-characters-remaining',
            'label-message-placeholder',
            'label-limit-validation',
            'label-missing-rating',
            'text-maxlength',
            'extended',
            'layout',
            'extension-layout',
            'overflow-maxlength',
        ];
    }

    /**
     * Layouts enum
     * @type {{SIMPLE: string, DEFAULT: string}}
     */
    static get layouts() {
        return {
            SIMPLE: 'simple',
            DEFAULT: 'default',
        };
    }

    /**
     * Extension layouts enum
     * @type {{DEFAULT: string, POSITIVE: string, NEGATIVE: string}}
     */
    static get extLayouts() {
        return {
            DEFAULT: 'default',
            POSITIVE: 'positive',
            NEGATIVE: 'negative',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: String,
                labelMinrating: String,
                labelMaxrating: String,
                labelTextarea: String,
                labelHint: String,
                labelPositive: String,
                labelNegative: String,
                labelConfirm: String,
                result: String,
                labelSuccess: String,
                labelCancel: String,
                labelError: String,
                labelResult: String,
                labelCharactersRemaining: String,
                labelLimitValidation: String,
                labelMessagePlaceholder: String,
                labelMissingRating: String,
                textMaxlength: Number,
                extended: Boolean,
                autoExtended: Boolean,
                cancelable: Boolean,
                overflowMaxlength: Boolean,
                layout: { type: String, default: UIFeedback.layouts.DEFAULT },
                extensionLayout: String,
            },
            children: {
                ratingBar: 'ui-field.ui-feedback__rating',
                extension: 'ui-field.ui-feedback__extension',
                buttonBar: 'ui-buttonbar',
                extTextarea:
                    '.ui-feedback__extension textarea[name="ui-feedback-textarea"]',
                submitBtn: 'button[name="ui-feedback-confirm"]',
                ratingErrorHint: '.ui-feedback__rating-error-hint',
            },
        };
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-feedback', {
            MIN_RATE: 'Difficult',
            MAX_RATE: 'Easy',
            POSITIVE: 'Yes',
            NEGATIVE: 'No',
            CONFIRM: 'Send',
            CANCEL: 'Cancel',
            TEXTAREA_LABEL: 'What can we do to make it even better?',
            HINT_MESSAGE:
                'This feedback is anonymous.' +
                ' We will use it to improve our services.',
            SUCCESS_MESSAGE: 'Thank you for your feedback!',
            ERROR_MESSAGE: 'Feedback sending failed',
            MISSING_RATING_ERROR: 'Please select a rating',
        });
    }

    /**
     * @type {("POSITIVE" | "NEGATIVE")}
     */
    get simpleRating() {
        return this._simpleRating;
    }
    set simpleRating(value) {
        let isExtended;
        this._simpleRating = value;
        this.ratingActivated = true;
        this.dispatchCustomEvent('change', { value: value });

        switch (this.extensionLayout) {
            case UIFeedback.extLayouts.POSITIVE:
                isExtended = value === 'POSITIVE';
                break;
            case UIFeedback.extLayouts.NEGATIVE:
                isExtended = value === 'NEGATIVE';
                break;
            case UIFeedback.extLayouts.DEFAULT:
            default:
                isExtended = !!this.extensionLayout || !!this.extended;
                break;
        }

        if (isExtended) {
            this.rebuildChildren(this.buildSimpleFeedbackElements());
            if (this.extTextarea) {
                this.extTextarea.focus();
            }
        } else {
            this.handleFeedbackConfirm();
        }
    }

    /**
     * @type {string}
     * @readonly
     */
    get rating() {
        switch (this.layout) {
            case UIFeedback.layouts.SIMPLE:
                return this.simpleRating;
            default:
                return this.ratingBar
                    ? this.ratingBar.querySelector('ui-rating').value
                    : null;
        }
    }

    /**
     * Return custom label or empty string if the label disabled or default value
     * @param {string} customValue
     * @param {string | null} defaultValue
     * @returns {string}
     */
    defineLabel(customValue, defaultValue) {
        if (customValue === 'false') {
            return '';
        }
        return customValue || defaultValue;
    }

    /**
     * Build config with elements for extension
     * @returns {Array<IElementConfig>}
     */
    buildExtension() {
        const isTextareaVisible =
            this.layout && (this.extended || this.extensionLayout);
        /**
         * @type {Array<IElementConfig>}
         */
        const configs = [
            {
                element: new UIHint(),
                classList: {
                    'ui-feedback__hint': true,
                },
                children: [
                    this.defineLabel(
                        this.labelHint,
                        UIFeedback.labels.HINT_MESSAGE
                    ),
                ],
            },
            {
                element: new UIButtonbar(),
                children: [
                    this.cancelable && {
                        tagName: 'button',
                        children: [
                            this.defineLabel(
                                this.labelCancel,
                                UIFeedback.labels.CANCEL
                            ),
                        ],
                        attributes: {
                            type: 'button',
                            name: 'ui-feedback-cancel',
                            class: 'button -destructive',
                            'data-event': 'ui-feedback-cancel',
                        },
                    },
                    {
                        tagName: 'button',
                        children: [
                            this.defineLabel(
                                this.labelConfirm,
                                UIFeedback.labels.CONFIRM
                            ),
                        ],
                        attributes: {
                            type: 'button',
                            name: 'ui-feedback-confirm',
                            class: 'button',
                            'data-event': 'ui-feedback-confirm',
                            disabled:
                                (this.extTextarea &&
                                    !this.extTextarea.validity.valid) ||
                                null,
                        },
                    },
                ],
            },
        ];

        if (isTextareaVisible) {
            configs.unshift(this.buildTextareaElement());
        }

        return configs;
    }

    /**
     * Build config for extended element in feedback form
     * @returns {IElementConfig}
     */
    buildTextareaElement() {
        const validationLabel = this.defineLabel(
            this.labelLimitValidation,
            null
        );
        return {
            element: new UIField(),
            classList: {
                'ui-feedback__extension': true,
                '-hidden': !this.ratingActivated && !this.autoExtended,
            },
            attributes: {
                label:
                    this.layout === UIFeedback.layouts.DEFAULT
                        ? this.defineLabel(
                              this.labelTextarea,
                              UIFeedback.labels.TEXTAREA_LABEL
                          )
                        : null,
            },
            children: [
                {
                    tagName: 'ui-textcounter',
                    attributes: {
                        limit: this.textMaxlength,
                        label: this.labelCharactersRemaining,
                        'label-error': validationLabel,
                        overflow:
                            validationLabel || this.overflowMaxlength
                                ? true
                                : null,
                        validate:
                            validationLabel || this.overflowMaxlength
                                ? true
                                : null,
                        nolabel: validationLabel ? true : null,
                    },
                    children: [
                        {
                            tagName: 'textarea',
                            attributes: {
                                id: `ui-feedback-textarea-${Digest.randomId()}`,
                                name: 'ui-feedback-textarea',
                                placeholder:
                                    this.labelMessagePlaceholder || null,
                                'data-event': 'ui-feedback-input',
                                'aria-label':
                                    this.layout === UIFeedback.layouts.SIMPLE
                                        ? this.defineLabel(
                                              this.labelTextarea,
                                              UIFeedback.labels.TEXTAREA_LABEL
                                          )
                                        : null,
                            },
                            children: this.extTextarea?.value
                                ? [this.extTextarea.value]
                                : [],
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Build configs for layout with fields and button for feedback
     * @returns {Array<IElementConfig>}
     */
    buildFeedbackElements() {
        /**
         * @type {Array<IElementConfig>}
         */
        const configs = [
            {
                tagName: 'h3',
                classList: {
                    'ui-feedback__heading': true,
                },
                children: [this.label],
            },
            {
                element: new UIField(),
                classList: {
                    'ui-feedback__rating': true,
                },
                children: [
                    {
                        element: new UIRating(),
                        attributes: {
                            'label-min': this.defineLabel(
                                this.labelMinrating,
                                UIFeedback.labels.MIN_RATE
                            ),
                            'label-max': this.defineLabel(
                                this.labelMaxrating,
                                UIFeedback.labels.MAX_RATE
                            ),
                            'label-legend': this.label,
                        },
                        children: [
                            {
                                tagName: 'input',
                                attributes: {
                                    min: 1,
                                    max: 5,
                                    step: 1,
                                    id:
                                        this.getAttribute('id') ||
                                        Digest.randomId(),
                                    value: this.ratingBar
                                        ? this.ratingBar.querySelector(
                                              'ui-rating'
                                          ).value
                                        : null,
                                    'data-event': 'ui-feedback-rating',
                                },
                            },
                        ],
                    },
                    {
                        tagName: 'ui-hint',
                        attributes: {
                            type: 'error',
                            class: 'ui-feedback__rating-error-hint -hidden',
                        },
                        children: [
                            this.defineLabel(
                                this.labelMissingRating,
                                UIFeedback.labels.MISSING_RATING_ERROR
                            ),
                        ],
                    },
                ],
            },
        ];

        if (this.ratingActivated || this.autoExtended) {
            return configs.concat(this.buildExtension());
        }

        return configs;
    }

    /**
     * Build configs for 'simple' feedback layout
     * @returns {Array<IElementConfig>}
     */
    buildSimpleFeedbackElements() {
        /**
         * @type {Array<IElementConfig>}
         */
        const configs = [
            {
                tagName: 'h3',
                attributes: {
                    class: 'ui-feedback__heading',
                },
                children: [
                    !this.ratingActivated
                        ? this.label
                        : this.defineLabel(
                              this.labelTextarea,
                              UIFeedback.labels.TEXTAREA_LABEL
                          ),
                ],
            },
        ];

        return configs.concat(
            !this.ratingActivated
                ? {
                      element: new UIButtonbar(),
                      children: [
                          {
                              tagName: 'div',
                              attributes: {
                                  class: 'ui-buttonbar__left',
                              },
                              children: [
                                  {
                                      tagName: 'button',
                                      children: [
                                          this.defineLabel(
                                              this.labelPositive,
                                              UIFeedback.labels.POSITIVE
                                          ),
                                      ],
                                      attributes: {
                                          type: 'button',
                                          name: 'ui-feedback-positive',
                                          class: 'button -guiding',
                                          'data-event': 'ui-feedback-action',
                                      },
                                  },
                                  {
                                      tagName: 'button',
                                      children: [
                                          this.defineLabel(
                                              this.labelNegative,
                                              UIFeedback.labels.NEGATIVE
                                          ),
                                      ],
                                      attributes: {
                                          type: 'button',
                                          name: 'ui-feedback-negative',
                                          class: 'button -guiding',
                                          'data-event': 'ui-feedback-action',
                                      },
                                  },
                              ],
                          },
                      ],
                  }
                : this.buildExtension()
        );
    }

    /**
     * Build config for layout with feedback result
     * @returns {Array<IElementConfig>}
     */
    buildResultElements() {
        /**
         * @type {Array<IElementConfig>}
         */
        const configs = [
            {
                tagName: 'h3',
                children: [
                    this.result === 'success' &&
                        this.defineLabel(
                            this.labelSuccess,
                            UIFeedback.labels.SUCCESS_MESSAGE
                        ),
                    this.result === 'error' &&
                        this.defineLabel(
                            this.labelError,
                            UIFeedback.labels.ERROR_MESSAGE
                        ),
                ],
            },
        ];

        return this.labelResult
            ? configs.concat({
                  tagName: 'p',
                  children: [document.createTextNode(this.labelResult)],
              })
            : configs;
    }

    /**
     * Reset feedback to build the first view with empty fields
     */
    reset() {
        this.ratingActivated = false;
        if (this.layout === UIFeedback.layouts.SIMPLE) {
            this._simpleRating = null;
        }

        if (this.result) {
            this.result = null;
            return;
        }

        if (this.ratingBar) {
            this.ratingBar.querySelector('ui-rating').value = null;
            // feedback labels are set in va-chatbox element, handle reset label text there
            this.dispatchCustomEvent('reset');
        }
        if (this.extension) {
            setData(this.extension, { 'ui-feedback-textarea': null });
            // dispatch change event to recheck field validity
            this.extTextarea.dispatchEvent(new Event('change'));
        }

        this.rebuildChildren(this.buildFeedback());
    }

    updateTextCounter() {
        if (this.extTextarea) {
            const counter =
                /** @type UITextCounter */ this.querySelector('ui-textcounter');
            if (counter) {
                counter.updateCounter();
            }
        }
    }

    ensureSubmitValidity() {
        if (this.extTextarea) {
            const isValid = this.extTextarea.validity.valid;

            this.submitBtn.disabled = !isValid;
        }
    }

    /**
     * Handle click on positive/negative button inside simple layout
     * @private
     * @param  {DeclarativeEvent} e
     */
    handleFeedbackAction(e) {
        switch (e.detail.relatedTarget.name) {
            case 'ui-feedback-positive':
                this.simpleRating = 'POSITIVE';
                break;
            case 'ui-feedback-negative':
                this.simpleRating = 'NEGATIVE';
                break;
            default:
                break;
        }
    }

    /**
     * Handle confirm feedback form
     * @private
     */
    handleFeedbackConfirm() {
        if (!this.rating) {
            this.ratingErrorHint.show();
            return;
        }
        if (this.ratingErrorHint) {
            this.ratingErrorHint.hide();
        }
        this.dispatchCustomEvent('confirm', {
            rating: this.rating,
            text: this.extTextarea ? this.extTextarea.value : '',
            id: this.getAttribute('id'),
        });
    }

    /**
     * Handle cancel feedback form
     * @private
     */
    handleFeedbackCancel() {
        this.dispatchCustomEvent('cancel', {
            rating: this.rating,
            text: this.extTextarea ? this.extTextarea.value : '',
            id: this.getAttribute('id'),
        });
    }

    /**
     * Handle rating bar change
     * @private
     */
    handleRatingBarActivated() {
        if (this.ratingActivated) {
            return;
        }
        this.ratingActivated = true;
        this.rebuildChildren(this.buildFeedbackElements());
        if (this.ratingErrorHint) {
            this.ratingErrorHint.hide();
        }
        if (this.extTextarea) {
            this.extTextarea.focus();
        }
    }

    /**
     * Handle textarea input
     * @private
     */
    handleTextareaInput() {
        this.ensureSubmitValidity();
    }

    /**
     * Build config for the feedback
     * @returns {Array<IElementConfig>}
     */
    buildFeedback() {
        if (this.result) {
            return this.buildResultElements();
        }

        switch (this.layout) {
            case UIFeedback.layouts.SIMPLE:
                return this.buildSimpleFeedbackElements();
            default:
                return this.buildFeedbackElements();
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'label':
            case 'label-minrating':
            case 'label-maxrating':
            case 'label-textarea':
            case 'label-hint':
            case 'label-confirm':
            case 'result':
            case 'label-success':
            case 'label-error':
            case 'label-result':
            case 'label-positive':
            case 'label-negative':
            case 'label-message-placeholder':
            case 'label-limit-validation':
            case 'label-missing-rating':
            case 'extended':
            case 'auto-extended':
            case 'extension-layout':
            case 'overflow-maxlength':
                this.rebuildChildren(this.buildFeedback());
                this.updateTextCounter();
                this.ensureSubmitValidity();
                break;
            case 'text-maxlength':
            case 'layout':
                this.reset();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.clickObserver.disconnect();
        this.changeObserver.disconnect();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.clickObserver.observe(this);
        this.changeObserver.observe(this);
    }

    /**
     * @inheritDoc
     */
    render() {
        this.ratingActivated = false;
        this.insertElements(this.buildFeedback());
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        /** @type {EventObserver} */
        this.clickObserver = new EventObserver({ name: 'click' });
        this.changeObserver = new EventObserver({ name: 'change' });
        this.inputObserver = new EventObserver({ name: 'input' });
        this.clickObserver.observe(this);
        this.changeObserver.observe(this);
        this.inputObserver.observe(this);

        this.addEventListener(
            'ui-feedback-action',
            this.handleFeedbackAction.bind(this)
        );
        this.addEventListener(
            'ui-feedback-confirm',
            this.handleFeedbackConfirm.bind(this)
        );
        this.addEventListener(
            'ui-feedback-cancel',
            this.handleFeedbackCancel.bind(this)
        );
        this.addEventListener(
            'ui-feedback-rating',
            this.handleRatingBarActivated.bind(this)
        );
        this.addEventListener(
            'ui-feedback-input',
            this.handleTextareaInput.bind(this)
        );
    }
}

UIFeedback.defineElement('ui-feedback', styles);
export { UIFeedback };

import { mergeAttributes } from '../../global/ui-helpers.js';

/**
 * Create an IElementConfig based on IFieldControl
 * @param {IFieldControl} control
 * @returns {IElementConfig}
 * @private
 */
export function fromControlConfig(control) {
    const data = control.control || {};
    return {
        tagName: 'ui-autocomplete',
        attributes: control.attributes,
        children: [
            {
                tagName: 'input',
                attributes: mergeAttributes(
                    {
                        value: 'value' in control ? control.value : null,
                    },
                    control.control.attributes
                ),
            },
            {
                tagName: 'ui-datalist',
                children:
                    'options' in data &&
                    data.options.map((option) => {
                        const additional = {};
                        if (option.data) {
                            for (const key in option.data) {
                                if (option.data.hasOwnProperty(key)) {
                                    additional['data-' + key] =
                                        option.data[key];
                                }
                            }
                        }
                        return {
                            tagName: 'option',
                            attributes: mergeAttributes(
                                {
                                    value: option.value,
                                    selected:
                                        option.value === data.value
                                            ? 'selected'
                                            : null,
                                },
                                additional
                            ),
                            children: [option.content],
                        };
                    }),
            },
        ],
    };
}

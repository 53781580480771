import { UIElement } from '../ui-element.js';
import styles from './ui-chips.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIChips
 * @element ui-chips
 * @classdesc Represents a class for <code>ui-chips</code> element.
 * Chips are grouped together horizontally and cover the full width of container. Chips are either
 * side-scrollable or stacked on top of each other if they don’t fit inside the container.
 * @property {boolean} [mobileScrollable] {@attr mobile-scrollable}
 * Makes chips side scrollable on mobile.
 * @slot {@type "ui-chip"}
 * @example
 * <ui-chips>
 *   <ui-chip label="Chip"></ui-chip>
 *   <ui-chip label="Chip"></ui-chip>
 * </ui-chips>
 */
class UIChips extends UIElement {
    /**
     * @returns {IProps}
     */
    static get props() {
        return {
            attributes: {
                mobileScrollable: Boolean,
            },
        };
    }

    onScroll(event) {
        const target = event.target;
        const startReached = target.scrollLeft === 0;
        const endReached =
            Math.round(target.scrollLeft + target.offsetWidth) ===
            target.scrollWidth;
        let gradient = '--ui-chips-gradient-middle';
        if (startReached) {
            gradient = '--ui-chips-gradient-start';
        } else if (endReached) {
            gradient = '--ui-chips-gradient-end';
        }
        target.style.setProperty('--ui-chips-gradient', `var(${gradient})`);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.onScroll = this.onScroll.bind(this);
        this.addEventListener('scroll', this.onScroll);
    }
}

UIChips.defineElement('ui-chips', styles);
export { UIChips };

import { UIElement } from '../ui-element.js';
import styles from './ui-column.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIColumn
 * @element ui-column
 * @property {number} span {@attr span} Spans the column, acts as flex value.
 * @classdesc Represents a class for <code>ui-column</code> element.
 * Layout column should be column child of ui-columns.
 * @slot
 * @example
 * <ui-column></ui-column>
 */
class UIColumn extends UIElement {}

UIColumn.defineElement('ui-column', styles);
export { UIColumn };

import { UIElement } from '../ui-element.js';
import { updateElement } from '../../global/ui-helpers.js';
import styles from './ui-step.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIStep
 * @element ui-step
 * @classdesc Represents a class for <code>ui-step</code> element.
 * Draws step.
 * @property {string} label {@attr label} Caption for step.
 * @property {boolean} active {@attr active} Marks step icon as active.
 * @property {boolean} completed {@attr completed} Marks step icon as completed.
 * @property {HTMLDivElement} icon {@readonly}
 * @example
 * <ui-step label="Current step" active="true"></ui-step>
 */
class UIStep extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: String,
                active: Boolean,
                completed: Boolean,
            },
            children: {
                icon: '.ui-step__icon',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['active'];
    }

    /**
     * @type {UISteps}
     * @readonly
     */
    get container() {
        return /** @type {UISteps} */ this.closest('ui-steps');
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateElement({
            attributes: {
                role: 'listitem',
                'aria-current': this.active ? 'step' : null,
            },
            classList: {
                'ui-step__list-item': true,
            },
            children: [
                {
                    tagName: 'div',
                    classList: {
                        'ui-step__icon': true,
                        '-active': this.active,
                        '-passed': this.completed,
                    },
                    children: [
                        {
                            tagName: 'ins',
                        },
                    ],
                },
                {
                    tagName: 'span',
                    classList: {
                        'ui-step__label': true,
                    },
                    children: [this.label],
                },
            ],
        });
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }

        switch (name) {
            case 'active':
                if (this.container) {
                    [].forEach.call(this.container.steps, (s) =>
                        s.removeAttribute('aria-current')
                    );
                }
                updateElement(this, {
                    attributes: {
                        'aria-current': 'step',
                    },
                });
                updateElement(this.icon, {
                    classList: {
                        '-active': this.active,
                    },
                });
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }
}

UIStep.defineElement('ui-step', styles);
export { UIStep };

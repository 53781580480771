/**
 * Create an IElementConfig based on IFieldControl.
 * @param {IFieldControl} control
 * @returns {IElementConfig}
 * @private
 */
export function fromControlConfig(control) {
    return {
        tagName: 'ui-datepicker',
        attributes: control.attributes,
        children: [
            {
                tagName: 'input',
                attributes:
                    control.control && control.control.attributes
                        ? control.control.attributes
                        : {},
            },
        ],
    };
}

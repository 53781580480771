import { UIElement } from '../ui-element.js';
import { UIMessage } from './ui-message.js';
import {
    createElement,
    prerenderElementsTree,
} from '../../global/render-api.js';
import { debounce } from '../../global/helpers.js';
import { browser } from '../../global/browser.js';
import styles from './ui-messagebox.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIMessageBox
 * @element ui-messagebox
 * @property {NodeListOf<UIMessage>} messages {@readonly}
 * @property {boolean} [bubbles] {@attr bubbles} bubbles
 * @classdesc Represents a class for <code>ui-messagebox</code> element.
 * Container for ui-message. Provides API and container to work with
 * ui-message elements.
 * @slot {@type "ui-message"}
 * @example
 * <ui-messagebox>
 *   <ui-message type="error">This is an error</ui-message>
 *   <ui-message type="info">This is an info</ui-message>
 * </ui-messagebox>
 */
class UIMessageBox extends UIElement {
    static get SCROLL_DEBOUNCE_TIME() {
        return 5;
    }

    static get RESIZE_DEBOUNCE_TIME() {
        return 5;
    }

    static get REDRAW_DEBOUNCE_TIME() {
        return 50;
    }

    static get BUBBLE_LIFETIME() {
        return 5000;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                bubbles: Boolean,
            },
            children: {
                messages: {
                    selector: 'ui-message',
                    multiple: true,
                },
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['bubbles'];
    }

    /**
     * Finds message by given type, code and layout params.
     * @param {AlertMessageType} type
     * @param {string} [code]
     * @param {string} [layout]
     * @returns {UIMessage}
     */
    getMessage(type, code, layout) {
        let selector = 'ui-message[type="' + type + '"]';
        if (code) {
            selector += '[code="' + code + '"]';
        }
        if (layout) {
            selector += '[layout="' + layout + '"]';
        }
        return /** @type {UIMessage} */ this.querySelector(selector);
    }

    /**
     * Finds message by type.
     * @param {string} layout
     * @returns {NodeList}
     */
    getMessagesByLayout(layout) {
        return this.querySelectorAll('ui-message[layout="' + layout + '"]');
    }

    /**
     * Finds message by type.
     * @param {AlertMessageType} type
     * @returns {NodeList}
     */
    getMessagesByType(type) {
        return this.querySelectorAll('ui-message[type="' + type + '"]');
    }

    /**
     * Creates <ui-message/> element with given type, layout, code and closable functionality.
     * @param {AlertMessageType} type
     * @param {string} [code]
     * @param {string} [layout]
     * @param {boolean} [closable]
     * @param {boolean} [persistent]
     * @returns {UIMessage}
     */
    createMessage(
        type,
        code = '',
        layout = UIMessage.layouts.DEFAULT,
        closable,
        persistent
    ) {
        const item = createElement({
            tagName: 'ui-message',
            attributes: {
                type,
                code,
                layout,
                closable,
                persistent,
            },
        });
        this.appendChild(item);
        prerenderElementsTree(this, true);
        if (this.bubbles) {
            this.redrawPopoverDebounced();
        }
        return item;
    }

    /**
     * Creates message by given params.
     * @param {AlertMessageType} type
     * @param {string} content
     * @param {string} [code]
     * @param {string} [layout]
     * @param {boolean} [closable]
     * @param {boolean} [persistent]
     * @returns {UIMessageBox}
     */
    addMessage(type, content, code, layout, closable, persistent) {
        const message = this.findOrCreateMessageBlock(
            type,
            code,
            layout,
            closable,
            persistent
        );
        message.showMessage(content);
        if (this.bubbles) {
            this.redrawPopoverDebounced();
        }
        return this;
    }

    /**
     * Creates messages from given array, message with same type and code will be merged.
     * @param {Array<IMessageConfig>} messages
     * @param {boolean} [closable]
     * @param {boolean} [persistent]
     * @returns {UIMessageBox}
     */
    addMessages(messages, closable, persistent) {
        const mergedMessages = messages.reduce((data, message) => {
            const key = [message.layout, message.type, message.code || ''].join(
                '-'
            );
            if (!data[key]) {
                data[key] = {
                    layout: message.layout || UIMessage.layouts.DEFAULT,
                    type: message.type,
                    messages: [message.content],
                    closable: message.closable,
                    code: message.code,
                    persistent: message.persistent,
                };
            } else {
                data[key].messages.push(message.content);
            }
            return data;
        }, {});

        Object.keys(mergedMessages).forEach((key) => {
            const item = mergedMessages[key];
            let message = this.getMessage(item.type, item.code, item.layout);
            if (!message) {
                message = this.createMessage(
                    item.type,
                    item.code,
                    item.layout,
                    closable !== undefined ? closable : item.closable,
                    persistent !== undefined ? persistent : item.persistent
                );
            }
            message.showMessage(item.messages.join('\n'));
        });
        return this;
    }

    /**
     * Shortcut for addMessage with predefined "success" type.
     * @param {string} content
     * @param {string} [code]
     * @returns {UIMessageBox}
     */
    addSuccess(content, code) {
        return this.addMessage('success', content, code);
    }

    /**
     * Shortcut for addMessage with predefined "error" type.
     * @param {string} content
     * @param {string} [code]
     * @param {string} [layout]
     * @returns {UIMessageBox}
     */
    addError(content, code, layout) {
        return this.addMessage('error', content, code, layout);
    }

    /**
     * Shortcut for addMessage with predefined "info" type.
     * @param {string} content
     * @param {string} [code]
     * @returns {UIMessageBox}
     */
    addInfoMessage(content, code) {
        return this.addMessage('info', content, code);
    }

    /**
     * Shortcut for addMessage with predefined "warning" type.
     * @param {string} content
     * @param {string} [code]
     * @param {string} [layout]
     * @returns {UIMessageBox}
     */
    addWarning(content, code, layout) {
        return this.addMessage('warning', content, code, layout);
    }

    /**
     * Finds message block by given type and code.
     * Creates one if not found
     * @param {AlertMessageType} type
     * @param {string} [code]
     * @param {string} [layout]
     * @param {boolean} [closable]
     * @param {boolean} [persistent]
     * @returns {UIMessage}
     */
    findOrCreateMessageBlock(type, code, layout, closable, persistent) {
        let message = this.getMessage(type, code, layout);
        if (!message) {
            message = this.createMessage(
                type,
                code,
                layout,
                closable,
                persistent
            );
        }
        return message;
    }

    /**
     * Removes all messages by given layout.
     * @param {string} layout
     * @returns {UIMessageBox}
     */
    removeMessagesByLayout(layout) {
        [].forEach.call(this.getMessagesByLayout(layout), (message) =>
            message.clear()
        );
        if (this.bubbles) {
            this.redrawPopoverDebounced();
        }
        return this;
    }

    /**
     * Removes all messages by given type.
     * @param {AlertMessageType} type
     * @returns {UIMessageBox}
     */
    removeMessagesByType(type) {
        [].forEach.call(this.getMessagesByType(type), (message) =>
            message.clear()
        );
        if (this.bubbles) {
            this.redrawPopoverDebounced();
        }
        return this;
    }

    /**
     * Shortcut for removeMessagesByType with predefined type - "info".
     * @returns {UIMessageBox}
     */
    removeInfoMessages() {
        return this.removeMessagesByType('info');
    }

    /**
     * Adds shortcut for removeMessagesByType with predefined type - "success"
     * @returns {UIMessageBox}
     */
    removeSuccessMessages() {
        return this.removeMessagesByType('success');
    }

    /**
     * Shortcut for removeMessagesByType with predefined type - "error".
     * @returns {UIMessageBox}
     */
    removeErrors() {
        return this.removeMessagesByType('error');
    }

    /**
     * Shortcut for removeMessagesByType with predefined type - "warning".
     * @returns {UIMessageBox}
     */
    removeWarnings() {
        return this.removeMessagesByType('warning');
    }

    /**
     * Removes the message by given type, layout and code.
     * @param {AlertMessageType} type
     * @param {string} [code]
     * @param {string} [layout]
     * @returns {UIMessageBox}
     */
    removeMessage(type, code, layout) {
        const message = this.getMessage(type, code, layout);
        if (!message) {
            return this;
        }
        message.clear();
        if (this.bubbles) {
            this.redrawPopoverDebounced();
        }
        return this;
    }

    /**
     * Adds shortcut for removeMessage with predefined type - "success"
     * @param {string} code
     * @returns {UIMessageBox}
     */
    removeSuccess(code) {
        return this.removeMessage('success', code);
    }

    /**
     * Shortcut for removeMessage with predefined type - "error".
     * @param {string} code
     * @returns {UIMessageBox}
     */
    removeError(code) {
        return this.removeMessage('error', code);
    }

    /**
     * Shortcut for removeMessage with predefined type - "info".
     * @param {string} code
     * @returns {UIMessageBox}
     */
    removeInfoMessage(code) {
        return this.removeMessage('info', code);
    }

    /**
     * Shortcut for removeMessage with predefined type - "warning".
     * @param {string} code
     * @returns {UIMessageBox}
     */
    removeWarning(code) {
        return this.removeMessage('warning', code);
    }

    /**
     * Adds shortcut for remove all messages.
     * @returns {UIMessageBox}
     */
    removeAllMessages() {
        this.removeSuccessMessages();
        this.removeInfoMessages();
        this.removeWarnings();
        this.removeErrors();
        return this;
    }

    /**
     * Closes detached popover.
     */
    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        if (!this.popoverElement) {
            return;
        }
        const modal = this.popoverElement.closest('ui-modal');
        modal?.close();

        this.popoverElement = null;
        this._detachedPopover = null;
    }
    /**
     * Opens detached popover.
     * @fires event:modal-open
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }
        this._detachedPopover = this.popoverElement;
        this.dispatchCustomEvent('modal-open', {
            type: 'bubble',
            content: this.popoverElement,
            params: {
                classList: {
                    '-messagebox': true,
                },
                attributes: {
                    closable: true,
                },
            },
        });
    }

    displayInPopover() {
        const nodeList = [].map.call(this.messages, (entry) => {
            return entry.cloneNode(true);
        });
        if (nodeList.length) {
            if (!this.popoverElement) {
                this.popoverElement = this.createElement({
                    tagName: 'div',
                    classList: {
                        'ui-messagebox__popover': true,
                    },
                });
                this.openDetachedPopover();
                this.subscribeIntersection();
            }
            this.closePopoverDebounced();
            this.rebuildChildren.call(this.popoverElement, nodeList);
        } else {
            if (this.popoverElement) {
                this.closeDetachedPopover();
                this.unsubscribeIntersection();
            }
        }
    }

    unsubscribeIntersection() {
        if (browser.supportsIntersectionObserver()) {
            if (this.observer) {
                this.observer.disconnect();
            }
        } else {
            document.removeEventListener('scroll', this.handleDocumentScroll);
            document.removeEventListener('resize', this.handleDocumentResize);
        }
    }

    subscribeIntersection() {
        if (browser.supportsIntersectionObserver()) {
            this.observer = new IntersectionObserver(
                (entries) => {
                    [].forEach.call(entries, (entry) => {
                        if (entry.isIntersecting && this.popoverElement) {
                            this.closeDetachedPopover();
                            this.unsubscribeIntersection();
                        }
                    });
                },
                { root: null, rootMargin: '0px' }
            );
            this.observer.observe(this);
        } else {
            document.addEventListener('scroll', this.handleDocumentScroll);
            document.addEventListener('resize', this.handleDocumentResize);
        }
    }

    redrawPopover() {
        if (!this.isIntersected && this.bubbles) {
            this.displayInPopover();
        }
    }

    checkIntersection() {
        if (this.isIntersected && this.popoverElement) {
            this.closeDetachedPopover();
            this.unsubscribeIntersection();
        }
    }

    observeIntersection() {
        if (this.bubbles && !this.intersectionObserver) {
            this.intersectionObserver = new IntersectionObserver(
                (entries) => {
                    const entry = entries[0];
                    this.isIntersected = entry.isIntersecting;
                },
                { root: null }
            );
            this.intersectionObserver.observe(this);
        } else if (!this.bubbles && this.intersectionObserver) {
            this.intersectionObserver.disconnect();
            this.intersectionObserver = null;
        }
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.closeDetachedPopover();
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'group');
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleDocumentScroll = debounce(
            this.checkIntersection.bind(this),
            UIMessageBox.SCROLL_DEBOUNCE_TIME
        );
        this.handleDocumentResize = debounce(
            this.checkIntersection.bind(this),
            UIMessageBox.RESIZE_DEBOUNCE_TIME
        );
        this.redrawPopoverDebounced = debounce(
            this.redrawPopover.bind(this),
            UIMessageBox.REDRAW_DEBOUNCE_TIME
        );
        this.closePopoverDebounced = debounce(
            this.closeDetachedPopover.bind(this),
            UIMessageBox.BUBBLE_LIFETIME
        );
        this.observeIntersection();
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }

        switch (name) {
            case 'bubbles':
                this.observeIntersection();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }
}

UIMessageBox.defineElement('ui-messagebox', styles);
export { UIMessageBox };

import { UIElement } from '../ui-element.js';
import styles from './ui-option-multipayment.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionMultipayment
 * @element ui-option-multipayment
 * @classdesc Represents a class for <code>ui-option-account</code> element.
 * Similar to ui-option, but holds multibanking account data. Used with combination
 * with ui-dropdown.
 * @property {boolean} optgroup {@attr optgroup}
 * @property {boolean} control {@attr control} Flag to distinguish the location
 * where option will be shown.
 * @property {string} [accountid] {@attr accountid} Account ID.
 * @property {string} [accountnumber] {@attr accountnumber} Account number.
 * @property {string} [holder] {@attr holder} Name of the account holder/owner.
 * @property {string} [amount] {@attr amount} Amount of available money in the account.
 * @property {string} [currency="EUR"] {@attr currency} Currency of the option.
 * @property {string} [bankname] {@attr bankname} Name of the bank.
 * @property {string} [secondarytext] {@attr secondarytext} Secondary text inside placeholder.
 * @property {("orange" | "turquoise" | "lilac" | "red" | "green" | "blue")} [secondarycolor]
 * {@attr secondarycolor} Color for secondary text:
 *  {@desc orange}
 *  {@desc turquoise}
 *  {@desc lilac}
 *  {@desc red}
 *  {@desc green}
 *  {@desc blue}
 * @property {string} [tertiarytext] {@attr tertiarytext} Tertiary text inside placeholder.
 * @property {("orange" | "turquoise" | "lilac" | "red" | "green" | "blue")} [tertiarycolor]
 * {@attr tertiarycolor} Color for tertiary text
 *  {@desc orange}
 *  {@desc turquoise}
 *  {@desc lilac}
 *  {@desc red}
 *  {@desc green}
 *  {@desc blue}
 * @slot
 * @example
 * <ui-dropdown layout="multipayment">
 *  <select>
 *   <option data-holder="Tiina Puu" data-account-number="EE111149490" data-account-id="1">
 *   <option data-holder="Mari Muul" data-account-number="EE222249450" data-account-id="2">
 *  </select>
 * </ui-dropdown>
 */
class UIOptionMultipayment extends UIElement {
    static get POPOVER_TYPE() {
        return 'basic';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                optgroup: Boolean,
                accountnumber: String,
                accountid: String,
                holder: String,
                bankname: String,
                secondarytext: String,
                secondarycolor: String,
                tertiarytext: String,
                tertiarycolor: String,
            },
        };
    }

    /**
     * Finds match between attributes' values and given text.
     * @param {HTMLOptionElement} option
     * @param {string} text
     * @returns {boolean}
     */
    findMatch(option, text) {
        const strings = [
            option.innerText,
            option.getAttribute('value'),
            option.getAttribute('data-holder'),
            option.getAttribute('data-account-number'),
            option.getAttribute('data-bank-name'),
        ];
        return (
            strings.filter((str) => {
                return (
                    str && str.toLowerCase().indexOf(text.toLowerCase()) > -1
                );
            }).length > 0
        );
    }

    /**
     * Gets data of the multipayment as object.
     * @returns {{holder: string, bankname: string, accountnumber: string}}
     */
    getData() {
        return {
            id: this.accountid,
            holder: this.holder,
            bankname: this.bankname,
            accountnumber: this.accountnumber,
        };
    }

    /**
     * Gets the value of the account number.
     * @returns {string}
     */
    getValue() {
        return this.accountnumber;
    }

    /**
     * Gets the value of the holder.
     * @returns {string}
     */
    getText() {
        return this.holder;
    }

    /**
     * Build placeholder with selected color.
     * @param {string} color
     * @param {string} text
     * @returns {IElementConfig}
     * @private
     */
    buildPlaceholder(color, text) {
        return {
            tagName: 'span',
            classList: {
                [`-${color}`]: !!color,
            },
            children: [document.createTextNode(' ' + text)],
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateClassList({
            '-control': this.control,
        });

        this.insertElements(
            this.optgroup
                ? [this.getAttribute('text')]
                : [
                      {
                          tagName: 'span',
                          attributes: {
                              class: 'ui-option-multipayment__number',
                          },
                          children: [this.accountnumber || ''],
                      },
                      {
                          tagName: 'span',
                          attributes: {
                              class: 'ui-option-multipayment__info',
                          },
                          children: [
                              this.holder ? this.holder : '',
                              this.holder && this.bankname ? ' - ' : '',
                              this.bankname ? this.bankname : '',
                              this.secondarytext &&
                                  this.buildPlaceholder(
                                      this.secondarycolor,
                                      this.secondarytext
                                  ),
                              this.tertiarytext &&
                                  this.buildPlaceholder(
                                      this.tertiarycolor,
                                      this.tertiarytext
                                  ),
                          ],
                      },
                  ]
        );

        if (this.control) {
            this.insertElements([
                {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'dropdown',
                    },
                },
            ]);
        }
    }
}

UIOptionMultipayment.defineElement('ui-option-multipayment', styles);
export { UIOptionMultipayment };

import { UIElement } from '../ui-element.js';
import styles from './ui-assets.css';
import { element } from '../../global/template-literals.js';
import { debounce } from '../../global/helpers.js';
import { Digest } from '../../global/index.js';
import { TabIndex } from '../../global/keyboard.js';
import { Labels } from '../../global/labels.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAssets
 * @element ui-assets
 * @classdesc Represents a class for <code>ui-assets</code> element.
 * Container for ui-asset. Can have only UIAsset children.
 * @property {string} [labelMore="Show more"] {@attr label-more} Content toggle button label
 * when content is collapsed.
 * @property {string} [labelLess="Show less"] {@attr label-less} Content toggle button label
 * when content is expanded.
 * @property {number} [visibleAssets=5] {@attr visible-assets} How many assets
 * will stay visible when the list is collapsed.
 * @property {boolean} [notoggle=false] {@attr notoggle} Will disable the content toggle.
 * @property {boolean} [expanded=false] {@attr expanded} If list will
 * be initially rendered expanded.
 * @property {HTMLDivElement} contentToggleElement {@readonly} Content toggle container element.
 * @property {HTMLDivElement} contentToggleCtaElement {@readonly} Content toggle button element.
 * @property {HTMLDivElement} contentToggleCtaIconElement {@readonly} Content toggle button
 * icon element.
 * @property {HTMLDivElement} contentToggleCtaLabelElement {@readonly} Content toggle button
 * label element.
 * @property {HTMLDivElement} contentToggleDescriptionElement {@readonly} Content toggle button
 * description element.
 * @property {NodeList<UIAsset>} assets {@readonly} All the child asset elements.
 * @slot {@type "ui-asset"}
 * @example
 * <ui-assets>
 *   <ui-asset
 *     label="Main account"
 *     label-secondary="EE345678909870901345"
 *   >
 *     <ui-badge slot="badge" layout="pin" color="pineapple">Default</ui-badge>
 *     <span slot="value"><ui-currency value="1200.48"></ui-currency> EUR</span>
 *   </ui-asset>
 *   <ui-asset
 *     label="Randy Lahey"
 *     label-secondary="EE323601345251978909"
 *     value-secondary="+2 currencies"
 *   >
 *     <span slot="value"><ui-currency value="12870.72"></ui-currency> EUR</span>
 *   </ui-asset>
 *   <ui-asset
 *     label="Credit card account"
 *     label-secondary="EE319781678251782328"
 *   >
 *     <span slot="value"><ui-currency value="900.35"></ui-currency> EUR</span>
 *   </ui-asset>
 *   <ui-asset
 *     label="Easy saver account"
 *     label-secondary="EE320783348252214302"
 *   >
 *     <span slot="value"><ui-currency value="500.40"></ui-currency> EUR</span>
 *   </ui-asset>
 *   <ui-asset
 *     label="Investment account"
 *     label-secondary="LHV | EE344981345133277795"
 *   >
 *     <span slot="value"><ui-currency value="200.42"></ui-currency> EUR</span>
 *   </ui-asset>
 *   <ui-asset
 *     label="Child’s account"
 *     label-secondary="EE315675538251764701"
 *   >
 *     <span slot="value"><ui-currency value="1000"></ui-currency> EUR</span>
 *   </ui-asset>
 * </ui-assets>
 */
class UIAssets extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                labelMore: String,
                labelLess: String,
                visibleAssets: { type: Number, default: 5 },
                notoggle: Boolean,
                expanded: Boolean,
            },
            children: {
                contentToggleElement: '.ui-assets__content-toggle',
                contentToggleCtaElement: '.ui-assets__content-toggle-cta',
                contentToggleCtaIconElement:
                    '.ui-assets__content-toggle-cta-icon',
                contentToggleCtaLabelElement:
                    '.ui-assets__content-toggle-cta-label',
                contentToggleDescriptionElement:
                    '.ui-assets__content-toggle-description',
                assets: {
                    selector: 'ui-asset',
                    multiple: true,
                },
            },
        };
    }

    /**
     * @type {string}
     * @private
     */
    get id() {
        return this._id || (this._id = Digest.randomId());
    }

    /**
     * @type {string}
     * @private
     */
    get descriptionId() {
        return this._descriptionId || (this._descriptionId = Digest.randomId());
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-assets', {
            labelMore: 'Show more',
            labelLess: 'Show less',
            labelToggleContent:
                'Currently {visibleAssets} of {allAssets} list items visible.',
        });
    }

    /**
     * @type {string}
     * @private
     */
    get toggleLabel() {
        return this.expanded
            ? this.labelLess || this.getLabel('labelLess')
            : this.labelMore || this.getLabel('labelMore');
    }

    /**
     * @type {string}
     * @private
     */
    get currentlyVisibleAssets() {
        return `${this.expanded ? this.assets.length : this.visibleAssets}`;
    }

    /**
     * @type {boolean}
     * @private
     */
    get hasContentToggle() {
        if (this._hasContentToggle === undefined) {
            this._hasContentToggle =
                !this.notoggle && this.assets.length > this.visibleAssets;
        }

        return this._hasContentToggle;
    }

    /**
     * Sets animation and accessibility attributes necessary for expanding and collapsing content.
     * @private
     */
    adjustContentToggleStyles() {
        this.style.setProperty(
            '--ui-assets-height',
            `${this.expanded ? this.fullHeight : this.minimizedHeight}px`
        );
        this.style.setProperty(
            '--ui-assets-cta-icon-angle',
            `${this.expanded ? 180 : 0}deg`
        );
        this.contentToggleCtaElement.setAttribute(
            'aria-expanded',
            `${this.expanded}`
        );
        this.contentToggleCtaLabelElement.innerText = this.toggleLabel;
        this.contentToggleDescriptionElement.innerText = this.getLabel(
            'labelToggleContent',
            {
                visibleAssets: this.currentlyVisibleAssets,
                allAssets: `${this.assets.length}`,
            }
        );

        const tabIndex = `${
            this.expanded ? TabIndex.Active : TabIndex.Inactive
        }`;
        for (
            let index = this.visibleAssets;
            index < this.assets.length;
            index++
        ) {
            this.assets[index].contentElement.setAttribute(
                'aria-hidden',
                `${!this.expanded}`
            );
            this.assets[index].contentElement.setAttribute(
                'tabindex',
                tabIndex
            );
        }
    }

    /**
     * Handles content expand/collapse.
     * @private
     */
    handleContentToggle() {
        this.expanded = !this.expanded;
        this.adjustContentToggleStyles();
    }

    /**
     * Handles browser resize.
     * @private
     */
    handleResize() {
        this.minimizedHeight =
            this.contentToggleElement.getBoundingClientRect().height;
        for (let index = 0; index < this.visibleAssets; index++) {
            this.minimizedHeight +=
                this.assets[index].getBoundingClientRect().height;
        }

        this.fullHeight = this.minimizedHeight;
        for (
            let index = this.visibleAssets;
            index < this.assets.length;
            index++
        ) {
            this.fullHeight +=
                this.assets[index].getBoundingClientRect().height;
        }

        this.classList.add('ui-assets__transitions-disabled');
        this.adjustContentToggleStyles();
        window.requestAnimationFrame(() => {
            this.classList.remove('ui-assets__transitions-disabled');
        });
    }

    /**
     * Util for connecting event handlers.
     * @private
     */
    addEventListeners() {
        this.contentToggleCtaElement.addEventListener(
            'click',
            this.contentToggleHandler
        );
        window.addEventListener('resize', this.resizeHandler);
    }

    /**
     * Util for disconnecting event handlers.
     * @private
     */
    removeEventListeners() {
        this.contentToggleCtaElement.removeEventListener(
            'click',
            this.contentToggleHandler
        );
        window.removeEventListener('resize', this.resizeHandler);
    }

    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'list');
        this.assets.forEach((asset) => {
            asset.setAttribute('role', 'listitem');
        });

        if (this.hasContentToggle) {
            this.setAttribute('id', this.id);
            this.append(
                element`
          <div class="ui-assets__content-toggle">
            <button
              class="ui-assets__content-toggle-cta"
              aria-controls="${this.id}"
              aria-expanded="${this.expanded}" 
              aria-describedby="${this.descriptionId}"
            >
              <ui-icon class="ui-assets__content-toggle-cta-icon" glyph="down"></ui-icon>
              <span class="ui-assets__content-toggle-cta-label">
                ${this.toggleLabel}
              </span>
            </button>
          </div>`,
                element`
          <div
            id="${this.descriptionId}"
            class="ui-assets__content-toggle-description"
            hidden
          >
            ${this.getLabel('labelToggleContent', {
                visibleAssets: this.currentlyVisibleAssets,
                allAssets: `${this.assets.length}`,
            })}
          </div>`
            );
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (this.hasContentToggle) {
            const resizeHandler = this.handleResize.bind(this);
            this.resizeHandler = debounce(resizeHandler, 200);
            this.contentToggleHandler = this.handleContentToggle.bind(this);
            this.addEventListeners();
            window.requestAnimationFrame(resizeHandler);
        } else {
            this.reconnect = () => {};
            this.disconnect = () => {};
        }
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.removeEventListeners();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.addEventListeners();
    }
}

UIAssets.defineElement('ui-assets', styles);
export { UIAssets };

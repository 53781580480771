import { UIElement } from '../ui-element.js';
import styles from './ui-layout.css';
import { browser } from '../../global/browser.js';
import { compareNumbersAsStrings } from '../../global/comparators.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UILayout
 * @element ui-layout
 * @classdesc Represents a class for <code>ui-layout</code> element.
 * @property {("two-column" | "box")} [type] {@attr type} Type of the layout.
 *  {@desc two-column: has left and right columns}
 *  {@desc box: has vertically and horizontally centered layout}
 * @property {UILayoutContent} content {@readonly} Shortcut to UILayoutContent child element.
 * @property {UILayoutSidebar} sidebar {@readonly} Shortcut to UILayoutSidebar child element.
 * @example
 * <ui-layout>
 *     <ui-layout-head>
 *         <h1>Page title</h1>
 *     </ui-layout-head>
 *     <ui-layout-content>
 *         <section class="frame">Content One</section>
 *         <section class="frame">Content Two</section>
 *         <section class="frame">Content Three</section>
 *     </ui-layout-content>
 *     <ui-layout-sidebar>
 *         <section class="frame">Sidebar One</section>
 *         <section class="frame">Sidebar Two</section>
 *     </ui-layout-sidebar>
 * </ui-layout>
 */
class UILayout extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                type: String,
            },
            children: {
                content: 'ui-layout-content',
                sidebar: 'ui-layout-sidebar',
            },
        };
    }

    /**
     * Type of layouts.
     * @type {{Default: 'default', TwoColumn: 'two-column', Box: 'box'}}
     */
    static get Type() {
        return {
            Default: 'default',
            TwoColumn: 'two-column',
            Box: 'box',
        };
    }

    /**
     * @type {Array<HTMLElement>}
     * @private
     */
    get sidebarNodes() {
        return this._sidebarNodes;
    }

    set sidebarNodes(nodes) {
        this._sidebarNodes = nodes;
    }

    /**
     * @type {Array<HTMLElement>}
     * @private
     */
    get sortedSidebarNodes() {
        return this._sortedSidebarNodes;
    }

    set sortedSidebarNodes(nodes) {
        this._sortedSidebarNodes = nodes;
    }

    /**
     * Used to set mobile layout when type is two column.
     * The logic was created with JS. Doing it with CSS flexbox / grid can create a disconnect
     * between the DOM order and visual presentation of content, causing keyboard navigation to break,
     * which affects accessibility.
     */
    setMobileLayout() {
        if (this.type !== this.constructor.Type.TwoColumn) {
            return;
        }

        this.sortedSidebarNodes.forEach((sidebarNode) => {
            const order = Number(sidebarNode.dataset.order);
            if (order) {
                this.content.insertBefore(
                    sidebarNode,
                    this.content.children[order - 1]
                );
            }
        });
    }

    /**
     * Used to set desktop layout when type is two column.
     * The logic was created with JS. Doing it with CSS flexbox / grid can create a disconnect
     * between the DOM order and visual presentation of content, causing keyboard navigation to break,
     * which affects accessibility.
     */
    setDesktopLayout() {
        if (this.type !== this.constructor.Type.TwoColumn) {
            return;
        }

        this.sidebar.append(...this.sidebarNodes);
    }

    /**
     * Render the layout by the type of screen. Used to handle two-column layout.
     * @param {boolean} isDesktop
     */
    renderByViewport(isDesktop) {
        isDesktop ? this.setDesktopLayout() : this.setMobileLayout();
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!this.sidebar) {
            return;
        }

        this.type = this.constructor.Type.TwoColumn;
        this.sidebarNodes = [...this.sidebar.children];
        this.sortedSidebarNodes = [...this.sidebarNodes].sort((a, b) =>
            compareNumbersAsStrings(a.dataset.order, b.dataset.order)
        );
        !browser.desktopMediaMatches() && this.renderByViewport(false);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (this.sidebarNodes) {
            // On resize
            browser.desktopMedia.addListener((event) => {
                this.renderByViewport(event.matches);
            });
        }
    }
}

UILayout.defineElement('ui-layout', styles);
export { UILayout };

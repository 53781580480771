import { UIElement } from '../ui-element.js';
import { eventBus } from '../../global/event-bus.js';
import { Labels } from '../../global/labels.js';
import { setInnerText, updateElement } from '../../global/ui-helpers.js';
import { UIBadge } from '../badge/ui-badge.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-dialogbubble.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIDialogBubble
 * @element ui-dialogbubble
 * @classdesc Represents a class for <code>ui-dialogbubble</code> element.
 * @property {string} badge {@attr badge} Text inside badge.
 * @property {string} label {@attr label} Label for button (accessibility).
 * @property {"on"|"off"} [standalone="on"] {@attr standalone} Use the component in standalone mode.
 * @property {HTMLSpanElement} badgeElem {@readonly} Shortcut to badge element.
 * @fires event:dialog-close
 * @fires event:dialog-open
 * @listens event:dialogbubble-show
 * @listens event:dialogbubble-hide
 * @listens event:dialogbubble-newmessages
 * @example
 * <ui-dialogbubble></ui-dialogbubble>
 */
class UIDialogBubble extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['badge'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                standalone: { type: String, default: 'on' },
                badge: String,
                label: String,
            },
            children: {
                badgeElem: 'ui-badge',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-dialogbubble', {
            open: 'Start chat',
        });
    }

    isStandalone() {
        return this.standalone !== 'off';
    }

    /**
     * Resolve possible count value
     * @param {string} value
     * @returns {string|null|*}
     */
    getNotificationsCount(value) {
        return value > 9 ? '9+' : value <= 0 ? null : value;
    }

    /**
     * Handle notification changes
     * @param {CustomEvent} event
     * @private
     */
    handleNewMessagesChange(event) {
        this.setAttributes({
            badge:
                event.detail && event.detail.count ? event.detail.count : null,
        });
    }

    /**
     * Show dialog bubble handle.
     * @private
     */
    handleShowAction() {
        this.show();
        if (this.isStandalone()) {
            this.dispatchCustomEvent('dialog-close', null);
        }
    }

    /**
     * Hide dialog bubble handle.
     * @private
     */
    handleHideAction() {
        this.hide();
    }

    /**
     * Handle click action to hide bubble and dispatch.
     * @private
     */
    handleClickAction() {
        this.hide();
        if (this.isStandalone()) {
            this.dispatchCustomEvent('dialog-open', null);
        }
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        if (!this.isStandalone()) {
            return;
        }
        eventBus.removeEventListener(
            'dialogbubble-show',
            this.handleShowAction
        );
        eventBus.removeEventListener(
            'dialogbubble-hide',
            this.handleHideAction
        );
        eventBus.removeEventListener(
            'dialogbubble-newmessages',
            this.handleNewMessagesChange
        );
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        if (!this.isStandalone()) {
            return;
        }
        eventBus.addEventListener('dialogbubble-show', this.handleShowAction);
        eventBus.addEventListener('dialogbubble-hide', this.handleHideAction);
        eventBus.addEventListener(
            'dialogbubble-newmessages',
            this.handleNewMessagesChange
        );
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        const count = this.getNotificationsCount(newValue);
        switch (name) {
            case 'badge':
                this.setAttributes({ badge: count || 0 });
                if (this.badgeElem) {
                    setInnerText(this.badgeElem, count);
                }
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateElement({
            children: [
                {
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                        'aria-haspopup': 'true',
                        'aria-label': this.label || UIDialogBubble.labels.open,
                    },
                    children: [
                        {
                            tagName: 'ui-icon',
                            attributes: {
                                glyph: 'dialog',
                                bgcolor: UIIcon.colors.TURQUOISE,
                            },
                        },
                    ],
                },
                {
                    tagName: 'ui-badge',
                    attributes: {
                        role: 'status',
                        'aria-live': 'polite',
                        color: BrandColors.Pineapple,
                        layout: UIBadge.Layout.Pin,
                    },
                    children: [this.getNotificationsCount(this.badge)],
                },
            ],
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (!this.isStandalone()) {
            return;
        }
        this.handleShowAction = this.handleShowAction.bind(this);
        this.handleHideAction = this.handleHideAction.bind(this);
        this.handleClickAction = this.handleClickAction.bind(this);
        this.handleNewMessagesChange = this.handleNewMessagesChange.bind(this);

        updateElement(this.querySelector('button'), {
            events: {
                click: this.handleClickAction,
            },
        });

        eventBus.addEventListener('dialogbubble-show', this.handleShowAction);
        eventBus.addEventListener('dialogbubble-hide', this.handleHideAction);
        eventBus.addEventListener(
            'dialogbubble-newmessages',
            this.handleNewMessagesChange
        );
    }
}

UIDialogBubble.defineElement('ui-dialogbubble', styles);
export { UIDialogBubble };

/**
 * Branding colors for the mostly marketing elements.
 * @type {UIBrandColors}
 */
const BrandColors = {
    Turquoise: 'turquoise',
    Lilac: 'lilac',
    Pineapple: 'pineapple',
    Orange: 'orange',
    Bark_70: 'bark-70',
    Bark: 'bark' /* should deprecate */,
};

/**
 * Checks that given color is brand color.
 * @param {string|UIBrandColor} color
 * @returns {boolean}
 */
const isBrandColor = (color) => Object.values(BrandColors).includes(color);

export { BrandColors, isBrandColor };

/**
 * @typedef {("turquoise" | "lilac" | "pineapple" | "orange" | "bark-70" | "bark")} UIBrandColor
 */
/**
 * @typedef {object} UIBrandColors
 * @property {Extract<UIBrandColor, 'lilac'>} Lilac - Lilac color
 * @property {Extract<UIBrandColor, 'pineapple'>} Pineapple - Pineapple color
 * @property {Extract<UIBrandColor, 'turquoise'>} Turquoise - Turquoise color
 * @property {Extract<UIBrandColor, 'orange'>} Orange - Orange color
 * @property {Extract<UIBrandColor, 'bark-70'>} Bark_70 - Bark_70 color
 * @property {Extract<UIBrandColor, 'bark'>} Bark - Bark color (deprecated)
 */

import { UIElement } from '../ui-element.js';
import { UIIcon } from '../icon/ui-icon.js';
import { Labels } from '../../global/labels.js';
import { createElement } from '../../global/render-api.js';
import { browser } from '../../global/browser.js';
import { doesClickedInsideRect } from '../../global/ui-helpers.js';
import styles from './ui-dialog.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @implements {IActivatable}
 * @alias UIDialog
 * @element ui-dialog
 * @classdesc Represents a class for <code>ui-dialog</code> element.
 * This is an experimental component which uses native browsers <code>&lt;dialog></code>
 * tag.
 * @fires event:dialog-open
 * @fires event:dialog-open-enter
 * @fires event:dialog-close
 * @fires event:dialog-close-enter
 * @property {boolean} nonclosable {@attr nonclosable} Set that dialog cannot be closed by
 * user interface interaction.
 * @property {boolean} opened {@attr opened} Sets the dialog open.
 * @property {boolean} compact {@attr compact} Shows smaller dialog.
 * @property {string} returnValue Return value when dialog is close with form's method="dialog".
 * @property {HTMLDialogElement} dialog Shortcut to dialog child element.
 * @property {HTMLButtonElement} closeButton Shortcut to close button element.
 * @property {HTMLButtonElement[]} dialogButtons Shortcut to close button element.
 * @slot
 * @example
 * <div>
 *   <ui-dialog id="dialog-1">
 *     <h2>I am modal title</h2>
 *     <p>How are you?</p>
 *   </ui-dialog>
 *   <button class="button" onclick="document.getElementById('dialog-1').toggle()">Toggle</button>
 * </div>
 */
class UIDialog extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                nonclosable: Boolean,
                opened: Boolean,
            },
            children: {
                dialog: 'dialog',
                closeButton: '.ui-dialog__close-action',
                dialogButtons: {
                    selector: 'dialog [type="submit"]',
                    multiple: true,
                },
            },
        };
    }

    /**
     * Stack of the opened dialogs.
     * @type {UIDialog[]}
     */
    static get DialogStack() {
        if (!this.dialogStack) {
            this.dialogStack = [];
        }
        return this.dialogStack;
    }

    get returnValue() {
        return this._returnValue || '';
    }
    set returnValue(v) {
        this._returnValue = v;
    }

    /**
     * @type {string[]}
     */
    static get observedAttributes() {
        return ['opened'];
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-dialog', {
            accept: 'Yes', // currently not in use
            decline: 'No', // currently not in use
            dismiss: 'OK', // currently not in use
            close: 'Close dialog',
        });
    }

    /**
     * @inheritDoc
     */
    async observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'opened':
                if (newValue === null) {
                    this.constructor.DialogStack.pop();
                    this.dialog.classList.add('-closing');
                    this.dispatchCustomEvent('dialog-close-enter');
                    await this.waitForDialogAnimation(() => {
                        this.dialog.classList.remove('-closing');
                        this.dialog.close();
                        this.dispatchCustomEvent('dialog-close');
                    });
                } else {
                    this.constructor.DialogStack.push(this);
                    this.dispatchCustomEvent('dialog-open-enter');
                    await this.waitForDialogAnimation(() => {
                        this.dialog.showModal();
                        this.dispatchCustomEvent('dialog-open');
                    });
                }
                this.handleDialogShifting();
                break;
            // istanbul ignore next
            default:
                break;
        }
    }

    /**
     * @private
     * @returns {Promise<Animation[]>}
     */
    async waitForDialogAnimation(callback) {
        if (!browser.prefersReducedMotion()) {
            const anims = this.dialog.getAnimations();
            return Promise.all(anims.map((a) => a.finished))
                .then(callback)
                .catch(callback);
        }
        return Promise.resolve([]).then(callback);
    }

    /**
     * Opens the dialog.
     */
    open() {
        this.opened = true;
    }

    /**
     * @inheritDoc
     */
    activate() {
        this.open();
    }

    /**
     * Shifts the dialog to a bit to the left on desktop.
     * @private
     */
    handleDialogShifting() {
        const stack = this.constructor.DialogStack;
        stack.forEach((uiDialog) => uiDialog.dialog.classList.remove('-shift'));
        if (stack.length > 1) {
            for (let i = 0; i < stack.length - 1; ++i) {
                stack[i].dialog.classList.add('-shift');
            }
            stack[stack.length - 1].dialog.classList.remove('-shift');
        }
    }

    /**
     * Closes the dialog.
     */
    close() {
        this.opened = false;
    }

    /**
     * Handles the toggle event.
     */
    toggle() {
        this.opened = !this.opened;
    }

    /**
     * Emulation of ::backdrop click.
     * @private
     * @param {MouseEvent} e
     */
    handleBackdropClick(e) {
        if (!doesClickedInsideRect(this.dialog, e)) {
            this.cancel();
        }
    }

    /**
     * Handles the cancel event.
     * @param {Event} [e]
     */
    cancel(e) {
        e?.preventDefault();
        if (this.nonclosable) {
            return;
        }
        this.close();
    }

    /**
     * @inheritDoc
     */
    render() {
        // istanbul ignore else
        if (this.dialog) {
            return;
        }
        this.append(
            createElement({
                tagName: 'dialog',
                children: [
                    ...this.detachChildNodes(),
                    // we do not use <form method="dialog"> for the close due to legacy
                    // Swedbank Java apps (like ibank) have 1 huge form wrapper like mainForm
                    // Common click handler provides compatibility with legacy apps.
                    !this.nonclosable && {
                        tagName: 'button',
                        classList: {
                            'ui-dialog__close-action': true,
                            '-iconed': true,
                        },
                        attributes: {
                            type: 'button',
                            'aria-label': UIDialog.labels.close,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'cross',
                                    color: UIIcon.colors.DEFAULT,
                                },
                            },
                        ],
                    },
                ],
            })
        );
    }

    /**
     * @param {Event} [e]
     */
    submit(e) {
        e?.preventDefault();
        this.returnValue = e?.target['value'] || '';
        this.close();
    }

    /**
     * @private
     */
    subscribeToEvents() {
        this.cancelHandler = this.cancel.bind(this);
        this.handleBackdropClickHandler = this.handleBackdropClick.bind(this);
        this.closeHandler = this.close.bind(this);
        this.submitHandler = this.submit.bind(this);
        this.dialog.addEventListener('cancel', this.cancelHandler);
        this.dialog.addEventListener('click', this.handleBackdropClickHandler);
        this.closeButton &&
            this.closeButton.addEventListener('click', this.closeHandler);
        this.dialogButtons.forEach((b) => {
            b.addEventListener('click', this.submitHandler);
        });
    }

    /**
     * @private
     */
    unsubscribeFromEvents() {
        this.dialog.removeEventListener('cancel', this.cancelHandler);
        this.dialog.removeEventListener(
            'click',
            this.handleBackdropClickHandler
        );
        this.closeButton &&
            this.closeButton.removeEventListener('click', this.closeHandler);
        this.dialogButtons.forEach((b) => {
            b.removeEventListener('click', this.submitHandler);
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.subscribeToEvents();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.unsubscribeFromEvents();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        this.subscribeToEvents();
    }
}

UIDialog.defineElement('ui-dialog', styles);
export { UIDialog };

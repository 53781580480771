import '../icon/ui-icon.js';
import { UIElement } from '../ui-element.js';
import {
    dispatchNativeEvent,
    hide,
    show,
    updateElement,
} from '../../global/ui-helpers.js';
import { Labels } from '../../global/labels.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-searchinput.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UISearchInput
 * @element ui-searchinput
 * @classdesc Represents a class for <code>ui-searchinput</code> element.
 * Input field used for search purposes.
 * @fires event:clear
 * @property {boolean} [cleanable] {@attr cleanable} If true, field value can
 * be cleaned with button.
 * @property {boolean} [searchable] {@attr searchable} If true, button with search
 * icon will be added.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {HTMLInputElement} control {@readonly} Shortcut to control.
 * @property {HTMLButtonElement} clearBtn {@readonly} Shortcut to clean button if present.
 * @property {HTMLLabelElement} label {@readonly} Shortcut to clean label.
 * @slot
 * @example
 * <ui-searchinput cleanable="true">
 *   <input name="search" type="search" id="test-render-id" class="-long">
 * </ui-searchinput>
 */
class UISearchInput extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                cleanable: Boolean,
                controlId: String,
            },
            children: {
                control: 'input',
                clearBtn: '.ui-searchinput__clear',
                label: '.ui-searchinput__label',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-searchinput', {
            clear: 'Clear input',
        });
    }

    get searchable() {
        if (!this.hasAttribute('searchable')) {
            return true;
        }
        return this.parseBooleanAttr(this.getAttribute('searchable'));
    }
    set searchable(value) {
        this.setAttribute('searchable', this.convertBooleanAttr(value));
    }

    /**
     * Handles clean event.
     * @param {Event} e
     * @private
     */
    handleClear(e) {
        if (!this.cleanable) {
            return;
        }

        const clearEvent = this.dispatchCustomEvent('clear', {}, true);

        if (clearEvent.defaultPrevented) {
            return;
        }

        this.control.value = '';
        dispatchNativeEvent(this.control, 'change', true);
        this.control.focus();
    }

    /**
     * Updates appearance regarding to input value.
     */
    updateStatus() {
        if (this.control.value) {
            if (this.cleanable) {
                show(this.clearBtn);
            }
            if (this.searchable) {
                hide(this.label);
            }
        } else {
            if (this.cleanable) {
                hide(this.clearBtn);
            }
            if (this.searchable) {
                show(this.label);
            }
        }
    }

    /**
     * Handles update on input.
     * @param {Event} e
     * @private
     */
    handleInputUpdates(e) {
        this.updateStatus();
    }

    /**
     * Handles focus on input.
     * @param {Event} e
     * @private
     */
    handleInputFocus(e) {
        this.updateClassList({ '-focus': true });
    }

    /**
     * Handles blur on input.
     * @param {Event} e
     * @private
     */
    handleInputBlur(e) {
        this.updateClassList({ '-focus': false });
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'control-id':
                if (this.control) {
                    if (newValue) {
                        this.control.setAttribute('id', newValue);
                    } else {
                        this.control.removeAttribute('id');
                    }
                }
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const input = this.querySelector('input');
        if ((!this.cleanable && !this.searchable) || !input) {
            return;
        }
        this.insertElements([
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-searchinput__icon',
                },
                children: [
                    this.cleanable && {
                        tagName: 'button',
                        attributes: {
                            type: 'button',
                            'aria-label': UISearchInput.labels.clear,
                        },
                        classList: {
                            'ui-searchinput__clear': true,
                            '-hidden': true,
                            '-iconed': true,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    bgcolor: UIIcon.colors.BARK_30,
                                    glyph: 'cross',
                                },
                            },
                        ],
                    },
                    this.searchable && {
                        tagName: 'label',
                        attributes: {
                            for: input.id ? input.id : '',
                        },
                        classList: {
                            'ui-searchinput__label': true,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'search-form',
                                },
                            },
                        ],
                    },
                ],
            },
        ]);
        this.updateStatus();
        if (this.control && this.control.type !== 'search') {
            this.control.setAttribute('role', 'searchbox');
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        if (!this.control) {
            return;
        }
        updateElement(this.control, {
            events: {
                focus: this.handleInputFocus.bind(this),
                blur: this.handleInputBlur.bind(this),
            },
        });
        if (this.cleanable) {
            updateElement(this.control, {
                events: {
                    input: this.handleInputUpdates.bind(this),
                    change: this.handleInputUpdates.bind(this),
                },
            });
            updateElement(this.clearBtn, {
                events: {
                    click: this.handleClear.bind(this),
                },
            });
        }
        if (this.searchable) {
            updateElement(this.label, {
                events: {
                    click:
                        (!this.control || !this.control.id) &&
                        function () {
                            this.control.focus();
                        }.bind(this),
                },
            });
        }
        this.updateStatus();
    }
}

UISearchInput.defineElement('ui-searchinput', styles);
export { UISearchInput };

import { UIElement } from '../ui-element.js';
import styles from './ui-griditem.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIGridItem
 * @element ui-griditem
 * @classdesc Represents a class for <code>ui-griditem</code> element.
 * This element is done for styling grid item inside grid layout.
 * @property {("default" | "account")} [layout] {@attr layout} Layout type.
 *  {@desc account}
 * @slot
 * @example
 * <ui-grid>
 *   <ui-griditem layout="account"></ui-griditem>
 *   <ui-griditem layout="account"></ui-griditem>
 * </ui-grid>
 */
class UIGridItem extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: '' },
            },
        };
    }
}

UIGridItem.defineElement('ui-griditem', styles);
export { UIGridItem };

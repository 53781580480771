import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import { setInnerText, updateElement } from '../../global/ui-helpers.js';
import { TabIndex } from '../../global/keyboard.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-navlist.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UINavList
 * @element ui-navlist
 * @classdesc Represents a class for <code>ui-navlist</code> element
 * @fires event:menu-update-shortcuts
 * @fires event:menu-back
 * @fires event:edit-shortcuts
 * @fires event:remove-shortcut
 * @fires event:add-shortcut
 * @property {HTMLHeadingElement} backNav Shortcut to Back button.
 * @property {NodeList<UINavItem>} menuItems Shortcut to menu items.
 * @property {boolean} edit {@attr edit} Whether the nav list is in edit mode.
 * @property {string} [labelBack] {@attr label-back} Label for Back button
 * @property {("secondary" | "auxiliary")} [type=secondary] {@attr type}
 *  {@desc secondary}
 *  {@desc auxiliary}
 * @example
 * <ui-navlist></ui-navlist>
 */
class UINavList extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                edit: Boolean,
                type: { type: String, default: 'secondary' },
                labelBack: String,
            },
            children: {
                menuItems: {
                    selector: 'ui-navitem[item]',
                    multiple: true,
                },
                backNav: '.ui-navlist__header.-backnav',
            },
        };
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['label-back'];
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-navlist', {
            MY_SHORTCUTS: 'My shortcuts',
            REMOVE_SHORTCUT: 'Delete shortcut',
            ADD_SHORTCUT: 'Add shortcut',
            EDIT_SHORTCUTS: 'Edit shortcuts',
            FINISH_EDITING: 'Finish editing',
            BACK: 'Back',
        });
    }

    /**
     * Types list.
     * @type {{SECONDARY: string, AUXILIARY: string}}
     */
    static get types() {
        return {
            SECONDARY: 'secondary',
            AUXILIARY: 'auxiliary',
        };
    }

    hasActions() {
        return this.getAttribute('ref') === 'shortcuts';
    }

    setActiveShortcut(pageId) {
        const activeLinks = [].filter.call(this.menuItems, (node) => {
            if (pageId === node.getAttribute('item')) {
                node.activate();
                return true;
            } else {
                node.deactivate();
                return false;
            }
        });
        const managePageAction = this.querySelector(
            '.ui-navlist__action.-manage-page'
        );
        if (managePageAction) {
            UIElement.mutateTo(
                managePageAction,
                this.createElement(
                    this.buildManagePageAction(activeLinks.length > 0)
                ),
                true
            );
        }
    }

    /**
     * Sets class -selected to be true to activate the nav list
     * @returns {UIElement}
     */
    activate() {
        this.setAttributes({ 'aria-hidden': 'false' });
        return this.updateClassList({ '-expanded': true });
    }

    /**
     * Removes class -selected to be true to remove deactivate the nav list
     * @returns {UIElement}
     */
    deactivate() {
        this.setAttributes({ 'aria-hidden': 'true' });
        return this.updateClassList({ '-expanded': false });
    }

    /**
     *
     * @param {Event} e
     * @fires event:menu-update-shortcuts
     */
    handleDrop(e) {
        this.dragTarget = null;
        const items = [].map.call(this.menuItems, (node) => {
            return node.getAttribute('item');
        });
        this.dispatchCustomEvent('menu-update-shortcuts', { items: items });
    }

    handleDragOver(e) {
        e.preventDefault();
        const targetPlace = e.target.closest('ui-navitem');
        if (this.dragTarget) {
            const pos = targetPlace.compareDocumentPosition(this.dragTarget);
            if (pos === 4) {
                targetPlace.parentElement.insertBefore(
                    this.dragTarget,
                    targetPlace
                );
            } else if (pos === 2) {
                const next = targetPlace.nextSibling;
                if (next) {
                    targetPlace.parentElement.insertBefore(
                        this.dragTarget,
                        next
                    );
                } else {
                    targetPlace.parentElement.appendChild(this.dragTarget);
                }
            }
        }
    }

    handleDragStart(e) {
        const item = e.target.closest('ui-navitem');
        if (!item) {
            return;
        }
        this.dragTarget = item;
    }

    toggleEditMode() {
        this.edit = !this.edit;
        const editAction = this.querySelector('.ui-navlist__action.-edit');
        if (editAction) {
            UIElement.mutateTo(
                editAction,
                this.createElement(this.buildEditAction(this.edit)),
                true
            );
        }
        [].forEach.call(this.menuItems, (node) => {
            node.edit = this.edit;
            const link = node.querySelector('a');
            if (this.edit) {
                node.addEventListener('dragover', this.handleDragOver);
                node.addEventListener('drop', this.handleDrop);
                link &&
                    link.addEventListener('dragstart', this.handleDragStart);
            } else {
                node.removeEventListener('dragover', this.handleDragOver);
                node.removeEventListener('drop', this.handleDrop);
                link &&
                    link.removeEventListener('dragstart', this.handleDragStart);
            }
        });
    }

    /**
     * @param {boolean} isEditMode
     * @fires event:edit-shortcuts
     * @returns {IElementConfig}
     */
    buildEditAction(isEditMode) {
        return {
            tagName: 'ui-navitem',
            classList: {
                'ui-navlist__action': true,
                '-edit': true,
            },
            children: [
                {
                    tagName: 'button',
                    attributes: {
                        'data-event': 'edit-shortcuts',
                        type: 'button',
                    },
                    children: [
                        {
                            tagName: 'ui-caption',
                            children: [
                                {
                                    tagName: 'ui-icon',
                                    attributes: {
                                        glyph: 'edit',
                                        bgcolor: UIIcon.colors.TURQUOISE,
                                    },
                                },
                                isEditMode
                                    ? UINavList.labels.FINISH_EDITING
                                    : UINavList.labels.EDIT_SHORTCUTS,
                            ],
                        },
                    ],
                },
            ],
        };
    }

    /**
     * @param {boolean} hasShortcutForPage
     * @fires event:remove-shortcut
     * @fires event:add-shortcut
     * @returns {IElementConfig}
     */
    buildManagePageAction(hasShortcutForPage) {
        return {
            tagName: 'ui-navitem',
            classList: {
                'ui-navlist__action': true,
                '-manage-page': true,
            },
            children: [
                {
                    tagName: 'button',
                    attributes: {
                        'data-event': hasShortcutForPage
                            ? 'remove-shortcut'
                            : 'add-shortcut',
                        type: 'button',
                    },
                    children: [
                        {
                            tagName: 'ui-caption',
                            children: [
                                {
                                    tagName: 'ui-icon',
                                    attributes: {
                                        glyph: hasShortcutForPage
                                            ? 'cross'
                                            : 'add',
                                        bgcolor: UIIcon.colors.TURQUOISE,
                                    },
                                },
                                hasShortcutForPage
                                    ? UINavList.labels.REMOVE_SHORTCUT
                                    : UINavList.labels.ADD_SHORTCUT,
                            ],
                        },
                    ],
                },
            ],
        };
    }

    /**
     * @returns {IElementConfig}
     */
    buildShortcutsActions() {
        return {
            tagName: 'div',
            classList: {
                'ui-navlist__actions': true,
            },
            children: [
                this.buildEditAction(this.edit),
                this.buildManagePageAction(false),
            ],
        };
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (this.state !== 'hydrated') {
            return;
        }

        switch (name) {
            case 'label-back':
                this.backNav
                    .querySelector('.ui-navlist__header-icon')
                    .setAttribute(
                        'color',
                        this.labelBack
                            ? UIIcon.colors.DEFAULT
                            : UIIcon.colors.ORANGE
                    );
                setInnerText(
                    this.backNav.querySelector('.ui-navlist__header-caption'),
                    this.labelBack || UINavList.labels.BACK
                );
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        if (this.hasActions()) {
            this.insertElements([this.buildShortcutsActions()]);
        }

        const nav = this.closest('ui-nav');
        this.updateElement({
            attributes: {
                'aria-hidden': 'true',
            },
            classList: {
                '-secondary': this.type === UINavList.types.SECONDARY,
                '-auxiliary': this.type === UINavList.types.AUXILIARY,
                '-actions': this.hasActions(),
            },
        }).insertElements([
            {
                tagName: 'button',
                attributes: {
                    type: 'button',
                    tabindex:
                        nav && nav.isMobileMode()
                            ? TabIndex.Active
                            : TabIndex.Inactive,
                },
                classList: {
                    'ui-navlist__header': true,
                    '-backnav': true,
                    '-link': true,
                },
                children: [
                    {
                        tagName: 'h4',
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'left',
                                    color: this.labelBack
                                        ? UIIcon.colors.DEFAULT
                                        : UIIcon.colors.ORANGE,
                                    class: 'ui-navlist__header-icon',
                                },
                            },
                            {
                                tagName: 'span',
                                classList: {
                                    'ui-navlist__header-caption': true,
                                },
                                children: [
                                    this.labelBack || UINavList.labels.BACK,
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                tagName: 'ul',
                attributes: {
                    role:
                        this.type === UINavList.types.AUXILIARY
                            ? 'navigation'
                            : null,
                },
                classList: {
                    '-secondary-list': this.type === UINavList.types.SECONDARY,
                    '-auxiliary-list': this.type === UINavList.types.AUXILIARY,
                    '-plain-content': !this.querySelector('ui-navlist-menu'),
                },
                children: this.childNodes,
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        updateElement(this.backNav, {
            events: {
                click: () => this.dispatchCustomEvent('menu-back', null),
            },
        });
    }
}

UINavList.defineElement('ui-navlist', styles);
export { UINavList };

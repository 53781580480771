import { toggleLoaderInButton } from './loader.js';
import { eventBus } from './event-bus.js';

/**
 * Locks the form or control due when making HTTP request.
 * @memberof UiHelpers
 * @param {PromiseLike<any>} request
 * @param {HTMLInputElement|HTMLSelectElement|HTMLButtonElement|UICaption} control
 * @param {HTMLElement} context
 * @returns {Promise<Array<Awaited<any>> | any>}
 */
const runWithLockingForm = (request, control, context) => {
    const beforeActions = [() => eventBus.dispatchCustomEvent('loading-start')];
    const afterActions = [
        () => eventBus.dispatchCustomEvent('loading-complete'),
    ];

    const updateButtonIcon = () => {
        /** @type {UIIcon} */
        const icon = control.querySelector('ui-icon');
        const prevGlyph = icon.glyph;
        const prevBgColor = icon.bgcolor;
        beforeActions.push(() => {
            icon.glyph = 'loader';
            icon.bgcolor = 'info-gray';
        });
        afterActions.push(() => {
            icon.glyph = prevGlyph;
            icon.bgcolor = prevBgColor;
        });
    };
    if (control) {
        const tag = control.tagName.toLowerCase();
        switch (tag) {
            case 'ui-caption':
                if (control.layout === 'chip') {
                    updateButtonIcon();
                }
                break;
            case 'button':
                if (control.classList.contains('-iconed')) {
                    updateButtonIcon();
                } else {
                    beforeActions.push(() =>
                        toggleLoaderInButton(control, true)
                    );
                    afterActions.push(() =>
                        toggleLoaderInButton(control, false)
                    );
                }
                break;
            case 'input':
            case 'select':
            case 'textarea':
                switch (control.type) {
                    case 'select-one':
                    case 'text':
                        beforeActions.push(() => {
                            control.readOnly = true;
                            control.classList.add('-loading');
                        });
                        afterActions.push(() => {
                            control.readOnly = false;
                            control.classList.remove('-loading');
                        });
                        break;
                }
                break;
        }
    }

    if (context) {
        const preventer = (e) => e.preventDefault();

        const controls = context.querySelectorAll(
            'input:not([disabled]):not([readonly]),select:not([disabled]):not([readonly])'
        );

        const fields = context.querySelectorAll('ui-field');

        beforeActions.push(() => {
            fields.forEach((entry) => {
                entry.style.opacity = 0.7;
                entry.style.transition = 'opacity 0.3s ease-in';
            });
            controls.forEach((entry) => {
                entry.readOnly = true;
            });
            context.style.pointerEvents = 'none';
            context.addEventListener('keydown', preventer);
            context.addEventListener('keyup', preventer);
            context.addEventListener('keypress', preventer);
        });
        afterActions.push(() => {
            fields.forEach((entry) => {
                entry.style.opacity = 1;
                entry.style.transition = null;
            });
            controls.forEach((entry) => {
                entry.readOnly = false;
            });
            context.style.pointerEvents = null;
            context.removeEventListener('keydown', preventer);
            context.removeEventListener('keyup', preventer);
            context.removeEventListener('keypress', preventer);
        });
    }

    const runBlockers = () => Promise.all(beforeActions.map((r) => r()));
    const stopBlockers = () => Promise.all(afterActions.map((r) => r()));

    return Promise.resolve()
        .then(() => runBlockers())
        .then(() => Promise.resolve(request))
        .then(
            () => stopBlockers(),
            (e) =>
                stopBlockers().then(() => {
                    throw e;
                })
        );
};

export { runWithLockingForm };

import { UIElement } from '../ui-element.js';
import { BrandColors, isBrandColor } from '../../global/helpers-marketing.js';
import { insertElements } from '../../global/render-api.js';
import styles from './ui-remark.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIRemark
 * @element ui-remark
 * @classdesc Represents a class for <code>ui-remark</code> element.
 * Formerly known as eye-catcher.
 * @property {UIRemarkLayout} [layout="corner"] {@attr layout} Shape of the remark.
 * @property {UIBrandColor} [color="lilac"] {@attr color} Color of the remark.
 * @example
 * <ui-remark layout="corner">Great offer!</ui-remark>
 */
class UIRemark extends UIElement {
    /**
     * @type {UIRemarkLayouts}
     */
    static get Layout() {
        return {
            Corner: 'corner',
            Circle: 'circle',
            Speech: 'speech',
            SpeechSquare: 'speech-square',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: {
                    type: String,
                    default: UIRemark.Layout.Corner,
                },
                color: {
                    type: String,
                    default: BrandColors.Lilac,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component,
                        value
                    ) => {
                        const info = `Allowed options are: turquoise, lilac, pineapple, orange.`;
                        if (['yellow', 'blue'].includes(value)) {
                            console.warn(
                                `${value} - color is deprecated. ${info}`,
                                component
                            );
                            return;
                        }
                        if (!isBrandColor(value)) {
                            console.warn(
                                `${value} - value does not exist. ${info}`,
                                component
                            );
                        }
                    },
                },
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        insertElements(this, [
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-remark__content',
                },
                children: this.childNodes,
            },
        ]);
    }
}

UIRemark.defineElement('ui-remark', styles);
export { UIRemark };

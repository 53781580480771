import '../datetime/ui-monthpicker.js';
import { Labels } from '../../global/labels.js';
import { keyCodes, TabIndex } from '../../global/keyboard.js';
import { Digest } from '../../global/digest.js';
import { UIDateElement } from '../datetime/ui-dateelement.js';
import { addLeadingZero } from '../../global/helpers.js';
import {
    getFocusableElements,
    setInnerText,
    updateElement,
} from '../../global/ui-helpers.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-calendar.css';

/**
 * @memberof SharedComponents
 * @augments {UIDateElement}
 * @alias UICalendar
 * @element ui-calendar
 * @classdesc Represents a class for <code>ui-calendar</code> element.
 * Renders calendar element, mostly used in combination with ui-datepicker.
 * @fires event:change
 * @property {string} min {@attr min} Minimum date value of the calendar.
 * @property {string} max {@attr max} Maximum date value of the calendar.
 * @property {string} value {@attr value} Current date value of the calendar,
 * referenced of the control/input element.
 * @property {Locale} [locale="en"] {@attr locale} Localization rules.
 * @property {HTMLParagraphElement} message {@readonly} Shortcut to accessibility assist message.
 * @property {UIMonthPicker} monthPicker {@readonly}
 * @property {HTMLInputElement} control {@readonly}
 * @property {HTMLDivElement} calendar {@readonly}
 * @property {HTMLButtonElement} prevMonthButton {@readonly}
 * @property {HTMLButtonElement} nextMonthButton {@readonly}
 * @property {NodeList<HTMLButtonElement>} dayButtons {@readonly}
 * @property {HTMLDivElement} header {@readonly}
 * @property {NodeList<HTMLTableCellElement>} activeDayButtons {@readonly}
 * @property {HTMLTableCellElement} today {@readonly}
 * @example
 * <ui-calendar></ui-calendar>
 */
class UICalendar extends UIDateElement {
    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-calendar', {
            weekDays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
            weekdaysLong: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ],
            prevMonth: 'Previous month',
            nextMonth: 'Next month',
            arrowKeys: 'Arrow keys can navigate dates',
        });
    }

    static resolveMonthKey(index) {
        // eslint-disable-next-line max-len
        return [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ][index % 12];
    }
    static resolveWeekdaysKey(index) {
        return ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fr', 'Sat'][index % 7];
    }

    /**
     * When call update calendar, these are list of possible sources where from calendar is updated.
     * @type {{
     *  CALENDAR_CHANGED: string,
     *  RENDERED: string,
     *  DATE_SELECTED: string,
     *  CONTROL_CHANGED: string,
     *  MONTH_CHANGED: string,
     *  PERIOD_CHANGED: string,
     *  INPUT_CHANGED: string
     * }}
     */
    static get RENDER_SOURCES() {
        return {
            RENDERED: 'rendered',
            MONTH_CHANGED: 'month-changed',
            CONTROL_CHANGED: 'control-changed',
            PERIOD_CHANGED: 'period-changed',
            CALENDAR_CHANGED: 'calendar-changed',
            DATE_SELECTED: 'date-selected',
            INPUT_CHANGED: 'input-changed',
        };
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                locale: String,
            },
            children: {
                control: '.ui-calendar__input',
                monthPicker: 'ui-monthpicker',
                calendar: '.ui-calendar__calendar',
                prevMonthButton: '.ui-calendar__navbutton.-prev',
                nextMonthButton: '.ui-calendar__navbutton.-next',
                header: '.ui-calendar__header',
                message: '.ui-calendar__message',
                today: '.ui-calendar__daybutton.-today',
                dayButtons: {
                    selector: '.ui-calendar__daybutton',
                    multiple: true,
                },
                activeDayButtons: {
                    selector: '.ui-calendar__daybutton:not(.-disabled)',
                    multiple: true,
                },
            },
        };
    }
    get value() {
        if (!this.isValidDate(this.control.value)) {
            return null;
        }
        return this.reformatDateString(this.control.value);
    }
    set value(value) {
        if (!this.isValidDate(value) || this.control.value === value) {
            return;
        }
        this.control.value = value;
    }

    /**
     * @type {number}
     */
    static get ASSIST_MSG_TIMEOUT() {
        return 200;
    }

    /**
     * @param {ICalendarConfig} config
     */
    setConfig(config) {
        this._config = config;
    }

    /**
     * @returns {ICalendarConfig}
     */
    getConfig() {
        return this._config;
    }

    /**
     * Sets year and month to the monthpicker.
     * @param {number} year
     * @param {number} month
     */
    setActiveMonth(year, month) {
        this.monthPicker.value = [year, month].join('-');
        this.updateCalendar(
            year,
            month,
            UICalendar.RENDER_SOURCES.MONTH_CHANGED
        );
        this.monthPicker.recalculateMonthWidth();
    }

    /**
     * Update a calendar appearance according to given year and month values.
     * @param {number} year
     * @param {number} month
     * @param {string} source
     * @returns {UICalendar}
     */
    updateCalendar(year, month, source) {
        const weekStart = 1;
        const date = new Date(year, month, 0);
        const lastDate = date.getDate();
        let startDay = new Date(year, month - 1, 1).getDay();
        let value = this.value;

        if (source !== UICalendar.RENDER_SOURCES.RENDERED) {
            this.toggleMonthNavigationButtons(date);
        }

        if (weekStart && startDay === 0) {
            startDay = 7;
        }

        const config = this.getConfig();
        const periods =
            config && config.periods
                ? config.periods.filter(
                      (p) =>
                          Array.isArray(p.months) &&
                          p.months.includes(
                              UICalendar.resolveMonthKey(date.getMonth())
                          )
                  )
                : [];

        const isDateInactive = (date) =>
            periods.some((p) => {
                if (p.type !== 'inactive') {
                    return false;
                }

                if (
                    Array.isArray(p.dates) &&
                    p.dates.includes(date.getDate())
                ) {
                    return true;
                }

                return (
                    Array.isArray(p.weekdays) &&
                    p.weekdays.includes(
                        UICalendar.resolveWeekdaysKey(date.getDay())
                    )
                );
            });

        this.querySelectorAll('td[data-index]').forEach((node) => {
            const index = Number(node.dataset.index);
            const isPreviousMonth = index + 1 < startDay;
            const isNextMoth = index + 1 >= startDay + lastDate;
            if (isPreviousMonth || isNextMoth) {
                updateElement(node, {
                    classList: {
                        '-disabled': true,
                        '-selected': false,
                        '-today': false,
                    },
                    attributes: {
                        tabindex: TabIndex.Inactive,
                        'data-date': null,
                        'aria-hidden': 'true',
                    },
                });
                setInnerText(node, '');
            } else {
                const day = index + 2 - startDay;
                const dateString = this.formatDateString(
                    Number(addLeadingZero(day)),
                    Number(addLeadingZero(month)),
                    year
                );
                const todayDateString = this.timestampToDateString(
                    new Date().getTime()
                );
                if (value === null) {
                    value = todayDateString;
                }
                const date = new Date(year, month - 1, day);
                updateElement(node, {
                    attributes: {
                        'data-date': dateString,
                        'aria-selected': value === dateString ? 'true' : null,
                        tabindex:
                            value === dateString
                                ? TabIndex.Active
                                : TabIndex.Inactive,
                    },
                    classList: {
                        '-selected': value === dateString,
                        '-today': dateString === todayDateString,
                        '-disabled':
                            !this.isDateInRange(date) || isDateInactive(date),
                    },
                });
                setInnerText(node, String(day));
            }
        });

        if (this.monthPicker.monthSelect && this.monthPicker.yearSelect) {
            const title =
                this.monthPicker.monthSelect.selectedOptions[0].text +
                ' ' +
                this.monthPicker.yearSelect.selectedOptions[0].text;
            setInnerText(this.header, title);
        }

        if (source === UICalendar.RENDER_SOURCES.RENDERED) {
            this.insertElements([
                {
                    tagName: 'p',
                    classList: {
                        disclaimer: true,
                        'ui-calendar__message': true,
                        '-hidden': true,
                    },
                    attributes: {
                        'aria-live': 'polite',
                    },
                    children: [UICalendar.labels.arrowKeys],
                },
            ]);
        }
        return this;
    }

    /**
     * Sets value to the controls.
     * @param {string} value
     * @param {string} source
     * @returns {UICalendar}
     */
    updateControlValue(value, source) {
        const validValue = this.isValidDate(value);
        const data = this.parseDateValue(value, this.min, this.max);
        const isNewValue = this.value !== data.value;
        if (validValue && isNewValue) {
            this.value = data.value;
        }
        if (isNewValue || source === 'date-selected') {
            this.dispatchCustomEvent('change', { source: source });
        }
        const monthPicker = this.monthPicker;
        const monthValue = [data.year, data.month].join('-');
        if (monthPicker && monthPicker.value !== monthValue) {
            monthPicker.value = monthValue;
        }
        this.updateCalendar(
            data.year,
            data.month,
            UICalendar.RENDER_SOURCES.CONTROL_CHANGED
        );
        return this;
    }

    /**
     * Fires callback when period is changed.
     * @private
     */
    onChangePeriod() {
        const picker = this.monthPicker;
        if (this.min) {
            const minParts = this.parseDateString(this.min);
            const newMinValue = this.formatMonthString(
                minParts.month,
                minParts.year
            );
            if (picker.min !== newMinValue) {
                picker.min = newMinValue;
            }
        }
        if (this.max) {
            const maxParts = this.parseDateString(this.max);
            const newMaxValue = this.formatMonthString(
                maxParts.month,
                maxParts.year
            );
            if (picker.max !== newMaxValue) {
                picker.max = newMaxValue;
            }
        }
        this.updateControlValue(
            this.value,
            UICalendar.RENDER_SOURCES.PERIOD_CHANGED
        );
    }

    /**
     * Fires callback when the host element's value is updated.
     * @param {Event} e
     * @private
     */
    handleValueUpdate(e) {
        if (e.target !== this) {
            return;
        }
        this.updateControlValue(
            this.value,
            UICalendar.RENDER_SOURCES.CALENDAR_CHANGED
        );
    }

    /**
     * Fires callback when user clicks on the next-month button.
     * @private
     */
    handleNextMonth() {
        const maxDateValue = this.getMaxDate();
        const picker = this.monthPicker;
        const data = this.parseMonthValue(picker.value, picker.min, picker.max);
        const date = new Date();
        date.setFullYear(data.year, data.month, 1);
        date.setHours(0, 0, 0, 0);
        this.toggleMonthNavigationButtons(date);
        if (this.nextMonthButton.disabled) {
            this.monthPicker.monthSelect.focus();
        }
        if (maxDateValue && maxDateValue < date) {
            return;
        }
        this.setActiveMonth(date.getFullYear(), date.getMonth() + 1);
    }

    /**
     * Fires callback when user clicks on the previous-month button.
     * @private
     */
    handlePrevMonth() {
        const minDateValue = this.getMinDate();
        const picker = this.monthPicker;
        const data = this.parseMonthValue(picker.value, picker.min, picker.max);
        const date = new Date();
        date.setFullYear(data.year, data.month - 1, 0);
        this.toggleMonthNavigationButtons(date);
        if (this.prevMonthButton.disabled) {
            picker.monthSelect.focus();
        }
        if (minDateValue && minDateValue > date) {
            return;
        }
        this.setActiveMonth(date.getFullYear(), date.getMonth() + 1);
    }

    /**
     * Resolves disability of next/prev buttons.
     * @param {Date} date
     * @private
     */
    toggleMonthNavigationButtons(date) {
        const d = new Date(date); // we don't mutate original date.
        const min = this.getMinDate();
        const max = this.getMaxDate();
        if (min) {
            this.prevMonthButton.disabled =
                min.getMonth() >= d.getMonth() &&
                min.getFullYear() >= d.getFullYear();
        }
        if (max) {
            this.nextMonthButton.disabled =
                max.getMonth() <= d.getMonth() &&
                max.getFullYear() <= d.getFullYear();
        }
    }

    /**
     * Fires callback when user change active month using the monthpicker.
     * @param {Event} e
     * @private
     */
    handleMonthUpdate(e) {
        const data = this.parseMonthValue(
            e.target.value,
            e.target.min,
            e.target.max
        );
        this.updateCalendar(
            data.year,
            data.month,
            UICalendar.RENDER_SOURCES.MONTH_CHANGED
        );
    }

    /**
     * Fires callback when user click on a date.
     * @param {Event} e
     * @private
     */
    handleDaySelect(e) {
        if (e.target.classList.contains('-disabled')) {
            return;
        }
        this.updateControlValue(
            e.target.dataset.date,
            UICalendar.RENDER_SOURCES.DATE_SELECTED
        );
    }

    /**
     * @param {KeyboardEvent} e
     * @private
     */
    handleKeyboardSelect(e) {
        if ([keyCodes.ENTER, keyCodes.SPACE].indexOf(e.keyCode) !== -1) {
            this.handleDaySelect(e);
        }
    }

    /**
     * Fires callback when user focus on a date grid.
     * @param {Event} e
     * @private
     */
    handleDayFocus(e) {
        this.setMessageDelayed(UICalendar.labels.arrowKeys);
    }

    /**
     * Fires callback when the input control gets a change event.
     * @param {Event} e
     * @private
     */
    handleInputChange(e) {
        this.updateControlValue(
            e.target.value,
            UICalendar.RENDER_SOURCES.INPUT_CHANGED
        );
    }

    /**
     * @inheritDoc
     */
    handleControlMutations(mutations) {
        for (let i = 0; i < mutations.length; i++) {
            const mutation = mutations[i];
            if (mutation.type !== 'attributes') {
                continue;
            }
            if (['value', 'min', 'max'].indexOf(mutation.attributeName) > -1) {
                if (
                    mutation.oldValue !==
                    mutation.target.getAttribute(mutation.attributeName)
                ) {
                    this.onChangePeriod();
                    return;
                }
            }
        }
    }

    /**
     * Handles cursor keys.
     * @param {KeyboardEvent} event
     * @private
     */
    handleKeyDown(event) {
        /* Prevent default (like scroll with arrow keys), only ENTER and SPACE can be default. */
        if ([keyCodes.ENTER, keyCodes.SPACE].indexOf(event.keyCode) === -1) {
            event.preventDefault();
        }

        if (keyCodes.TAB === event.keyCode) {
            this.setMessageDelayed('');
            this.handleTabNavigation(event);
        }
    }

    /**
     * @param {KeyboardEvent} event
     * @private
     */
    handleArrowKeys(event) {
        event.preventDefault();
        const d = new Date(
            this.dateStringToTimestamp(document.activeElement.dataset.date)
        );
        if (
            document.activeElement.classList.contains('ui-calendar__daybutton')
        ) {
            switch (event.keyCode) {
                case keyCodes.LEFT:
                    this.moveToPreviousDay(d);
                    break;
                case keyCodes.RIGHT:
                    this.moveToNextDay(d);
                    break;
                case keyCodes.UP:
                    this.moveToPreviousWeek(d);
                    break;
                case keyCodes.DOWN:
                    this.moveToNextWeek(d);
                    break;
                case keyCodes.PAGEUP:
                    this.moveToNextYear(d);
                    break;
                case keyCodes.PAGEDOWN:
                    this.moveToPreviousYear(d);
                    break;
                /* istanbul ignore next */
                default:
                    break;
            }
        }
    }

    /**
     * Handles tab navigation.
     * @param {KeyboardEvent|PointerEvent} event
     */
    handleTabNavigation(event) {
        event.preventDefault();
        const targets = getFocusableElements(this);
        const popover = this.closest('.ui-datepicker__popover');
        let closeElem;
        if (popover) {
            closeElem = popover.querySelector('.ui-datepicker__close-btn');
        }
        if (closeElem) {
            targets.push(closeElem);
        }
        if (targets.length === 0) {
            return;
        }
        const firstElem = targets[0];
        const lastElem = targets[targets.length - 1];
        const index = targets.indexOf(document.activeElement);

        if (index === -1) {
            event.shiftKey ? lastElem.focus() : firstElem.focus();
            return;
        }

        if (index === targets.length - 1 && !event.shiftKey) {
            firstElem.focus();
            return;
        }

        if (index === 0 && event.shiftKey) {
            lastElem.focus();
            return;
        }

        targets[index + (event.shiftKey ? -1 : 1)].focus();
    }

    /**
     * Sets the messages for accessibility with slight delay to make them be read
     * by screen-readers.
     * @param {string} msg
     */
    setMessageDelayed(msg) {
        if (this.message.innerText !== msg) {
            setTimeout(
                () => setInnerText(this.message, msg),
                UICalendar.ASSIST_MSG_TIMEOUT
            );
        }
    }

    /**
     * Sets the focus when navigating with cursor buttons.
     * @param {Date} d
     * @private
     */
    setFocusDay(d) {
        const min = this.getMinDate() || new Date(1970, 0, 1);
        const max = this.getMaxDate() || new Date(9999, 0, 1);
        const value = this.timestampToDateString(d.getTime());

        // Create a new date to be sure that there isn't extra timezone offset.
        const tmpDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        if (tmpDate < min || tmpDate > max) {
            return;
        }
        this.value = value;

        // We are using a delay to be sure that grid is drawn and focus can be set correctly.
        setTimeout(() => {
            const btn = this.querySelector('[data-date="' + value + '"]');
            btn.focus();
        });
    }

    /**
     * Move date to previous day.
     * @param {Date} d
     * @private
     */
    moveToPreviousDay(d) {
        d.setDate(d.getDate() - 1);
        this.setFocusDay(d);
    }

    /**
     * Move date to next day.
     * @param {Date} d
     * @private
     */
    moveToNextDay(d) {
        d.setDate(d.getDate() + 1);
        this.setFocusDay(d);
    }

    /**
     * Move date to previous week.
     * @param {Date} d
     * @private
     */
    moveToPreviousWeek(d) {
        d.setDate(d.getDate() - 7);
        this.setFocusDay(d);
    }

    /**
     * Move date to next week.
     * @param {Date} d
     * @private
     */
    moveToNextWeek(d) {
        d.setDate(d.getDate() + 7);
        this.setFocusDay(d);
    }

    /**
     * Move date to next year.
     * @param {Date} d
     * @private
     */
    moveToNextYear(d) {
        d.setFullYear(d.getFullYear() + 1);
        this.setFocusDay(d);
    }

    /**
     * Move date to previous year.
     * @param {Date} d
     * @private
     */
    moveToPreviousYear(d) {
        d.setFullYear(d.getFullYear() - 1);
        this.setFocusDay(d);
    }

    /**
     * @param {MouseEvent} event
     * @private
     */
    handleClick(event) {
        if (document.activeElement.localName === 'body') {
            setInnerText(this.message, '');
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const uuid = Digest.randomId();
        const control = this.queryChildren('input')[0];
        control.classList.add('ui-calendar__input');
        const value = control.getAttribute('value');
        const valueParts = value ? this.parseDateString(value) : null;
        let minParts;
        let maxParts;

        if (control) {
            ['value', 'min', 'max'].forEach((a) => {
                if (control.hasAttribute(a)) {
                    control.setAttribute(a, this.reformatDateString(this[a]));
                }
            });
        }

        const monthPickerAttributes = {
            value: value
                ? this.formatMonthString(valueParts.month, valueParts.year)
                : null,
        };
        if (this.min) {
            minParts = this.parseDateString(this.min);
            monthPickerAttributes.min = this.formatMonthString(
                minParts.month,
                minParts.year
            );
        }
        if (this.max) {
            maxParts = this.parseDateString(this.max);
            monthPickerAttributes.max = this.formatMonthString(
                maxParts.month,
                maxParts.year
            );
        }

        this.setAttribute('aria-labelledby', uuid);
        this.insertElements([
            {
                tagName: 'h2',
                attributes: {
                    'aria-live': 'polite',
                    id: uuid,
                },
                classList: {
                    'ui-calendar__header': true,
                    '-visually-hidden': true,
                },
            },
            {
                tagName: 'input',
                element: control,
                attributes: {
                    class: 'ui-calendar__input',
                    value: value,
                    type: 'hidden',
                    min: this.min,
                    max: this.max,
                    pattern: this.dateRegExp.toString(),
                },
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-calendar__header',
                },
                children: [
                    {
                        tagName: 'button',
                        attributes: {
                            class: 'ui-calendar__navbutton -prev',
                            type: 'button',
                            'aria-label': UICalendar.labels.prevMonth,
                            disabled:
                                minParts &&
                                valueParts &&
                                minParts.month >= valueParts.month &&
                                minParts.year >= valueParts.year
                                    ? true
                                    : null,
                        },
                        classList: {
                            'ui-calendar__navbutton': true,
                            '-prev': true,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'left',
                                    bgcolor: UIIcon.colors.TEAL,
                                },
                            },
                        ],
                    },
                    {
                        tagName: 'ui-monthpicker',
                        attributes: {
                            locale: this.locale,
                            layout: 'refined',
                        },
                        children: [
                            {
                                tagName: 'input',
                                attributes: monthPickerAttributes,
                            },
                        ],
                    },
                    {
                        tagName: 'button',
                        attributes: {
                            type: 'button',
                            'aria-label': UICalendar.labels.nextMonth,
                            disabled:
                                maxParts &&
                                valueParts &&
                                maxParts.month <= valueParts.month &&
                                maxParts.year <= valueParts.year
                                    ? true
                                    : null,
                        },
                        classList: {
                            'ui-calendar__navbutton': true,
                            '-next': true,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'right',
                                    bgcolor: UIIcon.colors.TEAL,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-calendar__calendar',
                },
                children: [
                    {
                        tagName: 'table',
                        attributes: {
                            role: 'grid',
                        },
                        children: [
                            {
                                tagName: 'thead',
                                children: [
                                    {
                                        tagName: 'tr',
                                        children:
                                            UICalendar.labels.weekDays.map(
                                                (label, i) => {
                                                    return {
                                                        tagName: 'th',
                                                        attributes: {
                                                            scope: 'col',
                                                            abbr: UICalendar
                                                                .labels
                                                                .weekdaysLong[
                                                                i
                                                            ],
                                                        },
                                                        children: [label],
                                                    };
                                                }
                                            ),
                                    },
                                ],
                            },
                        ].concat([
                            {
                                tagName: 'tbody',
                                children: [0, 1, 2, 3, 4, 5].map((week) => {
                                    return {
                                        tagName: 'tr',
                                        children: [0, 1, 2, 3, 4, 5, 6].map(
                                            (day) => {
                                                return {
                                                    tagName: 'td',
                                                    attributes: {
                                                        'data-index':
                                                            day + week * 7,
                                                        tabindex:
                                                            TabIndex.Inactive,
                                                    },
                                                    classList: {
                                                        'ui-calendar__daybutton': true,
                                                    },
                                                };
                                            }
                                        ),
                                    };
                                }),
                            },
                        ]),
                    },
                ],
            },
        ]);

        const validValue = this.isValidDate(value);
        const data = this.parseDateValue(value, this.min, this.max);
        if (validValue && this.value !== data.value) {
            this.value = data.value;
        }
        this.updateCalendar(
            data.year,
            data.month,
            UICalendar.RENDER_SOURCES.RENDERED
        );
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.updateElement({
            events: {
                change: this.handleValueUpdate.bind(this),
                keydown: this.handleKeyDown.bind(this),
                click: this.handleClick.bind(this),
            },
        });
        updateElement(this.control, {
            events: {
                change: this.handleInputChange.bind(this),
            },
        });
        updateElement(this.prevMonthButton, {
            events: {
                click: this.handlePrevMonth.bind(this),
            },
        });
        updateElement(this.nextMonthButton, {
            events: {
                click: this.handleNextMonth.bind(this),
            },
        });
        updateElement(this.monthPicker, {
            events: {
                change: this.handleMonthUpdate.bind(this),
            },
        });

        updateElement(this.calendar, {
            events: {
                keydown: this.handleArrowKeys.bind(this),
            },
        });

        this.dayButtons.forEach((btn) => {
            btn.addEventListener('focus', this.handleDayFocus.bind(this));
            btn.addEventListener('click', this.handleDaySelect.bind(this));
            btn.addEventListener(
                'keydown',
                this.handleKeyboardSelect.bind(this)
            );
        });

        this.observer = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.observer.observe(this.control, { attributes: true });
    }
}

UICalendar.defineElement('ui-calendar', styles);
export { UICalendar };

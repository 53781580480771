import { makeDateFromString } from './helpers.js';

/**
 * @memberof SharedComponents
 * @class Comparator
 * @alias comparator
 */

/**
 * Compares strings 2 string using locale.
 * @memberof Comparator
 * @param {string}  a
 * @param {string} b
 * @returns {number}
 */
const compareStrings = (a, b) => a.localeCompare(b);

/**
 * Compares date represented as strings using in formats dd.mm.yyyy and yyyy-mm-dd.
 * @memberof Comparator
 * @param {Date} a
 * @param {Date} b
 * @returns {number}
 */
const compareDates = (a, b) => a.getTime() - b.getTime();

/**
 * Compares 2 strings or string-line numbers taking into account spaces.
 * @memberof Comparator
 * @param {string|number} a
 * @param {string|number} b
 * @returns {number}
 */
const compareNumbersAsStrings = (a, b) => {
    const retval =
        parseFloat(typeof a === 'string' ? a.replace(/\s+/g, '') : a) -
        parseFloat(typeof b === 'string' ? b.replace(/\s+/g, '') : b);
    return !Number.isNaN(retval) ? retval : 0;
};

/**
 * Detects types and compares values of strings, numbers and dates.
 * @memberof Comparator
 * @param {string|number} a
 * @param {string|number} b
 * @returns {number}
 */
const compareValuesAsStrings = (a, b) => {
    if (isNaN(parseInt(a)) || isNaN(parseInt(b))) {
        return compareStrings(a, b);
    }

    const dateA = makeDateFromString(`${a} 00:00:00`);
    const dateB = makeDateFromString(`${b} 00:00:00`);
    if (dateA instanceof Date && dateB instanceof Date) {
        return compareDates(dateA, dateB);
    }

    return compareNumbersAsStrings(a, b);
};

export {
    compareStrings,
    compareNumbersAsStrings,
    compareDates,
    compareValuesAsStrings,
};

import { UIElement } from '../ui-element.js';
import { eventBus } from '../../global/event-bus.js';
import { debounce } from '../../global/helpers.js';
import {
    handleMenuOnResize,
    handleTooltipsOnResize,
    mainMenuOverlayHandler,
    modalOverlayHandler,
} from './overlay-handlers.js';
import { MessageType } from './message-type.js';
import { browser } from '../../global/browser.js';

/**
 * @memberof SharedComponents
 * @class
 * @element ui-iframe-controller
 * @alias UIIFrameController
 * @augments UIElement
 * @classdesc Represents a class for <code>ui-iframe-controller</code> element.
 * Watches the resizing of the iframe embedded viewport and listen messages.
 * @property {string} rootSelector {@attr root-selector}
 * @property {string} [mainMenuSelector="#nav-main"] {@attr main-menu-selector}
 * @property {string} [targetOrigin='*'] {@attr target-origin} Origin which should receive
 * @property {boolean} menuOpened Is menu opened.
 * @property {boolean} popoverOpened Is popover opened.
 * the message, by default it '*' any origin.
 * @property {HTMLElement | null} root {@readonly} Parent element where all content is placed.
 * @example
 * <ui-iframe-controller
 *   root-selector=".any-wrapper"
 *   target-origin="https://example.org"
 * ></ui-iframe-controller>
 */
class UIIFrameController extends UIElement {
    constructor() {
        super();
        /** @public */
        this.menuOpened = false;
        /** @public */
        this.popoverOpened = false;

        const compStyles = window.getComputedStyle(document.documentElement);
        this._currentContentHeight = compStyles.getPropertyValue(
            '--ui-content-height'
        );
        this._currentTop = window.scrollY;
        this._currentTopOffset = compStyles.getPropertyValue(
            '--ui-viewport-top-offset'
        );
        this._currentBottom = compStyles.getPropertyValue(
            '--ui-content-bottom'
        );
    }
    /**
     * @type {IProps}
     */
    static get props() {
        return {
            attributes: {
                targetOrigin: {
                    type: String,
                    default: '*',
                },
                rootSelector: String,
                mainMenuSelector: {
                    type: String,
                    default: '#nav-main',
                },
            },
        };
    }

    /**
     * Magic number for delay, usually 3 ideal frames (at 60 fps) is enough to reflow.
     * But on slower CPUs this might still cause reflow delay.
     * @type {number}
     */
    static get DebounceTime() {
        return 16.6667 * 3;
    }

    /**
     * Magic offset for non ui-modal popover to add extra space for height to cover
     * extra difference like filter shadows.
     * @type {number}
     */
    static get PopoverOffset() {
        return 10;
    }

    /**
     * @type {Array<string>}
     */
    static get WatchedEvents() {
        return [
            'modal-updatelist',
            'modal-open-enter',
            'modal-close-enter',
            'menu-open',
            'menu-close',
            'menu-toggle',
            'popover-updated',
        ];
    }

    /**
     * @type {Array<string>}
     */
    static get NavContainers() {
        return [
            '.ui-nav__items',
            '.ui-nav__columns.-primary.-on',
            '.ui-nav__columns.-secondary.-on',
            '.ui-nav__columns.-auxiliary.-on',
            '[class*="__popover"]',
        ];
    }

    get root() {
        return document.querySelector(this.rootSelector);
    }

    /**
     * @param {CustomEvent | Event} e
     * @private
     */
    async openPopoverHandler(e) {
        this.handleOverlay(e);
        this.handleHeight(e);
    }

    /**
     * @returns {UINav | Element | null}
     */
    getMainMenu() {
        return document.querySelector(this.mainMenuSelector);
    }

    /**
     * Handles different cases when overlay should be shown.
     * @param {CustomEvent | Event} e
     */
    handleOverlay(e) {
        // 1. Is menu is opened / closed
        mainMenuOverlayHandler(this, e);

        // 2. Modals are opened as fullscreen and popovers.
        modalOverlayHandler(this, e);
    }

    /**
     * @returns {boolean}
     */
    hasLastOverlay() {
        return this.modalController.getActiveFullViewModals().length !== 0;
    }

    /**
     * @param {string} [type]
     * @param {OverlayPayload} payload
     * @private
     */
    sendOverlayMessage(type = MessageType.OverlayClosed, payload) {
        this.postMessage({ type, payload });
    }

    /**
     * Updates current height.
     * @param {CustomEvent | Event} [e]
     * @param {object} [data]
     */
    handleHeight(e, data) {
        const isFixedLayout =
            !browser.desktopMediaMatches() &&
            !!e?.target.closest(
                'ui-modal.-position-fixed, ui-modal.-navtoggle, ui-navtoggle, ui-nav'
            );
        if (e && isFixedLayout) {
            return;
        }

        const height = this.getHighest();
        const toChange = this._currentHeight !== height || data;

        if (toChange) {
            this._currentHeight = height;
            this.setIframeHeight(this._currentHeight);
        }
    }

    /**
     * @returns {number}
     */
    getHighest() {
        const elems = [this.root, ...this.getAllPopovers()];
        return elems.reduce((prev, next) => {
            const bs = next.getBoundingClientRect();
            let h = bs.height + bs.top;
            if (
                !['ui-modal', 'ui-tooltip'].includes(
                    next.nodeName.toLowerCase()
                )
            ) {
                h += this.constructor.PopoverOffset;
            }
            return Math.max(prev, h, document.body.scrollHeight);
        }, 0);
    }

    /**
     * Handles when outside click from postMessages received
     * @private
     */
    onClickReceived() {
        const isDesktop = browser.desktopMediaMatches();

        // 1. Menu
        const mainMenu = this.getMainMenu();
        if (isDesktop) {
            mainMenu.close();
        } else {
            eventBus.dispatchCustomEvent('menu-toggle', { active: false });
        }

        // 2. Nav-toggles
        if (!isDesktop) {
            eventBus.dispatchCustomEvent('navtoggle-closed');
        }

        // 3 .Modals
        const modal = this.modalController.getActiveTopModalWindow();
        modal?.close();

        // 4. All other cases
        document.dispatchEvent(new PointerEvent('click'));
    }

    /**
     * @param {number} height
     */
    setIframeHeight(height) {
        this.postMessage({
            type: MessageType.HeightChanged,
            payload: height,
        });
    }

    /**
     * @param {IframeMessage} message
     * @private
     */
    postMessage(message) {
        window.parent.postMessage(message, this.targetOrigin);
    }

    /**
     * @returns {Array<HTMLElement>}
     */
    getAllPopovers() {
        let elems = [];
        if (this.modalController) {
            elems = elems.concat([...this.modalController.getActiveModals()]);
        }
        const navs = document.querySelectorAll(
            this.constructor.NavContainers.join(',')
        );
        elems = elems.concat([...navs]);
        return elems;
    }

    /**
     * @param {MessageEvent} e
     */
    async onMessageHandler(e) {
        /** @type {IframeMessage<LayoutPayload | object>} */
        const data = e.data;
        // There always should be a type.
        if (!data?.type) {
            return;
        }

        if (this.allowedOrigin && e.origin !== this.allowedOrigin) {
            console.error(
                `${e.origin} is forbidden check allowed-origin attribute`
            );
            return;
        }

        switch (data.type) {
            case MessageType.LayoutUpdate:
                this.handleHeight(null, data);
                break;
            case MessageType.PopoverUpdate:
                this.onPopoverUpdate(data.payload);
                break;
            case MessageType.Click:
                this.onClickReceived();
                break;
        }
    }

    /**
     * Handles layout update
     * @param {LayoutPayload} payload
     * @private
     */
    onPopoverUpdate(payload) {
        if (!payload) {
            return;
        }

        const top = payload.top + 'px';
        const topOffset = payload.topOffset + 'px';
        const contentHeight = payload.height + 'px';
        const bottom = payload.bottom + 'px';

        if (this._currentTop !== top) {
            this._currentTop = top;
            document.documentElement.style.setProperty(
                '--ui-viewport-top',
                top
            );
        }

        if (this._currentTopOffset !== topOffset) {
            this._currentTopOffset = topOffset;
            document.documentElement.style.setProperty(
                '--ui-viewport-top-offset',
                topOffset
            );
        }

        if (this._currentContentHeight !== contentHeight) {
            this._currentContentHeight = contentHeight;
            document.documentElement.style.setProperty(
                '--ui-content-height',
                contentHeight
            );
        }

        if (this._currentBottom !== bottom) {
            this._currentBottom = bottom;
            document.documentElement.style.setProperty(
                '--ui-content-bottom',
                bottom
            );
        }
    }

    /**
     * @param {Array<ResizeObserverEntry>} entries
     * @private
     */
    observeResizeHandler(entries) {
        for (const entry of entries) {
            entry.contentRect && this.handleHeight();
        }
    }

    /**
     * @inheritDoc
     */
    render() {}

    /**
     * @inheritDoc
     */
    hydrate() {
        /** @type {UINav} */
        const mainMenu = this.getMainMenu();
        /** @type {UIModalController} */
        this.modalController = document.querySelector('ui-modal-controller');
        if (!this.modalController) {
            console.warn(
                `${this.constructor.name}: ui-modal-controller not found`
            );
        }
        if (!this.root) {
            this.constructor.DEBUG_MODE &&
                console.error(
                    `${this.constructor.name}: root element ${this.rootSelector} doesn't exists!`
                );
            return;
        }
        this.setIframeHeight(this.getHighest());
        // For some cases like height it might require debounce() GUI-2466.
        this.popoverHandler = this.openPopoverHandler.bind(this);
        this.resizeHandler = debounce(
            this.observeResizeHandler.bind(this),
            this.constructor.DebounceTime
        );
        this.resizeObserver = new ResizeObserver(this.resizeHandler);
        this.resizeObserver.observe(this.root);
        this.constructor.WatchedEvents.forEach((name) => {
            eventBus.addEventListener(name, this.popoverHandler);
        });
        mainMenu?.mobileMediaQueryList.addListener((event) => {
            handleMenuOnResize(this, event);
        });
        browser.mobileMedia.addListener((event) => {
            handleTooltipsOnResize(this, event);
        });

        this.onMessageHandler = this.onMessageHandler.bind(this);
        window.addEventListener('message', this.onMessageHandler);
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        window.removeEventListener('message', this.onMessageHandler);
        this.resizeObserver.disconnect();
        this.constructor.WatchedEvents.forEach((name) => {
            eventBus.removeEventListener(name, this.popoverHandler);
        });
        window.removeEventListener('message', this.onMessageHandler);
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        window.addEventListener('message', this.onMessageHandler);
        this.resizeObserver.observe(document.body);
        this.constructor.WatchedEvents.forEach((name) => {
            eventBus.addEventListener(name, this.popoverHandler);
        });
        window.addEventListener('message', this.onMessageHandler);
    }
}

UIIFrameController.defineElement('ui-iframe-controller');
export { UIIFrameController };

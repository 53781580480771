import { UIElement } from '../ui-element.js';
import { addLeadingZero, getDaysInMonth } from '../../global/helpers.js';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @classdesc Abstract class for date elements. Holds date and methods to
 * work with it. Usually used in combination with other date-related elements.
 * @alias UIDateElement
 * @property {string} max {@attr max} Maximum date value. String like in format
 * '(-|+)N(m|d|y)'. Where N is integer for offset. 'd' - modifier day,
 * 'm' - modifier for month, 'y' - modifier for years.
 * @property {string} min {@attr min} Minimum date value. String like in format
 * '(-|+)N(m|d|y)'. Where N is integer for offset. 'd' - modifier day,
 * 'm' - modifier for month, 'y' - modifier for years.
 * @property {Locale} [locale="en"] {@attr locale} Localization rules.
 * @property {HTMLElement} control {@readonly}
 * @property {string} value {@readonly}
 */
class UIDateElement extends UIElement {
    static get PREDEFINED_DATES() {
        return {
            tomorrow: '+1d',
            yesterday: '-1d',
            today: '0d',
        };
    }

    /**
     * Types of input.
     * @type {{MONTH: string, YEAR: string, TIME: string}}
     */
    static get VALUE_TYPES() {
        return {
            YEAR: 'year',
            MONTH: 'month',
            TIME: 'time',
        };
    }

    /**
     * Gets Monday date of a week of a input date.
     * @param {Date} date
     * @returns {Date}
     */
    static getMonday(date) {
        const diff =
            date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    }

    /**
     * Gets the 1st of January of last year.
     * @param {Date} date
     * @returns {{start: Date, end: Date}}
     */
    static getLastYearPeriod(date) {
        const lastYear = date.getFullYear() - 1;
        return {
            start: new Date(lastYear, 0, 1),
            end: new Date(lastYear, 11, 31),
        };
    }

    get valueType() {
        return UIDateElement.VALUE_TYPES.YEAR;
    }

    get hours12RegExp() {
        return /^(1[0-2]|[1-9]):([0-5][0-9])\s*([apAP][mM])$/;
    }

    get hours24RegExp() {
        return /^((([0-1]?[0-9]|2[0-3]):([0-5][0-9]))|(24:00))$/;
    }

    get dateRegExp() {
        if (this.locale === 'lt') {
            return /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
        }
        return /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/;
    }

    get monthRegExp() {
        return /^[0-9]{4}-[0-9]{1,2}$/;
    }

    /**
     * Regular expression to parse date difference like '-4d', '+3d'.
     * @returns {RegExp}
     */
    get dayOffsetRegExp() {
        return /^([-+]?)(\d+)([dmy])$/;
    }

    get max() {
        const value = this.control.getAttribute('max');
        return this.isValidValue(value) ? value : null;
    }

    set max(value) {
        if (!this.control || !this.isValidValue(value)) {
            return;
        }
        this.control.setAttribute('max', value);
    }

    get min() {
        const value = this.control.getAttribute('min');
        return this.isValidValue(value) ? value : null;
    }
    set min(value) {
        if (!this.control || !this.isValidValue(value)) {
            return;
        }
        this.control.setAttribute('min', value);
    }

    get locale() {
        if (this.hasAttribute('locale')) {
            return this.getAttribute('locale');
        }
        return UIElement.LOCALE;
    }
    set locale(value) {
        this.setAttribute('locale', value);
    }

    get _timestamp() {
        if (this.isValidValue(this.value)) {
            return this.dateStringToTimestamp(this.value);
        }
        return NaN;
    }

    /**
     * Used in date-range like elements to synchronize the controls.
     */
    syncControls() {}

    /**
     * Parses the string like in format '(-|+)N(m|d|y)'. Where N is integer for offset.
     * 'd' - modifier day, 'm' - modifier for month, 'y' - modifier for years.
     * @param {string} value
     * @returns {{value: number, part: string} | null}
     */
    parseDateOffset(value) {
        const result = String(value).match(this.dayOffsetRegExp);
        if (result) {
            const part = result[3];
            const val = parseInt(result[2]);
            if (Number.isNaN(val)) {
                return null;
            }
            return {
                value: parseInt(result[1] === '-' ? -val : val), // Get rid of negative zero.
                part: part,
            };
        }
        return null;
    }

    /**
     * Checks if given string matched to validation rules for time value
     * @param {string} value
     * @returns {boolean}
     */
    isValidTime(value) {
        return this.hours24RegExp.test(value) || this.hours12RegExp.test(value);
    }

    /**
     * Checks if given string matched to validation rules for date value
     * @param {string} value
     * @param {boolean} [allowEmpty]
     * @returns {boolean}
     */
    isValidDate(value, allowEmpty = false) {
        if (['today', 'yesterday', 'tomorrow'].indexOf(value) > -1) {
            return true;
        }
        if (this.dayOffsetRegExp.test(value)) {
            return true;
        }
        if (allowEmpty && !value) {
            return true;
        }
        return this.dateRegExp.test(value);
    }

    /**
     * Checks if given string matched to validation rules for month value
     * @param {string} value
     * @returns {boolean}
     */
    isValidMonth(value) {
        return this.monthRegExp.test(value);
    }

    /**
     * Checks if given value belongs to date range
     * @param {Date} date
     * @returns {boolean}
     */
    isDateInRange(date) {
        const minDateValue = this.getMinDate();
        const maxDateValue = this.getMaxDate();
        if (minDateValue && minDateValue > date) {
            return false;
        }
        return !(maxDateValue && maxDateValue < date);
    }

    /**
     * Parse date string based on locale
     * @param {string} value
     * @returns {object}
     */
    parseDateString(value) {
        const date = new Date();
        let m;
        let y;
        let daysInMonth;

        if (UIDateElement.PREDEFINED_DATES.hasOwnProperty(value)) {
            value = UIDateElement.PREDEFINED_DATES[value];
        }

        const offset = this.parseDateOffset(value);
        if (offset) {
            switch (offset.part) {
                case 'y':
                    date.setFullYear(date.getFullYear() + offset.value);
                    break;
                case 'm':
                    // This is JS Date() object bug. Check for days count manually.
                    // When changing the month if the previous days count less than current month
                    // current month is selected.
                    m = date.getMonth() + offset.value;
                    y = date.getFullYear();
                    daysInMonth = getDaysInMonth(y, m);
                    if (date.getDate() > daysInMonth) {
                        date.setDate(daysInMonth);
                    }
                    date.setMonth(m);
                    break;
                default:
                    date.setDate(date.getDate() + offset.value);
                    break;
            }

            return {
                date: date.getDate(),
                month: date.getMonth() + 1,
                year: date.getFullYear(),
            };
        }

        const sep =
            this.locale === 'lt' ||
            this.valueType === UIDateElement.VALUE_TYPES.MONTH
                ? '-'
                : '.';
        const parts = value.split(sep).map(Number);

        if (
            this.locale === 'lt' ||
            this.valueType === UIDateElement.VALUE_TYPES.MONTH
        ) {
            return {
                date: parts[2],
                month: parts[1],
                year: parts[0],
            };
        }

        return {
            date: parts[0],
            month: parts[1],
            year: parts[2],
        };
    }

    /**
     * Convert to date string based on locale.
     * @param {number} date
     * @param {number} month
     * @param {number} year
     * @returns {string}
     */
    formatDateString(date, month, year) {
        const retval = [year, addLeadingZero(month)];
        if (date) {
            retval.push(addLeadingZero(date));
        }
        if (this.locale === 'lt') {
            return retval.join('-');
        }
        retval.reverse();
        return retval.join('.');
    }

    /**
     * Converts to date string from UNIX timestamp.
     * @param {number} ts
     * @returns {string}
     */
    timestampToDateString(ts) {
        const d = new Date(ts);
        return this.formatDateString(
            d.getDate(),
            d.getMonth() + 1,
            d.getFullYear()
        );
    }

    /**
     * Converts string date to timestamp.
     * @param {string} dateString
     * @returns {number}
     */
    dateStringToTimestamp(dateString) {
        if (!this.isValidDate(dateString)) {
            return 0;
        }
        const parts = this.parseDateString(dateString);
        const date = new Date(
            parts.year,
            parts.month - 1,
            parts.date,
            0,
            0,
            0,
            0
        );
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return date.valueOf() - userTimezoneOffset;
    }

    /**
     * Converts string date to timestamp using UTC date.
     * @param {string} dateString
     * @returns {number}
     */
    dateStringToTimestampUTC(dateString) {
        if (!this.isValidDate(dateString)) {
            return 0;
        }
        const parts = this.parseDateString(dateString);
        return new Date(
            Date.UTC(parts.year, parts.month - 1, parts.date, 0, 0, 0, 0)
        ).getTime();
    }

    /**
     * Reformat given value, converts constants like 'today/tomorrow/yesterday' to real dates.
     * @param {string} str
     * @returns {string}
     */
    reformatDateString(str) {
        if (!str) {
            return '';
        }
        const value = this.parseDateString(str);
        return this.formatDateString(value.date, value.month, value.year);
    }

    /**
     * Convert to date string based on locale.
     * @param {number} month
     * @param {number} year
     * @returns {string}
     */
    formatMonthString(month, year) {
        return [year, addLeadingZero(month)].join('-');
    }

    /**
     * Returns the min date in Date-object representation
     * with hours, minutes, seconds and milliseconds set to zero.
     * @returns {Date} date
     */
    getMinDate() {
        if (!this.min) {
            return null;
        }
        const parts = this.parseDateString(this.min);
        return new Date(parts.year, parts.month - 1, parts.date);
    }

    /**
     * Returns the max date in Date-object representation
     * with hours, minutes, seconds and milliseconds set to zero.
     * @returns {Date} date
     */
    getMaxDate() {
        if (!this.max) {
            return null;
        }
        const parts = this.parseDateString(this.max);
        return new Date(parts.year, parts.month - 1, parts.date);
    }

    /**
     * Parse date string and shift values to be in range if needed.
     * @param {string} value
     * @param {string} min
     * @param {string} max
     * @returns {object}
     */
    parseDateValue(value, min, max) {
        const parts = this.parseDateString(
            this.isValidDate(value) ? value : 'today'
        );

        let dayValue = parts.date;
        let monthValue = parts.month;
        let yearValue = parts.year;

        if (min) {
            const minParts = this.parseDateString(min);
            const minDayValue = minParts.date;
            const minMonthValue = minParts.month;
            const minYearValue = minParts.year;
            if (
                yearValue < minYearValue ||
                (yearValue === minYearValue && monthValue < minMonthValue) ||
                (yearValue === minYearValue &&
                    monthValue === minMonthValue &&
                    dayValue < minDayValue)
            ) {
                dayValue = minDayValue;
                yearValue = minYearValue;
                monthValue = minMonthValue;
            }
        }

        if (max) {
            const maxParts = this.parseDateString(max);
            const maxDayValue = maxParts.date;
            const maxMonthValue = maxParts.month;
            const maxYearValue = maxParts.year;
            if (
                yearValue > maxYearValue ||
                (yearValue === maxYearValue && monthValue > maxMonthValue) ||
                (yearValue === maxYearValue &&
                    monthValue === maxMonthValue &&
                    dayValue > maxDayValue)
            ) {
                dayValue = maxDayValue;
                yearValue = maxYearValue;
                monthValue = maxMonthValue;
            }
        }

        return {
            value: this.formatDateString(
                dayValue < 10 ? '0' + dayValue : dayValue,
                monthValue < 10 ? '0' + monthValue : monthValue,
                yearValue
            ),
            year: yearValue,
            month: monthValue,
            day: dayValue,
        };
    }

    /**
     * Parse month string and shift values to be in range if needed.
     * @param {string} value
     * @param {string} min
     * @param {string} max
     * @returns {object}
     */
    parseMonthValue(value, min, max) {
        let values;
        if (!this.isValidMonth(value)) {
            const date = new Date();
            values = [date.getFullYear(), date.getMonth() + 1];
        } else {
            values = value.split('-').map(Number);
        }

        let yearValue = values[0];
        let monthValue = values[1];

        if (min) {
            const minParts = min.split('-').map(Number);
            const minYearValue = minParts[0];
            const minMonthValue = minParts[1];
            if (
                yearValue < minYearValue ||
                (yearValue === minYearValue && monthValue < minMonthValue)
            ) {
                yearValue = minYearValue;
                monthValue = minMonthValue;
            }
        }

        if (max) {
            const maxParts = max.split('-').map(Number);
            const maxYearValue = maxParts[0];
            const maxMonthValue = maxParts[1];
            if (
                yearValue > maxYearValue ||
                (yearValue === maxYearValue && monthValue > maxMonthValue)
            ) {
                yearValue = maxYearValue;
                monthValue = maxMonthValue;
            }
        }

        return {
            value: [
                yearValue,
                monthValue < 10 ? addLeadingZero(monthValue) : monthValue,
            ].join('-'),
            year: yearValue,
            month: monthValue,
        };
    }

    /**
     * Parse time string and shift values to be in range if needed.
     * @param {string} value
     * @param {string} min
     * @param {string} max
     * @returns {object}
     */
    parseTimeValue(value, min, max) {
        let values;
        if (!this.isValidTime(value)) {
            values = [0, 0];
        } else {
            values = this.convertTimeTo24Hours(value).split(':');
        }

        let hoursVal = Number(values[0]);
        let minutesVal = Number(values[1]);

        if (min) {
            const minParts = min.split(':');
            const minHoursVal = Number(minParts[0]);
            const minMinutesVal = Number(minParts[1]);
            if (
                hoursVal < minHoursVal ||
                (hoursVal === minHoursVal && minutesVal < minMinutesVal)
            ) {
                hoursVal = minHoursVal;
                minutesVal = minMinutesVal;
            }
        }

        if (max) {
            const maxParts = max.split(':');
            const maxHoursVal = Number(maxParts[0]);
            const maxMinutesVal = Number(maxParts[1]);
            if (
                hoursVal > maxHoursVal ||
                (hoursVal === maxHoursVal && minutesVal > maxMinutesVal)
            ) {
                hoursVal = maxHoursVal;
                minutesVal = maxMinutesVal;
            }
        }

        return {
            value: [
                hoursVal < 10 ? '0' + hoursVal : hoursVal,
                minutesVal < 10 ? '0' + minutesVal : minutesVal,
            ].join(':'),
            hours: hoursVal,
            minutes: minutesVal,
        };
    }

    /**
     * Validate control value based on value type.
     * @param {string} value
     * @returns {boolean}
     */
    isValidValue(value) {
        switch (this.valueType) {
            case UIDateElement.VALUE_TYPES.TIME:
                return this.isValidTime(value);
            case UIDateElement.VALUE_TYPES.MONTH:
                return this.isValidMonth(value);
            case UIDateElement.VALUE_TYPES.YEAR:
            default:
                return this.isValidDate(value);
        }
    }

    /**
     * Converts value to 24-hours format.
     * @param {string} value
     * @returns {string}
     */
    convertTimeTo24Hours(value) {
        if (this.hours24RegExp.test(value)) {
            return value;
        }

        if (!this.hours12RegExp.test(value)) {
            return '00:00';
        }
        const matches = value.match(this.hours12RegExp);
        let hours = Number(matches[1]);
        const minutes = Number(matches[2]);
        const modifier = matches[3].toLowerCase();
        if (modifier === 'am' && hours === 12) {
            hours = 0;
        }
        if (modifier === 'pm' && hours < 12) {
            hours += 12;
        }
        return [
            hours < 10 ? '0' + hours : hours,
            minutes < 10 ? '0' + minutes : minutes,
        ].join(':');
    }
}

export { UIDateElement };

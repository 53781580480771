import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import { makeDateFromString } from '../../global/helpers.js';
import { setInnerText } from '../../global/ui-helpers.js';
import { browser } from '../../global/browser.js';
import styles from './ui-countdown.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UICountdown
 * @element ui-countdown
 * @classdesc Represents a class for <code>ui-countdown</code> element.
 * Makes a counter with counts down every second until given date.
 * @property {string} expired {@attr expired} When deadline will happen.
 * Datetime in format 'dd.mm.yyyy hh:mm:ss' or 'yyyy-mm-dd hh:mm:ss' for
 * Lithuania.
 * @property {string} [format="dd:hh:mm:ss"] {@attr format} Countdown format.
 * @example
 * <ui-countdown expired="02.01.2020 00:00:00"></ui-countdown>
 */
class UICountdown extends UIElement {
    static get SECOND() {
        return 1000;
    }

    /**
     * Provides list of observed attributes to be watched.
     * @type {Array<string>}
     */
    static get observedAttributes() {
        return ['expired'];
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-countdown', {
            days: 'Days',
            hours: 'Hours',
            minutes: 'Minutes',
            seconds: 'Seconds',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                expired: String,
                format: { type: String, default: 'dd:hh:mm:ss' },
            },
        };
    }

    /**
     * Converts seconds to days, hours, minutes, seconds with leading zeros.
     * @returns {CountdownDateParts}
     * @private
     */
    timeAsStr() {
        // If more than 100 days then return 99 for everything to avoid grid overflow.
        if (this.remainingSecs > 86400 * 100) {
            return {
                days: '99',
                hours: '23',
                minutes: '59',
                seconds: '59',
            };
        }

        if (this.remainingSecs <= 0) {
            return {
                days: '00',
                hours: '00',
                minutes: '00',
                seconds: '00',
            };
        }

        const format = (f) => String(f).padStart(2, '0');
        const parts = {};
        let delta = this.remainingSecs;
        parts.days = format(Math.floor(delta / 86400));
        delta -= parts.days * 86400;
        parts.hours = format(Math.floor(delta / 3600) % 24);
        delta -= parts.hours * 3600;
        parts.minutes = format(Math.floor(delta / 60) % 60);
        delta -= parts.minutes * 60;
        parts.seconds = format(Math.floor(delta % 60));
        return parts;
    }

    /**
     * Ticks every second.
     * @private
     */
    tick() {
        const deadline = makeDateFromString(this.expired) || new Date();
        const expiredSecs = deadline.getTime();
        const nowSecs = new Date().getTime();
        this.remainingSecs =
            Math.floor(expiredSecs - nowSecs) / UICountdown.SECOND;
    }

    /**
     * Parses the format and returns its parts.
     * @returns {Array<string>}
     * @private
     */
    parseFormat() {
        return this.format.split(':');
    }

    /**
     * Maps format to labels.
     * @type {{s: string, d: string, h: string, m: string}}
     * @private
     */
    get mapFormat() {
        return {
            d: 'days',
            h: 'hours',
            m: 'minutes',
            s: 'seconds',
        };
    }

    /**
     * Set the interval for countdown element.
     * @returns {number}
     * @private
     */
    setClock() {
        clearInterval(this.clockId);
        const ticker = () => {
            this.tick();
            const parts = this.timeAsStr();
            Object.keys(parts).forEach((key) => {
                this.querySelectorAll(`[data-${key}]`).forEach((el) =>
                    setInnerText(el.querySelector('span'), parts[key])
                );
            });
            if (this.remainingSecs <= 0) {
                clearInterval(this.clockId);
            }
        };
        ticker(); // call first time immediately.
        if (!browser.prefersReducedMotion()) {
            return setInterval(ticker, UICountdown.SECOND);
        } else {
            return -1;
        }
    }

    /**
     * Starts the clock.
     */
    startClock() {
        this.tick();
        this.clockId = this.setClock();
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'expired':
                this.startClock();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const mapFormat = this.parseFormat().map((f) => this.mapFormat[f[0]]);
        this.insertElements([
            {
                tagName: 'div',
                attributes: {
                    role: 'group',
                },
                classList: {
                    'ui-countdown__inner': true,
                },
                children: Object.keys(mapFormat).map((key, i) => {
                    const tag = this.createElement({
                        tagName: 'div',
                        classList: {
                            'ui-countdown__time': true,
                        },
                        attributes: {
                            role: 'group',
                            'aria-label': UICountdown.labels[mapFormat[i]],
                            ['data-' + mapFormat[i]]: 'true',
                        },
                        children: [
                            {
                                tagName: 'span',
                            },
                            {
                                tagName: 'ins',
                                attributes: {
                                    'aria-hidden': true,
                                },
                                classList: {
                                    'ui-countdown__separator': true,
                                },
                                children: [':'],
                            },
                            {
                                tagName: 'ins',
                                classList: {
                                    'ui-countdown__label': true,
                                },
                                attributes: {
                                    'aria-hidden': 'true',
                                },
                                children: [UICountdown.labels[mapFormat[i]]],
                            },
                        ],
                    });
                    setInnerText(tag.querySelector('span'), '&nbsp;', true);
                    return tag;
                }),
            },
        ]);
        this.updateElement({
            attributes: {
                role: 'timer',
            },
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.remainingSecs = 0;
        if (!this.expired) {
            console.warn(
                this.constructor.name +
                    ': used without attribute element. Zero value initialized.'
            );
            return;
        }
        this.startClock();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        clearInterval(this.clockId);
    }
}

UICountdown.defineElement('ui-countdown', styles);
export { UICountdown };

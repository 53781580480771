import '../icon/ui-icon.js';
import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import { scrollTo } from '../../global/helpers.js';
import {
    closestByCond,
    insertElements,
    createElement,
} from '../../global/ui-helpers.js';
import { keyCodes, TabIndex } from '../../global/keyboard.js';
import { Digest } from '../../global/digest.js';
import styles from './ui-curtain.css';

/**
 * @memberof SharedComponents
 * @implements {IActivatable}
 * @augments {UIElement}
 * @classdesc Represents a class for <code>ui-curtain</code> element.
 * Curtain is opening section with content. Layout 'spoiler'
 * can be without container ui-curtains.
 * @fires event:curtain-toggle
 * @fires event:curtain-open
 * @property {("default" | "spoiler" | "dark")} layout {@attr layout} Type of layout.
 *  {@desc spoiler}
 *  {@desc dark}
 * @property {boolean} [limited=false] {@attr limited} Deprecated: If true, curtain will not
 * be active. This property will be removed in next releases.
 * @property {boolean} [mobile] {@attr mobile} Curtain is shown only in smaller screen view.
 * @property {string} [label] {@attr label} Label of curtain.
 * @property {string} [labelOpen] {@attr label-open} Label of open button,
 * used in layout="spoiler".
 * @property {string} [labelClose] {@attr label-close} Label of close button,
 * used in layout="spoiler".
 * @property {boolean} [open=false] {@attr open} If true, curtain will be opened,
 * close otherwise.
 * @property {HTMLDivElement} content {@readonly} Shortcut to curtain's body element.
 * @property {HTMLDivElement} head {@readonly} Shortcut to curtain's of head element.
 * @property {HTMLDivElement} headContent {@readonly} Shortcut to curtain's of head-content element.
 * @property {HTMLButtonElement} expanderButton {@readonly} Shortcut to expander button.
 * @alias UICurtain
 * @element ui-curtain
 * @slot
 * @example
 * <ui-curtain label="Open me">
 *   <p>Very intersting content</p>
 * </ui-curtain>
 */
class UICurtain extends UIElement {
    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-curtain', {
            showLess: 'Show less',
            showMore: 'Show more',
        });
    }

    /**
     * Layout type of curtains.
     * @type {{SPOILER: string, DEFAULT: string}}
     */
    static get LAYOUTS() {
        return {
            DEFAULT: 'default',
            SPOILER: 'spoiler',
        };
    }

    /**
     * Provides list of observed attributes to be watched
     */
    static get observedAttributes() {
        return [
            'layout',
            'label',
            'label-open',
            'label-close',
            'open',
            'limited',
        ];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: String,
                label: String,
                mobile: String,
                labelOpen: String,
                labelClose: String,
                limited: {
                    type: Boolean,
                    validate: /** @type {IAttributeValidationHandler} */ (
                        component
                    ) => {
                        console.warn(
                            `limited - attribute is deprecated.`,
                            component
                        );
                    },
                },
                completed: Boolean,
            },
            children: {
                expanderButton: 'button.ui-curtain__head-expander',
                head: '.ui-curtain__head',
                headContent: '.ui-curtain__head-content',
                content: '.ui-curtain__body-content',
            },
        };
    }

    /**
     * @returns {HTMLElement}
     */
    get toggle() {
        return this.querySelector(
            this.layout === UICurtain.LAYOUTS.SPOILER
                ? 'ui-curtain[layout="spoiler"] > .ui-curtain__head .ui-curtain__head-content'
                : 'ui-curtain > .ui-curtain__head'
        );
    }

    get open() {
        if (!this.hasAttribute('open')) {
            return false;
        }
        const value = this.getAttribute('open');
        return value === '' || value === 'open' || this.parseBooleanAttr(value);
    }
    set open(value) {
        if (value) {
            this.setAttribute('open', '');
            if (this.isAnimated()) {
                this.slideAnimation('down').onfinish = () => {
                    this.dispatchCustomEvent('curtain-open');
                };
            }
        } else {
            this.removeAttribute('open');
            if (this.isAnimated()) {
                this.slideAnimation('up').onfinish = () => {
                    this.handleFinishAnimation();
                };
            }
        }
    }

    /**
     * Get or create curtain head element.
     * @returns {Element}
     */
    getCurtainHead() {
        let el = this.querySelector('h3.ui-curtain__head');
        if (!el) {
            el = createElement({
                tagName: 'h3',
                classList: {
                    'ui-curtain__head': true,
                },
            });
            this.insertBefore(el, this.firstChild);
        }
        return el;
    }

    /**
     * Get or create curtain title element.
     * @returns {Element}
     */
    getCurtainTitle() {
        const content = this.getCurtainHeadContent();
        let el = content.querySelector('span.ui-curtain__head-title');
        if (!el) {
            el = createElement({
                tagName: 'span',
                attributes: {
                    id: 'ui-curtain-title-' + this._uuid,
                },
                classList: {
                    'ui-curtain__head-title': true,
                },
            });
            content.appendChild(el);
        }
        return el;
    }

    /**
     * Get or create curtain head content element.
     * @returns {Element}
     */
    getCurtainHeadContent() {
        const head = this.getCurtainHead();
        let el = head.querySelector('div.ui-curtain__head-content');
        if (!el) {
            el = createElement({
                tagName: 'div',
                attributes: {
                    role: 'button',
                    'aria-expanded': this.open ? 'true' : 'false',
                    'aria-disabled': this.limited ? 'true' : 'false',
                },
                classList: {
                    'ui-curtain__head-content': true,
                },
            });
            head.insertBefore(el, head.lastChild);
        }
        return el;
    }

    /**
     * Get text for spoiler title (opened/closed).
     * @returns {string}
     */
    getTitle() {
        if (this.label) {
            return this.label;
        }
        return this.open
            ? this.labelClose || UICurtain.labels.showLess
            : this.labelOpen || UICurtain.labels.showMore;
    }

    /**
     * Get glyph for opening/closing icon.
     * @returns {string}
     */
    getExpanderGlyph() {
        return this.open ? 'up' : 'down';
    }

    /**
     * Get or create complex curtain head content (with a lot of different slots)
     * @returns {Element}
     */
    getComplexHeadContent() {
        const headContent = this.getCurtainHeadContent();
        let wrapper = this.querySelector('ui-columns');

        if (!wrapper) {
            const title = headContent.querySelector(
                'span.ui-curtain__head-title'
            );
            wrapper = document.createElement('ui-columns');

            if (title) {
                headContent.replaceChild(wrapper, title);
                wrapper.appendChild(title);
            }
        }
        return wrapper;
    }

    /**
     * Get or build curtain expander for not limited curtain.
     * @returns {Element}
     */
    buildCurtainExpander() {
        if (this.limited) {
            return null;
        }
        let el = this.expanderButton;
        if (!el) {
            el = createElement({
                tagName: 'button',
                attributes: {
                    type: 'button',
                    'aria-expanded': this.open ? 'true' : 'false',
                    'aria-label': this.hasAttribute('open')
                        ? this.labelClose || UICurtain.labels.showLess
                        : this.labelOpen || UICurtain.labels.showMore,
                },
                classList: {
                    'ui-curtain__head-expander': true,
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: this.getExpanderGlyph(),
                        },
                    },
                ],
            });
            if (this.layout === UICurtain.LAYOUTS.SPOILER) {
                this.getCurtainHeadContent().insertBefore(
                    el,
                    this.getCurtainHeadContent().firstChild
                );
            } else {
                this.getCurtainHead().appendChild(el);
            }
        }
        return el;
    }

    /**
     * Get or build curtain body.
     * @returns {Element}
     */
    buildCurtainBody() {
        let el = this.querySelector('.ui-curtain__body');
        if (!el) {
            el = this.createElement({
                attributes: {
                    role: 'region',
                    'aria-labelledby': 'ui-curtain-title-' + this._uuid,
                },
                tagName: 'div',
                classList: {
                    'ui-curtain__body': true,
                    '-animating': this.open && this.isAnimated(),
                },
            });
            this.appendChild(el);
        }
        return el;
    }

    /**
     * Remove curtain expander
     * @returns {UICurtain}
     */
    destroyCurtainExpander() {
        if (!this.limited) {
            return this;
        }
        if (this.expanderButton) {
            this.expanderButton.remove();
        }
        return this;
    }

    /**
     * Render header for complex layout with &lt;header>&lt;/header>.
     * @param {Array<Element>} children
     */
    renderComplexHeader(children) {
        const wrapper = this.getComplexHeadContent();
        const headContent = this.getCurtainHeadContent();

        if (!children.length) {
            return;
        }

        [].forEach.call(children, (child) => {
            switch (child.tagName.toLowerCase()) {
                case 'ui-icon':
                    headContent.insertBefore(
                        child,
                        this.layout !== UICurtain.LAYOUTS.SPOILER
                            ? headContent.firstChild
                            : wrapper
                    );
                    break;
                case 'ui-amount':
                    headContent.appendChild(child);
                    break;
                default:
                    wrapper.appendChild(child);
                    break;
            }
        });
    }

    /**
     * Render curtain body with content.
     * @param {Array<IElementConfig | HTMLElement | Node | string>} contentNodes
     * @returns {Element}
     */
    renderBody(contentNodes) {
        const body = this.buildCurtainBody();
        const content = this.createElement({
            tagName: 'div',
            classList: {
                'ui-curtain__body-content': true,
            },
            children: contentNodes,
        });

        this.rebuildChildren.call(body, [content]);
        return this.content;
    }

    /**
     * Renders head of the curtain.
     * @returns {UICurtain}
     */
    renderHead() {
        if (this.layout === UICurtain.LAYOUTS.SPOILER) {
            this.renderSpoilerHead();
        }
        this.getCurtainTitle().innerText = this.getTitle();
        if (this.limited) {
            this.headContent.setAttribute('aria-disabled', 'true');
            this.destroyCurtainExpander();
        } else {
            this.headContent.removeAttribute('aria-disabled');
            this.buildCurtainExpander();
        }

        return this;
    }

    /**
     * Renders spoilers header.
     * @returns {UICurtain}
     */
    renderSpoilerHead() {
        const head = this.getCurtainHead();
        const els = head.querySelectorAll('div.ui-curtain__head-decorator');
        if (!els.length) {
            insertElements(head, [
                {
                    tagName: 'div',
                    classList: {
                        'ui-curtain__head-decorator': true,
                    },
                },
                {
                    tagName: 'div',
                    classList: {
                        'ui-curtain__head-decorator': true,
                    },
                },
            ]);
        }
        return this;
    }

    /**
     * Update curtain head
     */
    updateCurtainHead() {
        if (!this.limited) {
            this.expanderButton
                .querySelector('ui-icon')
                .setAttribute('glyph', this.getExpanderGlyph());
            this.expanderButton.setAttribute(
                'aria-label',
                this.open
                    ? this.labelClose || UICurtain.labels.showLess
                    : this.labelOpen || UICurtain.labels.showMore
            );
            this.expanderButton.setAttribute(
                'aria-expanded',
                this.open ? 'true' : 'false'
            );
        }
        this.getCurtainTitle().innerText = this.getTitle();
    }

    /**
     * Disable animation for '(prefers-reduced-motion: reduce)'
     * @returns {boolean}
     */
    isAnimated() {
        return !window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }

    /**
     * Slide up/down curtain animation
     * @param {'up' | 'down'} direction
     * @returns {Animation | undefined}
     */
    slideAnimation(direction) {
        const body = this.querySelector('.ui-curtain__body');
        if (!body) {
            return;
        }
        const height = body.getBoundingClientRect().height + 'px';
        body.classList.add('-animating');

        return body.animate(
            [
                { height: direction === 'up' ? height : '0px', offset: 0 },
                { height: direction === 'down' ? height : '0px', offset: 1 },
            ],
            {
                duration: 200,
                easing: 'ease-in-out',
            }
        );
    }

    /**
     * Handle onfinish animation event
     * @private
     */
    handleFinishAnimation() {
        this.querySelector('.ui-curtain__body').classList.remove('-animating');
    }

    /**
     * Dispatch custom event curtain-toggle if it is not limited
     * @param {Event} e
     * @private
     */
    curtainClickHandler(e) {
        if (e.defaultPrevented && e.type !== 'keydown') {
            return;
        }

        e.preventDefault();
        const link = closestByCond(e.target, (node) => {
            return node.nodeName && node.nodeName.toLowerCase() === 'a';
        });

        const headContent = closestByCond(link, (node) => {
            return (
                node.classList &&
                node.classList.contains('ui-curtain__head-content')
            );
        });

        if (!(link && headContent) && !this.limited) {
            this.open = !this.open;
            this.headContent.setAttribute(
                'aria-expanded',
                this.open ? 'true' : 'false'
            );
            this.dispatchCustomEvent('curtain-toggle', null);
        }
    }

    /**
     * Changes current view to active (open) state.
     * @returns {UICurtain}
     */
    activate() {
        this.open = true;
        return this;
    }

    /**
     * Handles curtains scroll on mobile.
     * @private
     */
    async handleAutoScroll() {
        const isMobile = window.matchMedia(
            'screen and (max-width: 767px)'
        ).matches;
        const hasVerticalScroll =
            document.body.scrollHeight > document.body.clientHeight;
        if (isMobile && hasVerticalScroll && this.open) {
            await scrollTo(this, 0);
        }
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleKeyDown(event) {
        switch (event.keyCode) {
            case keyCodes.ENTER:
            case keyCodes.SPACE:
                event.preventDefault();
                this.curtainClickHandler(event);
                break;
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'label':
            case 'label-open':
            case 'label-close':
            case 'limited':
                this.renderHead();
                break;
            case 'open':
                this.updateCurtainHead();
                break;
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        this._uuid = Digest.randomId();
        const childNodes = this.detachChildNodes();
        const header = childNodes
            .filter(function (node) {
                return node.tagName && node.tagName.toLowerCase() === 'header';
            })
            .shift();
        const section = childNodes
            .filter(function (node) {
                return node.tagName && node.tagName.toLowerCase() === 'section';
            })
            .shift();

        const contentNodes = section
            ? section.childNodes
            : [].filter.call(childNodes, function (node) {
                  return (
                      node.nodeName && node.nodeName.toLowerCase() !== 'header'
                  );
              });

        this.renderHead().renderBody(contentNodes);

        if (header) {
            const headerChildren = [].filter.call(
                header.children,
                function (node) {
                    return node.nodeName;
                }
            );
            this.renderComplexHeader(headerChildren);
        }
        if (
            (this.layout === UICurtain.LAYOUTS.DEFAULT || !this.layout) &&
            !this.limited
        ) {
            if (this.label) {
                this.setAttribute('aria-label', this.label);
            }
            this.head.tabIndex = TabIndex.Active;
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.toggle.addEventListener(
            'click',
            this.curtainClickHandler.bind(this)
        );
        // #a11y
        this.head.addEventListener('keydown', this.handleKeyDown.bind(this));

        const parentCurtains = this.closest('ui-curtains');
        if (parentCurtains && parentCurtains.autoscroll) {
            this.addEventListener('transitionend', async () => {
                await this.handleAutoScroll.call(this);
            });
        }
    }
}

UICurtain.defineElement('ui-curtain', styles);
export { UICurtain };

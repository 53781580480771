/**
 * @memberof SharedComponents
 * @class Browser
 * @alias browser
 */
class Browser {
    constructor() {
        this.mobileMedia = window.matchMedia('(max-width: 767px)');
        this.tabletMedia = window.matchMedia(
            '(min-width: 768px) and (max-width: 1023.98px)'
        );
        this.desktopMedia = window.matchMedia('(min-width: 1024px)');
        this._data = this.getDeviceInfo();
        window.addEventListener('resize', this.handleViewportResize.bind(this));
    }

    /**
     * Checks if the browser support touch
     * @returns {Record<string, boolean>}
     */
    getDeviceInfo() {
        return {
            touch: this.supportTouch(),
            mobile: this.isMobile(),
            reducedMotion: this.prefersReducedMotion(),
        };
    }

    /**
     * Checks if the browser support touch
     * @returns {boolean}
     */
    supportTouch() {
        return Boolean(
            'ontouchstart' in window ||
                'onmsgesturechange' in window ||
                navigator.maxTouchPoints
        );
    }

    /**
     * Check if user has slow or cellular connection
     * Reference for spec:
     * http://wicg.github.io/netinfo/
     * @returns {boolean}
     */
    isSlowOrCellularConnection() {
        const connection =
            navigator.connection ||
            navigator.mozConnection ||
            navigator.webkitConnection;

        if (connection) {
            //
            if (['cellular'].indexOf(connection.type) > -1) {
                return true;
            }

            // effectiveType:  'slow-2g', '2g', '3g', or '4g'.
            if (
                ['slow-2g', '2g', '3g'].indexOf(connection.effectiveType) > -1
            ) {
                return false;
            }
        }
        return false;
    }

    /**
     * Checks if the browser is mobile
     * @returns {boolean}
     */
    isMobile() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i,
        ];
        return toMatch.some((toMatchItem) =>
            navigator.userAgent.match(toMatchItem)
        );
    }

    /**
     * Is desktop screen width
     * @returns {boolean}
     */
    desktopMediaMatches() {
        return this.desktopMedia.matches;
    }

    /**
     * Is tablet screen width
     * @returns {boolean}
     */
    tabletMediaMatches() {
        return this.tabletMedia.matches;
    }

    /**
     * Is mobile screen width
     * @returns {boolean}
     */
    mobileMediaMatches() {
        return this.mobileMedia.matches;
    }

    /**
     * Checks if the user prefers reduced motion,
     * could be used to simplify transitions and animations
     * @returns {boolean}
     */
    prefersReducedMotion() {
        return window.matchMedia('(prefers-reduced-motion: reduce)').matches;
    }

    /**
     * Fires callback when window resized
     * @private
     * @fires event:browser:update
     */
    handleViewportResize() {
        const currentData = this.getDeviceInfo();

        const isChanged =
            this._data.touch !== currentData.touch ||
            this._data.mobile !== currentData.mobile ||
            this._data.reducedMotion !== currentData.reducedMotion;

        if (!isChanged) {
            return;
        }

        this._data = this.getDeviceInfo();

        const event = new CustomEvent('browser:update', {
            bubbles: true,
            detail: currentData,
        });
        document.body.dispatchEvent(event);
    }

    /**
     * Checks that browser supports IntersectionObserver.
     * @returns {boolean}
     */
    supportsIntersectionObserver() {
        return (
            'IntersectionObserver' in window &&
            'IntersectionObserverEntry' in window &&
            'intersectionRatio' in window.IntersectionObserverEntry.prototype
        );
    }

    /**
     * Resolves first level domain from full domain name.
     * @returns {string}
     */
    resolveMainDomain() {
        const hostname = window.location.hostname;
        // check for ip address IP address
        if (hostname.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
            return hostname;
        }

        const match = hostname.match(/[^.]*\.[^.]{2,3}(?:\.[^.])?$/);
        return String(match || hostname);
    }
}

export const browser = new Browser();

/**
 * @memberof Browser
 * @event browser:update
 * @alias event:browser:update
 * @augments {CustomEvent}
 */

import '../buttonbar/ui-buttonbar.js';
import { EventObserver } from '../../global/event-observer.js';
import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import {
    rebuild,
    revertClassList,
    updateClassList,
} from '../../global/ui-helpers.js';
import styles from './ui-wizard.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIWizard
 * @element ui-wizard
 * @classdesc Represents a class for <code>ui-wizard</code> element.
 * Wizard is used to create multi-step forms.
 * @fires event:wizard-next
 * @fires event:wizard-back
 * @property {number} [selectedIndex] {@attr selected-index} Which step is activated.
 * @property {number} [lastActivatedStep] {@attr last-activated-step} Highest activated step.
 * @property {boolean} [animated] {@attr animated} Is animation between steps enabled/disabled.
 * @property {boolean} [external] {@attr external} If UIWizard has external control.
 * @property {string} [labelBack] {@attr label-back} If is set this label will be
 * put to the back button.
 * @property {string} [labelNext] {@attr label-next} If is set this label will be put
 * to the next button.
 * @property {boolean} [standalone] {@attr standalone} Views are visually hidden.
 * @property {boolean} [subNavigation] {@attr sub-navigation} Allows to navigate between passed
 * steps using step captions
 * @property {UIViews} views {@readonly} Shortcut to views.
 * @property {NodeListOf<UIView>} viewList {@readonly} Shortcut to view nodes.
 * @property {UIButtonbar} buttonBar {@readonly} Shortcut to button bar.
 * @property {HTMLButtonElement} nextButton {@readonly} Shortcut to next button.
 * @property {HTMLButtonElement} backButton {@readonly} Shortcut to back button.
 * @slot {@type "ui-view"}
 * @example
 * <ui-wizard>
 *   <ui-view>...</ui-view>
 *   <ui-view>...</ui-view>
 *   <ui-view>...</ui-view>
 * </ui-wizard>
 */
class UIWizard extends UIElement {
    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-wizard', {
            next: 'Next',
            back: 'Back',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                labelBack: String,
                labelNext: String,
                selectedIndex: { type: Number, default: 1 },
                lastActivatedStep: { type: Number, default: 1 },
                animated: Boolean,
                external: Boolean,
                standalone: Boolean,
                subNavigation: Boolean,
            },
            children: {
                buttonBar: 'ui-buttonbar.ui-wizard__nav',
                nextButton: '.ui-wizard__nav button.-next',
                backButton: '.ui-wizard__nav button.-back',
                views: ':scope > ui-views',
                viewList: {
                    selector: ':scope > ui-views > ui-view, :scope > ui-view',
                    multiple: true,
                },
            },
        };
    }

    /**
     * Returns current step index
     * @returns {number}
     */
    getCurrentStep() {
        return this.selectedIndex;
    }

    /**
     * Changes current step index
     * @param {number} [step]
     */
    setCurrentStep(step = 1) {
        this.selectedIndex =
            step > 0 && step <= this.views.children.length ? step : 1;
    }

    /**
     * Checks if the current step is the first one.
     * @returns {boolean}
     */
    isInitialStep() {
        return this.selectedIndex === 1;
    }

    /**
     * Checks if the current step is the last one.
     * @returns {boolean}
     */
    isLastStep() {
        return this.selectedIndex >= this.views.children.length;
    }

    /**
     * Does transition between two views.
     * @param {IAnimationConfig} currentView
     * @param {IAnimationConfig} nextView
     * @param {Function | null} [callback]
     */
    performTransition(currentView, nextView, callback = null) {
        if (!this.animated) {
            if (typeof callback === 'function') {
                callback();
            }
            return;
        }
        updateClassList(currentView.element, currentView.prepare);
        currentView.element['offsetWidth'].toString();

        updateClassList(nextView.element, nextView.prepare);
        nextView.element['offsetWidth'].toString();

        updateClassList(currentView.element, currentView.run);

        const nextViewComplete = () => {
            nextView.element.removeEventListener(
                'transitionend',
                nextViewComplete
            );
            revertClassList(nextView.element, nextView.run);
            revertClassList(nextView.element, nextView.prepare);

            if (typeof callback === 'function') {
                callback();
            }
        };

        const currentViewComplete = () => {
            currentView.element.removeEventListener(
                'transitionend',
                currentViewComplete
            );
            revertClassList(currentView.element, currentView.run);
            revertClassList(currentView.element, currentView.prepare);

            updateClassList(nextView.element, nextView.run);
            nextView.element.addEventListener(
                'transitionend',
                nextViewComplete
            );
            nextView.element['offsetWidth'].toString();
        };

        currentView.element.addEventListener(
            'transitionend',
            currentViewComplete
        );
    }

    /**
     * @param {UIView} currentView
     * @param {UIView} nextView
     */
    switchViews(currentView, nextView) {
        this.performTransition(
            {
                element: currentView,
                prepare: {
                    '-display-effect': true,
                    '-fade-out': true,
                },
                run: {
                    '-animating': true,
                },
            },
            {
                element: nextView,
                prepare: {
                    '-display-effect': true,
                    '-fade-in': true,
                    '-no-space': true,
                },
                run: {
                    '-animating': true,
                    '-no-space': false,
                },
            }
        );
    }
    /**
     * Navigate to the previous view.
     */
    back() {
        if (this.isInitialStep()) {
            return;
        }
        const currentView = this.views.getActiveView();
        const nextView = currentView.previousElementSibling;
        this.switchViews(currentView, nextView);
        this.selectedIndex--;
    }

    /**
     * Navigate to the specific view by given id.
     * @param {number} stepId
     */
    activateStep(stepId) {
        const currentView = this.views.getActiveView();
        const nextView = this.viewList[stepId - 1];
        if (currentView === nextView) {
            return;
        }
        this.switchViews(currentView, nextView);
        this.selectedIndex = stepId;
    }

    /**
     * Navigate to the next view.
     */
    next() {
        if (this.isLastStep() || !this.isStepValid()) {
            return;
        }
        const currentView = this.views.getActiveView();
        const nextView = currentView.nextElementSibling;
        this.switchViews(currentView, nextView);
        this.selectedIndex++;
    }

    /**
     * Clean-up errors on step display.
     * @param {UIView} view
     * @returns {UIView}
     * @private
     */
    cleanErrors(view) {
        view.querySelectorAll('ui-field').forEach((field) => {
            const controlId = field.getControlId();
            const control = field.querySelector(`[id="${controlId}"]`);
            if (control && !control.validity.valid) {
                field.removeErrors();
            }
        });
        return view;
    }

    /**
     * Fires callback when the step has been changed.
     * @private
     */
    onChangeStep() {
        this.updateWizardNavigation();
        this.querySelectorAll('.ui-wizard__steps li').forEach((node, index) => {
            if (!this.viewList[index]) {
                return;
            }
            rebuild(node, this.buildStepCaption(this.viewList[index], index));
        });
    }

    updateWizardNavigation() {
        const toggleHidden = this.selectedIndex > 1 ? this.show : this.hide;
        if (this.backButton && !this.buttonBar.classList.contains('-custom')) {
            toggleHidden.call(this.backButton);
        }
        if (this.selectedIndex > this.lastActivatedStep) {
            this.lastActivatedStep = this.selectedIndex;
        }
        this.views.setAttribute('selected-index', this.selectedIndex);
    }

    /**
     * Handles click on the "Next" button.
     * @private
     */
    handleActionNext() {
        if (this.external) {
            this.dispatchCustomEvent('wizard-next', {});
        } else {
            this.next();
        }
    }

    /**
     * Handles click on the "Next" button.
     * @param {DeclarativeEvent} e
     * @private
     */
    handleActionChange(e) {
        const stepId = Number(e.detail.relatedTarget.dataset.stepId) || 1;
        if (this.external) {
            this.dispatchCustomEvent('wizard-next', { stepId });
        } else {
            this.activateStep(stepId);
        }
    }

    /**
     * Handles click on the "Back" button.
     * @private
     */
    handleActionBack() {
        if (this.external) {
            this.dispatchCustomEvent('wizard-back', {});
        } else {
            this.back();
        }
    }

    /**
     * Handles component mutations
     * @param {MutationRecord} mutation
     * @private
     */
    handleAttributesMutation(mutation) {
        if (mutation.attributeName === 'selected-index') {
            this.onChangeStep();
        }
    }

    /**
     * Fires callback when component mutates.
     * @param {Array<MutationRecord>} mutations
     * @private
     */
    handleMutations(mutations) {
        mutations.forEach((mutation) => {
            if (mutation.type === 'attributes') {
                this.handleAttributesMutation(mutation);
            }
        });
    }

    /**
     * Checks if current step is valid before invoking next(). If step is valid
     * go to next step, otherwise show errors for validation.
     * @returns {boolean}
     */
    isStepValid() {
        const currentStep = this.views.getActiveView();
        const form = this.closest('form');
        let valid = true;
        if (form && form.validator && form.validator.shouldFormValidate()) {
            form.validator.resolveElementsRequirability();
            form.validator.validate();
            form.checkValidity();
            const elems = currentStep.querySelectorAll('input,select,textarea');
            if (elems.length > 0) {
                valid = [].every.call(elems, (elem) => {
                    return elem.willValidate ? elem.checkValidity() : true;
                });
            }
        }
        return valid;
    }

    /**
     * Renders button bar.
     * @returns {UIButtonbar}
     */
    renderButtonBar() {
        const buttonbar = this.queryChildren('ui-buttonbar')[0];
        return this.createElement({
            tagName: 'ui-buttonbar',
            element: buttonbar,
            classList: {
                'ui-wizard__nav': true,
                '-custom': !!buttonbar,
            },
            children: buttonbar
                ? []
                : [
                      {
                          tagName: 'button',
                          attributes: {
                              type: 'button',
                              class: 'button -destructive -back',
                              'data-event': 'wizard-action-back',
                          },
                          children: [this.labelBack || UIWizard.labels.back],
                      },
                      {
                          tagName: 'button',
                          attributes: {
                              type: 'button',
                              class: 'button -next',
                              'data-event': 'wizard-action-next',
                          },
                          children: [this.labelNext || UIWizard.labels.next],
                      },
                  ],
        });
    }

    /**
     * Renders views.
     * @returns {UIViews}
     */
    renderViews() {
        return (
            this.views ||
            this.createElement({
                tagName: 'ui-views',
                classList: {
                    'form-content': !this.standalone,
                },
                children: this.viewList,
            })
        );
    }

    /**
     * Build config for step caption
     * @param {UIView} view
     * @param {number} index
     * @returns {IElementConfig}
     */
    buildStepCaption(view, index) {
        const labelText = view.label ? view.label : '';
        const label =
            this.subNavigation && index < this.lastActivatedStep
                ? {
                      tagName: 'button',
                      attributes: {
                          class: 'ui-wizard__link',
                          'data-event': 'wizard-action-change',
                          'data-step-id': index + 1,
                          type: 'button',
                      },
                      children: [labelText],
                  }
                : labelText;
        return {
            tagName: 'li',
            classList: {
                'ui-wizard__step': true,
                '-passed': index < this.selectedIndex - 1,
                '-active': index === this.selectedIndex - 1,
            },
            attributes: {
                'aria-current':
                    index === this.selectedIndex - 1 ? 'step' : null,
            },
            children: [label, index > 0 ? { tagName: 'ins' } : ''],
        };
    }

    /**
     * Renders steps.
     * @param {UIViews} views
     * @returns {HTMLElement}
     */
    renderSteps(views) {
        return this.createElement({
            tagName: 'ol',
            classList: {
                'ui-wizard__steps': true,
            },
            children: [].map.call(views.children, (view, index) =>
                this.buildStepCaption(view, index)
            ),
        });
    }

    /**
     * @inheritDoc
     */
    render() {
        const views = this.renderViews();
        const buttonBar = this.renderButtonBar();
        const steps = this.renderSteps(views);
        this.detachChildNodes();
        this.insertElements([steps, views, buttonBar]);
        if (this.backButton && !this.backButton.hasAttribute('data-event')) {
            this.backButton.setAttribute('data-event', 'wizard-action-back');
        }
        if (this.nextButton && !this.nextButton.hasAttribute('data-event')) {
            this.nextButton.setAttribute('data-event', 'wizard-action-next');
        }
        this.updateWizardNavigation();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.handleActionNext = this.handleActionNext.bind(this);
        this.handleActionBack = this.handleActionBack.bind(this);
        this.handleActionChange = this.handleActionChange.bind(this);

        this.clickObserver = new EventObserver({ name: 'click' });
        this.clickObserver.observe(this);
        this.addEventListener('wizard-action-back', this.handleActionBack);
        this.addEventListener('wizard-action-next', this.handleActionNext);
        this.addEventListener('wizard-action-change', this.handleActionChange);

        this.observer = new MutationObserver(this.handleMutations.bind(this));
        this.observer.observe(this, { attributes: true });
    }
}

UIWizard.defineElement('ui-wizard', styles);
export { UIWizard };

import { UIElement } from '../ui-element.js';
import styles from './ui-section.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UISection
 * @element ui-section
 * @classdesc Represents a class for <code>ui-section</code> element.
 * @slot
 * @example
 * <ui-section>
 *   <ui-assets>
 *     <ui-asset label="Main account" label-secondary="EE345678909870901345">
 *       <ui-badge layout="pin" color="pineapple">Default</ui-badge>
 *       <span slot="value"><ui-currency value="1200.48"></ui-currency> EUR</span>
 *     </ui-asset>
 *     <ui-asset
 *       label="Randy Lahey"
 *       label-secondary="EE323601345251978909"
 *       value-secondary="+2 currencies"
 *     >
 *       <span slot="value"><ui-currency value="12870.72"></ui-currency> EUR</span>
 *     </ui-asset>
 *     <ui-asset
 *       label="Credit card account"
 *       label-secondary="EE319781678251782328"
 *     >
 *       <span slot="value"><ui-currency value="900.35"></ui-currency> EUR</span>
 *     </ui-asset>
 *   </ui-assets>
 * </ui-section>
 */
class UISection extends UIElement {}

UISection.defineElement('ui-section', styles);
export { UISection };

/* istanbul ignore file */
import { prerenderElementsTree } from './ui-helpers.js';

/**
 * @param {string} htmlSource Raw html string to be pre-rendered.
 * @param {string} [tagName] Wrapper for HTML tree.
 * @returns {string}
 */
const prerenderHTML = (htmlSource, tagName) => {
    const wrapper = document.createElement(tagName ? tagName : 'div');
    wrapper.innerHTML = htmlSource;
    prerenderElementsTree(wrapper, true);
    return wrapper.innerHTML;
};

/**
 * @param {string} templateId - id of the template element
 * @param {string} [tagName] Wrapper for HTML tree. It is needed sometimes
 * that some elements aren't allowed markup to build correct DOM tree.
 * Like you cannot insert &lt;tr> inside &lt;div>, but &lt;iframe accepts
 * &lt;tr>.
 * @returns {string}
 */
const renderVirtualTemplate = (templateId, tagName) => {
    const tpl = document.querySelector(templateId);
    return prerenderHTML(tpl.innerHTML, tagName);
};

export { prerenderHTML, renderVirtualTemplate };

import { UITable } from '../../components/table/ui-table.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table editable plugin.
 * @alias UITableEditable
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableEditable {
    constructor(target) {
        this.target = target;
    }

    /**
     * Check if table is remove cell for cloneable plugin.
     * @param {HTMLTableCellElement} td
     * @returns {boolean}
     * @private
     */
    isRemoveCell(td) {
        return td.classList.contains('-remove');
    }

    /**
     * Check if table data cell contains non elements nodes.
     * @param {HTMLTableCellElement} td
     * @returns {boolean}
     * @private
     */
    hasOnlyTextableNodes(td) {
        return (
            [].filter.call(
                td.childNodes,
                (child) => child.nodeType === Node.ELEMENT_NODE
            ).length === 0
        );
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-editable');
        const rows = this.target.querySelectorAll('tbody tr');
        if (rows.length > 0) {
            [].forEach.call(
                this.target.querySelectorAll('tbody td'),
                (cell) => {
                    cell.classList.add('col');
                    if (
                        this.isRemoveCell(cell) ||
                        this.hasOnlyTextableNodes(cell)
                    ) {
                        return;
                    }
                    cell.classList.add('-valign-top');
                }
            );
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {}
}

UITable.registerPlugin('editable', UITableEditable);

export { UITableEditable };

import { UIElement } from '../ui-element.js';
import { debounce } from '../../global/helpers.js';
import { updateElement } from '../../global/ui-helpers.js';
import { isEscapePressed } from '../../global/keyboard.js';
import styles from './ui-navsearch.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UINavSearch
 * @element ui-navsearch
 * @classdesc Represents a class for <code>ui-navsearch</code> element
 * @fires event:menu-search-toggle
 * @fires event:menu-search-instant
 * @fires event:menu-search-request
 * @property {HTMLButtonElement} toggler {@readonly}
 * @property {HTMLInputElement} input {@readonly}
 * @property {string | attr} label label for search input
 * @property {string | attr} labelOpen label for open button
 * @property {string | attr} labelClose label for close button
 * @property {HTMLFormElement} form {@readonly}
 * @example
 * <ui-navsearch></ui-navsearch>
 */
class UINavSearch extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['active'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                active: Boolean,
                labelOpen: String,
                labelClose: String,
                label: String,
            },
            children: {
                form: '.ui-navsearch__form',
                input: '.ui-navsearch__input',
                toggler: 'button#nav-search-button',
            },
        };
    }

    getValue() {
        return this.input.value.trim();
    }

    setValue(value) {
        return (this.input.value = value.trim());
    }

    /**
     * Opens the search bar
     * @fires event:menu-search-toggle
     */
    activate() {
        this.active = true;
        this.dispatchCustomEvent('menu-search-toggle', { active: this.active });
        this.input.focus();
    }

    /**
     * Closes the search bar
     * @fires event:menu-search-toggle
     */
    deactivate() {
        this.active = false;
        this.dispatchCustomEvent('menu-search-toggle', { active: this.active });
    }

    /**
     * Fires callback when item state is updated
     * @private
     */
    onChangeActiveState() {
        const icon = this.toggler.querySelector('ui-icon');
        icon.setAttribute('glyph', this.active ? 'cross' : 'search');
        updateElement(this.toggler, {
            classList: {
                active: this.active,
            },
            attributes: {
                'aria-label':
                    (this.active ? this.labelClose : this.labelOpen) ||
                    this.label ||
                    null,
            },
        });
    }

    /**
     * @param {Event} e
     */
    handleInputFocus(e) {
        e.target.value = '';
    }

    /**
     * @param {Event} e
     * @fires event:menu-search-instant
     */
    handleInputUpdate(e) {
        this.dispatchCustomEvent('menu-search-instant', {
            query: e.target.value,
        });
    }

    /**
     * @param {Event} e
     */
    handleToggleSearch(e) {
        if (!this.active) {
            this.activate();
        } else {
            this.deactivate();
        }
    }

    /**
     * @param {Event} e
     * @fires event:menu-search-request
     */
    handleSubmitSearch(e) {
        e.preventDefault();
        this.dispatchCustomEvent('menu-search-request', {
            query: this.input.value,
        });
    }

    /**
     * @param {KeyboardEvent} event
     */
    handleEscape(event) {
        if (isEscapePressed(event) && this.active) {
            this.deactivate();
            this.toggler.focus();
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'active':
                this.onChangeActiveState();
                break;
            /* istanbul ignore next */
            default:
                break;
        }
    }

    /**
     * Renders item as a primary search
     */
    render() {
        this.updateElement({
            attributes: {
                role: 'search',
            },
            classList: {
                '-primary': true,
                '-search': true,
            },
            children: [
                {
                    tagName: 'form',
                    attributes: {
                        id: 'nav-search-form',
                        class: 'ui-navsearch__form',
                    },
                    children: [
                        {
                            tagName: 'input',
                            attributes: {
                                'aria-label': this.label,
                                autocomplete: 'off',
                                id: 'nav-search-query',
                                name: 'search-query',
                                type: 'text',
                                role: 'searchbox',
                                placeholder: this.label,
                                class: 'ui-navsearch__input',
                            },
                        },
                        {
                            tagName: 'button',
                            attributes: {
                                id: 'nav-search-submit',
                                name: 'searchSubmit',
                                type: 'submit',
                                'aria-label': this.label,
                                value: '',
                                class: 'ui-navsearch__submit',
                            },
                            children: [
                                {
                                    tagName: 'ui-icon',
                                    attributes: {
                                        glyph: 'search',
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    tagName: 'button',
                    attributes: {
                        id: 'nav-search-button',
                        'aria-label': this.labelOpen || this.label || null,
                        value: this.label, // TODO: check if it is really needed for ibank
                        class: 'ui-navitem__icon-button',
                    },
                    children: [
                        {
                            tagName: 'ui-icon',
                            attributes: {
                                glyph: this.active ? 'cross' : 'search',
                            },
                        },
                    ],
                },
            ],
        });
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.toggler.addEventListener(
            'click',
            this.handleToggleSearch.bind(this)
        );
        this.input.addEventListener('focus', this.handleInputFocus.bind(this));
        this.input.addEventListener(
            'input',
            debounce(this.handleInputUpdate.bind(this), 400)
        );
        this.form.addEventListener(
            'submit',
            this.handleSubmitSearch.bind(this)
        );
        this.addEventListener('keydown', this.handleEscape.bind(this));
    }
}

UINavSearch.defineElement('ui-navsearch', styles);
export { UINavSearch };

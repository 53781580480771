import { Labels } from '../../global/labels.js';
import { Digest } from '../../global/digest.js';
import {
    dispatchNativeEvent,
    setInnerText,
    updateElement,
} from '../../global/ui-helpers.js';
import { UIElement } from '../ui-element.js';
import {
    FormatterType,
    formatYearsValue,
    formatYearsMonthsValue,
    formatStep,
} from '../../global/formatters.js';
import styles from './ui-stepper.css';

/**
 * @memberof SharedComponents
 * @element ui-stepper
 * @augments {UIElement}
 * @alias UIStepper
 * @classdesc Represents a class for <code>ui-stepper</code> element.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {boolean} [unlimited] {@attr unlimited} Allow put value grater than the max one.
 * @property {boolean} [labels] {@attr labels} Display hint labels for min and max values.
 * @property {FormatterType} [formatter] {@attr formatter}
 * Function to format stepper value.
 * @property {string} [units] {@attr units} Shows unit as a suffix for the values.
 * @property {HTMLInputElement} control {@readonly} Main input for storing model value and
 * handle events.
 * @property {number} min The minimum stepper's value (proxied from control).
 * @property {number} max The maximum stepper's value (proxied from control).
 * @property {number} step The step value of a stepper (proxied from control).
 * @property {number} value The value of the stepper (proxied from control).
 * @property {HTMLDivElement} input {@readonly} Shortcut to stepper value div element.
 * @property {HTMLElement|UIHint} stepperMin {@readonly} Shortcut to label min container.
 * @property {HTMLElement|UIHint} stepperMax {@readonly} Shortcut to label max container.
 * @property {HTMLDivElement} field {@readonly} Shortcut to input field.
 * @property {HTMLButtonElement} incrementButton {@readonly} Shortcut to increment button.
 * @property {HTMLButtonElement} decrementButton {@readonly} Shortcut to decrement button.
 * @slot
 * @example
 * <ui-stepper units="€" labels="true">
 *   <input type="number" min="5000" max="50000" step="1" value="28000" />
 * </ui-stepper>
 */
class UIStepper extends UIElement {
    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-stepper', {
            month: {
                1: 'month',
                2: 'months',
            },
            year: {
                1: 'year',
                2: 'years',
            },
            increase: 'Increase',
            decrease: 'Decrease',
            value: 'Value',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                unlimited: Boolean,
                labels: Boolean,
                formatter: String,
                units: { type: String, default: '' },
                controlId: String,
            },
            children: {
                control: '.ui-stepper__control',
                input: '.ui-stepper__input',
                field: '.ui-stepper__field',
                incrementButton: '.ui-stepper__button.-increment',
                decrementButton: '.ui-stepper__button.-decrement',
                stepperMax: '.ui-stepper__limit.-max',
                stepperMin: '.ui-stepper__limit.-min',
            },
        };
    }

    /**
     * @type {UIStepperDefaults}
     * @readonly
     */
    static get defaults() {
        return {
            min: '0',
            max: '10',
            step: '1',
            value: '0',
        };
    }

    get id() {
        if (!this._id) {
            this._id = Digest.uuidv4('ui-stepper-');
        }
        return this._id;
    }

    get min() {
        return Number(this.control.min) || Number(UIStepper.defaults.min);
    }
    set min(value) {
        this.control.min = value;
    }

    get max() {
        return Number(this.control.max) || Number(UIStepper.defaults.max);
    }
    set max(value) {
        this.control.max = value;
    }

    get step() {
        return Number(this.control.step) || Number(UIStepper.defaults.step);
    }
    set step(value) {
        this.control.step = value;
    }

    get value() {
        return Number(this.control.value) || Number(UIStepper.defaults.value);
    }
    set value(value) {
        this.control.value = value;
    }

    /**
     * Formats value based on given formatter.
     * @param {number} value
     * @param {boolean} [isInput]
     * @param {boolean} [noUnits]
     * @returns {string}
     */
    formatValue(value, isInput = false, noUnits = false) {
        switch (this.formatter) {
            case FormatterType.Years:
                return formatYearsValue(value, {
                    noUnits,
                    labels: this.constructor.labels,
                });
            case FormatterType.Months:
                return formatYearsMonthsValue(value, {
                    noUnits,
                    labels: this.constructor.labels,
                });
            case FormatterType.String:
                return String(value);
            default: {
                /** @type Array<string | number> */
                let parts = [value];
                if (this.unlimited && !isInput && value > this.max) {
                    parts = [this.max + '+'];
                }
                if (!noUnits) {
                    parts.push(this.units);
                }
                return parts.join(' ').trim();
            }
        }
    }

    /**
     * Calculates discrete value based on the given relative value and the configured step.
     * @param {number} valueRelative
     * @returns {number} Rounded value
     */
    calcDiscreteValue(valueRelative) {
        const scale = (this.max - this.min) / this.step;
        const index = Math.round(scale * valueRelative);
        return formatStep(index * this.step + this.min, { step: this.step });
    }

    /**
     * Sets the min and max values to the label placeholders.
     * @returns {UIStepper | undefined}
     */
    updateLabels() {
        if (this.labels) {
            setInnerText(this.stepperMin, this.formatValue(this.min, false));
            setInnerText(this.stepperMax, this.formatValue(this.max, false));
            return this;
        }
    }

    /**
     * Sets an absolute value to the controls
     * @param {number} value
     * @returns {UIStepper}
     */
    setValue(value) {
        this.value = this.alignValueToRange(Number(value));

        const formattedValue = this.formatValue(this.value, true, true);
        const formattedValueWithUnits = this.formatValue(
            this.value,
            true,
            false
        );

        this.control.value = formattedValue;
        this.input.value = formattedValueWithUnits;
        setInnerText(this.input, formattedValueWithUnits);

        dispatchNativeEvent(this.control, 'change', true);

        return this;
    }

    /**
     * Checks if value is in range and return an edge value if the value is out of the range.
     * @param {number} value
     * @returns {number}
     */
    alignValueToRange(value) {
        const isHigherThanAllowed = value > this.max && !this.unlimited;
        const isLowerThanAllowed = value < this.min;

        if (isHigherThanAllowed) {
            return this.max;
        }
        if (isLowerThanAllowed) {
            return this.min;
        }
        return value;
    }

    /**
     * Applies the current input value to user controls.
     * @returns {UIStepper}
     */
    updateValue() {
        this.setValue(this.value);
        this.toggleButtonDisabledStates(this.value);
        return this;
    }

    /**
     * Toggle increment and decrement buttons disabled states based on max and min values.
     * @param {number} value
     * @private
     */
    toggleButtonDisabledStates(value) {
        this.decrementButton.disabled = this.value === this.min;
        this.incrementButton.disabled =
            !this.unlimited && this.value === this.max;
    }

    /**
     * Fires callback when user clicks on the decrement button.
     * @param {KeyboardEvent} e
     * @private
     */
    handleDecrementValue(e) {
        this.value = formatStep(this.value - this.step, { step: this.step });
        this.updateValue();
    }

    /**
     * Fires callback when user clicks on the increment button.
     * @param {KeyboardEvent} e
     * @private
     */
    handleIncrementValue(e) {
        this.value = formatStep(this.value + this.step, { step: this.step });
        this.updateValue();
    }

    /**
     * Fires callback when an user focuses the input element.
     * @param {FocusEvent} e
     * @private
     */
    handleFocusInput(e) {
        // Allows manually entering number values into input (which was text input with unit such as €)
        e.target.type = 'number';

        e.target.value = this.value;
    }

    /**
     * Fires callback when a user blurs the input element.
     * @param {FocusEvent|{target: HTMLInputElement}} e
     * @private
     */
    handleBlurInput(e) {
        const value = Number(e.target.value);
        const shouldChangeValue =
            !this.unlimited || !this.max || value < this.max;

        this.value = shouldChangeValue
            ? this.calcDiscreteValue(this.calcProgress(value) / 100)
            : value;

        // Allows units such as € to be displayed inside input
        e.target.type = 'text';

        this.updateValue();
    }

    /**
     * Fires callback when a user enters vale into input.
     * @param {FocusEvent|{target: HTMLInputElement}} e
     * @private
     */
    handleEnterInput(e) {
        const enteredValue = e.target.value;

        this.input.value = enteredValue;
        this.control.value = enteredValue;
    }

    /**
     * Calculates progress value based on the given absolute value.
     * @param {number} value
     * @returns {number}
     */
    calcProgress(value) {
        if (this.unlimited && this.max && value >= this.max) {
            return 100;
        }
        return ((value - this.min) / (this.max - this.min)) * 100;
    }

    /**
     * @returns {IElementConfig} Markup of input with decrement and increment buttons.
     * Without min and max labels.
     */
    getStepperFieldEl() {
        // Provided as child element of stepper component.
        const control = this.queryChildren('input')[0];

        const controlInput = {
            tagName: 'input',
            element: control,
            attributes: {
                type: 'hidden',
                // #pally
                'aria-label': 'virtual',
            },
            classList: {
                'ui-stepper__control': true,
                '-hidden': true,
            },
        };
        const decrementButton = {
            tagName: 'button',
            attributes: {
                class: 'ui-stepper__button -decrement',
                type: 'button',
                'aria-label': UIStepper.labels.decrease,
                'aria-controls': this.id,
            },
            children: [{ tagName: 'ui-icon', attributes: { glyph: 'minus' } }],
        };
        const incrementButton = {
            tagName: 'button',
            attributes: {
                class: 'ui-stepper__button -increment',
                type: 'button',
                'aria-label': UIStepper.labels.increase,
                'aria-controls': this.id,
            },
            children: [{ tagName: 'ui-icon', attributes: { glyph: 'add' } }],
        };
        const input = {
            tagName: 'input',
            attributes: {
                type: 'text',
                min: control?.min || UIStepper.defaults.min,
                max: control?.max || UIStepper.defaults.max,
                step: control?.step || UIStepper.defaults.step,
                value: control?.value || UIStepper.defaults.value,
                id: this.id,
            },
            classList: {
                'ui-stepper__input': true,
                'ui-stepper__units-input': true,
                '-hidden': false,
            },
        };

        return {
            tagName: 'div',
            classList: {
                'ui-stepper__field': true,
            },
            children: [controlInput, decrementButton, input, incrementButton],
        };
    }

    /**
     * @returns {IElementConfig | undefined} Markup of min and max labels in case
     * they are configured to be displayed.
     */
    getMinAndMaxLabelEl() {
        if (this.labels) {
            return {
                tagName: 'div',
                classList: {
                    'ui-stepper__limits': true,
                },
                children: [
                    {
                        tagName: 'ui-hint',
                        classList: {
                            'ui-stepper__limit': true,
                            '-min': true,
                        },
                    },
                    {
                        tagName: 'ui-hint',
                        classList: {
                            'ui-stepper__limit': true,
                            '-max': true,
                        },
                    },
                ],
            };
        }
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        if (!this.rendered) {
            return;
        }

        switch (name) {
            case 'control-id':
                if (newValue) {
                    this.input.setAttribute('id', newValue);
                } else {
                    this.input.removeAttribute('id');
                }
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const stepperField = this.getStepperFieldEl();
        const minAndMaxLabels = this.getMinAndMaxLabelEl();

        this.insertElements([stepperField, minAndMaxLabels]);

        this.updateLabels();
        this.toggleButtonDisabledStates(this.value);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        updateElement(this.decrementButton, {
            events: {
                click: this.handleDecrementValue.bind(this),
            },
        });
        updateElement(this.incrementButton, {
            events: {
                click: this.handleIncrementValue.bind(this),
            },
        });
        updateElement(this.input, {
            events: {
                focus: this.handleFocusInput.bind(this),
                blur: this.handleBlurInput.bind(this),
                input: this.handleEnterInput.bind(this),
            },
        });
        this.setValue(this.value);
    }
}

UIStepper.defineElement('ui-stepper', styles);
export { UIStepper };

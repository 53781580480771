import { BOOLEAN_ATTRIBUTES, SVG_ELEMENTS } from './constants.js';
/**
 * Returns xmlns namespace by key
 * @memberof UiHelpers
 * @param {string} key
 * @returns {string}
 */
const resolveNamespace = (key) => {
    // TODO: add others
    switch (key) {
        case 'svg':
            return 'http://www.w3.org/2000/' + key;
        case 'xlink':
            return 'http://www.w3.org/1999/' + key;
        default:
            return 'http://www.w3.org/1999/' + key;
    }
};

/**
 * Update class list of the node by given config.
 * @memberof UiHelpers
 * @example
 * // adds -active class to the node classList and removes -awesome from the node classList
 * element.updateClassList({"-active": true, "-awesome": false})
 * @param {HTMLElement} element
 * @param {Record<string, boolean>} config - hash map where key is classname value is flag
 * to add / remove it.
 * @returns {HTMLElement}
 */
const updateClassList = (element, config) => {
    Object.keys(config || {}).forEach((key) => {
        if (!!config[key] && !element.classList.contains(key)) {
            element.classList.add(key);
        }
        if (!config[key] && element.classList.contains(key)) {
            element.classList.remove(key);
        }
    });
    return element;
};

/**
 * Update class list of the node by reverting given config,
 * could be used for animations to roll back last applied class config.
 * @memberof UiHelpers
 * @example
 * // adds -active class to the node classList and removes -awesome from the node classList
 * element.revertClassList({"-active": true, "-awesome": false})
 * @param {HTMLElement} element
 * @param {Record<string, boolean>} config - hash map where key is classname, value is flag
 * which defines if the class should be added or removed
 * @returns {HTMLElement}
 */
const revertClassList = (element, config) => {
    return updateClassList(
        element,
        Object.keys(config || {}).reduce((acc, key) => {
            acc[key] = !config[key];
            return acc;
        }, {})
    );
};

/**
 * @memberof UiHelpers
 * @param {Element} element
 * @param {string} text
 * @param {boolean} [trusted] - defines if the text is trusted HTML
 * @returns {Element}
 */
const setInnerText = (element, text, trusted) => {
    if (trusted) {
        element.innerHTML = text;
        return element;
    }
    element.innerHTML = '';
    element.appendChild(document.createTextNode(text));
    return element;
};

/**
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {IAttributeProps | Record<string, string | null>} attributes to be applied to the node
 * @example
 * // Set attributes "foo" and "bar" to attributes "id" and "name" respectively
 * element.setAttributes({id: "foo", name: "bar"});
 * @returns {HTMLElement}
 */
const setAttributes = (element, attributes) => {
    if (typeof attributes === 'object' && !Array.isArray(attributes)) {
        Object.keys(attributes || {}).forEach((key) => {
            let attributeValue = attributes[key];
            if (BOOLEAN_ATTRIBUTES.indexOf(key) > -1) {
                if (attributeValue === false) {
                    attributeValue = null;
                } else if (attributeValue === true) {
                    attributeValue = key;
                }
            }
            if (attributeValue !== null) {
                if (key.indexOf(':') > -1) {
                    element.setAttributeNS(
                        resolveNamespace(key.split(':').shift()),
                        key,
                        attributeValue
                    );
                } else {
                    element.setAttribute(key, attributeValue);
                }
            } else {
                element.removeAttribute(key);
            }
        });
    }
    return element;
};

/**
 * return list of the node's attributes.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @returns {Record<string, string>}
 */
const getAttributes = (element) => {
    return [...element.attributes].reduce((attributes, attr) => {
        if (BOOLEAN_ATTRIBUTES.indexOf(attr.name) > -1) {
            attributes[attr.name] = String(
                ['true', '', attr.name].indexOf(attr.nodeValue) > -1
            );
        } else {
            attributes[attr.name] = attr.nodeValue;
        }
        return attributes;
    }, {});
};

/**
 * Updates element by given config.
 * @memberof UiHelpers
 * @param {HTMLElement} element
 * @param {IElementConfig} config
 * @param {boolean} [trusted] - defines if element's strings are trusted HTML
 * @returns {HTMLElement}
 */
const updateElement = (element, config, trusted) => {
    if (config.classList) {
        updateClassList(element, config.classList);
    }
    if (config.attributes) {
        setAttributes(element, config.attributes);
    }

    if (
        config.hasOwnProperty('innerHTML') &&
        element.innerHTML !== config.innerHTML
    ) {
        console.warn('Usage of innerHTML is deprecated use children instead');
        setInnerText(element, config.innerHTML, trusted);
    }

    const items =
        config.children instanceof NodeList
            ? [].map.call(config.children, (node) => {
                  return node;
              })
            : config.children;

    if (Array.isArray(items)) {
        items
            .filter((item) => !!item)
            .forEach(
                /**
                 * @param {IElementConfigChildren} entry
                 */
                (entry) => {
                    [].concat(entry).forEach((item) => {
                        if (['string', 'number'].indexOf(typeof item) > -1) {
                            if (trusted) {
                                element.insertAdjacentHTML(
                                    'beforeend',
                                    String(item)
                                );
                            } else {
                                element.appendChild(
                                    document.createTextNode(String(item))
                                );
                            }
                        } else {
                            const child =
                                item instanceof Node
                                    ? item
                                    : createElement(item, trusted);
                            element.appendChild(child);
                        }
                    });
                }
            );
    }

    if (config.events) {
        Object.keys(config.events).forEach((key) => {
            if (typeof config.events[key] === 'function') {
                element.addEventListener(key, config.events[key]);
            }
        });
    }
    return element;
};

/**
 * Creates element by given tagName and config.
 * @memberof UiHelpers
 * @param {IElementConfig} config
 * @param {boolean} [trusted] - defines if element's strings are trusted HTML
 * @returns {* | HTMLElement}
 */
const createElement = (config, trusted) => {
    let element;
    if (config.element instanceof HTMLElement) {
        element = config.element;
    } else if (config.tagName) {
        if (SVG_ELEMENTS.indexOf(config.tagName) > -1) {
            element = document.createElementNS(
                resolveNamespace('svg'),
                config.tagName
            );
        } else {
            element = document.createElement(config.tagName);
        }
    }
    if (!element) {
        console.warn(
            'Invalid config given. Either tagName or element property required'
        );
        return null;
    }
    updateElement(element, config, trusted);
    return element;
};

/**
 * Inserts elements inside component.
 * @memberof UiHelpers
 * @param {Element|DocumentFragment} parent
 * @param {Array<IElementConfig | HTMLElement | string>} configs
 * @param {InsertPosition} [position]
 * @param {boolean} [trusted] - defines if element's strings are trusted HTML
 * @returns {Element}
 */
const insertElements = (parent, configs, position = 'beforeend', trusted) => {
    const elements = configs
        .reduce((collection, item) => {
            return collection.concat(
                []
                    .concat(item)
                    .filter(
                        (item) =>
                            !(
                                item === undefined ||
                                item === null ||
                                item === false
                            )
                    )
            );
        }, [])
        .map((item) => {
            if (typeof item === 'string' || item instanceof Node) {
                return item;
            }
            return createElement(item, trusted);
        });
    const items =
        ['beforebegin', 'afterbegin'].indexOf(position) > -1
            ? elements.reverse()
            : elements;
    items.forEach(
        /** @param {Element | string | number | boolean} element */
        (element) => {
            if (typeof element === 'string') {
                if (trusted) {
                    const template = document.createElement('template');
                    template.innerHTML = element;
                    parent.appendChild(template.content);
                } else {
                    parent.appendChild(document.createTextNode(element));
                }
            } else if (['number', 'boolean'].indexOf(typeof element) > -1) {
                parent.appendChild(document.createTextNode(String(element)));
            } else {
                if (element) {
                    parent.appendChild(element);
                }
            }
        }
    );
    return parent;
};

/**
 * Recursively pre-render Element tree.
 * @memberof UiHelpers
 * @param {HTMLElement|UIElement} node
 * @param {boolean} [recursive]
 */
const prerenderElementsTree = (node, recursive) => {
    if (
        typeof node.render === 'function' &&
        ['rendered', 'hydrated'].indexOf(node.state) === -1
    ) {
        if (node.rendered) {
            return;
        }
        if (typeof node.runLifecycleHook === 'function') {
            node.runLifecycleHook('render');
        }
        node.state = 'rendered';
    }
    if (!recursive) {
        return;
    }
    const children = node.children;
    if (!children) {
        return;
    }
    [].forEach.call(children, (child) => {
        prerenderElementsTree(child, recursive);
    });
};

export {
    createElement,
    insertElements,
    setAttributes,
    getAttributes,
    setInnerText,
    updateElement,
    updateClassList,
    revertClassList,
    prerenderElementsTree,
};

import { UIElement } from '../ui-element.js';
import { keyCodes, isKeyPressed, TabIndex } from '../../global/keyboard.js';
import styles from './ui-award.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAward
 * @element ui-award
 * @classdesc Represents a class for <code>ui-award</code> element.
 * Single ui-award should be child of ui-awards container.
 * @fires event:award-open
 * @property {string} [price] {@attr price} Price of the award.
 * @property {string} [oldPrice] {@attr old-price} Old price (strike-through) price.
 * @property {string} [labelPrice] {@attr label-price} Label of the price.
 * @property {string} [labelPriceExtra] {@attr label-price-extra} More texts to the price container.
 * @property {number} [awardId] {@attr award-id} Award ID specified by back-end usually
 * for reward programme in iBank.
 * @property {string} [image] {@attr image} Image URL for the award.
 * @property {string} [alt=""] {@attr alt} Alternate text for image.
 * @property {boolean} [clickable=false] {@attr clickable} If true whole ui-award will be clickable
 * and focusable.
 * @property {string} [nolazy] {@attr nolazy} Prevents set 'loading=lazy' attribute for image.
 * loading='lazy | eager' might cause side effect for image rendering.
 * @slot
 * @example
 * <ui-award price="5400" label-price="Points" award-id="11"
 *    image="assets/mocks/images/award01.jpg">
 *   <ui-badge color="turquoise">Business</ui-badge>
 *   <p>New image size: 600x600 px! Old ones will be scaled up.</p>
 * </ui-award>
 */
class UIAward extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                price: String,
                oldPrice: String,
                labelPrice: String,
                labelPriceExtra: String,
                awardId: Number,
                image: String,
                alt: String,
                clickable: Boolean,
                nolazy: Boolean,
            },
        };
    }

    /**
     * Handles click and keyboard events.
     * @param {KeyboardEvent | PointerEvent} event
     * @private
     */
    handleAwardOpen(event) {
        event.preventDefault();
        if (
            event.type === 'keypress' &&
            !(
                isKeyPressed(event, keyCodes.ENTER) ||
                isKeyPressed(event, keyCodes.SPACE)
            )
        ) {
            return;
        }
        if (event.type === 'click') {
            if (!this.clickable) {
                return;
            }
            const link = this.getInnerLink();
            if (link) {
                link.dispatchEvent(new MouseEvent('click'));
            }
        }

        const params = {};
        if (this.awardId) {
            params['award-id'] = this.awardId;
        }
        this.dispatchCustomEvent('award-open', params);
    }

    /**
     * Gets first link from current ui-award.
     * @returns {HTMLAnchorElement}
     */
    getInnerLink() {
        return this.querySelector('a');
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!this.image) {
            console.warn(
                this.constructor.name + ': used without image attribute.'
            );
        }
        const hasInnerLink = !!this.getInnerLink();
        if (this.awardId || hasInnerLink) {
            this.clickable = true;
        }
        if (this.clickable) {
            this.tabIndex = +TabIndex.Active;
        }
        const priceContainers = [
            {
                tagName: 'div',
                classList: {
                    'ui-award__price-container': true,
                },
                children: [
                    (this.oldPrice || this.price) && {
                        tagName: 'div',
                        children: [
                            this.oldPrice && {
                                tagName: 'div',
                                children: [this.oldPrice],
                                classList: {
                                    'ui-award__old-price': true,
                                },
                            },
                            this.price && {
                                tagName: 'div',
                                children: [this.price],
                                classList: {
                                    'ui-award__price': true,
                                },
                            },
                        ],
                        classList: {
                            'ui-award__price-wrapper': true,
                        },
                    },
                    this.labelPrice && {
                        tagName: 'div',
                        children: [this.labelPrice],
                        classList: {
                            'ui-award__units': true,
                        },
                    },
                    this.labelPriceExtra && {
                        tagName: 'div',
                        children: [this.labelPriceExtra],
                        classList: {
                            'ui-award__price-extra': true,
                        },
                    },
                ],
            },
        ];

        const content = [].filter
            .call(this.childNodes, (node) => node.localName !== 'ui-badge')
            .concat(priceContainers);

        this.insertElements([
            {
                tagName: 'figure',
                children: [
                    {
                        tagName: 'img',
                        attributes: {
                            src: this.image || '',
                            alt: this.alt || '',
                            loading: this.nolazy ? null : 'lazy',
                        },
                    },
                ],
            },
            {
                tagName: 'article',
                children: this.clickable
                    ? [
                          {
                              tagName: 'div', // makes trick with :focus-within
                              attributes: {
                                  tabindex: TabIndex.Active,
                              },
                              classList: {
                                  'ui-award__focus-handler': true,
                              },
                              children: [content],
                          },
                      ]
                    : [content],
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.addEventListener('click', this.handleAwardOpen.bind(this));
        this.addEventListener('keypress', this.handleAwardOpen.bind(this));
    }
}

UIAward.defineElement('ui-award', styles);
export { UIAward };

import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import {
    addSyntheticFocusVisible,
    buildSyntheticFocusControl,
    createElement,
    dispatchNativeEvent,
    hide,
    rebuildChildren,
    setInnerText,
    show,
    updateElement,
} from '../../global/ui-helpers.js';
import { EventObserver } from '../../global/event-observer.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-upload.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIUpload
 * @element ui-upload
 * @classdesc Represents a class for <code>ui-upload</code> element.
 * Provides input field with file upload user interface.
 * @fires event:change
 * @listens event:remove-upload-item
 * @property {boolean} [multiple=false] {@attr multiple} If true, multiple files can be added.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {("add" | "replace")} [mode="add"] {@attr mode} Upload mode for multiple.
 *  {@desc add: each time file attached to the end of the list}
 *  {@desc replace: each time selected, files replace the previous list (native behaviour)}
 * @property {HTMLInputElement} input {@readonly} Shortcut to input element.
 * @property {HTMLInputElement} inputAux {@readonly} Shortcut to additional input element.
 * Needed for the multiple add mode (solved problem with duplicates).
 * @property {HTMLButtonElement} button {@readonly} Shortcut to upload button element.
 * @property {HTMLButtonElement} clearBtn {@readonly} Shortcut to clean button element.
 * @property {HTMLLabelElement} label {@readonly} Shortcut to label element.
 * @property {HTMLDivElement} placeholder {@readonly} Shortcut to placeholder.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @slot
 * @example
 * <ui-upload>
 *   <input type="text" name="fileupload" placeholder="Max 12 MB" id="test-ui-upload">
 * </ui-upload>
 */
class UIUpload extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                multiple: Boolean,
                controlId: String,
                mode: { type: String, default: UIUpload.MODES.ADD },
            },
            children: {
                input: '.ui-upload__input',
                inputAux: '.ui-upload__input-auxiliary',
                label: '.ui-upload__label',
                button: '.ui-upload__submit',
                clearBtn: '.ui-upload__clear',
                placeholder: '.ui-upload__placeholder',
                focusTrigger: '.ui-upload__focus-trigger',
            },
        };
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-upload', {
            upload: 'Upload',
            clear: 'Remove file',
        });
    }

    /**
     * Mode of uploading.
     * @type {{ADD: string, REPLACE: string}}
     */
    static get MODES() {
        return {
            ADD: 'add',
            REPLACE: 'replace',
        };
    }

    /**
     * Provides list of observed attributes to be watched.
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'control-id':
                if (this.focusTrigger) {
                    if (newValue) {
                        this.focusTrigger.setAttribute('id', newValue);
                    } else {
                        this.focusTrigger.removeAttribute('id');
                    }
                }
                break;
        }
    }

    /**
     * Get or create file list element.
     * @returns {Element}
     */
    getFileListElement() {
        let el = this.querySelector('.ui-upload__file-list');
        if (!el) {
            el = createElement({
                tagName: 'div',
                classList: {
                    'ui-upload__file-list': true,
                },
            });
            this.appendChild(el);
        }
        return el;
    }

    /**
     * Updates native input with multiple files.
     * @private
     */
    updateMultipleView() {
        this.syncFiles();

        rebuildChildren(
            this.getFileListElement(),
            this._filelist.map((file, id) => ({
                tagName: 'ui-upload-item',
                attributes: {
                    'data-index': id,
                    filename: file.name,
                    filesize: this.formatFileSize(file.size),
                    link: URL.createObjectURL(file) || null,
                },
            }))
        );
    }

    /**
     * Format file size from bytes to human-readable value.
     * @param {number} size
     * @returns {string}
     * @private
     */
    formatFileSize(size) {
        if (!size) {
            return '0';
        }

        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(size) / Math.log(1024));

        return (
            parseFloat((size / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i]
        );
    }

    /**
     * Resets component to initial state
     */
    reset() {
        this._filelist = [];
        this.input.value = '';
        if (this.inputAux) {
            this.inputAux.value = '';
        }
        dispatchNativeEvent(this.input, 'change', true);
        this.button.focus();
        if (this.input.willValidate) {
            this.input.checkValidity();
        }
    }

    /**
     * Synchronise files from input and private variable
     */
    syncFiles() {
        const dt = new DataTransfer();
        if (this.inputAux) {
            this.inputAux.value = '';
        }
        for (const file of this._filelist) {
            dt.items.add(file);
        }

        this.input.files = dt.files;
        if (this.input.willValidate) {
            dispatchNativeEvent(this.input, 'invalid', true);
        }
    }

    /**
     * Updates appearance regarding to setting a file.
     */
    updateStatus() {
        if (this.multiple) {
            this.updateMultipleView();
        } else {
            if (this._filelist.length > 0) {
                setInnerText(this.placeholder, this.input.files[0].name);
                show(this.clearBtn);
            } else {
                setInnerText(
                    this.placeholder,
                    this.input.getAttribute('placeholder') || ''
                );
                hide(this.clearBtn);
            }
        }
        this.updateClassList({ '-empty': !(this.input.files.length > 0) });
    }

    /**
     * Handle removing file item from list.
     * @param {CustomEvent} e
     */
    handleRemoveUploadItem(e) {
        const uploadItem = e.detail.relatedTarget.closest('ui-upload-item');
        if (!uploadItem) {
            return;
        }
        const id = uploadItem.getAttribute('data-index');
        if (id) {
            this._filelist.splice(parseInt(id), 1);
            this.syncFiles();
            dispatchNativeEvent(this.input, 'change', true);
            if (this.input.willValidate) {
                this.input.checkValidity();
            }
        }
    }

    /**
     * Fires callback when user changes file.
     * @param {Event} e
     * @private
     */
    handleFileChange(e) {
        if (this.inProgress) {
            return;
        }

        const files =
            this.multiple && this.mode === UIUpload.MODES.ADD
                ? [...this.inputAux.files]
                : [...this.input.files];

        // In Safari browser such changes of this.input.files
        // emit native 'change' event once again.
        // this.inProgress = true - prevent 'change' in this case.
        this.inProgress = true;
        this._filelist =
            this.mode === UIUpload.MODES.REPLACE || !this.multiple
                ? files
                : this._filelist.concat(files);
        this.updateStatus();
        this.inProgress = false;
    }

    /**
     * Fires callback when user clicks on label.
     * @param {Event} e
     * @private
     */
    handleLabelClick(e) {
        if (this.label.getAttribute('for')) {
            return;
        }
        this.handleInput();
    }

    /**
     * Handle input activate.
     * @private
     */
    handleInput() {
        if (!(this.multiple && this.mode === UIUpload.MODES.ADD)) {
            this.input.click();
        } else {
            this.inputAux.value = '';
            this.inputAux.click();
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        /** @type {HTMLInputElement} */
        const input = this.queryChildren('input')[0];
        const button = this.queryChildren('button')[0];
        this.detachChildNodes();
        this._filelist = input && input.files ? [...input.files] : [];
        this.insertElements([
            buildSyntheticFocusControl(this.controlId, 'ui-upload'),
            {
                tagName: 'div',
                classList: {
                    'ui-upload__control-row': true,
                },
                children: [
                    {
                        tagName: 'input',
                        element: input,
                        attributes: {
                            type: 'file',
                            multiple: this.multiple || null,
                            'aria-labelledby': this.controlId,
                        },
                        classList: {
                            'ui-upload__input': true,
                            '-visually-hidden': true,
                        },
                    },
                    this.multiple &&
                        this.mode === UIUpload.MODES.ADD && {
                            tagName: 'input',
                            attributes: {
                                type: 'file',
                                multiple: this.multiple,
                                accept: input
                                    ? input.getAttribute('accept')
                                    : null,
                            },
                            classList: {
                                'ui-upload__input-auxiliary': true,
                                '-utility': true,
                                '-hidden': true,
                            },
                        },
                    !this.multiple && {
                        tagName: 'div',
                        classList: {
                            'ui-upload__control': true,
                        },
                        children: [
                            {
                                // FIXME, TODO: nested <label> elements.
                                tagName: 'label',
                                attributes: {
                                    for: input ? input.id || null : null,
                                },
                                classList: {
                                    'ui-upload__label': true,
                                    'input-file': true,
                                },
                                children: [
                                    {
                                        tagName: 'label',
                                        attributes: {
                                            for: input
                                                ? input.id || null
                                                : null,
                                        },
                                        classList: {
                                            'ui-upload__placeholder': true,
                                        },
                                        children: input
                                            ? [
                                                  input.getAttribute(
                                                      'placeholder'
                                                  ),
                                              ]
                                            : [],
                                    },
                                ],
                            },
                            {
                                tagName: 'button',
                                classList: {
                                    'ui-upload__clear': true,
                                    '-iconed': true,
                                },
                                attributes: {
                                    type: 'button',
                                    'aria-label': UIUpload.labels.clear,
                                },
                                events: {
                                    click: this.reset.bind(this),
                                },
                                children: [
                                    {
                                        tagName: 'ui-icon',
                                        attributes: {
                                            bgcolor: UIIcon.colors.ALERT_RED,
                                            glyph: 'cross',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        tagName: 'button',
                        element: button,
                        classList: {
                            'ui-upload__submit': true,
                            button: true,
                            '-guiding': true,
                        },
                        attributes: {
                            type: 'button',
                        },
                        children: button ? [] : [UIUpload.labels.upload],
                    },
                    this.multiple && {
                        tagName: 'label',
                        attributes: {
                            for: input ? input.id || null : null,
                        },
                        classList: {
                            'ui-upload__placeholder': true,
                        },
                        children: input
                            ? [input.getAttribute('placeholder')]
                            : [],
                    },
                ],
            },
        ]);

        this.updateStatus();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.inProgress = false;

        updateElement(this.input, {
            events: {
                change: this.handleFileChange.bind(this),
            },
        });

        if (this.inputAux) {
            updateElement(this.inputAux, {
                events: {
                    change: this.handleFileChange.bind(this),
                },
            });
        }

        updateElement(this.label, {
            events: {
                click:
                    !this.input.getAttribute('id') &&
                    this.handleLabelClick.bind(this),
            },
        });
        updateElement(this.button, {
            events: {
                click: this.handleInput.bind(this),
            },
        });

        this.clickObserver = new EventObserver({ name: 'click' });
        this.clickObserver.observe(this);
        this.addEventListener(
            'remove-upload-item',
            this.handleRemoveUploadItem.bind(this)
        );
        this.focusTrigger.addEventListener('click', () =>
            addSyntheticFocusVisible(this.input)
        );
    }
}

UIUpload.defineElement('ui-upload', styles);
export { UIUpload };

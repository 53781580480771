import { UITable } from '../../components/table/ui-table.js';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table merge plugin.
 * @alias UITableMerge
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableMerge {
    constructor(target) {
        this.target = target;
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-merge');
    }

    /**
     * @inheritDoc
     */
    hydrate() {}
}
UITable.registerPlugin('merge', UITableMerge);

export { UITableMerge };

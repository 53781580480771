export default {
    en: [
        { name: 'Latvia', iso2: 'lv', dialCode: '371' },
        { name: 'Estonia', iso2: 'ee', dialCode: '372' },
        { name: 'Lithuania', iso2: 'lt', dialCode: '370' },
        { name: 'Russia', iso2: 'ru', dialCode: '7' },
        { name: 'Finland', iso2: 'fi', dialCode: '358' },
        { name: 'Sweden', iso2: 'se', dialCode: '46' },
    ],
    et: [
        { name: 'Läti', iso2: 'lv', dialCode: '371' },
        { name: 'Eesti', iso2: 'ee', dialCode: '372' },
        { name: 'Leedu', iso2: 'lt', dialCode: '370' },
        { name: 'Venemaa', iso2: 'ru', dialCode: '7' },
        { name: 'Soome', iso2: 'fi', dialCode: '358' },
        { name: 'Rootsi', iso2: 'se', dialCode: '46' },
    ],
    ru: [
        { name: 'Латвия', iso2: 'lv', dialCode: '371' },
        { name: 'Эстония', iso2: 'ee', dialCode: '372' },
        { name: 'Литва', iso2: 'lt', dialCode: '370' },
        { name: 'Россия', iso2: 'ru', dialCode: '7' },
        { name: 'Финляндия', iso2: 'fi', dialCode: '358' },
        { name: 'Швеция', iso2: 'se', dialCode: '46' },
    ],
    lv: [
        { name: 'Latvija', iso2: 'lv', dialCode: '371' },
        { name: 'Igaunija', iso2: 'ee', dialCode: '372' },
        { name: 'Lietuva', iso2: 'lt', dialCode: '370' },
        { name: 'Krievija', iso2: 'ru', dialCode: '7' },
        { name: 'Somija', iso2: 'fi', dialCode: '358' },
        { name: 'Zviedrija', iso2: 'se', dialCode: '46' },
    ],
    lt: [
        { name: 'Latvija', iso2: 'lv', dialCode: '371' },
        { name: 'Estija', iso2: 'ee', dialCode: '372' },
        { name: 'Lietuva', iso2: 'lt', dialCode: '370' },
        { name: 'Rusija', iso2: 'ru', dialCode: '7' },
        { name: 'Suomija', iso2: 'fi', dialCode: '358' },
        { name: 'Švedija', iso2: 'se', dialCode: '46' },
    ],
};

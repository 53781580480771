import { UIElement } from '../ui-element.js';
import styles from './ui-inputgroup.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIInputGroup
 * @element ui-inputgroup
 * @classdesc Represents a class for <code>ui-inputgroup</code> element.
 * Combines together inputs and labels like currencies or selects.
 * Or can be combined two inputs or two selects.
 * @slot
 * @example
 * <ui-field label="Select + currency">
 *   <ui-inputgroup>
 *     <select name="units" id="test-render-id-3" class="-long">
 *       <option value="EUR">My account</option>
 *       <option value="USD">Billy's account</option>
 *     </select>
 *     <label class="units">EUR</label>
 *   </ui-inputgroup>
 * </ui-field>
 */
class UIInputGroup extends UIElement {
    /**
     * @inheritDoc
     */
    render() {
        this.setAttribute('role', 'group');
    }
}

UIInputGroup.defineElement('ui-inputgroup', styles);
export { UIInputGroup };

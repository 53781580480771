import { UIElement } from '../ui-element.js';
import styles from './ui-option-basic.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionBasic
 * @element ui-option-basic
 * @classdesc Represents a class for <code>ui-option-basic</code> element.
 * Similar to ui-option, but holds basic value. Used with combination
 * with ui-dropdown.
 * @property {boolean} control {@readonly} It shows if the element will be located in control.
 * @example
 * <ui-option-basic></ui-option-basic>
 */
class UIOptionBasic extends UIElement {
    static get POPOVER_TYPE() {
        return 'basic';
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
            },
        };
    }

    /**
     * Finds match in option value and given texts.
     * @param {HTMLOptionElement} option
     * @param {string} text
     * @returns {boolean}
     */
    findMatch(option, text) {
        const val = option.getAttribute('value').toLowerCase();
        return val.indexOf(text.toLowerCase()) > -1;
    }

    /**
     * For basic option returns it's own dataset only.
     * @returns {Record<string, string>}
     */
    getData() {
        return this.getAttributes();
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateElement({
            classList: {
                '-control': this.control,
            },
            children: [
                {
                    tagName: 'span',
                    children: [
                        this.getAttribute('text') ||
                            this.getAttribute('value') ||
                            '',
                    ],
                },
                this.control && {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'dropdown',
                    },
                },
            ],
        });
    }
}

UIOptionBasic.defineElement('ui-option-basic', styles);
export { UIOptionBasic };

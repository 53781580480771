import { UITable } from '../../components/table/ui-table.js';
import { Labels } from '../../global/labels.js';
import { compareValuesAsStrings } from '../../global/comparators.js';

import {
    closestByCond,
    updateClassList,
    updateElement,
    dispatchCustomEvent,
    adoptStyle,
} from '../../global/ui-helpers.js';
import styles from './ui-table-sortable.css';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-table sortable plugin.
 * @alias UITableSortable
 * @implements {IComponentPlugin}
 * @param {HTMLTableElement} target Target instance.
 */
class UITableSortable {
    /**
     * @param {UITable} target
     */
    constructor(target) {
        this.target = target;
    }

    /**
     * @type {number}
     */
    static get SORT_ASC() {
        return 1;
    }

    /**
     * @type {number}
     */
    static get SORT_DESC() {
        return -1;
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-table-sortable', {
            sortBy: 'Sort by',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                label: String,
                compact: Boolean,
            },
        };
    }

    /**
     * Creates missing elements and assigns events for table to be fully
     * sortable.
     * @private
     */
    buildSortable() {
        if (!this.canBuildSortable()) {
            return;
        }
        const links = this.getSortableLinks();
        if (!links.length) {
            return;
        }

        [].forEach.call(links, (link) => {
            const th = this.getClosestColumn(link);
            let shouldPlaceButton = true;

            if (link.nodeName !== 'TH') {
                updateClassList(th, {
                    sort: true,
                    '-asc': link.classList.contains('-asc'),
                    '-desc': link.classList.contains('-desc'),
                    '-sorted': link.classList.contains('-sorted'),
                });
                updateClassList(link, {
                    sort: false,
                    '-asc': false,
                    '-desc': false,
                    '-sorted': false,
                });
                if (link.childNodes.length === 0) {
                    link.parentElement.removeChild(link);
                }
            }

            // if (!th.classList.contains('-asc') && !th.classList.contains('-desc')) {
            //   th.classList.add('-asc');
            // }

            if (
                th.firstElementChild instanceof HTMLElement &&
                ['BUTTON', 'A'].indexOf(th.firstElementChild.nodeName) !== -1
            ) {
                shouldPlaceButton = false;
            }

            if (shouldPlaceButton) {
                const button = this.target.createElement({
                    tagName: 'button',
                    attributes: {
                        type: 'button',
                    },
                    children: [].filter.call(
                        th.childNodes,
                        (child) => child.nodeName !== 'UI-TOOLTIP'
                    ),
                });
                th.insertAdjacentElement('afterbegin', button);
            }

            this.setAccessibilityAttrs(th);
        });
    }

    /**
     * Checks if table has thead and tbody and can build sortable.
     * @returns {boolean}
     * @private
     */
    canBuildSortable() {
        return !!(
            this.target.table.tHead || this.target.table.tBodies.length > 0
        );
    }

    /**
     * Handles event for sorting button clicks by checking direction of sorting.
     * @param {Event} event
     * @private
     */
    handleTableSort(event) {
        const link = event.target;
        // If tooltip is clicked do not sort.
        const isTooltip = closestByCond(
            link,
            (node) => node.nodeName === 'UI-TOOLTIP'
        );
        if (isTooltip) {
            return;
        }
        const th = this.getClosestColumn(link);
        const direction = this.getNextDirection(th);
        this.sort(th, direction, null);
        /**
         * @type {TableSortEventDetails}
         */
        const sortEventDetails = {
            column: th,
            columnId: th.id || null,
            direction: direction === UITableSortable.SORT_ASC ? 'asc' : 'desc',
            directionCode: direction,
        };
        dispatchCustomEvent(this.target, 'tablesort', sortEventDetails);
    }

    /**
     * Gets the closest column header element by checking an argument.
     * @private
     * @param {HTMLTableCellElement} element
     * @returns {HTMLTableCellElement}
     */
    getClosestColumn(element) {
        return element.nodeName === 'TH'
            ? element
            : closestByCond(element, (node) => node.nodeName === 'TH');
    }

    /**
     * Returns sorting direction by checking current direction of an element
     * and reversing it.
     * @private
     * @param {HTMLElement} element
     * @returns {number} Direction of sorting either 1 for ascending or -1 for descending.
     */
    getNextDirection(element) {
        const hasSorted = element.classList.contains('-sorted');
        return (!hasSorted && element.classList.contains('-asc')) ||
            (hasSorted && element.classList.contains('-desc'))
            ? UITableSortable.SORT_ASC
            : UITableSortable.SORT_DESC;
    }

    /**
     * Sorts table by provided th element from thead or by column number.
     * @param {HTMLTableCellElement|number} column - TH element or it's index.
     * from THEAD that needs to be sorted or numerical index of column
     * @param {number} [direction] - Ascending value is 1. Descending value is -1.
     * @param {Function} [compareFn] - Function that compares two values for sorting
     */
    sort(column, direction = 1, compareFn) {
        const index = typeof column === 'number' ? column : column.cellIndex;
        const th =
            column instanceof HTMLTableCellElement
                ? column
                : this.getColumnHeadByIndex(index);
        if (!th) {
            return;
        }
        const rows = this.getSortableRows();
        rows.sort((a, b) => {
            const x = a.children[index].innerText;
            const y = b.children[index].innerText;
            return (
                (typeof compareFn === 'function'
                    ? compareFn(x, y)
                    : compareValuesAsStrings(x, y)) * direction
            );
        });

        for (let i = 0; i < rows.length; i++) {
            const parent = rows[i].parentNode;
            const sibling = rows[i].nextElementSibling;
            const row = parent.removeChild(rows[i]);
            parent.appendChild(row);

            // Process expandable rows.
            if (sibling && sibling.classList.contains('-extra')) {
                row.insertAdjacentElement('afterend', sibling);
            }
        }
        this.processSortedColumn(th, direction);
    }

    /**
     * Returns all rows that are available to sort in the table. Colspans and rowspans
     * aren't sortable.
     * @private
     * @returns {Array<HTMLTableRowElement>}
     */
    getSortableRows() {
        return [].filter.call(this.target.table.tBodies[0].rows, (row) => {
            if (!this.checkUnsuitableCells(row)) {
                return row;
            }
        });
    }

    /**
     * Returns column head element that has provided index in the thead element.
     * @private
     * @param {number} index
     * @returns {HTMLTableCellElement}
     */
    getColumnHeadByIndex(index) {
        if (this.target.table.tHead) {
            return this.target.table.tHead.rows[0].cells[index];
        }
        return null;
    }

    /**
     * Applies required markings newly sorted column and removes sorting marks
     * from other columns.
     * @private
     * @param {HTMLTableCellElement} th
     * @param {number} order
     */
    processSortedColumn(th, order) {
        const links = this.getSortableLinks();
        [].forEach.call(links, (link) => {
            const th = this.getClosestColumn(link);
            updateElement(th, {
                classList: {
                    '-sorted': false,
                },
            });
        });
        updateElement(th, {
            classList: {
                '-sorted': true,
                '-asc': order === UITableSortable.SORT_ASC,
                '-desc': order === UITableSortable.SORT_DESC,
            },
        });
        this.setAccessibilityAttrs(th);
    }

    /**
     * Sets accessibility attributes.
     * @param {HTMLTableCellElement} th
     * @private
     */
    setAccessibilityAttrs(th) {
        let dir;
        if (th.classList.contains('-asc')) {
            dir = 'ascending';
        }
        if (th.classList.contains('-desc')) {
            dir = 'descending';
        }
        if (dir !== undefined) {
            th.setAttribute('aria-sort', dir);
        }
    }

    /**
     * Returns list of elements that are linked to column sorting
     * in the thead element.
     * @private
     * @returns {NodeListOf<HTMLElement>} Links with .sort classname
     */
    getSortableLinks() {
        return this.target.table.tHead.rows[0].querySelectorAll('.sort');
    }

    /**
     * Checks if table includes any unsupported types of cell, like those
     * spanned by row or column.
     * @param {HTMLTableRowElement} tr
     * @returns {boolean}
     */
    checkUnsuitableCells(tr) {
        return (
            tr.querySelector('[colspan], [rowspan]') instanceof
            HTMLTableCellElement
        );
    }

    /**
     * Builds compact sortable interface for smaller screens.
     */
    buildCompactSortable() {
        if (!this.canBuildSortable()) {
            return;
        }
        const spoiler = this.target.createElement({
            tagName: 'ui-curtain',
            attributes: {
                layout: 'spoiler',
                label: this.target.label || UITableSortable.labels.sortBy,
            },
        });
        [].forEach.call(this.target.querySelectorAll('.sort'), (sortElem) => {
            const div = this.target.createElement({
                tagName: 'div',
                attributes: {
                    class: sortElem.className,
                },
                children: [].map.call(sortElem.childNodes, (child) => {
                    if (child.nodeName !== 'UI-TOOLTIP') {
                        return child.cloneNode(true);
                    }
                }),
            });
            spoiler.appendChild(div);
        });
        const mobileSortContainer = this.target.createElement({
            tagName: 'div',
            classList: {
                'ui-table-sortable__sortcontainer': true,
                '-hidden': this.target.table.classList.contains('-auto'),
            },
            children: [spoiler],
        });
        this.target.insertAdjacentElement('afterbegin', mobileSortContainer);
    }

    /**
     * @inheritDoc
     */
    render() {
        this.target.table.classList.add('-sortable');
        this.buildSortable();
        this.buildCompactSortable();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        let applyEvents = true;
        if (!this.canBuildSortable()) {
            return;
        }
        const links = this.getSortableLinks();
        if (!links.length) {
            return;
        }

        [].forEach.call(links, (link) => {
            const th = this.getClosestColumn(link);
            // <a> tag means external link in the most cases in iBank.
            // Otherwise if need special external sorting behaviour apply -external-sort CSS class to
            // external <button> of any other control. This is mostly made to provide smooth transition
            // between legacy and web-components in iBank.
            if (
                th.firstElementChild.nodeName === 'A' ||
                th.firstElementChild.classList.contains('-external-sort')
            ) {
                applyEvents = false;
            } else {
                th.addEventListener('click', this.handleTableSort.bind(this));
            }
        });

        const sorted = this.target.table.querySelector('th.-sorted');
        if (sorted && applyEvents) {
            const order = sorted.classList.contains('-asc')
                ? UITableSortable.SORT_ASC
                : UITableSortable.SORT_DESC;
            this.sort(sorted, order);
        }

        const ths = this.target.table.querySelectorAll('.sort');
        const items = this.target.querySelectorAll(
            '.ui-table-sortable__sortcontainer .sort'
        );
        [].forEach.call(items, (item, i) => {
            item.addEventListener('click', (event) => {
                [].forEach.call(items, (elem) => {
                    elem.classList.remove('-sorted');
                });
                const isASC =
                    this.getNextDirection(ths[i]) === UITableSortable.SORT_ASC;
                updateClassList(item, {
                    '-asc': isASC,
                    '-desc': !isASC,
                    '-sorted': true,
                });
                ths[i].click();
            });
        });
    }
}

UITable.registerPlugin('sortable', UITableSortable);
adoptStyle(styles, 'ui-table-sortable-style').catch((err) =>
    console.error(err)
);

export { UITableSortable };

/**
 * @typedef TableSortEventDetails
 * @property {HTMLTableCellElement} column Column element
 * @property {string | null} columnId Column id
 * @property {string} direction 'asc' or 'desc'
 * @property {number} directionCode -1, 0, 1
 */

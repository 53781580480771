import { UIElement } from '../ui-element.js';
import styles from './ui-columns.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIColumns
 * @element ui-columns
 * @classdesc Represents a class for <code>ui-columns</code> element.
 * Represent columns layout, should contain only <b>&lt;ui-column></b> children elements.
 * @property {boolean} [wrap=false] {@attr wrap} Columns behave like flex-wrap CSS property.
 * @property {string} [colwidth] {@attr colwidth} Fixed width for column (not mandatory).
 * @property {("left" | "center" | "right" | "space-between" | "space-around")} [halign]
 *  {@attr halign} Horizontal alignment like flex.
 *  {@desc left}
 *  {@desc center}
 *  {@desc right}
 *  {@desc space-between}
 *  {@desc space-around}
 * @property {("top" | "bottom" | "center" | "stretch")} [valign] {@attr valign}
 *  Vertical alignment like flex.
 *  {@desc left}
 *  {@desc center}
 *  {@desc right}
 *  {@desc stretch}
 * @property {boolean} [inrow=false] {@attr inrow} If true, in mobile view columns
 *   will stay in a row.
 * @property {boolean} [bordered=false] {@attr bordered} Draw left border of each column except
 * last child.
 * @slot {@type "ui-column"}
 * @example
 * <ui-columns>
 *   <ui-column>I'm first column</ui-column>
 *   <ui-column>I'm second column</ui-column>
 *   <ui-column>I'm third column</ui-column>
 * </ui-columns>
 */
class UIColumns extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                wrap: Boolean,
                colwidth: String,
                halign: String,
                valign: String,
                inrow: Boolean,
                bordered: Boolean,
            },
        };
    }

    /**
     * Apply inline styles for columns width.
     */
    applyColumnWidth() {
        if (this.colwidth) {
            [].forEach.call(this.children, (node) => {
                node.style.flexBasis = this.colwidth;
                node.style.maxWidth = this.colwidth;
            });
        }
    }

    /**
     * Update columns appearance with colwidth.
     */
    updateColumnsAppearance() {
        if (
            this.mobileMediaQueryList &&
            this.mobileMediaQueryList.matches === true &&
            !this.inrow
        ) {
            [].forEach.call(this.children, function (node) {
                node.removeAttribute('style');
            });
        } else {
            this.applyColumnWidth();
        }
    }

    /**
     * Handle event when media changed desktop to mobile and vice versa.
     * @private
     */
    handleChangeAppearance() {
        this.updateColumnsAppearance();
    }

    /**
     * @inheritDoc
     */
    handleControlMutations() {
        this.updateColumnsAppearance();
    }

    /**
     * @inheritDoc
     */
    render() {
        if (!window.matchMedia('(max-width: 767px)').matches) {
            this.applyColumnWidth();
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.mobileMediaQueryList = window.matchMedia('(max-width: 767px)');
        this.handleChangeAppearance();
        this.mobileMediaQueryList.addListener(
            this.handleChangeAppearance.bind(this)
        );

        this.observer = new MutationObserver(
            this.handleControlMutations.bind(this)
        );
        this.observer.observe(this, {
            childList: true,
        });
    }
}

UIColumns.defineElement('ui-columns', styles);
export { UIColumns };

import { UIElement } from '../ui-element.js';
import styles from './ui-view.css';

/**
 * @memberof SharedComponents
 * @alias UIView
 * @element ui-view
 * @augments {UIElement}
 * @implements {IActivatable}
 * @classdesc Represents a class for <code>ui-view</code> element.
 * Element for changing views, can be only child of ui-views container.
 * @fires event:change
 * @property {boolean} [active] {@attr active} If true, current view will be active,
 * otherwise inactive.
 * @property {string} [label] {@attr label} The caption of current view,
 * it is used for tabs, wizard etc.
 * @slot
 * @example <ui-view label="This is a header"></ui-view>
 */
class UIView extends UIElement {
    static get observedAttributes() {
        return ['active'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                active: Boolean,
                label: String,
            },
        };
    }

    /**
     * Changes current view to active (visible) state.
     * @fires event:change
     * @returns {UIView}
     */
    activate() {
        if (this.active) {
            return this;
        }
        this.active = true;
        this.dispatchNativeEvent('change', false);
        return this;
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'active':
                this.active ? this.show() : this.hide();
                break;
        }
    }
}

UIView.defineElement('ui-view', styles);
export { UIView };

import { Labels } from '../../global/labels.js';
import { UIElement } from '../ui-element.js';
import { resolveVideoHost } from '../../global/helpers.js';
import { TabIndex } from '../../global/keyboard.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-video.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIVideo
 * @element ui-video
 * @classdesc Represents a class for <code>ui-video</code> element.
 * Marketing element allows to play videos from youtube. Maybe in
 * future more video sources will be implemented.
 * @property {string} [poster] {@attr poster} Background image for video container.
 * @property {string} [src] {@attr src} URL of the video.
 * Only YouTube video URLs are supported at the moment.
 * @property {string} [alt=""] {@attr alt} alternate text for image.
 * Same as alt for &lt;img>.
 * @property {("external" | "embed" | "modal")} [type=external]
 *   {@attr type} How video should be opened.
 *   {@desc external: opens video in new tab/window}
 *   {@desc embed: insert video just instead of wallpaper}
 *   {@desc modal: video will be opened in modal dialog}
 * @property {HTMLButtonElement} button Shortcut to play button element.
 * @example
 * <ui-video
 *    poster="assets/mocks/images/offer08.jpg"
 *    type="modal"
 *    src="https://www.youtube.com/watch?v=qRc7PrxxJNs"></ui-video>
 */
class UIVideo extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                poster: String,
                src: String,
                alt: String,
                type: { type: String, default: 'external' },
            },
            children: {
                button: '.ui-video__icon-container',
            },
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-video', {
            play: 'Play video',
        });
    }

    /**
     * @type {{UNKNOWN: string, YOUTUBE: string}}
     */
    static get HOSTS() {
        return {
            UNKNOWN: 'unknown',
            YOUTUBE: 'youtube',
        };
    }

    /**
     * Extracts from URL YouTube video id.
     * @returns {string}
     * @private
     */
    getYoutubeVideoId() {
        const vidRegExp =
            /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^?&"'>]+)/;
        try {
            return String(this.src).match(vidRegExp)[5];
        } catch (e) {
            return '';
        }
    }

    /**
     * Creates video modal dialog.
     * @returns {UIModal}
     * @private
     */
    createVideoWrapper() {
        const videoHost = resolveVideoHost(this.src);
        const videoId = this.getYoutubeVideoId();
        let videoURL = '';
        if (videoHost === UIVideo.HOSTS.YOUTUBE && videoId) {
            videoURL = `https://www.youtube-nocookie.com/embed/${videoId}?rel=0&autoplay=1`;
        } else {
            return null;
        }
        const wrapper = this.createElement({
            tagName: 'div',
            classList: {
                'ui-video__wrapper': true,
            },
        });
        const iframe = this.createElement({
            tagName: 'iframe',
            attributes: {
                tabindex: TabIndex.Active,
                allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                allowfullscreen: true,
                src: videoURL,
                frameborder: 0,
            },
        });
        wrapper.appendChild(iframe);
        return wrapper;
    }

    /**
     * Video open callback.
     * @param {Event} e
     * @private
     */
    handleVideoOpen(e) {
        e.preventDefault();
        const wrapper = this.createVideoWrapper();
        this.classList.add('-playing');
        switch (this.type) {
            case 'modal':
                this.dispatchCustomEvent('modal-open', {
                    type: 'page',
                    content: wrapper,
                });
                break;
            case 'embed':
                this.appendChild(wrapper);
                break;
            default:
                window.open(this.src);
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        if (this.poster) {
            this.appendChild(
                this.createElement({
                    tagName: 'img',
                    attributes: {
                        src: this.poster,
                        alt: this.alt || '',
                    },
                })
            );
        }
        this.appendChild(
            this.createElement({
                tagName: 'button',
                classList: {
                    'ui-video__icon-container': true,
                    '-iconed': true,
                },
                attributes: {
                    type: 'button',
                    'aria-label': UIVideo.labels.play,
                },
                children: [
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph: 'play',
                            bgcolor: UIIcon.colors.ORANGE,
                            color: UIIcon.colors.WHITE,
                            size: 'large',
                        },
                        classList: {
                            '-navicon': true,
                        },
                    },
                ],
            })
        );
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.button.addEventListener('click', this.handleVideoOpen.bind(this));
    }
}

UIVideo.defineElement('ui-video', styles);
export { UIVideo };

import { UISlider } from '../../components/slider/ui-slider.js';
import { adoptStyle, insertElements } from '../../global/ui-helpers.js';
import styles from './ui-slider-limit-offer.css';

/**
 * @memberof SharedComponents
 * @classdesc Class for ui-slider offer plugin.
 * @alias UISliderLimitOffer
 * @property {number} [limitOffer] {@attr limit-offer} Adds offer limit to slider.
 * @property {string} [labelLimitOffer] {@attr label-limit-offer} Adds offer
 * limit label for slider.
 * @param {UIElement} target
 * @implements {IComponentPlugin}
 */
class UISliderLimitOffer {
    constructor(target) {
        this.target = target;
    }

    get limitOffer() {
        return Number(this.target.getAttribute('limit-offer'));
    }

    set limitOffer(value) {
        this.target.setAttribute('limit-offer', !value ? 0 : value);
        this.updateLimitLabel();
        this.setLimitOfferPosition();
    }

    get labelLimitOffer() {
        return this.target.getAttribute('label-limit-offer');
    }

    set labelLimitOffer(value) {
        this.target.setAttribute('label-limit-offer', value);
        this.updateLimitLabel();
        this.setLimitOfferPosition();
    }

    get offer() {
        return this.target.querySelector('.ui-slider__limit-offer');
    }

    get offerLabel() {
        return this.target.querySelector('.ui-slider__label-limit-offer');
    }

    get offerLabelArrow() {
        return this.target.querySelector('.ui-slider__label-limit-offer-arrow');
    }

    /**
     * Formats label offer limit, replaced sub-sting {limit} with value.
     * @returns {string}
     */
    formatLabelLimitOffer() {
        return String(this.labelLimitOffer).replace('{limit}', this.limitOffer);
    }

    /**
     * Updates limit label.
     */
    updateLimitLabel() {
        this.offerLabel.innerText = this.formatLabelLimitOffer();
    }

    /**
     * Sets limit offer position.
     */
    setLimitOfferPosition() {
        const lim =
            this.limitOffer > this.target.max
                ? this.target.max
                : this.limitOffer;
        const progress = this.target.calcProgress(lim);
        if (lim) {
            this.target.adjustElementPosition(this.offer, progress);
        }
        if (this.labelLimitOffer) {
            this.target.adjustElementPosition(this.offerLabel, progress);
            this.target.adjustElementPosition(this.offerLabelArrow, progress);
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        if (this.limitOffer) {
            insertElements(this.target.bar, [
                {
                    tagName: 'div',
                    classList: {
                        'ui-slider__limit-offer': true,
                    },
                },
            ]);
        }

        if (this.labelLimitOffer) {
            insertElements(this.target.container, [
                {
                    tagName: 'div',
                    classList: {
                        'ui-slider__additional-limit': true,
                    },
                    children: [
                        {
                            tagName: 'span',
                            classList: {
                                'ui-slider__label-limit-offer-arrow': true,
                            },
                        },
                        {
                            tagName: 'label',
                            classList: {
                                'ui-slider__label-limit-offer': true,
                            },
                            children: [this.formatLabelLimitOffer()],
                        },
                    ],
                },
            ]);
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.limitOfferPositioner = this.setLimitOfferPosition.bind(this);
        this.target.addEventListener('sync', this.limitOfferPositioner);
        window.addEventListener('resize', this.limitOfferPositioner);
        this.limitOfferPositioner();
    }

    /**
     * @inheritDoc
     */
    reconnect() {
        window.addEventListener('resize', this.limitOfferPositioner);
        this.target.addEventListener('sync', this.limitOfferPositioner);
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        window.removeEventListener('resize', this.limitOfferPositioner);
        this.target.removeEventListener('sync', this.limitOfferPositioner);
    }
}

UISlider.registerPlugin('limit-offer', UISliderLimitOffer);
adoptStyle(styles, 'ui-slider-limit-offer-style').catch((err) =>
    console.error(err)
);

export { UISliderLimitOffer };

import { mutateTo } from './mutations.js';
import { createReactiveElement } from './reactive-elements.js';
import { createElement } from './render-api.js';

/**
 * @memberof SharedComponents
 * @class Loader
 * @alias loader
 * @classdesc
 * Helper methods for adding progress loaders.
 */

/**
 * @typedef {object} IButtonLoaderOptions
 * @property {boolean} [fast] Animation speed
 * @property {boolean} [disabled] Disabled state
 * @property {boolean} [loading] Loading state
 */

/**
 * @typedef {Proxy} IButtonLoader
 * @property {boolean} loading Loading state
 */

/**
 * Toggle loader icon for button
 * @memberof Loader
 * @param {HTMLButtonElement} button
 * @param {boolean} [active]
 * @param {IButtonLoaderOptions} [options]
 * @returns {IElementConfig}
 */
const buildButtonLoaderConfig = (button, active, options = {}) => {
    const existingIcon = button.querySelector('ui-icon[glyph="loader"]');
    if (!active && existingIcon) {
        existingIcon.remove();
    }
    return {
        element: button,
        attributes: {
            disabled: options.disabled && active ? options.disabled : null,
        },
        classList: {
            '-loading': active,
        },
        children: [
            active
                ? createElement({
                      tagName: 'ui-icon',
                      element: existingIcon,
                      attributes: {
                          glyph: 'loader',
                          color: 'white',
                          fast: options.fast ? options.fast : null,
                      },
                  })
                : null,
        ],
    };
};

/**
 * Adds loader icon for button.
 * @memberof Loader
 * @param {HTMLButtonElement} button
 * @param {IButtonLoaderOptions} [options]
 * @returns {IButtonLoader}
 */
const addButtonLoader = (button, options = {}) => {
    /**
     * @param {{loading: boolean}} state
     * @returns {IElementConfig}
     */
    const render = (state) =>
        buildButtonLoaderConfig(button, state.loading, options);
    const initialState = { loading: options.loading || false };
    return createReactiveElement(render, initialState).reactiveState;
};

/**
 * Toggle loader icon for button
 * @memberof Loader
 * @param {HTMLButtonElement} button
 * @param {boolean} [active]
 * @param {IButtonLoaderOptions} [options]
 */
const toggleLoaderInButton = (button, active, options = {}) => {
    mutateTo(
        button,
        createElement(buildButtonLoaderConfig(button, active, options))
    );
};

export { addButtonLoader, toggleLoaderInButton, buildButtonLoaderConfig };

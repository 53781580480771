import { UIElement } from '../ui-element.js';
import { updateElement } from '../../global/ui-helpers.js';
import styles from './ui-views.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIViews
 * @element ui-views
 * @classdesc Represents a class for <code>ui-views</code> element.
 * Should contain only ui-views children.
 * @fires event:change
 * @property {string} [pattern] {@attr pattern} Child nodes matched by pattern
 * would be considered as views.
 * @property {string} [filter='ui-view'] {@attr filter} Filter selector.
 * @property {boolean} [focusonchange] {@attr focusonchange} Force focus to
 * the first interactive element
 * @property {number} [selectedIndex] {@attr selected-index} Default child UIView
 * will be visible by default.
 * @slot {@type "ui-view"}
 * @example
 * <ui-views selected-index="2">
 *   <ui-view></ui-view>
 *   <ui-view>I am active! Yepppeeee!</ui-view>
 *   <ui-view></ui-view>
 * </ui-views>
 */
class UIViews extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['selected-index'];
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                filter: { type: String, default: 'ui-view' },
                focusonchange: Boolean,
            },
        };
    }
    get selectedIndex() {
        const active = this.getActiveView();
        return active ? this.getViews().indexOf(active) : 0;
    }
    set selectedIndex(value) {
        this.getViews().forEach(
            (node, index) => (node.active = value === index)
        );
        if (this.hasAttribute('selected-index')) {
            this.setAttribute('selected-index', value + 1);
        }
    }

    /**
     * Returns list of the all views.
     * @returns {Array<UIView> }
     */
    getViews() {
        const pattern = this.filter;
        return [].filter.call(this.children, (node) => {
            return (
                node.nodeType !== Node.TEXT_NODE &&
                node.tagName === pattern.toUpperCase()
            );
        });
    }

    /**
     * Returns an active view (first one if there are multiple).
     * @returns {UIView}
     */
    getActiveView() {
        return this.getViews().filter((view) => view.active)[0];
    }

    /**
     * Selects item by given index.
     * @param {number} index Index of views to select.
     * @returns {UIViews}
     */
    selectByIndex(index) {
        const view = this.getViews()[index];
        if (view) {
            view.activate();
        }
        return this;
    }

    /**
     * Selects item by id/item-id attributes.
     * @override
     * @returns {UIViews}
     */
    selectById(id) {
        const view = this.getViews().filter((view) => {
            return (
                view.getAttribute('id') === id ||
                view.getAttribute('item-id') === id
            );
        })[0];
        if (view) {
            view.activate();
        }
        return this;
    }

    /**
     * Activates default view.
     * @returns {UIViews} Reference to itself
     */
    activateDefaultView() {
        const views = this.getViews();

        if (views.length === 0) {
            return this;
        }

        const activeViews = views.filter((view) => view.active);

        if (activeViews.length > 1) {
            activeViews.slice(1, activeViews.length).forEach((view) => {
                view.active = false;
            });
        }

        if (activeViews.length === 0) {
            let defaultIndex = 0;
            if (this.hasAttribute('selected-index')) {
                defaultIndex = Number(this.getAttribute('selected-index')) - 1;
                if (!views[defaultIndex]) {
                    defaultIndex = 0;
                }
            }
            views[defaultIndex].active = true;
        }

        views.forEach((view) => {
            view.active ? view.show() : view.hide();
        });

        return this;
    }

    /**
     * Fires callback when the selected-index is changed.
     * @param {string} oldValue
     * @param {string} newValue
     * @private
     */
    onSelectedIndexChanged(oldValue, newValue) {
        const val = Number(newValue) - 1;
        if (val === this.selectedIndex || this.getAttribute('disabled')) {
            return;
        }
        this.selectByIndex(val);
    }

    /**
     * Fires callback when the active view is changed.
     * @param {Event} e
     * @private
     */
    handleViewChange(e) {
        const node = e.target;
        if (
            node.nodeType === Node.TEXT_NODE ||
            node.tagName.toLowerCase() !== this.filter ||
            !node.active
        ) {
            return;
        }
        this.getViews().forEach((view) => {
            if (view !== node) {
                view.active = false;
            }
        });
        if (this.focusonchange) {
            node.focusInteractiveElement();
        }
        this.dispatchNativeEvent('change', false);
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        /* istanbul ignore if */
        if (!this.hydrated) {
            return;
        }
        switch (name) {
            case 'selected-index':
                this.onSelectedIndexChanged(oldValue, newValue);
                break;
            default:
                break;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const views = this.queryChildren(this.filter);
        this.detachChildNodes();
        if (views.length === 0) {
            return;
        }
        this.insertElements(views);
        this.activateDefaultView();
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        this.getViews().forEach((view) => {
            updateElement(view, {
                events: {
                    change: this.handleViewChange.bind(this),
                },
            });
        });
    }
}

UIViews.defineElement('ui-views', styles);
export { UIViews };

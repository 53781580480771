import { UIElement } from '../ui-element.js';
import styles from './ui-awarddetails.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIAwardDetails
 * @element ui-awarddetails
 * @classdesc Represents a class for <code>ui-awarddetails</code> element.
 * Detailed view of award of offer or reward programme in iBank.
 * @property {HTMLButtonElement} prevBtn - {@readonly} Short cut to previous button.
 * @property {HTMLButtonElement} nextBtn - {@readonly} Short cut to next button.
 * @example
 * <ui-awarddetails></ui-awarddetails>
 */
class UIAwardDetails extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            children: {
                prevBtn: 'button.-prev',
                nextBtn: 'button.-next',
            },
        };
    }
}

UIAwardDetails.defineElement('ui-awarddetails', styles);
export { UIAwardDetails };

import '../modal/ui-modal-controller.js';
import { Labels } from '../../global/labels.js';
import { Digest } from '../../global/digest.js';
import { UIElement } from '../ui-element.js';
import {
    makePopoverHandler,
    appendModalControllerIfRequired,
} from '../../global/helpers.js';
import { isParentOf } from '../../global/ui-helpers.js';
import { TabIndex } from '../../global/keyboard.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-tooltip.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UITooltip
 * @element ui-tooltip
 * @classdesc Represents a class for <code>ui-tooltip</code> element.
 * Tooltip is a button with icon, which opens help dialog on click.
 * @property {boolean} [expanded] {@attr expanded} Tooltip is opened or not.
 * @property {string} [labelClose] {@attr label-close} Label for close button.
 * @property {string} [labelOpen] {@attr label-open} Label for open button.
 * @property {string} [labelLink] {@attr label-link} Label for link.
 * @property {("question" | "option" | "more" | "important")} [type="question"] {@attr type}
 * Type of the tooltip.
 *  {@desc question}
 *  {@desc option}
 *  {@desc more}
 *  {@desc important}
 * @property {("left" | "right")} [captionPosition] {@attr caption-position}
 * Position of the caption.
 *  {@desc left}
 *  {@desc right}
 * @property {HTMLButtonElement} popoverElement Shortcut to popover element.
 * @property {HTMLDivElement} content Shortcut to content element.
 * @property {HTMLButtonElement} control Shortcut to content element.
 * @property {HTMLButtonElement} close Shortcut to close button.
 * @slot
 * @example
 * <ui-tooltip type="question" label-open="Open" label-close="Close">
 *   Any content you want inside the balloon comes here.
 * </ui-tooltip>
 */
class UITooltip extends UIElement {
    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['expanded'];
    }

    /**
     * Provides getter for "defaultAttributes" property
     * Default values can be set here so if there is no value at the time of creation of element,
     * defaults will be used
     * @returns {Record<string, string>}
     */
    get defaultAttributes() {
        return {
            'label-link': '',
            type: 'question',
            'label-open': UITooltip.labels.open,
            'label-close': UITooltip.labels.close,
            'caption-position': 'right',
        };
    }

    /**
     * Define labels what could be localised
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-tooltip', {
            open: 'Open tooltip',
            close: 'Close tooltip',
        });
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                expanded: Boolean,
                labelClose: String,
                labelOpen: String,
                labelLink: String,
                type: { type: String, default: 'question' },
                captionPosition: String,
            },
            children: {
                content: '.ui-tooltip__content',
                control: '.ui-tooltip__control',
                close: '.ui-tooltip__close',
            },
        };
    }
    get popoverElement() {
        if (this._detachedPopover) {
            return this._detachedPopover;
        }
        return this.querySelector('.ui-tooltip__popover');
    }

    /**
     * Gets popover height.
     * @returns {number}
     */
    getPopoverHeight() {
        return this.popoverElement.clientHeight;
    }

    /**
     * Gets popover width.
     * @returns {number}
     */
    getPopoverWidth() {
        return this.popoverElement.clientWidth;
    }

    /**
     * @fires event:modal-open
     */
    openDetachedPopover() {
        if (this._detachedPopover) {
            return;
        }
        const popoverHandler = makePopoverHandler(
            this.control.querySelector('ui-icon') || this.control,
            {
                popoverHeight:
                    /** @type {number} */ this.getPopoverHeight.bind(this),
                verticalOffset: 12,
                popoverWidth:
                    /** @type {number} */ this.getPopoverWidth.bind(this),
                horizontalOffset: -20,
                supportMiddleAlignment: false,
            }
        );
        this._detachedPopover = this.popoverElement;
        this.dispatchCustomEvent('modal-open', {
            type: 'popover',
            content: this.popoverElement,
            params: {
                classList: {
                    '-tooltip': true,
                    '-balloon': this.type === 'balloon',
                    '-basic': this.type === 'basic',
                    '-plain': this.type === 'plain',
                },
                position: () => {
                    if (window.innerWidth < 768) {
                        return {
                            align: 'fixed',
                        };
                    }
                    return popoverHandler();
                },
                target: this,
                onOpen: () => {
                    this.popoverElement && this.popoverElement.focus();
                },
                onClose: () => {
                    this.control.focus();
                    this.expanded = false;
                },
            },
        });
    }

    closeDetachedPopover() {
        if (!this._detachedPopover) {
            return;
        }
        const modal = this._detachedPopover.closest('ui-modal');
        if (modal) {
            this._detachedPopover = null;
            modal.close();
        }
    }

    /**
     * Fires callback when the popover state has been changed
     * @private
     */
    onChangePopoverState() {
        if (!this.popoverElement) {
            return;
        }

        if (this.expanded) {
            this.openDetachedPopover();
            document.addEventListener('click', this.handleClickOutside);
        } else {
            this.closeDetachedPopover();
            document.removeEventListener('click', this.handleClickOutside);
        }
    }

    /**
     * Closes popover element.
     */
    closePopover() {
        this.expanded = false;
        if (
            !document.activeElement ||
            document.activeElement === document.body
        ) {
            this.control.focus();
        }
    }

    /**
     * Toggles popover status - show / hide.
     * @private
     */
    togglePopover() {
        this.expanded = !this.expanded;
    }

    /**
     * Fires callback when user clicked outside of tooltip element.
     * @param {Event} event
     * @private
     */
    handleClickOutside(event) {
        if (
            this.expanded &&
            !isParentOf(this, event.target) &&
            !isParentOf(this.popoverElement, event.target)
        ) {
            this.closePopover();
        }
    }

    /**
     * Fires callback when user clicks on the tooltip control
     * @private
     */
    handleClickOnControl() {
        this.togglePopover();
    }

    /**
     * @inheritDoc
     */
    disconnect() {
        this.closeDetachedPopover();
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'expanded':
                this.onChangePopoverState();
                break;
        }
    }

    /**
     * Resolves opening label for screen-readers.
     * @private
     * @returns {string}
     */
    resolveAccessibilityOpenLabel() {
        if (this.labelOpen) {
            return this.labelOpen;
        }
        if (this.labelLink) {
            return this.labelLink;
        }
        return UITooltip.labels.open;
    }

    /**
     * Resolves closing label for screen-readers.
     * @returns {string}
     * @private
     */
    resolveAccessibilityCloseLabel() {
        if (this.labelClose) {
            return this.labelClose;
        }
        return UITooltip.labels.close;
    }

    /**
     * @inheritDoc
     */
    render() {
        const popoverId = Digest.randomId();
        this.insertElements([
            {
                tagName: 'button',
                attributes: {
                    type: 'button',
                    'aria-haspopup': 'dialog',
                    'aria-controls': popoverId,
                    'aria-label': this.resolveAccessibilityOpenLabel(),
                },
                classList: {
                    'ui-tooltip__control': true,
                    '-iconed': true,
                },
                children: [
                    this.labelLink &&
                        this.captionPosition === 'left' && {
                            tagName: 'span',
                            children: [this.labelLink],
                        },
                    {
                        tagName: 'ui-icon',
                        attributes: {
                            glyph:
                                this.type === 'option' || this.type === 'more'
                                    ? 'options'
                                    : this.type === 'important'
                                      ? 'exclamation'
                                      : 'question',
                            bgcolor: UIIcon.colors.BARK_30,
                        },
                    },
                    this.labelLink &&
                        this.captionPosition !== 'left' && {
                            tagName: 'span',
                            children: [this.labelLink],
                        },
                ],
            },
            {
                tagName: 'div',
                attributes: {
                    class: 'ui-tooltip__popover',
                    id: popoverId,
                    tabindex: TabIndex.Active,
                },
                children: [
                    {
                        tagName: 'div',
                        attributes: {
                            class: 'ui-tooltip__content',
                        },
                        children: this.detachChildNodes(),
                    },
                    {
                        tagName: 'button',
                        attributes: {
                            type: 'button',
                            title: this.resolveAccessibilityCloseLabel(),
                        },
                        classList: {
                            'ui-tooltip__close': true,
                            '-visually-hidden': true,
                            '-iconed': true,
                        },
                        children: [
                            {
                                tagName: 'ui-icon',
                                attributes: {
                                    glyph: 'cross',
                                    color: UIIcon.colors.DEFAULT,
                                },
                            },
                        ],
                    },
                    {
                        tagName: 'div',
                        classList: {
                            'ui-tooltip__pointer': true,
                        },
                    },
                ],
            },
        ]);
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        appendModalControllerIfRequired();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.control.addEventListener(
            'click',
            this.handleClickOnControl.bind(this)
        );
        this.close.addEventListener('click', this.closePopover.bind(this));
        this.onChangePopoverState();
    }
}

UITooltip.defineElement('ui-tooltip', styles);
export { UITooltip };

/**
 * @memberof SharedComponents
 * @alias Digest
 * @type {Digest}
 * @classdesc Contains methods to works with Hashing and cryptography.
 */
export class Digest {
    /**
     * @type {{
     *   SHA1: "SHA-1",
     *   SHA256: "SHA-256",
     *   SHA384: "SHA-384",
     *   SHA512: "SHA-512"
     * }}
     */
    static get HashingAlgos() {
        return {
            SHA1: 'SHA-1',
            SHA256: 'SHA-256',
            SHA384: 'SHA-384',
            SHA512: 'SHA-512',
        };
    }

    /**
     * @param {string} text
     * @param {HashingAlgo} algo
     * @returns {Promise<ArrayBuffer>}
     */
    static async hash(text, algo) {
        return await window.crypto.subtle.digest(
            algo,
            new TextEncoder().encode(text)
        );
    }

    /**
     * Implements SHA1 (Simple Hash Algorithm) and returns value as hex string.
     * @param {string} text
     * @returns {Promise<string>}
     */
    static async sha1(text) {
        const buf = await Digest.hash(text, Digest.HashingAlgos.SHA1);
        return Digest.bufferToString(buf);
    }

    /**
     * Implements SHA256 (Simple Hash Algorithm) and returns value as hex string.
     * @param {string} text
     * @returns {Promise<string>}
     */
    static async sha256(text) {
        const buf = await Digest.hash(text, Digest.HashingAlgos.SHA256);
        return Digest.bufferToString(buf);
    }

    /**
     * Implements SHA384 (Simple Hash Algorithm) and returns value as hex string.
     * @param {string} text
     * @returns {Promise<string>}
     */
    static async sha384(text) {
        const buf = await Digest.hash(text, Digest.HashingAlgos.SHA384);
        return Digest.bufferToString(buf);
    }

    /**
     * Implements SHA512 (Simple Hash Algorithm) and returns value as hex string.
     * @param {string} text
     * @returns {Promise<string>}
     */
    static async sha512(text) {
        const buf = await Digest.hash(text, Digest.HashingAlgos.SHA512);
        return Digest.bufferToString(buf);
    }

    /**
     * @param {ArrayBuffer} buffer
     * @returns {string}
     */
    static bufferToString(buffer) {
        return [...new Uint8Array(buffer)]
            .map((x) => {
                return /** @type {number} */ x.toString(16).padStart(2, '0');
            })
            .join('');
    }

    /**
     * Creates uuidv4 random string hash.
     * @param {string} [prefix]
     * @returns {string}
     */
    static uuidv4(prefix = '') {
        if (typeof window.crypto.randomUUID === 'function') {
            return prefix + window.crypto.randomUUID();
        }
        return ('' + prefix + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(
            /[01]/g,
            () => {
                return (0 | (Math.random() * 16)).toString(16);
            }
        );
    }

    /**
     * Converts UTF-8 string to base64
     * @param {string} s
     * @returns {string}
     */
    static base64UnicodeEncode(s) {
        return btoa(
            encodeURIComponent(s).replace(/%([0-9A-F]{2})/g, (match, p1) =>
                String.fromCharCode(parseInt(p1, 16))
            )
        );
    }

    /**
     * Converts base64 to UTF-8 string
     * @param {string} s
     * @returns {string}
     */
    static base64UnicodeDecode(s) {
        return decodeURIComponent(
            [].map
                .call(
                    atob(s),
                    (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                )
                .join('')
        );
    }

    /**
     * Generates random id with given length and prefix, by default prefixed with "id-"
     * @example uid-bb8def8a
     * @param {number} [len]
     * @param {string} [prefix]
     * @returns {string}
     */
    static randomId(len = 8, prefix = 'uid-') {
        const arr = new Uint8Array((len || 40) / 2);
        window.crypto.getRandomValues(arr);
        return (
            prefix +
            Array.from(arr, (dec) => dec.toString(16).padStart(2, '0')).join('')
        );
    }
}

/**
 * @typedef {("SHA-1" | "SHA-256" | "SHA-384" | "SHA-512")} HashingAlgo
 */

import { Labels } from '../../global/labels.js';
import { UIDateElement } from './ui-dateelement.js';
import {
    updateElement,
    createElement,
    insertElements,
    detachChildNodes,
    updateClassList,
    buildSyntheticFocusControl,
    addSyntheticFocusVisible,
} from '../../global/ui-helpers.js';
import styles from './ui-daterange.css';
import { getDaysInMonth } from '../../global/helpers.js';

/**
 * @memberof SharedComponents
 * @augments {UIDateElement}
 * @alias UIDateRange
 * @element ui-daterange
 * @classdesc Represents a class for <code>ui-daterange</code> element.
 * Uses 2 ui-datepickers to pick date range.
 * You can defined &lt;ui-datalist> with &tl;ui-option value=""> elements to make predefined
 * cloud links.
 * Possible values are:
 * TODAY - both datepickers are equal;
 * YESTERDAY - range between yesterday and today;
 * CURRENT_WEEK - range between beginning of the current week and today's date;
 * CURRENT_MONTH - range between beginning of the current month and today's date;
 * CURRENT_MONTH - range between beginning of the current year and today's date;
 * PREV_YEAR - range of last year;
 * CURRENT_YEAR - range of current year from 1st of January until current date;
 * PREV_WEEK - range of previous week;
 * PREV_MONTH - range of previous month;
 * PREV_MONTH_TODAY - range between previous month and today's date.
 * @property {string} [controlId] {@attr control-id} Control (input element) id, if not set
 * will be generated automatically.
 * @property {boolean} allowEmpty {@attr allow-empty} If true, empty values won't be replaced
 * with default date, or date from another picker.
 * @property {HTMLButtonElement} focusTrigger {@readonly} Shortcut to focus trigger element.
 * @property {UIDatePicker} pickerFrom {@readonly} Shortcut to datepicker-from element.
 * @property {UIDatePicker} pickerTo {@readonly} Shortcut to datepicker-to element.
 * @property {Array<HTMLButtonElement>} cloudLinks {@readonly} Shortcut to the array of links.
 * @property {Function} cloudLinkCallback Callback when links from clouds is clicked.
 * @slot
 * @example
 * <ui-daterange min="20.02.2019" max="25.02.2019">
 *   <ui-datepicker>
 *     <input name="dateFrom" value="21.02.2019" type="text">
 *   </ui-datepicker>
 *   <ui-datepicker>
 *     <input name="dateTo" value="23.02.2019" type="text">
 *   </ui-datepicker>
 *   <ui-datalist>
 *     <ui-option value="TODAY"></ui-option>
 *     <ui-option value="CURRENT_WEEK"></ui-option>
 *     <ui-option value="CURRENT_MONTH"></ui-option>
 *     <ui-option value="CURRENT_YEAR"></ui-option>
 *   </ui-datalist>
 * </ui-daterange>
 */
class UIDateRange extends UIDateElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                controlId: String,
                allowEmpty: Boolean,
            },
            children: {
                pickerFrom: 'ui-datepicker.-from',
                pickerTo: 'ui-datepicker.-to',
                cloudLinks: {
                    selector: '.ui-daterange__cloud-link button',
                    multiple: true,
                },
                focusTrigger: '.ui-daterange__focus-trigger',
            },
        };
    }

    /**
     * @type {UIDateRange}
     * @readonly
     */
    get control() {
        return this;
    }

    /**
     * @type {number}
     * @readonly
     */
    get tsFrom() {
        return this.dateStringToTimestamp(this.pickerFrom.value);
    }

    /**
     * @type {number}
     * @readonly
     */
    get tsTo() {
        return this.dateStringToTimestamp(this.pickerTo.value);
    }

    /**
     * @type {boolean}
     * @readonly
     */
    get shouldSync() {
        return (
            this.pickerTo.control.disabled ||
            this.pickerTo.control.readOnly ||
            this.pickerFrom.control.disabled ||
            this.pickerFrom.control.readOnly
        );
    }

    /**
     *
     * @returns {{
     *   PREV_MONTH_TODAY: 'PREV_MONTH_TODAY',
     *   CURRENT_YEAR: 'CURRENT_YEAR',
     *   PREV_MONTH: 'PREV_MONTH',
     *   TODAY: 'TODAY',
     *   PREV_WEEK: 'PREV_WEEK',
     *   YESTERDAY: 'YESTERDAY',
     *   CURRENT_WEEK: 'CURRENT_WEEK',
     *   CURRENT_MONTH: 'CURRENT_MONTH',
     *   PREV_YEAR: 'PREV_YEAR'
     * }}
     */
    static get cloudLinksTypes() {
        return {
            TODAY: 'TODAY',
            YESTERDAY: 'YESTERDAY',
            CURRENT_WEEK: 'CURRENT_WEEK',
            CURRENT_MONTH: 'CURRENT_MONTH',
            CURRENT_YEAR: 'CURRENT_YEAR',
            PREV_YEAR: 'PREV_YEAR',
            PREV_WEEK: 'PREV_WEEK',
            PREV_MONTH: 'PREV_MONTH',
            PREV_MONTH_TODAY: 'PREV_MONTH_TODAY',
        };
    }

    /**
     * @type {UILabelType}
     * @readonly
     */
    static get labels() {
        return Labels.attach('ui-daterange', {
            TODAY: 'Today',
            YESTERDAY: 'Yesterday',
            CURRENT_WEEK: 'Current week',
            CURRENT_MONTH: 'Current month',
            CURRENT_YEAR: 'Year to date',
            PREV_YEAR: 'Last year',
            PREV_WEEK: 'Last week',
            PREV_MONTH: 'Previous month',
            PREV_MONTH_TODAY: 'Last month and this month',
            PERIOD_START: 'Period start',
            PERIOD_END: 'Period end',
            TIME_START: 'Time start',
            TIME_END: 'Time end',
        });
    }

    /**
     * Provides list of observed attributes to be watched
     * @returns {string[]}
     */
    static get observedAttributes() {
        return ['control-id'];
    }

    /**
     * @inheritDoc
     */
    observeAttributes(name, oldValue, newValue) {
        switch (name) {
            case 'control-id':
                if (this.pickerFrom) {
                    this.pickerFrom.controlId = newValue;
                }
                break;
        }
    }

    /**
     * Syncs start and end values.
     * @override
     */
    syncControls() {
        if (!this.shouldSync) {
            return;
        }
        const pickerFrom = this.pickerFrom;
        const pickerTo = this.pickerTo;
        if (!pickerFrom || !pickerTo) {
            return;
        }
        if (!this.isValidDate(pickerTo.value)) {
            pickerFrom.max = this.max;
        } else {
            pickerFrom.max = pickerTo.value;
        }

        if (!this.isValidDate(pickerFrom.value)) {
            pickerTo.min = this.min;
        } else {
            pickerTo.min = pickerFrom.value;
        }
    }

    /**
     * Fires callback when a value of the date-from control is changed.
     * @param {Event} e
     * @private
     */
    handleChangeDateFrom(e) {
        this.syncControls();
        const ts = this.dateStringToTimestamp(e.target.value);
        if (this.allowEmpty && !this.tsTo) {
            return;
        }
        if (ts > this.tsTo) {
            if (!this.pickerTo.isEditable) {
                this.pickerFrom.value = this.pickerTo.value;
            } else {
                this.pickerTo.value = this.pickerFrom.value;
            }
        }
    }

    /**
     * Fires callback when a value of the date-to control is changed.
     * @param {Event} e
     * @private
     */
    handleChangeDateTo(e) {
        this.syncControls();
        const ts = this.dateStringToTimestamp(e.target.value);
        if (this.allowEmpty && !this.tsFrom) {
            return;
        }
        if (ts < this.tsFrom) {
            if (!this.pickerFrom.isEditable) {
                this.pickerTo.value = this.pickerFrom.value;
            } else {
                this.pickerFrom.value = this.pickerTo.value;
            }
        }
    }

    /**
     * Gets the cloud links options.
     * @returns {Array<string>}
     */
    getCloudLinksTypes() {
        return [].map.call(
            this.querySelectorAll('ui-datalist ui-option'),
            (opt) => opt.getAttribute('value')
        );
    }

    /**
     * Builds cloud links.
     * @param {Array<string>} linkTypes
     * @returns {HTMLUListElement}
     */
    buildCloudLinks(linkTypes) {
        return createElement({
            tagName: 'div',
            classList: {
                'ui-daterange__cloud': true,
            },
            children: [
                {
                    tagName: 'ul',
                    children: linkTypes.map((p) => ({
                        tagName: 'li',
                        classList: {
                            'ui-daterange__cloud-link': true,
                        },
                        children: [
                            {
                                tagName: 'button',
                                classList: {
                                    '-link': true,
                                },
                                attributes: {
                                    type: 'button',
                                    'data-type': p,
                                },
                                children: [UIDateRange.labels[p]],
                            },
                        ],
                    })),
                },
            ],
        });
    }

    /**
     * Handle cloud link click and sets values to ui-dateranges.
     * @param {Event} e
     */
    handleCloudLinkClick(e) {
        this.resolvePredefinedValue(e.target.dataset.type);
    }

    /**
     * Resolves correct dates based on typings.
     * @param {string} type
     */
    resolvePredefinedValue(type) {
        const todayDate = new Date();
        const todayTs = todayDate.getTime();
        const prevMonthDate = new Date(
            todayDate.getFullYear(),
            todayDate.getMonth() - 1,
            1
        );
        const DAY = 86400000;
        const tzOffset = prevMonthDate.getTimezoneOffset() * 60000;

        // Don't remove might be useful.
        // const min = this.pickerFrom.dateStringToTimestamp(this.min);
        // const max = this.pickerFrom.dateStringToTimestamp(this.max);

        const lastYear = UIDateRange.getLastYearPeriod(todayDate);

        this.pickerTo.value = this.pickerTo.timestampToDateString(todayTs);
        switch (type) {
            case UIDateRange.cloudLinksTypes.TODAY:
                this.setPredefinedMinValue(todayTs);
                break;
            case UIDateRange.cloudLinksTypes.YESTERDAY:
                this.setPredefinedMinValue(todayTs - DAY);
                this.setPredefinedMaxValue(todayTs - DAY);
                break;
            case UIDateRange.cloudLinksTypes.CURRENT_WEEK:
                this.setPredefinedMinValue(
                    UIDateRange.getMonday(todayDate).getTime()
                );
                break;
            case UIDateRange.cloudLinksTypes.CURRENT_MONTH:
                this.setPredefinedMinValue(
                    todayTs - DAY * (todayDate.getDate() - 1)
                );
                break;
            case UIDateRange.cloudLinksTypes.CURRENT_YEAR:
                this.setPredefinedMinValue(
                    new Date(todayDate.getFullYear(), 0, 1).getTime()
                );
                break;
            case UIDateRange.cloudLinksTypes.PREV_YEAR:
                this.setPredefinedMinValue(lastYear.start.getTime());
                this.setPredefinedMaxValue(lastYear.end.getTime());
                break;
            case UIDateRange.cloudLinksTypes.PREV_WEEK:
                this.setPredefinedMinValue(
                    todayTs - DAY * (6 + todayDate.getDay())
                );
                this.setPredefinedMaxValue(todayTs - DAY * todayDate.getDay());
                break;
            case UIDateRange.cloudLinksTypes.PREV_MONTH:
                this.setPredefinedMinValue(prevMonthDate.getTime());
                this.setPredefinedMaxValue(
                    prevMonthDate.getTime() +
                        getDaysInMonth(
                            prevMonthDate.getFullYear(),
                            prevMonthDate.getMonth()
                        ) *
                            DAY +
                        tzOffset
                );
                break;
            case UIDateRange.cloudLinksTypes.PREV_MONTH_TODAY:
                this.setPredefinedMinValue(prevMonthDate.getTime());
                break;
            /* istanbul ignore next */
            default:
                break;
        }

        if (typeof this.cloudLinkCallback === 'function') {
            this.cloudLinkCallback();
        }
    }

    /**
     * Helper functions to set min date.
     * @param {number} t
     * @private
     */
    setPredefinedMinValue(t) {
        this.pickerFrom.value = this.pickerFrom.timestampToDateString(t);
    }

    /**
     * Helper functions to set max date.
     * @param {number} t
     * @private
     */
    setPredefinedMaxValue(t) {
        this.pickerTo.value = this.pickerTo.timestampToDateString(t);
    }

    /**
     * @inheritDoc
     */
    render() {
        const datePickers = [];
        const timePickers = [];
        const cloudLinksTypes = this.getCloudLinksTypes();

        [].forEach.call(this.children, (node) => {
            const isPicker =
                node.tagName && node.tagName.toLowerCase() === 'ui-datepicker';
            if (!isPicker) {
                return;
            }
            datePickers.push(node);
            const nextNode = node.nextElementSibling;
            if (
                nextNode &&
                nextNode.tagName &&
                nextNode.tagName.toLowerCase() === 'ui-timepicker'
            ) {
                timePickers[datePickers.length - 1] = nextNode;
            }
        });
        const pickerTimeInputs = [
            timePickers[0] ? timePickers[0].querySelector('input') : null,
            timePickers[1] ? timePickers[1].querySelector('input') : null,
        ];
        if (pickerTimeInputs[0]) {
            pickerTimeInputs[0].setAttribute(
                'aria-label',
                UIDateRange.labels.TIME_START
            );
        }
        if (pickerTimeInputs[1]) {
            pickerTimeInputs[1].setAttribute(
                'aria-label',
                UIDateRange.labels.TIME_END
            );
        }
        detachChildNodes(this);

        const pickerDateInputs = [
            datePickers[0] ? datePickers[0].querySelector('input') : null,
            datePickers[1] ? datePickers[1].querySelector('input') : null,
        ];

        if (pickerDateInputs[0]) {
            pickerDateInputs[0].setAttribute(
                'aria-label',
                UIDateRange.labels.PERIOD_START
            );
        }
        if (pickerDateInputs[1]) {
            pickerDateInputs[1].setAttribute(
                'aria-label',
                UIDateRange.labels.PERIOD_END
            );
        }

        pickerDateInputs
            .filter((input) => !!input)
            .forEach((input) => {
                updateElement(input, {
                    attributes: {
                        min: this.min,
                        max: this.max,
                    },
                });
            });

        insertElements(this, [
            buildSyntheticFocusControl(this.controlId, 'ui-daterange'),
            {
                tagName: 'div',
                classList: {
                    'ui-daterange__pickers': true,
                },
                children: [
                    {
                        tagName: 'ui-datepicker',
                        element: datePickers[0],
                        attributes: {
                            'allow-empty': this.allowEmpty || null,
                        },
                        classList: {
                            '-from': true,
                        },
                        children: [
                            !pickerDateInputs[0] && {
                                tagName: 'input',
                                attributes: {
                                    'aria-label':
                                        UIDateRange.labels.PERIOD_START,
                                    min: this.min,
                                    max: this.max,
                                },
                            },
                        ],
                    },
                    timePickers[0],
                ],
            },
            {
                tagName: 'span',
                classList: {
                    'ui-daterange__separator': true,
                },
            },
            {
                tagName: 'div',
                classList: {
                    'ui-daterange__pickers': true,
                },
                children: [
                    {
                        tagName: 'ui-datepicker',
                        element: datePickers[1],
                        attributes: {
                            'allow-empty': this.allowEmpty || null,
                        },
                        classList: {
                            '-to': true,
                        },
                        children: [
                            !pickerDateInputs[1] && {
                                tagName: 'input',
                                attributes: {
                                    'aria-label': UIDateRange.labels.PERIOD_END,
                                    min: this.min,
                                    max: this.max,
                                },
                            },
                        ],
                    },
                    timePickers[1],
                ],
            },
        ]);

        updateClassList(this, {
            '-timepick': timePickers.length > 0,
        });
        this.syncControls();

        if (cloudLinksTypes.length !== 0) {
            this.appendChild(this.buildCloudLinks(cloudLinksTypes));
        }
    }

    /**
     * @inheritDoc
     */
    hydrate() {
        [].forEach.call(this.cloudLinks, (link) => {
            link.addEventListener(
                'click',
                this.handleCloudLinkClick.bind(this)
            );
        });
        updateElement(this.pickerFrom, {
            events: {
                input: this.handleChangeDateFrom.bind(this),
            },
        });
        updateElement(this.pickerTo, {
            events: {
                input: this.handleChangeDateTo.bind(this),
            },
        });
        this.focusTrigger.addEventListener('click', (e) =>
            addSyntheticFocusVisible(this.pickerFrom.control)
        );
    }
}

UIDateRange.defineElement('ui-daterange', styles);
export { UIDateRange };

import { UIElement } from '../ui-element.js';
import { UIIcon } from '../icon/ui-icon.js';
import styles from './ui-option-lang.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIOptionLang
 * @element ui-option-lang
 * @classdesc Represents a class for <code>ui-option-lang</code> element.
 * @property {boolean} [control] {@attr expanded} It shows if the element will
 * be located in control.
 * @property {string} [value] {@attr value} Value of selected item, if text attribute
 * isn't set, then value will be used as text.
 * @property {string} [text] {@attr text} Text of selected item.
 * @property {string} [color] {@attr color} Color for the arrow icon.
 * Used with combination with ui-dropdown.
 * @example
 * <ui-option-lang></ui-option-lang>
 */
class UIOptionLang extends UIElement {
    static get POPOVER_TYPE() {
        return 'balloon';
    }

    static get POPOVER_H_OFFSET() {
        return -20;
    }

    static get POPOVER_V_OFFSET() {
        return 12;
    }

    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                control: Boolean,
                text: String,
                value: String,
                color: String,
            },
        };
    }

    /**
     * @inheritDoc
     */
    render() {
        this.updateElement({
            classList: {
                'ui-option-lang': true,
                '-control': this.control,
            },
            children: [
                {
                    tagName: 'span',
                    children: [this.text || this.value],
                },
                this.control && {
                    tagName: 'ui-icon',
                    attributes: {
                        glyph: 'down',
                        color: this.color ? this.color : UIIcon.colors.ORANGE,
                    },
                },
            ],
        });
    }
}

UIOptionLang.defineElement('ui-option-lang', styles);
export { UIOptionLang };

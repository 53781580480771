import { UIElement } from '../ui-element.js';
import { BrandColors } from '../../global/helpers-marketing.js';
import styles from './ui-welcome.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIWelcome
 * @element ui-welcome
 * @classdesc Represents a class for <code>ui-welcome</code> element.
 * IBank specific components which show the info when user has logged in.
 * @property {("vip" | "gold" | "senior")} [csp] {@attr csp} Customer service programme attribute.
 *  {@desc vip}
 *  {@desc gold}
 *  {@desc senior}
 * @property {("primary" | "secondary")} [layout="primary"] {@attr layout} Layout of the element.
 * @property {string} [labelWelcome] {@attr label-welcome} Welcome message.
 * @property {string} [labelHint] {@attr label-hint} Hint text.
 * @property {string} [labelCsp] {@attr label-csp} CSP label for leaf.
 * @property {string} [linkCsp] {@attr link-csp} Link for CSP web-page.
 * @example
 * <ui-welcome
 *     csp="gold"
 *     label-welcome="Welcome, iBank User!"
 *     label-hint="You previously logged in on: 16.11.2020 14:12:29">
 * </ui-welcome>
 */
class UIWelcome extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                layout: { type: String, default: 'primary' },
                csp: String,
                labelWelcome: String,
                labelHint: String,
                labelCsp: String,
                linkCsp: String,
            },
        };
    }

    /**
     * @type {{SECONDARY: string, PRIMARY: string}}
     */
    static get layouts() {
        return {
            PRIMARY: 'primary',
            SECONDARY: 'secondary',
        };
    }

    /**
     * @type {{GOLD: string, SENIOR: string, VIP: string}}
     */
    static get csp() {
        return {
            VIP: 'vip',
            GOLD: 'gold',
            SENIOR: 'senior',
        };
    }

    /**
     * Resolves the color of the badge leaf.
     * @returns {UIBrandColor}
     * @private
     */
    resolveLeafColor() {
        switch (this.csp) {
            case UIWelcome.csp.VIP:
                return BrandColors.Bark_70;
            case UIWelcome.csp.GOLD:
                return BrandColors.Orange;
            case UIWelcome.csp.SENIOR:
            default:
                return BrandColors.Turquoise;
        }
    }

    /**
     * @inheritDoc
     */
    render() {
        const color = this.resolveLeafColor();
        const leafConfig = this.csp &&
            this.layout === UIWelcome.layouts.PRIMARY && {
                tagName: 'ui-badge',
                classList: {
                    'ui-welcome__leaf': true,
                },
                attributes: {
                    color: color || null,
                },
                children: [this.labelCsp],
            };
        const linkScpConfig = this.csp &&
            this.layout === UIWelcome.layouts.PRIMARY && {
                tagName: 'a',
                attributes: {
                    href: this.linkCsp,
                },
                classList: {
                    'ui-welcome__csp-link': true,
                },
                children: [leafConfig],
            };
        this.insertElements([
            {
                tagName: 'div',
                classList: {
                    'ui-welcome__container': true,
                },
                children: [
                    {
                        tagName:
                            this.layout === UIWelcome.layouts.SECONDARY
                                ? 'h2'
                                : 'h1',
                        children: [this.labelWelcome],
                    },
                    {
                        tagName: 'ui-hint',
                        children: [this.labelHint],
                    },
                ],
            },
            this.linkCsp ? linkScpConfig : leafConfig,
        ]);
    }
}

UIWelcome.defineElement('ui-welcome', styles);
export { UIWelcome };

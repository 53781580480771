import { UIElement } from '../ui-element.js';
import styles from './ui-placement.css';

/**
 * @memberof SharedComponents
 * @augments {UIElement}
 * @alias UIPlacement
 * @element ui-placement
 * @classdesc Represents a class for <code>ui-placement</code> element.
 * This element is used to place elements on the line with alignment to left,
 * right, center and justify.
 * @property {("justify" | "right" | "left" | "center" | "top" | "bottom")} [alignment="justify"]
 * {@attr alignment} Alignment of ui-placement element's children
 *  {@desc justify: makes even space between elements}
 *  {@desc right: push everything to right side only for row direction}
 *  {@desc left: push everything to left side only for row direction}
 *  {@desc center: push everything in center}
 *  {@desc top: push everything to top (only for column direction)}
 *  {@desc bottom: push everything to bottom (only for column direction)}
 * @property {boolean} [spacious] {@attr spacious} Adds some spacing around placements.
 * @property {("row" | "column")} [direction="row"] {@attr direction} Direction or placement.
 *  {@desc row: direction in row}
 *  {@desc column: direction in column}
 * @slot
 * @example
 * <ui-placement alignment="justify">
 *   <h2 class="-left">Hello</h2>
 *   <ui-tooltip class="-right">Helpers text</ui-tooltip>
 * </ui-placement>
 */
class UIPlacement extends UIElement {
    /**
     * @type {IProps}
     * @readonly
     */
    static get props() {
        return {
            attributes: {
                direction: { type: String, default: 'row' },
                alignment: { type: String, default: 'justify' },
                spacious: Boolean,
            },
        };
    }
}

UIPlacement.defineElement('ui-placement', styles);
export { UIPlacement };
